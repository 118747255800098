import { useCallback } from 'react';
import {
  RiCheckLine,
  RiCloseCircleLine,
  RiInformationLine,
} from 'react-icons/ri';

import AddCheck from './AddCheck';

import * as S from './styles';

interface Checklist {
  checks: CheckItem[];
  setChecks: React.Dispatch<React.SetStateAction<CheckItem[]>>;
  canNotAdd?: boolean;
  postCheckItem?: (checkContent: string) => void;
  deleteCheckItem?: (id: number | undefined) => void;
  markCheckBox?: (id: number, marked: boolean, canBeRemoved: boolean) => void;
}

function Checklist({
  checks,
  setChecks,
  canNotAdd = false,
  postCheckItem,
  deleteCheckItem,
  markCheckBox,
}: Checklist) {
  const handleCheckRemove = useCallback(
    (name, checkItem?: CheckItem) => {
      setChecks(checks.filter(check => check.name !== name));
      if (deleteCheckItem) {
        deleteCheckItem(checkItem!.id);
      }
    },
    [checks, deleteCheckItem, setChecks]
  );

  const handleCheckCheck = useCallback(
    (name: string, checkItem?: CheckItem): void => {
      const itemIndex = checks.findIndex(check => check.name === name);
      const reChecks = checks;
      reChecks[itemIndex].marked = !checks[itemIndex].marked;
      setChecks([...reChecks]);
      if (markCheckBox) {
        markCheckBox(
          checkItem!.id!,
          checks[itemIndex].marked,
          checks[itemIndex].canBeRemoved!
        );
      }
    },
    [checks, markCheckBox, setChecks]
  );

  return (
    <S.Container data-testid="checklistComponent">
      {checks.map(check => {
        if (check.name) {
          return (
            <div key={check.name} className="check">
              <S.Checkbox onClick={() => handleCheckCheck(check.name, check)}>
                {check.marked && <RiCheckLine id="checkline" size={16} />}
              </S.Checkbox>
              <p>{check.name}</p>
              {check.canBeRemoved || check.canBeRemoved === undefined ? (
                <RiCloseCircleLine
                  className="delete-btn"
                  size={14}
                  color="var(--red-primary)"
                  onClick={() => handleCheckRemove(check.name, check)}
                />
              ) : (
                <RiInformationLine
                  className="delete-btn"
                  title={check.infos}
                  size={14}
                  color="var(--red-primary)"
                />
              )}
            </div>
          );
        }

        return <div key={check.name} />;
      })}

      {canNotAdd === false && (
        <AddCheck
          postCheckItem={postCheckItem}
          checks={checks}
          setChecks={setChecks}
        />
      )}
    </S.Container>
  );
}

export default Checklist;
