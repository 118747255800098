/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { MdSkipPrevious, MdSkipNext } from 'react-icons/md';
import { useAuth } from '../../../../contexts/AuthContext';
import {
  Container,
  Search,
  Input,
  Filters,
  TicketsWrapper,
  Controll,
} from './styles';

import api from '../../../../services/api';
import Item from './components/item';
import Post from '../../components/WikiPost';

function Tickets() {
  const { id: userId } = useAuth().user;
  const [isAdmin, setIsAdmin] = useState(false);
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('all');
  const [subCategory, setSubCategory] = useState('all');
  const [tickets, setTickets] = useState({ data: [] });
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(`${userId}`);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState('true');
  const [query, setQuery] = useState('');
  const [sortByTime, setSortByTime] = useState(false);

  const getTickets = useCallback(async () => {
    await api.get(`/tickets${query}`).then(res => setTickets(res.data));
  }, [query]);

  const getPosts = useCallback(async () => {
    await api
      .get(`/wiki_posts/search?published=false${query.replace('?', '&')}`)
      .then(res => setPosts(res.data));
  }, [query]);
  useEffect(() => {
    getTickets();
    getPosts();
  }, [getPosts, getTickets, query]);

  useEffect(() => {
    if (isAdmin) {
      setSelectedUser('all');
    } else {
      setSelectedUser(`${userId}`);
    }
  }, [isAdmin, userId]);

  useEffect(() => {
    api
      .get('/roles')
      .then(res =>
        res.data.forEach(role => role.role === 'admin' && setIsAdmin(true))
      );
    api.get('/roles/support').then(res =>
      setUsers(
        res.data.map(user => (
          <option key={user.id} value={user.id}>
            {`${user.name} ${user.last_name}`}
          </option>
        ))
      )
    );
  }, []);

  const subOptions = useMemo(() => {
    if (category === 'cs') {
      return (
        <>
          <option value="onboarding">Onboarding</option>
          <option value="performance">Performance</option>
          <option value="conflicts">Resolução de Conflitos</option>
        </>
      );
    }
    if (category === 'nc') {
      return (
        <>
          <option value="growth">Crescimento da Unidade</option>
          <option value="hiring">Contratações</option>
          <option value="renegociation">Renogiciação</option>
        </>
      );
    }
    if (category === 'finance') {
      return (
        <>
          <option value="billings">Contas a Receber</option>
          <option value="pnp">Padrões e Processos</option>
        </>
      );
    }
    if (category === 'tech') {
      return (
        <>
          <option value="lab">Mkt Lab</option>
        </>
      );
    }
    if (category === 'sales') {
      return (
        <>
          <option value="hunter">Hunter</option>
          <option value="pnp">Padrões e Processos</option>
        </>
      );
    }
    return false;
  }, [category]);

  useEffect(() => {
    let q = `?page=${page}&open=${open}`;
    if (sortByTime) {
      q += '&sort=time';
    }
    if (category !== 'all') {
      q += `&category=${category}`;
    }
    if (subCategory !== 'all') {
      q += `&subcategory=${subCategory}`;
    }
    if (selectedUser !== 'all') {
      q += `&assign_id=${selectedUser}`;
    }
    if (search !== '') {
      q += `&name=${search}`;
    }

    setQuery(q);
  }, [
    category,
    open,
    getTickets,
    page,
    sortByTime,
    subCategory,
    selectedUser,
    search,
  ]);

  return (
    <Container>
      <Search>
        <h1>Pesquisar</h1>
        <Input>
          <input
            type="text"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Pesquisar tickets..."
          />
        </Input>
      </Search>
      <Filters>
        <input
          type="radio"
          id="priority"
          name="order"
          value="priority"
          checked={!sortByTime}
          onClick={() => setSortByTime(false)}
        />
        <label htmlFor="priority">Prioridade</label>
        <input
          type="radio"
          id="dueDate"
          name="order"
          value="dueDate"
          checked={sortByTime}
          onClick={() => setSortByTime(true)}
        />
        <label htmlFor="dueDate">Vencimento</label>
        <select
          name="category"
          onChange={e => {
            setSubCategory('all');
            setCategory(e.target.value);
          }}
          defaultValue="0"
        >
          <option value="0" disabled>
            Categoria
          </option>
          <option value="all">Todos</option>
          <option value="cs">Custumer Success</option>
          <option value="nc">Network Coordination</option>
          <option value="finance">Financeiro</option>
          <option value="tech">Tecnologia</option>
          <option value="sales">Vendas</option>
        </select>
        <select
          name="subcategory"
          onChange={e => setSubCategory(e.target.value)}
          defaultValue="0"
        >
          <option value="0" disabled>
            Subcategoria
          </option>
          <option value="all">Todos</option>
          {subOptions}
        </select>
        <select
          name="open"
          onChange={e => setOpen(e.target.value)}
          defaultValue="true"
        >
          <option value="true">Abertos</option>
          <option value="false">Fechados</option>
        </select>
        {isAdmin && (
          <select
            name="user"
            onChange={e => setSelectedUser(e.target.value)}
            defaultValue="0"
          >
            <option value="0" disabled>
              Usuario
            </option>
            <option value="all">Todos</option>
            {users}
          </select>
        )}
      </Filters>
      <TicketsWrapper>
        {posts
          .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
          .map(post => (
            <Post post={post} key={post.id} getPosts={getPosts} editable />
          ))}
        {tickets.data.map(ticket => (
          <Item
            ticket={ticket}
            key={ticket.id}
            getTickets={getTickets}
            getPosts={getPosts}
          />
        ))}
        <Controll>
          {page - 1 > 0 ? (
            <MdSkipPrevious size={26} onClick={() => setPage(page - 1)} />
          ) : (
            <MdSkipPrevious size={26} color="var(--gray)" />
          )}
          {`${tickets.start} - ${tickets.finish} de ${tickets.count}`}
          {tickets.finish < tickets.count ? (
            <MdSkipNext size={26} onClick={() => setPage(page + 1)} />
          ) : (
            <MdSkipNext size={26} color="var(--gray)" />
          )}
        </Controll>
      </TicketsWrapper>
    </Container>
  );
}

export default Tickets;
