/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { useCallback } from 'react';
import { FiCopy } from 'react-icons/fi';
import Popup from 'reactjs-popup';
import { format, addMinutes } from 'date-fns';
import { toast } from 'react-toastify';
import { Circle } from '../../styles';

// Ordena a table pra cima ou baixo.
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

// Retorna a table.
const Table = props => {
  const { items, requestSort, sortConfig } = useSortableData(props.contents);

  const handleTooltipContent = useCallback(contacts => {
    if (contacts) {
      if (contacts[0]) {
        if (contacts[0].contacted) {
          return <p className="yes">{contacts[0].contact_result}</p>;
        }

        if (!contacts[0].contacted) {
          return <p className="no">{contacts[0].contact_result}</p>;
        }
      }
    }
    return <p>Ainda não recebido</p>;
  }, []);

  // Coloca o icone de cima ou baixo.
  const getClassNamesFor = useCallback(
    name => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    },
    [sortConfig]
  );

  // Passa o id e o tipo da cobranca para o popup.
  const handlePopUp = useCallback(
    (id, type, royaltiesValue, iuguId, unit) => {
      props.setIuguId(iuguId);
      props.setItemType(type);
      props.setItemId(id);
      props.setEditPopup(true);
      props.setItemRoyalties(royaltiesValue);
      props.setCardUnitId(unit);
    },
    [props]
  );

  const handleCheckboxSelection = useCallback(
    (e, id) => {
      e.stopPropagation();

      if (props.userUnitId === 1) {
        props.setContactPopup(true);
      }
      props.setItemId(id);
    },
    [props]
  );

  const formatDate = useCallback(dateString => {
    if (dateString) {
      try {
        return format(addMinutes(new Date(dateString), 500), 'dd/MM/yyyy');
      } catch {
        return 'Data invalida';
      }
    }
  }, []);

  const handleContactStatus = useCallback(
    (contacts, id, type) => {
      if (contacts) {
        if (contacts[contacts.length - 1]) {
          if (contacts[contacts.length - 1].contacted) {
            if (type !== 'text') {
              return (
                <Circle
                  style={{ background: 'var(--green-primary)' }}
                  onClick={e => handleCheckboxSelection(e, id)}
                />
              );
            }
            return 'Sim';
          }
          if (!contacts[contacts.length - 1].contacted) {
            if (type !== 'text') {
              return (
                <Circle
                  style={{ background: 'var(--red-primary)' }}
                  onClick={e => handleCheckboxSelection(e, id)}
                />
              );
            }
            return 'Nao';
          }
        }
        if (contacts[contacts.length - 1] === undefined) {
          return <Circle onClick={e => handleCheckboxSelection(e, id)} />;
        }
      }
    },
    [handleCheckboxSelection]
  );

  const handleMultipleSelection = useCallback(
    (e, billing) => {
      e.stopPropagation();

      if (props.selected.length === 0) {
        props.setSelected([{ id: billing.id }]);
      }

      if (e.target.checked) {
        props.setSelected([...props.selected, { id: billing.id }]);
      } else if (!e.target.checked) {
        const removed = props.selected.filter(item => item.id !== billing.id);
        props.setSelected(removed);
      }
    },
    [props]
  );

  const copyToClipboard = useCallback(text => {
    navigator.clipboard.writeText(text);

    toast.success(`Id Iugu copiado com sucesso: ${text}`);
  }, []);

  return (
    <>
      <table id="main-table">
        <tbody>
          <tr className="table-header">
            <td>
              <p style={{ display: 'none' }}>Contato</p>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('due_date')}
                className={getClassNamesFor('due_date')}
              >
                Vencimento
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('client_id')}
                className={getClassNamesFor('client_id')}
              >
                Cliente
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('unit_id')}
                className={getClassNamesFor('unit_id')}
              >
                Unidade
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('fee_value')}
                className={getClassNamesFor('fee_value')}
              >
                Valor do fee
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('royalties_value')}
                className={getClassNamesFor('royalties_value')}
              >
                Royalties
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('matriz_value')}
                className={getClassNamesFor('matriz_value')}
              >
                Valor matriz
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('table_status')}
                className={getClassNamesFor('table_status')}
              >
                Status
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('received_date')}
                className={getClassNamesFor('received_date')}
              >
                Recebido Iugu
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('final_received_date')}
                className={getClassNamesFor('final_received_date')}
              >
                Recebido conta
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('iugu_id')}
                className={getClassNamesFor('iugu_id')}
              >
                IuguId
              </button>
            </td>
            <td>
              <button
                type="button"
                onClick={() => requestSort('payment_url')}
                className={getClassNamesFor('payment_url')}
              >
                Link
              </button>
            </td>
          </tr>
          {items.map(item => {
            if (item.unit_id === props.unitId || props.unitId === 1) {
              if (item.client) {
                const isPaidIugu =
                  item.origin === 'Iugu' && item.status === 'paid';
                return (
                  <tr
                    className="table-content"
                    style={{
                      background:
                        item.origin === 'Iugu' &&
                        item.iugu_id === null &&
                        'var(--red-primary)',
                      color:
                        item.origin === 'Iugu' &&
                        item.iugu_id === null &&
                        'var(--white)',
                      borderRadius:
                        item.origin === 'Iugu' &&
                        item.iugu_id === null &&
                        '10%',
                    }}
                    onClick={() => {
                      if (props.userUnitId === 1) {
                        handlePopUp(
                          item.id,
                          item.origin,
                          item.royalties_value,
                          item.iugu_id,
                          item.unit_id
                        );
                      }
                    }}
                    key={item.id}
                  >
                    <td>
                      <div className="checkbox-container">
                        <div style={{ display: 'none' }}>
                          {handleContactStatus(item.contacts, item.id, 'text')}
                        </div>
                        <div>
                          <input
                            style={{
                              accentColor: 'var(--red-primary)',
                              opacity: isPaidIugu ? 0.5 : 1,
                              cursor: isPaidIugu ? 'not-allowed' : 'default',
                            }}
                            type="checkbox"
                            onClick={e => handleMultipleSelection(e, item)}
                            disabled={isPaidIugu}
                          />
                        </div>

                        <Popup
                          position="top left"
                          on="hover"
                          className="tooltip"
                          arrow={false}
                          contentStyle={{
                            border: 0,
                            boxShadow: 0,
                            padding: '0px',
                          }}
                          trigger={handleContactStatus(item.contacts, item.id)}
                        >
                          <div className="popUp-tooltip-content">
                            <p>Obs. do Contato</p>
                            {handleTooltipContent(item.contacts)}
                          </div>
                        </Popup>
                      </div>
                    </td>
                    <td>{formatDate(item.due_date)}</td>
                    <td>
                      <p>
                        {item.client.name} [{item.client_id}]
                      </p>
                    </td>
                    <td>{item.unit.name}</td>
                    <td>
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(item.fee_value)}
                    </td>
                    <td>{item.royalties_value}%</td>
                    <td>
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(item.matriz_value)}
                    </td>
                    <td style={{ color: item.table_status_color }}>
                      {item.table_status}
                    </td>
                    <td>{formatDate(item.received_date)}</td>
                    <td>{formatDate(item.final_received_date)}</td>
                    <td
                      aria-hidden
                      onClick={() => {
                        if (item.iugu_id === null) {
                          toast.warn('Este boleto não tem Id Iugu');
                          return;
                        }

                        copyToClipboard(item.iugu_id);
                      }}
                    >
                      <FiCopy />
                    </td>
                    <td>{item.payment_url}</td>
                  </tr>
                );
              }
            }
            return null;
          })}
        </tbody>
      </table>
    </>
  );
};

export default Table;
