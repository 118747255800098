import styled from 'styled-components';

interface editInformationModalProps {
  left?: string;
}

export const EditInformationModal = styled.div<editInformationModalProps>`
  position: fixed;
  top: 7%;
  left: ${props => props.left};
  margin-left: calc((${props => props.left} / 2 * -1));
  height: 85vh;
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  padding: 1.5rem;
  border-radius: 5px;
  z-index: 92;
  overflow: auto;
  max-height: 90%;
  justify-content: space-between;
`;

export const FormInformations = styled.form`
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border-top: 1px solid var(--gray);
  padding-top: 1rem;
`;

export const CalendarModal = styled.div`
  width: 100%;
  min-height: 100vh;
  /* position: fixed; */
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  background-color: rgb(0, 0, 0, 0.3);
  cursor: initial;
  position: absolute;

  & div:first-child {
    position: relative;
    z-index: 150;
  }
`;
