import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, addDays } from 'date-fns';
import { Profile } from '../../components/Profile';
import apiV2 from '../../../../services/apiV2';
import * as S from './styles';
import { ChartsBoard } from './components/ChartsBoard';
import { Filters } from './components/Filters';
import { DetailsBar } from './components/DetailsBar';
import { TaskCard } from './components/TaskCard';
import { IndividualTaskCard } from './components/IndividualTaskCard';
import { useAuth } from '../../../../contexts/AuthContext';

export type FiltersTypes = {
  deleted: boolean;
  completed: boolean;
  sort: string;
  range: {
    start: string;
    end: string;
  };
};

const TasksBoard: React.FC = () => {
  const { id } = useAuth().user;
  const [tasks, setTasks] = useState<Task[]>([]);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState<FiltersTypes>({
    deleted: false,
    completed: false,
    sort: '',
    range: {
      start: '',
      end: '',
    },
  });

  const getTasks = useCallback(async () => {
    await apiV2.get(`users/${id}/tasks${query}`).then(res => {
      setTasks(res.data);
    });
  }, [id, query]);

  const tasksList = useMemo(() => {
    const tasksToSort = [...tasks];
    if (filters.sort === 'ASC') {
      if (filters.range.start && filters.range.end) {
        const rangeStart = format(
          addDays(new Date(filters.range.start), 1),
          'yyyy-MM-dd'
        );
        const rangeEnd = format(
          addDays(new Date(filters.range.end), 1),
          'yyyy-MM-dd'
        );
        if (filters.completed) {
          return tasksToSort
            .filter(
              task =>
                new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) >=
                  new Date(rangeStart) &&
                new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) <=
                  new Date(rangeEnd)
            )
            .sort((a: Task, b: Task) =>
              new Date(a.due_date) > new Date(b.due_date) ? 1 : -1
            )
            .filter(task => task.finished_at);
        }
        return tasksToSort
          .filter(
            task =>
              new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) >=
                new Date(rangeStart) &&
              new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) <=
                new Date(rangeEnd)
          )
          .sort((a: Task, b: Task) =>
            new Date(a.due_date) > new Date(b.due_date) ? 1 : -1
          )
          .filter(task => !task.finished_at);
      }
      if (filters.completed) {
        return tasksToSort
          .sort((a: Task, b: Task) =>
            new Date(a.due_date) > new Date(b.due_date) ? 1 : -1
          )
          .filter(task => task.finished_at);
      }
      return tasksToSort
        .sort((a: Task, b: Task) =>
          new Date(a.due_date) > new Date(b.due_date) ? 1 : -1
        )
        .filter(task => !task.finished_at);
    }
    if (filters.sort === 'DESC') {
      if (filters.range.start && filters.range.end) {
        const rangeStart = format(
          addDays(new Date(filters.range.start), 1),
          'yyyy-MM-dd'
        );
        const rangeEnd = format(
          addDays(new Date(filters.range.end), 1),
          'yyyy-MM-dd'
        );
        if (filters.completed) {
          return tasksToSort
            .filter(
              task =>
                new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) >=
                  new Date(rangeStart) &&
                new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) <=
                  new Date(rangeEnd)
            )
            .sort((a: Task, b: Task) =>
              new Date(a.due_date) < new Date(b.due_date) ? 1 : -1
            )
            .filter(task => task.finished_at);
        }
        return tasksToSort
          .filter(
            task =>
              new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) >=
                new Date(rangeStart) &&
              new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) <=
                new Date(rangeEnd)
          )
          .sort((a: Task, b: Task) =>
            new Date(a.due_date) < new Date(b.due_date) ? 1 : -1
          )
          .filter(task => !task.finished_at);
      }
      if (filters.completed) {
        return tasksToSort
          .sort((a: Task, b: Task) =>
            new Date(a.due_date) < new Date(b.due_date) ? 1 : -1
          )
          .filter(task => task.finished_at);
      }
      return tasksToSort
        .sort((a: Task, b: Task) =>
          new Date(a.due_date) < new Date(b.due_date) ? 1 : -1
        )
        .filter(task => !task.finished_at);
    }
    if (filters.range.start && filters.range.end) {
      const rangeStart = format(
        addDays(new Date(filters.range.start), 1),
        'yyyy-MM-dd'
      );
      const rangeEnd = format(
        addDays(new Date(filters.range.end), 1),
        'yyyy-MM-dd'
      );

      if (filters.completed) {
        return tasksToSort
          .filter(
            task =>
              new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) >=
                new Date(rangeStart) &&
              new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) <=
                new Date(rangeEnd)
          )
          .filter(task => task.finished_at);
      }

      return tasksToSort
        .filter(
          task =>
            new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) >=
              new Date(rangeStart) &&
            new Date(format(new Date(task.due_date), 'yyyy-MM-dd')) <=
              new Date(rangeEnd)
        )
        .filter(task => !task.finished_at);
    }
    if (filters.completed) {
      return tasks.filter(task => task.finished_at);
    }
    return tasks.filter(task => !task.finished_at);
  }, [tasks, filters.sort, filters.range, filters.completed]);

  useEffect(() => {
    let newQuery = '';
    if (filters.deleted) {
      newQuery += '?deleted=true';
    }

    setQuery(newQuery);
  }, [filters.deleted]);

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  return (
    <S.Wrapper>
      <S.Header>
        <section>
          <Profile
            buttons
            buttonLabel="OKR's e KR's"
            buttonHref="/home/tasks_board/okrs"
          />
        </section>
        <ChartsBoard id={id} />
      </S.Header>
      <Filters filters={filters} setFilters={setFilters} />
      <DetailsBar />
      <S.Content>
        {tasks &&
          tasksList.map((task: Task) => {
            if (task.phase_id !== null) {
              return (
                <TaskCard
                  key={task.id}
                  task={task}
                  tasks={tasks}
                  setTasks={setTasks}
                />
              );
            }
            return (
              <IndividualTaskCard
                key={task.id}
                task={task}
                tasks={tasks}
                setTasks={setTasks}
              />
            );
          })}
      </S.Content>
    </S.Wrapper>
  );
};

export default TasksBoard;
