import React, { useState, useCallback } from 'react';

import { TextInput, Button } from '../../../../../components/Inputs';

import * as S from './styles';

interface PremiseForm {
  name: string;
  cause: string[];
  risk: string[];
  effect: string;
  probability: number;
  impact: number;
}

interface PremiseFormProps {
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    newCard: PremiseForm
  ) => void;
}

const PremiseForm: React.FC<PremiseFormProps> = ({ handleSubmit }) => {
  const defaultObject = {
    name: '',
    effect: '',
    cause: [''],
    risk: [''],
    probability: 0,
    impact: 0,
  };
  const [newCard, setNewCard] = useState<PremiseForm>(defaultObject);

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;
      setNewCard({ ...newCard, [name]: value });
    },
    [newCard]
  );

  return (
    <S.Container>
      <S.Form
        onSubmit={e => {
          handleSubmit(e, newCard);
          setNewCard(defaultObject);
        }}
        data-testid="form"
      >
        <div className="input">
          <p>Nome da premissa</p>
          <TextInput
            maxLength={48}
            required
            placeholder="Digite aqui"
            name="name"
            value={newCard.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="input">
          <p>Causas</p>
          <TextInput
            maxLength={200}
            required
            placeholder="Digite aqui"
            name="cause"
            value={newCard.cause}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="input">
          <p>Riscos</p>
          <TextInput
            maxLength={200}
            required
            placeholder="Digite aqui"
            name="risk"
            value={newCard.risk}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="input">
          <p>Efeito</p>
          <TextInput
            maxLength={48}
            required
            placeholder="Digite aqui"
            name="effect"
            value={newCard.effect}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
            }}
          />
        </div>

        <div className="inputContainer">
          <div className="input">
            <p>Probabilidade</p>
            <TextInput
              required
              placeholder="Digite aqui"
              type="number"
              max="10"
              min="0"
              name="probability"
              value={newCard.probability}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="input">
            <p>Impacto</p>
            <TextInput
              required
              placeholder="Digite aqui"
              type="number"
              max="10"
              min="0"
              step="1"
              name="impact"
              value={newCard.impact}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
            />
          </div>
        </div>

        <div className="input">
          <Button
            background="var(--red-primary)"
            type="submit"
            height="1.8rem"
            width="6rem"
            font="800 0.7rem Work Sans"
            text="Adicionar"
          />
        </div>
      </S.Form>
    </S.Container>
  );
};

export default PremiseForm;
