import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 5px;
  background: ${({ theme }) => theme.backgroundPrimary} 0% 0% no-repeat
    padding-box;
  color: ${({ theme }) => theme.text};
  font-weight: normal;
  border: 1px solid ${({ theme }) => theme.backgroundPrimary};
  border-radius: 6px;
  padding: 10px;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
  max-height: 400px;
  resize: both;
  overflow-y: auto;
  cursor: text;

  img {
    max-width: 200px;
    max-height: 200px;
  }
`;
