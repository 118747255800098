import { toast } from 'react-toastify';

import apiV2 from '../../../services/apiV2';

interface Props {
  name: string;
  clientId: number;
  draftId: number | undefined;
}

export async function postOkrService({
  name,
  clientId,
  draftId,
}: Props): Promise<undefined | string> {
  try {
    const response = await apiV2.post('okr', {
      okr: {
        name,
        is_active: true,
        client_id: clientId,
        due_date: new Date(),
      },
      checkinDraftId: draftId && draftId,
    });

    return response.data;
  } catch {
    toast.error('Falha ao criar okr');

    return undefined;
  }
}
