import styled from 'styled-components';

export const Checkbox = styled.div`
  height: 14px;
  width: 14px;
  color: var(--white);
  background-color: var(--red-primary);
  border-radius: 4px;
  border: 0;
  cursor: pointer;
`;

export const CheckedCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 14px;
  width: 14px;
  background-color: var(--red-primary);
  border-radius: 4px;
  border: 0;
  cursor: pointer;
`;
