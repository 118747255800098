import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  color: var(--gray);
  align-items: center;

  #name {
    width: 24%;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
  }

  #responsibles {
    width: 18%;
    align-items: center;
  }

  #period {
    width: 20%;

    div {
      width: 16rem;
    }
  }

  #progress {
    width: 20%;

    div {
      width: 16rem;
    }
  }

  #add {
    div {
      width: 12rem;
    }
  }

  @media (max-width: 1890px) {
    #responsibles {
      width: 18%;
      align-items: center;
      margin: 0 1.3rem;
    }

    #period {
      width: 25%;
    }

    #progress {
      width: 25%;

      div {
        margin: 0 0.8rem;
      }
    }
    #add {
      div {
        display: none;
      }
    }
  }

  @media (max-width: 1360px) {
    #responsibles {
      width: 18%;
      align-items: center;
      margin: 0 1.3rem;
    }

    #period {
      width: 20%;

      div {
        width: 16rem;
      }
    }

    #progress {
      width: 20%;

      div {
        margin: 0 0.8rem;
      }
    }

    #add {
      div {
        display: none;
      }
    }
  }

  .animation {
    animation-name: loading;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    @keyframes loading {
      0% {
        background-color: var(--gray);
      }
      10% {
        background-color: var(--gray-text);
      }
      20% {
        background-color: var(--gray);
      }
      30% {
        background-color: var(--gray-text);
      }
      40% {
        background-color: var(--gray);
      }
      50% {
        background-color: var(--gray-text);
      }
      60% {
        background-color: var(--gray);
      }
      70% {
        background-color: var(--gray-text);
      }
      80% {
        background-color: var(--gray);
      }
      90% {
        background-color: var(--gray-text);
      }
      100% {
        background-color: var(--gray);
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const NameBar = styled.div`
  width: 15rem;
  height: 0.8rem;
  margin: 0.2rem 0;
  border-radius: 0.2rem;
  background-color: var(--gray);
  & + div {
    width: 17rem;
  }
  :last-child {
    width: 16rem;
  }

  @media (max-width: 1890px) {
    width: 10rem;
    & + div {
      width: 12rem;
    }
    :last-child {
      width: 11rem;
    }
  }

  @media (max-width: 1360px) {
    width: 5rem;
    & + div {
      width: 7rem;
    }
    :last-child {
      width: 6rem;
    }
  }
`;

export const AvatarIcon = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--gray);
  margin-left: -1rem;
  border: 0.1rem solid ${({ theme }) => theme.backgroundPrimary};
  @media (min-width: 2550px) {
  }

  @media (max-width: 1600px) {
  }

  @media (max-width: 1360px) {
    width: 2rem;
    height: 2rem;
  }
`;

export const ProgressBar = styled.div`
  height: 2rem;
  background-color: var(--gray);
  border-radius: 0.2rem;
  @media (min-width: 2550px) {
  }

  @media (max-width: 1600px) {
  }

  @media (max-width: 1360px) {
  }
`;
