import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { Button, TextInput } from '../Inputs';

interface addCheck {
  checks: {
    name: string;
    marked: boolean;
  }[];
  setChecks: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        marked: boolean;
      }[]
    >
  >;
  postCheckItem?: (checkContent: string) => void;
}

const AddCheck: React.FC<addCheck> = ({ setChecks, checks, postCheckItem }) => {
  const [inputText, setInputText] = useState('');

  const handleAdd = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      if (inputText) {
        const checklistFound = checks.some(elem => {
          return JSON.stringify(inputText) === JSON.stringify(elem.name);
        });

        if (checklistFound) {
          e.preventDefault();
          toast.warn('As subtasks não podem ter nomes iguais');
        } else {
          e.preventDefault();
          setChecks([...checks, { name: inputText, marked: false }]);
          if (postCheckItem) {
            postCheckItem(inputText);
          }
        }
      } else {
        e.preventDefault();
        toast.warn('Não é possível criar uma subtask sem nome. 😕');
      }
    },
    [checks, inputText, postCheckItem, setChecks]
  );

  return (
    <div className="check">
      <form onSubmit={handleAdd} className="input">
        <TextInput
          placeholder="Nome da subtask"
          width="10.7rem"
          height="1.8rem"
          font="400 1rem Work Sans"
          value={inputText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            setInputText(e.target.value);
          }}
        />
        <Button id="post" type="submit">
          <p style={{ fontSize: '0.82rem', margin: '0' }}>Adicionar</p>
        </Button>
      </form>
    </div>
  );
};

export default AddCheck;
