import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { BiDice1 } from 'react-icons/bi';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ProgressDisplay } from '../../../../../Tasks/components/ProgressDisplay';
import { Responsibles } from '../../../../../Tasks/components/Responsibles';
import * as S from './styles';
import { useTags } from '../../../../../../contexts/TagsContext';
import { Tag } from '../../../../../Tasks/components/Tag';
import useProgress from '../../../../../../hooks/useProgress';
import { EditIndividualTask } from '../../../../../Tasks/components/Modals/EditIndividualTask';
import { Modal } from '../../../../../Tasks/styles';

export const IndividualTaskCard: React.FC<{
  task: Task;
  tasks: Task[];
  setTasks: Dispatch<SetStateAction<Task[]>>;
}> = ({ task, tasks, setTasks }) => {
  const [openModal, setOpenModal] = useState(false);
  const { progress, colors } = useProgress();
  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();

  useEffect(() => {
    setTags(() => (task.tags ? task.tags : []));
  }, [task]);

  const periodProgressPercent = useMemo(
    () => progress(task.created_at, task.due_date),
    [progress, task.created_at, task.due_date]
  );

  const progressBarsColors = useMemo(
    () => colors(task.due_date, task.finished_at),
    [colors, task.due_date, task.finished_at]
  );

  const progressTitle = useMemo(
    () => `${task.progress ? Math.round(task.progress * 100) / 100 : 0}%`,
    [task.progress]
  );

  const handleOpenModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };
  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') handleOpenModal();
  };
  return (
    <>
      <S.Wrapper>
        <S.Container>
          {tags && (
            <S.TagsArea>
              {tags.map((tag: ITag, indexOfTag) => {
                if (indexOfTag >= 10) {
                  return false;
                }
                return (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    type="task_id"
                    typeId={task.id}
                    setTagsList={setTags}
                    showLabel={show}
                    setShowLabel={handleShowTags}
                  />
                );
              })}
            </S.TagsArea>
          )}
          <S.Main>
            <div id="name">
              <button id="label" type="button" onClick={handleOpenModal}>
                <BiDice1 color="var(--red-primary)" size={16} />
                <div id="individual-task-label">
                  <h4>Task</h4>
                  <h4>Individual</h4>
                </div>
                <span> | </span>
                <div>
                  <h4
                    title={
                      task.top_hierarchy &&
                      `${task.top_hierarchy.client_name ?? '...'}/${task
                        .top_hierarchy.project_name ?? '...'}`
                    }
                  >
                    {task.top_hierarchy &&
                      `${task.top_hierarchy.client_name ?? '...'}/${task
                        .top_hierarchy.project_name ?? '...'}`}
                  </h4>
                  <p title={task.name}>{task.name}</p>
                </div>
              </button>

              <button type="button" id="edit-button" onClick={handleOpenModal}>
                <FaRegEdit size={14} />
              </button>
            </div>

            <p> | </p>
            {task.was_removed ? (
              <S.RestoreProject>
                <p id="restore-label">Task Individual Deletada</p>
              </S.RestoreProject>
            ) : (
              <div id="managers">
                <Responsibles users={task.users} />
              </div>
            )}

            <p> | </p>

            <div id="period">
              <ProgressDisplay
                width="95%"
                height="2rem"
                borderRadius="0.5rem"
                percentage={periodProgressPercent}
                title={
                  task.due_date
                    ? format(new Date(task.due_date), 'dd.MMMM.yyyy', {
                        locale: ptBR,
                      })
                    : 'Sem prazo'
                }
                colorBar={progressBarsColors.dueDateColor}
              />
            </div>
            <p> | </p>

            <div id="progress">
              <ProgressDisplay
                width="95%"
                height="2rem"
                borderRadius="0.5rem"
                title={progressTitle}
                percentage={task.progress}
                colorBar={progressBarsColors.progressColor}
              />
            </div>
            <p> | </p>

            <div id="open">
              <button type="button" onClick={handleOpenModal}>
                Abrir
              </button>
            </div>
          </S.Main>
        </S.Container>
        <S.StickerColor />
      </S.Wrapper>
      {openModal && (
        <Modal id="modal" onClick={handleOutsideClick}>
          <EditIndividualTask
            handleClose={handleCloseModal}
            task={task}
            tasks={tasks}
            setTasks={setTasks}
            tags={tags}
            myTasksView
          />
        </Modal>
      )}
    </>
  );
};
