import styled from 'styled-components';

export const Container = styled.div`
  .inputs .input input {
    background: ${({ theme }) => theme.backgroundPrimary} !important;
    color: ${({ theme }) => theme.text} !important;
  }

  textarea {
    background: ${({ theme }) => theme.backgroundPrimary} !important;
    color: ${({ theme }) => theme.text} !important;
  }
`;
