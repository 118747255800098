import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem 0;
  .item {
    display: flex;
    position: relative;
    border-bottom: 2px solid var(--gray);
    margin: 10px 0;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.backgroundSecondary};
  }
  input[type='checkbox'].checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  input[type='checkbox'].checkbox + label.css-label {
    padding-left: 22px;
    height: 17px;
    display: inline-block;
    line-height: 17px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 17px;
    vertical-align: middle;
    cursor: pointer;
  }

  input[type='checkbox'].checkbox:checked + label.css-label {
    background-position: 0 -17px;
  }
  label.css-label {
    background-image: url('https://csscheckbox.com/checkboxes/u/csscheckbox_314a66eb1641f8832a7ada19bbf96697.png');
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const Controll = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  color: var(--gray);
  width: 100%;
  > div {
    display: flex;
    align-items: center;
  }

  svg {
    cursor: pointer;
    color: var(--gray);
  }
`;
export const Filters = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding: 0 0.5rem;

  select {
    color: ${({ theme }) => theme.text};
    background: transparent;
    border: none;
    font-weight: bold;
    width: 10%;
    margin-right: 20px;
    option {
      color: ${({ theme }) => theme.text};
      :disabled {
        color: var(--gray);
      }
    }
  }
  #allRead {
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    border-radius: 4px;
    color: var(--white);
    padding: 2px;
    margin: 0 5px;
    :disabled {
      background: transparent;
      color: var(--red-primary);
      cursor: not-allowed;
    }
    :hover {
      filter: brightness(80%);
    }
  }
`;
