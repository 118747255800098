/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { parseISO, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MdSkipPrevious, MdSkipNext } from 'react-icons/md';
import socketio from 'socket.io-client';
import api from '../../../../services/api';
import { NotificationCard } from './components/NotificationCard';
import { Container, Controll, Filters } from './styles';
import { useAuth } from '../../../../contexts/AuthContext';

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const [read, setRead] = useState('all');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useAuth();
  const socket = socketio('https://socketio.laboratoriodomarketing.com', {
    query: {
      user_id: user.id,
    },
  });

  useEffect(() => {
    socket.on('notification', notification => {
      setNotifications([
        ...notifications,
        {
          ...notification,
          timeDistance: formatDistance(
            parseISO(notification.createdAt),
            new Date(),
            { addSuffix: true, locale: ptBR }
          ),
        },
      ]);
    });
    socket.on('notificationUpdate', notification => {
      setNotifications(
        notifications.map(nf => {
          if (nf._id === notification._id) {
            return {
              ...notification,
              timeDistance: formatDistance(
                parseISO(notification.createdAt),
                new Date(),
                { addSuffix: true, locale: ptBR }
              ),
            };
          }
          return nf;
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMark = useCallback(
    id => {
      if (selecteds.includes(id)) {
        setSelecteds(selecteds.filter(selected => selected !== id));
        return false;
      }
      setSelecteds([...selecteds, id]);
      return true;
    },
    [selecteds]
  );
  const handleRead = useCallback(
    async (id, isRead) => {
      setNotifications(
        notifications.map(notification =>
          notification._id === id
            ? { ...notification, read: isRead }
            : notification
        )
      );
    },
    [notifications]
  );

  const handleAllRead = useCallback(() => {
    selecteds.forEach(async selected => {
      await api.put('notifications', { id: selected, read: true });
    });
    setNotifications(
      notifications.map(notification =>
        selecteds.includes(notification._id)
          ? { ...notification, read: true }
          : notification
      )
    );
  }, [notifications, selecteds]);

  const handleSelectAll = useCallback(() => {
    if (selecteds.length === notifications.length) {
      setSelecteds([]);
      return false;
    }
    setSelecteds(notifications.map(notification => notification._id));
    return true;
  }, [notifications, selecteds.length]);

  useEffect(() => {
    api.get(`notifications?read=${read}&page=${page}`).then(res => {
      setNotifications(
        res.data.notifications.map(notification => ({
          ...notification,
          timeDistance: formatDistance(
            parseISO(notification.createdAt),
            new Date(),
            { addSuffix: true, locale: ptBR }
          ),
        }))
      );
      setCount(res.data.total);
    });
  }, [page, read]);

  const { firstValue, lastValue } = useMemo(
    () => ({
      firstValue: (page - 1) * 10 + 1,
      lastValue: (page - 1) * 10 + notifications.length,
    }),
    [notifications.length, page]
  );

  return (
    <Container>
      <Filters>
        <input
          type="checkbox"
          className="checkbox"
          onClick={handleSelectAll}
          checked={selecteds.length === notifications.length}
          id="checkAll"
        />
        <label htmlFor="checkAll" className="css-label" />
        <button
          type="button"
          id="allRead"
          onClick={handleAllRead}
          disabled={selecteds.length < 1}
        >
          Marcar como lido
        </button>
        <select
          name="filter"
          onChange={e => {
            setRead(e.target.value);
          }}
          defaultValue="0"
        >
          <option value="0" disabled>
            Filtrar
          </option>
          <option value="all">Todos</option>
          <option value="false">Não lidas</option>
          <option value="true">Lidas</option>
        </select>
      </Filters>
      {notifications
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map(notification => (
          <div key={notification._id} className="item">
            <input
              type="checkbox"
              className="checkbox"
              onClick={() => handleMark(notification._id)}
              checked={selecteds.includes(notification._id)}
              id={notification._id}
            />
            <label htmlFor={notification._id} className="css-label" />
            <NotificationCard notification={notification} update={handleRead} />
          </div>
        ))}
      <Controll>
        {page - 1 > 0 ? (
          <MdSkipPrevious size={26} onClick={() => setPage(page - 1)} />
        ) : (
          <MdSkipPrevious size={26} color="var(--gray)" />
        )}
        {`${firstValue} - ${lastValue} de ${count}`}
        {lastValue < count ? (
          <MdSkipNext size={26} onClick={() => setPage(page + 1)} />
        ) : (
          <MdSkipNext size={26} color="var(--gray)" />
        )}
      </Controll>
    </Container>
  );
}

export default Notifications;
