import styled from 'styled-components';

interface UploaderHoverProps {
  drag: boolean;
}

export const Uploader = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 20vh;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e32337;
  color: #fff;
  padding: 1rem;
  font-size: 0.8rem;
  cursor: pointer;
  position: relative;

  > div:last-child {
    font-weight: bolder;
  }
`;

export const UploaderHover = styled.div<UploaderHoverProps>`
  background: ${props =>
    props.drag ? 'rgba(255, 255, 255, 0.8)' : 'transparent'};
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
