import styled from 'styled-components';

import { ButtonProps } from '../types';

export const Container = styled.button<ButtonProps>`
  width: ${({ width }) => width || '100%'};
  background: ${({ background }) => background || 'var(--red-primary)'};
  font: ${({ font }) => font || '400 0.8rem Work Sans'};
  padding: ${({ padding }) => padding || '0.4rem'};
  color: ${({ color }) => color || 'var(--white)'};

  border: 0;
  border-radius: 0.3rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  :hover {
    filter: brightness(80%);
  }
`;
