import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { useParams } from 'react-router-dom';
import { Board } from './Board';
import { changePhaseOrder } from '../../../../services/ChangePhaseOrder';
import { changeTaskOrder } from '../../../../services/ChangeTaskOrder';

import * as S from './styles';
import { useTasks } from '../../../../../../contexts/TasksContext';
import { SetPhases } from '../../../../utils/SetPhases';
import { getPhases } from '../../../../services/GetPhases';
import selectGroupIndexById from '../../../../utils/SelectGroupIndexById';
import selectProjectIndexById from '../../../../utils/SelectProjectIndexById';
import { setTasks } from '../../../../utils/SetTasks';

export function GroupBoard() {
  const { projects, setProjects, query } = useTasks();
  const [draggingTimeOut, setDraggingTimeOut] = useState(0);
  const [draggingTaskId, setDraggingTaskId] = useState<number>();
  const [firstTime, setFirstTime] = useState(true);
  const { projectId, groupId } = useParams<TasksParams>();

  const projectIndex = selectProjectIndexById(+projectId, projects);

  const groupIndex = selectGroupIndexById(projectIndex, +groupId, projects);

  const setPhaseInfo = useCallback(async () => {
    const newPhases = await getPhases(+groupId, query);

    if (newPhases === []) return;

    const newProjects = SetPhases(newPhases, projects, +projectId, +groupId);
    setProjects(() => newProjects);
  }, [query, groupId, projects, projectId, setProjects]);

  useEffect(() => {
    if (firstTime) {
      setPhaseInfo();
      setFirstTime(false);
    }
  }, [setPhaseInfo, firstTime]);

  const handleChangeTaskOrder = useCallback(
    async (
      taskId: number,
      phaseId: number,
      newOrder: number,
      sourceDropId?: number,
      destinationDropId?: number
    ) => {
      await changeTaskOrder(taskId, phaseId, newOrder);

      const verifyMovement = sourceDropId !== destinationDropId;

      if (!verifyMovement) {
        toast.error('Falha em alterar task de fase!');
      }

      setDraggingTaskId(0);
    },
    []
  );

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    if (draggingTaskId === +draggableId) {
      toast.warn(
        'O seu último movimento dessa task está sendo processado. Aguarde.'
      );
      return;
    }

    setDraggingTaskId(+draggableId);

    if (!destination) {
      return;
    }

    if (type === 'list') {
      const phaseSelected =
        projects[projectIndex].groups[groupIndex].phases[source.index];
      const order = destination.index + 1;

      if (source.index === 0) {
        toast.warn('Não é possível mover a primeira fase.');
        return;
      }
      if (
        source.index ===
        projects[projectIndex].groups[groupIndex].phases.length - 1
      ) {
        toast.warn('Não é possível mover a última fase.');
        return;
      }
      if (destination.index === 0) {
        toast.warn(
          'Não é possível reordenar uma fase para a primeira posição.'
        );
        return;
      }
      if (
        destination.index ===
        projects[projectIndex].groups[groupIndex].phases.length - 1
      ) {
        toast.warn('Não é possível reordenar uma fase para a última posição.');
        return;
      }
      setDraggingTimeOut(
        window.setTimeout(() => {
          changePhaseOrder(phaseSelected.id, order);
        }, 500)
      );

      const phasesUpdate = projects[projectIndex].groups[
        groupIndex
      ].phases.filter(phaseItem => phaseItem.id !== phaseSelected.id);
      phasesUpdate.splice(destination.index, 0, phaseSelected);
      const newProjects = SetPhases(
        phasesUpdate,
        projects,
        +projectId,
        +groupId
      );
      setProjects(() => newProjects);
      return;
    }

    const destinyPhaseIndex = +destination.droppableId;
    const destinyPhase =
      projects[projectIndex].groups[groupIndex].phases[destinyPhaseIndex];
    const order = destination.index + 1;

    const sourcePhaseIndex = +source.droppableId;
    const sourcePhase =
      projects[projectIndex].groups[groupIndex].phases[sourcePhaseIndex];

    const draggingTask = sourcePhase.tasks.filter(
      task => task.id === +draggableId
    )[0];

    if (destinyPhase.users && destinyPhase.users.length > 0) {
      if (destinyPhase.remove_users) {
        draggingTask.users = [];
      }
      draggingTask.users = draggingTask.users.concat(destinyPhase.users);
      draggingTask.users = _.uniqBy(draggingTask.users, 'id');
    }

    if (sourcePhaseIndex === destinyPhaseIndex) {
      const tasksUpdated = sourcePhase.tasks.filter(
        taskItem => taskItem.id !== draggingTask.id
      );

      tasksUpdated.splice(destination.index, 0, draggingTask);
      const newProjects = setTasks(
        tasksUpdated,
        projects,
        +projectId,
        +groupId,
        destinyPhase.id
      );

      setProjects([...newProjects]);
    } else {
      const sourcePhaseTasksUpdated = sourcePhase.tasks.filter(
        task => task.id !== +draggableId
      );

      const projectsWithoutDraggingTask = setTasks(
        sourcePhaseTasksUpdated,
        projects,
        +projectId,
        +groupId,
        sourcePhase.id
      );

      draggingTask.subtask_phase_checklist_items = [
        ...destinyPhase.phase_checklist_items!,
        ...draggingTask.subtask_phase_checklist_items!,
      ];

      destinyPhase.tasks = [...destinyPhase.tasks, draggingTask];

      const newProjects = setTasks(
        destinyPhase.tasks,
        projectsWithoutDraggingTask,
        +projectId,
        +groupId,
        destinyPhase.id
      );

      setProjects([...newProjects]);
    }
    if (draggingTimeOut) {
      clearTimeout(draggingTimeOut);
    }
    if (draggingTask && destinyPhase && order) {
      setDraggingTimeOut(
        window.setTimeout(() => {
          handleChangeTaskOrder(
            draggingTask.id,
            destinyPhase.id,
            order,
            +source.droppableId,
            +destination.droppableId
          );
        }, 500)
      );
    }
  };

  const kanban = document.getElementById('scroller');

  window.addEventListener('wheel', e => {
    if (kanban) {
      if (e.deltaY > 0) kanban.scrollLeft += 100;
      else kanban.scrollLeft -= 100;
    }
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="app" type="list" direction="horizontal">
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ height: '100%' }}
          >
            <S.Wrapper id="scroller">
              {projects[projectIndex].groups[groupIndex].phases &&
                projects[projectIndex].groups[groupIndex].phases.map(
                  (phase, phaseIndex) => {
                    return (
                      <Board
                        key={phase.id}
                        phases={
                          projects[projectIndex].groups[groupIndex].phases
                        }
                        phase={phase}
                        index={phaseIndex}
                      />
                    );
                  }
                )}
            </S.Wrapper>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
