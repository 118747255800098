/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../../../contexts/AuthContext';
import NewPopUp from '../../../../components/PopUp';
import { TextInput, Select, Button } from '../../../../components/Inputs';
import { OptionsSelect } from '../../../../components/Inputs/OptionsSelect';
import TextEditor from '../../../../components/TextEditor';
import * as S from './styles';
import api from '../../../../services/api';

type User = {
  id: number;
  unit_id: number;
  name: string;
  last_name: string;
};

type Unit = {
  id: number;
  name: string;
};

type ObjectProps = {
  name: string;
  content: string;
  required: boolean;
  users_id: number[];
};

interface NewBroadcastProps {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewBroadcast: FC<NewBroadcastProps> = ({ setActive }) => {
  const [object, setObject] = useState<ObjectProps>({} as ObjectProps);
  const [who, setWho] = useState('all');
  const [users, setUsers] = useState<User[]>([]);
  const [units, setUnits] = useState([]);
  const [selectedUnitsIds, setSelectedUnitsIds] = useState<number[]>([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);
  const { unit_id: unitId } = useAuth().user;

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;
      setObject({ ...object, [name]: value });
    },
    [object]
  );

  const getUsers = useCallback(async () => {
    await api
      .get('users')
      .then(res =>
        setUsers(
          unitId === 1
            ? res.data
            : res.data.filter((user: User) => user.unit_id === unitId)
        )
      );
  }, [unitId]);

  const getUnits = useCallback(async () => {
    await api
      .get('units?active=true')
      .then(res =>
        setUnits(
          unitId === 1
            ? res.data
            : res.data.filter((unit: Unit) => unit.id === unitId)
        )
      );
  }, [unitId]);

  useEffect(() => {
    getUnits();
    getUsers();
  }, [getUnits, getUsers]);

  const forWhoOptions = [
    { key: 2, value: 'units', name: 'Unidades' },
    { key: 3, value: 'users', name: 'Usuários' },
  ];

  if (unitId === 1) forWhoOptions.push({ key: 1, value: 'all', name: 'Todos' });

  const selectWho = useMemo(() => {
    if (who === 'all') {
      return false;
    }
    if (who === 'units') {
      const unitOptions = units.map((unit: Unit) => ({
        name: unit.name,
        value: String(unit.id),
      }));
      return (
        <div className="selectBox">
          Selecione as unidades:
          <OptionsSelect
            onChange={(e: number[]) => setSelectedUnitsIds(e)}
            value={selectedUnitsIds}
            options={unitOptions}
            search
            multiple
          />
        </div>
      );
    }
    if (who === 'users') {
      const usersOptions = users.map(user => ({
        name: `${user.name} ${user.last_name}`,
        value: String(user.id),
      }));
      return (
        <div className="selectBox">
          Selecione os usuários:
          <OptionsSelect
            onChange={(e: number[]) => setSelectedUsersIds(e)}
            value={String(selectedUsersIds)}
            options={usersOptions}
            search
            multiple
          />
        </div>
      );
    }
    return false;
  }, [selectedUnitsIds, selectedUsersIds, units, users, who]);

  useEffect(() => {
    if (who === 'all') {
      setObject({ ...object, users_id: users.map(user => user.id) });
    } else if (who === 'users') {
      setObject({ ...object, users_id: selectedUsersIds });
    } else if (who === 'units') {
      setObject({
        ...object,
        users_id: users
          .filter(user => selectedUnitsIds.includes(user.unit_id))
          .map(user => user.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnitsIds, selectedUsersIds, users, who]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (!object.content || !object.users_id) {
        toast.warn('Preencha todos os campos');
      }

      await api
        .post('broadcasts', object)
        .then(() => {
          toast.success('Comunicado enviado!');
          setActive(false);
        })
        .catch(() => {
          toast.error('Algo deu errado, por favor, atualize a página');
        });
    },
    [object, setActive]
  );

  return (
    <NewPopUp closePopUp={setActive} header="Novo Comunicado" width="60%">
      <S.Form onSubmit={handleSubmit}>
        <label htmlFor="name">
          <strong>Titulo:</strong>
          <TextInput
            id="name"
            value={object.name}
            name="name"
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="content">
          <strong>Conteúdo:</strong>
          <TextEditor object={object} setObject={setObject} />
        </label>
        <label htmlFor="required" className="required">
          <input
            type="checkbox"
            className="checkbox"
            name="required"
            onClick={() => setObject({ ...object, required: !object.required })}
            checked={object.required}
            id="required"
          />
          <label htmlFor="required" className="css-label" />
          <strong>Forçar visualização e aceite</strong>
        </label>
        <div className="selectBox">
          <strong>Para quem?</strong>
          <Select
            onChange={e => setWho(String(e.target.value))}
            value={who}
            options={forWhoOptions}
          />
        </div>
        {selectWho}
        <S.Button>
          <Button
            background="var(--red-primary)"
            width="8rem"
            height="2rem"
            font="0.8rem"
            type="submit"
          >
            Enviar
          </Button>
        </S.Button>
      </S.Form>
    </NewPopUp>
  );
};

export default NewBroadcast;
