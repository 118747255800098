/* eslint-disable no-useless-escape */
import { useEffect, useState, useCallback } from 'react';

import { toast } from 'react-toastify';
import { MdClose, MdDone } from 'react-icons/md';
import { AvatarInput } from './AvatarInput';
import * as S from './styles';
import { useAuth } from '../../../../contexts/AuthContext';

import { Button } from '../../../../components/Inputs';

import { updateProfileRequest } from '../../services/UpdateProfileRequest';

export function Profile() {
  const { user: profile, updateUser } = useAuth();

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle] = useState('Frontend Developer');
  const [email, setEmail] = useState(profile.email);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [file, setFile] = useState(profile.avatar && profile.avatar.path);

  useEffect(() => {
    setName(profile.name);
    setLastName(profile.last_name);
  }, [profile.last_name, profile.name]);

  const updateName = useCallback(
    async (newName: string) => {
      const data = {
        name: newName,
      };

      const user = await updateProfileRequest(data);
      if (user) updateUser(user.data);
    },
    [updateUser]
  );

  const updateLastName = useCallback(
    async (newLastName: string) => {
      const data = {
        last_name: newLastName,
      };

      const user = await updateProfileRequest(data);
      if (user) updateUser(user.data);
    },
    [updateUser]
  );

  const updateEmail = useCallback(
    async (newEmail: string) => {
      const data = {
        email: newEmail,
      };

      if (!newEmail.toLowerCase().includes('@v4company.com')) {
        toast.error('Software exclusivo para integrantes da V4 Company.');
      }

      toast.error('Software exclusivo para integrantes da V4 Company.');
      const user = await updateProfileRequest(data);
      if (user) updateUser(user.data);
    },
    [updateUser]
  );

  const updateAvatar = useCallback(
    async (newAvatar: string) => {
      setFile(newAvatar);

      const data = {
        avatar_id: newAvatar,
      };

      const user = await updateProfileRequest(data);
      if (user) updateUser(user.data);
    },
    [updateUser]
  );

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      const data = {
        name,
        last_name: lastName,
        email,
        oldPassword,
        password,
        confirmPassword,
        avatar_id: file,
      };

      if (!password || !oldPassword || !confirmPassword) {
        toast.error('Digite as senhas antes de continuar.');
        return null;
      }

      if (password !== confirmPassword) {
        toast.error('A senha e a confirmação de senha estão diferentes.');
        return null;
      }

      if (!email.toLowerCase().includes('@v4company.com')) {
        toast.error('Software exclusivo para integrantes da V4 Company.');
        return null;
      }

      if (
        !(
          password.length > 7 &&
          /([ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/g.test(password) &&
          /([0-9]+)/g.test(password) &&
          /([A-Z]+)/g.test(password) &&
          /([a-z]+)/g.test(password)
        )
      ) {
        toast.error('Sua senha não atende os requistos de segurança');
        return null;
      }

      const user = await updateProfileRequest(data);
      if (user) updateUser(user.data);
      return null;
    },
    [
      confirmPassword,
      email,
      oldPassword,
      password,
      file,
      lastName,
      name,
      updateUser,
    ]
  );

  return (
    <S.Container>
      <S.ImgContainer>
        <AvatarInput
          profile={profile}
          file={file}
          updateAvatar={updateAvatar}
        />
        <S.Name>
          {name} {lastName}
        </S.Name>
        <S.JobTitle>{jobTitle}</S.JobTitle>
      </S.ImgContainer>
      <S.Aside>
        <S.Form onSubmit={handleSubmit}>
          <S.FormTitle>Edite seu perfil</S.FormTitle>
          <S.Header>
            <S.Label htmlFor="name">
              Nome
              <input
                id="name"
                defaultValue={name}
                type="text"
                placeholder="Digite seu nome"
                onBlur={e => {
                  setName(e.target.value);
                  if (e.target.value !== name) {
                    updateName(e.target.value);
                  }
                }}
                maxLength={20}
              />
            </S.Label>
            <S.Label htmlFor="lastName">
              Sobrenome
              <input
                id="lastName"
                defaultValue={lastName}
                type="text"
                placeholder="Digite seu sobrenome"
                onBlur={e => {
                  setLastName(e.target.value);
                  if (e.target.value !== lastName) {
                    updateLastName(e.target.value);
                  }
                }}
                maxLength={30}
              />
            </S.Label>
          </S.Header>
          <S.Label>
            E-mail
            <input
              type="email"
              placeholder="Digite seu e-mail"
              defaultValue={email}
              autoComplete="off"
              onBlur={e => {
                setEmail(e.target.value);
                if (e.target.value !== email) {
                  updateEmail(e.target.value);
                }
              }}
              maxLength={30}
            />
          </S.Label>
          <S.Separator />
          <S.Label htmlFor="oldPassword">
            Senha atual
            <input
              id="oldPassword"
              type="password"
              name="oldpassword"
              defaultValue={oldPassword}
              placeholder="Digite sua senha atual"
              autoComplete="new-password"
              onBlur={e => setOldPassword(e.target.value)}
            />
          </S.Label>
          <S.Label htmlFor="newPassword">
            Nova senha
            <input
              id="newPassword"
              type="password"
              defaultValue={password}
              placeholder="Digite sua nova senha"
              onBlur={e => setPassword(e.target.value)}
            />
          </S.Label>
          <S.PassCheck>
            <li>
              {password.length > 7 ? (
                <MdDone color="var(--green-primary)" />
              ) : (
                <MdClose color="var(--red-primary)" />
              )}
              8 Caracteres
            </li>
            <li>
              {/([ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/g.test(password) ? (
                <MdDone color="var(--green-primary)" />
              ) : (
                <MdClose color="var(--red-primary)" />
              )}
              Caracter especial
            </li>
            <li>
              {/([0-9]+)/g.test(password) ? (
                <MdDone color="var(--green-primary)" />
              ) : (
                <MdClose color="var(--red-primary)" />
              )}
              Número
            </li>
            <li>
              {/([A-Z]+)/g.test(password) ? (
                <MdDone color="var(--green-primary)" />
              ) : (
                <MdClose color="var(--red-primary)" />
              )}
              Letra maiúscula
            </li>
            <li>
              {/([a-z]+)/g.test(password) ? (
                <MdDone color="var(--green-primary)" />
              ) : (
                <MdClose color="var(--red-primary)" />
              )}
              Letra minúscula
            </li>
          </S.PassCheck>
          <S.Label htmlFor="confirmPassword">
            Confirmar nova senha
            <input
              id="confirmPassword"
              type="password"
              defaultValue={confirmPassword}
              placeholder="Confirme sua nova senha"
              style={{
                color: password === confirmPassword ? 'green' : 'red',
              }}
              onBlur={e => setConfirmPassword(e.target.value)}
              maxLength={25}
            />
          </S.Label>
          <S.Footer>
            <Button type="submit" width="48%">
              <p>Salvar senha</p>
            </Button>
          </S.Footer>
        </S.Form>
      </S.Aside>
    </S.Container>
  );
}
