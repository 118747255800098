interface DefaultChartStylesProps {
  tooltipOption?: boolean;
  solid?: boolean;
  stacked?: boolean;
  colors?: string[];
}

interface DefaultChartStylesReturn {
  fill: {
    opacity: number;
    type: string;
    gradient: {
      shade: string | null;
      shadeIntensity: number;
      opacityFrom: number;
      opacityTo: number;
      stops: number[];
    };
  };

  chart: {
    background: string;
    toolbar: {
      show: boolean;
    };
    zoom: {
      enabled: boolean;
    };
    stacked: boolean;
  };

  tooltip: {
    enabled: boolean;
    theme: string | null;
  };

  colors: string[];

  grid: {
    show: boolean;
    borderColor: string;
  };

  dataLabels: {
    enabled: boolean;
  };

  stroke: {
    curve: string;
    width: number;
  };

  theme: {
    mode: string | null;
    palette: string;
  };

  yaxis: {
    tickAmount: number;
  };

  plotOptions: {
    bar: {
      horizontal: boolean;
      columnWidth: string;
      borderRadius: number;
    };
  };
}

const defaultColors = [
  'var(--red-primary)',
  'var(--green-primary)',
  'var(--yellow-primary)',
  'var(--magenta-primary)',
  'var(--cyan-primary)',
  'var(--cyan-secondary)',
];

const DefaultChartStyles = ({
  tooltipOption = false,
  solid = false,
  stacked = false,
  colors = defaultColors,
}: DefaultChartStylesProps): DefaultChartStylesReturn => {
  const theme = window.localStorage.getItem('theme');

  const gradient = {
    shade: theme,
    shadeIntensity: 0.5,
    opacityFrom: theme === 'light' ? 0 : 0.7,
    opacityTo: 0,
    stops: [0, 100],
  };

  const defaultOptions = {
    fill: {
      opacity: 1,
      type: solid ? 'solid' : 'gradient',
      gradient,
    },
    chart: {
      background: 'transparent',
      toolbar: { show: false },
      zoom: { enabled: false },
      stacked,
    },
    tooltip: {
      enabled: tooltipOption,
      theme,
    },
    colors,
    grid: { show: true, borderColor: 'var(--gray)' },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth', width: 3 },
    theme: { mode: theme, palette: 'palette4' },
    yaxis: { tickAmount: 3 },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        borderRadius: 15,
      },
    },
  };

  return defaultOptions;
};

export default DefaultChartStyles;
