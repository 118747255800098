import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow-x: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.8rem;

  border-bottom: 1px solid ${({ theme }) => theme.backgroundPrimary};

  input {
    background: ${({ theme }) => theme.backgroundSecondary} !important;
  }

  .phaseCard {
    margin-right: 1rem;
  }

  .addButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    cursor: pointer;
  }

  .addButton p {
    padding-top: 0.8rem;
  }
`;
