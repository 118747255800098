import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function createChecklistItem(
  phaseId: number,
  checklistName: string
): Promise<void> {
  try {
    await apiV2.post(`phase-checklist-items`, {
      phase_id: phaseId,
      name: checklistName,
    });
  } catch {
    toast.error(
      'Ocorreu algum problema ao criar o checklist, atualize a página e tente novamente :S'
    );
  }
}
