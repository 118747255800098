const selectProjectIndexById = (
  projectId: number,
  globalProjects: Project[]
): number => {
  const projectIndex = globalProjects.findIndex(
    project => project.id === projectId
  );
  return projectIndex;
};

export default selectProjectIndexById;
