import React, { Dispatch, SetStateAction } from 'react';
import * as S from './styles';

interface ModalHeaderProps {
  setModal: Dispatch<SetStateAction<boolean>>;
}

const ModalBackground: React.FC<ModalHeaderProps> = ({
  setModal,
  children,
}) => {
  return (
    <S.Background onClick={() => setModal(false)}>{children}</S.Background>
  );
};

export default ModalBackground;
