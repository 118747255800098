import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Card from './Card';
import LegacyCheckins from './LegacyCheckins';

import api from '../../../../services/api';
import Loading from '../../../../components/Loadings/Loading';

import * as S from './styles';
import { QuarterCard } from './types';
import { getQuartersByClient } from '../../services/getQuartersByClient';
import { verifyIsLastQuarter } from '../../utils/verifyIsLastQuarter';

interface Params {
  id: string;
  redirect: string;
}

interface OldCheckinList {
  id: number;
  created_at: Date;
}

const QuarterSelect: React.FC = () => {
  const { id, redirect } = useParams<Params>();
  const [isLoading, setIsLoading] = useState(true);
  const [checkinList, setCheckinList] = useState<OldCheckinList[]>([]);
  const [quarters, setQuarters] = useState<QuarterCard[]>([]);
  const [clientName, setClientName] = useState('');

  const handleGetCheckins = useCallback(async () => {
    await api.get(`clients/${id}/comments`).then(res => {
      setCheckinList(res.data);
    });

    await api.get(`clients/${id}`).then(res => {
      if (res.data) setClientName(res.data.name);
    });

    const loadQuarters = await getQuartersByClient(id);

    const parsedRedirect = !!Number(redirect);

    verifyIsLastQuarter({
      id,
      quarters: loadQuarters,
      redirect: parsedRedirect,
    });

    setQuarters(loadQuarters);
    setIsLoading(false);
  }, [id, redirect]);

  useEffect(() => {
    handleGetCheckins();
  }, [handleGetCheckins]);

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      <p>{clientName}</p>

      <div className="pageTitle">
        <h1>SELECIONE OS</h1>
        <h1>QUARTERS PASSADOS</h1>
      </div>

      <div className="cards">
        {checkinList.length >= 1 && (
          <LegacyCheckins checkinList={checkinList} />
        )}

        {quarters.map((quarter, i) => {
          return (
            <Card
              key={quarter.quarter.id}
              quarter={quarter}
              quarterNumber={quarters.length - i - 1}
            />
          );
        })}
      </div>
    </S.Container>
  );
};

export default QuarterSelect;
