import React from 'react';

import { Container } from './styles';

export const Button: React.FC<{ text?: string }> = ({
  text,
  children,
  ...rest
}) => {
  return (
    <Container {...rest}>
      {text}
      {children}
    </Container>
  );
};
