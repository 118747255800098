import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
  hr {
    margin: 10px 0;
    border: 1px solid var(--gray);
  }
  textarea {
    margin-top: 15px;
    background: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.backgroundPrimary};
    border-radius: 6px;
    padding: 10px;
    max-width: 100%;
    min-width: 100%;
    min-height: 40px;
    max-height: 400px;
  }
  label {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.text};
  }
  select {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.backgroundPrimary};
    border: none;
    font-weight: bold;
    margin-right: 20px;
    option {
      color: ${({ theme }) => theme.text};
      :disabled {
        color: var(--white);
        background-color: var(--gray);
      }
    }
  }
`;
export const Topic = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  pre {
    padding: 10px;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: initial;
  align-items: center;
  color: ${({ theme }) => theme.text};
  > * {
    margin-right: 5px;
  }

  .avatar {
    border: ${props => {
      if (props.isUser) {
        return '1px solid var(--green-primary)';
      }
      return '1px solid var(--black)';
    }};
    border-radius: 50%;
    padding: 0px;
    width: 36px !important;
    height: 36px !important;
    font-size: 36px;
  }
`;

export const Replies = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Reply = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  pre {
    margin-top: 10px;
    color: ${({ theme }) => theme.text};
  }

  button {
    margin: 5px auto;
    width: 60%;
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    font-weight: bolder;
    font-size: 1.2vw;
    text-align: center;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    :hover {
      filter: brightness(90%);
    }
  }
`;
export const Assisgns = styled.div`
  display: flex;
`;
export const Buttons = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  button {
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    font-weight: bolder;
    font-size: 1.2vw;
    text-align: center;
    :hover {
      filter: brightness(90%);
    }
  }
  #done {
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
  }
  #reply {
    background: var(--green-primary);
    border: 1px solid var(--green-primary);
  }
`;
export const Rating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
  label {
    display: flex;

    flex-direction: column;
    align-items: flex-end;
  }
  > div {
    margin-top: 5px;
  }
`;
