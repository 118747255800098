import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  .reAprove {
    background: var(--red-primary);
  }

  ::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.backgroundSecondary};
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 10px;
  }

  .rdrCalendarWrapper {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;
  }

  .rdrDayNumber span {
    color: ${({ theme }) => theme.text};
  }

  .rdrNextPrevButton {
    background: var(--gray);

    i {
      border-color: transparent var(--white) transparent transparent;
    }
  }
  span + button.rdrNextPrevButton {
    i {
      border-color: transparent transparent transparent var(--white);
    }
  }

  .rdrDateDisplayWrapper {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;
  }

  .rdrDateDisplayItem {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;

    input {
      color: ${({ theme }) => theme.text};
    }
  }

  .rdrMonth {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;
    color: var(--white) !important;
  }
  .rdrMonthPicker {
    color: ${({ theme }) => theme.text} !important;
  }

  .rdrMonthAndYearPickers select {
    color: ${({ theme }) => theme.text} !important ;
  }

  .rdrMonthAndYearPickers option {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;
  }

  .table {
    margin-top: 20px;
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.backgroundSecondary};
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 10px;
    }
  }

  .popup-content .tooltip-content {
    background: var(--gray) !important;
    border: 0 !important;
    box-shadow: 0 !important;
    padding: 0px !important;
  }

  .popUp-tooltip-content {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.backgroundPrimary};
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .yes {
      color: var(--green-primary);
    }

    .no {
      color: var(--red-primary);
    }
  }

  .date {
    flex: 1;
    background: ${({ theme }) => theme.backgroundPrimary};
    width: 15rem;
    height: 1.7rem;
    border: 1px solid var(--gray);
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.text};
    font-size: 0.8rem;
    font-family: Work Sans;
    text-align: center;
    font-style: normal;
    cursor: pointer;
    transition: 0.3s;

    :hover {
      background: var(--red-primary);
      color: var(--white);
    }
  }

  .multi-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    --rmsc-main: ${({ theme }) => theme.backgroundSecondary};
    --rmsc-hover: var(--red-primary);
    --rmsc-bg: ${({ theme }) => theme.backgroundSecondary} !important;
    --rmsc-selected: ${({ theme }) => theme.backgroundSecondary};
    --rmsc-border: ${({ theme }) => theme.backgroundSecondary};
    --rmsc-gray: ${({ theme }) => theme.text};
    --rmsc-p: 8px; /* Spacing */
    --rmsc-radius: 3px; /* Radius */
    --rmsc-h: 33px; /* Height */

    color: ${({ theme }) => theme.text} !important;
    transition: 0.3s !important;
    flex: 1;
    min-width: 20vh;
    font-size: 0.7rem !important;
    cursor: pointer;

    :hover {
      --rmsc-border: var(--red-primary);

      background: var(--red-primary);
    }

    svg {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }

    .dropdown-heading {
      cursor: pointer;
    }
    .panel-content {
      ::-webkit-scrollbar {
        background-color: ${({ theme }) => theme.backgroundSecondary};
        width: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: var(--gray);
        border-radius: 10px;
      }
    }

    .go1084040746 {
      cursor: pointer !important;
    }

    .go606532123 {
      input {
        background: ${({ theme }) => theme.backgroundSecondary};
      }
    }

    header {
      p {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        color: ${({ theme }) => theme.text};
      }
    }

    .header-text {
      margin: 8px 0 0;
      color: ${({ theme }) => theme.text};
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 40px;
    }

    input[type='date'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      flex: 1;
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 2px;
      border: 0;
      padding: 8px 12px;
      font-size: 16px;
      color: ${({ theme }) => theme.text};
      font-family: Arial, Helvetica, sans-serif;
    }

    .hideButton {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      flex: 1;
      background: transparent;
      border-radius: 2px;
      border: 0;
      padding: 0.4rem;
      border-radius: 10%;
      margin-left: 0.3rem;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      flex: 1;
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 2px;
      border: 0;
      padding: 12.2px 12px;
      font-size: 0.7rem;
      color: ${({ theme }) => theme.text};
      max-width: 180px;
      cursor: pointer;
      transition: 0.3s;

      option {
        background: var(--gray);
      }

      :hover {
        background: var(--red-primary);
        color: var(--white);
      }
    }

    .button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      flex: 1;
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 2px;
      border: 0;
      padding: 12.2px 12px;
      font-size: 0.7rem;
      color: ${({ theme }) => theme.text};
      cursor: pointer;
      transition: 0.3s;

      :hover {
        background: var(--red-primary);
        color: var(--white);
      }
    }

    .off-button {
      display: none;
    }

    .options {
      display: flex;
      flex-direction: row;
      color: var(--white);
      margin-bottom: 40px;
      width: 100%;
    }

    .option {
      margin-right: 20px;
    }

    .more-button {
      background: ${({ theme }) => theme.backgroundSecondary};
      align-items: center;
      display: flex;
      padding-right: 5px;
      padding-left: 5px;
      cursor: pointer;
      transition: 0.3s;

      :hover {
        background: var(--red-primary);
        color: var(--white);
      }
    }

    .option-selectors {
      display: flex;
      flex-direction: row;
    }
  }
`;
