/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import api from '../../services/api';

import * as S from './styles';

const plugins = ['image code'];
const toolbar =
  'undo redo | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image ';

class RichTextEditor extends React.Component {
  handleEditorChange = content => {
    this.props.contentToSave(content);
  };

  render() {
    return (
      <S.Container>
        <Editor
          apiKey={`${process.env.REACT_APP_RICHTEXT_API}`}
          initialValue={this.props.initialValue}
          init={{
            height: this.props.height,
            width: this.props.width,
            menubar: false,
            plugins,
            toolbar,
            skin: 'oxide-dark',
            content_css: 'dark',
            images_upload_handler(blobInfo, success, failure) {
              const imageContent = new FormData();

              const file = blobInfo.blob();

              imageContent.append('file', file);

              api
                .post('/files', imageContent)
                .then(res => {
                  success(res.data.url);
                })
                .catch(res => {
                  if (res.error) {
                    failure('Image is too big');
                  }
                });
            },
            content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #707070; color: #f1f3f4; }`,
            visual_table_class: 'my-custom-class',
          }}
          onEditorChange={this.handleEditorChange}
        />
      </S.Container>
    );
  }
}

export default RichTextEditor;
