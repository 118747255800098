/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useCallback } from 'react';
import { OptionsSelect } from '../../../../../components/Inputs/OptionsSelect';

import * as S from './styles';

export default function MultaPage({ functions }) {
  const totalValue = useMemo(() => {
    const objects = functions.data.filter(
      item => !functions.selectedItems.includes(item.id)
    );
    let feeValue = 0;
    objects.forEach(item => {
      feeValue += parseFloat(item.fee_value) * (functions.multaValue / 100);
    });
    return feeValue;
  }, [functions.data, functions.multaValue, functions.selectedItems]);

  const subTotalValue = useMemo(() => {
    const objects = functions.data.filter(
      item => !functions.selectedItems.includes(item.id)
    );
    let feeValue = 0;
    objects.forEach(item => {
      feeValue += parseFloat(item.fee_value);
    });
    return feeValue;
  }, [functions.data, functions.selectedItems]);

  const handleClients = useCallback(() => {
    const clientOptions = [];
    functions.clientList.map(theClient =>
      clientOptions.push({ name: theClient.name, value: theClient.id })
    );

    return clientOptions;
  }, [functions.clientList]);

  return (
    <S.Container>
      <div className="inputs" style={{ gridTemplateColumns: 'repeat(4, 2fr)' }}>
        <div className="input">
          <label htmlFor="client">Cliente</label>
          <OptionsSelect
            id="client"
            placeholder="Selecione o cliente"
            options={handleClients()}
            onChange={functions.setClient}
            search
          />
        </div>

        <div className="input">
          <label htmlFor="vencimento">Vencimento</label>
          <input
            required
            id="vencimento"
            name="vencimento"
            type="date"
            placeholder="Data Vencimento"
            value={functions.endDate}
            onChange={e => {
              functions.setEndDate(e.target.value);
            }}
          />
        </div>

        <div className="input">
          <label htmlFor="multa">Distrato %</label>
          <input
            required
            id="multa"
            name="multa"
            type="number"
            placeholder="30%"
            value={functions.multaValue}
            onChange={e => {
              functions.setMultaValue(e.target.value);
            }}
          />
        </div>

        <div className="input">
          <label htmlFor="value">Valor em reais</label>
          <input
            required
            id="value"
            name="value"
            type="number"
            readOnly
            placeholder={Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(totalValue)}
            style={{ padding: '1px' }}
            value={functions.feeValue}
            onChange={e => {
              functions.setFeeValue(e.target.value);
            }}
          />
        </div>
        <p style={{ left: 0 }}>
          Subtotal:{' '}
          {Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(subTotalValue)}
        </p>
      </div>
      <div className="table">
        <functions.MultaTable
          selectedItems={functions.selectedItems}
          setSelectedItems={functions.setSelectedItems}
          contents={functions.data}
        />
      </div>
      <div className="description">
        <label htmlFor="description">Descrição</label>
        <textarea
          placeholder="Descreva a nova cobrança aqui!"
          id="description"
          name="description"
          cols="40"
          rows="5"
          value={functions.description}
          onChange={e => functions.setDescription(e.target.value)}
        />
      </div>
    </S.Container>
  );
}
