import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

import * as S from './styles';
import PopUp from '..';

interface PopUpConfirmationProps {
  closePopUp: Dispatch<SetStateAction<boolean>>;
  header?: string;
  text: string;
  confirmation: (del: boolean) => Promise<boolean>;
}

const PopUpConfirmation: FC<PopUpConfirmationProps> = ({
  closePopUp,
  header = 'Confirmação de Ação',
  text,
  confirmation,
}) => {
  const [doubleCheck, setDoubleCheck] = useState('');
  const handleSubmit = useCallback(() => {
    confirmation(true);
    closePopUp(false);
  }, [closePopUp, confirmation]);

  return (
    <PopUp closePopUp={closePopUp} header={header} width="40%">
      <S.Form onSubmit={handleSubmit}>
        <pre>{text}</pre>

        <label htmlFor="doubleCheck">
          <span>
            Digite <strong>CONFIRMAR</strong> para completar a ação
          </span>
          <input
            id="doubleCheck"
            type="text"
            value={doubleCheck}
            onChange={e => setDoubleCheck(e.target.value)}
            required
          />
        </label>
        <S.Buttons>
          <button
            type="submit"
            id="confirm"
            disabled={doubleCheck.toLocaleLowerCase() !== 'confirmar'}
          >
            Confirmar
          </button>
          <button
            type="button"
            id="cancel"
            onClick={() => {
              confirmation(false);
              closePopUp(false);
            }}
          >
            Cancelar
          </button>
        </S.Buttons>
      </S.Form>
    </PopUp>
  );
};

export default PopUpConfirmation;
