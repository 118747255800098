import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { format } from 'date-fns';
import { Props } from 'react-apexcharts';
import * as S from './styles';
import { OkrProgress } from '../../../../types';
import getDefaultOptions from '../../../../../../styles/defaultChartStyles';

interface NewChart {
  progress: OkrProgress;
}

const InvestmentChart: React.FC<NewChart> = ({ progress }) => {
  const data = useMemo(() => {
    const datasets: {
      name: string;
      data: number[];
    }[] = [];
    const labels: string[] = [];

    const quarterProgress: number[] = [];
    progress.quarterProgress.forEach(progressNum => {
      const parsedProgress = Number((progressNum * 100).toFixed(2));

      quarterProgress.push(parsedProgress);
    });

    datasets.push({
      name: 'Progresso Do Quarter',
      data: quarterProgress,
    });

    progress.progress.sort((a: { name: string }, b: { name: string }) => {
      return a.name.localeCompare(b.name);
    });

    progress.progress.forEach(okr => {
      datasets.push({
        name: okr.name,
        data: okr.values,
      });
    });
    progress.dates.forEach(date => {
      labels.push(format(new Date(date), 'dd.MM.yyyy'));
    });

    return {
      labels,
      datasets,
    };
  }, [progress]);

  const colors = [
    'var(--white)',
    'var(--red-primary)',
    'var(--yellow-primary)',
    'var(--cyan-primary)',
    'var(--green-primary)',
    'var(--magenta-primary)',
    'var(--orange-secondary)',
  ];

  const defaultOptions = getDefaultOptions({
    tooltipOption: true,
    colors,
  });

  const options: Props['ApexOptions'] = {
    ...defaultOptions,
    yaxis: {
      labels: {
        style: {
          colors: ['#fff'],
          fontSize: '9px',
        },
      },
    },
    xaxis: {
      categories: data.labels,
      axisBorder: { show: false },
      axisTicks: { show: false },

      labels: {
        style: {
          colors: ['#fff'],
          fontSize: '9px',
        },
      },
    },
  };

  return (
    <S.Container>
      <Chart
        options={options}
        series={data.datasets}
        type="area"
        height="100%"
      />
    </S.Container>
  );
};

export default InvestmentChart;
