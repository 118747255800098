import React, { useState, useEffect, useCallback } from 'react';
import { RiSeedlingLine, RiPenNibLine } from 'react-icons/ri';

import { toast } from 'react-toastify';
import { Button, TextInput } from '../../../../../components/Inputs';
import * as S from './styles';
import RichText from '../../../../../components/RichText';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import Responsible from '../../../../../components/Responsible';
import Checklist from '../../../../../components/Checklist';
import { Modal } from '../../../styles';
import MassEditTaskModal from '../MassEditTaskModal';
import useForm from '../../../../../hooks/useForm';
import { Checkbox } from '../../Checkbox';
import { SelectIcon } from '../../SelectIcons';
import { LoadingButton } from '../../LoadingButton';
import { getPhaseChecklistItems } from '../../../services/GetPhaseChecklistItems';
import { editPhase } from '../../../services/EditPhase';
import { deletePhase } from '../../../services/DeletePhase';
import { createChecklistItem } from '../../../services/CreateChecklistItem';
import { deleteChecklistItem } from '../../../services/DeleteChecklistItem';
import { useTasks } from '../../../../../contexts/TasksContext';
import { SetPhases } from '../../../utils/SetPhases';

type CheckItem = {
  name: string;
  marked: boolean;
};

interface useFormHookProps {
  form: { phaseName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

const PhaseEdit: React.FC<{
  handleClose: () => void;
  phase: Phase;
  phases: Phase[];
  groupId: number;
  projectId: number;
}> = ({ handleClose, phase, phases, groupId, projectId }) => {
  const { projects, setProjects } = useTasks();
  const { form, onChange }: useFormHookProps = useForm({
    phaseName: phase.name,
  });
  const [users, setUsers] = useState<
    {
      id?: number;
    }[]
  >([{}]);
  const [content, setContent] = useState('');
  const [checkRemove, setCheckRemove] = useState(false);
  const [checks, setChecks] = useState<CheckItem[]>([]);
  const [selectedIcon, setSelectedIcon] = useState<string>(phase!.icon_name!);
  const [openModal, setOpenModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleChangeModalVisibility = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'outside-modal') handleChangeModalVisibility();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };
  const setChecksInfo = useCallback(async () => {
    const newChecks = await getPhaseChecklistItems(phase.id);

    setChecks(newChecks);
  }, [phase.id]);

  const getPhaseUsers = useCallback(() => {
    const phaseUsers: { id: number; name: string; last_name: string }[] = [];
    if (phase.users) {
      phase.users.forEach((user: User) => {
        phaseUsers.push({
          id: user.id,
          name: user.name,
          last_name: user.last_name,
        });
      });
    }
    setUsers(phaseUsers);
  }, [phase.users]);

  useEffect(() => {
    getPhaseUsers();
    setChecksInfo();
  }, [setChecksInfo, getPhaseUsers]);

  const handleEditPhase = useCallback(async () => {
    setIsDisable(true);
    setSaving(true);

    const editedPhase = await editPhase(
      phase.id,
      form.phaseName!,
      content,
      selectedIcon,
      checkRemove,
      users
    );

    const phasesUpdate = phases.filter(phaseItem => phaseItem.id !== phase.id);

    phasesUpdate.splice(editedPhase.order! - 1, 0, editedPhase);
    const newProjects = SetPhases(
      [...phasesUpdate],
      projects,
      projectId,
      groupId
    );

    setProjects([...newProjects]);

    toast.success('Fase editada com sucesso!');
    handleClose();

    setIsDisable(false);
    setSaving(false);
  }, [
    checkRemove,
    content,
    form.phaseName,
    handleClose,
    phase.id,
    phases,
    selectedIcon,
    groupId,
    projectId,
    projects,
    setProjects,
    users,
  ]);

  const editPhaseOnBlur = useCallback(async () => {
    const editedPhase = await editPhase(
      phase.id,
      form.phaseName!,
      content,
      selectedIcon,
      checkRemove,
      users
    );
    const phasesUpdate = phases.filter(phaseItem => phaseItem.id !== phase.id);

    phasesUpdate.splice(editedPhase.order! - 1, 0, editedPhase);
    const newProjects = SetPhases(
      [...phasesUpdate],
      projects,
      projectId,
      groupId
    );

    setProjects([...newProjects]);

    toast.success('Fase editada com sucesso!');
  }, [
    checkRemove,
    content,
    form.phaseName,
    phase.id,
    phases,
    selectedIcon,
    groupId,
    projectId,
    projects,
    setProjects,
    users,
  ]);

  const handleDeletePhase = useCallback(async () => {
    setIsDisable(true);
    setDeleting(true);

    await deletePhase(phase.id);

    const newProjects = SetPhases(
      phases.filter(phaseItem => phaseItem.id !== phase.id),
      projects,
      projectId,
      groupId
    );
    setProjects([...newProjects]);
    toast.success('Fase excluída com sucesso!');
    handleClose();

    setIsDisable(false);
    setDeleting(false);
  }, [
    handleClose,
    phase.id,
    phases,
    groupId,
    projectId,
    projects,
    setProjects,
  ]);

  const postCheckItem = useCallback(
    async (checkContent: string) => {
      await createChecklistItem(phase.id, checkContent);

      toast.success('Check item salvo com sucesso!');
    },
    [phase.id]
  );

  const deleteCheckItem = useCallback(async (id: number | undefined) => {
    await deleteChecklistItem(id);

    toast.success('Check item excluído com sucesso. Aguarde!');
  }, []);

  return (
    <S.Wrapper>
      <TaskCard width="23rem" close={handleClose}>
        <header>
          <RiSeedlingLine
            className="icon-phase"
            size={21}
            color="var(--red-primary)"
          />
          <S.InputWrapper>
            <TextInput
              name="phaseName"
              onChange={handleInputChange}
              onBlur={() => editPhaseOnBlur()}
              value={form.phaseName}
              background="none"
              height="1.5"
              placeholder="Editar fase"
            />
          </S.InputWrapper>
          <RiPenNibLine className="icon-phase" size={16} color="var(--gray)" />
        </header>
        <main>
          <S.Content>
            <Button id="mass-edit-button" onClick={handleChangeModalVisibility}>
              <p>Fazer edição em massa</p>
            </Button>
            <div className="input" role="button" tabIndex={0}>
              <p>Responsável</p>

              <Responsible users={users} setUsers={setUsers} />
            </div>

            <S.RemoveResponsibles role="button" tabIndex={0}>
              <Checkbox
                name="remove"
                finished={checkRemove}
                setFinished={setCheckRemove}
              />
              <p>Remover Responsáveis atuais</p>
            </S.RemoveResponsibles>

            <div className="input">
              <p style={{ marginBottom: '0.6rem' }}>Checklist</p>
              <Checklist
                checks={checks}
                setChecks={setChecks}
                postCheckItem={postCheckItem}
                deleteCheckItem={deleteCheckItem}
              />
            </div>

            <div className="input">
              <p>Selecione o Ícone</p>
              <SelectIcon
                selectedIcon={selectedIcon}
                setSelectedIcon={setSelectedIcon}
              />
            </div>

            <div className="input" onBlur={() => handleEditPhase()}>
              <p>Descrição do grupo</p>

              <RichText
                height={200}
                width={300}
                initialValue={phase.desc}
                contentToSave={(contentItem: string) => {
                  setContent(contentItem);
                }}
              />
            </div>
          </S.Content>
        </main>
        <footer>
          {deleting ? (
            <LoadingButton width="70%" height="2.1rem" />
          ) : (
            <Button
              id="delete-button"
              disabled={!!(phase.order === 1)}
              style={{ opacity: `${phase.order === 1 ? 0.2 : 1}` }}
              onClick={() => handleDeletePhase()}
            >
              <p>Deletar</p>
            </Button>
          )}
          {saving ? (
            <LoadingButton width="100%" height="2.1rem" />
          ) : (
            <Button
              id="confirm-button"
              disabled={isDisable}
              onClick={() => handleEditPhase()}
            >
              <p>Confirmar</p>
            </Button>
          )}
        </footer>
      </TaskCard>
      {openModal && (
        <Modal id="outside-modal" onClick={handleOutsideClick}>
          <MassEditTaskModal
            type="phase_id"
            id={phase.id}
            handleClose={handleChangeModalVisibility}
          />
        </Modal>
      )}
    </S.Wrapper>
  );
};

export default PhaseEdit;
