import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-width: 7rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 0.4rem;
  text-align: center;
  margin-right: 1rem;

  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.backgroundSecondary};

  .doughnut-chart {
    padding-top: 1rem;
    width: 3rem;
    fill: ${({ theme }) => theme.text};
  }

  p {
    font-size: 0.8rem;
  }
`;

export const TooltipArea = styled.div`
  width: 100%;
  position: relative;
`;
