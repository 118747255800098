import React, { useState, useCallback, useEffect, useRef } from 'react';
import { RiArrowDownLine, RiArrowUpLine } from 'react-icons/ri';
import useDraggable from './useDraggable';

import apiV2 from '../../../../../services/apiV2';

import * as S from './styles';

interface NewFinalSlide {
  oldInfo: {
    considerations: string;
    actions_for_next_week_v4: string;
    actions_for_next_week_client: string;
  };
  draftId: number;
  presentation?: boolean;
  clientName?: string;
}

const Observations: React.FC<NewFinalSlide> = ({
  oldInfo,
  draftId,
  presentation = false,
  clientName = '',
}) => {
  const [open, setOpen] = useState(false);
  const obsRef = useRef<HTMLDivElement>(null);
  useDraggable(obsRef);
  const [requestText, setRequestText] = useState('');
  const [considerations, setConsiderations] = useState(oldInfo.considerations);
  const [actionsV4, setActionsV4] = useState(oldInfo.actions_for_next_week_v4);
  const [actionsClient, setActionsClient] = useState(
    oldInfo.actions_for_next_week_client
  );

  useEffect(() => {
    setConsiderations(oldInfo.considerations);
    setActionsV4(oldInfo.actions_for_next_week_v4);
    setActionsClient(oldInfo.actions_for_next_week_client);
  }, [oldInfo]);

  const handleSubmit = useCallback(async () => {
    if (
      considerations !== oldInfo.considerations ||
      actionsV4 !== oldInfo.actions_for_next_week_v4 ||
      actionsClient !== oldInfo.actions_for_next_week_client
    ) {
      setRequestText('Salvando...');
      await apiV2
        .put('/checkinDraft', {
          id: draftId,
          considerations,
          actions_for_next_week_v4: actionsV4,
          actions_for_next_week_client: actionsClient,
        })
        .then(() => {
          setRequestText('Salvo!');
        })
        .catch(() => {
          setRequestText('Falha ao atualizar!');
        });
    }
  }, [considerations, actionsV4, actionsClient, draftId, oldInfo]);

  useEffect(() => {
    const timeoutId = setTimeout(() => handleSubmit(), 1000);
    return () => clearTimeout(timeoutId);
  }, [handleSubmit, considerations, actionsV4, actionsClient]);

  return open ? (
    <S.Container ref={obsRef}>
      <header>
        <div className="text">
          <h1>OBSERVAÇÕES</h1>
          <h1>CHECKIN</h1>
        </div>
        <RiArrowDownLine onClick={() => setOpen(!open)} size={16} />
      </header>

      <S.Card>
        <div className="input">
          <p>Considerações da reunião</p>
          <textarea
            value={considerations}
            onChange={e => setConsiderations(e.target.value)}
            required
            placeholder="Digite aqui"
            disabled={presentation}
          />
        </div>

        <div className="input">
          <p>Ações da próxima semana {clientName}</p>
          <textarea
            value={actionsClient}
            onChange={e => setActionsClient(e.target.value)}
            required
            placeholder="Digite aqui"
            disabled={presentation}
          />
        </div>

        <div className="input">
          <p>Ações da próxima semana</p>
          <textarea
            value={actionsV4}
            onChange={e => setActionsV4(e.target.value)}
            required
            placeholder="Digite aqui"
            disabled={presentation}
          />
        </div>

        <p style={{ marginBottom: '0.5rem' }}>{requestText}</p>
      </S.Card>
    </S.Container>
  ) : (
    <S.Container
      ref={obsRef}
      style={{ background: 'var(--red-primary)', cursor: 'grab' }}
    >
      <div className="up-button">
        <p style={{ color: 'var(--white)' }}>Observações</p>
        <RiArrowUpLine
          color="var(--white)"
          onClick={() => setOpen(!open)}
          size={12}
        />
      </div>
    </S.Container>
  );
};

export default Observations;
