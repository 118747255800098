import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import OkrsAndKrs from '../../components/KrsAndOkrs';

import CalendarPopup from './CalendarPopup';

import * as S from './styles';

import api from '../../../../services/api';
import apiV2 from '../../../../services/apiV2';
import Loading from '../../../../components/Loadings/Loading';
import { useAuth } from '../../../../contexts/AuthContext';

import { Params, Okr } from '../../types';

const VSelect: React.FC = () => {
  const { id: userId } = useAuth().user;
  const [selectedV, setSelectedV] = useState('v1');
  const { id } = useParams<Params>();
  const [lastQuarter, setLastQuarter] = useState(new Date());
  const [hasLastQuarter, setHasLastQuarter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clientName, setClientName] = useState('');
  const [okrs, setOkrs] = useState<Okr[]>([]);
  const vOkr = useMemo(() => {
    const today = new Date();
    today.setMonth(today.getMonth() + 1);

    return [
      {
        id: 0,
        status: '',
        name: 'Encontrar indicadores do processo de vendas.',
        is_active: false,
        due_date: String(new Date(today)),
        created_at: String(new Date()),
        updated_at: String(new Date()),
        client_id: Number(id),
        krs: [
          {
            id: 1,
            name: 'ROI LTV do projeto.',
            is_active: true,
            value: 0,
            is_the_lesser_the_better: false,
            target_goal: null,
            goal: 100,
            created_at: String(new Date()),
            updated_at: String(new Date()),
            users_id: [{ id: userId, name: '' }],
          },
          {
            id: 2,
            name: 'ROI Direto do projeto.',
            is_active: true,
            value: 0,
            is_the_lesser_the_better: false,
            target_goal: null,
            goal: 100,
            created_at: String(new Date()),
            updated_at: String(new Date()),
            users_id: [{ id: userId, name: '' }],
          },
          {
            id: 3,
            name: 'Investir toda a verba de mídia.',
            is_active: true,
            value: 0,
            is_the_lesser_the_better: false,
            target_goal: null,
            goal: 600000,
            created_at: String(new Date()),
            updated_at: String(new Date()),
            users_id: [{ id: userId, name: '' }],
          },
        ],
      },
    ];
  }, [id, userId]);

  useEffect(() => {
    api.get(`clients/${id}`).then(res => {
      if (res.data) setClientName(res.data.name);
    });

    apiV2.get(`quarter/byClient/${id}`).then(res => {
      setIsLoading(false);
      if (res.data.quarter) {
        setLastQuarter(res.data[0].quarter.next_quarter);
        setHasLastQuarter(true);
      }
    });
  }, [id]);

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      <div className="topBar">
        <p>{clientName}</p>
        {selectedV !== 'v1' && okrs.length === 0 ? (
          <p>Crie pelo menos uma Okr</p>
        ) : (
          <CalendarPopup
            clientId={Number(id)}
            newDate={lastQuarter}
            hasLast={hasLastQuarter}
            vselected={selectedV}
            okrs={selectedV === 'v1' ? vOkr : []}
          />
        )}
      </div>
      <header>
        <div className="pageTitle">
          <h1>SELECIONE O</h1>
          <h1>V DO CLIENTE</h1>
        </div>

        <div className="steps">
          <S.Step
            selected={selectedV === 'v1'}
            onClick={() => setSelectedV('v1')}
          >
            <h1>Step V1</h1>
          </S.Step>

          <S.Step
            selected={selectedV === 'v2'}
            onClick={() => setSelectedV('v2')}
          >
            <h1>Step V2</h1>
          </S.Step>

          <S.Step
            selected={selectedV === 'v3'}
            onClick={() => setSelectedV('v3')}
          >
            <h1>Step V3</h1>
          </S.Step>

          <S.Step
            selected={selectedV === 'v4'}
            onClick={() => setSelectedV('v4')}
          >
            <h1>Step V4</h1>
          </S.Step>
        </div>
      </header>
      <main>
        <OkrsAndKrs
          predefinedOkr={selectedV === 'v1' ? vOkr[0] : undefined}
          receiveOkrs={setOkrs}
          clientId={Number(id)}
        />
      </main>
    </S.Container>
  );
};

export default VSelect;
