import React, { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import * as S from './styles';
import Tooltip from '../../../../../../components/ChartCard/Tooltip';

interface DoughnutChartCard {
  title: string;
  color: string;
  percent: number;
  users: User[];
}

const DoughnutChartCard: React.FC<DoughnutChartCard> = ({
  title,
  color,
  percent,
  users,
}: DoughnutChartCard) => {
  const [visible, setVisible] = useState(false);
  const retitle = title.split(' ');

  return (
    <S.Container>
      <p>
        {retitle[0]} <br /> {retitle[1]} {retitle[2] && `${retitle[2]}`}
      </p>
      <S.TooltipArea aria-hidden="true" onClick={() => setVisible(!visible)}>
        <PieChart
          className="doughnut-chart"
          data={[
            { title: 'One', value: percent, color: `${color}` },
            { title: 'Two', value: 100 - percent, color: 'var(--gray)' },
          ]}
          lineWidth={15}
          label={() => `${percent || 0}%`}
          labelStyle={{
            fontSize: '25px',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
        {visible && users?.length > 0 && <Tooltip users={users} />}
      </S.TooltipArea>
    </S.Container>
  );
};

export default DoughnutChartCard;
