/* eslint-disable react/require-default-props */
import { Dispatch, SetStateAction, useCallback } from 'react';
import { RiPaletteFill, RiCloseLine } from 'react-icons/ri';

import { Button } from '../../../../../components/Inputs';

import { deleteTag } from '../../../services/DeleteTag';
import * as S from './styles';

type TooltipProps = {
  id: number;
  type?: string;
  typeId?: number;
  setTooltipArea: Dispatch<SetStateAction<boolean>>;
  setBackgroundColor: Dispatch<SetStateAction<string>>;
  setTagsList: Dispatch<SetStateAction<ITag[]>>;
};

export const Tooltip = ({
  id,
  type,
  typeId,
  setTooltipArea,
  setBackgroundColor,
  setTagsList,
}: TooltipProps): JSX.Element => {
  const handleDeleteTag = useCallback(() => {
    setTagsList(state => state.filter(tagItem => tagItem.id !== id));
    if (type && typeId) {
      deleteTag(id, type, typeId);
    }
  }, [id, setTagsList, type, typeId]);

  const handleSelectColor = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      setBackgroundColor(`${e.target.value}`);
    },
    [setBackgroundColor]
  );

  const closeWrapper = useCallback(() => {
    setTooltipArea(false);
  }, [setTooltipArea]);

  return (
    <S.Wrapper>
      <div>
        <form onChange={handleSelectColor}>
          <label htmlFor="favcolor">
            <p>Cor da Tag</p>
            <RiPaletteFill
              id="picker-icon"
              color="var(--red-primary)"
              size={22}
            />

            <input type="color" id="favcolor" name="favcolor" />
          </label>
        </form>
        <button type="button" onClick={handleDeleteTag}>
          <p>Remover Tag</p>
          <RiCloseLine id="picker-icon" color="var(--red-primary)" size={22} />
        </button>

        <Button onClick={closeWrapper}>Fechar</Button>
      </div>
    </S.Wrapper>
  );
};
