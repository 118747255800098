import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  font-size: 0.8rem;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0rem;
  }
  header svg {
    cursor: pointer;
    min-height: 16px;
    min-width: 16px;
  }
  header .name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.4rem;
    word-break: break-all;
  }
  header .name p {
    letter-spacing: 0.36px;
    font-weight: bold;
    margin-right: 0.4rem;
    font-size: 1.2rem;
  }
  main {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  main .textHolder {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
  }
  main .textHolder:last-child {
    margin-bottom: 0rem;
  }
  main .textHolder .answer {
    letter-spacing: 0.28px;
    margin-top: 0.1rem;
    word-break: break-all;
  }
  .button {
    margin-top: 0.5rem;
  }
  @media (max-width: 800px) {
    margin-left: 0rem;
    margin-top: 1rem;
  }
`;

export const FinalValueHolder = styled.div`
  color: var(--red-primary);
  display: flex;
  align-items: center;
  p:last-child {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 0.2rem;
  }
`;

export const Title = styled.p`
  letter-spacing: 0.36px;
  font-weight: bold;
  margin-bottom: 0.2rem;
  margin-right: 0.3rem;
  font-size: 1rem;
`;

export const ValueHolder = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
  font-size: 1.2rem;
`;

export const Values = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-left: 1rem;
  border-left: 2px solid ${({ theme }) => theme.backgroundTerciary};
  @media (max-width: 1830px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media (max-width: 1500px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
