import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  overflow-y: auto;
  border-radius: 8px;
  font-size: 0.8rem;

  table {
    margin-top: 0.4rem;
    border: 2px solid var(--red-primary);
    border-radius: 8px;
    width: 100%;
  }

  tr {
    border: 0;
  }

  th {
    font-weight: 400;
    border: 0;
  }

  td {
    border: 0;
  }

  button {
    width: 100%;
    border: 0;
    background: var(--red-primary);
    color: ${({ theme }) => theme.text};
    padding: 0.1rem;
    border-radius: 8px;
  }
`;

export const Title = styled.h1`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
  text-align: center;
  color: ${({ theme }) => theme.text};
`;
