import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  .animation {
    animation-name: loading;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    @keyframes loading {
      0% {
        background-color: var(--gray);
      }
      10% {
        background-color: var(--gray-text);
      }
      20% {
        background-color: var(--gray);
      }
      30% {
        background-color: var(--gray-text);
      }
      40% {
        background-color: var(--gray);
      }
      50% {
        background-color: var(--gray-text);
      }
      60% {
        background-color: var(--gray);
      }
      70% {
        background-color: var(--gray-text);
      }
      80% {
        background-color: var(--gray);
      }
      90% {
        background-color: var(--gray-text);
      }
      100% {
        background-color: var(--gray);
      }
    }
  }
`;

export const Loading = styled.div`
  width: 95%;
  height: 1rem;
  border-radius: 0.2rem;
  background-color: var(--gray);
  opacity: 0.2;
  margin: 1rem 0;
`;
