import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function changeTaskOrder(
  taskId: number,
  phaseId: number,
  newOrder: number
): Promise<editTask> {
  try {
    const response = await apiV2.put(`tasks`, {
      id: taskId,
      phase_id: phaseId,
      order: newOrder,
    });

    const task = response.data;

    return task;
  } catch {
    toast.error(
      'Ocorreu algum problema, atualize a página e tente novamente 😕'
    );

    return {} as editTask;
  }
}
