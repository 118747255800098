import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-self: flex-end;
  align-items: center;
  width: max-content;
  cursor: pointer;
  margin: 2vh 0 1vh;
  font-size: 0.625rem;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.text};
  font-family: 'Poppins', sans-serif;
  font-size: 0.625rem;
  letter-spacing: 1.8px;
  transition: all 0.3s;

  svg {
    margin-right: 10px;
    font-size: 1rem;
    color: var(--red-primary);
  }
  &:hover {
    filter: brightness(80%);
  }
`;
