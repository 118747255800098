import React, { useCallback, useEffect, useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import Responsible from '../../../../../../../components/Responsible';

import { Container } from './styles';
import Phases from './Phase';
import apiV2 from '../../../../../../../services/apiV2';

interface GroupProps {
  group: Group;
}

const GroupItem: React.FC<GroupProps> = ({ group }) => {
  const [phases, setPhases] = useState<Phase[]>([]);
  const [name, setName] = useState(group.name);
  const [description, setDescription] = useState(group.desc);
  const [deadline, setDeadline] = useState('29.Setembro.2020');
  const [users, setUsers] = useState([{}]);
  const [showPhases, setShowPhases] = useState(false);

  const getPhases = useCallback(async () => {
    await apiV2.get(`phases/${group.id}`).then(res => setPhases(res.data));
  }, [group.id]);

  useEffect(() => {
    getPhases();
  }, [getPhases]);

  return (
    <>
      <Container>
        <div className="previewItem">
          <p>Grupo de entrega</p>
          <textarea
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="previewResponsible">
          <p>Responsaveis</p>
          <Responsible users={users} setUsers={setUsers} />
        </div>

        <div className="previewItem">
          <p>Prazo</p>
          <input
            value={deadline}
            onChange={e => {
              setDeadline(e.target.value);
            }}
          />
        </div>

        <div className="previewItem">
          <p>Descrição</p>
          <textarea
            value={description}
            onChange={e => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <RiArrowDropDownLine
          style={{ transform: showPhases ? 'rotate(180deg)' : 'rotate(0)' }}
          onClick={() => setShowPhases(!showPhases)}
          size={21}
        />
      </Container>
      {showPhases && <Phases phases={phases} />}
    </>
  );
};

export default GroupItem;
