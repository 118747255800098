import { FC } from 'react';

import { motion } from 'framer-motion';

import { LoadingAvatar, Ball } from './styles';

const LoadingComents: FC = () => {
  return (
    <>
      <motion.div
        animate={{
          opacity: [0.4, 0.4, 0, 0.4, 0.4],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          times: [0, 0.3, 0.6, 0.8, 1],
          loop: Infinity,
        }}
        className="comment"
      >
        <div className="commentData">
          <LoadingAvatar />
          <div
            style={{
              width: '90px',
              height: '10px',
              background: 'var(--gray)',
              borderRadius: '8px',
              marginTop: '5px',
              marginLeft: '8px',
            }}
          />
        </div>
        <div className="timing">
          <Ball />
        </div>
      </motion.div>

      <motion.div
        animate={{
          opacity: [0.4, 0.4, 0, 0.4, 0.4],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          times: [0, 0.3, 0.6, 0.8, 1],
          loop: Infinity,
        }}
        className="comment"
        style={{ marginTop: '1rem' }}
      >
        <div className="commentData">
          <LoadingAvatar />
          <div
            style={{
              width: '90px',
              height: '10px',
              background: 'var(--gray)',
              borderRadius: '8px',
              marginTop: '5px',
              marginLeft: '8px',
            }}
          />
        </div>
        <div className="timing">
          <Ball />
        </div>
      </motion.div>
    </>
  );
};

export default LoadingComents;
