import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { FiLayers } from 'react-icons/fi';
import {
  RiArrowUpSLine,
  RiArrowDownSLine,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import * as S from './styles';
import { Tag } from '../../../../components/Tag';
import { useTags } from '../../../../../../contexts/TagsContext';
import { Responsibles } from '../../../../components/Responsibles';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { AddNew } from '../../../../components/AddNew';
import { ItenTypes } from '../../../../types';
import PhaseBoard from '../PhaseBoard';
import { PhaseLoading } from '../Loadings';
import { Modal } from '../../../../styles';
import GroupEdit from '../../../../components/Modals/GroupEdit';
import NewPhase from '../../../../components/Modals/NewPhase';
import useProgress from '../../../../../../hooks/useProgress';
import apiV2 from '../../../../../../services/apiV2';
import { PopupText } from '../../../../components/PopupText';
import { getPhases } from '../../../../services/GetPhases';
import { useTasks } from '../../../../../../contexts/TasksContext';
import selectProjectIndexById from '../../../../utils/SelectProjectIndexById';
import { setGroups } from '../../../../utils/SetGroups';

type DragItem = {
  index: number;
  group: Group;
  name: string;
  type: string;
};

interface GroupCardProps {
  group: Group;
  projectId: number;
  move: (from: number, to: number, type: string, group: Task | Group) => void;
  index: number;
  groups: Group[];
}

export const GroupCard = ({
  group,
  projectId,
  move,
  index,
  groups,
}: GroupCardProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const { projects, setProjects, query } = useTasks();
  const { progress, colors } = useProgress();
  const [phaseArea, setPhaseArea] = useState(false);
  const [dataArrived, setDataArrived] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();

  useEffect(() => {
    setTags(() => (group.tags ? group.tags : []));
  }, [group]);

  const periodProgressPercent = useMemo(
    () => progress(group.created_at, group.due_date),
    [group.created_at, group.due_date, progress]
  );
  const periodProgressTitle = useMemo(
    () =>
      group.due_date
        ? format(new Date(group.due_date), 'dd.MMMM.yyyy', {
            locale: ptBR,
          }).toString()
        : 'Sem prazo',
    [group.due_date]
  );

  const progressBarsColors = useMemo(
    () => colors(group.due_date, group.finished_at),
    [colors, group.finished_at, group.due_date]
  );

  const progressTitle = useMemo(
    () => `${group.progress ? Math.round(group.progress * 100) / 100 : 0}%`,
    [group.progress]
  );

  const handleOpenModal = (type: string): void => {
    type === 'new'
      ? setOpenModalNew(!openModalNew)
      : setOpenModalEdit(!openModalEdit);
  };

  const handleCloseModal = (): void => {
    setOpenModalEdit(false);
    setOpenModalNew(false);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') {
      setOpenModalEdit(false);
      setOpenModalNew(false);
    }
  };

  const projectIndex = selectProjectIndexById(projectId, projects);

  const setPhasesInfo = useCallback(async () => {
    const newPhases = await getPhases(group.id, query);

    if (newPhases === []) return;

    const newGlobalProjects = cloneDeep(projects);

    if (projectIndex === -1) return;

    const selectedProject = newGlobalProjects[projectIndex];

    if (!selectedProject.groups) return;

    selectedProject.groups[index].phases = newPhases;

    setProjects(() => newGlobalProjects);
    setDataArrived(true);
  }, [group.id, projects, index, projectIndex, setProjects, query]);

  const handleOpenPhase = (): void => {
    if (!phaseArea) {
      setPhasesInfo();
    }
    setPhaseArea(!phaseArea);
  };

  const [{ isDragging }, drag] = useDrag({
    item: { index, group, name: 'Group Card', type: ItenTypes.GROUP },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItenTypes.GROUP,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = (item as DragItem)?.index;
      const hoverIndex = index;
      const groupSelected = (item as DragItem)?.group;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref?.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor?.getClientOffset();

      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      move(dragIndex, hoverIndex, 'group', groupSelected);

      const itemIndex = (
        receveidItem: DragObjectWithType,
        hoverIdx: number
      ): number => {
        const itemAny = receveidItem as DragItem;
        itemAny.index = hoverIdx;
        return itemAny.index;
      };

      itemIndex(item, hoverIndex);
    },
  });
  const restoreGroup = useCallback(
    async (groupToRestore: Group) => {
      await apiV2
        .post(`groups/undelete/${groupToRestore.id}`)
        .then(() => {
          const groupUndeleted = {
            ...groupToRestore,
            was_removed: false,
          };

          const groupsUpdate = groups.filter(
            groupItem => groupItem.id !== groupToRestore.id
          );

          groupsUpdate.splice(groupToRestore.order! - 1, 0, groupUndeleted);
          const newProjects = setGroups(groupsUpdate, projects, projectIndex);
          setProjects(() => newProjects);
          toast.success('Grupo restaurado com sucesso');
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            toast.error(
              'Ocorreu algum problema, atualize a página e tente novamente 😕'
            );
          }
        });
    },
    [groups, projectIndex, projects, setProjects]
  );

  drag(drop(ref));
  return (
    <>
      <S.Wrapper ref={ref} isDragging={isDragging}>
        <S.Container className="drag">
          {tags && (
            <S.TagsArea>
              {tags.map((tag: ITag, indexOfTag) => {
                if (indexOfTag >= 10) {
                  return false;
                }
                return (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    type="group_id"
                    typeId={group.id}
                    setTagsList={setTags}
                    showLabel={show}
                    setShowLabel={handleShowTags}
                  />
                );
              })}
            </S.TagsArea>
          )}
          <S.Main>
            <S.Content id="name" className="grid-item">
              <div>
                <FiLayers
                  className="icon"
                  size={20}
                  color="var(--red-primary)"
                />
                <h5>
                  Grupo
                  <br /> de <br />
                  Entrega
                </h5>
              </div>

              <button
                id="container-title"
                data-testid="group-btn"
                onClick={handleOpenPhase}
                type="button"
              >
                <PopupText popUpText={group.name}>
                  {group.name.length > 27
                    ? `${group.name.substring(0, 20)}...`
                    : group.name}
                </PopupText>
              </button>

              <FaRegEdit
                id="edit-button"
                onClick={() => handleOpenModal('edit')}
                size={14}
              />
            </S.Content>

            <div className="grid-item">
              <p>|</p>
              {group.was_removed ? (
                <S.RestoreGroup>
                  <button
                    type="button"
                    title={group.name}
                    onClick={() => restoreGroup(group)}
                  >
                    <RiDeleteBin2Line size={14} color="var(--red-primary)" />
                    <p id="restore-label">Restaurar Grupo</p>
                  </button>
                </S.RestoreGroup>
              ) : (
                <S.Content id="managers">
                  <Responsibles users={group.users} />
                </S.Content>
              )}
              <p>|</p>
            </div>

            <div id="period" className="grid-item">
              <ProgressDisplay
                width="95%"
                height="2rem"
                borderRadius="0.5rem"
                percentage={periodProgressPercent}
                title={periodProgressTitle}
                colorBar={progressBarsColors.dueDateColor}
              />

              <p>|</p>
            </div>

            <div id="progress" className="grid-item">
              <ProgressDisplay
                width="95%"
                height="2rem"
                borderRadius="0.5rem"
                title={progressTitle}
                percentage={group.progress}
                colorBar={progressBarsColors.progressColor}
              />

              <p>|</p>
            </div>

            <S.Content id="add-new">
              <AddNew
                labelText="Adicionar nova fase"
                onClick={() => handleOpenModal('new')}
              />
              {phaseArea ? (
                <button type="button" onClick={handleOpenPhase}>
                  <RiArrowUpSLine className="icon" size={20} />
                </button>
              ) : (
                <button type="button" onClick={handleOpenPhase}>
                  <RiArrowDownSLine className="icon" size={20} />
                </button>
              )}
            </S.Content>
          </S.Main>
        </S.Container>
        <S.StickerColor />
      </S.Wrapper>
      {phaseArea && (
        <>
          {dataArrived && (
            <>
              {group.phases && group.phases.length ? (
                <PhaseBoard
                  phases={group.phases}
                  phaseArea={phaseArea}
                  projectId={projectId}
                  groupId={group.id}
                />
              ) : (
                <p
                  style={{
                    alignSelf: 'center',
                    margin: '1rem 0',
                  }}
                >
                  Não há fase
                </p>
              )}
            </>
          )}

          {!dataArrived && <PhaseLoading />}
        </>
      )}

      {openModalEdit && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <GroupEdit
            group={group}
            projectId={projectId}
            handleClose={handleCloseModal}
            tags={tags}
          />
        </Modal>
      )}
      {openModalNew && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NewPhase
            groupId={group.id}
            projectId={projectId}
            handleClose={handleCloseModal}
            phases={group.phases}
          />
        </Modal>
      )}
    </>
  );
};
