import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getTags(id: string): Promise<ITag[]> {
  try {
    const response = await apiV2.get(`tags/${id}`);

    const tags = response.data;

    return tags;
  } catch {
    toast.error(
      'Ocorreu algum ao carregar as tags, atualize a página e tente novamente :S'
    );
    return [];
  }
}
