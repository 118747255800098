import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;
  justify-content: space-between;
  transition: 0.3s;

  p {
    font-size: 1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.text};
  }

  a {
    text-decoration: none !important;
  }

  .oldCheckin {
    width: 100%;
    margin-bottom: 1rem;

    border-radius: 8px;
    padding: 0.5rem 1rem;
    background: ${({ theme }) => theme.backgroundTerciary};
  }

  .oldCheckin header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .oldCheckin svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }

  main {
    margin-top: 0.5rem;
  }

  main .comment {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }

  main .comment p:last-child {
    font-weight: normal;
  }
`;
