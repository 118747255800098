import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import apiV2 from '../../../../../services/apiV2';

import { useBlock } from '../../../../../contexts/HandleCheckinBlock';

import * as S from './styles';

import { TextInput } from '../../../../../components/Inputs';

interface RequiredCards {
  checkinId: number;
  oldTopCards: {
    investment: number;
    revenue: number;
    lifetime_in_months: number;
    client_margin: number;
  };
}

const TopCards: React.FC<RequiredCards> = ({ checkinId, oldTopCards }) => {
  const { setIsBlocked } = useBlock();
  const [investment, setInvestment] = useState<number | null | string>(null);
  const [revenue, setRevenue] = useState<number | null | string>(null);
  const [lifetime, setLifetime] = useState<number | null>(null);
  const [margin, setMargin] = useState<number | null | string>(null);
  const oldInvestment = oldTopCards.investment / 100;
  const oldRevenue = oldTopCards.revenue / 100;
  const oldLifetime = oldTopCards.lifetime_in_months;
  const oldMargin = oldTopCards.client_margin / 100;

  useEffect(() => {
    setInvestment(oldTopCards.investment === 0 ? null : oldInvestment);
    setRevenue(oldTopCards.revenue === 0 ? null : oldRevenue);
    setLifetime(oldTopCards.lifetime_in_months === 0 ? null : oldLifetime);
    setMargin(oldTopCards.client_margin === 0 ? null : oldMargin);
  }, [oldTopCards, oldRevenue, oldInvestment, oldLifetime, oldMargin]);

  const callUpdateFunction = useCallback(async () => {
    if (
      investment !== oldInvestment ||
      revenue !== oldRevenue ||
      lifetime !== oldLifetime ||
      margin !== oldMargin
    ) {
      setIsBlocked(true);
      await apiV2
        .put('checkinDraft', {
          id: checkinId,
          investment:
            investment === null
              ? investment
              : Math.trunc(Number(investment) * 100),
          revenue:
            revenue === null ? revenue : Math.trunc(Number(revenue) * 100),
          lifetime_in_months: lifetime,
          client_margin:
            margin === null ? margin : Math.trunc(Number(margin) * 100),
        })
        .then(() => {
          setIsBlocked(false);
        })
        .catch(() => {
          toast.warn('Falha em salvar dados.');
          setIsBlocked(false);
        });
      setIsBlocked(false);
    }
  }, [
    oldMargin,
    investment,
    lifetime,
    oldLifetime,
    revenue,
    checkinId,
    setIsBlocked,
    oldInvestment,
    oldRevenue,
    margin,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => callUpdateFunction(), 2000);
    return () => clearTimeout(timeoutId);
  }, [investment, revenue, lifetime, callUpdateFunction, margin]);

  return (
    <S.Container>
      <div className="card">
        <p>Investimento</p>
        <TextInput
          type="number"
          placeholder="Escreva aqui o valor de investimento"
          value={investment === null ? undefined : investment}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // Remove the dots and format the number;
            const p = e.target.value.replace('.', '');
            const l = p.substring(-2, p.length - 2);
            const r = p.substring(p.length - 2, p.length);

            setInvestment(`${l}.${r}`);
          }}
        />

        {investment !== null && investment > 9999999 && (
          <p style={{ color: 'var(--red-primary)' }}>
            Números maiores que 9999999 não serão salvos!
          </p>
        )}
      </div>

      <div className="card">
        <p>Faturamento</p>
        <TextInput
          type="number"
          placeholder="Escreva aqui o valor de faturamento"
          value={revenue === null ? undefined : revenue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // Remove the dots and format the number;
            const p = e.target.value.replace('.', '');
            const l = p.substring(-2, p.length - 2);
            const r = p.substring(p.length - 2, p.length);

            setRevenue(`${l}.${r}`);
          }}
        />

        {revenue !== null && revenue > 9999999 && (
          <p style={{ color: 'var(--red-primary)' }}>
            Números maiores que 9999999 não serão salvos!
          </p>
        )}
      </div>

      <div className="card">
        <p>Margem %</p>
        <TextInput
          type="number"
          placeholder="Escreva aqui"
          value={margin === null ? undefined : margin}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // Remove the dots and format the number;
            const p = e.target.value.replace('.', '');
            const l = p.substring(-2, p.length - 2);
            const r = p.substring(p.length - 2, p.length);

            setMargin(`${l}.${r}`);
          }}
        />

        {margin !== null && margin > 100 && (
          <p style={{ color: 'var(--red-primary)' }}>
            Números maiores que 100% não serão salvos!
          </p>
        )}
      </div>

      <div className="card">
        <p>Life Time em Meses</p>
        <TextInput
          type="number"
          placeholder="Escreva aqui"
          value={lifetime === null ? undefined : lifetime}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setLifetime(Number(e.target.value));
          }}
        />

        {lifetime !== null && (lifetime > 999 || lifetime % 1 !== 0) && (
          <p style={{ color: 'var(--red-primary)' }}>
            Números maiores que 999 ou Não inteiros não serão salvos!
          </p>
        )}
      </div>
    </S.Container>
  );
};

export default TopCards;
