import React from 'react';
import { RiTrophyLine } from 'react-icons/ri';

import CheckinSlide from '../../../components/CheckinSlide';

import * as S from './styles';

interface Result {
  id: number;
  description: string;
}

interface VictoriesSlide {
  victories: Result[];
}

const Victories: React.FC<VictoriesSlide> = ({ victories }) => {
  const sortedVictories = victories.sort((a, b) => a.id - b.id);

  return (
    <CheckinSlide redWords={['r']}>
      <S.Container>
        <div className="icon">
          <RiTrophyLine size={100} color="var(--green-primary)" />
          <p>Vitórias</p>
        </div>

        <div className="victoriesList">
          {sortedVictories.map(victorie => {
            return (
              <div key={victorie.id} className="victorie">
                <S.RedBall /> <p>{victorie.description}</p>
              </div>
            );
          })}
        </div>
      </S.Container>
    </CheckinSlide>
  );
};

export default Victories;
