import styled from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  .icon {
    margin: 0 0.4rem;
  }

  p {
    font-size: 0.7rem;
    color: ${({ theme }) => theme.text};
  }

  @media (max-width: 1360px) {
    p {
      font-size: 0.4rem;
    }
  }
`;
