import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Card from './Card';

import apiV2 from '../../../../services/apiV2';

import * as S from './styles';
import { ProjectsProps } from './types';
import { Okrs } from '../../components/KrsAndOkrs/types';

const LinkOkrs: React.FC = () => {
  const [projects, setProjects] = useState<ProjectsProps[]>();
  const [okrs, setOkrs] = useState<Okrs[]>([]);

  const handleGetProjects = useCallback(async () => {
    await apiV2
      .get(`projects/projectsNameId/${620}`)
      .then(res => {
        setProjects(res.data);
      })
      .catch(() => {
        toast.error('Falha em carregar projetos.');
      });
  }, []);

  const handleGetOkrs = useCallback(async () => {
    await apiV2.get(`okr?clientId=${620}`).then(res => {
      setOkrs(res.data);
    });
  }, []);

  const handleOkrRemove = useCallback(
    id => {
      const newOkrs = okrs;
      setOkrs(newOkrs.filter(okr => okr.id !== id));
    },
    [okrs]
  );

  useEffect(() => {
    handleGetProjects();
    handleGetOkrs();
  }, [handleGetProjects, handleGetOkrs]);

  return (
    <S.Container>
      <h1>Relacionar Okrs:</h1>

      <div className="cards">
        {okrs.map(okr => {
          return (
            okr.project === null && (
              <Card
                key={okr.id}
                remove={handleOkrRemove}
                okr={okr}
                projects={projects || []}
              />
            )
          );
        })}
      </div>
    </S.Container>
  );
};

export default LinkOkrs;
