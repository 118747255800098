import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { WiMoonAltFull, WiMoonAltWaningCrescent4 } from 'react-icons/wi';
import Loading from '../../../../../components/Loadings/CustomLoading';
import { useContracts } from '../../../../../contexts/ContractsContext';
import { getContracts } from '../../../services/GetContracts';
import { convertNumberToMoney } from '../../../utils/ConvertNumberToMoney';
import { getFirstPartner } from '../../../utils/GetFirstPartner';
import { formatContractsFee } from '../../../utils/FormatContractsFee';
import { EditInformation } from '../../Modals/EditInformation';
import ModalBackground from '../../Modals/ModalBackground';
import { Signers } from '../../Modals/Signers';

import * as S from './styles';

export const WaitingSignatureTable: React.FC = () => {
  const [openModals, setOpenModals] = useState(false);
  const [contractToVerify, setContractToVerify] = useState({} as Contract);
  const [signers, setSigners] = useState({} as Signers[]);

  const [loading, setLoading] = useState(true);

  const {
    contracts,
    setContracts,
    setActualPage,
    setTotalPages,
    actualPage,
    totalPages,
  } = useContracts();

  const arrayOfTotalPages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handleCloseModal = (): void => {
    setOpenModals(!openModals);
  };

  const signedSignatures = (contractSigners: Signers[]) => {
    let signed = 0;

    contractSigners.map(signer => {
      if (signer.sign === true) {
        signed += 1;
      }
      return signed;
    });

    return signed;
  };

  const signsIcon = (contractSigners: Signers[]) => {
    const sign = signedSignatures(contractSigners);
    const icon =
      sign > 0 ? (
        <WiMoonAltWaningCrescent4 color="var(--green-primary)" />
      ) : (
        <WiMoonAltFull color="var(--red-primary)" />
      );

    return icon;
  };

  const setContractsInfo = useCallback(
    async (status = 'sign', page = 1, limit = 10) => {
      setLoading(true);
      const newContracts = await getContracts(status, page, limit);

      if (newContracts) {
        const contractsWithFormatedFee = formatContractsFee(
          newContracts.contracts
        );

        setContracts(contractsWithFormatedFee);
        setActualPage(newContracts.page);
        setTotalPages(newContracts.total_pages);
      }

      setLoading(false);
    },
    [setContracts, setActualPage, setTotalPages]
  );

  useEffect(() => {
    setContractsInfo();
  }, [setContractsInfo]);

  return (
    <>
      <S.TableBody>
        <S.TableCategories>
          <S.ColumnCategory className="status">
            <strong>Status</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="vigence">
            <strong>Vigência</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="first-payment">
            <strong>Primeiro Pagamento</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="client">
            <strong>Unidade</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="cnpj">
            <strong>CNPJ</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="legal-representative">
            <strong>Representante Legal</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="legal-representative-cpf">
            <strong>CPF Representante Legal</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="contract-value">
            <strong>Valor do Contrato</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="product">
            <strong>Produto</strong>
          </S.ColumnCategory>
        </S.TableCategories>
        {loading ? (
          <Loading style={{ alignSelf: 'center' }} />
        ) : (
          contracts &&
          contracts.map(contract => (
            <S.TableLine
              key={contract.id}
              onClick={() => {
                setContractToVerify(contract);
                setSigners(contract.signers);
              }}
            >
              <S.ColumnLine
                className="status"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                {signsIcon(contract.signers)}
                <p>
                  {`${signedSignatures(contract.signers)} de ${
                    contract.signers.length
                  }`}
                </p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="vigence"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                <p>{contract.life_time}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="first-payment"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                <p>
                  {format(new Date(contract.first_payment_date), 'dd/MM/yyyy', {
                    locale: ptBR,
                  }).toString()}
                </p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="client"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                <p>{contract.unit.name}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="cnpj"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                <p>{contract.cnpj_cpf}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="legal-representative"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                <p>
                  {contract.partners
                    ? getFirstPartner(contract.partners).name
                    : 'Sócio legal não preenchido'}
                </p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="legal-representative-cpf"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                <p>
                  {contract.partners
                    ? getFirstPartner(contract.partners).cpf
                    : 'CPF do sócio não preenchido'}
                </p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="contract-value"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                <p>{convertNumberToMoney(contract.fee)}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="product"
                onClick={() => {
                  setOpenModals(!openModals);
                }}
              >
                <p>{contract.product ? contract.product : 'Tração'}</p>
              </S.ColumnLine>
            </S.TableLine>
          ))
        )}
        {openModals && (
          <S.Modals>
            <ModalBackground setModal={handleCloseModal} />

            <EditInformation
              contractToVerify={contractToVerify}
              handleCloseModal={handleCloseModal}
              left="25%"
            />
            <Signers signers={signers} handleCloseModal={handleCloseModal} />
          </S.Modals>
        )}
      </S.TableBody>
      {arrayOfTotalPages.length > 0 && (
        <S.Pagination>
          <FaAngleLeft onClick={() => setContractsInfo('sign', 1, 10)} />
          {arrayOfTotalPages.map(page => (
            <button
              key={page}
              onClick={() => setContractsInfo('sign', page, 10)}
              className={page === actualPage ? 'isActive' : ''}
              type="button"
            >
              {page}
            </button>
          ))}
          <FaAngleRight
            onClick={() =>
              setContractsInfo('sign', arrayOfTotalPages.length, 10)
            }
          />
        </S.Pagination>
      )}
    </>
  );
};
