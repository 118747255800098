import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import * as S from './styles';
import ModalHeader from '../ModalHeader';
import {
  ModalAddressLabel,
  ModalContractLabel,
  ModalLabel,
} from '../ModalLabel';

import ModalFooter from '../ModalFooter';
import ModalButton from '../ModalButton';
import { convertNumberToMoney } from '../../../utils/ConvertNumberToMoney';
import { getFirstPartner } from '../../../utils/GetFirstPartner';
import { ModalLoading } from '../../../../../components/Loadings/ModalLoading';
import { getFirstAddress } from '../../../utils/GetFirstAddress';

interface ContractInformationProps {
  contractToVerify: Contract;
  handleCloseModal: () => void;
  inactiveContract: boolean;
}

export const ContractInformation: React.FC<ContractInformationProps> = ({
  contractToVerify,
  handleCloseModal,
  inactiveContract,
}) => {
  const [loading] = useState(false);
  const [loadingButtons] = useState(false);

  return (
    <>
      <S.EditInformationModal>
        <ModalHeader
          icon={MdEdit}
          title="Editar informações"
          setModal={handleCloseModal}
          disabled={loadingButtons}
        />
        {loading ? (
          <ModalLoading rows={7} />
        ) : (
          <S.FormInformations action="">
            <S.Row>
              <ModalLabel title="Razão social do cliente" htmlFor="companyName">
                <p>{contractToVerify.social_name}</p>
              </ModalLabel>
              <ModalLabel title="Data do primeiro pagamento" htmlFor="calendar">
                <p>
                  {new Date(
                    contractToVerify.first_payment_date
                  ).toLocaleDateString('pt-BR')}
                </p>
              </ModalLabel>
              <ModalLabel title="CNPJ/CPF" htmlFor="cnpj">
                <p>{contractToVerify.cnpj_cpf}</p>
              </ModalLabel>
              <ModalLabel
                title="Pagamentos subsequentes"
                htmlFor="nextPayments"
              >
                <p>{contractToVerify.payment_date}</p>
              </ModalLabel>
              <ModalLabel title="Tempo de vigência" htmlFor="vigenceTime">
                <p>{contractToVerify.life_time}</p>
              </ModalLabel>
              <ModalLabel title="Valor Fee" htmlFor="fee">
                <p>{convertNumberToMoney(contractToVerify.fee)}</p>
              </ModalLabel>
            </S.Row>
            <S.Row>
              <ModalLabel
                title="Representante legal"
                htmlFor="legalRepresentative"
              >
                <p>
                  {contractToVerify.partners
                    ? getFirstPartner(contractToVerify.partners).name
                    : 'Sócio legal não preenchido'}
                </p>
              </ModalLabel>
              <ModalLabel title="Cpf" htmlFor="cpf">
                <p>
                  {contractToVerify.partners
                    ? getFirstPartner(contractToVerify.partners).cpf
                    : 'Sócio legal não preenchido'}
                </p>
              </ModalLabel>
            </S.Row>
            <S.Row>
              <ModalAddressLabel htmlFor="street" width="100%">
                <ModalLabel title="Rua" htmlFor="street">
                  <p>
                    {contractToVerify.addresses
                      ? getFirstAddress(contractToVerify.addresses).street
                      : 'Rua não preenchida'}
                  </p>
                </ModalLabel>
                <ModalLabel width="25%" title="Cidade" htmlFor="city">
                  <p>
                    {contractToVerify.addresses
                      ? getFirstAddress(contractToVerify.addresses).city
                      : 'Cidade não preenchida'}
                  </p>
                </ModalLabel>
                <ModalLabel width="22%" title="Estado" htmlFor="state">
                  <p>
                    {contractToVerify.addresses
                      ? getFirstAddress(contractToVerify.addresses).state
                      : 'Estado não preenchido'}
                  </p>
                </ModalLabel>
                <ModalLabel width="24%" title="Número" htmlFor="number">
                  <p>
                    {contractToVerify.addresses
                      ? getFirstAddress(contractToVerify.addresses).number
                      : 'Número não preenchido'}
                  </p>
                </ModalLabel>
                <ModalLabel width="24%" title="Bairro" htmlFor="district">
                  <p>
                    {contractToVerify.addresses
                      ? getFirstAddress(contractToVerify.addresses).district
                      : 'Bairro não preenchido'}
                  </p>
                </ModalLabel>
                <ModalLabel width="24%" title="País" htmlFor="country">
                  <p>
                    {contractToVerify.addresses
                      ? getFirstAddress(contractToVerify.addresses).country
                      : 'País não preenchido'}
                  </p>
                </ModalLabel>
                <ModalLabel width="24%" title="Cep" htmlFor="cep">
                  <p>
                    {contractToVerify.addresses
                      ? getFirstAddress(contractToVerify.addresses).cep
                      : 'Cep não preenchido'}
                  </p>
                </ModalLabel>
              </ModalAddressLabel>
            </S.Row>
            <S.Row>
              <ModalLabel title="Tipo do contrato" htmlFor="contract">
                <p>Padrão</p>
              </ModalLabel>
              <ModalLabel title="Qual unidade?" htmlFor="unit">
                <p>
                  {contractToVerify.unit
                    ? contractToVerify.unit.name
                    : 'Unidade não preenchida'}
                </p>
              </ModalLabel>
            </S.Row>
            <S.Row>
              <ModalContractLabel
                title="Link do contrato:"
                htmlFor="contract-link"
              >
                <a
                  href={contractToVerify.link ? contractToVerify.link : '#'}
                  target="_self"
                >
                  Acessar contrato
                </a>
              </ModalContractLabel>
            </S.Row>
          </S.FormInformations>
        )}
        <ModalFooter>
          {inactiveContract && (
            <ModalButton
              className="red disabled"
              width="10rem"
              alignRight
              type="button"
              disabled={loadingButtons}
            >
              Inativar Contrato
            </ModalButton>
          )}
        </ModalFooter>
      </S.EditInformationModal>
    </>
  );
};
