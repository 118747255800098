import { FC, useState, useCallback, SetStateAction, Dispatch } from 'react';
import Avatar from 'react-avatar';
import { TextInput } from '../../Inputs';
import * as S from './styles';

interface user {
  avatarUrl: string;
  name: string;
  value: number;
}

interface SelectWithAvatar {
  options: user[];
  onChange: Dispatch<SetStateAction<number>>;
  placeholder: string;
}

const SelectWithAvatar: FC<SelectWithAvatar> = ({
  options,
  onChange,
  placeholder = '',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [openOptions, setOpenOptions] = useState(false);

  const handleChange = useCallback(e => {
    setInputValue(e.target.value);
  }, []);

  const handleOpenOption = useCallback(() => {
    setOpenOptions(!openOptions);
  }, [openOptions]);

  const handleSelect = useCallback(
    option => {
      handleOpenOption();
      onChange(option.value);
      setInputValue('');
    },
    [handleOpenOption, onChange]
  );

  return (
    <S.Container>
      <TextInput
        placeholder={placeholder}
        onChange={e => handleChange(e)}
        value={inputValue}
        onClick={handleOpenOption}
      />
      {openOptions && (
        <S.UserList>
          {options
            .filter(option => {
              const lowerName = option.name.toLocaleLowerCase();
              const lowerInput = inputValue.toLocaleLowerCase();
              return lowerName.includes(lowerInput);
            })
            .sort((optA, optB) => (optA.name < optB.name ? -1 : 1))
            .map(option => (
              <li key={option.value}>
                <button onClick={() => handleSelect(option)} type="button">
                  <Avatar
                    name={option.name}
                    src={option.avatarUrl}
                    className="avatar"
                    color="var(--gray)"
                  />
                  <p>{option.name}</p>
                </button>
              </li>
            ))}
        </S.UserList>
      )}
    </S.Container>
  );
};

export default SelectWithAvatar;
