import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Routes from './routes/index';
import GlobalStyle from './styles/global';
import { Contexts } from './contexts';
import { history } from './services/history';

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router history={history}>
        <Contexts>
          <Routes />
          <GlobalStyle />
          <ToastContainer
            autoClose={3000}
            position="bottom-center"
            draggable
            pauseOnHover
          />
        </Contexts>
      </Router>
    </DndProvider>
  );
}

export default App;
