import React, { useState, useCallback, useEffect } from 'react';
import { RiAddCircleLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { useBlock } from '../../../../../contexts/HandleCheckinBlock';

import apiV2 from '../../../../../services/apiV2';
import * as S from './styles';
import FunnelCard from './FunnelCard';

interface Card {
  name: string;
  value: number;
  id: number;
  position: number;
}

interface ManualCardsParams {
  checkinDraftId: number;
  oldCards: Card[];
  newBiLink: string | null;
  setNewBiLink: React.Dispatch<React.SetStateAction<string | null>>;
}

const ManualCards: React.FC<ManualCardsParams> = ({
  checkinDraftId,
  oldCards,
  newBiLink,
  setNewBiLink,
}) => {
  const [cards, setCards] = useState<Card[]>([]);
  const { setIsBlocked } = useBlock();

  useEffect(() => {
    setCards(oldCards.sort((a, b) => a.position - b.position));
  }, [oldCards]);

  const addCard = useCallback(async () => {
    const lastCard = cards[cards.length - 1];
    const noLastCard = cards
      .reverse()
      .slice(1)
      .reverse();

    if (cards.length <= 6) {
      setIsBlocked(true);

      const funnelStepRequestData = {
        funnel: {
          name: 'Nova etapa',
          value: 0,
        },
        checkinDraftId,
      };

      const funnelStep = await apiV2.post('funnel', funnelStepRequestData);

      if (funnelStep) {
        setCards([...noLastCard, funnelStep.data, lastCard]);

        // Removes the biLink, setting to manual
        await apiV2.put('checkinDraft', {
          id: checkinDraftId,
          bi_link: null,
        });

        toast.success('Etapa de funil adicionada.');
      }

      setIsBlocked(false);
    }
  }, [cards, checkinDraftId, setIsBlocked]);

  const editCard = useCallback(
    async (newCard, update = false) => {
      const itemIndex = cards.findIndex(card => card.id === newCard.id);
      const reChecks = cards;
      reChecks[itemIndex] = newCard;
      reChecks[itemIndex].value *= 100;

      setCards([...reChecks]);

      if (update) {
        setIsBlocked(true);

        await apiV2
          .put('funnel/update', {
            funnel: {
              value: Math.trunc(newCard.value),
              id: newCard.id,
              name: newCard.name,
            },
            checkinDraftId,
          })
          .catch(() => {
            toast.error('Falha ao atualizar etapa!');
          });

        if (newBiLink !== null) {
          await apiV2
            .put('checkinDraft', {
              id: checkinDraftId,
              bi_link: null,
            })
            .then(() => {
              setNewBiLink(null);
            });
        }

        setIsBlocked(false);
      }
    },
    [cards, checkinDraftId, setIsBlocked, newBiLink, setNewBiLink]
  );

  const deleteCard = useCallback(
    async id => {
      await apiV2
        .delete(`funnel/delete?funnelId=${id}&checkinDraftId=${checkinDraftId}`)
        .then(() => {
          setCards(cards.filter(reCard => reCard.id !== id));
          toast.success('Etapa deletada!');
        })
        .catch(() => {
          toast.error('Falha ao deletar etapa!');
        });
    },
    [cards, checkinDraftId]
  );

  return (
    <S.Container data-testid="cards">
      {cards.map((card, i) => {
        if (card) {
          return (
            <FunnelCard
              card={card}
              i={i}
              cardsLenght={cards.length}
              deleteCard={deleteCard}
              editCard={editCard}
              key={card.id}
            />
          );
        }

        return null;
      })}
      <div
        className="cardAdd"
        role="button"
        aria-hidden
        onClick={() => addCard()}
      >
        <RiAddCircleLine size={50} color="var(--red-primary)" />
        <p>
          Adicionar nova <br /> etapa no funil
        </p>
      </div>
    </S.Container>
  );
};

export default ManualCards;
