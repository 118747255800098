import React, { useState } from 'react';
import { format } from 'date-fns';
import { RoiMonth } from '../../../../types';

interface Props {
  date: Date;
  monthI: number;
  month: RoiMonth;
}

const Month: React.FC<Props> = ({ date, month, monthI }) => {
  const monthDate = format(new Date(date), 'dd.MM.yyyy');
  const [showWeek, setShowWeek] = useState(false);

  return (
    <>
      <tr>
        <td>
          <button onClick={() => setShowWeek(!showWeek)} type="button">
            {showWeek ? '-' : '+'}
          </button>
        </td>
        <td>Mes {monthDate}</td>
        <td>{month.roas[monthI] && month.roas[monthI].toFixed(2)}</td>
        <td>{month.roasLtv[monthI] && month.roasLtv[monthI].toFixed(2)}</td>
        <td>{month.roi[monthI] && month.roi[monthI].toFixed(2)}</td>
        <td>{month.roiLtv[monthI] && month.roiLtv[monthI].toFixed(2)}</td>
      </tr>
      {showWeek &&
        month.weekly.dates.map((weekDate, weekI) => {
          if (String(weekDate).split('/')[1] === monthDate.split('.')[1]) {
            return (
              <tr>
                <td />
                <td>Week {String(weekDate).replaceAll('/', '.')}</td>
                <td>
                  {month.weekly.roas[weekI] &&
                    month.weekly.roas[weekI].toFixed(2)}
                </td>
                <td>
                  {month.weekly.roasLtv[weekI] &&
                    month.weekly.roasLtv[weekI].toFixed(2)}
                </td>
                <td>
                  {month.weekly.roi[weekI] &&
                    month.weekly.roi[weekI].toFixed(2)}
                </td>
                <td>
                  {month.weekly.roiLtv[weekI] &&
                    month.weekly.roiLtv[weekI].toFixed(2)}
                </td>
              </tr>
            );
          }
          return <> </>;
        })}
    </>
  );
};

export default Month;
