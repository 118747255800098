import styled from 'styled-components';

export const WrapperAvatar = styled.div`
  height: 3.2rem;
  display: flex;
  padding-left: 1.5rem;
  overflow-y: hidden;
  overflow-x: auto;

  .avatar {
    border-radius: 50%;
    padding: 0px;
    margin-left: -0.5rem;
    margin-top: 0.2rem;

    img {
      max-width: none !important;
    }
  }
`;
