import styled from 'styled-components';

export const LoadingScene = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  overflow-y: hidden;

  p {
    align-self: center;
    text-align: center;
    letter-spacing: 5.64px;
    color: ${({ theme }) => theme.text};
    font-size: 20px;
  }

  svg {
    path {
      color: blue;
    }
  }

  strong {
    color: var(--red-primary);
  }

  div {
    position: relative;
  }

  p {
    position: relative;
  }
`;
