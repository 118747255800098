import React, { useCallback, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import Animation from '../../../assets/animations/AnimationLoading.json';
import * as S from './styles';

interface NewLoading {
  size?: string;
}

const Loading: React.FC<NewLoading> = ({ size = '30vh' }) => {
  const randomize = useRef(Math.floor(Math.random() * 15));

  const loadsentences = useCallback(() => {
    const phrases = [
      <p>
        MOTIVO#1: <strong>MUDAR</strong> O <strong>MUNDO</strong>.
      </p>,
      <p>
        MOTIVO#2: SER <strong>INTERNACIONAL</strong>.
      </p>,
      <p>
        MOTIVO#3: SER RECONHECIDO COMO <strong>MELHOR</strong> NO QUE FAZ.
      </p>,
      <p>
        MOTIVO#4: TER <strong>SUCESSO</strong> INTELECTUAL.
      </p>,
      <p>
        MOTIVO#5: TER UMA <strong>GRANDE</strong> <strong>EMPRESA</strong> E UMA{' '}
        <strong>GRANDE</strong> <strong>MARCA</strong>.
      </p>,
      <p>
        MOTIVO#6: TER <strong>LIBERDADE</strong> FINANCEIRA.
      </p>,
      <p>
        ATITUDE#1: <strong>NÃO</strong> RECLAME.
      </p>,
      <p>
        ATITUDE#2: <strong>NÃO</strong> CUMPRA <strong>HORÁRIOS</strong>, CUMPRA{' '}
        <strong>METAS</strong>
      </p>,
      <p>
        ATITUDE#3: <strong>ENCONTRE</strong> SEUS <strong>ERROS</strong> SEMPRE.
      </p>,
      <p>
        ATITUDE#4: <strong>HONRE</strong> SEUS <strong>COMPROMISSOS</strong>.
      </p>,
      <p>
        ATITUDE#5: <strong>INOVE</strong> SEMPRE.
      </p>,
      <p>
        ATITUDE#6: MAIS <strong>AÇÃO</strong> E MENOS GLAMOUR.
      </p>,
      <p>
        ATITUDE#7: <strong>COMUNIQUE-SE</strong>.
      </p>,
      <p>
        ATITUDE#8: VOCÊ <strong>NÃO</strong> TEM UM <strong>EMPREGO</strong>,{' '}
        <strong>VOCÊ</strong> TEM UMA <strong>EMPRESA</strong>.
      </p>,
      <p>
        ATITUDE#9: <strong>RESPIRA</strong>... <strong>NÃO </strong> SEJA UM{' '}
        <strong>EMOCIONADO.</strong>
      </p>,
    ];

    return phrases[Number(randomize.current)];
  }, []);

  return (
    <S.LoadingScene>
      <Player
        style={{ height: size, width: size }}
        autoplay
        loop
        src={Animation}
      />

      {loadsentences()}
    </S.LoadingScene>
  );
};

export default Loading;
