import React from 'react';
import _ from 'lodash';
import { FaRegListAlt, FaCrosshairs } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Okr } from '../../types';
import { ProgressBar } from '../ProgressBar';
import * as S from './styles';

export const OkrsArea: React.FC<{ okrs: Okr[] }> = ({ okrs }) => {
  return (
    <S.Wrapper>
      <header>
        <FaRegListAlt size={18} color="var(--green-primary)" />
        <p>Minhas OKr&apos;s</p>
        <div>
          <span>|</span> <p> = Tempo Quarter</p>
        </div>
      </header>
      <main>
        {okrs &&
          okrs.map((okr: Okr, index: number) => {
            if (index >= 12) {
              return false;
            }
            let krProgress = 0;
            const totalKrs = okr.krs.length > 0 ? okr.krs.length : 1;
            _.forEach(okr.krs, kr => {
              if (kr.is_the_lesser_the_better) {
                krProgress += 1 + (1 - kr.value / kr.goal);
              } else {
                krProgress += kr.value / kr.goal;
              }
            });
            const quarterTime = Math.round(okr.moment_in_the_quarter);
            let okrProgress = Math.round((krProgress / totalKrs) * 100);
            okrProgress = okrProgress > 100 ? 100 : okrProgress;
            return (
              <S.OkrWrapper key={okr.name}>
                <S.Title>
                  <FaCrosshairs size={12} color="var(--red-primary)" />
                  <p>{okr.name}</p>
                </S.Title>
                <ProgressBar
                  percentage={okrProgress}
                  quarterPercent={quarterTime}
                />
              </S.OkrWrapper>
            );
          })}
      </main>

      <footer>
        <Link to="/home/tasks_board/okrs" id="my-tasks">
          <button type="button">Ver meu progresso</button>
        </Link>
      </footer>
    </S.Wrapper>
  );
};
