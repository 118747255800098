import { useState, useEffect } from 'react';
import { format } from 'date-fns';

import Header from './header';
import buildCalendar from './utils/build';
import dayStyles, { beforeToday } from './utils/styleFunc';

import * as S from './styles';

interface CalendarProps {
  onChange: (date: Date) => void;
  date: Date | null | undefined;
  blockPast?: boolean;
  activeRemove?: boolean;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Calendar({
  onChange,
  date,
  blockPast = false,
  activeRemove = false,
  setModal,
}: CalendarProps) {
  const [calendar, setCalendar] = useState<Date[][]>([]);
  const [value, setValue] = useState<Date | null | undefined>(date);

  const handleChangeDate = (newDay: Date) => {
    onChange(newDay);
  };

  useEffect(() => {
    if (value === null || value === undefined) {
      setCalendar(buildCalendar(new Date()));
    } else {
      setCalendar(buildCalendar(value));
    }
  }, [value]);

  return (
    <S.Container remove={activeRemove} style={{ marginBottom: '25vh' }}>
      <Header
        remove={activeRemove}
        blockPast={blockPast}
        value={value}
        setValue={setValue}
      />
      <div className="body">
        <div className="day-names">
          {['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'].map((d, i) => (
            <div key={`${d + i}`} className="week">
              <p>{d}</p>
            </div>
          ))}
        </div>
        {calendar.map(week => (
          <div key={`${week}`}>
            {week.map(calendarDay => (
              <div
                key={format(calendarDay, 'dd/mm/yyyy')}
                className="day"
                onClick={() => {
                  blockPast
                    ? !beforeToday(calendarDay) && setValue(calendarDay)
                    : setValue(calendarDay);
                  handleChangeDate(calendarDay);
                  setModal && setModal(false);
                }}
                role="button"
                aria-hidden
              >
                <div className={dayStyles(calendarDay, value, blockPast)}>
                  {format(calendarDay, 'd')}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </S.Container>
  );
}
