/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Avatar from 'react-avatar';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../contexts/AuthContext';
import api from '../../../../services/api';
import { Button } from '../../../../components/Inputs';
import { useBroadcast } from '../../../../contexts/BroadcastContext';

import WhoSign from './whoSign';
import {
  Container,
  Title,
  Content,
  Required,
  Author,
  Button as StyledButton,
} from './styles';

function Broadcast() {
  const [object, setObject] = useState({
    name: '',
    content: '',
    required: false,
    author_id: null,
    createdAt: `2020-01-01T00:00:00.000Z`,
  });
  const [author, setAuthor] = useState({});
  const [agree, setAgree] = useState(false);
  const [whoSign, setWhoSign] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user: profile } = useAuth();
  const { id } = useParams();
  const authorName = useMemo(() => {
    return `${author.name} ${author.last_name} `;
  }, [author.last_name, author.name]);
  const { setBroadcast } = useBroadcast();

  const createdAt = useMemo(() => {
    return format(parseISO(object.createdAt), 'dd/MM/yyyy HH:mm', {
      locale: ptBR,
    });
  }, [object.createdAt]);

  const history = useHistory();

  useEffect(() => {
    api.get(`broadcasts/${id}`).then(res => {
      setObject(res.data);
      setAgree(
        res.data.signs.filter(sign => sign.user_id === profile.id).length > 0
      );
      api.get(`users/${res.data.author_id}`).then(resu => setAuthor(resu.data));
    });
    api
      .get('roles')
      .then(res =>
        setIsAdmin(res.data.filter(role => role.role === 'admin').length > 0)
      );
  }, [id, profile.id]);

  const seeWhoSign = useMemo(() => {
    if (profile.id === object.author_id || (profile.unit_id === 1 && isAdmin)) {
      return true;
    }
    return false;
  }, [object.author_id, isAdmin, profile.id, profile.unit_id]);

  const handleConfirm = useCallback(async () => {
    if (!agree) {
      toast.error('Você precisa confirmar que leu!');

      return false;
    }

    if (object.signs.filter(sign => sign.user_id === profile.id).length > 0) {
      history.push('/home');
      return false;
    }

    await api
      .post('broadcasts/sign', {
        broadcast_id: object.id,
        agree,
      })
      .then(() => {
        setBroadcast(0);
        toast.success('Você confirmou que leu!');

        history.push('/home');
      });
    return true;
  }, [agree, object.id, object.signs, profile.id, setBroadcast, history]);

  return (
    <>
      <Container>
        <Title>
          <h1>{object.name}</h1>
          {seeWhoSign && object.required && (
            <Button
              background="var(--red-primary)"
              width="8rem"
              height="2rem"
              font="0.8rem"
              type="button"
              onClick={() => setWhoSign(true)}
            >
              Ver quem leu
            </Button>
          )}
        </Title>
        <Content>
          <div dangerouslySetInnerHTML={{ __html: object.content }} />
        </Content>
        <Author>
          Criado por{' '}
          <Avatar
            name={authorName}
            src={author.avatar && author.avatar.url}
            className="avatar"
            color="var(--gray)"
          />{' '}
          {`${authorName} em ${createdAt}`}
        </Author>
        {object.required && (
          <>
            {' '}
            <Required>
              <label htmlFor="required" className="required">
                <input
                  type="checkbox"
                  className="checkbox"
                  name="required"
                  onClick={() => setAgree(!agree)}
                  checked={agree}
                  id="required"
                />
                <label htmlFor="required" className="css-label" />
                <strong>Li e compreeendi</strong>
              </label>
            </Required>
            <StyledButton>
              <Button
                background="var(--red-primary)"
                width="8rem"
                height="2rem"
                font="0.8rem"
                type="button"
                onClick={handleConfirm}
              >
                Confirmar
              </Button>
            </StyledButton>{' '}
          </>
        )}
      </Container>
      {whoSign && (
        <WhoSign
          signs={object.signs}
          recivers={object.recivers}
          setActive={setWhoSign}
        />
      )}
    </>
  );
}

export default Broadcast;
