import { cloneDeep } from 'lodash';
import selectProjectIndexById from './SelectProjectIndexById';

export const editProjectDetails = (
  users: User[],
  dueDate: string,
  progress: number,
  projectId: number,
  projects: Project[]
): Project[] => {
  const newGlobalProjects = cloneDeep(projects);

  const projectIndex = selectProjectIndexById(projectId, projects);

  if (projectIndex === -1) return [];

  newGlobalProjects[projectIndex] = {
    ...newGlobalProjects[projectIndex],
    users,
    due_date: dueDate,
    progress,
  };

  return newGlobalProjects;
};
