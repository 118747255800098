import {
  startOfMonth,
  startOfWeek,
  endOfMonth,
  endOfWeek,
  subDays,
  isBefore,
  addDays,
} from 'date-fns';

export default function buildCalendar(value: Date): Date[][] {
  const startDay = startOfWeek(startOfMonth(value));
  const endDay = subDays(endOfWeek(endOfMonth(value)), 1);

  let day = subDays(startDay, 1);
  const calendar = [];

  const handleUpdateDate = (): Date => {
    day = addDays(day, 1);
    return day;
  };

  while (isBefore(day, endDay)) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => handleUpdateDate())
    );
  }

  return calendar;
}
