/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { format, addMonths, addDays } from 'date-fns';
import MultaTable from '../MultaTable';
import NewPopUp from '../../../../components/PopUp';
import IuguAndManualPage from './IuguAndManualPage';
import MultaPage from './MultaPage';

import api from '../../../../services/api';

const NewCharge = ({
  clientList,
  unitList,
  setNewChargePopUp,
  data,
  setJsonData,
  jsonData,
  setOverallUpdate,
  overallUpdate,
  aproveTableData,
  setAproveTableData,
}) => {
  const [royaltysValue, setRoyaltysValue] = useState('');
  const [multaValue, setMultaValue] = useState(1);
  const [endDate, setEndDate] = useState('');
  const [feeValue, setFeeValue] = useState('');
  const [client, setClient] = useState('');
  const [status, setStatus] = useState('');
  const [unit, setUnit] = useState('');
  const [receivedDate, setReceivedDate] = useState('');
  const [description, setDescription] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedPopUpButton, setSelectedPopUpButton] = useState('iugu');
  const [emails, setEmails] = useState('');
  const [chargesNumber, setChargesNumber] = useState(1);
  const [sendToContaAzul, setSendToContaAzul] = useState('no');

  const firstDay = useMemo(() => {
    const d = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const formated = format(new Date(d), 'yyyy-MM-dd');
    return formated;
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.get(`billing/royalties?unitId=${unit}`).then(response => {
      setRoyaltysValue(response.data.royalties);
    });
  }, [firstDay, unit]);

  const handleDateFix = useCallback((date, addingMonths, months) => {
    const newDate = format(new Date(date), 'MM/dd/yyyy');

    if (addingMonths === true) {
      return addMonths(addDays(new Date(newDate), 1), months);
    }
    return addDays(new Date(newDate), 1);
  }, []);

  const chargeData = useMemo(() => {
    const lateChargesList = [];
    data.forEach(item => {
      if (item.client_id === parseInt(client, 10)) {
        if (
          item.table_status === 'Atrasada' ||
          item.table_status === 'A receber'
        ) {
          lateChargesList.push(item);
        }
      }
    });

    return lateChargesList;
  }, [client, data]);

  const handleIugu = useCallback(
    async (
      addingMonths,
      monthsNumber,
      dueDate,
      clientId,
      unitId,
      feeValueIugu,
      royaltiesValue,
      comment,
      ccEmails
    ) => {
      await api
        .post('billing', {
          due_date: handleDateFix(dueDate, addingMonths, monthsNumber),
          client_id: clientId,
          unit_id: unitId,
          fee_value: feeValueIugu,
          royalties_value: royaltiesValue,
          origin: 'Iugu',
          comment,
          cc_emails: ccEmails,
          send_to_conta_azul: sendToContaAzul,
        })
        .then(res => {
          if (res.data.error === 'Invalid Client zip code') {
            return toast.error('Zip code do cliente é invalido.');
          }
          if (res.data.error === 'Invalid Client address') {
            return toast.error('Endereço do cliente invalido.');
          }
          if (res.data.error) {
            toast.error(`Billing criada sem iuguId`);
            setTimeout(() => setOverallUpdate(overallUpdate + 1), 2000);

            return toast.success('Atualizado!');
          }
          setJsonData([...jsonData, res.data]);
          setAproveTableData([...aproveTableData, res.data]);
          toast.success('Cobrança adicionada!');

          return toast.error('Erro indefinido!');
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status !== 200) {
              const message = () => {
                if (err.response.data.error === 'Validation fails.') {
                  return 'Falha na validação';
                }

                if (err.response.data.error === 'Invalid User') {
                  return 'Usuário invalido';
                }

                if (err.response.data.error === 'Permission Denied') {
                  return 'Permissão Negada';
                }

                if (err.response.data.error === 'Invalid Unit') {
                  return 'Unidade Inválida';
                }

                if (err.response.data.error === 'Invalid Client') {
                  return 'Cliente Inválido';
                }

                if (err.response.data.error === 'Invalid Client zip code') {
                  return 'Zip code do cliente é invalido.';
                }

                if (err.response.data.error === 'Invalid Client address') {
                  return 'Endereço do cliente invalido.';
                }

                return 'Falha';
              };
              toast.error(`${message}`);
            }
            toast.error(`${err.error}`);
          }
        });
    },
    [
      aproveTableData,
      handleDateFix,
      jsonData,
      overallUpdate,
      setAproveTableData,
      setJsonData,
      setOverallUpdate,
      sendToContaAzul,
    ]
  );

  const handleManual = useCallback(
    async (
      addingMonths,
      monthsNumber,
      operation,
      dueDate,
      clientId,
      unitId,
      feeValueIugu,
      royaltiesValue,
      comment,
      ccEmails,
      receivingDate,
      statusString
    ) => {
      await api
        .post('billing', {
          due_date:
            operation === 'new'
              ? handleDateFix(dueDate, addingMonths, monthsNumber)
              : dueDate,
          client_id: clientId,
          unit_id: unitId,
          fee_value: feeValueIugu,
          royalties_value: royaltiesValue,
          received_date:
            operation === 'new' ? handleDateFix(receivingDate) : receivingDate,
          origin: 'Manual',
          comment,
          ended: statusString === 'true',
          cc_emails: ccEmails,
          status: status === 'true' ? 'paid' : 'pending',
        })
        .then(res => {
          setJsonData([...jsonData, res.data]);
          setAproveTableData([...aproveTableData, res.data]);
          toast.success('Cobrança adicionada! ;)');
        })
        .catch(err => {
          if (err?.response?.status !== 200) {
            toast.error(`${err?.response?.data?.error}`);
          }
        });
    },
    [
      aproveTableData,
      handleDateFix,
      jsonData,
      setAproveTableData,
      setJsonData,
      status,
    ]
  );

  const handleMatriz = useCallback(
    async (
      addingMonths,
      monthsNumber,
      operation,
      dueDate,
      clientId,
      unitId,
      feeValueIugu,
      royaltiesValue,
      comment,
      ccEmails,
      receivingDate,
      statusString
    ) => {
      await api
        .post('billing', {
          due_date:
            operation === 'new'
              ? handleDateFix(dueDate, addingMonths, monthsNumber)
              : dueDate,
          client_id: clientId,
          unit_id: unitId,
          fee_value: feeValueIugu,
          royalties_value: royaltiesValue,
          received_date:
            operation === 'new' ? handleDateFix(receivingDate) : receivingDate,
          origin: 'Matriz',
          comment,
          ended: statusString === 'true',
          cc_emails: ccEmails,
        })
        .then(res => {
          setJsonData([...jsonData, res.data]);
          setAproveTableData([...aproveTableData, res.data]);
          toast.success('Cobrança adicionada! ;)');
        })
        .catch(err => {
          if (err.response.status !== 200) {
            toast.error(`${err.response.data.error}`);
          }
        });
    },
    [aproveTableData, handleDateFix, jsonData, setAproveTableData, setJsonData]
  );

  const handleLoop = useCallback(
    async type => {
      if (type === 'Iugu') {
        for (let x = 0; x < chargesNumber; x += 1) {
          await handleIugu(
            true,
            x,
            endDate,
            client,
            unit,
            feeValue,
            royaltysValue,
            description,
            emails !== null ? emails : ''
          );
        }
        setLoading(false);
        setNewChargePopUp(false);
      } else {
        for (let x = 0; x < chargesNumber; x += 1) {
          await handleManual(
            true,
            x,
            'new',
            endDate,
            client,
            unit,
            feeValue,
            royaltysValue,
            description,
            emails,
            receivedDate,
            'false'
          );
        }
        setLoading(false);
        setNewChargePopUp(false);
      }
    },
    [
      chargesNumber,
      client,
      description,
      emails,
      endDate,
      feeValue,
      handleIugu,
      handleManual,
      receivedDate,
      royaltysValue,
      unit,
      setNewChargePopUp,
    ]
  );

  const handleSubmit = useCallback(
    async event => {
      setLoading(true);

      event.preventDefault();

      if (selectedPopUpButton === 'iugu') {
        if (chargesNumber === 1) {
          await handleIugu(
            false,
            0,
            endDate,
            client,
            unit,
            feeValue,
            royaltysValue,
            description,
            emails
          );
          setNewChargePopUp(false);
        }

        if (chargesNumber > 1) {
          handleLoop('Iugu');
        }
      }

      if (selectedPopUpButton === 'manual') {
        if (chargesNumber === 1) {
          await handleManual(
            false,
            0,
            'new',
            endDate,
            client,
            unit,
            feeValue,
            royaltysValue,
            description,
            emails,
            receivedDate,
            'false'
          );

          setNewChargePopUp(false);
        }
        if (chargesNumber > 1) {
          handleLoop();
        }
      }

      if (selectedPopUpButton === 'matriz') {
        if (chargesNumber === 1) {
          await handleMatriz(
            false,
            0,
            'new',
            endDate,
            client,
            unit,
            feeValue,
            royaltysValue,
            description,
            emails,
            receivedDate,
            'false'
          );

          setNewChargePopUp(false);
        }
        if (chargesNumber > 1) {
          handleLoop();
        }
      }

      if (selectedPopUpButton === 'multa') {
        setLoading(true);

        let items = [];
        items = chargeData.filter(item => !selectedItems.includes(item.id));
        items.map(async item => {
          // Cancela as billings
          await api
            .put('billing/delete', {
              id: item.id,
            })
            .then(() => {
              toast.success(`Billing ${item.id} cancelada com sucesso!`);

              // Cria novas billings com a multa
              const billingValue =
                parseFloat(item.fee_value) * (multaValue / 100);
              if (item.origin === 'Iugu') {
                handleIugu(
                  false,
                  0,
                  endDate,
                  item.client_id,
                  item.unit_id,
                  billingValue,
                  item.royalties_value,
                  description,
                  item.cc_emails !== null ? item.cc_emails : ''
                );
              } else {
                handleManual(
                  false,
                  0,
                  'charge',
                  endDate,
                  item.client_id,
                  item.unit_id,
                  billingValue,
                  item.royalties_value,
                  description,
                  item.cc_emails !== null ? item.cc_emails : '',
                  item.received_date,
                  'status'
                );
              }
            })
            .catch(err => {
              if (err.response.status !== 200) {
                let message = '';
                if (err.response.data.error.errors === 'Not Found') {
                  message = 'Iugu: Erro, não encontrado.';
                } else {
                  message = err.response.data.error;
                }

                toast.error(`${message}`);
              }
            });
        });

        setLoading(false);
        setNewChargePopUp(false);
      }
    },
    [
      selectedPopUpButton,
      chargesNumber,
      setNewChargePopUp,
      handleIugu,
      endDate,
      client,
      unit,
      feeValue,
      royaltysValue,
      description,
      emails,
      handleLoop,
      handleManual,
      receivedDate,
      chargeData,
      selectedItems,
      multaValue,
      handleMatriz,
    ]
  );

  const functions = {
    setSendToContaAzul,
    sendToContaAzul,
    setEndDate,
    endDate,
    client,
    setClient,
    clientList,
    unit,
    setUnit,
    unitList,
    setFeeValue,
    feeValue,
    selectedPopUpButton,
    setRoyaltysValue,
    royaltysValue,
    emails,
    setEmails,
    setStatus,
    setReceivedDate,
    MultaTable,
    data: chargeData,
    selectedItems,
    setSelectedItems,
    multaValue,
    setMultaValue,
    setDescription,
    description,
    status,
    chargesNumber,
    setChargesNumber,
  };

  return (
    <NewPopUp width="65%" closePopUp={setNewChargePopUp} header="Nova Cobrança">
      <p style={{ fontWeight: 'bold' }} className="header-p">
        Selecione a forma de cobrança
      </p>

      <form onSubmit={event => handleSubmit(event)}>
        <div className="header-buttons">
          <button
            onClick={() => setSelectedPopUpButton('iugu')}
            type="button"
            className={
              selectedPopUpButton.includes('iugu') ? 'selected' : 'button'
            }
          >
            Cobrança Cliente Iugu
          </button>
          <button
            onClick={() => setSelectedPopUpButton('manual')}
            type="button"
            className={
              selectedPopUpButton.includes('manual') ? 'selected' : 'button'
            }
          >
            Registro Cobrança sem Boleto
          </button>
          <button
            type="button"
            className={
              selectedPopUpButton.includes('matriz') ? 'selected' : 'button'
            }
            onClick={() => setSelectedPopUpButton('matriz')}
          >
            Cobrança Franqueado Iugu
          </button>
        </div>
        {selectedPopUpButton.includes('multa') ? (
          <MultaPage functions={functions} />
        ) : (
          <IuguAndManualPage functions={functions} />
        )}
        <footer>
          <button
            className="button"
            type="submit"
            disabled={loading}
            style={{
              backgroundColor: loading && 'var(--gray)',
              color: loading && 'var(--gray)',
            }}
          >
            Salvar
          </button>
          <button
            onClick={() => setNewChargePopUp(false)}
            className="button"
            type="button"
            disabled={loading}
            style={{ backgroundColor: loading && 'var(--gray)' }}
          >
            Cancelar
          </button>
        </footer>
      </form>
    </NewPopUp>
  );
};

export default NewCharge;
