import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;

  .users {
    padding: 0 0.5rem;
    display: flex;
    overflow-x: auto;

    .avatarContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 0.5s;

      p {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        opacity: 0;
        margin-top: 0.5rem;
        font-size: 0.3rem;
        color: #fff;
      }

      :hover {
        p {
          opacity: 1;
        }
      }
    }

    .avatar {
      transition: 0.3s;
      cursor: pointer;

      :hover {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }
  }

  .add {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.text};
    cursor: pointer;

    p {
      font-weight: bold;
      font-size: 0.8rem;
      margin-left: 0.1rem;
      margin: 0 !important;
    }
  }

  .popUpSubmitButton {
    margin-top: 1rem;
  }

  .addingButtonText {
    font-weight: normal !important;
    font-size: 0.8rem !important;
    padding-left: 0.5rem !important;
    letter-spacing: 1.2px !important;
  }
`;
