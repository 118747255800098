import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from '../../services/api';
import UserAvatar from '../UserAvatar';
import SelectWithAvatar from './SelectWithAvatar';

import * as S from './styles';

interface Responsible {
  users: {
    id?: number;
  }[];
  setUsers: React.Dispatch<
    React.SetStateAction<
      {
        id?: number;
      }[]
    >
  >;
  maxUsers?: number;
}

interface apiUser {
  id: number;
  name: string;
  last_name: string;
  avatar: {
    path: string;
  };
}

interface IUserOptions {
  name: string;
  value: number;
  avatarUrl: string;
}

const Responsible: React.FC<Responsible> = ({
  users,
  setUsers,
  maxUsers = Infinity,
}) => {
  const [newUser, setNewUser] = useState(-1);
  const [options, setOptions] = useState<IUserOptions[]>([]);

  const handleGetUsers = useCallback(() => {
    api.get('users').then(res => {
      const userOptions: IUserOptions[] = [];
      res.data.forEach((user: apiUser) => {
        userOptions.push({
          name: `${user.name} ${user.last_name}`,
          value: user.id,
          avatarUrl: user.avatar
            ? `https://api.laboratoriodomarketing.com/files/${user.avatar.path}`
            : '',
        });
      });
      setOptions(userOptions);
    });
  }, []);

  useEffect(() => {
    handleGetUsers();
  }, [handleGetUsers]);

  const handleUserRemove = useCallback(
    id => {
      if (newUser === id) {
        setUsers(users.filter(user => user.id !== id));
        setNewUser(-1);
      } else {
        setUsers(users.filter(user => user.id !== id));
      }
    },
    [newUser, setUsers, users]
  );

  const addUser = useCallback(
    value => {
      const alreadyAdd = users.filter(user => user.id === value);
      if (alreadyAdd.length === 0) {
        const user = { id: value };
        if (user.id < 0) {
          toast.error('Selecione um usúario');
        } else if (
          users.some(elem => {
            return JSON.stringify(user) === JSON.stringify(elem);
          })
        ) {
          toast.error('Usúario já adicionado');
        } else if (users.length >= maxUsers) {
          toast.error('Maximo de usúarios alcançado!');
        } else {
          setUsers([...users, user]);
        }
      }
    },
    [maxUsers, setUsers, users]
  );

  useEffect(() => {
    if (newUser > -1) {
      addUser(newUser);
    }
  }, [addUser, newUser]);

  return (
    <>
      <SelectWithAvatar
        options={options}
        placeholder="Selecione um usúario"
        onChange={setNewUser}
      />
      <S.Container>
        <div className="users" data-testid="responsible">
          {users.map(user => {
            if (user.id) {
              return (
                <div
                  key={user.id}
                  className="avatarContainer"
                  onClick={() => handleUserRemove(user.id)}
                  role="button"
                  aria-hidden
                >
                  <div style={{ height: '0.8rem' }} />
                  <UserAvatar key={user.id} id={user.id} />
                  <p className="remove">Remover</p>
                </div>
              );
            }
            return (
              <div key={user.id ? user.id : 0} style={{ height: '38px' }} />
            );
          })}
        </div>
      </S.Container>
    </>
  );
};

export default Responsible;
