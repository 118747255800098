import * as S from './styles';

interface ComponentLoadingProps {
  quantity: number;
}

export default function ComponentLoading({ quantity }: ComponentLoadingProps) {
  function renderLoadings() {
    return Array.from({ length: quantity }, (_: undefined, i: number) => {
      return <S.Loading key={i} className="animation" />;
    });
  }
  return <S.Wrapper>{renderLoadings()}</S.Wrapper>;
}
