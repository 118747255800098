import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  padding: 1.5rem;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;

  header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--gray);
  }

  header p {
    color: ${({ theme }) => theme.text};
    font-size: 1.1rem;
    margin-left: 0.6rem;
  }

  main {
    color: ${({ theme }) => theme.text};
    margin-top: 1.2rem;
  }

  footer {
    margin-top: 0.8rem;
  }

  footer .rightButton {
    float: right;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 5rem;
    color: ${({ theme }) => theme.text};
    margin-top: 0.8rem;
    border-radius: 8px;
    border: 0;
    outline: 0;
    padding: 0.8rem;
    background: ${({ theme }) => theme.backgroundPrimary};
    font: 400 0.9rem Work Sans;

    ::placeholder {
      color: ${({ theme }) => theme.text};
      opacity: 70%;
    }
  }

  .input {
    margin-top: 0.8rem;

    input {
      margin-top: 0.7rem;
    }
  }

  .select-search {
    width: 100% !important;
  }

  .select-search__input {
    height: 2.1rem !important;
    font: 400 0.9rem Work Sans !important;
  }

  .initialButton {
    margin-right: 0.5rem;
  }

  .addProject {
    display: flex;
    float: right;
    margin-top: 1rem;
    cursor: pointer;
    letter-spacing: 1.8px;
    font-size: 0.8rem;
    align-items: center;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.text};
  position: absolute;
  right: 1.3rem;
`;
