import { useCallback, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { PhaseCard } from '../PhaseCard';
import { changePhaseOrder } from '../../../../services/ChangePhaseOrder';
import { changeTaskOrder } from '../../../../services/ChangeTaskOrder';
import { useTasks } from '../../../../../../contexts/TasksContext';
import { SetPhases } from '../../../../utils/SetPhases';
import selectProjectIndexById from '../../../../utils/SelectProjectIndexById';
import selectGroupIndexById from '../../../../utils/SelectGroupIndexById';
import { setTasks } from '../../../../utils/SetTasks';

interface PhaseBoardProps {
  phases: Phase[];
  phaseArea: boolean;
  projectId: number;
  groupId: number;
}

export const PhaseBoard = ({
  phases,
  phaseArea,
  projectId,
  groupId,
}: PhaseBoardProps): JSX.Element => {
  const { projects, setProjects } = useTasks();
  const [draggingTimeOut, setDraggingTimeOut] = useState(0);
  const [displayLineAndCircle, setDisplayLineAndCircle] = useState(false);
  const [dragDisabled, setDragDisable] = useState(false);
  const [draggingTaskId, setDraggingTaskId] = useState<number>();

  const projectIndex = selectProjectIndexById(projectId, projects);

  const groupIndex = selectGroupIndexById(projectIndex, groupId, projects);

  const handleChangeTaskOrder = useCallback(
    async (
      taskId: number,
      phaseId: number,
      newOrder: number,
      sourceDropId?: number,
      destinationDropId?: number
    ) => {
      await changeTaskOrder(taskId, phaseId, newOrder);

      const verifyMovement = sourceDropId !== destinationDropId;

      if (!verifyMovement) {
        toast.error('Falha em alterar task de fase!');
      }

      setDraggingTaskId(0);
    },
    []
  );

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId, type } = result;

    setDisplayLineAndCircle(!displayLineAndCircle);

    if (draggingTaskId === +draggableId) {
      toast.warn(
        'O seu último movimento dessa task está sendo processado. Aguarde.'
      );
      return;
    }

    setDraggingTaskId(+draggableId);

    if (!destination) {
      return;
    }

    if (type === 'list') {
      const phaseSelected =
        projects[projectIndex].groups[groupIndex].phases[source.index];
      const order = destination.index + 1;

      if (source.index === 0) {
        toast.warn('Não é possível mover a primeira fase.');
        return;
      }
      if (
        source.index ===
        projects[projectIndex].groups[groupIndex].phases.length - 1
      ) {
        toast.warn('Não é possível mover a última fase.');
        return;
      }
      if (destination.index === 0) {
        toast.warn(
          'Não é possível reordenar uma fase para a primeira posição.'
        );
        return;
      }
      if (
        destination.index ===
        projects[projectIndex].groups[groupIndex].phases.length - 1
      ) {
        toast.warn('Não é possível reordenar uma fase para a última posição.');
        return;
      }
      setDraggingTimeOut(
        window.setTimeout(() => {
          changePhaseOrder(phaseSelected.id, order);
        }, 500)
      );

      const phasesUpdate = projects[projectIndex].groups[
        groupIndex
      ].phases.filter(phaseItem => phaseItem.id !== phaseSelected.id);
      phasesUpdate.splice(destination.index, 0, phaseSelected);
      const newProjects = SetPhases(phasesUpdate, projects, projectId, groupId);
      setProjects(() => newProjects);
      return;
    }

    const destinyPhaseIndex = +destination.droppableId;
    const destinyPhase =
      projects[projectIndex].groups[groupIndex].phases[destinyPhaseIndex];
    const order = destination.index + 1;

    const sourcePhaseIndex = +source.droppableId;
    const sourcePhase =
      projects[projectIndex].groups[groupIndex].phases[sourcePhaseIndex];

    const draggingTask = sourcePhase.tasks.filter(
      task => task.id === +draggableId
    )[0];

    if (destinyPhase.users && destinyPhase.users.length > 0) {
      if (destinyPhase.remove_users) {
        draggingTask.users = [];
      }
      draggingTask.users = draggingTask.users.concat(destinyPhase.users);
      draggingTask.users = _.uniqBy(draggingTask.users, 'id');
    }

    if (sourcePhaseIndex === destinyPhaseIndex) {
      const tasksUpdated = sourcePhase.tasks.filter(
        taskItem => taskItem.id !== draggingTask.id
      );

      tasksUpdated.splice(destination.index, 0, draggingTask);
      const newProjects = setTasks(
        tasksUpdated,
        projects,
        projectId,
        groupId,
        destinyPhase.id
      );

      setProjects([...newProjects]);
    } else {
      const sourcePhaseTasksUpdated = sourcePhase.tasks.filter(
        task => task.id !== +draggableId
      );

      const projectsWithoutDraggingTask = setTasks(
        sourcePhaseTasksUpdated,
        projects,
        projectId,
        groupId,
        sourcePhase.id
      );

      destinyPhase.tasks = [...destinyPhase.tasks, draggingTask];

      const newProjects = setTasks(
        destinyPhase.tasks,
        projectsWithoutDraggingTask,
        projectId,
        groupId,
        destinyPhase.id
      );

      setProjects([...newProjects]);
    }
    if (draggingTimeOut) {
      clearTimeout(draggingTimeOut);
    }
    if (draggingTask && destinyPhase && order) {
      setDraggingTimeOut(
        window.setTimeout(() => {
          handleChangeTaskOrder(
            draggingTask.id,
            destinyPhase.id,
            order,
            +source.droppableId,
            +destination.droppableId
          );
        }, 500)
      );
    }
  };

  const onDragStart = () => {
    setDisplayLineAndCircle(!displayLineAndCircle);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="app" type="list" direction="vertical">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {phaseArea &&
              phases &&
              phases.map((phase, index) => {
                return (
                  <Draggable
                    key={`${phase.id}`}
                    draggableId={`${phase.id}`}
                    index={index}
                    isDragDisabled={dragDisabled}
                  >
                    {(providedDraggable, snapshot) => (
                      <div
                        className="content"
                        key={phase.id}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        ref={providedDraggable.innerRef}
                      >
                        <PhaseCard
                          index={index}
                          phases={phases}
                          phase={phase}
                          title={phase.name}
                          display={displayLineAndCircle}
                          isDragging={snapshot.isDragging}
                          setDragDisabled={setDragDisable}
                          projectId={projectId}
                          groupId={groupId}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default PhaseBoard;
