import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type ContextType = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  projects: Project[];
  setProjects: Dispatch<SetStateAction<Project[]>>;
  projectsLoading: boolean;
  setProjectsLoading: Dispatch<SetStateAction<boolean>>;
  allUsersOfProjects: IUserFilter[];
  setAllUsersOfProjects: Dispatch<SetStateAction<IUserFilter[]>>;
  groupsAndTasksLoading: boolean;
  setGroupsAndTasksLoading: Dispatch<SetStateAction<boolean>>;
};

interface TasksProviderProps {
  children: ReactNode;
}

const TasksContext = createContext({} as ContextType);

export const TasksProvider = ({
  children,
}: TasksProviderProps): JSX.Element => {
  const [query, setQuery] = useState('');
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [allUsersOfProjects, setAllUsersOfProjects] = useState<IUserFilter[]>(
    []
  );
  const [groupsAndTasksLoading, setGroupsAndTasksLoading] = useState(true);

  return (
    <TasksContext.Provider
      value={{
        query,
        setQuery,
        projects,
        setProjects,
        projectsLoading,
        setProjectsLoading,
        allUsersOfProjects,
        setAllUsersOfProjects,
        groupsAndTasksLoading,
        setGroupsAndTasksLoading,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = (): ContextType => {
  return useContext(TasksContext);
};
