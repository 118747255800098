import React from 'react';
import { IconBaseProps } from 'react-icons';

import { IconCard } from './styles';

interface EmoteCardProps {
  icon?: React.ComponentType<IconBaseProps>;
  text: string;
  selected?: number | undefined;
  iconId: number;
  setIcon: React.Dispatch<React.SetStateAction<number | undefined>>;
  color: string;
}

const EmoteCard: React.FC<EmoteCardProps> = ({
  icon: Icon,
  text,
  selected,
  iconId,
  setIcon,
  color,
}) => {
  const newText = text.split(' ');

  function handleClick() {
    if (selected === iconId) setIcon(undefined);
    else if (selected !== undefined && selected !== iconId) setIcon(undefined);
    else setIcon(iconId);
  }

  return (
    <IconCard
      color={color}
      onClick={() => handleClick()}
      selected={selected}
      iconId={iconId}
    >
      {Icon && <Icon size={70} />}

      <p>
        {newText[0]} <br /> {newText[1] && newText[1]}
      </p>
    </IconCard>
  );
};

export default EmoteCard;
