import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import PopUp from '../../../../../components/PopUp';
import { TextInput, Button } from '../../../../../components/Inputs';
import { Container } from './styles';

export default function CancelModal({ deleteFunction, closeFunction }) {
  const [confirmText, setConfirmText] = useState('');

  const handleDeleteCheck = useCallback(() => {
    if (confirmText === 'CONFIRMAR') {
      deleteFunction();
    } else {
      toast.error(`DIGITE "CONFIRMAR" PARA DELETAR`);
    }
  }, [confirmText, deleteFunction]);

  return (
    <PopUp closePopUp={closeFunction} width="45%" header="Confirmar delete">
      <Container>
        <h1>Confirme que quer deletar essa billing digitando CONFIRMAR</h1>{' '}
        <TextInput
          value={confirmText}
          onChange={e => {
            setConfirmText(e.target.value);
          }}
          placeholder="CONFIRMAR"
          width="97%"
          className="simple-input"
        />
        <footer>
          <Button
            height="2rem"
            background="var(--red-primary)"
            onClick={() => handleDeleteCheck()}
          >
            Deletar
          </Button>
          <Button height="2rem" onClick={() => closeFunction(false)}>
            Voltar
          </Button>
        </footer>
      </Container>
    </PopUp>
  );
}
