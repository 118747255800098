import React, { useCallback, useState } from 'react';

import CheckinSlide from '../../../components/CheckinSlide';
import NewIndividualTask from '../../../../Tasks/components/Modals/NewIndividualTask';
import { Button } from '../../../../../components/Inputs';
import api from '../../../../../services/apiV2';

import DougnutChat from './Dougnut';
import TaskCard from './Task';
import Chart from './Chart';

import { TaskProgress, CheckinTask, TaskProgressGraph } from '../../../types';
import * as S from './styles';

interface TaskSlide {
  progress: TaskProgress;
  isDraft: boolean;
  projectId: number;
  chartData: TaskProgressGraph;
  tasks: {
    finished_tasks?: {
      task: Task;
      projectName: string;
      groupName?: string;
      phaseName?: string;
    }[];
    unfinished_tasks?: {
      task: Task;
      projectName: string;
      groupName?: string;
      phaseName?: string;
    }[];
    checkin_tasks?: CheckinTask[];
  };
}

const TasksSlide: React.FC<TaskSlide> = ({
  progress,
  tasks,
  projectId,
  isDraft,
  chartData,
}) => {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [createdTasks, setCreatedTasks] = useState<Task[]>([]);
  const [unfinishedTasks, setUnfinishedTasks] = useState(
    tasks.unfinished_tasks
  );

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') {
      setOpenModalNew(false);
    }
  };

  const handleEditing = useCallback(
    async (id, name, date, createdNow) => {
      await api
        .put('tasks', {
          id,
          name,
          due_date: date,
        })
        .then(res => {
          if (createdNow) {
            const itemIndex = createdTasks.findIndex(task => task.id === id);

            const newcreatedTasks = createdTasks;
            newcreatedTasks[itemIndex] = res.data;
            setCreatedTasks([...newcreatedTasks]);
          } else if (unfinishedTasks) {
            const itemIndex = unfinishedTasks.findIndex(
              task => task.task.id === id
            );

            const newUnfinishedTasks = unfinishedTasks;
            newUnfinishedTasks[itemIndex].task = res.data;
            setUnfinishedTasks([...newUnfinishedTasks]);
          }
        });
    },
    [unfinishedTasks, createdTasks]
  );

  return (
    <>
      <CheckinSlide redWords={['e']}>
        <S.Container>
          <div className="graphics">
            <div className="donuts">
              <DougnutChat
                title="Tarefas em dia"
                percent={progress?.onTimeTasksProgress.percentage}
                color="var(--cyan-primary)"
                users={progress?.onTimeTasksProgress.users}
              />
              <DougnutChat
                title="Tarefas entregues"
                percent={progress?.onTimeDoneTasksProgress.percentage}
                color="var(--green-primary)"
                users={progress?.onTimeDoneTasksProgress.users}
              />
              <DougnutChat
                data-testid="chart-card"
                title="Tarefas atrasadas"
                percent={progress?.lateTasksProgress.percentage}
                color="var(--red-primary)"
                users={progress?.lateTasksProgress.users}
              />
              <DougnutChat
                title="Entregues atrasadas"
                percent={progress?.lateDoneTasksProgress.percentage}
                color="var(--yellow-primary)"
                users={progress?.lateDoneTasksProgress.users}
              />
              <DougnutChat
                title="Tarefas remanejadas"
                percent={progress?.rescheduleTasksProgress.percentage}
                color="var(--magenta-primary)"
                users={progress?.rescheduleTasksProgress.users}
              />
            </div>
            <Chart taskData={chartData} />
          </div>
          <div className="doneInLastCheckin">
            <div className="list">
              <p className="title">Tarefas concluídas do último check in</p>

              <div className="tasks">
                {tasks.finished_tasks?.map(task => {
                  return <TaskCard finished key={task.task.id} task={task} />;
                })}

                {tasks.checkin_tasks?.map(task => {
                  return !task.is_seven_days_after ? (
                    <TaskCard
                      finished
                      key={task.id}
                      task={{
                        task,
                        projectName: task.project_name,
                        groupName: task.group_name ? task.group_name : '',
                        phaseName: task.phase_name ? task.phase_name : '',
                      }}
                    />
                  ) : (
                    <> </>
                  );
                })}
              </div>
            </div>

            <div className="list">
              <p className="title">Tarefas próximos 7 dias</p>

              <div className="tasks">
                <div className="tasks">
                  {unfinishedTasks?.map(task => {
                    return (
                      <TaskCard
                        key={task.task.id}
                        editFunction={handleEditing}
                        task={task}
                      />
                    );
                  })}

                  {tasks.checkin_tasks?.map(task => {
                    return (
                      task.is_seven_days_after && (
                        <TaskCard
                          key={task.id}
                          task={{
                            task,
                            projectName: task.project_name,
                            groupName: task.group_name ? task.group_name : '',
                            phaseName: task.phase_name ? task.phase_name : '',
                          }}
                        />
                      )
                    );
                  })}

                  {createdTasks.map(task => {
                    return new Date(task.due_date) >= today ? (
                      <TaskCard
                        key={task.id}
                        editFunction={handleEditing}
                        task={{ task, projectName: '' }}
                        recentTask
                      />
                    ) : (
                      <> </>
                    );
                  })}
                </div>

                {isDraft && (
                  <Button
                    background="var(--red-primary)"
                    width="100%"
                    height="1.6rem"
                    text="Nova Task"
                    font="400 0.9rem Work Sans"
                    type="button"
                    onClick={() => setOpenModalNew(true)}
                  />
                )}
              </div>
            </div>
          </div>
        </S.Container>
      </CheckinSlide>
      {openModalNew && (
        <S.Modal id="modal" onClick={handleOutsideClick}>
          <NewIndividualTask
            projectId={projectId}
            tasks={createdTasks}
            setTasks={setCreatedTasks}
            handleClose={() => setOpenModalNew(false)}
          />
        </S.Modal>
      )}
    </>
  );
};

export default TasksSlide;
