import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export const createGroup = async (
  groupName: string | undefined,
  groupDescription: string,
  projectId: number,
  tagsIds: number[]
): Promise<Group> => {
  try {
    const response = await apiV2.post(`groups`, {
      name: groupName,
      desc: groupDescription,
      project_id: projectId,
      tags_ids: tagsIds,
    });

    const { group } = response.data;

    return group;
  } catch {
    toast.error(
      'Ocorreu algum problema ao criar o grupo, atualize a página e tente novamente 😕'
    );

    return {} as Group;
  }
};
