import React, { useCallback, useState, useEffect } from 'react';

import { RiFileListLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import apiV2 from '../../../../../services/apiV2';

import NewKr from '../NewKr';

import NewMeta from '../Meta';

import * as S from './styles';
import { CardInfo, EditingFunction } from '../types';

interface KrsCards {
  predefined?: boolean;
  krs: CardInfo[];
  okrId: number;
  showHeader?: boolean;
  presentation?: boolean;
  handleOkrUpdate?: (
    okrId: number,
    newKrs: CardInfo[],
    predefined?: boolean
  ) => void;
}

const KrsCard: React.FC<KrsCards> = ({
  krs,
  okrId,
  presentation = false,
  handleOkrUpdate,
  predefined = false,
  showHeader = true,
}) => {
  const [mainCards, setMainCards] = useState(krs);

  // No way to use all dependencies
  useEffect(() => {
    if (handleOkrUpdate) {
      handleOkrUpdate(okrId, mainCards, predefined);
    }
  }, [mainCards, okrId, handleOkrUpdate, predefined]);

  const handleAddKr = useCallback(
    newKrData => {
      apiV2
        .post('kr', { kr: newKrData, okrId })
        .then(res => {
          setMainCards([...mainCards, res.data]);
        })
        .catch(err => {
          toast.error(err.message);
        });
    },
    [mainCards, okrId]
  );

  const handleCardRemove = useCallback(
    (id: number) => {
      apiV2
        .delete(`kr/deleteKr?krId=${id}`)
        .then(() => {
          const filteredKrs = mainCards.filter(card => card.id !== id);
          setMainCards(filteredKrs);
          toast.error('Kr deletada com sucesso!');
        })
        .catch(() => {
          toast.error('Falha ao deletar kr');
        });
    },
    [mainCards]
  );

  const handleCardEdit = useCallback(
    (object: EditingFunction) => {
      apiV2
        .put('kr', object)
        .then(res => {
          const itemIndex = mainCards.findIndex(card => card.id === object.id);
          const reKrs = mainCards;
          reKrs[itemIndex] = res.data;

          setMainCards([...reKrs]);
          toast.success('Kr Editada com sucesso!');
        })
        .catch(() => {
          toast.error('Falha ao editar Kr.');
        });
    },
    [mainCards]
  );

  return (
    <S.Container>
      {showHeader && (
        <header>
          <RiFileListLine size={20} color="var(--green-primary)" />
          <p>KR&apos;s Time</p>
        </header>
      )}

      <div className="krs">
        {mainCards &&
          mainCards.map(card => {
            return (
              <NewMeta
                key={card.id}
                cardInfo={card}
                removeFunction={handleCardRemove}
                editFunction={handleCardEdit}
                predefined={predefined}
              />
            );
          })}
      </div>
      {!presentation && !predefined && <NewKr addFunction={handleAddKr} />}
    </S.Container>
  );
};

export default KrsCard;
