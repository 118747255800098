import { cloneDeep } from 'lodash';

export const setGroups = (
  groups: Group[],
  projects: Project[],
  projectId: number
) => {
  const newProjects = cloneDeep(projects);

  const selectedProject = newProjects.find(project => project.id === projectId);
  if (!selectedProject) return [];
  selectedProject.groups = groups;

  return newProjects;
};
