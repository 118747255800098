import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  padding-top: 0.5rem;

  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    button:first-child {
      margin-right: 1rem;
    }
  }

  .startNewCheckin {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;

    .pageTitle {
      margin-right: 0rem;
      margin-top: 0rem;
      text-align: center;
    }

    button {
      box-shadow: 0 4px 8px 0 rgba(227, 36, 56, 0.5),
        0 6px 20px 0 rgba(227, 36, 56, 0.5);
    }
  }
`;
