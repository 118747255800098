/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-useless-escape */
import { FC, useState, useEffect, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { MdClose, MdDone } from 'react-icons/md';
import { toast } from 'react-toastify';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';

import { ReactComponent as TopAtomo } from '../../../../assets/images/top-atomo.svg';
import { ReactComponent as BottomAtomo } from '../../../../assets/images/bottom-atomo.svg';
import * as S from './styles';
import { ModalLoading } from '../../../../components/Loadings/ModalLoading';

type Franchise = {
  id: number;
  name: string;
};

const Register: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [unitId, setUnitId] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [franchises, setFranchises] = useState([{} as Franchise]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function getFranchises() {
    try {
      const response = await api.get('/units?active=true');
      const franchisesResponse = response.data;
      setFranchises(franchisesResponse);
    } catch (err) {
      toast.error('Não foi possivel carregar as franquias.');
    }
  }

  useEffect(() => {
    getFranchises();
  }, []);

  async function signUp() {
    setLoading(true);
    try {
      await api.post('users', {
        name: firstName,
        last_name: lastName,
        email,
        password,
        unit_id: unitId,
      });

      toast.success('Usuário criado com sucesso!');
      setLoading(false);
      history.push('/');
    } catch (err) {
      toast.error('Usuário já existe.');
      setLoading(false);
    }
  }

  function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();

    if (password === passwordConfirmation) {
      if (unitId !== null) {
        if (
          password.length > 7 &&
          /([ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/g.test(password) &&
          /([0-9]+)/g.test(password) &&
          /([A-Z]+)/g.test(password) &&
          /([a-z]+)/g.test(password)
        ) {
          signUp();
        } else {
          toast.error('Sua senha não atende os requistos de segurança');
        }
      } else {
        toast.error('Selecione a sua unidade');
      }
    } else {
      toast.error('As senhas não são iguais!');
    }
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const findUnitId = (value: string) => {
    const selectedFranchise = franchises.find(
      franchise => franchise.name === value
    );

    return String(selectedFranchise?.id);
  };

  return (
    <S.Container>
      <S.BackgroundContainer />
      <TopAtomo
        style={{
          position: 'absolute',
          top: '-6%',
          right: '-4%',
        }}
      />
      <BottomAtomo
        style={{
          position: 'absolute',
          bottom: '-6%',
          left: '-4%',
        }}
      />

      <S.Form onSubmit={onSubmit} autoComplete="chrome-off">
        {loading ? (
          <ModalLoading rows={3} />
        ) : (
          <>
            <S.Logo />
            <S.InputContainer htmlFor="first_name">
              Nome
              <input
                type="text"
                className="form-control"
                name="first_name"
                placeholder="Digite aqui"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                onFocus={() => null}
                required
              />
            </S.InputContainer>
            <S.InputContainer htmlFor="last_name">
              Sobrenome
              <input
                type="text"
                className="form-control"
                name="last_name"
                placeholder="Digite aqui"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                onFocus={() => null}
                required
              />
            </S.InputContainer>
            <S.InputContainer htmlFor="email">
              Email
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Digite aqui"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onFocus={() => null}
                required
              />
            </S.InputContainer>
            <S.InputContainer
              htmlFor="password"
              style={{ position: 'relative', marginBottom: '0px' }}
            >
              Senha
              <input
                type={passwordShown ? 'text' : 'password'}
                className="form-control"
                name="password"
                placeholder="Digite aqui"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onFocus={() => null}
                required
                autoComplete="new-password"
              />
              <BsFillEyeSlashFill onClick={togglePasswordVisiblity} />
            </S.InputContainer>
            <S.PassCheck>
              <li>
                {password.length > 7 ? (
                  <MdDone color="var(--green-primary)" />
                ) : (
                  <MdClose color="var(--red-primary)" />
                )}
                8 Caracteres
              </li>
              <li>
                {/([ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/g.test(password) ? (
                  <MdDone color="var(--green-primary)" />
                ) : (
                  <MdClose color="var(--red-primary)" />
                )}
                Caracter especial
              </li>
              <li>
                {/([0-9]+)/g.test(password) ? (
                  <MdDone color="var(--green-primary)" />
                ) : (
                  <MdClose color="var(--red-primary)" />
                )}
                Número
              </li>
              <li>
                {/([A-Z]+)/g.test(password) ? (
                  <MdDone color="var(--green-primary)" />
                ) : (
                  <MdClose color="var(--red-primary)" />
                )}
                Letra maiúscula
              </li>
              <li>
                {/([a-z]+)/g.test(password) ? (
                  <MdDone color="var(--green-primary)" />
                ) : (
                  <MdClose color="var(--red-primary)" />
                )}
                Letra minúscula
              </li>
            </S.PassCheck>
            <S.InputContainer
              htmlFor="password_confirmation"
              style={{ position: 'relative' }}
            >
              Confirme sua senha
              <input
                type={passwordShown ? 'text' : 'password'}
                className=""
                name="password_confirmation"
                placeholder="Digite aqui"
                style={{
                  color:
                    password === passwordConfirmation
                      ? 'var(--green-primary)'
                      : 'var(--red-primary)',
                }}
                value={passwordConfirmation}
                onChange={e => setPasswordConfirmation(e.target.value)}
                onFocus={() => null}
                required
              />
              <BsFillEyeSlashFill onClick={togglePasswordVisiblity} />
            </S.InputContainer>

            <S.InputContainer htmlFor="unit">
              Selecione a unidade franqueada
              <input
                type="select"
                list="units"
                id="unit"
                placeholder="Selecione a unidade franqueada"
                required
                onChange={e =>
                  setUnitId(
                    findUnitId(e.target.value) && findUnitId(e.target.value)
                  )
                }
              />
              <datalist id="units">
                {franchises &&
                  franchises
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(franchise => (
                      <option key={franchise.id} value={franchise.name} />
                    ))}
              </datalist>
            </S.InputContainer>
            <button type="submit">Registrar</button>
            <p>
              Já tem uma conta? <Link to="/">Faça login</Link>
            </p>
          </>
        )}
      </S.Form>
    </S.Container>
  );
};

export { Register };
