import React, { useCallback } from 'react';

import { Container, LogoHeader, Footer, LogoFooter } from './styles';

interface CheckinSlides {
  redWords: string[];
  children: React.ReactNode;
}

const CheckinSlide: React.FC<CheckinSlides> = ({ children, redWords }) => {
  const handleColor = useCallback(
    word => {
      if (redWords.includes(word)) return 'red';
      return '';
    },
    [redWords]
  );

  return (
    <Container
      style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <header
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <LogoHeader />
        <div className="text">
          <p className={handleColor('r')}>R.</p>
          <p className={handleColor('o')}>O.</p>
          <p className={handleColor('p')}>P.</p>
          <p className={handleColor('r2')}>R.</p>
          <p className={handleColor('e')}>E</p>
        </div>
      </header>
      <div className="children">{children}</div>
      <Footer>
        <p>Powered by</p>
        <LogoFooter />
      </Footer>
    </Container>
  );
};

export default CheckinSlide;
