import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5rem;
  padding-right: 1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
  margin-bottom: 1rem;

  #deliveries {
    flex: 0.9;
  }

  .titleText {
    color: ${({ theme }) => theme.text};
  }

  .grid-item {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:last-child {
      margin-left: 0.1rem;
    }

    p:first-child {
      margin-right: 0.1rem;
    }
  }

  #add-new {
    button {
      display: flex;
      align-items: center;
      border: none;
      background: none;
      p {
        color: ${({ theme }) => theme.text};
        font-size: 1rem;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        white-space: nowrap;
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 0.8;

    div {
      display: flex;
      align-items: center;
      width: 100%;
      flex: 0.8;

      @media (max-width: 991px) {
        p {
          display: none;
        }

        justify-content: center;
      }
    }
  }

  .icon {
    margin: 0 0.4rem;
  }
`;
