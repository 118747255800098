import React, { useCallback, useState } from 'react';
import { RiFoldersLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Button, TextInput } from '../../../../../components/Inputs';
import * as S from './styles';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import RichText from '../../../../../components/RichText';
import { Modal } from '../../../styles';
import ImportPlaybook from '../ImportPlaybook';
import useForm from '../../../../../hooks/useForm';
import { LoadingButton } from '../../LoadingButton';
import { TagsCreate } from '../../TagsCreate';
import { useTasks } from '../../../../../contexts/TasksContext';
import { createProject } from '../../../services/CreateProject';

interface Params {
  id: string;
}

interface useFormHookProps {
  form: { projectName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

interface NewProjectProps {
  handleClose: () => void;
}

function NewProject({ handleClose }: NewProjectProps) {
  const { projects, setProjects } = useTasks();
  const { form, onChange }: useFormHookProps = useForm({ projectName: '' });
  const [content, setContent] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams<Params>();
  const [isDisable, setIsDisable] = useState(false);
  const [tagsList, setTagsList] = useState<ITag[]>([]);

  const handleOpenModal = useCallback((): void => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleCloseModal = useCallback((): void => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleOutsideClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const targetClick = event.target as HTMLDivElement;
      if (targetClick.id === 'outside-modal') handleOpenModal();
    },
    [handleOpenModal]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const handleCreateProject = useCallback(async () => {
    setIsDisable(true);
    if (form.projectName!.length <= 0 || form.projectName! === ' ') {
      toast.warn('Não é possível criar um projeto sem nome!');
      setIsDisable(false);
      return;
    }
    const project = await createProject(
      form.projectName,
      content,
      id,
      tagsList.map((tag: ITag) => tag.id)
    );

    if (setProjects && projects) {
      setProjects([...projects, project]);
    }
    handleClose();
    toast.success('Projeto criado com sucesso!');

    setIsDisable(false);
  }, [
    id,
    content,
    form.projectName,
    tagsList,
    handleClose,
    projects,
    setProjects,
  ]);

  return (
    <S.Container>
      <TaskCard close={handleClose}>
        <header>
          <RiFoldersLine size={21} color="var(--red-primary)" />
          <p>Novo Projeto</p>
        </header>
        <main>
          {/* <Button
            background="var(--red-primary)"
            width="100%"
            height="2.1rem"
            onClick={handleOpenModal}
          >
            <p>Importar de um playbook</p>
          </Button> */}
          <div className="input">
            <p>Nome do projeto</p>
            <TextInput
              name="projectName"
              onChange={handleInputChange}
              value={form.projectName}
              width="100%"
              height="2.1rem"
              placeholder="Escreva o nome do projeto"
              font="400 0.9rem Work Sans"
            />
          </div>

          <div className="input">
            <p>Adicionar uma Tag</p>
            <TagsCreate tagsList={tagsList} setTagsList={setTagsList} />
          </div>

          <div className="input">
            <p>Descrição do projeto</p>
            <RichText
              height={200}
              width={282}
              initialValue="Escreva aqui a descrição deste projeto."
              contentToSave={(contentItem: string) => {
                setContent(contentItem);
              }}
            />
          </div>

          <footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {isDisable ? (
              <LoadingButton width="50%" height="2.1rem" />
            ) : (
              <Button
                background="var(--green-primary)"
                width="50%"
                height="2.1rem"
                font="bold 0.9rem Work Sans"
                onClick={() => handleCreateProject()}
              >
                <p>Criar</p>
              </Button>
            )}
          </footer>
        </main>
      </TaskCard>
      {openModal && (
        <Modal id="outside-modal" onClick={handleOutsideClick}>
          <ImportPlaybook handleClose={handleCloseModal} />
        </Modal>
      )}
    </S.Container>
  );
}

export default NewProject;
