import styled from 'styled-components';
import BackgroundImage from '../../../../assets/images/backgroundImg.webp';

export const Container = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.lowOpacity};
  font-family: 'Open Sans';
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -1;
`;

export const Logo = styled.div`
  left: -3px;
  position: relative;
  width: 55%;
  margin-bottom: 3vh;
  min-height: 38px;
  background-image: ${({ theme }) => theme.logo};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Form = styled.form`
  width: 21vw;
  min-width: 398px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 8px;
  padding: 25px 20px;
  box-shadow: 0px 3px 22px #00000063;
  background-color: ${({ theme }) => theme.backgroundSecondary};

  button {
    background: var(--red-primary);
    width: 100%;
    min-height: 41px;
    border-radius: 4px;
    border: none;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 7px;
    letter-spacing: 0.84px;
    color: var(--white);
    transition: all 0.2s ease-in-out;
    margin-bottom: 1vh;
  }

  button:hover {
    opacity: 0.8;
  }

  p {
    font-size: 0.75rem;
    letter-spacing: 0.72px;
    line-height: 13px;
    font-weight: bold;
    color: var(--gray);
    align-self: center;

    a {
      color: var(--red-primary);
    }
  }
`;

export const InputContainer = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  letter-spacing: 0.84px;
  line-height: 13px;
  font-weight: bold;
  margin-bottom: 3vh;
  position: relative;

  & + & {
    margin-bottom: 3vh;
  }

  input {
    font-size: 0.75rem;
    letter-spacing: 0.72px;
    line-height: 13px;
    font-weight: normal;
    margin-top: 1vh;
    background: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    border-radius: 8px;
    min-height: 36px;
    padding: 9px 14px;
    border: none;
  }

  svg {
    position: absolute;
    right: 10px;
    top: 32px;
    cursor: pointer;
  }
`;

export const PassCheck = styled.ul`
  color: ${({ theme }) => theme.text};
  display: flex;
  font-size: 0.75rem;
  margin: 1vh 0 1.5vh;
  flex-wrap: wrap;
  list-style: none;

  li {
    width: 33%;
    display: flex;
    display: flex;
    align-items: center;
    align-items: center;
    margin-bottom: 0.5vh;
  }
`;
