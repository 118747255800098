/* eslint-disable react/require-default-props */
import { useState, useCallback, useEffect, useMemo, ChangeEvent } from 'react';
import { RiFocusLine } from 'react-icons/ri';

import { toast } from 'react-toastify';

import Coments from '../../../../../components/Coments';
import RichTextEditor from '../../../../../components/RichText';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import Checklist from '../../../../../components/Checklist';
import { Calendar } from '../../../../../components/Calendar';
import Responsible from '../../../../../components/Responsible';

import { Button, TextInput, Select } from '../../../../../components/Inputs';

import * as S from './styles';
import useForm from '../../../../../hooks/useForm';
import apiV2 from '../../../../../services/apiV2';
import useProgress from '../../../../../hooks/useProgress';
import { comments, Subtask } from './types';
import { LoadingButton } from '../../LoadingButton';
import { ProgressDisplay } from '../../ProgressDisplay';
import { TagsCreate } from '../../TagsCreate';
import { getSubtasks } from '../../../services/GetSubtasks';
import { getComments } from '../../../services/GetComments';
import selectProjectIndexById from '../../../utils/SelectProjectIndexById';
import { editIndividualTasks } from '../../../utils/EditIndividualTasks';
import { useTasks } from '../../../../../contexts/TasksContext';
import { editProjectDetails } from '../../../utils/EditProjectDetails';
import { SetIndividualTasks } from '../../../utils/SetIndividualTasks';

interface TaskUser {
  id: number;
  name: string;
  last_name: string;
}

interface useFormHookProps {
  form: { taskName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

interface EditIndividualTaskProps {
  projectId?: number;
  task: Task;
  handleClose: () => void;
  tasks?: Task[];
  setTasks?: (tasks: Task[]) => void;
  tags?: ITag[];
  myTasksView?: boolean;
}

export function EditIndividualTask({
  projectId,
  task,
  handleClose,
  tasks,
  setTasks,
  tags,
  myTasksView,
}: EditIndividualTaskProps) {
  const RecurrenceDefaultValues = [
    {
      name: 'Nenhuma',
      value: '0',
    },
    {
      name: 'Dias após',
      value: 'DAYS_AFTER',
    },
    {
      name: 'Dias da Semana',
      value: 'WEEK_DAYS',
    },
  ];
  const WeekDays: CheckItem[] = [
    {
      name: 'Domingo',
      marked: false,
      infos: 'Selecionar domingo',
      canBeRemoved: false,
    },
    {
      name: 'Segunda-feira',
      marked: false,
      infos: 'Selecionar Segunda-feira',
      canBeRemoved: false,
    },
    {
      name: 'Terça-feira',
      marked: false,
      infos: 'Selecionar Terça-feira',
      canBeRemoved: false,
    },
    {
      name: 'Quarta-feira',
      marked: false,
      infos: 'Selecionar Quarta-feira',
      canBeRemoved: false,
    },
    {
      name: 'Quinta-feira',
      marked: false,
      infos: 'Selecionar Quinta-feira',
      canBeRemoved: false,
    },
    {
      name: 'Sexta-feira',
      marked: false,
      infos: 'Selecionar Sexta-feira',
      canBeRemoved: false,
    },
    {
      name: 'Sábado',
      marked: false,
      infos: 'Selecionar Sábado',
      canBeRemoved: false,
    },
  ];

  const { colors } = useProgress();
  const { form, onChange }: useFormHookProps = useForm({ taskName: task.name });
  const [date, setDate] = useState<Date | undefined>(
    task.due_date ? new Date(task.due_date) : undefined
  );
  const [users, setUsers] = useState<
    {
      id?: number;
    }[]
  >([{}]);
  const { projects, setProjects } = useTasks();
  const [coments, setComents] = useState<comments[]>([]);
  const [checks, setChecks] = useState<CheckItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tagsList, setTagsList] = useState<ITag[]>([]);

  const [taskDescription, setTaskDescription] = useState('');
  const [recurrence, setRecurrence] = useState({
    type: task.recurrence_type || 'null',
    value:
      task.recurrence_type === 'WEEK_DAYS'
        ? task.recurrence_value
        : (task.recurrence_value && JSON.parse(task.recurrence_value)[0]) || 1,
  });

  useEffect(() => {
    setTagsList(() => tags || []);
  }, [tags]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const progressBarsColors = useMemo(
    () => colors(task.due_date, task.finished_at, task.progress),
    [colors, task.due_date, task.finished_at, task.progress]
  );

  const getInitialValueOfRecurrenceDays = () => {
    const recurrenceValueParsed = JSON.parse(task.recurrence_value!);
    if (typeof recurrenceValueParsed === typeof []) {
      const weekDaysMapped = WeekDays.map((weekDay, index) => {
        if (
          recurrenceValueParsed &&
          recurrenceValueParsed.indexOf(index) !== -1
        ) {
          return {
            ...weekDay,
            marked: true,
          };
        }
        return weekDay;
      });
      return weekDaysMapped;
    }
    return [];
  };

  const [weekDays, setWeekDays] = useState(
    getInitialValueOfRecurrenceDays() || WeekDays
  );

  const setChecksInfo = useCallback(async () => {
    const subtasks = await getSubtasks(task.id);
    const checksData: CheckItem[] = [];

    subtasks.forEach((subtask: Subtask) => {
      const marked = !!subtask.finished_at;
      checksData.push({
        id: subtask.id,
        name: subtask.name,
        marked,
      });
    });
    setChecks(checksData);
  }, [task.id]);

  const setCommentsInfo = useCallback(async () => {
    const newComments = await getComments(task.id);

    setComents(newComments);
    setLoading(false);
  }, [task.id]);

  const getTaskUsers = useCallback(() => {
    const taskUsers: TaskUser[] = [];
    if (task.users) {
      task.users.forEach((user: User) => {
        taskUsers.push({
          id: user.id,
          name: user.name,
          last_name: user.last_name,
        });
      });
    }
    setUsers(taskUsers);
  }, [task.users]);

  useEffect(() => {
    getTaskUsers();
    setChecksInfo();
    setCommentsInfo();
  }, [setChecksInfo, setCommentsInfo, getTaskUsers]);

  const projectIndex = selectProjectIndexById(projectId!, projects);

  const editIndividualTask = useCallback(async () => {
    setIsDisable(true);
    setSaving(true);
    const recurrenceValueFormated: number[] = [];

    if (recurrence.type === 'WEEK_DAYS') {
      weekDays.forEach((weekDay, index) => {
        if (weekDay.marked === true) {
          recurrenceValueFormated.push(index);
        }
      });
      if (recurrenceValueFormated.length < 1) {
        toast.warn('Por favor, preencha o tempo de recorrencia');
        setIsDisable(false);
        return;
      }
    }
    if (recurrence.type === 'DAYS_AFTER') {
      if (!recurrence.value) {
        toast.warn('Por favor, preencha o tempo de recorrencia');
        setIsDisable(false);
        return;
      }
      if (typeof recurrence.value === 'string') {
        recurrenceValueFormated.push(JSON.parse(recurrence.value)[0]);
      } else {
        recurrenceValueFormated.push(recurrence.value);
      }
    }

    const dateToString = date ? date.toString() : null;
    await apiV2
      .put(`tasks`, {
        include_project_group_progress: true,
        id: task.id,
        name: form.taskName,
        desc: taskDescription,
        project_id: projectId,
        users_ids: users.filter(user => user.id).map(user => user.id),
        due_date: dateToString,
        subtasks: checks,
        tags_ids: tagsList.map((tag: ITag) => tag.id),
        ...(recurrence.type !== 'null'
          ? {
              recurrence_type: recurrence.type,
              recurrence_value: JSON.stringify(recurrenceValueFormated),
            }
          : {}),
      })
      .then(res => {
        toast.success('Task Individual editada com sucesso!');
        if (myTasksView) {
          if (tasks && setTasks) {
            const responseTask = {
              checklist: res.data.checklist,
              created_at: res.data.created_at,
              days_repeat: res.data.days_repeat,
              desc: res.data.desc,
              due_date: res.data.due_date,
              finished_at: res.data.finished_at,
              first_due_date: res.data.first_due_date,
              id: res.data.id,
              name: res.data.name,
              order: res.data.order,
              phase: task.phase,
              phase_id: res.data.phase_id,
              progress: res.data.progress,
              project_id: res.data.project_id,
              project_phase_name: res.data.project_phase_name,
              recurrence_type: res.data.recurrence_type,
              recurrence_value: res.data.recurrence_value,
              recurrence_id: res.data.recurrence_id,
              required: res.data.required,
              status: res.data.status,
              subtask_phase_checklist_items:
                res.data.substask_phase_checklist_items ?? [],
              subtasks: res.data.substasks ?? [],
              tags: res.data.tags,
              top_hierarchy: task.top_hierarchy,
              type: res.data.type,
              updated_at: res.data.updated_at,
              users: res.data.users,
              was_removed: res.data.was_removed,
            };
            const tasksUpdate = tasks.filter(
              tasksItem => tasksItem.id !== task.id
            );
            tasksUpdate.splice(res.data.order - 1, 0, responseTask);
            setTasks([...tasksUpdate]);
          }
        } else if (tasks && editIndividualTasks) {
          const responseTask = {
            checklist: res.data.checklist,
            created_at: res.data.created_at,
            days_repeat: res.data.days_repeat,
            desc: res.data.desc,
            due_date: res.data.due_date,
            finished_at: res.data.finished_at,
            first_due_date: res.data.first_due_date,
            id: res.data.id,
            name: res.data.name,
            order: res.data.order,
            phase_id: res.data.phase_id,
            progress: res.data.progress,
            project_id: res.data.project_id,
            project_phase_name: res.data.project_phase_name,
            recurrence_type: res.data.recurrence_type,
            recurrence_value: res.data.recurrence_value,
            recurrence_id: res.data.recurrence_id,
            required: res.data.required,
            status: res.data.status,
            subtask_phase_checklist_items:
              res.data.substask_phase_checklist_items ?? [],
            subtasks: res.data.substasks ?? [],
            tags: res.data.tags,
            type: res.data.type,
            updated_at: res.data.updated_at,
            users: res.data.users,
            was_removed: res.data.was_removed,
          };
          const tasksUpdate = tasks.filter(
            tasksItem => tasksItem.id !== task.id
          );
          tasksUpdate.splice(res.data.order - 1, 0, responseTask);
          const projectsWithIndividualTaskUpdated = editIndividualTasks(
            tasksUpdate,
            projects,
            res.data.project_id
          );
          const projectsWithProjectUpdated = editProjectDetails(
            res.data.project.users,
            res.data.project.due_date || undefined,
            res.data.project.progress,
            projectId!,
            projectsWithIndividualTaskUpdated
          );

          setProjects(() => projectsWithProjectUpdated);
        }

        handleClose();
      });

    setIsDisable(false);
    setSaving(false);
  }, [
    checks,
    date,

    form.taskName,
    handleClose,
    projectId,
    setTasks,
    myTasksView,
    task.phase,
    task.top_hierarchy,
    recurrence.type,
    tagsList,
    recurrence.value,
    task.id,
    taskDescription,
    tasks,
    users,
    weekDays,
    projects,
    setProjects,
  ]);

  const editIndividualTaskOnBlur = useCallback(async () => {
    const recurrenceValueFormated: number[] = [];

    if (recurrence.type === 'WEEK_DAYS') {
      weekDays.forEach((weekDay, index) => {
        if (weekDay.marked === true) {
          recurrenceValueFormated.push(index);
        }
      });
      if (recurrenceValueFormated.length < 1) {
        toast.warn('Por favor, preencha o tempo de recorrencia');
        setIsDisable(false);
        return;
      }
    }
    if (recurrence.type === 'DAYS_AFTER') {
      if (!recurrence.value) {
        toast.warn('Por favor, preencha o tempo de recorrencia');
        setIsDisable(false);
        return;
      }
      if (typeof recurrence.value === 'string') {
        recurrenceValueFormated.push(JSON.parse(recurrence.value)[0]);
      } else {
        recurrenceValueFormated.push(recurrence.value);
      }
    }
    const dateToString = date ? date.toString() : null;
    await apiV2
      .put(`tasks`, {
        include_project_group_progress: true,
        id: task.id,
        name: form.taskName,
        desc: taskDescription,
        project_id: projectId,
        due_date: dateToString,
        tags_ids: tagsList.map((tag: ITag) => tag.id),
        ...(recurrence.type !== 'null'
          ? {
              recurrence_type: recurrence.type,
              recurrence_value: JSON.stringify(recurrenceValueFormated),
            }
          : {}),
        users_ids: users,
      })
      .then(res => {
        if (myTasksView) {
          if (tasks && editIndividualTasks) {
            const responseTask = {
              checklist: res.data.checklist,
              created_at: res.data.created_at,
              days_repeat: res.data.days_repeat,
              desc: res.data.desc,
              due_date: res.data.due_date,
              finished_at: res.data.finished_at,
              first_due_date: res.data.first_due_date,
              id: res.data.id,
              name: res.data.name,
              order: res.data.order,
              phase: task.phase,
              phase_id: res.data.phase_id,
              progress: res.data.progress,
              project_id: res.data.project_id,
              project_phase_name: res.data.project_phase_name,
              recurrence_type: res.data.recurrence_type,
              recurrence_value: res.data.recurrence_value,
              recurrence_id: res.data.recurrence_id,
              required: res.data.required,
              status: res.data.status,
              subtask_phase_checklist_items:
                res.data.substask_phase_checklist_items ?? [],
              subtasks: res.data.substasks ?? [],
              tags: res.data.tags,
              top_hierarchy: task.top_hierarchy,
              type: res.data.type,
              updated_at: res.data.updated_at,
              users: res.data.users,
              was_removed: res.data.was_removed,
            };
            const tasksUpdate = tasks.filter(
              tasksItem => tasksItem.id !== task.id
            );
            tasksUpdate.splice(res.data.order - 1, 0, responseTask);
            const projectsWithIndividualTaskUpdated = editIndividualTasks(
              tasksUpdate,
              projects,
              projectIndex
            );
            const projectsWithProjectUpdated = editProjectDetails(
              res.data.project.users,
              res.data.project.due_date,
              res.data.project.progress,
              projectId!,
              projectsWithIndividualTaskUpdated
            );

            setProjects(() => projectsWithProjectUpdated);
          }
        } else if (tasks && editIndividualTasks) {
          const responseTask = {
            checklist: res.data.checklist,
            created_at: res.data.created_at,
            days_repeat: res.data.days_repeat,
            desc: res.data.desc,
            due_date: res.data.due_date,
            finished_at: res.data.finished_at,
            first_due_date: res.data.first_due_date,
            id: res.data.id,
            name: res.data.name,
            order: res.data.order,
            phase_id: res.data.phase_id,
            progress: res.data.progress,
            project_id: res.data.project_id,
            project_phase_name: res.data.project_phase_name,
            recurrence_type: res.data.recurrence_type,
            recurrence_value: res.data.recurrence_value,
            recurrence_id: res.data.recurrence_id,
            required: res.data.required,
            status: res.data.status,
            subtask_phase_checklist_items:
              res.data.substask_phase_checklist_items ?? [],
            subtasks: res.data.substasks ?? [],
            tags: res.data.tags,
            type: res.data.type,
            updated_at: res.data.updated_at,
            users: res.data.users,
            was_removed: res.data.was_removed,
          };
          const tasksUpdate = tasks.filter(
            tasksItem => tasksItem.id !== task.id
          );
          tasksUpdate.splice(res.data.order - 1, 0, responseTask);
          const projectsWithIndividualTaskUpdated = editIndividualTasks(
            tasksUpdate,
            projects,
            projectIndex
          );
          const projectsWithProjectUpdated = editProjectDetails(
            res.data.project.users,
            res.data.project.due_date,
            res.data.project.progress,
            projectId!,
            projectsWithIndividualTaskUpdated
          );

          setProjects(() => projectsWithProjectUpdated);
        }
        const projectsWithProjectUpdated = editProjectDetails(
          res.data.project.users,
          res.data.project.due_date,
          res.data.project.progress,
          projectId!,
          projects
        );

        setProjects(() => projectsWithProjectUpdated);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          toast.error(
            'Ocorreu algum problema, atualize a página e tente novamente 😕'
          );
        }
      });
  }, [
    date,
    form.taskName,
    projectId,
    tagsList,
    recurrence.type,
    recurrence.value,
    myTasksView,
    task.phase,
    task.top_hierarchy,
    task.id,
    taskDescription,
    tasks,
    users,
    weekDays,
    projectIndex,
    projects,
    setProjects,
  ]);

  const deleteIndividualTask = useCallback(async () => {
    setIsDisable(true);
    setDeleting(true);
    await apiV2
      .delete(`/tasks/${task.id}`)
      .then(() => {
        toast.success('Task Individual excluída com sucesso. Aguarde!');
        if (tasks) {
          const newProjects = SetIndividualTasks(
            tasks.filter(taskItem => taskItem.id !== task.id),
            projects,
            projectId!
          );

          setProjects(newProjects);
          handleClose();
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          toast.error(
            'Ocorreu algum problema, atualize a página e tente novamente 😕'
          );
        }
      });
    setIsDisable(false);
    setDeleting(false);
  }, [handleClose, projects, projectId, setProjects, task.id, tasks]);

  const postCheckItem = useCallback(
    async (checkContent: string) => {
      await apiV2
        .post(`subtasks`, {
          task_id: task.id,
          name: checkContent,
          include_task_progress: true,
        })
        .then(res => {
          toast.success('Check item salvo com sucesso!');
          const newSubtask: CheckItem = {
            id: res.data.id,
            createdAt: res.data.created_at,
            name: res.data.name,
            marked: false,
          };
          setChecks(checks.concat([newSubtask]));
          if (tasks && editIndividualTasks) {
            const newTask = task;
            newTask.progress = res.data.taskProgress;
            const tasksUpdate = tasks.filter(
              tasksItem => tasksItem.id !== newTask.id
            );

            tasksUpdate.splice(res.data.order - 1, 0, newTask);
            const projectsWithIndividualTaskUpdated = editIndividualTasks(
              tasksUpdate,
              projects,
              projectId!
            );

            setProjects(() => projectsWithIndividualTaskUpdated);
          }
        });
    },
    [checks, setChecks, task, tasks, projectId, projects, setProjects]
  );

  const markCheckBox = useCallback(
    async (id: number | undefined, marked: boolean) => {
      const finishedAt = marked ? new Date() : null;
      await apiV2
        .put(`subtasks`, {
          id,
          finished_at: finishedAt,
          include_task_progress: true,
        })
        .then(res => {
          if (tasks && editIndividualTasks) {
            const newTask = task;
            newTask.progress = res.data.taskProgress;
            const tasksUpdate = tasks.filter(
              tasksItem => tasksItem.id !== newTask.id
            );

            tasksUpdate.splice(res.data.order - 1, 0, newTask);
            const projectsWithIndividualTaskUpdated = editIndividualTasks(
              tasksUpdate,
              projects,
              projectId!
            );

            setProjects(() => projectsWithIndividualTaskUpdated);
          }

          toast.success('Check item atualizado com sucesso!');
        });
    },
    [tasks, task, projectId, projects, setProjects]
  );

  const deleteCheckItem = useCallback(
    (id: number | undefined) => {
      apiV2
        .delete(`subtasks/${id}`)
        .then(res => {
          toast.success('Check item excluído com sucesso. Aguarde!');
          if (tasks && editIndividualTasks) {
            const newTask = task;
            newTask.progress = res.data.task.progress;
            const tasksUpdate = tasks.filter(
              tasksItem => tasksItem.id !== newTask.id
            );

            tasksUpdate.splice(res.data.order - 1, 0, newTask);
            const projectsWithIndividualTaskUpdated = editIndividualTasks(
              tasksUpdate,
              projects,
              projectId!
            );

            setProjects(() => projectsWithIndividualTaskUpdated);
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            toast.error(
              'Ocorreu algum problema, atualize a página e tente novamente 😕'
            );
          }
        });
    },
    [projectId, projects, setProjects, tasks, task]
  );

  return (
    <S.Container>
      <TaskCard close={handleClose} width="100%">
        <header>
          <div>
            <RiFocusLine size={21} color="var(--red-primary)" />
            <p>Task individual</p>
          </div>

          <p> | </p>

          <div>
            <p>Progresso</p>
            <div className="progressWrapper">
              <ProgressDisplay
                width="95%"
                height="1.5rem"
                borderRadius="0.2rem"
                percentage={task.progress ? task.progress : 0}
                colorBar={progressBarsColors.progressColor}
                title={`${task.progress}%`}
              />
            </div>
          </div>
        </header>
        <main style={{ display: 'flex' }}>
          <div className="contentContainer">
            <div className="input">
              <p>Nome da task</p>
              <TextInput
                name="taskName"
                onChange={handleInputChange}
                // onBlur={() => editIndividualTaskOnBlur()}
                width="100%"
                height="2.1rem"
                value={form.taskName}
                defaultValue={form.taskName}
                placeholder="Escreva o nome da task"
                font="400 0.9rem Work Sans"
              />
            </div>

            <div className="input">
              <p>Responsável</p>

              <Responsible users={users} setUsers={setUsers} />
            </div>

            <div className="input">
              <p>Tipo Recorrência</p>

              <Select
                options={RecurrenceDefaultValues}
                value={recurrence.type}
                onChange={(e): void => {
                  setRecurrence({
                    ...recurrence,
                    type: String(e.target.value),
                  });
                }}
              />
            </div>
            {recurrence.type === 'DAYS_AFTER' ? (
              <div className="input">
                <p>Quantos dias após?</p>
                <TextInput
                  name="recurrenceValue"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setRecurrence({
                      ...recurrence,
                      value: parseInt(e.target.value, 10),
                    });
                  }}
                  value={recurrence.value}
                  defaultValue={recurrence.value}
                  width="100%"
                  height="2.1rem"
                  type="number"
                  min="1"
                  placeholder="Quantidade de dias após"
                  font="400 0.9rem Work Sans"
                />
              </div>
            ) : null}
            {recurrence.type === 'WEEK_DAYS' ? (
              <>
                <div className="input">
                  <p style={{ marginBottom: '0.6rem' }}>Dias da semana</p>
                  <Checklist
                    checks={weekDays}
                    setChecks={setWeekDays}
                    canNotAdd
                  />
                </div>
              </>
            ) : null}

            <div className="input" onBlur={() => editIndividualTaskOnBlur()}>
              <p>Descrição da task</p>
              <RichTextEditor
                height={120}
                width="22.5rem"
                initialValue={task.desc}
                contentToSave={(content: string) => {
                  setTaskDescription(content);
                }}
              />
            </div>

            <div className="input" role="button" tabIndex={0}>
              <p style={{ marginBottom: '1rem' }}>Prazo da task</p>

              <Calendar onChange={setDate} date={date || null} activeRemove />
            </div>
          </div>
          <div className="contentContainer">
            <div className="input">
              <p style={{ marginBottom: '0.6rem' }}>Checklist</p>
              <Checklist
                checks={checks}
                setChecks={setChecks}
                postCheckItem={postCheckItem}
                deleteCheckItem={deleteCheckItem}
                markCheckBox={markCheckBox}
              />
            </div>

            <div className="input">
              <p>Adicionar uma Tag</p>
              <TagsCreate
                clientId={task.top_hierarchy?.client_id}
                tagsList={tagsList}
                setTagsList={setTagsList}
              />
            </div>

            <div className="input">
              <p style={{ marginBottom: '0.6rem' }}>Coments</p>
              <Coments
                comments={coments}
                setComments={setComents}
                loading={loading}
                taskId={task.id}
              />
            </div>
          </div>
        </main>
        <footer>
          <div>
            {deleting ? (
              <LoadingButton width="7rem" height="2.1rem" />
            ) : (
              <Button
                id="delete-button"
                disabled={isDisable}
                onClick={() => deleteIndividualTask()}
              >
                <p>Deletar</p>
              </Button>
            )}
            {saving ? (
              <LoadingButton width="10rem" height="2.1rem" />
            ) : (
              <Button
                id="confirm-button"
                disabled={isDisable}
                onClick={() => editIndividualTask()}
              >
                <p>Salvar</p>
              </Button>
            )}
          </div>
        </footer>
      </TaskCard>
    </S.Container>
  );
}
