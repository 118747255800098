import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export const editTaskNextPhase = async (
  taskId: number,
  phaseId: number
): Promise<Task> => {
  try {
    const response = await apiV2.put(`tasks`, {
      include_project_group_progress: true,
      id: taskId,
      phase_id: phaseId,
    });

    const task = response.data;

    return task;
  } catch {
    toast.error(
      'Ocorreu algum problema editando a próxima fase da task, atualize a página e tente novamente 😕'
    );

    return {} as Task;
  }
};
