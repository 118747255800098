import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-right: auto;

  a {
    padding: 0.5rem 0;
    text-decoration: none;
  }

  button {
    display: flex;
    align-items: center;
    margin-right: 0.8rem;

    border: none;
    background: none;
    color: ${({ theme }) => theme.text};
    .icon {
      margin-right: 0.5rem;
    }
  }
`;
