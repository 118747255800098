import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 30vh;
  border-radius: 0.5rem;
  padding: 1.3rem;
  background: ${({ theme }) => theme.backgroundSecondary};
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    margin-left: 0.8rem;
    font-size: 1.2rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  min-height: 10rem;
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2.3rem;
`;

export const KrWrapper = styled.div`
  width: 18rem;
  height: 100%;
`;
