import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--gray);
  overflow: hidden;
  height: 2.5rem;
  text-overflow: ellipsis;
  text-align: start;
  white-space: nowrap;
  background: ${({ theme }) => theme.backgroundSecondary};
  margin: 0.3125rem;
  padding: 0.625rem;
  border-radius: 4px;

  a {
    color: ${({ theme }) => theme.text};
    text-decoration: none;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0.625rem;
  }

  .avatar {
    border: 1px solid var(--black);
    border-radius: 50%;
    padding: 0px;
    width: 2.25rem !important;
    height: 2.25rem !important;
    font-size: 2.25rem;

    img {
      max-width: none !important;
    }
  }
`;
