import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { OptionsSelect } from '../Inputs/OptionsSelect';
import api from '../../services/api';
import UserAvatar from '../UserAvatar';
import Loading from '../Loadings/CustomLoading';

import { Container } from './styles';

interface Responsible {
  users: IUserId[];
  setUsers: React.Dispatch<React.SetStateAction<IUserId[]>>;
  placeholder?: string;
  maxUsers?: number;
}

interface apiUser {
  id: string;
  name: string;
  last_name: string;
}

const Responsible: React.FC<Responsible> = ({
  users,
  setUsers,
  placeholder = 'Selecione um usuário',
}) => {
  const [newUser, setNewUser] = useState(-1);
  const [options, setOptions] = useState([
    { name: 'Carregando', value: String(-1) },
  ]);

  const [firstTime, setFirstTime] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleGetUsers = useCallback(() => {
    setLoading(true);

    try {
      api.get('users').then(res => {
        const userOptions = [{ name: '', value: '' }];
        res.data.forEach((user: apiUser) => {
          userOptions.push({
            name: `${user.name} ${user.last_name}`,
            value: user.id,
          });
        });
        setOptions(userOptions);
      });
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUserRemove = useCallback(
    id => {
      if (newUser === id) {
        setUsers(users.filter(userId => userId !== id));
        setNewUser(-1);
      } else {
        setUsers(users.filter(userId => userId !== id));
      }
    },
    [newUser, setUsers, users]
  );

  const addUser = useCallback(
    user => {
      const alreadyAdd = users.filter(userId => userId === user);

      if (alreadyAdd.length === 0) {
        if (user.id < 0) {
          toast.error('Selecione um usúario');
        }

        setUsers([...users, user]);
      }
    },
    [setUsers, users]
  );

  useEffect(() => {
    if (firstTime) {
      handleGetUsers();
      setFirstTime(false);
    }
  }, [firstTime, handleGetUsers]);

  useEffect(() => {
    if (newUser > -1) {
      addUser(newUser);
    }
  }, [addUser, newUser]);

  return (
    <>
      <OptionsSelect
        value={newUser}
        options={options}
        placeholder={placeholder}
        search
        onChange={setNewUser}
      />
      <Container>
        <div className="users" data-testid="responsible">
          {loading ? (
            <Loading />
          ) : (
            users &&
            users.map(user => (
              <div
                key={Math.floor(Math.random() * 65536)}
                className="avatarContainer"
                onClick={() => handleUserRemove(user)}
                role="button"
                aria-hidden
              >
                <div style={{ height: '0.8rem' }} />
                {loading ? (
                  <Loading />
                ) : (
                  user && <UserAvatar id={Number(user)} />
                )}
                <p className="remove">Remover</p>
              </div>
            ))
          )}
        </div>
      </Container>
    </>
  );
};

export default Responsible;
