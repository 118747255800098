import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getGroupsWithPhase(
  projectId: number | undefined
): Promise<
  {
    id: number;
    name: string;
    phase_id: number;
  }[]
> {
  try {
    const response = await apiV2.get(`/groups/with-phase/${projectId}`);

    const group = response.data;

    return group;
  } catch {
    toast.error(
      'Ocorreu algum problema ao carregar os grupos, atualize a página e tente novamente :S'
    );
    return [] as {
      id: number;
      name: string;
      phase_id: number;
    }[];
  }
}
