import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import apiV2 from '../../../../services/apiV2';

import { TextInput, Button } from '../../../../components/Inputs';

import { OptionsSelect } from '../../../../components/Inputs/OptionsSelect';

interface HeaderComponent {
  showConfig: string;
  setShowConfig: React.Dispatch<React.SetStateAction<string>>;
  checkinId: number;
  newLink: string | null;
  setNewBiLink: React.Dispatch<React.SetStateAction<string | null>>;
}

const Header: React.FC<HeaderComponent> = ({
  showConfig,
  setShowConfig,
  checkinId,
  newLink,
  setNewBiLink,
}) => {
  const [link, setLink] = useState<string | null>('');

  useEffect(() => {
    setLink(newLink);
  }, [newLink, setShowConfig]);

  useEffect(() => {
    if (newLink && newLink.includes('powerbi')) {
      setShowConfig('PowerBI');
    } else if (newLink && newLink.includes('datastudio')) {
      setShowConfig('DataStudio');
    } else if (newLink && newLink.includes('dashgoo')) {
      setShowConfig('DataStudio');
    } else {
      setShowConfig('Manual');
    }
  }, [newLink, setShowConfig]);

  const handleSubmit = useCallback(async () => {
    if (
      link?.includes('powerbi') ||
      link?.includes('datastudio') ||
      link?.includes('dashgoo') ||
      link?.includes('miro')
    ) {
      toast.warn('Atualizando!');
      await apiV2
        .put('checkinDraft', {
          id: checkinId,
          bi_link: link,
        })
        .then(() => {
          toast.success('Atualizado!');
          setNewBiLink(link);
        })
        .catch(() => {
          toast.error('Falha ao atualizar!');
        });
    } else toast.warn('Utilize powerbi, data studio, dashgoo ou miro.');
  }, [checkinId, link, setNewBiLink]);

  return (
    <header>
      <div className="configs" data-testid="select-type">
        <p>Como você quer mostrar os dados?</p>
        <OptionsSelect
          placeholder="Selecione"
          value={showConfig}
          options={[
            { name: 'Manual', value: 'Manual' },
            { name: 'Power BI', value: 'PowerBI' },
            { name: 'Data Studio', value: 'DataStudio' },
          ]}
          onChange={setShowConfig}
        />

        {(showConfig === 'PowerBI' || showConfig === 'DataStudio') && (
          <div className="biLink">
            <TextInput
              placeholder="Insira o link do seu bi"
              value={link === null ? undefined : link}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLink(e.target.value);
              }}
            />
            <Button
              background="var(--red-primary)"
              height="1.8rem"
              width="6rem"
              font="800 0.7rem Work Sans"
              text="Salvar"
              onClick={() => handleSubmit()}
            />
          </div>
        )}
        {link !== null &&
          !(
            link.includes('powerbi') ||
            link.includes('datastudio') ||
            link.includes('dashgoo') ||
            link.includes('miro')
          ) && (
            <p style={{ marginLeft: '0.5rem', color: 'var(--red-primary)' }}>
              Link não suportado.
            </p>
          )}
      </div>
    </header>
  );
};

export default Header;
