/* eslint-disable no-useless-escape */
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { MdClose, MdDone } from 'react-icons/md';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { ReactComponent as TopAtomo } from '../../../../assets/images/top-atomo.svg';
import { ReactComponent as BottomAtomo } from '../../../../assets/images/bottom-atomo.svg';

import api from '../../../../services/api';

import {
  Container,
  BackgroundContainer,
  Form,
  Logo,
  InputContainer,
  PassCheck,
} from './styles';

export function ResetPassword() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const history = useHistory();
  const { id }: { id: string } = useParams();

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);

    if (password === passwordConfirmation) {
      if (
        password.length > 7 &&
        /([ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/g.test(password) &&
        /([0-9]+)/g.test(password) &&
        /([A-Z]+)/g.test(password) &&
        /([a-z]+)/g.test(password)
      ) {
        await api
          .post('/reset_password', {
            token: id,
            password,
          })
          .then(() => {
            toast.success('Senha alterada com sucesso!');
            history.push(`/`);
          })
          .catch(err => {
            if (err.response.data.error === 'Invalid token.') {
              toast.error(
                'Link utilizado é inválido, por favor, faça o processo novamente!'
              );
              history.push(`/forgot_password`);
            }
            if (err.response.data.error === 'Token expired.') {
              toast.error(
                'Link utilizado é seu pedido expirou, por favor, faça o processo novamente!'
              );
              history.push(`/forgot_password`);
            }
          });
      } else {
        toast.error('Sua senha não atende os requistos de segurança');
      }
    } else {
      toast.error('As senhas não são iguais!');
    }

    setLoading(false);
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Container>
      <BackgroundContainer />
      <TopAtomo
        style={{
          position: 'absolute',
          top: '-6%',
          right: '-4%',
        }}
      />
      <BottomAtomo
        style={{
          position: 'absolute',
          bottom: '-6%',
          left: '-4%',
        }}
      />
      <Form onSubmit={onSubmit}>
        <Logo />
        <InputContainer htmlFor="email" style={{ marginBottom: 0 }}>
          Redefina sua senha
          <input
            type={passwordShown ? 'text' : 'password'}
            name="password"
            placeholder="Senha"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
          <BsFillEyeSlashFill onClick={togglePasswordVisiblity} />
        </InputContainer>
        <PassCheck>
          <li>
            {password.length > 7 ? (
              <MdDone color="green" />
            ) : (
              <MdClose color="red" />
            )}
            8 Caracteres
          </li>
          <li>
            {/([ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)/g.test(password) ? (
              <MdDone color="green" />
            ) : (
              <MdClose color="red" />
            )}
            Caracter especial
          </li>
          <li>
            {/([0-9]+)/g.test(password) ? (
              <MdDone color="green" />
            ) : (
              <MdClose color="red" />
            )}
            Número
          </li>
          <li>
            {/([A-Z]+)/g.test(password) ? (
              <MdDone color="green" />
            ) : (
              <MdClose color="red" />
            )}
            Letra maiúscula
          </li>
          <li>
            {/([a-z]+)/g.test(password) ? (
              <MdDone color="green" />
            ) : (
              <MdClose color="red" />
            )}
            Letra minúscula
          </li>
        </PassCheck>
        <InputContainer htmlFor="email">
          Confirme sua senha
          <input
            type={passwordShown ? 'text' : 'password'}
            name="password_confirmation"
            placeholder="Confirme sua senha"
            style={{
              color: password === passwordConfirmation ? 'green' : 'red',
            }}
            value={passwordConfirmation}
            onChange={e => setPasswordConfirmation(e.target.value)}
            required
          />
          <BsFillEyeSlashFill onClick={togglePasswordVisiblity} />
        </InputContainer>
        <button type="submit">{loading ? 'Carregando...' : 'Confirmar'}</button>
        <p>
          Voltar para o <Link to="/">Login</Link>
        </p>
      </Form>
    </Container>
  );
}
