import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getComments(taskId: number): Promise<Comments[]> {
  try {
    const response = await apiV2.get(`/task/comments/${taskId}`);

    const comments = response.data;

    return comments;
  } catch {
    toast.error(
      'Ocorreu algum problema ao carregar os comentários, atualize a página e tente novamente :S'
    );
    return [];
  }
}
