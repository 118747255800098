import React from 'react';
import { motion } from 'framer-motion';

import { Container, ChartContainer } from './styles';

export default function DonutChart({ porcentage = 0, text, value }) {
  // eslint-disable-next-line no-restricted-globals
  const numberVerify = isNaN(porcentage);
  // const data = useMemo(() => {
  //   return {
  //     labels: ['Pronto', 'Não Pronto'],
  //     datasets: [
  //       {
  //         backgroundColor: [color, secondColor],
  //         label: 'Valor',
  //         borderWidth: 0,
  //         data: numberVerify ? [0, 100] : [porcentage, porcentage - 100],
  //       },
  //     ],
  //   };
  // }, [color, numberVerify, porcentage, secondColor]);

  return (
    <Container>
      <p>{text}</p>
      <motion.div
        whileTap={{
          scale: 1.1,
          borderRadius: '100%',
        }}
      >
        <ChartContainer
          style={{ width: 'calc(0.20 * 90vw)', height: 'calc(0.20 * 90vh)' }}
        >
          <div className="inside">
            <p>{numberVerify ? 0 : Math.round(porcentage)}%</p>
            <p>{value}</p>
          </div>
          {/* 
          <Doughnut
            data={data}
            options={{
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 73,
              plugins: {
                labels: {
                  render: 'label',
                  fontSize: 0,
                },
              },
            }}
          /> */}
        </ChartContainer>
      </motion.div>
    </Container>
  );
}
