import React, { useCallback, useEffect, useState } from 'react';
import { RiEyeLine, RiAddCircleLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import { Button } from '../../../../../components/Inputs';

import ProjectItem from './Project';
import apiV2 from '../../../../../services/apiV2';

interface PreviewProps {
  handleClose: () => void;
}

function Preview({ handleClose }: PreviewProps) {
  const [projects, setProjects] = useState<Project[]>([]);
  const { id } = useParams<Params>();

  const getProjects = useCallback(async () => {
    await apiV2.get(`projects/${id}`).then(res => setProjects(res.data));
  }, [id]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <Container>
      <TaskCard close={handleClose} width="100%">
        <header>
          <RiEyeLine size={21} color="var(--red-primary)" />
          <p>Preview</p>
        </header>
        <main>
          {projects &&
            projects.map(project => {
              return (
                <div key={project.id} style={{ marginBottom: '2rem' }}>
                  <ProjectItem project={project} />
                </div>
              );
            })}

          <div role="button" aria-hidden className="addProject">
            <RiAddCircleLine
              className="initialButton"
              size={21}
              color="var(--red-primary)"
            />
            <p>Adicionar Projeto</p>
          </div>
        </main>
        <footer>
          <Button
            background="var(--green-primary)"
            height="2.1rem"
            width="10.5rem"
            font="400 0.9rem Work Sans"
            className="rightButton"
          >
            <p>Importar</p>
          </Button>
        </footer>
      </TaskCard>
    </Container>
  );
}

export default Preview;
