/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { FaRegListAlt, FaSpinner, FaCheckDouble } from 'react-icons/fa';

import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import NewGroup from '../../../../components/Modals/NewGroup';
import NewIndividualTask from '../../../../components/Modals/NewIndividualTask';
import { Modal } from '../../../../styles';
import { GroupCard } from '../GroupCard';
import { IndividualTaskCard } from '../IndividualTaskCard';
import { GroupOrTaskLoading } from '../Loadings';
import * as S from './styles';
import { changeKanbanTaskOrder } from '../../../../services/ChangeKanbanTaskOrder';
import { editProjectDetails } from '../../../../utils/EditProjectDetails';
import { useTasks } from '../../../../../../contexts/TasksContext';
import { SetIndividualTasks } from '../../../../utils/SetIndividualTasks';
import selectProjectIndexById from '../../../../utils/SelectProjectIndexById';
import { getGroupsAndIndividualTasks } from '../../../../services/GetGroupsAndIndividualTasks';

export function ProjectBoard() {
  const { projectId } = useParams<TasksParams>();
  const { projects, setProjects, query } = useTasks();
  const [draggingTimeOut, setDraggingTimeOut] = useState(0);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);

  const projectIndex = selectProjectIndexById(+projectId, projects);

  const setGroupsAndTasksInfos = useCallback(async () => {
    setIsLoadingGroups(true);
    const globalProjects = cloneDeep(projects);
    const [
      responseGroups,
      responseIndividualTasks,
    ] = await getGroupsAndIndividualTasks(+projectId, query);

    globalProjects[projectIndex] = {
      ...globalProjects[projectIndex],
      groups: responseGroups,
      tasks: responseIndividualTasks,
    };
    setProjects([...globalProjects]);
    setIsLoadingGroups(false);
  }, [
    query,
    setProjects,
    setIsLoadingGroups,
    projectId,
    projectIndex,
    projects,
  ]);

  useEffect(() => {
    if (!projects[projectIndex].groups && !projects[projectIndex].tasks) {
      setGroupsAndTasksInfos();
    }
  }, [setGroupsAndTasksInfos, projectIndex, projects]);

  const handleOpenModal = (type: string): void => {
    type === 'new'
      ? setOpenModalNew(!openModalNew)
      : setOpenModalCreate(!openModalCreate);
  };

  const handleCloseModal = (): void => {
    setOpenModalNew(false);
    setOpenModalCreate(false);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') {
      setOpenModalCreate(false);
      setOpenModalNew(false);
    }
  };

  const changeTasktOrder = useCallback(
    async (
      taskSelected: Task,
      projectPhaseName: string,
      newOrder: number | null
    ) => {
      const body = {
        id: taskSelected.id,
        include_project_group_progress: true,
        project_phase_name: projectPhaseName,
        order: newOrder,
      };

      const isTaskDone = {
        ...(!taskSelected.finished_at &&
          projectPhaseName === 'DONE' && { finished_at: new Date() }),
      };
      const isTaskUndone = {
        ...(taskSelected.finished_at &&
          projectPhaseName !== 'DONE' && { finished_at: null }),
      };
      Object.assign(body, isTaskDone || isTaskUndone);

      const newTaskOrder = await changeKanbanTaskOrder(body);

      if (newTaskOrder !== undefined) {
        const projectsWithProjectUpdated = editProjectDetails(
          newTaskOrder.project.users,
          newTaskOrder.project.due_date,
          newTaskOrder.project.progress,
          +projectId,
          projects
        );

        setProjects(() => projectsWithProjectUpdated);
      }
    },
    [projects, projectId, setProjects]
  );

  const nextProjectPhaseName = (
    projectPhaseName: 'TODO' | 'DOING' | 'DONE' | string
  ): ProjectPhaseNameOptions => {
    if (projectPhaseName === 'TODO') return 'DOING';
    return 'DONE';
  };

  const onDragEnd = async (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const draggingTask = projects[projectIndex].tasks.filter(
      task => task.id === +draggableId
    )[0];

    if (draggingTask) {
      const projectPhaseNameDestiny =
        destination?.droppableId || nextProjectPhaseName(source.droppableId);
      draggingTask.project_phase_name = projectPhaseNameDestiny;
      const order = destination.index + 1;

      const tasksToUpdate = [...projects[projectIndex].tasks];
      const prevTasks = tasksToUpdate.splice(
        destination.index,
        1,
        draggingTask
      );

      tasksToUpdate.splice(source.index, 1, prevTasks[0]);

      const newProjects = SetIndividualTasks(
        tasksToUpdate,
        projects,
        +projectId
      );

      setProjects(() => newProjects);

      setDraggingTimeOut(
        window.setTimeout(() => {
          changeTasktOrder(draggingTask, projectPhaseNameDestiny, order);
        }, 500)
      );
      if (draggingTimeOut) {
        clearTimeout(draggingTimeOut);
      }
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <S.Wrapper>
          <S.Board>
            <S.Title>
              <FaRegListAlt size={14} color="var(--green-primary)" />
              <p>To do</p>
            </S.Title>
            <S.Content>
              <Droppable droppableId="TODO">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ height: '100%' }}
                  >
                    {!isLoadingGroups ? (
                      projects[projectIndex].groups &&
                      projects[projectIndex].groups
                        .filter(group => group.kanban_phase === 'TODO')
                        .map(group => {
                          if (group) {
                            return (
                              <div key={group.id}>
                                <GroupCard
                                  group={group}
                                  groups={projects[projectIndex].groups}
                                />
                              </div>
                            );
                          }
                          return false;
                        })
                    ) : (
                      <>
                        <GroupOrTaskLoading />
                        <GroupOrTaskLoading />
                      </>
                    )}
                    {projects[projectIndex].tasks &&
                      projects[projectIndex].tasks
                        .filter(task => task.project_phase_name === 'TODO')
                        .map((task, index) => {
                          if (task) {
                            return (
                              <div key={task.id}>
                                <IndividualTaskCard
                                  task={task}
                                  index={index}
                                  tasks={projects[projectIndex].tasks}
                                />
                              </div>
                            );
                          }
                          return false;
                        })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </S.Content>
            <S.ButtonsArea>
              <button
                id="new-group"
                type="button"
                onClick={() => handleOpenModal('new')}
              >
                Novo Grupo
              </button>
              <button
                id="new-individual-task"
                type="button"
                onClick={() => handleOpenModal('group')}
              >
                Nova Task Individual
              </button>
            </S.ButtonsArea>
          </S.Board>
          <S.Board>
            <S.Title>
              <FaSpinner size={14} color="var(--yellow-primary)" />
              <p>In Progress</p>
            </S.Title>
            <S.Content>
              <Droppable droppableId="DOING">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ height: '100%' }}
                  >
                    {!isLoadingGroups ? (
                      projects[projectIndex].groups &&
                      projects[projectIndex].groups
                        .filter(group => group.kanban_phase === 'DOING')
                        .map(group => {
                          if (group) {
                            return (
                              <div key={group.id}>
                                <GroupCard
                                  group={group}
                                  groups={projects[projectIndex].groups}
                                />
                              </div>
                            );
                          }
                          return false;
                        })
                    ) : (
                      <>
                        <GroupOrTaskLoading />
                        <GroupOrTaskLoading />
                      </>
                    )}
                    {projects[projectIndex].tasks &&
                      projects[projectIndex].tasks
                        .filter(task => task.project_phase_name === 'DOING')
                        .map((task, index) => {
                          if (task) {
                            return (
                              <div key={task.id}>
                                <IndividualTaskCard
                                  task={task}
                                  index={index}
                                  tasks={projects[projectIndex].tasks}
                                />
                              </div>
                            );
                          }
                          return false;
                        })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </S.Content>
          </S.Board>
          <S.Board>
            <S.Title>
              <FaCheckDouble size={14} color="var(--cyan-primary)" />
              <p>Done</p>
            </S.Title>
            <S.Content>
              <Droppable droppableId="DONE">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ height: '100%' }}
                  >
                    {!isLoadingGroups ? (
                      projects[projectIndex].groups &&
                      projects[projectIndex].groups
                        .filter(group => group.kanban_phase === 'DONE')
                        .map(group => {
                          if (group) {
                            return (
                              <div key={group.id}>
                                <GroupCard
                                  group={group}
                                  groups={projects[projectIndex].groups}
                                  opacity={0.6}
                                />
                              </div>
                            );
                          }
                          return false;
                        })
                    ) : (
                      <>
                        <GroupOrTaskLoading />
                        <GroupOrTaskLoading />
                      </>
                    )}
                    {projects[projectIndex].tasks &&
                      projects[projectIndex].tasks
                        .filter(task => task.project_phase_name === 'DONE')
                        .map((task, index) => {
                          if (task) {
                            return (
                              <div key={task.id}>
                                <IndividualTaskCard
                                  task={task}
                                  index={index}
                                  tasks={projects[projectIndex].tasks}
                                  opacity={0.6}
                                />
                              </div>
                            );
                          }
                          return false;
                        })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </S.Content>
          </S.Board>
        </S.Wrapper>
      </DragDropContext>
      {openModalNew && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NewGroup handleClose={handleCloseModal} projectId={+projectId} />
        </Modal>
      )}
      {openModalCreate && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NewIndividualTask
            projectId={+projectId}
            handleClose={handleCloseModal}
            tasks={projects[projectIndex].tasks}
          />
        </Modal>
      )}
    </>
  );
}
