import React from 'react';
import { RiAddCircleLine } from 'react-icons/ri';
import { Container } from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const AddNewButton: React.FC<ButtonProps> = ({
  children,
  loading,
  onClick,
}) => {
  return (
    <Container type="button" onClick={onClick}>
      <RiAddCircleLine />
      {loading ? 'Carregando...' : children}
    </Container>
  );
};

export default AddNewButton;
