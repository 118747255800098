import { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FaRegEdit } from 'react-icons/fa';
import {
  RiTimeLine,
  RiLineChartFill,
  RiUser3Line,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { Tag } from '../../../../components/Tag';
import { useTags } from '../../../../../../contexts/TagsContext';
import useProgress from '../../../../../../hooks/useProgress';
import ProjectEdit from '../../../../components/Modals/ProjectEdit';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { Responsibles } from '../../../../components/Responsibles';
import { Modal } from '../../../../styles';
import { useTasks } from '../../../../../../contexts/TasksContext';
import * as S from './styles';
import { restoreProject } from '../../../../services/RestoreProject';

interface ProjectCardProps {
  project: Project;
}

export function ProjectCard({ project }: ProjectCardProps) {
  const history = useHistory();
  const { id } = useParams<Params>();
  const { projects, setProjects } = useTasks();
  const { progress, colors } = useProgress();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();

  useEffect(() => {
    setTags(() => (project.tags ? project.tags : []));
    if (history.location.pathname.includes('open')) {
      setIsOpenModal(true);
    }
  }, [project, history.location.pathname]);

  const periodProgressPercent = useMemo(
    () => progress(project.created_at, project.due_date),
    [progress, project.created_at, project.due_date]
  );

  const periodProgressTitle = useMemo(
    () =>
      project.due_date
        ? format(new Date(project.due_date), 'dd.MMMM.yyyy', {
            locale: ptBR,
          }).toString()
        : 'Sem prazo',
    [project.due_date]
  );

  const progressBarsColors = useMemo(
    () => colors(project.due_date, project.finished_at),
    [colors, project.due_date, project.finished_at]
  );

  const progressTitle = useMemo(
    () => `${project.progress ? Math.round(project.progress * 100) / 100 : 0}%`,
    [project.progress]
  );

  const invertModalVisibility = (): void => {
    setIsOpenModal(isOpen => !isOpen);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') invertModalVisibility();
  };

  const handleRestoreProject = useCallback(
    async (projectToRestore: Project) => {
      restoreProject(projectToRestore.id).then(() => {
        const projectUndeleted = {
          ...projectToRestore,
          was_removed: false,
        };

        const projectsUpdate = projects.filter(
          projectItem => projectItem.id !== projectToRestore.id
        );

        projectsUpdate.splice(projectToRestore.order - 1, 0, projectUndeleted);
        setProjects(() => projectsUpdate);
        toast.success('Projeto restaurado com sucesso');
      });
    },
    [projects, setProjects]
  );

  return (
    <>
      <S.Wrapper>
        <S.Container id="header">
          <button type="button" onClick={invertModalVisibility}>
            <FaRegEdit size={16} id="edit-button" color="var(--gray)" />
          </button>
        </S.Container>
        <S.Container id="tag-area">
          {tags &&
            tags.map((tag: ITag, index) => {
              if (index >= 10) {
                return false;
              }
              return (
                <Tag
                  key={tag.id}
                  tag={tag}
                  type="project_id"
                  typeId={project.id}
                  setTagsList={setTags}
                  showLabel={show}
                  setShowLabel={handleShowTags}
                />
              );
            })}
        </S.Container>
        <S.Container id="name">
          <h4>Projeto</h4>
          <p>{project.name}</p>
        </S.Container>
        <S.Container id="period">
          <S.Header>
            <RiTimeLine className="icon" size={20} color="var(--red-primary)" />
            <h5>Prazo</h5>
          </S.Header>
          <S.Content className="progress-bar">
            <ProgressDisplay
              width="99%"
              height="1.45rem"
              borderRadius="0.3rem"
              percentage={periodProgressPercent}
              title={periodProgressTitle}
              colorBar={progressBarsColors.dueDateColor}
            />
          </S.Content>
        </S.Container>
        <S.Container id="progress">
          <S.Header>
            <RiLineChartFill
              className="icon"
              size={20}
              color="var(--red-primary)"
            />
            <h5>Progresso</h5>
          </S.Header>
          <S.Content className="progress-bar">
            <ProgressDisplay
              width="99%"
              height="1.45rem"
              borderRadius="0.3rem"
              title={progressTitle}
              percentage={project.progress}
              colorBar={progressBarsColors.progressColor}
            />
          </S.Content>
        </S.Container>
        <S.Container id="responsibles">
          <S.Header>
            <RiUser3Line
              className="icon"
              size={20}
              color="var(--red-primary)"
            />
            <h5>Responsável</h5>
          </S.Header>
          <S.Content>
            {project.was_removed ? (
              <S.RestoreProject>
                <button
                  type="button"
                  title={project.name}
                  onClick={() => handleRestoreProject(project)}
                >
                  <RiDeleteBin2Line size={20} color="var(--red-primary)" />
                  <p id="restore-label">Restaurar Projeto</p>
                </button>
              </S.RestoreProject>
            ) : (
              <Responsibles users={project.users} />
            )}
          </S.Content>
        </S.Container>
        <S.Container id="button">
          <button
            type="button"
            onClick={() =>
              history.push(`/tasks/${id}/kanban/projects/${project.id}`)
            }
          >
            Abrir
          </button>
        </S.Container>
      </S.Wrapper>
      {isOpenModal && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <ProjectEdit
            project={project}
            handleClose={invertModalVisibility}
            tags={tags}
          />
        </Modal>
      )}
    </>
  );
}
