import React, { useCallback } from 'react';

import { RiCompassLine, RiUserLine } from 'react-icons/ri';

import UserAvatar from '../../../../../components/UserAvatar';
import ProgressBar from '../../../../../components/Progressbar';

import { CardEditing } from '../types';

import * as S from './styles';

const MetaCard: React.FC<CardEditing> = ({ cardInfo }) => {
  const handlePercentage = useCallback(() => {
    if (cardInfo.is_the_lesser_the_better) {
      return ((1 - cardInfo.value / cardInfo.goal) * 100).toFixed(0);
    }

    return ((cardInfo.value / cardInfo.goal) * 100).toFixed(0);
  }, [cardInfo.value, cardInfo.goal, cardInfo.is_the_lesser_the_better]);

  const handleProgressColor = useCallback(progress => {
    if (progress >= 100) {
      return 'var(--green-primary)';
    }

    if (progress >= 80) {
      return 'var(--cyan-primary)';
    }

    return 'var(--yellow-primary)';
  }, []);

  return (
    <S.Container>
      <div className="name">
        <p>{cardInfo.name}</p>
      </div>

      <S.MetaProgress>
        <div className="metaName">
          <RiCompassLine size={20} color="var(--red-primary)" />
          <p>Meta</p>
        </div>

        <ProgressBar
          percentage={`${handlePercentage()}`}
          color={handleProgressColor(handlePercentage())}
          title={`${handlePercentage()} %`}
        />
      </S.MetaProgress>

      <S.Users>
        <RiUserLine size={19} color="var(--red-primary)" />
        <p>Time</p>
        <div className="responsibles">
          {cardInfo.users_id &&
            cardInfo.users_id.map(user => {
              return <UserAvatar key={user.id} id={user.id} size="1.5rem" />;
            })}
        </div>
      </S.Users>
    </S.Container>
  );
};

export default MetaCard;
