import { RiFoldersLine } from 'react-icons/ri';
import { useTasks } from '../../../../../contexts/TasksContext';
import { ProjectCardLoading } from '../components/Loadings';
import { ProjectCard } from '../components/ProjectCard';
import * as S from './styles';

export function Main() {
  const { projects, projectsLoading } = useTasks();

  return (
    <S.Wrapper>
      <S.Header>
        <RiFoldersLine className="icon" size={20} color="var(--red-primary)" />
        <p>Projetos</p>
      </S.Header>
      <S.Container>
        {!projectsLoading ? (
          projects.map(project => {
            return (
              <div key={project.id}>
                <ProjectCard project={project} />
              </div>
            );
          })
        ) : (
          <ProjectCardLoading data-testid="projects-loading" />
        )}
      </S.Container>
    </S.Wrapper>
  );
}
