import { cloneDeep } from 'lodash';
import selectProjectIndexById from './SelectProjectIndexById';

export const SetIndividualTasks = (
  tasks: Task[],
  projects: Project[],
  projectId: number
) => {
  const globalProjects = cloneDeep(projects);

  const projectIndex = selectProjectIndexById(projectId, globalProjects);

  const selectedProject = globalProjects[projectIndex];

  selectedProject.tasks = tasks;

  return globalProjects;
};
