import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  color: white;

  form {
    display: flex;
    flex-direction: column;
  }

  input {
    margin-bottom: 1rem;
  }
`;
