import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

import { format } from 'date-fns';

import { Props } from 'react-apexcharts';
import getDefaultOptions from '../../../../../../styles/defaultChartStyles';
import * as S from './styles';
import { DashData } from '../../../../types';

interface CpChart {
  dashData: DashData;
}

type DataSetsProps = {
  name: string;
  data: number[];
};

const CplCpoChart: React.FC<CpChart> = ({ dashData }) => {
  const data = useMemo(() => {
    const datasets: DataSetsProps[] = [];
    const dateArrays: Date[][] = [];
    let biggestArrayIndex = 0;

    dashData.cpPresentation.forEach(cp => {
      const fixedCp: number[] = [];
      cp.cp.forEach(newCp => {
        fixedCp.push(Number(newCp.toFixed(2)));
      });

      datasets.push({ name: cp.name, data: fixedCp });
    });

    dashData.cpPresentation.forEach(object => {
      dateArrays.push(object.dates);
    });

    biggestArrayIndex = dateArrays
      .map(a => a.length)
      .indexOf(Math.max(...dateArrays.map(a => a.length)));

    return {
      labels:
        dateArrays[biggestArrayIndex] &&
        dateArrays[biggestArrayIndex].map(date => {
          return format(new Date(date), 'dd/MM');
        }),
      datasets,
    };
  }, [dashData.cpPresentation]);

  const defaultOptions = getDefaultOptions({ tooltipOption: true });

  const options: Props['ApexOptions'] = {
    ...defaultOptions,
    xaxis: {
      categories: data.labels,
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
  };

  return (
    <S.Container>
      <h1>Custos por</h1>

      <Chart
        options={options}
        series={data.datasets}
        type="area"
        width="100%"
      />
    </S.Container>
  );
};

export default CplCpoChart;
