import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { RiArrowRightLine, RiArrowLeftLine } from 'react-icons/ri';
import { Button } from '../../../../components/Inputs';
import { ReactComponent as TopAtomo } from '../../../../assets/images/top-atomo.svg';
import { ReactComponent as BottomAtomo } from '../../../../assets/images/bottom-atomo.svg';

import { CheckinDraft, DashData, TaskProgress } from '../../types';

import apiV2 from '../../../../services/apiV2';
import api from '../../../../services/api';
import Loading from '../../../../components/Loadings/Loading';

import FunnelSlide from './FunnelSlide';
import Victories from './Victories';
import Lessons from './Lessons';
import OkrSlide from './OkrSlide';
import PremisesAndRisks from './PremisesAndRisks';
import TasksSlide from './TasksSlide';
import FinalSlide from './FinalSlide';

import * as S from './styles';
import Observations from './Observations';

export interface Params {
  id: string;
  draft: string;
}

const Slides: React.FC = () => {
  const handle = useFullScreenHandle();
  const history = useHistory();
  const { id, draft } = useParams<Params>();
  const [clientName, setClientName] = useState('');
  const [actualSlide, setActualSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [quarter, setQuarter] = useState(0);
  const userTemplate = {
    id: 0,
    name: '',
    last_name: '',
    avatar_id: 0,
    file: {
      path: '',
    },
    count: 0,
    percentage: 0,
  };
  const [taskProgress, setTaskProgress] = useState<TaskProgress>({
    lateDoneTasksProgress: {
      count: 0,
      percentage: 0,
      users: [userTemplate],
    },
    lateTasksProgress: { count: 0, percentage: 0, users: [userTemplate] },
    onTimeDoneTasksProgress: { count: 0, percentage: 0, users: [userTemplate] },
    onTimeTasksProgress: { count: 0, percentage: 0, users: [userTemplate] },
    rescheduleTasksProgress: { count: 0, percentage: 0, users: [userTemplate] },
    projectId: 0,
  });
  const [dashData, setDashData] = useState<DashData>({
    finalRoiPresentation: [],
    cpPresentation: [],
    investment: {
      dates: [],
      investments: [],
    },
    conversionRate: {
      dates: [],
      convertions: [],
    },
    revGrossProfitAvgTicket: [{ dates: [], name: '', values: [] }],
    clientMargin: 0,
    variations: {
      avgTicketVar: 0,
      grossProfitVar: 0,
      revenueVar: 0,
      roasVar: 0,
      roiVar: 0,
      investmentVar: 0,
    },
    taskProgress: {
      rescheduleTasksProgress: {
        percentages: [],
      },
      lateTasksProgress: {
        percentages: [],
      },
      lateDoneTasksProgress: {
        percentages: [],
      },
      onTimeTasksProgress: {
        percentages: [],
      },
      onTimeDoneTasksProgress: {
        percentages: [],
      },
      dates: [],
    },
    okrProgressHistory: {
      dates: [],
      progress: [
        {
          name: '',
          values: [],
        },
      ],
      quarterProgress: [],
    },
  });
  const [presentationData, setPresentationData] = useState<CheckinDraft>({
    name: '',
    victories: [],
    premises: [],
    lessons: [],
    client_id: 0,
    project_id: 0,
    funnels: [
      {
        name: 'test',
        id: 0,
        value: 0,
        position: 0,
      },
    ],
    investment: 0,
    revenue: 0,
    lifetime_in_months: 0,
    bi_link: '',
    considerations: '',
    actions_for_next_week_v4: '',
    actions_for_next_week_client: '',
    okrs: [],
    emails: [],
    client_margin: 0,
  });
  const slides = [
    <FunnelSlide checkinData={presentationData} dashData={dashData} />,
    <Victories victories={presentationData.victories} />,
    <Lessons lessons={presentationData.lessons} />,
    <OkrSlide
      clientId={presentationData.client_id}
      draftId={Number(presentationData.id)}
      isDraft={Number(draft)}
      okrs={presentationData.okrs}
      progress={dashData.okrProgressHistory}
    />,
    <PremisesAndRisks cards={presentationData.premises} />,
    <TasksSlide
      chartData={dashData.taskProgress}
      projectId={presentationData.project_id}
      isDraft={!!Number(draft)}
      tasks={{
        finished_tasks: presentationData.finished_tasks,
        unfinished_tasks: presentationData.unfinished_tasks,
        checkin_tasks: presentationData.checkin_tasks,
      }}
      progress={taskProgress}
    />,
    draft && (
      <FinalSlide
        oldEmails={presentationData.emails}
        handle={handle}
        quarterId={quarter}
        draftId={Number(presentationData.id)}
      />
    ),
  ];
  let pages = 5;
  if (Number(draft)) pages = 6;

  const handleNextPage = useCallback(
    (direction: string) => {
      if (actualSlide < pages) {
        if (direction === 'right') setActualSlide(actualSlide + 1);
      }
      if (actualSlide !== 0) {
        if (direction === 'left') setActualSlide(actualSlide - 1);
      }
    },
    [actualSlide, pages]
  );

  const handlePresentationInfos = useCallback(async () => {
    let clientId = 0;
    let projectId = 0;

    await apiV2
      .get(
        Number(draft) ? `checkinDraft/getById/${id}` : `checkin?checkinId=${id}`
      )
      .then(res => {
        if (Number(draft)) setPresentationData(res.data.checkinDraft);
        else setPresentationData(res.data.checkin);
        setDashData(res.data.presentation);

        clientId = !Number(draft)
          ? res.data.checkin.client_id
          : res.data.checkinDraft.client_id;

        projectId = !Number(draft)
          ? res.data.checkin.project_id
          : res.data.checkinDraft.project_id;
      })
      .catch(err => {
        toast.error(JSON.stringify(err.message));
      });

    await apiV2
      .get(`quarter/byClient/${clientId}`)
      .then(response => {
        setQuarter(response.data[0].quarter.id);
      })
      .catch(err => {
        toast.error(err.message);
      });

    await apiV2
      .get(
        Number(draft)
          ? `projects/progressByProject?projectIds=${projectId}`
          : `projects/progressByProject?projectIds=${projectId}&checkinId=${id}`
      )
      .then(res => {
        setTaskProgress(res.data[0]);
        setIsLoading(false);
      })
      .catch(err => {
        toast.error(err.message);
      });

    await api.get(`clients/${clientId}`).then(res => {
      setClientName(res.data.name);
    });
  }, [id, draft]);

  useEffect(() => {
    handlePresentationInfos();
  }, [handlePresentationInfos]);

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      <Button
        background="var(--red-primary)"
        width="7rem"
        height="1.6rem"
        text="Checkins"
        font="400 0.9rem Work Sans"
        type="submit"
        onClick={() => history.push(`/checkin/quarter/${quarter}`)}
      />
      <div className="fullscreen">
        <div className="warnBox">
          <div className="headerText">
            <h1>ANTES DE COMEÇAR</h1>
            <h1>COLOQUE PARA GRAVAR</h1>
            <h1>A APRESENTAÇÃO!</h1>
          </div>

          <Button
            background="var(--red-primary)"
            width="7rem"
            height="1.6rem"
            text="Ok"
            font="400 0.9rem Work Sans"
            type="submit"
            onClick={handle.enter}
          />
        </div>

        <div style={{ display: handle.active ? '' : 'none' }} className="slide">
          <FullScreen handle={handle}>
            <TopAtomo
              style={{
                position: 'absolute',
                top: '-6%',
                right: '-4%',
              }}
            />
            <BottomAtomo
              style={{
                position: 'absolute',
                bottom: '-6%',
                left: '-4%',
              }}
            />
            <div className="slide-controlers">
              <RiArrowLeftLine
                onClick={() => handleNextPage('left')}
                size={16}
                style={{
                  visibility: actualSlide !== 0 ? 'visible' : 'hidden',
                  zIndex: 2,
                }}
              />

              <RiArrowRightLine
                onClick={() => handleNextPage('right')}
                size={16}
                style={{
                  visibility: actualSlide < pages ? 'visible' : 'hidden',
                  zIndex: 2,
                }}
              />
            </div>

            <Observations
              oldInfo={presentationData}
              draftId={Number(presentationData.id)}
              presentation={!Boolean(Number(draft)).valueOf()}
              clientName={clientName}
            />

            {slides[actualSlide]}
          </FullScreen>
        </div>
      </div>
    </S.Container>
  );
};

export default Slides;
