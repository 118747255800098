import { cloneDeep } from 'lodash';
import selectGroupIndexById from './SelectGroupIndexById';
import selectProjectIndexById from './SelectProjectIndexById';

export const setTasks = (
  tasks: Task[],
  projects: Project[],
  projectId: number,
  groupId: number,
  phaseId: number
) => {
  const newProjects = cloneDeep(projects);

  const projectIndex = selectProjectIndexById(projectId, projects);

  if (projectIndex === -1) return [];

  const selectedProject = newProjects[projectIndex];

  if (!selectedProject.groups) return [];

  const groupIndex = selectGroupIndexById(projectIndex, groupId, projects);

  const selectedGroup = selectedProject.groups[groupIndex];

  if (!selectedGroup.phases) return [];

  const selectedPhase = selectedGroup.phases.find(
    phase => phase.id === phaseId
  );

  if (!selectedPhase) return [];

  selectedPhase.tasks = tasks;

  return newProjects;
};
