import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  margin-top: 1rem;

  .fullscreen {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .warnBox {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.backgroundSecondary};
    padding: 1.6rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .warnBox button {
    color: var(--white);
    margin-left: 1.8rem;
  }

  .warnBox h1 {
    color: var(--yellow-primary);
    font-size: 1.2rem;
  }

  .warnBox h1:first-child {
    color: ${({ theme }) => theme.text};
  }

  .slide-controlers {
    flex: 1;
    display: flex;
    padding: 2rem;
    position: absolute;
    width: 100vw;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    svg {
      pointer-events: all;
      cursor: pointer;
      color: ${({ theme }) => theme.text};
    }
  }

  input {
    margin-top: 0.8rem;
  }
`;
