import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
  iframe {
    margin: 10px auto;
  }
  > label {
    margin: 20px 0;
    h1 {
      color: ${({ theme }) => theme.text};
    }
    pre {
      padding: 10px;
      color: ${({ theme }) => theme.text};
    }
  }
`;
export const Rating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  label {
    display: flex;

    flex-direction: column;
    align-items: flex-end;
  }
  > div {
    margin-top: 5px;
  }
`;
