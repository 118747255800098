import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: auto;
  margin-right: 3rem;
  align-self: flex-end;
  align-items: center;
  justify-content: space-between;

  div.filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    p.filter,
    button.filter {
      margin: 0 0.7rem;
    }
  }

  p.filter {
    font-size: 0.8rem;
  }

  button.filter {
    width: 6rem;
    :hover {
      border: none;
      background-color: var(--red-primary);
    }
  }

  button.filter {
    height: 1.5rem;
    border-radius: 0.7rem;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    border: 0.1rem solid var(--gray);
  }

  @media (max-width: 1200px) {
    margin: 0;
    width: 100%;
    flex-wrap: wrap;
  }
`;
