import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;
`;

export const TableWrapper = styled.div`
  margin-top: 1.5vh;
  width: 100%;
  height: 75vh;
`;
export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
