import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.6rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 2rem;
  max-width: 1500px;

  .card {
    background: ${({ theme }) => theme.backgroundSecondary};
    color: ${({ theme }) => theme.text};
    border-radius: 8px;
    padding: 1rem;

    p {
      color: ${({ theme }) => theme.text};
    }
  }

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
