import axios from 'axios';

const api = axios.create({ baseURL: 'https://api.laboratoriodomarketing.com' });

api.interceptors.response.use(
  response => response,
  err => {
    if (err.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    return Promise.reject(err);
  }
);

export default api;
