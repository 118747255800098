import styled from 'styled-components';

export const ProgressBar = styled.div`
  width: 100%;
  height: 1.25rem;
  border-radius: 0.2rem;
  color: var(--white);
  font-size: 0.8rem;
  align-items: center;
  text-align: center;
  display: flex;
  align-content: initial !important;
  justify-content: initial !important;
  padding: 0 !important;
  position: relative;
  background-color: var(--gray);

  * {
    margin-top: 0;
  }

  .progress {
    height: 100%;
    max-width: 100%;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
  }

  h5 {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
    color: var(--white);
  }

  .line-mark {
    height: 100%;
    max-width: 100%;
    position: absolute;
    background: transparent;
    border-right: 1px solid var(--white);
  }
`;
