/* eslint-disable no-unused-expressions */
import React, { useState, useCallback } from 'react';
import { FiClock } from 'react-icons/fi';
import { format, getHours, getMinutes, addMinutes } from 'date-fns';
import Avatar from 'react-avatar';
import { Container, DataHolder, ShowMore } from './styles';

const HistoryItem = ({ data }) => {
  const [openDetails, setOpenDetails] = useState(false);

  const handleShowDetails = useCallback(() => {
    openDetails ? setOpenDetails(false) : setOpenDetails(true);
  }, [openDetails]);

  return (
    <>
      <Container>
        <header>
          <div className="image">
            {data.user.avatar ? (
              <img src={data.user.avatar.url} alt={data.user.avatar.name} />
            ) : (
              <Avatar
                name={`${data.user.name}`}
                color="var(--gray)"
                style={{ border: '0' }}
                className="avatar"
              />
            )}
          </div>
          <div className="content">
            <h1>
              {data.user.name} fez uma {data.action}
            </h1>
            <div className="little-content">
              <FiClock
                style={{ marginRight: ' 3px' }}
                size={13}
                color="var(--red-primary)"
              />
              <p>{format(new Date(data.updatedAt), 'dd-MM-yyyy')}</p>
              <p>
                {getHours(new Date(data.updatedAt))}:
                {getMinutes(new Date(data.updatedAt))}
              </p>
            </div>
          </div>
        </header>
        <button type="button" onClick={() => handleShowDetails()}>
          <p className="arrow">❯</p>
        </button>
      </Container>

      {openDetails ? (
        <ShowMore>
          <div className="item">
            <p>Vencimento</p>
            <DataHolder>
              {format(addMinutes(new Date(data.due_date), 500), 'dd/MM/yyyy')}
            </DataHolder>
          </div>
          <div className="item">
            <p>Valor</p>
            <DataHolder>
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(data.fee_value)}
            </DataHolder>
          </div>
          <div className="item">
            <p>Roaylties</p>
            <DataHolder>{data.royalties_value}%</DataHolder>
          </div>
          <div className="item">
            <p>Valor Matriz</p>
            <DataHolder>
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(data.fee_value * (data.royalties_value / 100))}
            </DataHolder>
          </div>
          {data.comments ? (
            <div className="item">
              <p>Comentarios</p>
              <DataHolder>{data.comments}</DataHolder>
            </div>
          ) : (
            <> </>
          )}
        </ShowMore>
      ) : (
        <> </>
      )}
    </>
  );
};

export default HistoryItem;
