import { FC, useState, useCallback, useEffect, useMemo } from 'react';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Search } from '../../components/Search';
import LabTable from '../../components/LabTable';
import api from '../../services/api';
import { Button } from '../../components/Inputs';
import NewUnit from './components/NewUnit';
import * as S from './styles';
import { useAuth } from '../../contexts/AuthContext';

type Unit = {
  id: string;
  name: string;
  stakeholder_name: string;
  stakeholder_email: string;
  personal_email: string;
  phone: string;
  active: boolean;
  userTokenIugu: string;
  apiLiveIugu: string;
  state: string;
  city: string;
  address: string;
  createdAt: Date;
};

export const Units: FC = () => {
  const [search, setSearch] = useState('');
  const [units, setUnits] = useState([{} as Unit]);
  const [newUnit, setNewUnit] = useState(false);
  const { unit_id: unitId } = useAuth().user;
  const history = useHistory();

  const getUnits = useCallback(async () => {
    await api.get('units?allInfo=true').then(res => setUnits(res.data));
  }, []);

  const handleRowClick = useCallback(
    e => {
      history.push(`/units/${e.id}`);
    },
    [history]
  );

  useEffect(() => {
    if (unitId !== 1) {
      handleRowClick({ id: unitId });
    }
    getUnits();
  }, [getUnits, handleRowClick, unitId]);

  const table = useMemo(() => {
    const response = units
      .filter(
        unit =>
          (unit.name &&
            unit.name.toLowerCase().includes(search.toLowerCase())) ||
          (unit.stakeholder_name &&
            unit.stakeholder_name
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (unit.stakeholder_email &&
            unit.stakeholder_email
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (unit.personal_email &&
            unit.personal_email.toLowerCase().includes(search.toLowerCase())) ||
          (unit.phone &&
            unit.phone.toLowerCase().includes(search.toLowerCase())) ||
          (unit.state &&
            unit.state.toLowerCase().includes(search.toLowerCase())) ||
          (unit.city &&
            unit.city.toLowerCase().includes(search.toLowerCase())) ||
          (unit.address &&
            unit.address.toLowerCase().includes(search.toLowerCase()))
      )
      .map(unit => {
        return {
          id: unit.id,
          name: unit.name,
          stakeholder_name: unit.stakeholder_name,
          stakeholder_email: unit.stakeholder_email,
          active: unit.active ? 'Sim' : 'Não',
          personal_email: unit.personal_email,
          phone: unit.phone,
          state: unit.state,
          city: unit.city,
          address: unit.address,
          createdAt: format(new Date(unit.createdAt), 'dd/MM/yyyy'),
          Iugu: unit.userTokenIugu && unit.apiLiveIugu ? 'Sim' : 'Não',
        };
      });
    return response;
  }, [search, units]);
  return (
    <S.Wrapper>
      <S.FirstLine>
        <Search
          placeholder="Pesquisar unidades..."
          setSearch={setSearch}
          search={search}
          width="40vw"
        />
        <Button
          width="12%"
          height="40px"
          background="var(--red-primary)"
          onClick={() => setNewUnit(true)}
        >
          Criar Unidade
        </Button>
      </S.FirstLine>
      <S.TableWrapper>
        {table.length > 0 && (
          <LabTable contents={table} rowClick={handleRowClick} />
        )}
      </S.TableWrapper>
      {newUnit && <NewUnit setActive={setNewUnit} getUnits={getUnits} />}
    </S.Wrapper>
  );
};

export default Units;
