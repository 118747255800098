import { useCallback, useState, MouseEvent, ChangeEvent } from 'react';
import { BiLayer } from 'react-icons/bi';

import { toast } from 'react-toastify';
import { Button, TextInput } from '../../../../../components/Inputs';
import * as S from './styles';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import RichText from '../../../../../components/RichText';
import { Modal } from '../../../styles';
import ImportPlaybook from '../ImportPlaybook';
import useForm from '../../../../../hooks/useForm';
import { LoadingButton } from '../../LoadingButton';
import { TagsCreate } from '../../TagsCreate';
import { createGroup } from '../../../services/CreateGroup';
import { setGroups } from '../../../utils/SetGroups';
import { useTasks } from '../../../../../contexts/TasksContext';
import selectProjectIndexById from '../../../utils/SelectProjectIndexById';

interface useFormHookProps {
  form: { groupName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

interface NewGroupProps {
  handleClose: () => void;
  projectId: number;
}

function NewGroup({ handleClose, projectId }: NewGroupProps) {
  const { projects, setProjects } = useTasks();
  const { form, onChange }: useFormHookProps = useForm({ groupName: '' });
  const [content, setContent] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [tagsList, setTagsList] = useState<ITag[]>([]);

  const handleOpenModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'outside-modal') handleOpenModal();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const projectIndex = selectProjectIndexById(projectId, projects);

  const handleCreateGroup = useCallback(async () => {
    setIsDisable(true);

    if (form.groupName!.length <= 0 || form.groupName! === ' ') {
      toast.warn('Não é possível criar um projeto sem nome!');
      setIsDisable(false);
      return;
    }

    const newGroup = await createGroup(
      form.groupName,
      content,
      projectId,
      tagsList.map((tag: ITag) => tag.id)
    );

    if (!projects[projectIndex].groups) {
      projects[projectIndex].groups = [];
    }

    const newProjects = setGroups(
      [...projects[projectIndex].groups, newGroup],
      projects,
      projectId
    );
    setProjects(newProjects);

    setIsDisable(false);

    toast.success('Grupo criado com sucesso!');

    handleClose();
  }, [
    content,
    form.groupName,
    tagsList,
    handleClose,
    projectId,
    projectIndex,
    projects,
    setProjects,
  ]);

  return (
    <S.Container>
      <TaskCard close={handleClose}>
        <header>
          <BiLayer size={21} color="var(--red-primary)" />
          <p>Novo Grupo</p>
        </header>
        <main>
          <div className="input">
            <p>Nome do grupo</p>
            <TextInput
              name="groupName"
              onChange={handleInputChange}
              value={form.groupName}
              width="100%"
              height="2.1rem"
              placeholder="Escreva o nome do grupo"
              font="400 0.9rem Work Sans"
            />
          </div>

          <div className="input">
            <p>Adicionar uma Tag</p>
            <TagsCreate tagsList={tagsList} setTagsList={setTagsList} />
          </div>

          <div className="input">
            <p>Descrição do grupo</p>
            <RichText
              height={200}
              width={282}
              initialValue="Escreva aqui a descrição deste grupo."
              contentToSave={(contentItem: string) => {
                setContent(contentItem);
              }}
            />
          </div>

          <footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {isDisable ? (
              <LoadingButton width="50%" height="2.1rem" />
            ) : (
              <Button
                disabled={isDisable}
                onClick={() => handleCreateGroup()}
                id="confirm-button"
              >
                <p>Criar</p>
              </Button>
            )}
          </footer>
        </main>
      </TaskCard>
      {openModal && (
        <Modal id="outside-modal" onClick={handleOutsideClick}>
          <ImportPlaybook handleClose={handleCloseModal} />
        </Modal>
      )}
    </S.Container>
  );
}

export default NewGroup;
