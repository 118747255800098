import styled from 'styled-components';

export const MediumCard = styled.div`
  background: #1a2024;
  border-radius: 3px;
  padding: 14px;
  margin: 10px;
  flex: 1;
  white-space: pre-wrap;

  .cardData {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: space-around;

    @media (min-width: 1600px) {
      font-size: 14px;
    }

    p {
      color: #f6f6f6;

      :nth-child(2) {
        font-weight: bold;
        margin-left: 30px;

        @media (max-width: 1380px) {
          margin-left: 5px;
        }
      }
    }
  }
`;
