const selectGroupIndexById = (
  projectIndex: number,
  groupId: number,
  globalProjects: Project[]
): number => {
  const groupIndex = globalProjects[projectIndex].groups!.findIndex(
    group => group.id === groupId
  );
  return groupIndex;
};

export default selectGroupIndexById;
