import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  @media (max-width: 1301px) {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    padding: 3rem 0;
  }
`;

export const Container = styled.div`
  width: 40vw;
  min-height: 33rem;
  border-radius: 0.51rem;
  padding: 0.45rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.backgroundSecondary};
  color: ${({ theme }) => theme.text};

  :hover {
    box-shadow: 0 0.25rem 1.9rem 0 rgba(227, 36, 56, 0.2);
  }

  @media (max-width: 1301px) {
    width: 65vw;
  }
`;

export const Info = styled.div`
  width: 85%;
  height: 5rem;
  border-bottom: 1px solid var(--gray-text);
  display: flex;
  align-items: center;
  span {
    color: var(--red-primary);
  }
`;
