import {
  RiFoldersLine,
  RiUser3Line,
  RiTimeLine,
  RiLineChartFill,
} from 'react-icons/ri';
import * as S from './styles';

export function TopBar() {
  return (
    <S.Wrapper>
      <S.Container id="name">
        <RiFoldersLine className="icon" size={20} color="var(--red-primary)" />
        <p>Projeto</p>
      </S.Container>
      <S.Container id="responsible">
        <span>|</span>
        <RiUser3Line className="icon" size={20} color="var(--red-primary)" />
        <p>Responsável</p>
      </S.Container>
      <S.Container id="period">
        <span>|</span>
        <RiTimeLine className="icon" size={20} color="var(--red-primary)" />
        <p>Prazo</p>
      </S.Container>
      <S.Container id="progress">
        <span>|</span>
        <RiLineChartFill
          className="icon"
          size={20}
          color="var(--red-primary)"
        />
        <p>Progresso</p>
      </S.Container>
      <S.Container id="project">
        <span>|</span>
        <RiFoldersLine className="icon" size={20} color="var(--red-primary)" />
        <p>Selecionar Projeto</p>
      </S.Container>
    </S.Wrapper>
  );
}
