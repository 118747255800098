import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function restoreProject(projectId: number): Promise<void> {
  try {
    await apiV2.post(`projects/undelete/${projectId}`);
  } catch {
    toast.error(
      'Problema ao restaurar seu projeto, atualize a página e tente novamente 😕'
    );
  }
}
