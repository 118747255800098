import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

type CheckItem = {
  name: string;
  marked: boolean;
};

export async function getPhaseChecklistItems(
  phaseId: number
): Promise<CheckItem[]> {
  try {
    const response = await apiV2.get(`/phase-checklist-items/${phaseId}`);

    const checks = response.data;

    return checks;
  } catch {
    toast.error(
      'Ocorreu algum problema ao carregar os checklists das fases, atualize a página e tente novamente :S'
    );
    return [];
  }
}
