import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
  color: var(--gray);
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 4vh;

  :first-child {
    margin-top: 4vh;
  }
`;

export const InputLoading = styled.div`
  width: 60%;
  margin-right: 0.8rem;
  height: 2rem;
  background-color: var(--gray);
  border-radius: 0.2rem;
  animation-name: loading;
  animation-duration: 4s;
  animation-iteration-count: infinite;

  & + div {
    width: 40%;
    margin-left: 0.8rem;
  }

  @keyframes loading {
    0% {
      background-color: var(--gray);
    }
    10% {
      background-color: var(--gray-text);
    }
    20% {
      background-color: var(--gray);
    }
    30% {
      background-color: var(--gray-text);
    }
    40% {
      background-color: var(--gray);
    }
    50% {
      background-color: var(--gray-text);
    }
    60% {
      background-color: var(--gray);
    }
    70% {
      background-color: var(--gray-text);
    }
    80% {
      background-color: var(--gray);
    }
    90% {
      background-color: var(--gray-text);
    }
    100% {
      background-color: var(--gray);
    }
  }
`;
