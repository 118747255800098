/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useEffect, useMemo } from 'react';

import Avatar from 'react-avatar';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import StarRatingComponent from 'react-star-rating-component';
import { GiFlame } from 'react-icons/gi';
import { useAuth } from '../../../../../../contexts/AuthContext';
import {
  Form,
  Topic,
  Header,
  Replies,
  Reply,
  Assisgns,
  Buttons,
  Rating,
} from './styles';
import NewPopup from '../../../../../../components/PopUp';
import Wikipost from '../../../../components/WikiPost/view';
import api from '../../../../../../services/api';

function ReplyTicket({ active, ticket, getTickets, getPosts, user }) {
  const [openWiki, setOpenWiki] = useState(false);
  const [replies, setReplies] = useState([]);
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(0);
  const [desc, setDesc] = useState('');
  const [assign, setAssign] = useState(ticket.assign && ticket.assign.id);
  const [secondAssign, setSecondAssign] = useState(
    ticket.secondAssign && ticket.secondAssign.id
  );
  const [users, setUsers] = useState([]);
  const [rating, setRating] = useState(ticket.vote ? ticket.vote : 0);
  const [hoverRating, setHoverRating] = useState(0);

  const { id: userId } = useAuth().user;

  const getReplies = useCallback(async () => {
    await api
      .get(`/tickets/${ticket.id}`)
      .then(res =>
        setReplies(
          res.data.replies.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
        )
      );
  }, [ticket.id]);

  useEffect(() => {
    api.get('roles/support').then(res =>
      setUsers(
        res.data.map(usr => (
          <option value={usr.id} key={usr.id}>
            {`${usr.name} ${usr.last_name}`}
          </option>
        ))
      )
    );
    api.get('wiki_posts?all=true').then(res =>
      setPosts(
        res.data.data.map(post => (
          <option key={post.id} value={post.id}>
            {post.name}
          </option>
        ))
      )
    );
    getReplies();
  }, [getReplies]);

  const handleRating = useCallback(
    e => {
      setRating(e);

      api
        .put('tickets', {
          id: ticket.id,
          vote: e,
        })
        .then(() => getTickets());
    },
    [getTickets, ticket.id]
  );

  useEffect(() => {
    if (
      ticket.assign_id !== assign ||
      ticket.second_assign_id !== secondAssign
    ) {
      api
        .put('tickets', {
          id: ticket.id,
          ...(assign && { assign_id: assign }),
          ...(secondAssign && { second_assign_id: secondAssign }),
        })
        .then(() => getTickets());
    }
  }, [
    assign,
    getTickets,
    secondAssign,
    ticket.assign_id,
    ticket.id,
    ticket.second_assign_id,
  ]);

  const handleSubmit = useCallback(async e => {
    e.preventDefault();
  }, []);

  const sendReply = useCallback(async () => {
    if (!assign && !user) {
      toast.error('Você precisa selecionar um responsavel!');
      return false;
    }
    await api
      .post('ticket_replies', {
        ticket_id: ticket.id,
        desc,
        ...(selectedPost > 0 && { wiki_post_id: selectedPost }),
      })
      .then(() => {
        toast.success('Sua resposta foi enviada com sucesso.');
        getReplies();
        setDesc('');
      });
    return true;
  }, [assign, desc, getReplies, selectedPost, ticket.id, user]);

  const finishTicket = useCallback(async () => {
    if (rating < 1 && user) {
      toast.error('Você precisa avaliar antes de finalizar!');

      return false;
    }
    if (!assign && !user) {
      return false;
    }
    await sendReply().then(async () => {
      await api
        .put('tickets', {
          id: ticket.id,
          support_open: false,
          ...(user && { open: false, finished_at: new Date() }),
        })
        .then(async () => {
          getTickets();
          active(false);
          if (user) {
            await api
              .post('wiki_posts', {
                name: ticket.name,
                user_id: ticket.assign_id,
                ticket_id: ticket.id,
              })
              .then(() => getPosts());
          }
        });
    });
    return true;
  }, [
    active,
    assign,
    getPosts,
    getTickets,
    rating,
    sendReply,
    ticket.assign_id,
    ticket.id,
    ticket.name,
    user,
  ]);

  const acceptClose = useCallback(async () => {
    if (rating < 1 && user) {
      toast.error('Você precisa avaliar antes de finalizar!');

      return false;
    }
    await api
      .put('tickets', {
        id: ticket.id,
        support_open: false,
        open: false,
        finished_at: new Date(),
      })
      .then(async () => {
        getTickets();
        active(false);
        await api
          .post('wiki_posts', {
            name: ticket.name,
            user_id: ticket.assign_id,
            ticket_id: ticket.id,
          })
          .then(() => getPosts());
      });
    return true;
  }, [
    active,
    getPosts,
    getTickets,
    rating,
    ticket.assign_id,
    ticket.id,
    ticket.name,
    user,
  ]);

  const denyClose = useCallback(async () => {
    await api
      .put('tickets', {
        id: ticket.id,
        support_open: true,
        open: true,
      })
      .then(() => {
        getTickets();
        active(false);
      });
    await sendReply();
  }, [active, getTickets, sendReply, ticket.id]);

  const finishTicketWiki = useCallback(async () => {
    await sendReply().then(async () => {
      await api
        .put('tickets', {
          id: ticket.id,
          open: false,
          finished_at: new Date(),
        })
        .then(async () => {
          getTickets();
          active(false);
        });
    });
  }, [active, getTickets, sendReply, ticket.id]);

  const { stars, color } = useMemo(() => {
    if (hoverRating > 0) {
      return { stars: hoverRating, color: 'var(--gray)' };
    }

    return { stars: rating, color: 'var(--red-primary)' };
  }, [hoverRating, rating]);

  return (
    <>
      <NewPopup closePopUp={active} header="Responder Ticket" width="40%">
        <Form onSubmit={handleSubmit}>
          {!user && (
            <Assisgns>
              <label htmlFor="firstAssign">
                Responsável Principal
                <select
                  id="firstAssign"
                  value={assign}
                  onChange={e => setAssign(e.target.value)}
                  required
                >
                  <option value="">Selecione o responsável</option>
                  {users}
                </select>
              </label>
              <label htmlFor="secondAssign">
                Responsável Acompanhante
                <select
                  id="secondAssign"
                  value={secondAssign}
                  onChange={e => setSecondAssign(e.target.value)}
                >
                  <option value="">Selecione o acompanhante</option>
                  {users}
                </select>
              </label>
            </Assisgns>
          )}
          <Topic>
            <Header isUser={ticket.user && ticket.user.id === userId}>
              <Avatar
                name={`${ticket.user.name} ${ticket.user.last_name}`}
                src={ticket.user.avatar && ticket.user.avatar.url}
                className="avatar"
                color="var(--gray)"
                key={ticket.user.id}
              />
              <h1>{ticket.name}</h1>
              {format(new Date(ticket.createdAt), 'dd/MM/yyyy HH:mm:ss')}
            </Header>

            <pre>{ticket.desc}</pre>
          </Topic>
          <Replies>
            {replies.map(reply => (
              <Reply key={reply.id}>
                <Header isUser={reply.user && reply.user.id === userId}>
                  <Avatar
                    name={`${reply.user.name} ${reply.user.last_name}`}
                    src={reply.user.avatar && reply.user.avatar.url}
                    className="avatar"
                    color="var(--gray)"
                    key={reply.user.id}
                  />
                  {format(new Date(reply.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                </Header>
                <pre>{reply.desc}</pre>
                {reply.wiki_post_id && (
                  <button
                    type="button"
                    id="wiki_post"
                    onClick={() => setOpenWiki(true)}
                  >
                    Ver Wiki
                  </button>
                )}
                {openWiki && (
                  <Wikipost id={reply.wiki_post_id} active={setOpenWiki} />
                )}
              </Reply>
            ))}
          </Replies>
          {user && !ticket.open ? (
            <></>
          ) : (
            <>
              <hr />
              {!user && (
                <label htmlFor="posts">
                  Já existe uma solução para isso?
                  <select
                    id="posts"
                    value={selectedPost}
                    onChange={e =>
                      setSelectedPost(parseInt(e.target.value, 10))
                    }
                  >
                    <option value="0">Não</option>
                    {posts}
                  </select>
                </label>
              )}
              {user && !ticket.support_open ? (
                <></>
              ) : (
                <textarea
                  placeholder="Digite sua resposta aqui..."
                  value={desc}
                  onChange={e => setDesc(e.target.value)}
                  required
                />
              )}
              <Buttons>
                {user && !ticket.support_open ? (
                  <>
                    <button type="submit" id="reply" onClick={acceptClose}>
                      Aceitar Resposta
                    </button>

                    <button
                      type="submit"
                      id="done"
                      onMouseEnter={() =>
                        setDesc('A solução não foi aceita pelo Usuario')
                      }
                      onMouseLeave={() => setDesc('')}
                      onClick={denyClose}
                    >
                      Não Aceitar a Resposta
                    </button>
                  </>
                ) : (
                  <>
                    {selectedPost > 0 ? (
                      <button
                        type="submit"
                        id="done"
                        onClick={finishTicketWiki}
                      >
                        Responder e Finalizar
                      </button>
                    ) : (
                      <>
                        <button type="submit" id="reply" onClick={sendReply}>
                          Responder
                        </button>
                        <button type="submit" id="done" onClick={finishTicket}>
                          Responder e Finalizar
                        </button>
                      </>
                    )}
                  </>
                )}
              </Buttons>
            </>
          )}
          {user && (
            <Rating>
              <label>
                Quanto esse post foi útil?
                <StarRatingComponent
                  name="rate1"
                  starCount={5}
                  value={stars}
                  renderStarIcon={() => <GiFlame />}
                  starColor={color}
                  onStarHover={e => setHoverRating(e)}
                  onStarHoverOut={() => setHoverRating(0)}
                  onStarClick={handleRating}
                />
              </label>
            </Rating>
          )}
        </Form>
      </NewPopup>
    </>
  );
}

export default ReplyTicket;
