import React, { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { format } from 'date-fns';

import { Props } from 'react-apexcharts';
import getDefaultOptions from '../../../../../../styles/defaultChartStyles';

import * as S from './styles';
import { TaskProgressGraph } from '../../../../types';

interface ChartProps {
  taskData: TaskProgressGraph;
}

const Chart: React.FC<ChartProps> = ({ taskData }) => {
  const data = useMemo(() => {
    const newDates: string[] = [];
    taskData.dates.forEach(date => {
      newDates.push(format(new Date(date), 'dd/MM/yyyy'));
    });

    const datasets = [
      {
        name: 'Tarefas em dia',
        data: taskData.onTimeTasksProgress.percentages,
      },
      {
        name: 'Tarefas entregues',
        data: taskData.onTimeDoneTasksProgress.percentages,
      },
      {
        name: 'Tarefas atrasadas',
        data: taskData.lateTasksProgress.percentages,
      },
      {
        name: 'Entregues atrasadas',
        data: taskData.lateDoneTasksProgress.percentages,
      },
      {
        name: 'Tarefas remanejadas',
        data: taskData.rescheduleTasksProgress.percentages,
      },
    ];

    const colors = [
      'var(--cyan-primary)',
      'var(--green-primary)',
      'var(--red-primary)',
      'var(--yellow-primary)',
      'var(--magenta-primary)',
    ];

    return {
      labels: newDates,
      datasets,
      colors,
    };
  }, [taskData]);

  const defaultOptions = getDefaultOptions({
    tooltipOption: true,
    colors: data.colors,
  });

  const options: Props['ApexOptions'] = {
    ...defaultOptions,
    xaxis: {
      categories: data.labels,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    legend: {
      position: 'top',
      show: true,
      fontSize: '0px',
    },
  };

  return (
    <S.Container>
      <ApexChart
        options={options}
        series={data.datasets}
        type="area"
        height="100%"
      />
    </S.Container>
  );
};

export default Chart;
