import React from 'react';
import { MdEdit } from 'react-icons/md';
import * as S from './styles';
import ModalHeader from '../ModalHeader';
import { ModalLabel } from '../ModalLabel';

interface signersProps {
  signers: Signers[];
  handleCloseModal: () => void;
}

export const Signers: React.FC<signersProps> = ({
  signers,
  handleCloseModal,
}: signersProps) => {
  return (
    <>
      <S.SignersModal>
        <ModalHeader
          icon={MdEdit}
          title="Assinantes"
          setModal={handleCloseModal}
          disabled={false}
        />

        <S.FormInformations action="">
          {signers.length > 0 &&
            signers.map(signer => (
              <S.Row>
                <ModalLabel title="Nome" htmlFor="name" width="100%">
                  <p>{signer.signer.name}</p>
                </ModalLabel>
                <ModalLabel title="E-mail" htmlFor="email" width="100%">
                  <p>{signer.signer.email}</p>
                </ModalLabel>
                <ModalLabel title="Assinou?" htmlFor="sign" width="100%">
                  {signer.sign ? (
                    <p style={{ color: 'var(--green-primary)' }}>Sim</p>
                  ) : (
                    <p style={{ color: 'var(--red-primary)' }}>Não</p>
                  )}
                </ModalLabel>
              </S.Row>
            ))}
        </S.FormInformations>
      </S.SignersModal>
    </>
  );
};
