import { FC, useMemo, useState, useEffect, useCallback } from 'react';

import Avatar from 'react-avatar';
import Select from 'react-select';
import { FiCheck, FiX, FiEdit, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import * as S from './styles';
import PopUpConfirmation from '../../../../components/PopUp/PopUpConfirmation';
import api from '../../../../services/api';

type Role = {
  id: number;
  role: string;
};

type User = {
  id: number;
  name: string;
  last_name: string;
  roles: Role[];
  active_token: string;
  avatar: {
    url: string;
  };
};

interface UserCardProps {
  user: User;
  isAdmin: boolean;
  removeUser: (uId: number) => void;
}

const UserCard: FC<UserCardProps> = ({ user, isAdmin, removeUser }) => {
  const [roles, setRoles] = useState([]);
  const [edit, setEdit] = useState(false);
  const [popUpConfirmation, setPopUpConfirmation] = useState(false);
  const [activeToken, setActiveToken] = useState<string | null>(
    user.active_token
  );
  const [userRoles, setUserRoles] = useState(
    user.roles.map(role => {
      return { value: role.id, label: role.role };
    })
  );

  const name = useMemo(() => {
    return `${user.name} ${user.last_name}`;
  }, [user.last_name, user.name]);

  const isEdit = useMemo(() => {
    if (!edit && isAdmin) {
      return <FiEdit onClick={() => setEdit(true)} />;
    }
    if (edit) {
      return <FiX onClick={() => setEdit(false)} />;
    }
    return false;
  }, [edit, isAdmin]);

  const deleteUser = useCallback(
    async del => {
      if (del) {
        await api
          .delete(`users/${user.id}`)
          .then(() => {
            removeUser(user.id);
            toast.success('Usuário deletado com sucesso!');
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              toast.error('Você não tem permissão para fazer isso!');
            }
            toast.error(
              'Algo deu errado, por favor, tente mais tarde novamente.'
            );
          });
        return true;
      }
      return false;
    },
    [removeUser, user.id]
  );

  const approval = useCallback(
    async confirm => {
      await api
        .post(`/user_approval`, {
          activeToken,
          confirm,
        })
        .then(res => {
          if (res.data.msg === 'User Deleted.') {
            toast.info('Negada a entrada do novo usuário!');
          } else if (res.data.msg === 'User approved.') {
            toast.success('Novo usuário adicionado com sucesso!');
          }
          setActiveToken(null);
        });
    },
    [activeToken]
  );

  useEffect(() => {
    api.get('roles/options').then(res =>
      setRoles(
        res.data.map((role: Role) => {
          return { value: role.id, label: role.role };
        })
      )
    );
  }, []);

  const handleChangRoles = useCallback(
    async option => {
      setUserRoles(option);
      await api
        .put('roles', {
          user_id: user.id,
          roles_id: option.map((obj: { value: number }) => obj.value),
        })
        .then(() => {
          toast.success('Cargos atualizados com sucesso!');
        });
    },
    [user.id]
  );

  return (
    <>
      <S.Container>
        <S.Left>
          {isEdit}
          <Avatar
            name={name}
            src={user.avatar && user.avatar.url}
            className="avatar"
            color="var(--gray)"
          />
          <strong>{name}</strong>
          {edit && (
            <FiTrash2 id="delete" onClick={() => setPopUpConfirmation(true)} />
          )}
        </S.Left>
        <S.Right edit={edit}>
          {activeToken ? (
            <>
              <p>Aprovar entrada no lab?</p>
              <FiCheck
                className="approval accept"
                onClick={() => approval(true)}
              />
              <FiX className="approval denie" onClick={() => approval(false)} />
            </>
          ) : (
            <Select
              options={roles}
              value={userRoles}
              defaultValue={userRoles}
              closeMenuOnSelect={false}
              className="select-container"
              classNamePrefix="select"
              isMulti
              isDisabled={!edit}
              onChange={handleChangRoles}
            />
          )}
        </S.Right>
      </S.Container>
      {popUpConfirmation && (
        <PopUpConfirmation
          closePopUp={setPopUpConfirmation}
          text={`Você deseja realmente excluir o usuario: ${name}?`}
          confirmation={deleteUser}
        />
      )}
    </>
  );
};

export default UserCard;
