import styled from 'styled-components';

export const Container = styled.button`
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--red-primary);
  display: flex;
  justify-content: center;
  margin: 0 1vh;
  transition: 0.3s all;

  svg {
    color: var(--red-primary);
  }
  &:hover {
    filter: brightness(80%);
  }
`;
