import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;
`;

export const FirstLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.backgroundPrimary};
`;
export const BroadcastsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  max-height: 80vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.backgroundPrimary};
`;
