import React from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';

import * as S from './styles';

interface Props {
  children: React.ReactNode;
  close: () => void;
  width?: string;
}

const TaskCard: React.FC<Props> = ({ children, close, width = '20rem' }) => {
  return (
    <S.Container style={{ width }}>
      <S.CloseButton type="button" onClick={close}>
        <RiCloseCircleLine size={16} color="var(--red-primary)" />
      </S.CloseButton>
      {children}
    </S.Container>
  );
};

export default TaskCard;
