import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 0.6rem;

  .tox-editor-header {
    background: ${({ theme }) => theme.backgroundPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.backgroundPrimary};
    z-index: 0 !important;
  }

  .tox :not(svg):not(rect) {
    background: ${({ theme }) => theme.backgroundPrimary};

    color: ${({ theme }) => theme.text};
  }

  .tox .tox-tbtn svg {
    color: ${({ theme }) => theme.text};
    fill: ${({ theme }) => theme.text};
  }

  .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.text};
  }

  .tox .tox-tinymce-aux .tox-toolbar__overflow {
    background-color: var(--gray);
    border: 1px solid ${({ theme }) => theme.text};
  }

  .tox .tox-tbtn:hover svg {
    fill: ${({ theme }) => theme.text};
  }

  .tox .tox-tbtn:focus:not(.tox-tbtn--disabled) svg {
    fill: ${({ theme }) => theme.text};
  }

  .tox .tox-tbtn--enabled {
    background: var(--gray);
    border: 0;
    box-shadow: none;
    color: ${({ theme }) => theme.text};
    -webkit-filter: brightness(80%);
    filter: brightness(80%);
  }

  .tox .tox-tbtn:hover {
    background: var(--gray);
    border: 0;
    box-shadow: none;
    color: ${({ theme }) => theme.text};
    filter: brightness(80%);
  }
`;
