/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import {
  RiFocusLine,
  RiTimeLine,
  RiLineChartFill,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import { Draggable } from 'react-beautiful-dnd';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import * as S from './styles';

import { Tag } from '../../../../components/Tag';
import { useTags } from '../../../../../../contexts/TagsContext';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { Responsibles } from '../../../../components/Responsibles';
import { Modal } from '../../../../styles';
import { EditIndividualTask } from '../../../../components/Modals/EditIndividualTask';
import useProgress from '../../../../../../hooks/useProgress';
import { PopupText } from '../../../../components/PopupText';
import { restoreTask } from '../../../../services/RestoreTask';
import { SetIndividualTasks } from '../../../../utils/SetIndividualTasks';
import { useTasks } from '../../../../../../contexts/TasksContext';

interface IndividualCardProps {
  task: Task;
  index: number;
  tasks?: Task[];
  opacity?: number;
}

export function IndividualTaskCard({
  task,
  index,
  tasks,
  opacity = 1,
}: IndividualCardProps) {
  const { projects, setProjects } = useTasks();
  const { projectId } = useParams<TasksParams>();
  const [openModal, setOpenModal] = useState(false);
  const { progress, colors } = useProgress();
  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();

  useEffect(() => {
    setTags(() => (task.tags ? task.tags : []));
  }, [task]);

  const periodProgressPercent = useMemo(
    () => progress(task.created_at, task.due_date),
    [progress, task.created_at, task.due_date]
  );

  const progressBarsColors = useMemo(
    () => colors(task.due_date, task.finished_at),
    [colors, task.due_date, task.finished_at]
  );

  const progressTitle = useMemo(
    () => `${task.progress ? Math.round(task.progress * 100) / 100 : 0}%`,
    [task.progress]
  );

  const invertModalVisibility = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') invertModalVisibility();
  };

  const handleRestoreIndividualTask = useCallback(
    async (taskToRestore: Task) => {
      restoreTask(taskToRestore.id).then(() => {
        const taskUndeleted = {
          ...taskToRestore,
          was_removed: false,
        };

        const tasksUpdate = tasks!.filter(
          taskItem => taskItem.id !== taskToRestore.id
        );

        tasksUpdate.splice(taskToRestore.order! - 1, 0, taskUndeleted);
        const newProjects = SetIndividualTasks(
          [...tasksUpdate],
          projects,
          +projectId
        );
        setProjects(newProjects);
        toast.success('Task Individual restaurada com sucesso');
      });
    },
    [tasks, projects, projectId, setProjects]
  );

  return (
    <>
      <Draggable draggableId={`${task.id}`} index={index}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <S.Wrapper>
              <S.Container tabIndex={0} style={{ opacity }}>
                <S.TagsArea>
                  {tags &&
                    tags.map((tag: ITag, i) => {
                      if (i >= 10) {
                        return false;
                      }
                      return (
                        <Tag
                          key={tag.id}
                          tag={tag}
                          type="task_id"
                          typeId={task.id}
                          setTagsList={setTags}
                          showLabel={show}
                          setShowLabel={handleShowTags}
                        />
                      );
                    })}
                </S.TagsArea>
                <S.Header>
                  <S.Title>
                    <RiFocusLine size={14} color="var(--red-primary)" />
                    <p onClick={invertModalVisibility}>Task Individual</p>
                    <button type="button" onClick={invertModalVisibility}>
                      <FaRegEdit
                        size={14}
                        id="edit-button"
                        color="var(--gray)"
                      />
                    </button>
                  </S.Title>

                  <div id="responsible">
                    {task.was_removed ? (
                      <S.RestoreTask>
                        <button
                          type="button"
                          title={task.name}
                          onClick={() => handleRestoreIndividualTask(task)}
                        >
                          <RiDeleteBin2Line
                            size={14}
                            color="var(--red-primary)"
                          />
                          <p id="restore-label">Restaurar Task</p>
                        </button>
                      </S.RestoreTask>
                    ) : (
                      <Responsibles users={task.users} size="1.3rem" />
                    )}
                  </div>
                </S.Header>
                <button type="button" onClick={invertModalVisibility}>
                  <PopupText popUpText={task.name}>
                    {task.name.length > 27
                      ? `${task.name.substring(0, 35)}...`
                      : task.name}
                  </PopupText>
                </button>
                <S.Content role="button" onClick={invertModalVisibility}>
                  <div id="period">
                    <RiTimeLine
                      className="icon"
                      size={14}
                      color="var(--red-primary)"
                    />
                    <p>Prazo</p>

                    <ProgressDisplay
                      width="55%"
                      height="1rem"
                      borderRadius="0.3rem"
                      percentage={periodProgressPercent}
                      title={
                        task.due_date
                          ? format(new Date(task.due_date), 'dd.MMMM.yyyy', {
                              locale: ptBR,
                            }).toString()
                          : 'Sem prazo'
                      }
                      colorBar={progressBarsColors.dueDateColor}
                    />
                  </div>
                  <div id="progress">
                    <RiLineChartFill
                      className="icon"
                      size={14}
                      color="var(--red-primary)"
                    />
                    <p>Progresso</p>

                    <ProgressDisplay
                      width="55%"
                      height="1rem"
                      borderRadius="0.3rem"
                      title={progressTitle}
                      percentage={task.progress}
                      colorBar={progressBarsColors.progressColor}
                    />
                  </div>
                </S.Content>
              </S.Container>
            </S.Wrapper>
          </div>
        )}
      </Draggable>
      {openModal && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <EditIndividualTask
            task={task}
            projectId={+projectId}
            tasks={tasks}
            handleClose={invertModalVisibility}
            tags={tags}
          />
        </Modal>
      )}
    </>
  );
}
