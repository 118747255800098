import React, { useState, useRef, useEffect, useMemo } from 'react';

import { FaAngleDown } from 'react-icons/fa';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import AddNewButton from '../../../components/AddNewButton';
import CloseButton from '../../../components/CloseButton';

import Group from '../Group';
import IndividualTask from '../IndividualTask';
import { useExportPlaybook } from '../../../../../../../../contexts/ExportPlaybookContext';

import {
  Container,
  Project,
  Label,
  Deadline,
  Separator,
  Buttons,
  AccordionButton,
  Accordion,
  DeliveryGroups,
  IndividualTasks,
} from '../../../styles/ProjectContainer';

interface ProjectProps {
  project: IProject;
}

const ProjectItem: React.FC<ProjectProps> = ({ project }) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('');

  const [firstTime, setFirstTime] = useState(true);
  const [projectDueDate, setProjectDueDate] = useState<Date>(new Date());

  const content = useRef<HTMLDivElement>(null);

  const {
    updateProjectName,
    updateProjectDescription,
    deleteProject,
    addNewGroup,
    addNewIndividualTask,
    getProjectDueDate,
  } = useExportPlaybook();

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `1500vh`);
    setRotateState(setActive === 'active' ? '' : 'rotate');
  }

  const convertDueDateInDate = useMemo(() => {
    if (projectDueDate.getTime() === -1) return 'Sem prazo';

    const formatedNewDate = format(new Date(projectDueDate), 'dd.MMMM.yyyy', {
      locale: ptBR,
    }).toString();

    return formatedNewDate;
  }, [projectDueDate]);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    }
  }, [firstTime, getProjectDueDate, project.id]);

  return (
    <>
      <Container>
        <Project>
          <Label htmlFor="projectName">
            Projeto
            <textarea
              id="projectName"
              defaultValue={project.name}
              onBlur={e => {
                updateProjectName(project.id, e.target.value);
              }}
            />
          </Label>
          <Separator />
          <Deadline>
            Prazo
            <p>{convertDueDateInDate}</p>
          </Deadline>
          <Separator />
          <Label htmlFor="projectDescription">
            Descrição
            <textarea
              id="projectDescription"
              defaultValue={project.desc}
              onBlur={e => {
                updateProjectDescription(project.id, e.target.value);
              }}
            />
          </Label>
          <Buttons>
            <CloseButton
              onClick={() => {
                deleteProject(project.id);
              }}
              disabled={false}
            />
            <AccordionButton
              type="button"
              className={`${setActive}`}
              onClick={toggleAccordion}
            >
              <FaAngleDown className={`${setRotate}`} />
            </AccordionButton>
          </Buttons>
        </Project>
        <Accordion ref={content} style={{ maxHeight: `${setHeight}` }}>
          <DeliveryGroups>
            {project.groups &&
              project.groups.map(group => (
                <Group
                  key={group.id}
                  group={group}
                  projectId={project.id}
                  setProjectDueDate={setProjectDueDate}
                />
              ))}
            <AddNewButton onClick={() => addNewGroup(project.id)}>
              Adicionar novo grupo de entrega
            </AddNewButton>
          </DeliveryGroups>
          <IndividualTasks>
            {project.tasks &&
              project.tasks.map(individualTask => (
                <IndividualTask
                  key={individualTask.id}
                  individualTask={individualTask}
                  projectId={project.id}
                  setProjectDueDate={setProjectDueDate}
                />
              ))}
            <AddNewButton onClick={() => addNewIndividualTask(project.id)}>
              Adicionar nova task individual
            </AddNewButton>
          </IndividualTasks>
        </Accordion>
      </Container>
    </>
  );
};

export default ProjectItem;
