/* eslint-disable import/no-unresolved */
import React from 'react';
import { FiX } from 'react-icons/fi';

import { NewChargePopUp } from './styles';

export const Popup = ({ children, header, closePopUp }) => {
  return (
    <NewChargePopUp>
      <div className="container">
        <div className="header">
          <p>{header}</p>
          <FiX onClick={() => closePopUp(false)} size={12} className="FiX" />
        </div>
        <main>{children}</main>
      </div>
    </NewChargePopUp>
  );
};
