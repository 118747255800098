import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray);
  svg {
    cursor: pointer;
    color: var(--gray);
  }
`;
