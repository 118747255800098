import React from 'react';

import { useBlock } from '../../../../contexts/HandleCheckinBlock';
import { Button } from '../../../../components/Inputs';

interface ButtonProps {
  handleClick: () => void;
}

const NextPageButton: React.FC<ButtonProps> = ({ handleClick }) => {
  const { isBlocked } = useBlock();

  function buttonFunction() {
    if (!isBlocked) handleClick();
  }

  return (
    <Button
      className="continueButton"
      background={isBlocked ? 'var(--gray)' : 'var(--red-primary)'}
      disabled={isBlocked}
      type="submit"
      height="1.8rem"
      width="6rem"
      font="800 0.7rem Work Sans"
      text={isBlocked ? 'Carregando...' : 'Continuar'}
      onClick={() => buttonFunction()}
    />
  );
};

export default NextPageButton;
