import React from 'react';
import Avatar from 'react-avatar';

import { Link } from 'react-router-dom';
import * as S from './styles';
import { useAuth } from '../../../../contexts/AuthContext';

interface ProfileProps {
  buttons?: boolean;
  buttonLabel?: string;
  buttonHref?: string;
}

export const Profile: React.FC<ProfileProps> = ({
  buttons = false,
  buttonLabel,
  buttonHref,
}) => {
  const { user: profile } = useAuth();

  return (
    <S.Wrapper>
      <div>
        <Avatar
          name={profile.name}
          src={profile.avatar ? profile.avatar.url : 'https://'}
          className="avatar"
          size="8rem"
        />
      </div>
      <div>
        <h2>{`${profile.name} ${profile.last_name}`}</h2>
        <p>{profile.email}</p>
        {buttons && (
          <S.ButtonsArea>
            <Link to="/home" id="home">
              <button id="back" type="button">
                Voltar
              </button>
            </Link>
            <Link to={`${buttonHref}`}>
              <button id="go-to" type="button">
                {buttonLabel}
              </button>
            </Link>
          </S.ButtonsArea>
        )}
      </div>
    </S.Wrapper>
  );
};
