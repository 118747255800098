import styled from 'styled-components';

interface ContainerProps {
  size: string;
}

export const Container = styled.div<ContainerProps>`
  .avatar {
    border: 1px solid #121212;
    border-radius: 50%;
    padding: 0px;
    width: ${props => props.size} !important;
    height: ${props => props.size} !important;
  }
`;
