import styled from 'styled-components';

interface ContainerProps {
  editable: boolean;
}

interface UrgencyProps {
  open: boolean;
  dueDate: number;
}

interface WhatProps {
  category: string;
}

interface FooterProps {
  isUser: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 100%;
  background: ${({ theme }) => theme.backgroundSecondary};
  box-shadow: 0 4px 8px 0 rgba(1, 1, 1, 0.2), 0 6px 20px 0 rgba(1, 1, 1, 0.2);

  border-radius: 8px;
  margin: 1rem;
  margin-left: 0.5rem;
  padding: 1rem;
  color: ${({ theme }) => theme.text};
  text-align: start;
  cursor: pointer;

  > p {
    margin-top: 5px;
    padding: 45px;
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > h1 {
    margin-top: 0.8rem;
    font-size: 1.6rem;
    font-weight: 100;
    color: ${({ theme }) => theme.text};
  }

  #category {
    p {
      color: var(--white);
    }
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--white);
`;
export const Urgency = styled.div<UrgencyProps>`
  display: flex;
  > div {
    background: ${props => {
      if (!props.open) {
        return 'var(--green-primary)';
      }
      if (props.dueDate < 24) {
        return 'var(--red-primary)';
      }
      if (props.dueDate < 72) {
        return 'var(--yellow-primary)';
      }

      return 'var(--cyan-primary)';
    }};
    margin-right: 5px;
    padding: 5px;
    border-radius: 8px;
  }
`;
export const What = styled.div<WhatProps>`
  display: flex;
  > div {
    background: ${props => {
      if (props.category === 'finance') {
        return 'var(--green-primary)';
      }
      if (props.category === 'tech') {
        return 'var(--red-primary)';
      }
      if (props.category === 'sales') {
        return 'var(--purple-secondary)';
      }
      return 'var(--cyan-secondary)';
    }};
    margin-right: 5px;
    padding: 0.4rem;
    font-size: 0.6rem;
    border-radius: 8px;
  }
`;
export const Footer = styled.div<FooterProps>`
  display: flex;
  margin-top: 10px;
  .avatar {
    border-radius: 50%;
    padding: 0px;
    width: 36px !important;
    height: 36px !important;
    font-size: 36px;
    border: ${props => {
      if (props.isUser) {
        return '1px solid var(--green-primary)';
      }
      return '1px solid var(--black)';
    }};
  }
`;
