import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.6rem;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;

  h4 {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
  }

  p:last-child {
    font-size: 0.8rem;
    font-weight: bold;
  }
`;
