import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { RiCheckLine, RiPenNibLine } from 'react-icons/ri';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Button, TextInput } from '../../../../../components/Inputs';
import * as S from './styles';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import RichText from '../../../../../components/RichText';
import useForm from '../../../../../hooks/useForm';
import apiV2 from '../../../../../services/apiV2';
import { getGroupsWithPhase } from '../../../services/GetGroupsWithPhase';
import { SetPhases } from '../../../utils/SetPhases';
import { useTasks } from '../../../../../contexts/TasksContext';

interface useFormHookProps {
  form: { phaseName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

interface EditFinalPhaseProps {
  handleClose: () => void;
  phase: Phase;
  phases?: Phase[];
  projectIdByPhase?: number;
  groupIdByPhase?: number;
}

export const EditFinalPhase: React.FC<EditFinalPhaseProps> = ({
  handleClose,
  phase,
  phases,
  projectIdByPhase,
  groupIdByPhase,
}) => {
  const { projectId, groupId } = useParams<TasksParams>();
  const { projects, setProjects } = useTasks();
  const { form, onChange }: useFormHookProps = useForm({
    phaseName: phase.name,
  });
  const [groups, setGroups] = useState<
    {
      id: number;
      name: string;
      phase_id: number;
    }[]
  >([]);
  const [phaseId, setPhaseId] = useState<number>(phase.next_phase_id || 0);
  const [phaseDescription, setPhaseDescription] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const handleSelectGroup = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLSelectElement;
    if (target.value) {
      setPhaseId(Number(target.value));
    }
  };

  const setGroupsInfo = useCallback(async () => {
    const groupsWithPhases = await getGroupsWithPhase(
      +projectId || projectIdByPhase!
    );

    setGroups(groupsWithPhases);
  }, [projectId, projectIdByPhase]);

  useEffect(() => {
    setGroupsInfo();
  }, [setGroupsInfo]);

  const editPhase = useCallback(async () => {
    await apiV2
      .put(`phases`, {
        id: phase.id,
        name: form.phaseName,
        next_phase_id: phaseId > 0 ? phaseId : null,
        desc: phaseDescription,
      })
      .then(res => {
        toast.success('Fase editada com sucesso!');

        const responsePhase = {
          created_at: res.data.phase.created_at,
          desc: res.data.phase.desc,
          group_id: res.data.phase.group_id,
          icon_name: res.data.phase.icon_name,
          id: res.data.phase.id,
          name: res.data.phase.name,
          next_phase_id: res.data.phase.next_phase_id,
          order: res.data.phase.order,
          phase_checklist_items: res.data.phase.phase_checklist_items,
          remove_users: res.data.phase.remove_users,
          status: res.data.phase.status,
          tasks: res.data.phase.tasks,
          updated_at: res.data.phase.updated_at,
          users: res.data.phase.users,
          was_removed: res.data.phase.was_removed,
        };
        if (phases) {
          const phasesUpdate = phases.filter(
            phaseItem => phaseItem.id !== phase.id
          );

          phasesUpdate.splice(res.data.phase.order - 1, 0, responsePhase);
          const newProjects = SetPhases(
            [...phasesUpdate],
            projects,
            +projectId || projectIdByPhase!,
            +groupId || groupIdByPhase!
          );

          setProjects([...newProjects]);
        }
        handleClose();
      });
  }, [
    form.phaseName,
    handleClose,
    phase.id,
    phaseDescription,
    phaseId,
    phases,
    groupId,
    groupIdByPhase,
    projectId,
    projectIdByPhase,
    projects,
    setProjects,
  ]);

  return (
    <S.Wrapper>
      <TaskCard close={handleClose}>
        <header>
          <RiCheckLine
            className="icon-phase"
            size={21}
            color="var(--cyan-primary)"
          />
          <S.InputWrapper>
            <TextInput
              name="phaseName"
              onChange={handleInputChange}
              value={form.phaseName}
              background="none"
              height="1.5"
              placeholder="Editar fase"
            />
          </S.InputWrapper>
          <RiPenNibLine className="icon-phase" size={16} color="var(--gray)" />
        </header>
        <main>
          <div className="input">
            <p>Conexão</p>
            <select onChange={handleSelectGroup} value={phaseId}>
              <option value={0}>Selecionar conexão</option>
              {groups &&
                groups.map(groupItem => {
                  if (
                    groupItem.id === +groupId ||
                    groupItem.id === groupIdByPhase
                  ) {
                    return false;
                  }
                  return (
                    <option key={groupItem.id} value={groupItem.phase_id}>
                      {groupItem.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="input">
            <p>Descrição da fase</p>
            <RichText
              height={150}
              width={282}
              initialValue={phase.desc}
              contentToSave={(content: string) => {
                setPhaseDescription(content);
              }}
            />
          </div>

          <footer>
            <Button id="confirm-button" onClick={() => editPhase()}>
              <p>Confirmar</p>
            </Button>
          </footer>
        </main>
      </TaskCard>
    </S.Wrapper>
  );
};
