import styled from 'styled-components';

export const Wrapper = styled.div`
  width: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem;
  padding: 2rem 0;
`;

export const ChartBox = styled.div`
  width: 12rem;
  height: 12rem;
  border-radius: 0.5rem;
  padding: 1rem 0;
  background: ${({ theme }) => theme.backgroundSecondary};
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    width: 48%;
    text-align: center;
    font-weight: bold;
    padding-bottom: 0.5rem;
  }
  .doughnut-chart {
    width: 7rem;
    fill: ${({ theme }) => theme.text};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
