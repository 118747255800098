import React, { useState, useCallback } from 'react';

import { TextInput, Button } from '../../../../../components/Inputs';

import { CardData } from '../types';

import * as S from './styles';

interface Card {
  cardData: CardData;
  handleCardEdit: (id: string, newCard: CardData) => void;
}

const Editing: React.FC<Card> = ({ cardData, handleCardEdit }) => {
  const [newCard, setNewCard] = useState<CardData>({
    name: cardData.name,
    effect: cardData.effect,
    cause: cardData.cause,
    risk: cardData.risk,
    probability: cardData.probability,
    impact: cardData.impact,
    id: cardData.id,
  });

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;

      setNewCard({ ...newCard, [name]: value });
    },
    [newCard]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      handleCardEdit(cardData.name, newCard);
    },
    [cardData.name, handleCardEdit, newCard]
  );

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <header>
        <div
          className="name"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <S.Title>Nome:</S.Title>
          <TextInput
            maxLength={48}
            required
            placeholder="Digite aqui"
            name="name"
            value={newCard.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
            }}
          />
        </div>
      </header>

      <main>
        <div className="texts">
          <div className="textHolder">
            <S.Title>Causa:</S.Title>
            <TextInput
              maxLength={200}
              required
              placeholder="Digite aqui"
              name="cause"
              value={newCard.cause}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="textHolder">
            <S.Title>Risco:</S.Title>
            <TextInput
              maxLength={200}
              required
              placeholder="Digite aqui"
              name="risk"
              value={newCard.risk}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
            />
          </div>

          <div className="textHolder">
            <S.Title>Efeito:</S.Title>
            <TextInput
              maxLength={64}
              required
              placeholder="Digite aqui"
              name="effect"
              value={newCard.effect}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
            />
          </div>
        </div>

        <S.Values>
          <S.ValueHolder style={{ display: 'flex', flexDirection: 'column' }}>
            <S.Title>Probabilidade:</S.Title>
            <TextInput
              required
              placeholder="Digite aqui"
              type="number"
              max="10"
              name="probability"
              value={newCard.probability}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
            />
          </S.ValueHolder>

          <S.ValueHolder style={{ display: 'flex', flexDirection: 'column' }}>
            <S.Title>Impacto:</S.Title>
            <TextInput
              required
              placeholder="Digite aqui"
              type="number"
              max="10"
              name="impact"
              value={newCard.impact}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(e);
              }}
            />
          </S.ValueHolder>

          <Button
            background="var(--red-primary)"
            type="submit"
            className="button"
            height="1.8rem"
            width="6rem"
            font="800 0.7rem Work Sans"
            text="Atualizar"
          />
        </S.Values>
      </main>
    </form>
  );
};

export default Editing;
