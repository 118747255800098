import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getUnits(): Promise<Unit[]> {
  try {
    const response = await apiV2.get(`/units`);
    const units = response.data;

    return units;
  } catch (err) {
    toast.error(
      `Ocorreu algum problema ao carregar os contratos, atualize a página e tente novamente :S`
    );
    return [] as Unit[];
  }
}
