import styled from 'styled-components';

export const Compliance = styled.section`
  width: 100%;
  height: calc(100vh - 6.2vh);
  padding: 1.25rem 0;
  background-color: ${({ theme }) => theme.backgroundPrimary};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  input,
  a {
    font-family: 'Open Sans', sans-serif;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 1.44px;
    line-height: 1rem;
    color: ${({ theme }) => theme.text};
  }

  h4 {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.96px;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.text};
  }

  h5 {
    font-size: 0.875rem;
    letter-spacing: 0.84px;
    line-height: 0.75rem;
    font-weight: 700;
    color: ${({ theme }) => theme.text};
  }

  p {
    font-size: 0.625rem;
    letter-spacing: 0.22px;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.text};
  }

  strong {
    font-size: 0.75rem;
    letter-spacing: 0.24px;
    line-height: 1rem;
  }

  button.disabled {
    cursor: not-allowed;
    opacity: 0.3;
    position: relative;

    :hover span {
      visibility: visible;
      opacity: 1;
    }

    span {
      visibility: hidden;
      width: 100%;
      background-color: var(--gray);
      color: ${({ theme }) => theme.text};
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;

      :after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: var(--gray) transparent transparent transparent;
      }
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  height: 4rem;
  display: flex;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 5px 5px 0 0;
  align-items: center;
  padding: 1rem 1rem;
`;

export const Buttons = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-self: center;

  button {
    width: max-content;
    min-width: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray);
    color: var(--white);
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    border: none;
    margin: 0 0.25rem;

    :focus {
      background-color: var(--red-primary);
    }

    &.isActive {
      background: var(--red-primary);
    }
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  margin: 1rem 0;
  overflow: auto;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 5px;
`;

export const TableHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
`;

export const HeaderTabs = styled.div`
  display: flex;

  a {
    text-decoration: none;
    &:active {
      text-decoration: none;
    }

    :first-child button {
      border-radius: 5px 0 0 0;
    }
    :last-child button {
      border-radius: 0 5px 0 0;
    }

    button {
      width: max-content;
      min-width: 10rem;
      height: 2.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${({ theme }) => theme.backgroundSecondary};
      color: ${({ theme }) => theme.text};
      padding: 0.5rem 0.5rem;
      border: none;
      font-size: 0.75rem;
      letter-spacing: 0.96px;
      font-weight: 700;
      border-radius: 0%;
      transition: background 0.2s;

      &.isActive {
        background: var(--red-primary);
        color: var(--white);
      }
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;

  div {
    display: flex;
    flex-direction: column;
    position: relative;

    ul {
      display: none;
      position: absolute;
      flex-direction: column;
      top: 2.4rem;
      background: ${({ theme }) => theme.backgroundPrimary};
      border-radius: 0 0 12px 12px;
      z-index: 10;
      border: 1px solid var(--gray);
      border-top: none;
      opacity: 0.9;

      &.isActive {
        display: flex;
      }
      button {
        border: none;
        border-radius: 0;

        &:hover {
          box-shadow: 0 0 8px rgb(0 0 0 / 60%);
          background: var(--red-primary);
          color: var(--white);
        }
      }

      button + button {
        margin-left: 0;
        margin-top: 0.5rem;
        border-radius: 0 0 12px 12px;
      }
    }

    button:hover {
      box-shadow: 0 0 8px rgb(0 0 0 / 60%);
      background: var(--red-primary);
      color: var(--white);
    }
  }

  button {
    width: 8rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--gray);
    background-color: transparent;
    font-weight: 400;
    color: ${({ theme }) => theme.text};
    border-radius: 12px;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button + button {
    margin-left: 0.5rem;
  }

  .period {
    border: none;
    justify-content: center;
    color: var(--white);
    background-color: var(--red-primary);
  }
`;
