import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { RiCalendar2Line, RiUserLine, RiFocus3Line } from 'react-icons/ri';
import { format } from 'date-fns';

import { Button } from '../../../../../components/Inputs';
import UserAvatar from '../../../../../components/UserAvatar';
import ProgressBar from '../../../../../components/Progressbar';

import { Quarter } from '../types';

import * as S from './styles';

interface QuarterCard {
  quarter: {
    goal: number;
    quarter: Quarter;
    responsibles: { id: number }[];
  };
  quarterNumber: number;
}

const Card: React.FC<QuarterCard> = ({ quarter, quarterNumber }) => {
  const responsibles = useMemo(() => {
    const result: number[] = [];
    quarter.responsibles.forEach(user => {
      result.push(user.id);
    });
    return result;
  }, [quarter.responsibles]);

  const handleProgressColor = useCallback(() => {
    const start = new Date(quarter.quarter.start_date).getTime();
    let end = new Date(quarter.quarter.next_quarter).getTime();
    const today = new Date().getTime();

    if (end < today) end = today;

    const progress = (Math.abs(today - start) / Math.abs(end - start)) * 100;

    if (quarter.goal >= 100) return 'var(--green-primary)';
    if (progress - quarter.goal <= -5) return 'var(--green-primary)';
    if (progress - quarter.goal <= 5) return 'var(--yellow-primary)';
    return 'var(--red-primary)';
  }, [quarter.goal, quarter.quarter.next_quarter, quarter.quarter.start_date]);

  return (
    <S.Container>
      <S.DataHolder>
        <div className="labelHolder">
          <p style={{ color: ' var(--red-primary)' }} className="info">
            Quarter
          </p>
        </div>
        <p>Quarter {quarterNumber + 1}</p>
      </S.DataHolder>

      <S.DataHolder>
        <div className="labelHolder">
          <RiCalendar2Line size={14} color="var(--red-primary)" />
          <p className="info">Data de início e fim</p>
        </div>
        <p>
          {`${format(new Date(quarter.quarter.start_date), 'dd/MM/yyyy')} até
          ${format(new Date(quarter.quarter.next_quarter), 'dd/MM/yyyy')}`}
        </p>
      </S.DataHolder>

      <S.DataHolder>
        <div className="labelHolder">
          <RiUserLine size={14} color="var(--red-primary)" />
          <p className="info">Responsável</p>
        </div>

        <div className="users">
          {responsibles.map(user => {
            return <UserAvatar id={user} key={user} />;
          })}
        </div>
      </S.DataHolder>

      <S.DataHolder>
        <div className="labelHolder">
          <RiFocus3Line size={14} color="var(--red-primary)" />
          <p className="info">Meta</p>
        </div>
        <div className="progressBar">
          <ProgressBar
            color={handleProgressColor()}
            percentage={`${quarter.goal}`}
            title={`${quarter.goal}%`}
          />
        </div>
      </S.DataHolder>

      <Link to={`/checkin/quarter/${quarter.quarter.id}`}>
        <Button
          background="var(--red-primary)"
          width="100%"
          height="2rem"
          text="Visualizar"
          font="700 0.9rem Work Sans"
        />
      </Link>
    </S.Container>
  );
};

export default Card;
