import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 92%;
  align-self: flex-end;
  height: 5.5rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.backgroundTerciary};
  color: var(--gray);
  align-items: center;
  transition: 0.3s;

  :hover {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(227, 36, 56, 0.3);
  }

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(255, 255, 255, 0.2);
      border-radius: 1rem;
      background: transparent;
      box-shadow: none !important;
      cursor: grabbing;
      .drag {
        opacity: 0.8;
      }
    `}

  @media (max-width: 1650px) {
    width: 90%;
  }

  @media (max-width: 1400px) {
    width: 87%;
    height: 7rem;
  }

  @media (max-width: 1030px) {
    width: 84%;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TagsArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.4rem;
  margin-left: 1rem;
`;

export const Main = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  button {
    border: none;
    background: none;
    color: var(--gray);
  }

  .grid-item {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;

    p:last-child {
      color: var(--gray);
      margin-left: 0.1rem;
    }

    p:first-child {
      color: var(--gray);
      margin-right: 0.1rem;
    }
  }

  #name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    align-items: center;
    font-size: 0.7rem;
    cursor: pointer;
    > div {
      p {
        color: ${({ theme }) => theme.text};
      }
    }

    div h4 {
      color: var(--red-primary);
    }

    button {
      svg {
        color: ${({ theme }) => theme.text};
      }
    }
  }

  #managers {
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
  }

  #period {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #progress {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #add-new {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      svg {
        color: ${({ theme }) => theme.text};
      }
    }
  }
`;

export const RestoreTask = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  button {
    display: flex;
    align-items: center;
    color: var(--gray);
    background: none;
    border: none;

    #restore-label {
      margin: 0 0.5rem;
    }
  }
`;

export const StickerColor = styled.div`
  justify-content: flex-end;
  width: 0.5%;
  height: 100%;
  background-color: var(--red-primary);
  border-radius: 0 1rem 1rem 0;
  outline: none;
`;
