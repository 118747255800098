const selectProjectById = (
  projectId: number,
  globalProjects: IProject[]
): IProject | undefined => {
  const selectedProject = globalProjects.find(
    project => project.id === projectId
  );

  return selectedProject;
};

export default selectProjectById;
