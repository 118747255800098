import { toast } from 'react-toastify';
import { ChangeEvent, FC, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

import { ReactComponent as TopAtomo } from '../../assets/images/top-atomo.svg';
import { ReactComponent as BottomAtomo } from '../../assets/images/bottom-atomo.svg';
import { useAuth } from '../../contexts/AuthContext';
import * as S from './styles';

const Login: FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }
  function onChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit = useCallback(
    async (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);

      try {
        await signIn({ email, password });

        history.push('/finance');
      } catch {
        toast.error('Ocorreu um erro ao fazer login, verifique as credenciais');
      }

      setIsLoading(false);
    },
    [email, password, signIn, history]
  );

  return (
    <S.Container>
      <S.BackgroundContainer />
      <TopAtomo
        style={{
          position: 'absolute',
          top: '-6%',
          right: '-4%',
        }}
      />
      <BottomAtomo
        style={{
          position: 'absolute',
          bottom: '-6%',
          left: '-4%',
        }}
      />
      <S.Form onSubmit={onSubmit}>
        <S.Logo />
        <S.InputContainer htmlFor="email">
          Email
          <input
            type="email"
            name="email"
            placeholder="Digite aqui"
            value={email}
            onChange={onChangeEmail}
          />
        </S.InputContainer>
        <S.InputContainer htmlFor="senha">
          Senha
          <input
            type={passwordShown ? 'text' : 'password'}
            name="senha"
            placeholder="Digite aqui"
            value={password}
            onChange={onChangePassword}
          />
          {passwordShown ? (
            <BsFillEyeFill onClick={togglePasswordVisiblity} />
          ) : (
            <BsFillEyeSlashFill onClick={togglePasswordVisiblity} />
          )}
        </S.InputContainer>
        <Link to="/forgot_password">Esqueceu a senha?</Link>
        <button type="submit">{isLoading ? 'Carregando...' : 'Entrar'}</button>
        <p>
          Não tem uma conta? <Link to="/register">Inscrever-se</Link>
        </p>
      </S.Form>
    </S.Container>
  );
};

export default Login;
