import { Player } from '@lottiefiles/react-lottie-player';
import Animation from '../../../../assets/animations/AnimationLoading.json';

import * as S from './styles';

interface LoadingButtonProps {
  width: string;
  height: string;
}

export const LoadingButton = ({
  width,
  height,
}: LoadingButtonProps): JSX.Element => {
  return (
    <S.Wrapper type="button" style={{ width, height }}>
      <Player
        style={{
          height: '1.8rem',
          width: '1.8rem',
        }}
        autoplay
        loop
        src={Animation}
      />
    </S.Wrapper>
  );
};
