/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { format, addMinutes } from 'date-fns';
import { Popup } from './popup';
import { Container, Background } from './styles';
import HistoryItem from './historyItem';
import api from '../../../../services/api';
import CancelModal from './cancelModal';

const EditPopup = ({
  itemType,
  setEditPopup,
  itemId,
  jsonData,
  setJsonData,
  itemRoyalties,
  setOnLoading,
  setOverallUpdate,
  iuguId,
  overallUpdate,
  cardUnitId,
}) => {
  const [endDate, setEndDate] = useState('');
  const [showCancelModal, setShowCancelModal] = useState('');
  const [feeValue, setFeeValue] = useState('');
  const [receivedDate, setReceivedDate] = useState('');
  const [royaltysValue, setRoyaltysValue] = useState(itemRoyalties);
  const [iuguIdValue, setIuguIdValue] = useState(iuguId);
  const [status, setStatus] = useState('');
  const [sendToContaAzul, setSendToContaAzul] = useState('no');
  const [names, setNames] = useState({
    client: 'Carregando...',
    unit: 'Carregando...',
    paymentUrl: 'Carregando...',
  });
  const [history, setHistory] = useState([]);
  const [description, setDescription] = useState(false);
  const formatDate = useCallback(date => {
    return format(addMinutes(new Date(date), 500), 'yyyy-MM-dd');
  }, []);

  const handleReAprove = useCallback(async () => {
    await api
      .put('billing/approval', {
        id: itemId,
        reapproval: true,
      })
      .then(res => {
        const message = () => {
          if (res.data === `Billing ${itemId} approved by unit`) {
            return `Cobrança ${itemId} aprovada pela unidade`;
          }

          if (res.data === `Billing Approved`) {
            return `Cobrança aprovada!`;
          }

          if (res.data === `Permission denied to approve billing ${itemId}`) {
            return `Permissão negada para aprovar a cobrança ${itemId}`;
          }

          if (res.data === `Billing ${itemId} approved by admin`) {
            return `Cobrança ${itemId} aprovada pelo admin`;
          }

          if (res.data === `No data altered in the billing ${itemId}`) {
            return `Nenhum dado alterado na billing ${itemId}`;
          }

          return 'Aprovada';
        };
        toast.success(message);
        setOverallUpdate(overallUpdate + 1);
        setEditPopup(false);
      })
      .catch(err => {
        if (err.response.status !== 200) {
          const message = () => {
            if (err.response.data.error === `Validation fails.`) {
              return `Falha na validação`;
            }

            if (err.response.data.error === `Invalid User`) {
              return `Usúario inválido`;
            }

            if (
              err.response.data.error === `Billing ${itemId} approved by admin`
            ) {
              return `Cobrança ${itemId} aprovada pelo admin`;
            }

            if (err.response.data.error === 'Invalid billing selected') {
              return 'Cobrança selecionada inválida';
            }

            return err.response.data.error;
          };
          toast.error(message);
        }
      });
  }, [itemId, overallUpdate, setEditPopup, setOverallUpdate]);

  const handleCancel = useCallback(async () => {
    await api
      .put('billing/delete', {
        id: itemId,
      })
      .then(() => {
        setJsonData(jsonData.filter(item => item.id !== itemId));
        toast.success(`Billing ${itemId} cancelada com sucesso!`);
        setEditPopup(false);
      })
      .catch(err => {
        if (err.response.status !== 200) {
          let message = '';
          if (err.response.data.error.errors === 'Not Found') {
            message = 'Iugu: Erro, não encontrado.';
          } else {
            message = err.response.data.error;
          }
          toast.error(`${message}`);
        }
      });

    setShowCancelModal(false);
  }, [itemId, jsonData, setEditPopup, setJsonData]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (itemType === 'Iugu') {
        setOnLoading(true);
        await api
          .put('billing', {
            id: itemId,
            due_date: endDate,
            fee_value: feeValue.replace(',', '.'),
            royalties_value: royaltysValue,
            unit_id: cardUnitId,
            comment: description,
            iugu_id: iuguIdValue,
            send_to_conta_azul: sendToContaAzul,
          })
          .then(() => {
            toast.success('Cobrança Editada! ;)');
            setEditPopup(false);
          })
          .catch(err => {
            if (err?.response?.status !== 200) {
              toast.error(`${err.response.data.error}`);
            }
          });
        setOverallUpdate(overallUpdate + 1);
        setOnLoading(false);
      } else {
        setOnLoading(true);
        await api
          .put('billing', {
            id: itemId,
            due_date: endDate,
            fee_value: feeValue.replace(',', '.'),
            received_date: receivedDate,
            royalties_value: royaltysValue,
            unit_id: cardUnitId,
            comment: description,
            ended: status === 'true',
            status: status === 'true' ? 'paid' : 'pending',
          })
          .then(() => {
            toast.success('Cobrança Editada! ;)');
            setEditPopup(false);
          })
          .catch(err => {
            if (err.response.status !== 200) {
              const message = () => {
                if (err.response.data.error === 'Validation fails.') {
                  return 'Falha na validação';
                }

                if (err.response.data.error === 'Invalid User') {
                  return 'Usuário invalido';
                }

                if (err.response.data.error === 'Permission Denied') {
                  return 'Permissão Negada';
                }

                if (err.response.data.error === 'Invalid billing selected') {
                  return 'A cobrança selecionada é inválida.';
                }

                if (err.response.data.error === 'Waiting for Unit approval') {
                  return 'Esperando aprovação da unidade.';
                }

                return err.response.data.error;
              };
              toast.error(`${message}`);
            }
          });
        setOverallUpdate(overallUpdate + 1);
        setOnLoading(false);
      }
    },
    [
      itemType,
      setOnLoading,
      itemId,
      endDate,
      feeValue,
      royaltysValue,
      cardUnitId,
      description,
      iuguIdValue,
      setOverallUpdate,
      overallUpdate,
      setEditPopup,
      receivedDate,
      status,
      sendToContaAzul,
    ]
  );

  useEffect(() => {
    api.get(`billing?billingId=${itemId}`).then(response => {
      const { data } = response;

      setEndDate(formatDate(data.due_date));
      setFeeValue(data.fee_value);
      setReceivedDate(formatDate(data.received_date));
      setNames({
        client: data.client.name,
        unit: data.unit.name,
        paymentUrl: data.payment_url,
      });
    });

    api.get(`/billing/histories?billingId=${itemId}`).then(response => {
      setHistory(response.data);
    });
  }, [formatDate, itemId]);

  return (
    <>
      {showCancelModal ? (
        <CancelModal
          closeFunction={setShowCancelModal}
          deleteFunction={handleCancel}
        />
      ) : (
        <> </>
      )}
      <Background />
      <Container>
        <div className="popups">
          <Popup closePopUp={setEditPopup} header="Editar cobrança">
            <form onSubmit={e => handleSubmit(e)}>
              <h1
                style={{
                  padding: 0,
                  marginLeft: '20px',
                  marginBottom: '10px',
                  fontSize: '16px',
                }}
              >
                Cliente: {names.client}
              </h1>
              <h1
                style={{
                  padding: 0,
                  marginLeft: '20px',
                  fontSize: '16px',
                }}
              >
                Franqueado: {names.unit}
              </h1>{' '}
              <h1
                style={{
                  padding: 0,
                  marginLeft: '20px',
                  fontSize: '16px',
                }}
              >
                URL do boleto: <a href={names.paymentUrl}>{names.paymentUrl}</a>
              </h1>
              <main>
                <div className="inputs">
                  <div className="input">
                    <label htmlFor="sendToContaAzul">
                      Enviar para conta azul:
                    </label>

                    <select
                      value={sendToContaAzul}
                      onChange={e => {
                        setSendToContaAzul(e.target.value);
                      }}
                    >
                      <option value="no">Não</option>
                      <option value="yes">Sim</option>
                      <option value="sent">Enviado</option>
                    </select>
                  </div>
                </div>

                <div className="inputs">
                  {itemType === 'Iugu' ? (
                    <div className="input">
                      <label htmlFor="iuguId">Iugu Id:</label>
                      <input
                        required
                        id="iuguId"
                        name="iuguId"
                        type="text"
                        onChange={e => {
                          setIuguIdValue(e.target.value);
                        }}
                        value={iuguIdValue}
                        style={{ width: '450px' }}
                      />
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>
                <div className="inputs">
                  <div className="input">
                    <label htmlFor="value">Valor</label>
                    <input
                      required
                      id="value"
                      name="value"
                      type="number"
                      data-type="currency"
                      placeholder="Insira o valor em n°"
                      onChange={e => {
                        setFeeValue(e.target.value);
                      }}
                      value={feeValue}
                    />
                    <p
                      style={{
                        position: 'absolute',
                        color: 'var(--gray)',
                        fontSize: '12px',
                        opacity: '0.7',
                      }}
                    >
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(!feeValue ? 0 : feeValue)}
                    </p>
                  </div>

                  <div className="input">
                    <label htmlFor="vencimento">Vencimento</label>
                    <input
                      onChange={e => {
                        setEndDate(e.target.value);
                      }}
                      value={endDate}
                      required
                      id="vencimento"
                      name="vencimento"
                      type="date"
                      placeholder="Data"
                    />
                  </div>

                  {itemType.includes('Manual') ? (
                    <>
                      <div className="input">
                        <label htmlFor="status">Status</label>
                        <select
                          value={status}
                          onChange={e => {
                            setStatus(e.target.value);
                          }}
                          required
                          id="status"
                          name="status"
                        >
                          <option value="" disabled>
                            Selecione o status
                          </option>
                          <option value="true">Pago</option>
                          <option value="false">Não Pago</option>
                        </select>
                      </div>

                      <div className="input">
                        <label htmlFor="recebimento">Recebimento</label>
                        <input
                          required
                          id="recebimento"
                          name="recebimento"
                          type="date"
                          placeholder="Data"
                          value={receivedDate}
                          onChange={e => {
                            setReceivedDate(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <> </>
                  )}

                  <div className="input">
                    <label htmlFor="royaltys">Royalties</label>

                    {itemType.includes('Iugu') ? (
                      <input
                        min="0"
                        max="70"
                        required
                        id="royaltys"
                        pattern="[0-9]"
                        name="royaltys"
                        type="number"
                        placeholder="Insira o valor em %"
                        onChange={e => {
                          setRoyaltysValue(e.target.value);
                        }}
                        value={royaltysValue}
                      />
                    ) : (
                      <input
                        min="0"
                        max="100"
                        required
                        id="royaltys"
                        pattern="[0-9]"
                        name="royaltys"
                        type="number"
                        placeholder="Insira o valor em %"
                        onChange={e => {
                          setRoyaltysValue(e.target.value);
                        }}
                        value={royaltysValue}
                      />
                    )}

                    <p
                      style={{
                        position: 'absolute',
                        marginTop: '60px',
                        color: 'var(--gray)',
                        fontSize: '12px',
                        opacity: '0.7',
                      }}
                    >
                      {royaltysValue}%
                    </p>
                  </div>
                </div>

                <div style={{ marginTop: '40px' }} className="description">
                  <label htmlFor="description">Descrição</label>
                  <textarea
                    required
                    placeholder="Descreva a nova cobrança aqui!"
                    id="description"
                    name="description"
                    cols="20"
                    rows="5"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </div>
              </main>
              <div className="cancelarRow">
                <button
                  onClick={() => setShowCancelModal(true)}
                  type="button"
                  className="cancelar"
                >
                  Cancelar Fatura
                </button>
                <button
                  onClick={() => setEditPopup(false)}
                  className="button"
                  type="button"
                >
                  Cancelar
                </button>
              </div>
              <footer>
                <div>
                  {!iuguId && itemType === 'Iugu' && (
                    <button
                      onClick={() => handleReAprove()}
                      className="button"
                      type="button"
                    >
                      Re-aprovar
                    </button>
                  )}

                  <button className="button" type="submit">
                    Salvar
                  </button>
                </div>
              </footer>
            </form>
          </Popup>
          <Popup
            closePopUp={setEditPopup}
            style={{ marginLeft: '20px !important' }}
            header="Historico"
          >
            <div className="history">
              {history.map(historyItem => {
                return <HistoryItem key={historyItem.id} data={historyItem} />;
              })}
            </div>
          </Popup>
        </div>
      </Container>
    </>
  );
};

export default EditPopup;
