import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.text};
  padding: 1rem;
  height: 100%;
  overflow-y: auto;

  .topBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  header {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
  }

  hr {
    border: 1px solid ${({ theme }) => theme.backgroundTerciary};
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .predefinedText {
    margin-bottom: 1rem;
  }

  .pageTitle {
    margin-top: 1.6rem;
    margin-right: 5rem;
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    margin-bottom: 1.6rem;
    white-space: nowrap;
    font-size: 0.7rem;
  }

  .pageTitle h1 {
    color: ${({ theme }) => theme.text};
  }

  .pageTitle h1:last-child {
    color: var(--red-primary);
  }

  .steps {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .popup-content {
    background: ${({ theme }) => theme.backgroundSecondary} !important;
  }
`;

export const Step = styled.div<{ selected: boolean }>`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ selected, theme }) =>
    selected ? 'var(--red-primary)' : theme.backgroundSecondary};
  border-radius: 8px;
  margin-left: 1rem;
  cursor: pointer;
  transition: 0.3s;

  h1 {
    font-size: 1rem;
    color: ${({ selected, theme }) => (selected ? 'var(--white)' : theme.text)};
  }

  :hover {
    background: var(--red-primary);
    box-shadow: 0 4px 8px 0 rgba(227, 36, 56, 0.5),
      0 6px 20px 0 rgba(227, 36, 56, 0.5);

    h1 {
      color: var(--white);
    }
  }
`;
