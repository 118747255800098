import { FC, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import * as S from './styles';
import Tooltip from './Tooltip';

interface ChartCardProps {
  title: string;
  color: string;
  percent: number;
  users: User[];
}

const ChartCard: FC<ChartCardProps> = ({ title, color, percent, users }) => {
  const [visible, setVisible] = useState(false);

  return (
    <S.Container>
      <p>{title}</p>
      {users && (
        <S.TooltipArea
          style={{ cursor: users.length > 0 ? 'pointer' : 'default' }}
          aria-hidden="true"
          onClick={() => setVisible(!visible)}
        >
          <PieChart
            className="doughnut-chart"
            data={[
              { title: 'One', value: percent, color: `${color}` },
              { title: 'Two', value: 100 - percent, color: 'var(--gray)' },
            ]}
            lineWidth={15}
            label={() => `${percent || 0}%`}
            labelStyle={{
              fontSize: '25px',
              fontFamily: 'sans-serif',
            }}
            labelPosition={0}
          />
          {visible && users.length > 0 && <Tooltip users={users} />}
        </S.TooltipArea>
      )}
    </S.Container>
  );
};

export default ChartCard;
