/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { RiTimeLine, RiLineChartFill, RiDeleteBin2Line } from 'react-icons/ri';
import { BiLayer } from 'react-icons/bi';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './styles';

import { Tag } from '../../../../components/Tag';
import { useTags } from '../../../../../../contexts/TagsContext';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { Responsibles } from '../../../../components/Responsibles';
import { Modal } from '../../../../styles';
import GroupEdit from '../../../../components/Modals/GroupEdit';
import useProgress from '../../../../../../hooks/useProgress';
import { PopupText } from '../../../../components/PopupText';
import { restoreGroup } from '../../../../services/RestoreGroup';
import { useTasks } from '../../../../../../contexts/TasksContext';
import { setGroups } from '../../../../utils/SetGroups';

interface GroupCardProps {
  group: Group;
  groups: Group[];
  opacity?: number;
}

export function GroupCard({ group, groups, opacity = 1 }: GroupCardProps) {
  const history = useHistory();
  const { id, projectId } = useParams<TasksParams>();
  const { projects, setProjects } = useTasks();
  const [openModal, setOpenModal] = useState(false);
  const { progress, colors } = useProgress();
  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();

  useEffect(() => {
    setTags(() => (group.tags ? group.tags : []));
  }, [group]);

  const periodProgressPercent = useMemo(
    () => progress(group.created_at, group.due_date),
    [group.created_at, group.due_date, progress]
  );

  const periodProgressTitle = useMemo(
    () =>
      group.due_date
        ? format(new Date(group.due_date), 'dd.MMMM.yyyy', {
            locale: ptBR,
          }).toString()
        : 'Sem prazo',
    [group.due_date]
  );

  const progressBarsColors = useMemo(
    () => colors(group.due_date, group.finished_at),
    [colors, group.due_date, group.finished_at]
  );

  const progressTitle = useMemo(
    () => `${group.progress ? Math.round(group.progress * 100) / 100 : 0}%`,
    [group.progress]
  );

  const handleOpenModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') handleOpenModal();
  };

  const handleOpenGroup = () => {
    history.push(
      `/tasks/${id}/kanban/projects/${projectId}/groups/${group.id}`
    );
  };

  const handleRestoreGroup = useCallback(
    async (groupToRestore: Group) => {
      await restoreGroup(groupToRestore.id).then(() => {
        const groupUndeleted = {
          ...groupToRestore,
          was_removed: false,
        };

        const groupsUpdate = groups!.filter(
          groupItem => groupItem.id !== groupToRestore.id
        );

        groupsUpdate.splice(groupToRestore.order! - 1, 0, groupUndeleted);
        const newProjects = setGroups(groupsUpdate, projects, +projectId);
        setProjects(() => newProjects);
        toast.success('Grupo restaurado com sucesso');
      });
    },
    [groups, projects, projectId, setProjects]
  );

  return (
    <>
      <S.Wrapper>
        <S.Container style={{ opacity }}>
          <S.TagsArea>
            {tags &&
              tags.map((tag: ITag, index) => {
                if (index >= 10) {
                  return false;
                }
                return (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    type="group_id"
                    typeId={group.id}
                    setTagsList={setTags}
                    showLabel={show}
                    setShowLabel={handleShowTags}
                  />
                );
              })}
          </S.TagsArea>
          <S.Header>
            <S.Title>
              <BiLayer size={14} color="var(--red-primary)" />
              <p role="button" onClick={handleOpenGroup}>
                Grupo de Entrega
              </p>
              <button type="button" onClick={handleOpenModal}>
                <FaRegEdit size={14} id="edit-button" color="var(--gray)" />
              </button>
            </S.Title>
            <div id="responsibles">
              {group.was_removed ? (
                <S.RestoreGroup>
                  <button
                    type="button"
                    title={group.name}
                    onClick={() => handleRestoreGroup(group)}
                  >
                    <RiDeleteBin2Line size={14} color="var(--red-primary)" />
                    <p id="restore-label">Restaurar Grupo</p>
                  </button>
                </S.RestoreGroup>
              ) : (
                <Responsibles users={group.users} size="1.3rem" />
              )}
            </div>
          </S.Header>
          <div className="name" role="button" onClick={handleOpenGroup}>
            <PopupText popUpText={group.name}>
              {group.name.length > 27
                ? `${group.name.substring(0, 35)}...`
                : group.name}
            </PopupText>
          </div>
          <S.Content onClick={handleOpenGroup}>
            <div id="period">
              <RiTimeLine
                className="icon"
                size={14}
                color="var(--red-primary)"
              />
              <p>Prazo</p>
              <ProgressDisplay
                width="55%"
                height="1rem"
                borderRadius="0.3rem"
                percentage={periodProgressPercent}
                title={periodProgressTitle}
                colorBar={progressBarsColors.dueDateColor}
              />
            </div>
            <div id="progress">
              <RiLineChartFill
                className="icon"
                size={14}
                color="var(--red-primary)"
              />
              <p>Progresso</p>

              <ProgressDisplay
                width="55%"
                height="1rem"
                borderRadius="0.3rem"
                percentage={group.progress}
                title={progressTitle}
                colorBar={progressBarsColors.dueDateColor}
              />
            </div>
          </S.Content>
        </S.Container>
      </S.Wrapper>

      {openModal && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <GroupEdit
            group={group}
            projectId={+projectId}
            handleClose={handleCloseModal}
            tags={tags}
          />
        </Modal>
      )}
    </>
  );
}
