import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 92%;
  height: 75%;
  display: flex;
  justify-content: space-between;
`;

export const Board = styled.div`
  min-width: 31rem;
  max-width: 31rem;
  height: 100%;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  padding: 1rem;
  margin: 0 1rem;
  @media (max-width: 1890px) {
    margin-right: 1rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  height: 8%;
  display: flex;
  border-bottom: 0.1rem solid var(--gray);
  color: ${({ theme }) => theme.text};
  align-items: center;

  p {
    margin: 0 0.5rem;
  }

  @media (max-width: 1300px) {
    height: 12%;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;

  overflow: auto;

  ::-webkit-scrollbar {
    height: 0.4rem;
    border-radius: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.text};
  }
`;

export const ButtonsArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  button {
    width: 49%;
    height: 1.6rem;
    border-radius: 0.5rem;
    margin: 0;
    color: ${({ theme }) => theme.text};
  }
  #new-group {
    border: none;
    background-color: var(--red-primary);
    color: var(--white);
  }
  #new-individual-task {
    border: 0.1rem solid var(--gray);
  }
`;
