import React from 'react';
import { IoMdClose } from 'react-icons/io';

import { Container } from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: React.MouseEvent) => void;
  disabled: boolean;
}

const CloseButton: React.FC<ButtonProps> = ({ onClick, disabled }) => {
  return (
    <Container type="button" onClick={onClick} disabled={disabled}>
      <IoMdClose />
    </Container>
  );
};

export default CloseButton;
