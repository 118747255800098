import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray);
  padding: 10px;

  .arrow {
    margin-right: 8px;
    transform: rotate(90deg);
    font-size: 16px;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .image {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-right: 5px;
    }

    img {
      border-radius: 45px;
      width: 45px;
      height: 45px;
      object-fit: cover;
    }

    .avatar {
      border-radius: 100%;
      width: 45px !important;
      height: 45px !important;
      object-fit: cover;
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-top: 15px;

      .little-content {
        display: flex;
        flex-direction: row;

        p {
          color: var(--gray);
          font-size: 10px;
          margin-right: 3px;
          margin-left: 3px;
        }
      }

      h1 {
        color: ${({ theme }) => theme.text};
        font-size: 20px;
        padding: 0px;
        margin-bottom: 2px;
      }
    }
  }

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--gray);
    padding: 0;
    border: none;
    background: none;
  }
`;

export const DataHolder = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 2px;
  border: 0;
  padding: 8px 12px;
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  margin-right: 8px;
  font-family: Work Sans;
  font-style: normal;
`;

export const ShowMore = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-gap: 20px;

  p {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.text};
  }
`;
