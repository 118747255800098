import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 30%;
  padding: 1rem 0.5rem 1.5rem 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.backgroundPrimary};

  #title {
    width: 100%;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  p:first-child {
    margin: 0.5rem 0;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  p {
    margin-left: 0.4rem;
    font-size: 0.8rem;
  }
`;
