import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 5.5vh;
  margin: 0;
  width: 16rem;
  height: 50rem;
  min-width: 16rem;
  display: flex;
  background: ${({ theme }) => theme.backgroundSecondary};
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, -50rem);
  transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  z-index: -1;
  border-radius: 0 0 0.5rem 0.5rem;

  &.active {
    transform: translate(0, 0rem) !important;
    box-shadow: 0px 3px 6px ${({ theme }) => theme.boxShadow};
  }

  h2 {
    color: ${({ theme }) => theme.text};
    font-size: 1rem;
    letter-spacing: 0.9px;
    font-weight: 600;
  }
`;

export const Body = styled.div`
  padding: 1.6875rem 0.625rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    align-items: center;
    margin-top: 0.625rem;

    a {
      font-family: 'Open Sans', sans-serif;
      letter-spacing: 0.84px;
      display: flex;
      color: var(--gray-text);
      font-size: 0.875rem;
      text-decoration: none;
      flex: 1;
      min-height: 20px;
      font-weight: 600;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: var(--red-primary);
        margin-right: 0.5rem;

        svg {
          color: var(--white);
          font-size: 1rem;
        }
      }

      &:hover {
        color: ${({ theme }) => theme.text};

        div {
          box-shadow: 0px 0px 6px var(--red-primary);
        }
      }
    }
  }
`;

export const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0;
  min-height: 55%;
  max-height: 75%;
`;

export const Search = styled.div`
  min-height: 29px;
  display: flex;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
  border-radius: 8px;
  flex-direction: row;
  border: 1px solid var(--gray-text);
  input {
    border: 0;
    flex: 1;
    color: ${({ theme }) => theme.text};
    opacity: 1;
    background: transparent 0% 0%;
    font-size: 0.875rem;
    margin-left: 1.25rem;
    font-family: 'Open Sans', sans-serif;
    ::placeholder {
      color: var(--gray-text);
    }
  }
  button {
    border: 0;
    font-size: 0.75rem;
    font-style: normal;
    font-family: Work Sans;
    background: transparent 0% 0%;
    margin-right: 1.25rem;
  }
  svg {
    width: 1.25rem;
    color: var(--gray-text);
    margin-top: 2px;
  }
`;

export const Clients = styled.div`
  display: flex;
  overflow-y: overlay;
  overflow-x: hidden;
  flex-direction: column;
  ::-webkit-scrollbar {
    height: 4px;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundPrimary};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gray);
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.text};
  }
`;
