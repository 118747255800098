import { Dispatch, FC, SetStateAction } from 'react';

import * as S from './styles';

type ObjectProps = {
  name: string;
  content: string;
  required: boolean;
  users_id: number[];
};

interface TextEditorProps {
  object: ObjectProps;
  setObject: Dispatch<SetStateAction<ObjectProps>>;
}

const TextEditor: FC<TextEditorProps> = ({ setObject, object }) => {
  return (
    <S.Container
      onInput={e =>
        setObject({ ...object, content: e.currentTarget.innerHTML })
      }
      contentEditable="true"
    />
  );
};

export default TextEditor;
