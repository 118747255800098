import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { format } from 'date-fns';

import { Props } from 'react-apexcharts';
import * as S from './styles';
import getDefaultOptions from '../../../../../../styles/defaultChartStyles';
import { DashData } from '../../../../types';

interface NewFaturamentoChart {
  dashData: DashData;
}

interface DataSetProps {
  name: string;
  data: number[];
}

const FaturamentoChart: React.FC<NewFaturamentoChart> = ({ dashData }) => {
  const data = useMemo(() => {
    const datasets: DataSetProps[] = [];

    dashData.revGrossProfitAvgTicket.forEach(set => {
      datasets.push({
        name: set.name,
        data: set.values,
      });
    });

    const finalDates: string[] = [];
    dashData.revGrossProfitAvgTicket[0].dates.forEach(date => {
      finalDates.push(format(new Date(date), 'dd/MM'));
    });

    return {
      labels: finalDates,
      datasets,
    };
  }, [dashData.revGrossProfitAvgTicket]);

  const defaultOptions = getDefaultOptions({
    tooltipOption: true,
    solid: true,
    stacked: true,
  });

  const options: Props['ApexOptions'] = {
    ...defaultOptions,
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      categories: data.labels,
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
  };

  return (
    <S.Container>
      <h1>Faturamento, Lucro Bruto e Ticket Médio</h1>

      <Chart options={options} series={data.datasets} type="bar" width="100%" />
    </S.Container>
  );
};

export default FaturamentoChart;
