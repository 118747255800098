import { useCallback } from 'react';
import { differenceInHours } from 'date-fns';

interface useProgressI {
  progress: (createdAt: string, dueDate: string) => number;
  colors: (
    dueDate: string,
    finishedAt: string | null,
    progress?: number
  ) => {
    dueDateColor: string;
    progressColor: string;
  };
}
const useProgress = (): useProgressI => {
  const progress = useCallback((createdAt: string, dueDate: string): number => {
    if (!dueDate) {
      return 0;
    }

    const startDate = new Date(createdAt);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(dueDate);
    endDate.setHours(0, 0, 0, 0);

    if (startDate.getTime() === endDate.getTime()) {
      // If a task is due in the same day it's was created, we set the percentage
      // as 85 so the bar doesn't get full and the user doesn't think it's already
      // late.
      return 85;
    }

    const todayDate = new Date();
    todayDate.setHours(23, 59, 59, 999);

    const MILI_SEC_IN_DAY = 24 * 60 * 60 * 1000;

    const total = Math.round(
      (endDate.getTime() - startDate.getTime()) / MILI_SEC_IN_DAY
    );
    const current = Math.round(
      (todayDate.getTime() - startDate.getTime()) / MILI_SEC_IN_DAY
    );
    let percentage = Math.round((current / total) * 100);

    if (percentage > 100) {
      percentage = 100;
    }

    return percentage;
  }, []);

  const colors = useCallback(
    (
      dueDate: string,
      finishedAt: string | null,
      progressToSelectColor?: number
    ) => {
      if (!dueDate) {
        return { dueDateColor: 'var(--gray)', progressColor: 'var(--gray)' };
      }

      const today = new Date();
      const taskDueDate = new Date(dueDate);
      taskDueDate.setHours(23, 59, 59, 999);
      const diffInHours = differenceInHours(taskDueDate, today);
      const todayTimezoned = new Date(
        today.valueOf() - today.getTimezoneOffset() * 60000
      );

      const progressBar = {
        dueDateColor: 'var(--cyan-primary)',
        progressColor: 'var(--cyan-primary)',
      };

      if (finishedAt || progressToSelectColor === 100) {
        progressBar.dueDateColor = 'var(--green-primary)';
        progressBar.progressColor = 'var(--green-primary)';
      } else if (new Date(taskDueDate) < new Date(todayTimezoned)) {
        progressBar.dueDateColor = 'var(--red-primary)';
        progressBar.progressColor = 'var(--red-primary)';
      } else if (diffInHours < 72) {
        progressBar.dueDateColor = 'var(--yellow-primary)';
        progressBar.progressColor = 'var(--yellow-primary)';
      }

      return progressBar;
    },
    []
  );

  return { progress, colors };
};

export default useProgress;
