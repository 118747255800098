import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 1rem;
  background: ${({ theme }) => theme.backgroundSecondary};

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    background: ${({ theme }) => theme.backgroundSecondary};

    p {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .project {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 1rem;

    input {
      font-size: 1rem;
      font-weight: normal;
      width: 100% !important;
      background: ${({ theme }) => theme.backgroundTerciary};
      height: 100%;
      padding: 0.5rem;
    }
  }

  .icon {
    background: ${({ theme }) => theme.backgroundTerciary};
    color: var(--green-primary);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 1rem;
  }
`;
