import styled from 'styled-components';

export const Container = styled.div`
  label {
    cursor: pointer;

    :hover {
      opacity: 0.7;
    }

    .avatar {
      display: flex;
      border: 2px solid var(--red-primary);
      border-radius: 6px;
      max-width: 140px;
      max-height: 140px;
      width: auto;
      height: auto;
    }

    input {
      display: none;
    }
  }
`;
