import styled from 'styled-components';

import { TextInputProps } from '../types';

interface Props {
  props: TextInputProps;
}

export const Container = styled.div<Props>`
  position: relative;

  input {
    width: ${({ props }) => props.width || '100%'};
    height: ${({ props }) => props.height || ''};
    font: ${({ props }) => props.font || ''};
    color: ${({ theme }) => theme.text} !important;
    background: ${({ theme, props }) =>
      props.background || theme.backgroundTerciary};

    flex: 1;
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0.6rem;
    border-radius: 8px;

    ::placeholder {
      opacity: 70%;
    }
  }

  :focus-within::after {
    width: calc(100% - 2.5rem);
    height: 2px;
    content: '';
    background: var(--red-primary);
    position: absolute;
    left: 1.2rem;
    right: 1.2rem;
    bottom: 0;
  }
`;
