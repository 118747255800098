import React from 'react';

import OldCheckin from './OldCheckin';

import CustomLoading from '../../../../../../components/Loadings/CustomLoading';
import PopUp from '../../../../../../components/PopUp';

interface CheckinList {
  id: number;
  created_at?: Date;
}

interface CheckinListProps {
  closePopUp: React.Dispatch<React.SetStateAction<boolean>>;
  checkinList: CheckinList[];
}

const CheckinsList: React.FC<CheckinListProps> = ({
  checkinList,
  closePopUp,
}) => {
  return (
    <PopUp header="Checkins Antigos" closePopUp={closePopUp}>
      {checkinList.length < 1 && <CustomLoading />}
      {checkinList.map(oldCheckin => {
        return <OldCheckin key={oldCheckin.id} oldCheckin={oldCheckin} />;
      })}
    </PopUp>
  );
};

export default CheckinsList;
