import styled from 'styled-components';

interface NumberHolder {
  background: string;
}

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;

  .title {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 1.2rem;
  }

  main {
    display: flex;
  }

  .cards {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
    padding-right: 2rem;
    margin-right: 1rem;
    overflow-y: auto;

    @media (min-width: 1600px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
`;

export const RightBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  width: 10vw;

  .numberColumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 6rem;
    width: 100%;
    margin-bottom: 0.2rem;
    margin-top: 0.2rem;
  }

  .numberColumn .warn {
    padding-top: 2rem;
    margin-top: 1rem;
    font-size: 0.7rem;
    white-space: nowrap;
    transform-origin: 0 0;
    transform: rotate(270deg);
  }
`;

export const NumberHolder = styled.div<NumberHolder>`
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  border-radius: 8px;
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
`;
