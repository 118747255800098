import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 40px;
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 0.5rem;
`;
export const Votes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.backgroundPrimary};
  width: 100px;
  height: 100px;
  border-radius: 6px;
  color: ${({ theme }) => theme.text};
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 50%;
`;
export const FirstLine = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text};
  align-items: center;
`;
export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding: 0 10px;
  height: 60%;
  border-radius: 6px;

  background: ${props => {
    if (props.status === 'done') {
      return 'var(--green-primary)';
    }
    if (props.status === 'reproved') {
      return 'var(--red-primary)';
    }
    if (props.status === 'backlog') {
      return 'var(--gray)';
    }
    if (props.status === 'dev') {
      return 'var(--cyan-primary)';
    }
    if (props.status === 'review') {
      return 'var(--yellow-primary';
    }
    return 'var(--gray-text)';
  }};
  color: ${props => (props.status === 'open' ? 'var(--gray)' : 'var(--white)')};
`;
export const SecondLine = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text};
  max-width: 100%;

  p {
    padding: 0 10px;
  }
`;
export const ThirdLine = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text};
  margin-left: 10px;
  margin-top: 8px;
  * {
    margin-right: 8px;
  }
  svg {
    color: ${({ theme }) => theme.text};
    transition: 0.5s all;
    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.text};
    }
  }
`;
export const Replies = styled.div`
  display: flex;
  margin-top: 8px;
  p {
    color: ${({ theme }) => theme.text};
  }
  .avatar {
    margin-right: -8px;
    border: 1px solid var(--black);
    border-radius: 50%;
    padding: 0px;
    width: 36px !important;
    height: 36px !important;
    font-size: 36px;
    :last-of-type {
      margin-right: 8px;
    }
    img {
      max-width: none !important;
    }
  }
`;
