import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  button {
    border: none;
    background: none;
    color: ${({ theme }) => theme.text};
  }
`;

export const ReturnButton = styled.button`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  p {
    margin-left: 0.3rem;
    font-size: 1.2rem;
  }
`;
export const Header = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text};
  margin-top: 1rem;

  span {
    color: ${({ theme }) => theme.text};
  }

  #group {
    width: 8%;
    display: flex;
    align-items: center;

    p {
      font-size: 0.8rem;
      margin-left: 0.5rem;
    }
  }

  #name {
    width: 26%;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #add-new {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    button {
      display: flex;
      margin: 0;
      p {
        margin-left: 0.5rem;
      }
    }
  }
  @media (max-width: 1890px) {
    #group {
      width: 25%;
      p {
        font-size: 0.7rem;
      }
    }
    p {
      font-size: 0.7rem;
    }
  }
`;
