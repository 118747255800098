import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import apiV2 from '../../../../services/apiV2';

import PremiseForm from './PremiseForm';
import PremiseCard from './PremiseCard';

import * as S from './styles';
import { CardData } from './types';

interface PropsPremisesAndRisks {
  draftId: number;
  oldData: CardData[];
}

const PremisesAndRisks: React.FC<PropsPremisesAndRisks> = ({
  draftId,
  oldData,
}) => {
  const [cardsData, setCardsData] = useState<CardData[]>(oldData);

  useEffect(() => {
    setCardsData(oldData);
  }, [oldData]);

  const handleSubmit = useCallback(
    async (e, newCard) => {
      e.preventDefault();
      toast.warn('Adicionando');
      await apiV2
        .post('premise', {
          premise: newCard,
          checkinDraftId: draftId,
        })
        .then(res => {
          setCardsData([...cardsData, res.data]);
          toast.success('Adicionado');
        })
        .catch(() => {
          toast.error('Falha ao adicionar premissa');
        });
    },
    [cardsData, setCardsData, draftId]
  );

  const handleCardDelete = useCallback(
    async id => {
      toast.warn('Deletando');
      await apiV2
        .delete(`premise/delete?premiseId=${id}&checkinDraftId=${draftId}`)
        .then(() => {
          const filteredItems = cardsData.filter(card => card.id !== id);
          setCardsData(filteredItems);
          toast.success('Deletado!');
        })
        .catch(() => {
          toast.error('Falha ao deletar premissa');
        });
    },
    [cardsData, draftId]
  );

  const handleCardEdit = useCallback(
    async (id, newCard) => {
      toast.warn('Atualizando');
      await apiV2
        .put('premise/update', {
          premise: newCard,
          checkinDraftId: draftId,
        })
        .then(res => {
          const itemIndex = cardsData.findIndex(card => card.name === id);
          const reCards = cardsData;
          reCards[itemIndex] = res.data;

          setCardsData([...reCards]);
          toast.success('Atualizado');
        })
        .catch(() => {
          toast.error('Falha ao atualizar premissa');
        });
    },
    [cardsData, draftId]
  );

  return (
    <S.Container>
      <PremiseForm handleSubmit={handleSubmit} />
      <div data-testid="cards" className="cards">
        {cardsData.map(card => {
          return (
            <PremiseCard
              key={card.id}
              cardData={card}
              handleCardDelete={handleCardDelete}
              handleCardEdit={handleCardEdit}
            />
          );
        })}
      </div>
    </S.Container>
  );
};

export default PremisesAndRisks;
