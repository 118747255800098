import styled from 'styled-components';

export const Filters = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p,
  span,
  select,
  button {
    margin: 0 0.5rem;
  }

  p {
    font-size: 0.8rem;
  }

  button {
    width: 6rem;
    :hover {
      border: none;
      background-color: var(--red-primary);
    }
  }

  select,
  button {
    height: 1.5rem;
    border-radius: 0.7rem;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    border: 0.1rem solid var(--gray);
  }
  .select-search {
    width: 350px;
    border: 0.1rem solid var(--gray);
    border-radius: 3rem;
    margin: 0 0.5rem;
  }

  #tags-select.select-search {
    width: 200px;
  }

  .eoKIEi .select-search *,
  .eoKIEi .select-search *::after,
  .eoKIEi .select-search *::before {
    width: 99%;
  }

  .select-search__input {
    font-size: 0.65rem;
    height: 1.7rem;
    border-radius: 3rem;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border: 0.1rem solid var(--gray);
    ::placeholder {
      color: ${({ theme }) => theme.text};
    }
  }
  .select-search__option {
    color: ${({ theme }) => theme.text};
    font-size: 0.8rem;
    margin: 0.2rem;
  }

  .select-search__option.is-selected {
    width: 99%;
    background: var(--red-primary);
  }
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    width: 99%;
    background: var(--red-primary);
    color: var(--white);
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    width: 99%;
    background: var(--red-primary);
  }

  #tags-select .select-search__option.is-selected {
    width: 99%;
    background: var(--red-primary);
  }
  #tags-select .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    width: 99%;
    background: var(--red-primary);
    color: var(--white);
  }

  #tags-select .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    width: 99%;
    background: var(--red-primary);
  }

  @media (max-width: 1700px) {
    margin-top: 1rem;

    .select-search {
      width: 250px;
    }
  }

  @media (max-width: 1290px) {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;

    .select-search {
      width: 200px;
    }
  }

  @media (max-width: 1150px) {
    margin-left: 0;

    .filterOptions {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: baseline;
      width: 100%;
    }

    .select-search {
      margin-left: 0;
      margin-bottom: 0.7rem;
    }

    .checkboxDelete {
      margin: 0.6rem 0;
    }

    .filterButtons {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: row;

      button:first-child {
        margin-left: 0rem;
      }
    }
  }

  @media (max-width: 415px) {
    .filterButtons {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: baseline;

      button {
        margin-bottom: 1rem;
        width: 100%;
        margin-left: 0rem;
      }

      button:last-child {
        margin-bottom: 0rem;
      }
    }
  }
`;
