import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function updateContract(contract: Contract): Promise<boolean> {
  try {
    const newContract = {
      id: contract.id,
      social_name: contract.social_name,
      first_payment_date: contract.first_payment_date,
      cnpj_cpf: contract.cnpj_cpf,
      payment_date: contract.payment_date,
      life_time: contract.life_time,
      fee: contract.fee * 100,
      partners: contract.partners,
      addresses: contract.addresses,
      contract: contract.contract,
      unit: contract.unit,
      signers: contract.signers,
      unit_id: contract.unit_id,
    };

    await apiV2.put(`/contracts/`, newContract);

    return true;
  } catch (err) {
    toast.error(
      `Ocorreu algum problema ao editar este contato, atualize a página e tente novamente 😕`
    );

    return false;
  }
}
