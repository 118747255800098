import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  input {
    width: 100%;
    color: ${({ theme }) => theme.text} !important;
    background: ${({ theme }) => theme.backgroundTerciary};

    flex: 1;
    border: 0;
    outline: 0;
    padding: 0.6rem;
    border-radius: 8px;

    ::placeholder {
      opacity: 70%;
    }
  }

  ul {
    background: ${({ theme }) => theme.backgroundSecondary};
    box-shadow: 0px 0px 12px 3px ${({ theme }) => theme.lowOpacity};
    display: none;
    width: 100%;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    overflow-y: auto;
    max-height: 20rem;
    margin-top: 0.5rem;
    border-radius: 8px;
  }

  ul button {
    background: ${({ theme }) => theme.backgroundSecondary};
    font: 400 0.8rem Work Sans;
    padding: 0.4rem;
    color: ${({ theme }) => theme.text};

    margin-bottom: 0.2rem;
    border: 0;

    cursor: pointer;
    transition: 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      color: var(--white);
      background: var(--red-primary);
    }
  }

  ul button:last-child {
    margin-bottom: 0;
  }
`;
