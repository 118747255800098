import { isAfter } from 'date-fns';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import apiV2 from '../../services/apiV2';

type ImportPlaybookContextData = {
  globalPlaybookProjects: IPlaybookProject[];
  setGlobalPlaybookProjects: Dispatch<SetStateAction<IPlaybookProject[]>>;
  initialUsers: IUserId[];

  setPlaybookUpdatedAt: Dispatch<SetStateAction<Date>>;

  addNewProject: (playbookId: number) => void;
  updateProjectName: (projectId: number, newName: string) => void;
  updateProjectDescription: (projectId: number, newDescription: string) => void;
  deleteProject: (projectId: number) => void;
  addNewGroup: (projectId: number) => void;
  updateGroupName: (
    projectId: number,
    groupId: number,
    newName: string
  ) => void;
  updateGroupDescription: (
    projectId: number,
    groupId: number,
    newDescription: string
  ) => void;
  deleteGroup: (projectId: number, groupId: number) => void;
  addNewPhase: (projectId: number, groupId: number) => void;
  updatePhaseName: (
    projectId: number,
    groupId: number,
    phaseId: number,
    newDescription: string
  ) => void;
  deletePhase: (projectId: number, groupId: number, phaseId: number) => void;
  addNewPhaseChecklistItem: (
    projectId: number,
    groupId: number,
    phaseId: number
  ) => void;
  updatePhaseChecklistItemName: (
    projectId: number,
    groupId: number,
    phaseId: number,
    phaseChecklistItemId: number,
    newName: string
  ) => void;
  deletePhaseChecklistItem: (
    projectId: number,
    groupId: number,
    phaseId: number,
    phaseChecklistItemId: number
  ) => void;

  addNewTask: (projectId: number, groupId: number) => void;
  updateTaskName: (
    projectId: number,
    groupId: number,
    taskId: number,
    newName: string
  ) => void;
  updateTaskDescription: (
    projectId: number,
    groupId: number,
    taskId: number,
    newDescription: string
  ) => void;
  deleteTask: (projectId: number, groupId: number, taskId: number) => void;
  addUsersInTask: (
    users: IUserId[],
    projectId: number,
    groupId: number,
    taskId: number
  ) => void;
  addUserToProjectByTask: (
    userId: IUserId,
    projectId: number,
    groupId: number
  ) => void;
  deleteUserToProjectByTask: (
    userId: IUserId,
    projectId: number,
    groupId: number,
    taskId: number
  ) => void;
  addNewIndividualTask: (projectId: number) => void;
  updateIndividualTaskName: (
    projectId: number,
    individualTaskId: number,
    newName: string
  ) => void;
  updateIndividualTaskDescription: (
    projectId: number,
    individualTaskId: number,
    newDescription: string
  ) => void;
  deleteIndividualTask: (projectId: number, individualTaskId: number) => void;

  createInitialUsers: (users: IUserId[]) => void;
  addUsersInProjects: (users: IUserId[]) => void;
  addUsersInGroups: (users: IUserId[]) => void;
  addUserToProjectByIndividualTask: (
    userId: IUserId,
    projectId: number
  ) => void;
  deleteUserToProjectByIndividualTask: (
    userId: IUserId,
    projectId: number,
    individualTaskId: number
  ) => void;
  addUserInPhase: (
    user: IUserId,
    projectId: number,
    groupId: number,
    phaseId: number
  ) => void;
  deleteUserInPhase: (
    user: IUserId,
    projectId: number,
    groupId: number,
    phaseId: number
  ) => void;
  addUsersInIndividualTask: (
    users: IUserId[],
    projectId: number,
    individualTaskId: number
  ) => void;
  updateDueDateInIndividualTask: (
    newDueDate: number,
    projectId: number,
    individualTaskId: number
  ) => void;
  updateDueDateInTask: (
    newDueDate: number,
    projectId: number,
    groupId: number,
    taskId: number
  ) => void;
  getMinimunTasksDueDate: (projectId: number, groupId: number) => number;
  getMinimunIndividualTasksDueDate: (projectId: number) => number;
  getProjectDueDate: (projectId: number) => number;

  createGlobalPlaybookProjects: (
    playbookId: number,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => void;
  importPlaybook: (
    clientId: number,
    handleClose: () => void,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => void;
};

const ImportPlaybookContext = createContext({} as ImportPlaybookContextData);

type ImportPlaybookContextProviderProps = {
  children: ReactNode;
};

const ImportPlaybookProvider: React.FC<ImportPlaybookContextProviderProps> = ({
  children,
}: ImportPlaybookContextProviderProps) => {
  const [globalPlaybookProjects, setGlobalPlaybookProjects] = useState<
    IPlaybookProject[]
  >([]);

  const [initialUsers, setInitialUsers] = useState<IUserId[]>([]);
  const [playbookUpdatedAt, setPlaybookUpdatedAt] = useState<Date>(new Date());

  const createInitialUsers = (users: IUserId[]) => {
    setInitialUsers([...users]);
  };

  const createGlobalPlaybookProjects = async (
    playbookId: number,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => {
    setLoading(true);

    const getProjects = await apiV2.get(
      `playbooks/projects/playbook?playbookId=${playbookId}`
    );

    const projectsT: IPlaybookProject[] = getProjects.data;

    const newProjects = await Promise.all(
      projectsT.map(async project => {
        const newProject = project;

        const responseGroups = await apiV2.get(
          `/playbooks/groups/projects?projectId=${newProject.id}`
        );

        const responseIndividualTasks = await apiV2.get(
          `playbooks/tasks/projects?projectId=${project.id}`
        );

        const groups: IPlaybookGroup[] = responseGroups.data;

        const individualTasks: IPlaybookTask[] = responseIndividualTasks.data;

        const individualTasksWithSubtasks = await Promise.all(
          individualTasks.map(async individualTask => {
            const newIndividualTask = individualTask;

            const responseSubtasks = await apiV2.get(
              `playbooks/subtasks/tasks?taskId=${newIndividualTask.id}`
            );

            const allSubtasks: IPlaybookSubtask[] = responseSubtasks.data;

            const removeNullSubtasks = allSubtasks.map(subtask => {
              const newSubtask = subtask;

              if (newSubtask.name === '') {
                newSubtask.name = 'Está subtarefa da tarefa está sem nome';
              }

              return newSubtask;
            });

            newIndividualTask.subtasks = removeNullSubtasks;

            return newIndividualTask;
          })
        );

        const newGroups = await Promise.all(
          groups.map(async group => {
            const newGroup = group;

            const responsePhases = await apiV2.get(
              `playbooks/phases/groups?groupId=${newGroup.id}`
            );

            const updatedTimeOfGroup = new Date(playbookUpdatedAt);

            const oldSystemPlaybook = isAfter(
              updatedTimeOfGroup,
              new Date(2021, 2, 15)
            );

            let allPhases: IPlaybookPhase[] = responsePhases.data;

            switch (allPhases.length) {
              case 0:
                {
                  const initialPhase = {
                    id: Math.floor(Math.random() * 65536),
                    name: 'To Do',
                    desc: 'Fase inicial',
                    status: 'aberto',
                    playbook_group_id: newGroup.id,
                    icon_name: 'RiSeedlingLine',
                    order: 1,
                    tasks: [],
                    phase_checklist_items: [],
                    users_ids: [],
                  };

                  const lastPhase = {
                    id: Math.floor(Math.random() * 65536),
                    name: 'Done',
                    desc: 'Fase final',
                    status: 'aberto',
                    playbook_group_id: newGroup.id,
                    icon_name: 'RiCheckLine',
                    order: 2,
                    tasks: [],
                    phase_checklist_items: [],
                    users_ids: [],
                  };
                  const groupWithNewPhases = [initialPhase, lastPhase];

                  allPhases = groupWithNewPhases;
                }

                break;
              case 1:
                {
                  allPhases[0].order = 2;

                  const initialPhase = {
                    id: Math.floor(Math.random() * 65536),
                    name: 'To Do',
                    desc: 'Fase inicial',
                    status: 'aberto',
                    playbook_group_id: newGroup.id,
                    icon_name: 'RiSeedlingLine',
                    order: 1,
                    tasks: [],
                    phase_checklist_items: [],
                    users_ids: [],
                  };

                  const lastPhase = {
                    id: Math.floor(Math.random() * 65536),
                    name: 'Done',
                    desc: 'Fase final',
                    status: 'aberto',
                    playbook_group_id: newGroup.id,
                    icon_name: 'RiCheckLine',
                    order: 3,
                    tasks: [],
                    phase_checklist_items: [],
                    users_ids: [],
                  };

                  const groupWithNewPhases = [
                    initialPhase,
                    ...allPhases,
                    lastPhase,
                  ];

                  allPhases = groupWithNewPhases;
                }

                break;
              default: {
                if (!oldSystemPlaybook) {
                  const orderedPhases = allPhases.sort((a, b) =>
                    a.id > b.id ? 1 : -1
                  );

                  const initialPhase = {
                    id: Math.floor(Math.random() * 65536),
                    name: 'To Do',
                    desc: 'Fase inicial',
                    status: 'aberto',
                    playbook_group_id: newGroup.id,
                    icon_name: 'RiSeedlingLine',
                    order: 1,
                    tasks: [],
                    phase_checklist_items: [],
                    users_ids: [],
                  };

                  const lastPhase = {
                    id: Math.floor(Math.random() * 65536),
                    name: 'Done',
                    desc: 'Fase final',
                    status: 'aberto',
                    playbook_group_id: newGroup.id,
                    icon_name: 'RiCheckLine',
                    order: orderedPhases.length + 2,
                    tasks: [],
                    phase_checklist_items: [],
                    users_ids: [],
                  };

                  const phasesWithOrder = orderedPhases.map((phase, index) => {
                    const phaseWithOrder = phase;
                    phaseWithOrder.order = index + 2;
                    return phaseWithOrder;
                  });

                  const groupWithNewPhases = [
                    initialPhase,
                    ...phasesWithOrder,
                    lastPhase,
                  ];
                  allPhases = groupWithNewPhases;
                } else {
                  const orderedPhases = allPhases.sort((a, b) =>
                    a.id > b.id ? 1 : -1
                  );

                  const phasesWithOrder = orderedPhases.map((phase, index) => {
                    const phaseWithOrder = phase;
                    phaseWithOrder.order = index + 1;
                    return phaseWithOrder;
                  });

                  const groupWithNewPhases = [...phasesWithOrder];
                  allPhases = groupWithNewPhases;
                }
              }
            }

            const newPhases = await Promise.all(
              allPhases.map(async phase => {
                const newPhase = phase;

                const responseChecklistItem = await apiV2.get(
                  `playbooks/subtasks/phases?phaseId=${newPhase.id}`
                );

                const allChecklistItens: IPhaseChecklistItem[] =
                  responseChecklistItem.data;

                const removeNullChecklistItens = allChecklistItens.map(
                  checklistItem => {
                    const newChecklistItem = checklistItem;

                    if (newChecklistItem.name === '') {
                      newChecklistItem.name =
                        'Está tarefa da fase está sem nome';
                    }
                    return newChecklistItem;
                  }
                );

                newPhase.phase_checklist_items = removeNullChecklistItens;

                const responseTasks = await apiV2.get(
                  `/playbooks/tasks/phases?phaseId=${newPhase.id}`
                );

                const tasks: IPlaybookTask[] = responseTasks.data;

                const tasksWithSubtasks = await Promise.all(
                  tasks.map(async task => {
                    const newTask = task;

                    const responseSubtasks = await apiV2.get(
                      `/playbooks/subtasks/tasks?taskId=${task.id}`
                    );

                    const allSubtasks: IPlaybookSubtask[] =
                      responseSubtasks.data;

                    const removeNullSubtasks = allSubtasks.map(subtask => {
                      const newSubtask = subtask;

                      if (newSubtask.name === '') {
                        newSubtask.name =
                          'Está subtarefa da tarefa está sem nome';
                      }

                      return newSubtask;
                    });

                    newTask.subtasks = removeNullSubtasks;

                    return newTask;
                  })
                );

                newPhase.tasks = tasksWithSubtasks;

                return newPhase;
              })
            );

            const allTasks = newPhases.flatMap(
              (phase: IPlaybookPhase) => phase.tasks || []
            );

            const allPhasesWithTasksInFirstPhase = allPhases.map((phase, i) => {
              const newPhase = phase;
              newPhase.tasks = [];

              if (i === 0) {
                newPhase.tasks = allTasks;
              }
              if (i !== 0) {
                newPhase.tasks = [];
              }

              return newPhase;
            });

            newGroup.phases = allPhasesWithTasksInFirstPhase;

            return newGroup;
          })
        );

        newProject.groups = newGroups;

        newProject.tasks = individualTasksWithSubtasks;

        return newProject;
      })
    );

    setGlobalPlaybookProjects(newProjects);

    setLoading(false);
  };

  const addNewProject = (playbookId: number) => {
    const newProject = {
      id: Math.floor(Math.random() * 65536),
      name: 'Novo projeto',
      desc: 'Adicionar descrição',
      playbook_id: playbookId,
      groups: [],
      tasks: [],
      order: globalPlaybookProjects.length + 1,
      users_ids: initialUsers,
    };

    const newProjects = [...globalPlaybookProjects, newProject];

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updateProjectName = (projectId: number, newName: string) => {
    const newProjects = [...globalPlaybookProjects];

    const projectWithNameChanged = newProjects.filter(filteredProject => {
      const projectUpdated = filteredProject;
      if (filteredProject.id === projectId) {
        projectUpdated.name = newName;
      }
      return projectUpdated;
    });

    setGlobalPlaybookProjects(projectWithNameChanged);
  };

  const updateProjectDescription = (
    projectId: number,
    newDescription: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const projectWithDescriptionChanged = newProjects.filter(
      filteredProject => {
        const projectUpdated = filteredProject;
        if (projectUpdated.id === projectId) {
          projectUpdated.desc = newDescription;
        }
        return projectUpdated;
      }
    );
    setGlobalPlaybookProjects(projectWithDescriptionChanged);
  };

  const deleteProject = (projectId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject) return;

    const projectsWithoutDeletedProject = newProjects.filter(project => {
      return project.id !== selectedProject.id;
    });

    toast.success(`O projeto '${selectedProject.name}' foi deletado!`);

    setGlobalPlaybookProjects([...projectsWithoutDeletedProject]);
  };

  const addUsersInProjects = (users: IUserId[]) => {
    const newProjects = [...globalPlaybookProjects];

    newProjects.map(project => {
      const projectWithNewUsers = project;
      projectWithNewUsers.users_ids = users;

      return projectWithNewUsers;
    });
    setGlobalPlaybookProjects([...newProjects]);
  };

  const deleteUserToProjectByGroup = (
    userId: IUserId,
    projectId: number,
    groupId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(
      group => group.id === groupId
    );

    if (!selectedGroup) return;

    if (!selectedGroup.users_ids) {
      selectedGroup.users_ids = [];
    }

    const selectedGroupUsers = [...selectedGroup.users_ids];

    if (!selectedGroupUsers) return;

    const idToRemove = selectedGroupUsers.indexOf(userId);

    if (idToRemove !== -1) {
      selectedGroupUsers.splice(idToRemove, 1);
    }

    selectedGroup.users_ids = selectedGroupUsers;

    const userExistsInAnotherGroup = selectedProject.groups.map(group => {
      if (group.id === groupId) {
        return false;
      }
      if (!group.users_ids) {
        return false;
      }

      return group.users_ids.includes(userId);
    });

    const userExistsInGroups = userExistsInAnotherGroup.includes(true);

    if (!selectedProject.tasks) return;

    const userExistsInAnotherIndividualTask = selectedProject.tasks.map(
      individualTask => {
        if (!individualTask.users_ids) {
          return false;
        }

        return individualTask.users_ids.includes(userId);
      }
    );

    const userExistsInIndividualTask = userExistsInAnotherIndividualTask.includes(
      true
    );

    if (!selectedProject.users_ids) return;

    const projectUsers = [...selectedProject.users_ids];

    if (!userExistsInGroups && !userExistsInIndividualTask) {
      const idToRemoveInProjects = projectUsers.indexOf(userId);

      if (idToRemoveInProjects !== -1) {
        projectUsers.splice(idToRemoveInProjects, 1);
      }
    }
    selectedProject.users_ids = projectUsers;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const deleteUserToProjectByIndividualTask = (
    userId: IUserId,
    projectId: number,
    individualTaskId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];
    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (
      !selectedProject ||
      !selectedProject.users_ids ||
      !selectedProject.tasks
    )
      return;

    const allIndividualTasks = [...selectedProject.tasks];

    const userExistsInAnotherIndividualTask = allIndividualTasks.map(
      individualTask => {
        if (individualTask.id === individualTaskId) {
          return false;
        }

        if (!individualTask.users_ids) {
          return false;
        }

        return individualTask.users_ids.includes(userId);
      }
    );
    const userExistIndividualTask = userExistsInAnotherIndividualTask?.includes(
      true
    );

    if (!selectedProject.groups) return;

    const allGroups = [...selectedProject.groups];

    const userExistsInSomeGroup = allGroups.map(group => {
      if (!group.users_ids) {
        return false;
      }

      return group.users_ids.includes(userId);
    });

    const userExistGroup = userExistsInSomeGroup.includes(true);

    const allProjectUsers = [...selectedProject.users_ids];

    if (!userExistIndividualTask && !userExistGroup) {
      const idToRemove = allProjectUsers.indexOf(userId);

      if (idToRemove !== -1) {
        allProjectUsers.splice(idToRemove, 1);
      }
    }

    selectedProject.users_ids = allProjectUsers;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addUserToProjectByIndividualTask = (
    userId: IUserId,
    projectId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject) return;

    if (!selectedProject.users_ids) {
      selectedProject.users_ids = [];
    }

    const allProjectUsers = [...selectedProject.users_ids];

    const userExists = allProjectUsers.includes(userId);
    if (!userExists) {
      allProjectUsers.push(userId);
    }

    selectedProject.users_ids = allProjectUsers;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const deleteUserToProjectByTask = (
    userId: IUserId,
    projectId: number,
    groupId: number,
    taskId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(
      group => group.id === groupId
    );

    if (!selectedGroup) return;

    if (!selectedGroup.phases) return;

    const firstPhase = selectedGroup.phases[0];

    if (!firstPhase.tasks) return;

    const allTasks = [...firstPhase.tasks];

    if (!allTasks) return;

    const userExistsInAnotherTask = allTasks.map(task => {
      if (task.id === taskId) {
        return false;
      }
      if (!task.users_ids) {
        return false;
      }

      return task.users_ids.includes(userId);
    });

    const userExistInTasks = userExistsInAnotherTask.includes(true);

    if (userExistInTasks || !selectedGroup.users_ids) return;

    const selectedGroupUsers = [...selectedGroup.users_ids];

    const idToRemove = selectedGroupUsers.indexOf(userId);

    if (idToRemove !== -1) {
      selectedGroupUsers.splice(idToRemove, 1);
    }

    selectedGroup.users_ids = selectedGroupUsers;

    const userExistsInAnotherGroup = selectedProject.groups.map(group => {
      if (group.id === groupId) {
        return false;
      }
      if (!group.users_ids) {
        return false;
      }

      return group.users_ids.includes(userId);
    });

    const userExistsInGroups = userExistsInAnotherGroup.includes(true);

    let userExistsInIndividualTask = false;

    if (selectedProject.tasks) {
      const userExistsInAnotherIndividualTask = selectedProject.tasks.map(
        individualTask => {
          if (!individualTask.users_ids) {
            return false;
          }

          return individualTask.users_ids.includes(userId);
        }
      );

      userExistsInIndividualTask = userExistsInAnotherIndividualTask.includes(
        true
      );
    }

    if (!selectedProject.users_ids) return;

    const projectUsers = [...selectedProject.users_ids];

    if (!userExistsInGroups && !userExistsInIndividualTask) {
      const idToRemoveInProjects = projectUsers.indexOf(userId);

      if (idToRemoveInProjects !== -1) {
        projectUsers.splice(idToRemoveInProjects, 1);
      }
    }
    selectedProject.users_ids = projectUsers;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addUserToProjectByTask = (
    userId: IUserId,
    projectId: number,
    groupId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const allGroups = [...selectedProject.groups];

    const selectedGroup = allGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup) return;

    if (!selectedProject.users_ids) {
      selectedProject.users_ids = [];
    }

    const selectedProjectUsers = [...selectedProject.users_ids];

    if (!selectedGroup.users_ids) {
      selectedGroup.users_ids = [];
    }

    const selectedGroupUsers = [...selectedGroup.users_ids];

    if (selectedGroupUsers.includes(userId) === false) {
      selectedGroupUsers.push(userId);

      const userExistsInProjects = selectedProjectUsers.includes(userId);

      if (!userExistsInProjects) {
        selectedProjectUsers.push(userId);
      }
    }

    selectedGroup.users_ids = selectedGroupUsers;

    selectedProject.users_ids = selectedProjectUsers;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addNewGroup = (projectId: number) => {
    const randomId = Math.floor(Math.random() * 65536);
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject) return;

    if (!selectedProject.groups) {
      selectedProject.groups = [];
    }
    const oldGroups = [...selectedProject.groups];

    const newGroup = {
      id: randomId,
      name: 'Novo grupo de entrega',
      desc: 'Adicione uma descrição',
      playbook_project_id: projectId,
      phases: [
        {
          id: Math.floor(Math.random() * 65536),
          name: 'To Do',
          desc: 'Fase inicial',
          status: 'aberto',
          playbook_group_id: randomId,
          icon_name: 'RiSeedlingLine',
          order: 1,
          tasks: [],
          phase_checklist_items: [],
          users_ids: [],
        },
        {
          id: Math.floor(Math.random() * 65536),
          name: 'Done',
          desc: 'Fase final',
          status: 'aberto',
          playbook_group_id: randomId,
          icon_name: 'RiCheckLine',
          order: 2,
          tasks: [],
          phase_checklist_items: [],
          users_ids: [],
        },
      ],
      order: oldGroups.length + 1,
      users_ids: initialUsers,
    };

    const projectWithNewGroup = [...oldGroups, newGroup];

    selectedProject.groups = projectWithNewGroup;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updateGroupName = (
    projectId: number,
    groupId: number,
    newName: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup) return;

    selectedGroup.name = newName;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updateGroupDescription = (
    projectId: number,
    groupId: number,
    newDescription: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup) return;

    selectedGroup.desc = newDescription;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const deleteGroup = (projectId: number, groupId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup) return;

    const usersToRemove = selectedGroup.users_ids;

    if (!usersToRemove) return;

    usersToRemove.map(user => {
      return deleteUserToProjectByGroup(user, projectId, groupId);
    });

    const groupsWithoutDeletedGroup = selectedProject.groups.filter(group => {
      return group.id !== groupId;
    });

    toast.success(`O grupo '${selectedGroup.name}' foi deletado!`);

    selectedProject.groups = groupsWithoutDeletedGroup;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addUsersInGroups = (users: IUserId[]) => {
    const newProjects = [...globalPlaybookProjects];

    newProjects.map(project => {
      if (!project.groups) return project;

      project.groups.map(group => {
        const groupWithUsers = group;
        groupWithUsers.users_ids = users;

        return groupWithUsers;
      });
      return project;
    });
    setGlobalPlaybookProjects([...newProjects]);
  };

  const addNewPhase = (projectId: number, groupId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup) return;

    if (!selectedGroup.phases) {
      selectedGroup.phases = [];
    }
    const oldPhases = [...selectedGroup.phases];

    const existFinalPhase = oldPhases.length > 1;

    const newPhaseOrder = oldPhases.length;

    const newPhase = {
      id: Math.floor(Math.random() * 65536),
      name: 'Nova fase',
      desc: 'Adicionar descrição',
      playbook_group_id: groupId,
      icon_name: 'RiStarHalfFill',
      order: newPhaseOrder,
      tasks: [],
      phase_checklist_items: [],
      users_ids: [],
    };

    if (existFinalPhase) {
      oldPhases[oldPhases.length - 1].order = oldPhases.length + 1;
      oldPhases[0].order = 1;
    }

    const groupsWithNewPhase = [...oldPhases, newPhase];

    const groupsWithNewPhaseOrdered = groupsWithNewPhase.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      return 0;
    });

    selectedGroup.phases = groupsWithNewPhaseOrdered;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updatePhaseName = (
    projectId: number,
    groupId: number,
    phaseId: number,
    newName: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase) return;

    selectedPhase.name = newName;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const deletePhase = (projectId: number, groupId: number, phaseId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const phaseToDelete = selectedGroup.phases.find(phase => {
      return phase.id === phaseId;
    });

    if (!phaseToDelete) {
      return;
    }

    if (
      phaseToDelete.order === 1 ||
      phaseToDelete.order >= selectedGroup.phases.length
    ) {
      toast.error('Não é possível deletar a primeira ou a última fase!');
      return;
    }

    const groupsWithoutDeletedPhase = selectedGroup.phases.filter(phase => {
      return phase.id !== phaseId;
    });

    selectedGroup.phases = groupsWithoutDeletedPhase;

    toast.success(`A fase '${phaseToDelete?.name}' foi deletada!`);

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addUserInPhase = (
    user: IUserId,
    projectId: number,
    groupId: number,
    phaseId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const selectedPhase = selectedGroup.phases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase) return;

    if (selectedPhase.users_ids) {
      selectedPhase.users_ids.push(user);
    } else {
      selectedPhase.users_ids = [];
      selectedPhase.users_ids.push(user);
    }

    setGlobalPlaybookProjects([...newProjects]);
  };

  const deleteUserInPhase = (
    user: IUserId,
    projectId: number,
    groupId: number,
    phaseId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const selectedPhase = selectedGroup.phases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase || !selectedPhase.users_ids) return;

    const idToRemove = selectedPhase.users_ids.indexOf(user);
    if (idToRemove !== -1) {
      selectedPhase.users_ids.splice(idToRemove, 1);
    }

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addNewPhaseChecklistItem = (
    projectId: number,
    groupId: number,
    phaseId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase) return;

    if (!selectedPhase.phase_checklist_items) {
      selectedPhase.phase_checklist_items = [];
    }

    const oldPhaseChecklistItems = [...selectedPhase.phase_checklist_items];

    const newPhaseChecklistItem = {
      id: Math.floor(Math.random() * 99999) + 1,
      name: 'Novo checklist',
      playbook_phase_id: phaseId,
      is_required: false,
    };

    const phasesWithNewChecklistItems = [
      ...oldPhaseChecklistItems,
      newPhaseChecklistItem,
    ];

    selectedPhase.phase_checklist_items = phasesWithNewChecklistItems;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updatePhaseChecklistItemName = (
    projectId: number,
    groupId: number,
    phaseId: number,
    phaseChecklistItemId: number,
    newName: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase || !selectedPhase.phase_checklist_items) return;

    const oldPhaseChecklistItems = [...selectedPhase.phase_checklist_items];

    const selectedPhaseChecklistItem = oldPhaseChecklistItems.find(
      phaseChecklistItem => {
        return phaseChecklistItem.id === phaseChecklistItemId;
      }
    );

    if (!selectedPhaseChecklistItem) return;

    selectedPhaseChecklistItem.name = newName;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const deletePhaseChecklistItem = (
    projectId: number,
    groupId: number,
    phaseId: number,
    phaseChecklistItemId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase || !selectedPhase.phase_checklist_items) return;

    const oldPhaseChecklistItems = [...selectedPhase.phase_checklist_items];

    const removedPhaseChecklistItemIndex = oldPhaseChecklistItems.findIndex(
      selectedPhaseChecklistItem =>
        selectedPhaseChecklistItem.id === phaseChecklistItemId
    );

    if (removedPhaseChecklistItemIndex > -1) {
      oldPhaseChecklistItems.splice(removedPhaseChecklistItemIndex, 1);
    }

    selectedPhase.phase_checklist_items = oldPhaseChecklistItems;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addNewTask = (projectId: number, groupId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase) return;

    if (!selectedPhase.tasks) {
      selectedPhase.tasks = [];
    }
    const oldTasks = [...selectedPhase.tasks];

    const newTask = {
      id: Math.floor(Math.random() * 65536),
      name: 'Nova task',
      desc: 'Adicionar descrição',
      due_date: 0,
      initial_due_date: 0,
      days_repeat: 0,
      checklist: false,
      playbook_phase_id: firstPhaseId,
      order: oldTasks.length + 1,
      subtasks: [],
      users_ids: [],
    };

    const phasesWithNewTask = [...oldTasks, newTask];

    selectedPhase.tasks = phasesWithNewTask;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updateTaskName = (
    projectId: number,
    groupId: number,
    taskId: number,
    newName: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase || !selectedPhase.tasks) return;

    const oldTasks = [...selectedPhase.tasks];

    const selectedTask = oldTasks.find(task => {
      return task.id === taskId;
    });

    if (!selectedTask) return;

    selectedTask.name = newName;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updateTaskDescription = (
    projectId: number,
    groupId: number,
    taskId: number,
    newDescription: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase || !selectedPhase.tasks) return;

    const oldTasks = [...selectedPhase.tasks];

    const selectedTask = oldTasks.find(task => {
      return task.id === taskId;
    });

    if (!selectedTask) return;

    selectedTask.desc = newDescription;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const deleteTask = (projectId: number, groupId: number, taskId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase || !selectedPhase.tasks) return;

    const oldTasks = [...selectedPhase.tasks];

    const removedTask = oldTasks.findIndex(
      selectedTask => selectedTask.id === taskId
    );

    const selectedTask = oldTasks.find(task => {
      return task.id === taskId;
    });

    if (!selectedTask) return;

    const usersToRemove = selectedTask.users_ids;

    if (!usersToRemove) return;

    usersToRemove.map(user => {
      return deleteUserToProjectByTask(user, projectId, groupId, taskId);
    });

    if (removedTask > -1) {
      oldTasks.splice(removedTask, 1);
    }

    toast.success(`A task '${selectedTask.name}' foi deletada!`);

    selectedPhase.tasks = oldTasks;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addUsersInTask = (
    users: IUserId[],
    projectId: number,
    groupId: number,
    taskId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const oldGroups = [...selectedProject.groups];

    const selectedGroup = oldGroups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase || !selectedPhase.tasks) return;

    const oldTasks = [...selectedPhase.tasks];

    const selectedTask = oldTasks.find(task => {
      return task.id === taskId;
    });

    if (!selectedTask) return;

    selectedTask.users_ids = users;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addNewIndividualTask = (projectId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject) return;

    if (!selectedProject.tasks) {
      selectedProject.tasks = [];
    }
    const oldIndividualTasks = [...selectedProject.tasks];

    const newIndividualTask = {
      id: Math.floor(Math.random() * 65536),
      name: 'Nova tarefa individual',
      desc: 'Adicione uma descrição',
      due_date: 0,
      initial_due_date: 0,
      days_repeat: 0,
      checklist: true,
      project_id: projectId,
      order: oldIndividualTasks.length + 1,
      subtasks: [],
      users_ids: initialUsers,
    };

    const projectWithNewIndividualTask = [
      ...oldIndividualTasks,
      newIndividualTask,
    ];

    selectedProject.tasks = projectWithNewIndividualTask;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updateIndividualTaskName = (
    projectId: number,
    individualTaskId: number,
    newName: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.tasks) return;

    const oldTasks = [...selectedProject.tasks];

    const selectedIndividualTask = oldTasks.find(task => {
      return task.id === individualTaskId;
    });

    if (!selectedIndividualTask) return;

    selectedIndividualTask.name = newName;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updateIndividualTaskDescription = (
    projectId: number,
    individualTaskId: number,
    newDescription: string
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.tasks) return;

    const oldTasks = [...selectedProject.tasks];

    const selectedIndividualTask = oldTasks.find(task => {
      return task.id === individualTaskId;
    });

    if (!selectedIndividualTask) return;

    selectedIndividualTask.desc = newDescription;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const deleteIndividualTask = (
    projectId: number,
    individualTaskId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.tasks) return;

    const oldIndividualTasks = [...selectedProject.tasks];

    const removedTaskIndex = oldIndividualTasks.findIndex(
      selectedIndividualTask => selectedIndividualTask.id === individualTaskId
    );

    const selectedIndividualTask = oldIndividualTasks.find(individualTask => {
      return individualTask.id === individualTaskId;
    });

    if (!selectedIndividualTask) return;

    let usersToRemove = selectedIndividualTask.users_ids;

    if (!usersToRemove) {
      usersToRemove = [];
    }

    usersToRemove.map(user => {
      return deleteUserToProjectByIndividualTask(
        user,
        projectId,
        individualTaskId
      );
    });

    if (removedTaskIndex > -1) {
      oldIndividualTasks.splice(removedTaskIndex, 1);
    }

    toast.success(
      `A tarefa individual '${selectedIndividualTask.name}' foi deletado!`
    );

    selectedProject.tasks = oldIndividualTasks;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const addUsersInIndividualTask = (
    users: IUserId[],
    projectId: number,
    individualTaskId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.tasks) return;

    const oldTasks = [...selectedProject.tasks];

    const selectedIndividualTask = oldTasks.find(task => {
      return task.id === individualTaskId;
    });

    if (!selectedIndividualTask) return;

    selectedIndividualTask.users_ids = users;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const updateDueDateInIndividualTask = (
    newDueDate: number,
    projectId: number,
    individualTaskId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.tasks) return;

    const selectedIndividualTask = selectedProject.tasks.find(
      individualTask => individualTask.id === individualTaskId
    );

    if (!selectedIndividualTask) return;

    selectedIndividualTask.due_date = newDueDate;

    setGlobalPlaybookProjects(newProjects);
  };

  const updateDueDateInTask = (
    newDueDate: number,
    projectId: number,
    groupId: number,
    taskId: number
  ) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(
      group => group.id === groupId
    );

    if (!selectedGroup || !selectedGroup.phases) return;

    const firstPhase = selectedGroup.phases[0];

    if (!firstPhase || !firstPhase.tasks) return;

    const selectedTask = firstPhase.tasks.find(task => task.id === taskId);

    if (!selectedTask) return;

    selectedTask.due_date = newDueDate;

    setGlobalPlaybookProjects([...newProjects]);
  };

  const getMinimunTasksDueDate = (projectId: number, groupId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    let minimunDeadline = -1;

    if (!selectedProject || !selectedProject.groups) return minimunDeadline;

    const selectedGroup = selectedProject.groups.find(
      group => group.id === groupId
    );

    if (!selectedGroup || !selectedGroup.phases) return minimunDeadline;

    const firstPhase = selectedGroup.phases[0];

    if (!firstPhase || !firstPhase.tasks) return minimunDeadline;

    const allTasks = firstPhase.tasks;

    if (!allTasks) return minimunDeadline;

    if (allTasks.length > 1) {
      const taskWithminimunDeadline = allTasks.reduce((prev, curr) => {
        return prev.due_date < curr.due_date ? prev : curr;
      });
      minimunDeadline = taskWithminimunDeadline.due_date;
    }

    if (allTasks.length === 1) {
      const taskWithminimunDeadline = allTasks[0];
      minimunDeadline = taskWithminimunDeadline.due_date;
    }

    return minimunDeadline;
  };

  const getMinimunIndividualTasksDueDate = (projectId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });
    let minimunDeadline = -1;

    if (!selectedProject || !selectedProject.tasks) return minimunDeadline;

    const allTasks = selectedProject.tasks;

    if (!allTasks) return minimunDeadline;

    if (allTasks.length > 1) {
      const taskWithminimunDeadline = allTasks.reduce((prev, curr) => {
        return prev.due_date < curr.due_date ? prev : curr;
      });
      minimunDeadline = taskWithminimunDeadline.due_date;
    }

    if (allTasks.length === 1) {
      const taskWithminimunDeadline = allTasks[0];
      minimunDeadline = taskWithminimunDeadline.due_date;
    }

    return minimunDeadline;
  };

  const getProjectDueDate = (projectId: number) => {
    const newProjects = [...globalPlaybookProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    let deadline = -1;
    let individualTaskDeadline = -1;
    let groupDeadline = -1;

    if (!selectedProject) return deadline;

    const allIndividualTasks = selectedProject.tasks;

    if (!allIndividualTasks) {
      individualTaskDeadline = -1;
    }

    if (allIndividualTasks && allIndividualTasks.length > 1) {
      const individualTaskWithMinimunDeadline = allIndividualTasks.reduce(
        (prev, curr) => {
          return prev.due_date < curr.due_date ? prev : curr;
        }
      );
      individualTaskDeadline = individualTaskWithMinimunDeadline.due_date;
    }

    if (allIndividualTasks && allIndividualTasks.length === 1) {
      const individualTaskWithMinimunDeadline = allIndividualTasks[0];
      individualTaskDeadline = individualTaskWithMinimunDeadline.due_date;
    }

    const allGroups = selectedProject.groups;

    if (!allGroups) {
      groupDeadline = -1;
    }
    if (allGroups && allGroups.length === 1) {
      const allGroupsDuedate: number[] = [];
      const groupDueDate = getMinimunTasksDueDate(projectId, allGroups[0].id);

      allGroupsDuedate.push(groupDueDate);

      [groupDeadline] = allGroupsDuedate;
    }

    if (allGroups && allGroups.length > 1) {
      const allGroupsDuedate: number[] = [];
      allGroups.map(group => {
        const groupDueDate = getMinimunTasksDueDate(projectId, group.id);

        return allGroupsDuedate.push(groupDueDate);
      });

      groupDeadline = allGroupsDuedate.reduce((prev, curr) => {
        return prev < curr ? prev : curr;
      });
    }

    if (groupDeadline === -1) {
      deadline = individualTaskDeadline;
    }
    if (individualTaskDeadline === -1) {
      deadline = groupDeadline;
    }

    if (groupDeadline !== -1 && individualTaskDeadline !== -1)
      deadline =
        groupDeadline >= individualTaskDeadline
          ? individualTaskDeadline
          : groupDeadline;

    return deadline;
  };

  const individualTaskUserIsEmpty = (projects: IPlaybookProject[]) => {
    const allProjects = [...projects];

    let isEmpty = false;

    if (allProjects.length === 0) return isEmpty;

    allProjects.map(project => {
      const allIndividualTasks = project.tasks;

      if (!allIndividualTasks) return isEmpty;

      allIndividualTasks.map(individualTask => {
        const usersIsEmpty = individualTask.users_ids;

        if (!usersIsEmpty) return isEmpty;

        if (usersIsEmpty.length === 0) {
          isEmpty = true;
        }

        return isEmpty;
      });

      return isEmpty;
    });

    return isEmpty;
  };

  const taskUserIsEmpty = (projects: IPlaybookProject[]) => {
    const allProjects = [...projects];

    let isEmpty = false;

    if (allProjects.length === 0) return isEmpty;

    allProjects.map(project => {
      const allGroups = project.groups;

      if (!allGroups || allGroups.length === 0) return isEmpty;

      allGroups.map(group => {
        const allPhases = group.phases;

        if (!allPhases || allPhases.length === 0) return isEmpty;

        const allTasks = allPhases[0].tasks;

        if (!allTasks) return isEmpty;

        allTasks.map(task => {
          const usersIsEmpty = task.users_ids;

          if (!usersIsEmpty) return isEmpty;

          if (usersIsEmpty.length === 0) {
            isEmpty = true;
          }

          return isEmpty;
        });

        return isEmpty;
      });

      return isEmpty;
    });

    return isEmpty;
  };

  const importPlaybook = async (
    clientId: number,
    handleClose: () => void,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => {
    const data = {
      client_id: clientId,
      projects: globalPlaybookProjects,
    };

    if (individualTaskUserIsEmpty(data.projects)) {
      toast.error(
        'Não é possível importar um playboook com tasks individuais sem responsáveis, verifique e tente novamente!'
      );
      return;
    }

    if (taskUserIsEmpty(data.projects)) {
      toast.error(
        'Não é possível importar um playboook com tasks sem responsáveis, verifique e tente novamente!'
      );
      return;
    }

    if (data.projects.length === 0) {
      toast.error(
        'Não é possível importar um playboook vazio, adicione um projeto e tente novamente!'
      );
    }

    try {
      setLoading(true);
      await apiV2.post(`/playbooks/import`, data);
      toast.success('Playbook importado com sucesso, atualize a página!');
    } catch (err) {
      toast.error(
        'Problema ao importar Playbook, confira os campos e tente novamente 😕'
      );
    } finally {
      setLoading(false);
      handleClose();
      setTimeout(() => {
        window.location.reload(false);
      }, 1200);
    }
  };

  return (
    <ImportPlaybookContext.Provider
      value={{
        setPlaybookUpdatedAt,
        globalPlaybookProjects,
        setGlobalPlaybookProjects,
        addNewProject,
        updateProjectName,
        updateProjectDescription,
        deleteProject,
        addNewGroup,
        updateGroupName,
        updateGroupDescription,
        deleteGroup,
        addNewPhase,
        updatePhaseName,
        deletePhase,
        updatePhaseChecklistItemName,
        deletePhaseChecklistItem,
        addNewPhaseChecklistItem,
        addNewTask,
        updateTaskName,
        updateTaskDescription,
        deleteTask,
        addNewIndividualTask,
        updateIndividualTaskName,
        updateIndividualTaskDescription,
        deleteIndividualTask,
        initialUsers,
        createInitialUsers,
        addUsersInProjects,
        addUserToProjectByIndividualTask,
        deleteUserToProjectByIndividualTask,
        addUserToProjectByTask,
        deleteUserToProjectByTask,
        addUserInPhase,
        deleteUserInPhase,
        addUsersInGroups,
        addUsersInTask,
        addUsersInIndividualTask,
        updateDueDateInIndividualTask,
        updateDueDateInTask,
        getMinimunTasksDueDate,
        getMinimunIndividualTasksDueDate,
        getProjectDueDate,
        createGlobalPlaybookProjects,
        importPlaybook,
      }}
    >
      {children}
    </ImportPlaybookContext.Provider>
  );
};

export { ImportPlaybookProvider, ImportPlaybookContext };

export const useImportPlaybook = (): ImportPlaybookContextData => {
  return useContext(ImportPlaybookContext);
};
