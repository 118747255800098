import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 98%;
  min-height: 6.5rem;
  display: flex;
  border-radius: 0.5rem;

  background-color: ${({ theme }) => theme.backgroundTerciary};
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.text};
  &:hover {
    box-shadow: 0 0.25rem 1.5rem 0 rgba(227, 36, 56, 0.5);
  }

  ${props =>
    props.isDragging &&
    css`
      box-shadow: 0 0.25rem 1.5rem 0 rgba(227, 36, 56, 0.5);
      transform: rotate(356deg);
    `}
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 0.5rem;

  #responsible {
    width: 100%;
    display: flex;
    align-items: center;
    div {
      padding-top: 0.15rem;
    }
    p {
      margin-right: 1rem;
    }
  }
`;

export const TagsArea = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const Title = styled.div`
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    font-size: 0.6rem;
    color: ${({ theme }) => theme.text};
  }

  div > div {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 0.3rem;
  }

  #period {
    width: 45%;
    display: flex;
  }

  #progress {
    width: 45%;
    display: flex;
    margin-left: 1rem;
  }
`;

export const RestoreTask = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;

    #restore-label {
      color: var(--gray);
      margin: 0 0.5rem;
    }
  }
`;
