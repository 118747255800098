import styled, { css } from 'styled-components';

interface StyledDivProps {
  isDragging: boolean;
}

export const Wrapper = styled.div<StyledDivProps>`
  display: flex;
  width: 99%;
  height: 5.5rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.backgroundTerciary};
  align-items: center;
  align-self: flex-end;
  margin-bottom: 1rem;
  transition: 0.3s;

  #edit-button {
    border: none;
    background: none;
    margin-top: 0.4rem;
    margin-right: 0.2rem;
    color: ${({ theme }) => theme.text};
  }

  .grid-item {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;

    p:last-child {
      color: var(--gray);
      margin-left: 0.2rem;
    }

    p:first-child {
      color: var(--gray);
      margin-right: 0.2rem;
    }
  }

  :hover {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(227, 36, 56, 0.3);
  }

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(255, 255, 255, 0.2);
      padding-top: 31px;
      border-radius: 1rem;
      background: transparent;
      box-shadow: none !important;
      cursor: grabbing;
      .drag {
        opacity: 0;
      }
    `}
  @media (max-width: 1290px) {
    height: 7rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TagsArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.4rem;
  margin-left: 1rem;
`;

export const Main = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  #name {
    overflow-x: visible;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: pointer;

    p {
      font-size: 0.7rem;
      color: ${({ theme }) => theme.text};
    }

    div {
      display: flex;
      align-items: center;

      svg {
        width: 1rem;
        min-width: 1rem;
        margin-right: 0.4rem;
        color: ${({ theme }) => theme.text};
      }
    }

    button {
      background: none;
      border: none;
      color: var(--gray);
    }

    h5 {
      font-size: 0.6rem;
      color: ${({ theme }) => theme.text};
      @media (max-width: 1080px) {
        font-size: 0.2rem;
      }
    }
  }

  #managers {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
  }

  #add-new {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      border: none;
      background: none;
      color: var(--gray);
      .icon {
        margin-right: 0.5rem;

        color: ${({ theme }) => theme.text};
      }
    }
  }
`;

export const RestoreGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  button {
    display: flex;
    align-items: center;
    color: var(--gray);
    background: none;
    border: none;

    #restore-label {
      margin: 0 0.5rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StickerColor = styled.div`
  width: 0.5%;
  justify-content: flex-end;
  height: 100%;
  background-color: var(--red-primary);
  border-radius: 0 1rem 1rem 0;
  outline: none;
`;
