import {
  FC,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { format } from 'date-fns';
import { DateRange } from 'react-date-range';
import Popup from 'reactjs-popup';
import { FiltersTypes } from '../../modules/MyTasks/pages/TasksBoard/index';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import * as S from './styles';

type DateRangePickerProps = {
  setFilters: Dispatch<SetStateAction<FiltersTypes>>;
};

export const DateRangePicker: FC<DateRangePickerProps> = ({ setFilters }) => {
  const [dataRanges, setDataRanges] = useState([
    {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
      color: 'var(--red-primary)',
    },
  ]);

  const handleDateUpdate = useCallback(item => {
    setDataRanges([item.selection]);
  }, []);

  useEffect(() => {
    if (dataRanges[0].startDate && dataRanges[0].endDate) {
      const initialRange = format(
        new Date(dataRanges[0].startDate!),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );
      const finalRange = format(
        new Date(dataRanges[0].endDate!),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );
      setFilters(prevFilters => {
        const coppiedStateObject = { ...prevFilters };
        return {
          ...coppiedStateObject,
          range: { ...coppiedStateObject.range, start: initialRange },
        };
      });
      setFilters(prevFilters => {
        const coppiedStateObject = { ...prevFilters };
        return {
          ...coppiedStateObject,
          range: { ...coppiedStateObject.range, end: finalRange },
        };
      });
    }
  }, [dataRanges, setFilters]);

  return (
    <S.Wrapper>
      <div className="date">
        <Popup
          position="bottom center"
          trigger={<p>Filtrar por período</p>}
          on="click"
          className="tooltip"
          arrow={false}
          contentStyle={{
            border: 0,
            padding: '0px',
            marginTop: '20px',
          }}
        >
          <DateRange
            editableDateInputs
            onChange={item => handleDateUpdate(item)}
            moveRangeOnFirstSelection={false}
            ranges={dataRanges}
            showPreview
            className="date-range"
          />
        </Popup>
      </div>
    </S.Wrapper>
  );
};
