/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { MdSkipPrevious, MdSkipNext } from 'react-icons/md';

import { toast } from 'react-toastify';
import {
  Container,
  Search,
  Input,
  Filters,
  Header,
  PostsWrapper,
  Controll,
} from './styles';
import NewTicket from './components/newTicket';
import api from '../../services/api';
import WikiPost from './components/WikiPost';
import Ticket from './pages/Tickets/components/item';

import { useAuth } from '../../contexts/AuthContext';

function Help() {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [category, setCategory] = useState('all');
  const [subCategory, setSubCategory] = useState('all');
  const [openTicket, setOpenTicket] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [posts, setPosts] = useState({ data: [] });
  const [isWiki, setIsWiki] = useState(true);
  const [tickets, setTickets] = useState({ data: [] });
  const [open, setOpen] = useState('true');
  const { id: userId } = useAuth().user;
  const getRoles = useCallback(async () => {
    await api.get('roles').then(res => {
      setIsSupport(
        res.data.filter(role => role.role === 'support' || role.role === 'cs')
          .length > 0
      );
    });
  }, []);

  const getPosts = useCallback(async () => {
    await api.get(`wiki_posts${query}`).then(res => setPosts(res.data));
  }, [query]);

  const getTickets = useCallback(async () => {
    await api
      .get(`tickets${query}&user_id=${userId}&open=${open}`)
      .then(res => setTickets(res.data))
      .catch(() => {
        toast.warn('Você ainda nao tem nenhum ticket.');
      });
  }, [open, query, userId]);
  useEffect(() => {
    let q = `?page=${page}`;
    if (category !== 'all') {
      q += `&category=${category}`;
    }
    if (subCategory !== 'all') {
      q += `&subcategory=${subCategory}`;
    }
    if (search !== '') {
      q += `&name=${search}`;
    }

    setQuery(q);
  }, [category, page, subCategory, search]);
  useEffect(() => {
    getRoles();
  }, [getRoles]);
  useEffect(() => {
    getPosts();
    getTickets();
  }, [getPosts, getTickets, query]);

  const subOptions = useMemo(() => {
    if (category === 'cs') {
      return (
        <>
          <option value="onboarding">Onboarding</option>
          <option value="performance">Performance</option>
          <option value="conflicts">Resolução de Conflitos</option>
        </>
      );
    }
    if (category === 'nc') {
      return (
        <>
          <option value="growth">Crescimento da Unidade</option>
          <option value="hiring">Contratações</option>
          <option value="renegociation">Renogiciação</option>
        </>
      );
    }
    if (category === 'finance') {
      return (
        <>
          <option value="billings">Contas a Receber</option>
          <option value="pnp">Padrões e Processos</option>
        </>
      );
    }
    if (category === 'tech') {
      return (
        <>
          <option value="lab">Mkt Lab</option>
        </>
      );
    }
    if (category === 'sales') {
      return (
        <>
          <option value="hunter">Hunter</option>
          <option value="pnp">Padrões e Processos</option>
        </>
      );
    }
    return false;
  }, [category]);

  return (
    <Container>
      <Header>
        {isSupport && (
          <Link to="/help/tickets">
            <button type="button">Chamados</button>
          </Link>
        )}
      </Header>
      <Search>
        <h1>No que podemos te ajudar?</h1>
        <Input>
          <input
            type="text"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Pesquisar na wiki ou abrir chamado..."
          />
          {search.length > 2 && isWiki && (
            <button
              type="button"
              onClick={() => {
                if (tickets.votePending) {
                  toast.error(
                    'Você tem avaliações de tickets pendentes, por favor avalie antes de criar um novo!'
                  );
                  return false;
                }
                setOpenTicket(true);

                return true;
              }}
            >
              Não encontrou uma resposta? Envie um ticket!
            </button>
          )}
        </Input>
      </Search>
      <Filters>
        <input
          type="radio"
          id="wiki"
          name="order"
          value="wiki"
          checked={isWiki}
          onClick={() => setIsWiki(true)}
        />
        <label htmlFor="wiki">Ir para Wiki</label>
        <input
          type="radio"
          id="tickets"
          name="order"
          value="tickets"
          onClick={() => setIsWiki(false)}
        />
        <label htmlFor="tickets">{`Ir para Meus Tickets (${tickets.count})`}</label>
        <select
          name="category"
          onChange={e => setCategory(e.target.value)}
          defaultValue="0"
        >
          <option value="0" disabled>
            Categoria
          </option>
          <option value="all">Todos</option>
          <option value="cs">Custumer Success</option>
          <option value="nc">Network Coordination</option>
          <option value="finance">Financeiro</option>
          <option value="tech">Tecnologia</option>
          <option value="sales">Vendas</option>
        </select>
        <select
          name="subcategory"
          onChange={e => setSubCategory(e.target.value)}
          defaultValue="0"
        >
          <option value="0" disabled>
            Subcategoria
          </option>
          <option value="all">Todos</option>
          {subOptions}
        </select>
        {!isWiki && (
          <select
            name="open"
            onChange={e => setOpen(e.target.value)}
            defaultValue="true"
          >
            <option value="true">Abertos</option>
            <option value="false">Fechados</option>
          </select>
        )}
      </Filters>

      <PostsWrapper>
        {isWiki ? (
          <>
            {posts.data.map(post => (
              <WikiPost
                post={post}
                key={post.id}
                getPosts={getPosts}
                editable={false}
              />
            ))}

            <Controll>
              {page - 1 > 0 ? (
                <MdSkipPrevious size={26} onClick={() => setPage(page - 1)} />
              ) : (
                <MdSkipPrevious size={26} color="var(--gray)" />
              )}
              {`${posts.start} - ${posts.finish} de ${posts.count}`}
              {posts.finish < posts.count ? (
                <MdSkipNext size={26} onClick={() => setPage(page + 1)} />
              ) : (
                <MdSkipNext size={26} color="var(--gray)" />
              )}
            </Controll>
          </>
        ) : (
          <>
            {tickets.data.map(ticket => (
              <Ticket
                key={ticket.id}
                ticket={ticket}
                getPosts={getPosts}
                getTickets={getTickets}
                user
              />
            ))}
            <Controll>
              {page - 1 > 0 ? (
                <MdSkipPrevious size={26} onClick={() => setPage(page - 1)} />
              ) : (
                <MdSkipPrevious size={26} color="var(--gray)" />
              )}
              {`${tickets.start} - ${tickets.finish} de ${tickets.count}`}
              {tickets.finish < tickets.count ? (
                <MdSkipNext size={26} onClick={() => setPage(page + 1)} />
              ) : (
                <MdSkipNext size={26} color="var(--gray)" />
              )}
            </Controll>
          </>
        )}
      </PostsWrapper>

      {openTicket && (
        <NewTicket
          title={search}
          setSearch={setSearch}
          active={setOpenTicket}
        />
      )}
    </Container>
  );
}

export default Help;
