import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Link } from 'react-router-dom';
import * as S from './styles';

interface ObjectProgress {
  percentage: number;
  count: number;
}

type Progress = {
  lateDoneTasksProgress: ObjectProgress;
  lateTasksProgress: ObjectProgress;
  onTimeDoneTasksProgress: ObjectProgress;
  onTimeTasksProgress: ObjectProgress;
  rescheduleTasksProgress: ObjectProgress;
};

export const ChartsArea: React.FC<{ progress: Progress }> = ({ progress }) => {
  return (
    <S.Wrapper>
      <header>
        <h2>Progresso das Tarefas</h2>
      </header>
      <main>
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Tarefas atrasadas',
              value: progress.lateTasksProgress.percentage,
              color: `var(--red-primary)`,
            },
            {
              value: 100 - progress.lateTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={25}
          label={() => `${progress.lateTasksProgress.percentage}%`}
          labelStyle={{
            fontSize: '1.5625rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Entregues atrasadas',
              value: progress.lateDoneTasksProgress.percentage,
              color: `var(--yellow-primary)`,
            },
            {
              value: 100 - progress.lateDoneTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={25}
          label={() => `${progress.lateDoneTasksProgress.percentage}%`}
          labelStyle={{
            fontSize: '1.5625rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Tarefas em dia',
              value: progress.onTimeTasksProgress.percentage,
              color: `var(--cyan-primary)`,
            },
            {
              value: 100 - progress.onTimeTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={25}
          label={() => `${progress.onTimeTasksProgress.percentage}%`}
          labelStyle={{
            fontSize: '1.5625rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Tarefas entregues',
              value: progress.onTimeDoneTasksProgress.percentage,
              color: `var(--green-primary)`,
            },
            {
              value: 100 - progress.onTimeDoneTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={25}
          label={() => `${progress.onTimeDoneTasksProgress.percentage}%`}
          labelStyle={{
            fontSize: '1.5625rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Tarefas remanejadas',
              value: progress.rescheduleTasksProgress.percentage,
              color: `var(--magenta-primary)`,
            },
            {
              value: 100 - progress.rescheduleTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={25}
          label={() => `${progress.rescheduleTasksProgress.percentage}%`}
          labelStyle={{
            fontSize: '1.5625rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
      </main>
      <footer>
        <Link to="/home/tasks_board" id="my-tasks">
          <button type="button">Ver minhas Tasks</button>
        </Link>
      </footer>
    </S.Wrapper>
  );
};
