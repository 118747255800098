/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import MultiSelect from 'react-multi-select-component';
import Popup from 'reactjs-popup';
import { DateRange } from 'react-date-range';

import { Container, Header, Half } from './styles';
import Card from './components/Card';
import BigDataCard from './components/BigCard';
import LittleCard from './components/LittleCard';
import DonutChart from './components/DonutChart';
import CohorChart from './components/CohortChart';
import api from '../../../../services/api';
import Loading from '../../../../components/Loadings/Loading';

export default function ChargesCharts() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiRes, setApiRes] = useState([]);
  const [apiClientsRes, setApiClientsRes] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [unit, setUnit] = useState('');
  const [client, setClient] = useState('');
  const [dateUpdate, setDateUpdate] = useState(0);
  const [dataRanges, setDataRanges] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: 'selection',
      color: '#e32438',
    },
  ]);

  const handleUpdateRequest = useCallback(async () => {
    const initialDate = format(new Date(dataRanges[0].startDate), 'yyyy-MM-dd');
    const finalDate = format(new Date(dataRanges[0].endDate), 'yyyy-MM-dd');
    setIsLoading(true);
    const clientArray = [];
    if (client !== '') {
      // eslint-disable-next-line array-callback-return
      client.map(newClient => {
        clientArray.push(newClient.value);
      });
    }
    const unitArray = [];
    if (unit !== '') {
      // eslint-disable-next-line array-callback-return
      unit.map(newUnit => {
        unitArray.push(newUnit.value);
      });
    }

    await Promise.all([
      api
        .get(`billing/dashboard`, {
          params: {
            clientId: clientArray.join(', '),
            unitId: unitArray.join(', '),
            initialDate,
            finalDate,
          },
        })
        .then(response => {
          setApiRes(response.data);
        }),
    ]);
    setIsLoading(false);
  }, [client, dataRanges, unit]);

  useEffect(() => {
    api.get('billing/dashboard').then(res => {
      setApiRes(res.data);
    });

    api.get('billing/dashboard/clients').then(res => {
      setApiClientsRes(res.data);
    });

    api.get('units').then(response => {
      setUnitList(response.data);
    });

    api.get('clients').then(response => {
      setClientList(response.data);
    });
  }, []);

  useEffect(() => {
    if (dateUpdate !== 0) {
      handleUpdateRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateUpdate, unit, client]);

  const fixMoney = useCallback(value => {
    if (!value) {
      return 'Carregando...';
    }
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }, []);

  const handleDateUpdate = useCallback(
    item => {
      setDataRanges([item.selection]);

      if (item.selection.startDate !== item.selection.endDate) {
        setDateUpdate(dateUpdate + 1);
      }
    },
    [dateUpdate]
  );

  return (
    <Container>
      {isLoading ? <Loading /> : <> </>}
      <Header>
        <motion.div
          style={{ paddingLeft: '8px' }}
          initial={{ opacity: '0%', scale: 0 }}
          animate={{ opacity: '100%', scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 280,
          }}
        >
          <Half>
            <div className="inputs">
              <div className="input">
                <p>Cliente</p>
                <MultiSelect
                  className="multi-select"
                  options={clientList.map(theClient => {
                    return { label: theClient.name, value: theClient.id };
                  })}
                  value={client}
                  onChange={setClient}
                  labelledBy="Selecione o Cliente"
                  hasSelectAll={false}
                  overrideStrings={{
                    selectSomeItems: 'Selecionar o cliente',
                    allItemsAreSelected: 'Todos itens selecionados.',
                    selectAll: 'Selecionar todos',
                    search: 'Pesquisar',
                  }}
                />
              </div>

              <div className="input">
                <p>Unidade</p>
                <MultiSelect
                  className="multi-select"
                  options={unitList.map(theUnit => {
                    return { label: theUnit.name, value: theUnit.id };
                  })}
                  value={unit}
                  onChange={setUnit}
                  labelledBy="Selecione a unidade"
                  hasSelectAll={false}
                  overrideStrings={{
                    selectSomeItems: 'Selecione a unidade',
                    allItemsAreSelected: 'Todos itens selecionados.',
                    selectAll: 'Selecionar todos',
                    search: 'Pesquisar',
                  }}
                />
              </div>

              <div className="input">
                <p>Período</p>
                <Popup
                  position="bottom center"
                  trigger={
                    <select defaultValue="0">
                      <option disabled value="0" style={{ display: 'none' }}>
                        {`${dataRanges[0].startDate.getDate()}/${dataRanges[0].startDate.getMonth() +
                          1}/${dataRanges[0].startDate.getFullYear()}`}
                        :
                        {`${dataRanges[0].endDate.getDate()}/${dataRanges[0].endDate.getMonth() +
                          1}/${dataRanges[0].endDate.getFullYear()}`}
                      </option>
                    </select>
                  }
                  on="click"
                  className="tooltip"
                  arrow={false}
                  contentStyle={{
                    background: '#333',
                    border: 0,
                    boxShadow: 0,
                    padding: '0px',
                    marginTop: '20px',
                  }}
                >
                  <DateRange
                    editableDateInputs
                    onChange={item => handleDateUpdate(item)}
                    moveRangeOnFirstSelection={false}
                    ranges={dataRanges}
                    showPreview
                    style={{ backgroundColor: '#F6F6F6' }}
                  />
                </Popup>
              </div>
            </div>
          </Half>
        </motion.div>
        <Half>
          <motion.div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              cursor: 'default',
            }}
            initial={{ opacity: '0%', scale: 0 }}
            animate={{ opacity: '100%', scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 280,
            }}
          >
            <Card
              title="Prov. Na Rede"
              value={fixMoney(apiRes.totalIncome)}
              title2="Prov. Na Matriz"
              value2={fixMoney(apiRes.totalIncome)}
              color="#f1d302"
              pin1="100%"
              pin2="100%"
            />
            <Card
              title="Receb. Na Rede"
              value={fixMoney(apiRes.totalPaid)}
              title2="Receb. Na Matriz"
              value2={fixMoney(apiRes.totalPaid)}
              pin1={`${Math.round(apiRes.totalIncome / apiRes.totalPaid)}%`}
              pin2={`${Math.round(apiRes.totalIncome / apiRes.totalPaid)}%`}
              color="#1FC875"
              tooltip1="Valor recebido / Valor provisionado"
              tooltip2="Valor recebido / Valor provisionado"
            />
            <Card
              title="Atrasado na rede"
              value={fixMoney(apiRes.totalNotPaid)}
              title2="Atrasado Na Matriz"
              value2={fixMoney(apiRes.totalNotPaid)}
              color="#E32438"
            />
            <Card
              title="A receb. Na Rede"
              value={fixMoney(apiRes.totalToReceive * -1)}
              color="#f6f6f6"
              title2="Receb. Na Matriz"
              value2={fixMoney(apiRes.totalToReceive * -1)}
              pin1="0%"
              pin2="0%"
            />
          </motion.div>
        </Half>
      </Header>
      <motion.div
        initial={{ opacity: '0%' }}
        animate={{ opacity: '100%' }}
        transition={{
          type: 'spring',
          stiffness: 10,
          damping: 500,
        }}
        className="row"
      >
        <BigDataCard
          title="Total Provisionado"
          value={fixMoney(apiRes.filteredTotalIncome)}
          color="#f1d302"
          pin1="Filtrados"
        />
        <BigDataCard
          title="Recebido"
          value={fixMoney(apiRes.filteredTotalPaid)}
          color="#1FC875"
          pin1="Filtrados"
        />
        <BigDataCard
          title="Em Atraso"
          value={fixMoney(apiRes.filteredTotalNotPaid)}
          color="#E32438"
          pin1="Filtrados"
        />
        <BigDataCard
          title="A receber"
          value={fixMoney(apiRes.filteredTotalToReceive * -1)}
          color="#f6f6f6"
          pin1="Filtrados"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: '0%' }}
        animate={{ opacity: '100%' }}
        transition={{
          type: 'spring',
          stiffness: 10,
          damping: 500,
        }}
        className="row"
      >
        <BigDataCard
          title="Total Provisionado"
          value={fixMoney(apiRes.totalIncome)}
          color="#f1d302"
          pin1="16%"
          pin2="17%"
          pin3="18%"
        />
        <BigDataCard
          title="Recebido"
          value={fixMoney(apiRes.totalPaid)}
          color="#1FC875"
          pin1="16%"
        />
        <BigDataCard
          title="Em Atraso"
          value={fixMoney(apiRes.totalNotPaid)}
          color="#E32438"
          pin1="16%"
          pin2="17%"
          pin3="18%"
        />
        <BigDataCard
          title="A receber"
          value={fixMoney(apiRes.totalToReceive * -1)}
          color="#f6f6f6"
          pin1="16%"
          pin2="17%"
          pin3="18%"
        />
      </motion.div>
      <Half>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <motion.div
            initial={{ opacity: '0%' }}
            animate={{ opacity: '100%' }}
            transition={{
              type: 'spring',
              stiffness: 10,
              damping: 500,
            }}
            className="row"
          >
            <LittleCard
              color="#f6f6f6"
              title="Clientes"
              value={apiClientsRes.totalClients}
            />
            <LittleCard
              color="#f6f6f6"
              title="Novos Clientes"
              value={apiClientsRes.totalNewClientsMonth}
            />
            <LittleCard color="#f6f6f6" title="CAC" value="R$6.300,00" />
            <LittleCard
              color="#f6f6f6"
              title="Royalties"
              value={fixMoney(apiClientsRes.totalRoyatiesUnit)}
            />
            <LittleCard
              color="#f6f6f6"
              title="Ticket Médio"
              value="R$2.150,00"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: '0%' }}
            animate={{ opacity: '100%' }}
            transition={{
              type: 'spring',
              stiffness: 10,
              damping: 500,
            }}
            className="row"
          >
            <LittleCard
              color="#E32438"
              title="Clientes em Atraso"
              value={apiClientsRes.totalPassedDueClients}
            />
            <LittleCard
              color="#1FC875"
              title="Recuperados"
              value={apiClientsRes.totalRetrievedClients}
            />
            <LittleCard
              color="#E32438"
              title="Cac Em atraso"
              value={apiClientsRes.totalClients}
            />
            <LittleCard
              color="#1FC875"
              title="Cac Recuperado"
              value={apiClientsRes.totalClients}
            />
            <LittleCard
              color="#E32438"
              title="Roy em atraso"
              value={fixMoney(apiClientsRes.totalPassedDueRoyalties)}
            />
            <LittleCard
              color="#1FC875"
              title="Roy em recuperado"
              value={fixMoney(apiClientsRes.totalRetrievedRoyalties)}
            />
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: '0%' }}
          animate={{ opacity: '100%' }}
          transition={{
            type: 'spring',
            stiffness: 10,
            damping: 500,
          }}
          className="row"
          style={{ justifyContent: 'space-between' }}
        >
          <DonutChart
            color="#6E44FF"
            porcentage={apiRes.totalIncomePorcentage}
            text="Total Provisionado"
            value={fixMoney(apiRes.filteredTotalIncome)}
          />
          <DonutChart
            color="#FFA62B"
            porcentage={apiRes.totalPaidPorcentage}
            text="Total Pago"
            value={fixMoney(apiRes.filteredTotalIncome)}
          />
          <DonutChart
            color="#FECEE9"
            porcentage={apiRes.totalNotPaidPorcentage}
            text="Total Atrasado"
            value={fixMoney(apiRes.filteredTotalIncome)}
          />
          <DonutChart
            color="#F25F5C"
            porcentage={apiRes.totalReceivedPorcentage}
            text="Total Recebido"
            value={fixMoney(apiRes.filteredTotalIncome)}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: '0%' }}
          animate={{ opacity: '100%' }}
          transition={{
            type: 'spring',
            stiffness: 10,
            damping: 500,
          }}
          className="row"
        >
          <CohorChart updates={dateUpdate} dates={dataRanges} />
        </motion.div>
      </Half>
    </Container>
  );
}
