import React, { useState, useEffect, useCallback } from 'react';
import { RiSeedlingLine, RiPenNibLine } from 'react-icons/ri';

import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { Button, TextInput } from '../../../../../components/Inputs';
import * as S from './styles';
import RichText from '../../../../../components/RichText';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import Responsible from '../../../../../components/Responsible';
import Checklist from '../../../../../components/Checklist';
import useForm from '../../../../../hooks/useForm';
import apiV2 from '../../../../../services/apiV2';
import { Checkbox } from '../../Checkbox';
import { LoadingButton } from '../../LoadingButton';
import { SelectIcon } from '../../SelectIcons';
import { getPhases } from '../../../services/GetPhases';
import { SetPhases } from '../../../utils/SetPhases';
import { useTasks } from '../../../../../contexts/TasksContext';
import selectProjectIndexById from '../../../utils/SelectProjectIndexById';
import selectGroupIndexById from '../../../utils/SelectGroupIndexById';

interface useFormHookProps {
  form: { phaseName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

const NewPhase: React.FC<{
  handleClose: () => void;
  groupId: number;
  projectId: number;
  phases: Phase[];
}> = ({ handleClose, groupId, projectId, phases }) => {
  const { projects, setProjects } = useTasks();
  const { form, onChange }: useFormHookProps = useForm({ phaseName: '' });
  const [phasesList, setPhasesList] = useState<Phase[]>([]);
  const [phaseSelected, setPhaseSelected] = useState<number>();
  const [users, setUsers] = useState([{}]);
  const [selectedIcon, setSelectedIcon] = useState<string>('');
  const [content, setContent] = useState('');
  const [checks, setChecks] = useState<CheckItem[]>([]);
  const [checkRemove, setCheckRemove] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const handleSelectPhase = (event: React.MouseEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLSelectElement;
    if (target.value) {
      setPhaseSelected(Number(target.value));
    }
  };

  const projectIndex = selectProjectIndexById(projectId, projects);

  const groupIndex = selectGroupIndexById(projectIndex, groupId, projects);

  const setPhasesInfo = useCallback(async () => {
    const newPhases = await getPhases(groupId);

    setPhasesList(newPhases);
  }, [groupId]);

  useEffect(() => {
    setPhasesInfo();
  }, [setPhasesInfo]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const createPhase = useCallback(async () => {
    setIsDisable(true);

    if (form.phaseName!.length <= 0 || form.phaseName! === ' ') {
      toast.warn('Não é possível criar uma fase sem nome!');
      setIsDisable(false);
      return;
    }

    await apiV2
      .post(`phases`, {
        name: form.phaseName,
        desc: content,
        group_id: groupId,
        order: phaseSelected! + 1,
        phase_checklist_items: checks,
        icon_name: selectedIcon || null,
        users_ids: users,
        remove_users: checkRemove,
      })
      .then(res => {
        toast.success('Fase criada com sucesso!');

        let phasesToUpdate: Phase[] = [];

        const groupPhases = projects[projectIndex].groups[groupIndex].phases;

        if (groupPhases) {
          phasesToUpdate = cloneDeep(groupPhases);
        }

        phasesToUpdate.splice(res.data.phase.order - 1, 0, res.data.phase);

        const newProjects = SetPhases(
          phasesToUpdate,
          projects,
          projectId,
          groupId
        );

        setProjects(() => newProjects);

        handleClose();
      });

    setIsDisable(false);
  }, [
    checkRemove,
    checks,
    content,
    form.phaseName,
    groupId,
    projectId,
    projects,
    setProjects,
    groupIndex,
    projectIndex,
    handleClose,
    selectedIcon,
    phaseSelected,
    users,
  ]);

  return (
    <S.Wrapper>
      <TaskCard width="23rem" close={handleClose}>
        <header>
          <RiSeedlingLine
            className="icon-phase"
            size={24}
            color="var(--red-primary)"
          />
          <S.InputWrapper>
            <TextInput
              name="phaseName"
              onChange={handleInputChange}
              value={form.phaseName}
              background="none"
              font="400 0.9rem Work Sans"
              height="1.5"
              placeholder="Nova Fase"
            />
          </S.InputWrapper>
          <RiPenNibLine className="icon-phase" size={16} color="var(--gray)" />
        </header>
        <main>
          <S.Content>
            <div className="input">
              <p>Responsável</p>
              <Responsible users={users} setUsers={setUsers} />
            </div>

            <S.RemoveResponsibles role="button" tabIndex={0}>
              <Checkbox
                name="remove"
                finished={checkRemove}
                setFinished={setCheckRemove}
              />
              <p>Remover Responsáveis atuais</p>
            </S.RemoveResponsibles>

            <div className="input">
              <p>Adicionar após</p>
              <select onClick={handleSelectPhase}>
                <option value="">Selecionar fase</option>
                {phasesList &&
                  phases &&
                  phasesList.map((phase, index) => {
                    if (index === phases.length - 1) {
                      return false;
                    }
                    return (
                      <option key={phase.id} value={phase.order!}>
                        {phase.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="input">
              <p style={{ marginBottom: '0.6rem' }}>Checklist</p>
              <Checklist checks={checks} setChecks={setChecks} />
            </div>

            <div className="input">
              <p>Selecione o Ícone</p>
              <SelectIcon
                selectedIcon={selectedIcon}
                setSelectedIcon={setSelectedIcon}
              />
            </div>

            <div className="input">
              <p>Descrição da fase</p>

              <RichText
                height={200}
                width={300}
                initialValue="Escreva aqui a descrição desta fase."
                contentToSave={(contentItem: string) => {
                  setContent(contentItem);
                }}
              />
            </div>
          </S.Content>
        </main>
        <footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isDisable ? (
            <LoadingButton width="50%" height="2.1rem" />
          ) : (
            <Button
              background="var(--green-primary)"
              width="50%"
              height="2.1rem"
              font="400 0.9rem Work Sans"
              onClick={() => createPhase()}
            >
              <p>Confirmar</p>
            </Button>
          )}
        </footer>
      </TaskCard>
    </S.Wrapper>
  );
};

export default NewPhase;
