import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.6rem;
  overflow-x: auto;

  border-bottom: 1px solid ${({ theme }) => theme.backgroundPrimary};

  svg {
    cursor: pointer;
    min-width: 1.5rem;
    transition: 0.3s;
    color: ${({ theme }) => theme.text};
  }

  .previewItem {
    margin-right: 2rem;
    width: 100%;
    height: 5.8rem;
  }

  .previewResponsible {
    margin-right: 2rem;
    width: 100%;
    height: 5.8rem;
    max-width: 15rem;

    p {
      color: var(--red-primary);
      letter-spacing: 1.8px;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
    }
  }

  .previewItem p {
    color: var(--red-primary);
    letter-spacing: 1.8px;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .previewItem textarea {
    height: 60px !important;
    letter-spacing: 0.72px;
    min-width: 15rem;
    margin-top: 0rem;
  }

  .previewItem h4 {
    font-weight: 400;
  }

  hr {
    border: 1px solid var(--gray);
    height: 2rem;
    margin-right: 1rem;
  }

  input {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.backgroundSecondary};
    font: 400 0.9rem Work Sans;
    height: 2.1rem;
    margin-top: 0.8rem;
    border-radius: 8px;
    border: 0;
    outline: 0;
    padding: 0.8rem;
  }
`;
