import React from 'react';
import { ThemeProps } from '../../../../../../styles/themes';
import * as S from './styles';

interface LoadingProps {
  background: string;
}

export const Loading = ({ background }: LoadingProps): JSX.Element => {
  return (
    <S.Wrapper style={{ backgroundColor: background }}>
      <S.Container id="name">
        <S.NameBar className="animation" />
        <S.NameBar className="animation" />
        <S.NameBar className="animation" />
      </S.Container>
      <S.Container id="responsibles">
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
      </S.Container>
      <S.Container id="period">
        <S.ProgressBar className="animation" />
      </S.Container>
      <S.Container id="progress">
        <S.ProgressBar className="animation" />
      </S.Container>
      <S.Container id="add">
        <S.ProgressBar className="animation" />
      </S.Container>
    </S.Wrapper>
  );
};

export const ProjectCardLoading = (): JSX.Element => {
  return (
    <>
      <Loading
        background={String((theme: ThemeProps) => theme.backgroundSecondary)}
      />
      <Loading
        background={String((theme: ThemeProps) => theme.backgroundSecondary)}
      />
      <Loading
        background={String((theme: ThemeProps) => theme.backgroundSecondary)}
      />
      <Loading
        background={String((theme: ThemeProps) => theme.backgroundSecondary)}
      />
      <Loading
        background={String((theme: ThemeProps) => theme.backgroundSecondary)}
      />
      <Loading
        background={String((theme: ThemeProps) => theme.backgroundSecondary)}
      />
    </>
  );
};

export const GroupLoading = (): JSX.Element => {
  return (
    <>
      <Loading
        background={String((theme: ThemeProps) => theme.backgroundSecondary)}
      />
    </>
  );
};

export const TaskLoading = (): JSX.Element => {
  return (
    <>
      <Loading
        background={String((theme: ThemeProps) => theme.backgroundSecondary)}
      />
    </>
  );
};

export const PhaseLoading = (): JSX.Element => {
  return (
    <S.Wrapper
      style={{
        width: '95%',
        alignSelf: 'flex-end',
      }}
    >
      <S.Container id="name">
        <S.NameBar className="animation" />
        <S.NameBar className="animation" />
        <S.NameBar className="animation" />
      </S.Container>
      <S.Container id="responsibles">
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
      </S.Container>
      <S.Container id="period">
        <S.ProgressBar className="animation" />
      </S.Container>
      <S.Container id="progress">
        <S.ProgressBar className="animation" />
      </S.Container>
      <S.Container id="add">
        <S.ProgressBar className="animation" />
      </S.Container>
    </S.Wrapper>
  );
};
