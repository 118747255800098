import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  form {
    width: 100%;
    input#tag-name {
      width: 100%;
      height: 2.1rem;
      padding: 0 1rem;
      border: none;
      background: ${({ theme }) => theme.backgroundTerciary};
      color: ${({ theme }) => theme.text};
      ::placeholder {
        color: var(--white);
      }
    }
  }
  #tag-area {
    width: 100%;
    max-height: 10rem;
    margin-top: 3rem;
    padding-top: 0.5rem;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    div {
      width: 100%;
      height: 1.8rem;
      margin: 0.2rem 0;
    }

    input[type='radio'] {
      position: absolute;
      visibility: hidden;
      display: none;
    }
    label {
      width: 100%;
      height: 1.5rem;
      padding-left: 0.3rem;
      color: ${({ theme }) => theme.text};
      display: inline-block;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.2s linear;
      &:hover {
        filter: brightness(90%);
        transition: all 0.2s linear;
        background: var(--red-primary);
      }
    }
    /* input[type='radio']:checked + label {
      color: var(--white);
      background: var(--gray);
      border-radius: 1rem;
      transition: all 0.2s linear;

      &:hover {
        filter: brightness(90%);
        border-radius: 3px;
        transition: all 0.2s linear;
      }
    } */

    ::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.text};
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
