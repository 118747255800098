import React, { Dispatch, SetStateAction, useState } from 'react';
import { RiFilterLine } from 'react-icons/ri';
import { FiltersTypes } from '../../index';
import { Checkbox } from '../../../../../Tasks/components/Checkbox';
import { DateRangePicker } from '../../../../../../components/DateRangePicker';
import * as S from './styles';

interface FiltersProps {
  filters: FiltersTypes;
  setFilters: Dispatch<SetStateAction<FiltersTypes>>;
}

export const Filters: React.FC<FiltersProps> = ({ filters, setFilters }) => {
  // const [checkDeleted, setCheckDeleted] = useState(false);
  const [checkCompleted, setCheckCompleted] = useState(false);
  const [isActive, setIsActive] = useState('standard');

  return (
    <S.Wrapper>
      <div className="header filter">
        <RiFilterLine className="icon" size={20} color="var(--red-primary)" />
        <p className="filter">Filtrar |</p>
      </div>
      <div className="filterOptions filter">
        {/* <div className="checkboxDelete filter">
          <Checkbox
            name="deleted"
            finished={checkDeleted}
            setFinished={setCheckDeleted}
            checkFunction={() =>
              setFilters({ ...filters, deleted: !filters.deleted })
            }
          />
          <p className="filter">Mostrar Deletadas?</p>
        </div> */}

        <div className="checkboxComplete filter">
          <Checkbox
            name="completed"
            finished={checkCompleted}
            setFinished={setCheckCompleted}
            checkFunction={() =>
              setFilters({ ...filters, completed: !filters.completed })
            }
          />
          <p className="filter">Mostrar completas?</p>
        </div>

        <DateRangePicker setFilters={setFilters} />

        <div className="filterButtons filter">
          <button
            className="filter"
            style={{
              backgroundColor:
                isActive === 'standard' ? 'var(--red-primary)' : 'initial',
              border:
                isActive === 'standard' ? 'none' : '0.1rem solid var(--gray)',
              color: isActive === 'standard' ? 'var(--white)' : 'var(--gray)',
            }}
            onClick={() => {
              setIsActive('standard');
              setFilters({ ...filters, sort: 'default' });
            }}
            type="button"
          >
            Padrão
          </button>
          <button
            className="filter"
            style={{
              backgroundColor:
                isActive === 'asc' ? 'var(--red-primary)' : 'initial',
              border: isActive === 'asc' ? 'none' : '0.1rem solid var(--gray)',
              color: isActive === 'asc' ? 'var(--white)' : 'var(--gray)',
            }}
            onClick={() => {
              setIsActive('asc');
              setFilters({ ...filters, sort: 'ASC' });
            }}
            type="button"
          >
            Menor Prazo
          </button>
          <button
            className="filter"
            style={{
              backgroundColor:
                isActive === 'desc' ? 'var(--red-primary)' : 'initial',
              border: isActive === 'desc' ? 'none' : '0.1rem solid var(--gray)',
              color: isActive === 'desc' ? 'var(--white)' : 'var(--gray)',
            }}
            onClick={() => {
              setIsActive('desc');
              setFilters({ ...filters, sort: 'DESC' });
            }}
            type="button"
          >
            Maior Prazo
          </button>
        </div>
      </div>
    </S.Wrapper>
  );
};
