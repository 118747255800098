import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 0.9rem;

  @media (max-width: 570px) {
    flex-direction: column;
  }

  p {
    width: 6rem;
    white-space: pre-wrap;

    @media (max-width: 570px) {
      text-align: center;
    }
  }

  p strong {
    word-break: break-all;
  }

  .date p {
    width: 100%;
  }

  button {
    margin-bottom: 0.5rem;
  }

  .name {
    min-width: 70%;
    max-width: 70%;

    @media (max-width: 1500px) {
      min-width: auto;
      max-width: auto;
    }
  }

  .date {
    background: ${({ theme }) => theme.backgroundTerciary};
    color: ${({ theme }) => theme.text};
    padding: 0.5rem;
    font-weight: bold;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-right: 0.4rem;
    font-size: 0.8rem;

    @media (max-width: 570px) {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }
`;

export const Csat = styled.div<{ color: string }>`
  position: relative;

  span {
    width: 12rem;
    padding: 0.6rem;

    background: ${({ color }) => color};
    color: var(--white);
    border-radius: 8px;
    font-size: 0.8rem;
    font-weight: 500;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;

    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    &::before {
      // Flecha em baixo do tooltip.
      content: '';
      border-style: solid;
      border-color: ${({ color }) => `${color} transparent`};
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
