import React, { useState, useCallback } from 'react';
import { RiCheckboxCircleLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import apiV2 from '../../../../../services/apiV2';

import Projects from './Projects';
import KrsCard from '../../../components/KrsAndOkrs/KrsCard';

import * as S from './styles';
import { ProjectsProps } from '../types';
import { Okrs } from '../../../components/KrsAndOkrs/types';

interface CardProps {
  projects: ProjectsProps[];
  okr: Okrs;
  remove: (id: number) => void;
}

const Card: React.FC<CardProps> = ({ projects, okr, remove }) => {
  const [projectId, setProjectId] = useState<number>();

  const handleLinkOkr = useCallback(() => {
    if (projectId) {
      apiV2
        .put('okr', {
          id: okr.id,
          project_id: projectId,
        })
        .then(() => {
          toast.success('Okr relacionada com sucesso!');
          remove(okr.id);
        })
        .catch(() => {
          toast.error('Erro ao relacionar okr.');
        });
    } else {
      toast.warn('Selecione o projeto.');
    }
  }, [remove, okr.id, projectId]);

  return (
    <S.Container>
      <header>
        <p>{okr.name}</p>

        <div className="project">
          <Projects projects={projects} setProjectId={setProjectId} />
          <div className="icon" aria-hidden onClick={() => handleLinkOkr()}>
            <RiCheckboxCircleLine size={22} />
          </div>
        </div>
      </header>

      <div className="krs">
        <KrsCard showHeader={false} okrId={okr.id} krs={okr.krs} />
      </div>
    </S.Container>
  );
};

export default Card;
