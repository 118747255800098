import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export const createProject = async (
  projectName: string | undefined,
  projectDescription: string,
  clientId: string,
  tagsIds: number[]
): Promise<Project> => {
  try {
    const response = await apiV2.post(`projects`, {
      name: projectName,
      desc: projectDescription,
      client_id: clientId,
      tags_ids: tagsIds,
    });

    const project = response.data;

    return project;
  } catch {
    toast.error(
      'Ocorreu algum problema ao criar ao criar o projeto, atualize a página e tente novamente 😕'
    );

    return {} as Project;
  }
};
