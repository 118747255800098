import React, { useMemo } from 'react';
import { format } from 'date-fns';
import Chart from 'react-apexcharts';

import { Props } from 'react-apexcharts';
import getDefaultOptions from '../../../../../../styles/defaultChartStyles';
import * as S from './styles';
import { DashData } from '../../../../types';

interface NewConversionChart {
  dashData: DashData;
}

type DataSetsProps = {
  name: string;
  data: number[];
};

const ConversionChart: React.FC<NewConversionChart> = ({ dashData }) => {
  const data = useMemo(() => {
    const datasets: DataSetsProps[] = [];

    const finalDates: string[] = [];

    dashData.conversionRate.dates.forEach(date => {
      finalDates.push(format(new Date(date), 'dd/MM/yyyy'));
    });

    dashData.conversionRate.convertions.forEach(conversion => {
      datasets.push({
        name: conversion.name,
        data: conversion.values,
      });
    });

    return {
      labels: finalDates,
      datasets,
    };
  }, [dashData.conversionRate]);

  const defaultOptions = getDefaultOptions({});

  const options: Props['ApexOptions'] = {
    ...defaultOptions,
    xaxis: {
      categories: data.labels,
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    legend: {
      show: false,
    },
  };

  return (
    <S.Container>
      <h1>Taxa de conversão</h1>

      <Chart
        options={options}
        series={data.datasets}
        type="area"
        width="100%"
      />
    </S.Container>
  );
};

export default ConversionChart;
