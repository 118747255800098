import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getContracts(
  status = 'all',
  page = 1,
  limit = 20
): Promise<Contracts | false> {
  try {
    const response = await apiV2.get(
      `/contracts/?status=${status}&page=${page}&limit=${limit}`
    );
    const contracts: Contracts = response.data;

    return contracts;
  } catch (err) {
    toast.error(
      `Ocorreu algum problema ao carregar os contratos, atualize a página e tente novamente 😕`
    );
    return false;
  }
}
