import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { differenceInDays, format, addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { RiEditBoxLine } from 'react-icons/ri';
import CloseButton from '../../../components/CloseButton';

import { useImportPlaybook } from '../../../../../../../../contexts/ImportPlaybookContext';
import ResponsibleIds from '../../../../../../../../components/ResponsibleIds';
import { Calendar } from '../../../../../../../../components/Calendar';
import { Modal } from '../../../../../../styles';

import {
  Container,
  Label,
  Deadline,
  SeparatorVertical,
  Buttons,
} from '../../../styles/IndividualTaskContainer';

interface TaskProps {
  individualTask: IPlaybookTask;
  projectId: number;
  setProjectDueDate: Dispatch<SetStateAction<number>>;
}

const IndividualTask: React.FC<TaskProps> = ({
  individualTask,
  projectId,
  setProjectDueDate,
}) => {
  const [users, setUsers] = useState<IUserId[]>([]);
  const [backupUsers, setBackupUsers] = useState<IUserId[]>([]);

  const [individualTaskDueDate, setIndividualTaskDueDate] = useState<
    Date | undefined
  >(new Date());

  const [openNewCalendar, setOpenNewCalendar] = useState(false);

  const [firstTime, setFirstTime] = useState(true);

  const {
    updateIndividualTaskName,
    updateIndividualTaskDescription,
    updateDueDateInIndividualTask,
    deleteIndividualTask,
    addUserToProjectByIndividualTask,
    deleteUserToProjectByIndividualTask,
    initialUsers,
    addUsersInIndividualTask,
    getProjectDueDate,
  } = useImportPlaybook();

  const handleOpenCalendar = (): void => {
    setOpenNewCalendar(!openNewCalendar);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'calendar-modal') handleOpenCalendar();
  };

  const deleteIndividualTaskAndUpdateProjectDueDate = () => {
    deleteIndividualTask(projectId, individualTask.id);

    const projectDueDate = getProjectDueDate(projectId);
    setProjectDueDate(projectDueDate);
  };

  useEffect(() => {
    if (backupUsers.length !== users.length) {
      if (backupUsers.length > users.length) {
        const [deletedUser] = backupUsers.filter(user => !users.includes(user));

        addUsersInIndividualTask(users, projectId, individualTask.id);
        deleteUserToProjectByIndividualTask(
          deletedUser,
          projectId,
          individualTask.id
        );
      } else {
        const [addedUser] = users.filter(user => !backupUsers.includes(user));

        addUsersInIndividualTask(users, projectId, individualTask.id);
        addUserToProjectByIndividualTask(addedUser, projectId);
      }
      setBackupUsers(users);
    }
  }, [
    addUserToProjectByIndividualTask,
    addUsersInIndividualTask,
    backupUsers,
    deleteUserToProjectByIndividualTask,
    individualTask.id,
    projectId,
    users,
  ]);

  useEffect(() => {
    const diffDays = differenceInDays(individualTaskDueDate!, new Date()) + 1;

    if (individualTask.due_date !== diffDays) {
      updateDueDateInIndividualTask(diffDays, projectId, individualTask.id);
      const projectDueDate = getProjectDueDate(projectId);
      setProjectDueDate(projectDueDate);
    }
  }, [
    getProjectDueDate,
    individualTask.due_date,
    individualTask.id,
    individualTaskDueDate,
    projectId,
    setProjectDueDate,
    updateDueDateInIndividualTask,
  ]);

  useEffect(() => {
    if (firstTime) {
      addUsersInIndividualTask(initialUsers, projectId, individualTask.id);
      setUsers(initialUsers);
      setBackupUsers(initialUsers);

      const projectDueDate = getProjectDueDate(projectId);
      setProjectDueDate(projectDueDate);

      setIndividualTaskDueDate(addDays(new Date(), individualTask.due_date));

      setFirstTime(false);
    }
  }, [
    addUsersInIndividualTask,
    firstTime,
    getProjectDueDate,
    individualTask.due_date,
    individualTask.id,
    individualTask.users_ids,
    initialUsers,
    projectId,
    setProjectDueDate,
    users,
  ]);

  return (
    <>
      <Container>
        <Label>
          Task Individual
          <textarea
            id="individualTaskName"
            defaultValue={individualTask.name}
            onBlur={e => {
              updateIndividualTaskName(
                projectId,
                individualTask.id,
                e.target.value
              );
            }}
          />
        </Label>
        <SeparatorVertical />
        <Label>
          <ResponsibleIds
            users={users}
            setUsers={setUsers}
            placeholder="Selecione aqui um responsável"
          />
        </Label>
        <SeparatorVertical />
        <Deadline>
          Prazo
          <button type="button" onClick={handleOpenCalendar}>
            <p>
              {format(individualTaskDueDate!, 'dd.MMMM.yyyy', {
                locale: ptBR,
              }).toString()}
              <RiEditBoxLine color="var(--gray)" size={15} />
            </p>
          </button>
          {openNewCalendar && (
            <Modal id="calendar-modal" onClick={handleOutsideClick}>
              <Calendar
                onChange={setIndividualTaskDueDate}
                date={individualTaskDueDate}
                activeRemove
              />
            </Modal>
          )}
        </Deadline>
        <SeparatorVertical />
        <Label>
          Descrição
          <textarea
            id="individualTaskDescription"
            defaultValue={individualTask.desc}
            onBlur={e => {
              updateIndividualTaskDescription(
                projectId,
                individualTask.id,
                e.target.value
              );
            }}
          />
        </Label>
        <Buttons>
          <CloseButton
            onClick={() => deleteIndividualTaskAndUpdateProjectDueDate()}
            disabled={false}
          />
        </Buttons>
      </Container>
    </>
  );
};

export default IndividualTask;
