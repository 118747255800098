import React, { Dispatch, SetStateAction } from 'react';
import {
  RiFoldersLine,
  RiUser3Line,
  RiTimeLine,
  RiLineChartFill,
  RiAddCircleLine,
} from 'react-icons/ri';
import * as S from './styles';

interface DetailsBar {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const DetailsBar: React.FC<DetailsBar> = ({
  setOpenModal,
}: DetailsBar) => {
  const handleOpenModal = (): void => {
    setOpenModal(true);
  };
  return (
    <>
      <S.Wrapper>
        <div className="grid-item">
          <div id="deliveries">
            <RiFoldersLine
              className="icon"
              size={20}
              color="var(--red-primary)"
            />
            <p className="titleText">Entregas</p>
          </div>
        </div>
        <div className="grid-item">
          <p> | </p>
          <div id="responsible">
            <RiUser3Line
              className="icon"
              size={20}
              color="var(--red-primary)"
            />
            <p className="titleText">Responsável</p>
          </div>
          <p> | </p>
        </div>

        <div className="grid-item">
          <div id="period">
            <RiTimeLine className="icon" size={20} color="var(--red-primary)" />
            <p className="titleText">Prazo</p>
          </div>
          <p> | </p>
        </div>

        <div className="grid-item">
          <div id="progress">
            <RiLineChartFill
              className="icon"
              size={20}
              color="var(--red-primary)"
            />
            <p className="titleText">Progresso</p>
          </div>
          <p> | </p>
        </div>

        <div className="grid-item">
          <div id="add-new">
            <button type="button" onClick={handleOpenModal}>
              <RiAddCircleLine
                className="icon"
                size={20}
                color="var(--red-primary)"
              />
              <p className="titleText">Adicionar novo</p>
            </button>
          </div>
        </div>
      </S.Wrapper>
    </>
  );
};
