import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { Container, Footer } from './styles';
import api from '../../../../../services/api';

function Reply({ suggestion_id, setActive, getSuggestions }) {
  const [desc, setDesc] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      await api
        .post('/reply_suggestion', {
          desc,
          status,
          suggestion_id,
        })
        .then(() => {
          toast.success('Adicionada respota!');
          getSuggestions();
          setActive(false);
        })
        .catch(() => {
          toast.error('Algo errado aconteceu, por favor, atualize a página!');
        });
    },
    [desc, getSuggestions, setActive, status, suggestion_id]
  );

  return (
    <Container onSubmit={handleSubmit}>
      <textarea
        value={desc}
        placeholder="Digite sua resposta..."
        onChange={e => setDesc(e.target.value)}
      />
      <Footer>
        <select
          name="status"
          onChange={e => setStatus(e.target.value)}
          defaultValue="0"
        >
          <option value="0" disabled>
            Selecione o status
          </option>
          <option value="open">Aberto</option>
          <option value="backlog">Backlog</option>
          <option value="dev">Desenvolvendo</option>
          <option value="review">Em análise</option>
          <option value="done">Finalizado</option>
          <option value="reproved">Reprovado</option>
        </select>
        <button type="submit">Enviar Resposta</button>
      </Footer>
    </Container>
  );
}

export default Reply;
