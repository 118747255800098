import { useContext } from 'react';
import { RiTrelloLine, RiMistFill } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import { ViewContext } from '../../../../contexts/ViewContext';
import * as S from './styles';

function SelectViewBar() {
  const { changeView, view } = useContext(ViewContext);
  const { id } = useParams<Params>();

  return (
    <S.Wrapper>
      <Link to={`/tasks/${id}/list`}>
        <button onClick={() => changeView('list')} type="button">
          <RiMistFill
            className="icon"
            size={18}
            color={view === 'list' ? 'var(--red-primary)' : 'var(--gray)'}
          />
          Lista
        </button>
      </Link>
      <Link to={`/tasks/${id}/kanban`}>
        <button onClick={() => changeView('kanban')} type="button">
          <RiTrelloLine
            className="icon"
            size={18}
            color={view === 'kanban' ? 'var(--red-primary)' : 'var(--gray)'}
          />
          Kanban
        </button>
      </Link>
    </S.Wrapper>
  );
}

export default SelectViewBar;
