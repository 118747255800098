import {
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { GiFlame } from 'react-icons/gi';

import PopUp from '../../../../../components/PopUp';

import * as S from './styles';
import { postNewWikiPostVoteService } from '../../../services/postNewWikiPostVoteService';
import { getWikiPostService } from '../../../services/getWikiPostService';

type Post = {
  id: number;
  name: string;
  published: string;
  createdAt: Date;
  voteId: number;
  problem: string;
  reasons: string;
  solutions: string;
  youtube: string;
};

interface WikipostProps {
  setActive: Dispatch<SetStateAction<boolean>>;
  id: number;
}

const Wikipost: FC<WikipostProps> = ({ setActive, id }) => {
  const [post, setPost] = useState({} as Post);
  const [rating, setRating] = useState(0);
  const [allRating, setAllRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);

  const getPost = useCallback(async () => {
    const selectedPost = await getWikiPostService({ id });

    if (selectedPost) {
      setPost(selectedPost);
      setRating(selectedPost.userVote);
      setAllRating(selectedPost.votes);
    }
  }, [id]);

  const handleRating = useCallback(
    async e => {
      setRating(e);
      await postNewWikiPostVoteService({ event: e, post });
    },
    [post]
  );

  useEffect(() => {
    getPost();
  }, [getPost]);

  const { stars, color } = useMemo(() => {
    if (hoverRating > 0) {
      return { stars: hoverRating, color: 'var(--gray)' };
    }
    if (rating > 0) {
      return { stars: rating, color: 'var(--red-primary)' };
    }

    return { stars: allRating, color: 'var(--red-primary)' };
  }, [allRating, hoverRating, rating]);

  return (
    <PopUp closePopUp={setActive} header={post.name} width="60%">
      <S.Container>
        <iframe
          title="yt"
          id="ytplayer"
          width="640"
          height="360"
          src={`https://www.youtube.com/embed/${post.youtube}?origin=https://laboratoriodomarketing.com`}
          frameBorder="0"
        />
        <label htmlFor="problem">
          <h1>Problema:</h1>
          <pre id="problem">{post.problem}</pre>
        </label>
        <label htmlFor="reasons">
          <h1>Causas:</h1>
          <pre id="reasons">{post.reasons}</pre>
        </label>
        <label htmlFor="solutions">
          <h1>Soluções:</h1>
          <pre id="solutions">{post.solutions}</pre>
        </label>
        <S.Rating>
          Quanto esse post foi útil?
          <StarRatingComponent
            name="rate1"
            starCount={5}
            value={stars}
            renderStarIcon={() => <GiFlame />}
            starColor={color}
            onStarHover={e => setHoverRating(e)}
            onStarHoverOut={() => setHoverRating(0)}
            onStarClick={handleRating}
          />
        </S.Rating>
      </S.Container>
    </PopUp>
  );
};

export default Wikipost;
