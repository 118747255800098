import styled from 'styled-components';

export const Container = styled.div`
  max-width: 500px;
  height: 100%;
  padding: 1rem;
  border-radius: 8px;
  background: ${({ theme }) => theme.backgroundSecondary};
`;

export const Form = styled.form`
  .input {
    margin-bottom: 1rem;
  }

  .input:last-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 0rem;
  }

  .inputContainer {
    display: flex;
    width: 100%;
  }

  .inputContainer .input {
    width: 100%;
  }

  .inputContainer .input:last-child {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
`;
