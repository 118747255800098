import styled from 'styled-components';

export const Background = styled.div`
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const Container = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;
  cursor: auto;
  display: flex;
  flex-direction: column;
  height: 85vh;
  justify-content: space-between;
  margin: 8vh auto 0;
  min-width: 1024px;
  padding: 4vh 1.5vw;
  position: absolute;
  top: 0;
  width: 65%;
`;

export const Header = styled.header`
  align-items: center;
  border-bottom: 2px solid var(--gray);
  display: flex;
  margin-bottom: 2vh;
  padding-bottom: 1.5vh;
  width: 100%;

  svg {
    color: var(--red-primary);
    cursor: pointer;
    font-size: 1rem;
  }

  h3 {
    color: ${({ theme }) => theme.text};
    flex: 1;
    font-size: 1rem;
    margin-left: 10px;
  }
`;

export const Projects = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2vh;
  overflow: auto;
  padding-right: 1vw;
  width: 100%;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    color: var(--red-primary);
    flex: 1;
    margin-right: 2rem;
  }

  button + button {
    margin-left: 0.5rem;
  }
`;
