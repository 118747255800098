import styled from 'styled-components';

export const ProgressBar = styled.div`
  color: var(--white);
  font-size: 0.8rem;
  align-items: center;
  text-align: center;
  display: flex;
  align-content: initial !important;
  justify-content: initial !important;
  padding: 0 !important;
  position: relative;
  background-color: var(--gray);
  * {
    margin-top: 0;
  }
  .progress {
    height: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;

    h5 {
      color: var(--white);
    }
  }
  h5 {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
    color: var(--white);
  }
`;
