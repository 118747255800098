import { toast } from 'react-toastify';
import api from '../../../services/api';

export const getClient = async (id: number | string): Promise<IClient> => {
  try {
    const response = await api.get(`/clients/${id}`);

    const client = response.data;

    return client;
  } catch {
    toast.error(
      'Ocorreu algum problema ao carregar o cliente, atualize a página e tente novamente 😕'
    );
    return {} as IClient;
  }
};
