import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #adadad;
  label {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    input {
      margin-top: 10px;
      background: #1b1f24;
      color: #adadad;
      border-radius: 4px;
      border: none;
      padding: 0 5px;
      height: 20px;
      width: 40%;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20%;
  margin-top: 20px;
  button {
    color: #0c0e10;
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    font-weight: bolder;
    font-size: 1.2vw;
    text-align: center;
    :hover {
      color: #adadad;
      background: transparent !important;
    }
  }
  #cancel {
    background: #e32438;
    border: 1px solid #e32438;
  }
  #confirm {
    background: #1fc875;
    border: 1px solid #1fc875;
    :disabled {
      background: #adadad;
      color: #121212;
      border: 1px solid #121212;
      :hover {
        background: #adadad !important;
        color: #121212;
      }
    }
  }
`;
