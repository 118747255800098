import React from 'react';
import { RiAddCircleLine } from 'react-icons/ri';

import { Container } from './styles';

import TaskItem from './Task';
import PhaseCard from '../../../../PhaseCard';

interface PhaseProps {
  phases: Phase[];
}

const PhasesItem: React.FC<PhaseProps> = ({ phases }) => {
  return (
    <>
      <Container>
        {phases.map((phase, i) => {
          if (i === 0) {
            return (
              <div className="phaseCard">
                <PhaseCard type="initial" phase={phase} />
              </div>
            );
          }

          if (i === 1) {
            return (
              <div className="phaseCard">
                <PhaseCard type="intermediary" phase={phase} />
              </div>
            );
          }

          if (i === 2) {
            return (
              <div className="phaseCard">
                <PhaseCard type="final" phase={phase} />
              </div>
            );
          }

          return <div />;
        })}
        <div className="addButton">
          <RiAddCircleLine size={40} color="var(--red-primary)" />
          <p>Adicionar uma Fase</p>
        </div>
      </Container>

      {phases[0].tasks.map(task => {
        return <TaskItem task={task} />;
      })}
      <div
        style={{
          marginLeft: '3.5rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div role="button" aria-hidden className="addProject">
          <RiAddCircleLine
            className="initialButton"
            size={21}
            color="var(--red-primary)"
          />
          <p>Adicionar Task</p>
        </div>
      </div>
    </>
  );
};

export default PhasesItem;
