import { FC } from 'react';
import { RiUser3Line } from 'react-icons/ri';
import * as S from './styles';

interface ITooltip {
  users: User[];
}

const Tooltip: FC<ITooltip> = ({ users }: ITooltip) => {
  return (
    <S.Wrapper>
      <S.Content>
        <RiUser3Line className="icon" size={20} color="var(--red-primary)" />
        <p>Responsável</p>
      </S.Content>
      <S.List>
        {users.length ? (
          users
            .sort((a, b) =>
              a.percentage && b.percentage && a.percentage < b.percentage
                ? 0
                : -1
            )
            .map(user => {
              if (user.file) {
                return (
                  <div key={user.id}>
                    <img
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        borderRadius: '50%',
                      }}
                      src={`https://api.laboratoriodomarketing.com/files/${user.file?.path}`}
                      alt=""
                    />
                    <p>{user.name}</p>
                    <span>{`${user.percentage || 0}%`}</span>
                  </div>
                );
              }
              return <></>;
            })
        ) : (
          <></>
        )}
      </S.List>
    </S.Wrapper>
  );
};

export default Tooltip;
