import { useCallback, useEffect, useState } from 'react';
import { RiFileTransferLine } from 'react-icons/ri';
import { Route, Switch, useParams, useHistory } from 'react-router-dom';

import { SideButtonMenu } from './components/SideButtonMenu';
import { Header } from './components/Header';

import { ListView } from './pages/ListView';

import { useTasks } from '../../contexts/TasksContext';
import { useTags } from '../../contexts/TagsContext';

import * as S from './styles';
import { getProjects } from './services/GetProjects';
import { getTags } from './services/GetTags';
import { Main } from './pages/KanbanView/Main';
import { ProjectMain } from './pages/KanbanView/Project';
import { GroupMain } from './pages/KanbanView/Group';
import ComponentLoading from '../../components/Loadings/ComponentLoading';

export function Tasks() {
  const {
    projects,
    setProjects,
    projectsLoading,
    setProjectsLoading,
    setAllUsersOfProjects,
  } = useTasks();
  const { setAllTagsOfClient } = useTags();
  const history = useHistory();

  const [sideButtonTurnOn, setSideButtonTurnOn] = useState(false);
  const [cardsArea, setCardsArea] = useState(true);
  const [firstTime, setFirstTime] = useState(true);

  const { id } = useParams<TasksParams>();

  function filterUsersOfProjects(data: Project[]): IUserFilter[] {
    const usrs: IUserFilter[] = [];

    data.forEach((project: Project) => {
      project.users.forEach(user => {
        const ids = usrs.map((usr: IUserFilter) => usr.value);

        if (!ids.includes(String(user.id))) {
          usrs.push({
            value: String(user.id),
            name: `${user.name} ${user.last_name}`,
          });
        }
      });
    });

    return usrs;
  }

  const setProjectsInfo = useCallback(async () => {
    if (typeof setProjectsLoading === 'function') {
      setProjectsLoading(true);
      const responseProjects = await getProjects(+id);

      if (responseProjects === []) {
        return;
      }

      const filteredUsers = filterUsersOfProjects(responseProjects);

      setProjects(() => responseProjects);
      setAllUsersOfProjects(() => filteredUsers);
      setProjectsLoading(false);
    }
  }, [setProjects, id, setAllUsersOfProjects, setProjectsLoading]);

  const setTagsInfo = useCallback(async () => {
    const tagsResponse = await getTags(id);

    const tags: ITagFilter[] = [];

    tagsResponse.map((tag: ITag) => {
      tags.push({
        value: tag.id,
        name: tag.name,
      });
      return true;
    });

    if (typeof setAllTagsOfClient === 'function') {
      setAllTagsOfClient(() => tags);
    }
  }, [id, setAllTagsOfClient]);

  useEffect(() => {
    if (firstTime) {
      setProjectsInfo();
      setFirstTime(false);
    } else {
      setProjects(() => projects);
    }
    setTagsInfo();
  }, [setProjectsInfo, setTagsInfo, firstTime, setProjects, projects]);

  useEffect(() => {
    setProjectsInfo();
    setTagsInfo();
  }, [id, setProjectsInfo, setTagsInfo]);

  useEffect(() => {
    if (!history.location.pathname.includes('project')) {
      const localTheme = window.localStorage.getItem('view');

      if (localTheme === 'kanban') history.push(`/tasks/${id}/kanban`);
      else history.push(`/tasks/${id}/list`);
    }
  }, [id, history]);

  useEffect(() => {
    if (!projectsLoading && projects?.length === 0) {
      setSideButtonTurnOn(true);
    }
  }, [projectsLoading, projects]);

  const handleSideButton = (): void => {
    setSideButtonTurnOn(!sideButtonTurnOn);
  };

  return (
    <S.Wrapper>
      <S.Container data-testid="container">
        <S.TopBar>
          <Header cardsArea={cardsArea} setCardsArea={setCardsArea} />
          <hr />
        </S.TopBar>
        <S.Content cardsArea={cardsArea}>
          <Switch>
            <Route exact path="/tasks/:id/list">
              <ListView />
            </Route>
            <Route exact path="/tasks/:id/kanban">
              <Main />
            </Route>
            <Route exact path="/tasks/:id/kanban/projects/:projectId">
              <ProjectMain />
            </Route>
            <Route
              exact
              path="/tasks/:id/kanban/projects/:projectId/groups/:groupId"
            >
              {projects.length ? (
                <GroupMain />
              ) : (
                <ComponentLoading quantity={6} />
              )}
            </Route>
          </Switch>
        </S.Content>
      </S.Container>

      {sideButtonTurnOn && <SideButtonMenu data-testid="sidemenu-open" />}
      <S.Button
        data-testid="side-btn"
        onMouseEnter={handleSideButton}
        onMouseDown={handleSideButton}
        type="button"
      >
        <RiFileTransferLine size={24} />
      </S.Button>
    </S.Wrapper>
  );
}
