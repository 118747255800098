import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Avatar from 'react-avatar';
import { faBell, faSignOutAlt } from '@fortawesome/pro-regular-svg-icons';
import { faMale } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import ThemeToggler from './components/ThemeToggler';
import { useAuth } from '../../../contexts/AuthContext';
import { Menu } from './components/Menu';

import * as S from './styles';
import SelectViewBar from '../../../modules/Tasks/components/SelectViewBar';

interface HeaderProps {
  themeToggler: () => void;
}

export function Header({ themeToggler }: HeaderProps) {
  const { user } = useAuth();
  const profile = user;
  const userName = useMemo(() => {
    const lastName = profile.last_name.split(' ');
    const name = `${profile.name} ${lastName[lastName.length - 1]}`;

    return name;
  }, [profile.last_name, profile.name]);

  library.add(faMale, faSignOutAlt, faBell);

  const [menuActive, setMenuActive] = useState(false);
  const [showUserOptions, setShowUserOptions] = useState(true);
  const [pageName, setPageName] = useState('Financeiro');
  const [viewToggler, setViewToggler] = useState(false);
  const { signOut } = useAuth();
  const location = useLocation();
  const [firstTime, setFirsTime] = useState(true);

  const handleLogOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const handleUserOptions = useCallback(() => {
    setShowUserOptions(!showUserOptions);
  }, [setShowUserOptions, showUserOptions]);

  const getPageName = useCallback(page => {
    if (page === '/compliance') {
      setPageName('Compliance');
      setViewToggler(false);
    }

    if (page === '/finance') {
      setPageName('Financeiro');
      setViewToggler(false);
    }
    if (page.match(/\b(compliance)\b/g)) {
      setPageName('Compliance');
      setViewToggler(false);
    }
    if (window.location.href.split('/')[4] === 'profile') {
      setPageName('Clientes');
    }

    if (page === '/units') {
      setPageName('Unidades');
      setViewToggler(false);
    }

    if (page === '/profile') {
      setPageName('Perfil');
      setViewToggler(false);
    }
    if (page.includes('/tasks')) {
      setPageName('Tasks');
      setViewToggler(true);
    }
    if (page.includes('/checkin')) {
      setPageName('Checkin');
      setViewToggler(false);
    }
  }, []);

  useEffect(() => {
    getPageName(location.pathname);

    if (firstTime) {
      setTimeout(() => {
        handleUserOptions();
      }, 10000);
      setFirsTime(false);
    }
  }, [getPageName, location, handleUserOptions, firstTime]);

  return (
    <S.Container>
      <S.SideContainer />
      <S.Content>
        <S.SideBarHeader>
          <S.HamburguerMenu role="navigation">
            <div id="menuToggle" className={menuActive ? 'active' : ''}>
              <input type="checkbox" onChange={handleMenuActive} />
              <span />
              <span />
              <span />
            </div>
          </S.HamburguerMenu>
          <h3>Financeiro V4</h3>
        </S.SideBarHeader>
        <Menu menuActive={menuActive} handleMenuActive={handleMenuActive} />
        <S.PageName>
          <h4>{pageName}</h4>
          {viewToggler && (
            <>
              <S.Separator /> <SelectViewBar />
            </>
          )}
        </S.PageName>

        <S.AccountDetails>
          <S.ContainerOptions className={showUserOptions ? 'active' : ''}>
            <S.ThemeButton>
              <ThemeToggler toggleTheme={themeToggler} />
            </S.ThemeButton>
            <S.Separator />
          </S.ContainerOptions>
          <S.UserOptions className={showUserOptions ? 'active' : ''}>
            <button type="button" onClick={handleLogOut} id="logout">
              <FontAwesomeIcon icon={['far', 'sign-out-alt']} />
              <span>Logout</span>
            </button>
            <Link to="/profile" id="profile">
              <FontAwesomeIcon icon={['fas', 'male']} />
              <span>Perfil</span>
            </Link>
          </S.UserOptions>
          <S.User>
            <button type="button" onClick={handleUserOptions}>
              <Avatar
                name={userName}
                src={profile.avatar && profile.avatar.url}
                className="avatar"
                color="var(--gray)"
              />
            </button>
          </S.User>
        </S.AccountDetails>
      </S.Content>
    </S.Container>
  );
}

export default Header;
