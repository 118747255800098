import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  color: ${({ theme }) => theme.text};
  > div {
    max-height: 80vh;
  }

  header,
  main,
  footer {
    width: 20rem;
  }

  header {
    display: flex;
    align-items: center;
  }

  main {
    > button {
      margin: 0;
    }
    select {
      width: 15rem;
      height: 1.5rem;
      border-radius: 0.4rem;
      background-color: ${({ theme }) => theme.backgroundTerciary};
      color: ${({ theme }) => theme.text};
      border: none;
      margin-top: 0.5rem;
    }

    overflow-x: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar {
      height: 0.4rem;
      border-radius: 0.4rem;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.text};
    }
  }

  #mass-edit-button {
    background: var(--red-primary);
    width: 100%;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }

  footer {
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;
  }

  #delete-button {
    background: var(--red-primary);
    width: 70%;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }

  #confirm-button {
    background: var(--green-primary);
    width: 100%;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }
`;

export const Content = styled.div`
  width: 95%;
`;

export const InputWrapper = styled.div`
  width: 8rem;
  margin: 0 0.8rem;
`;

export const RemoveResponsibles = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  p {
    margin-left: 0.5rem;
  }
`;
