import React from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { Container } from './styles';

interface TopCard {
  name: string;
  value: string;
  variation?: number;
}

const Card: React.FC<TopCard> = ({ name, value, variation = 0 }) => {
  return (
    <Container>
      <p>{name}</p>
      <h4
        style={{
          color: variation < 0 ? 'var(--red-primary)' : 'var(--green-primary)',
        }}
      >
        {value === 'R$-Infinity' ? 'R$0' : value}
      </h4>

      <p>
        Variação:{' '}
        <strong
          style={{
            color:
              variation < 0 ? 'var(--red-primary)' : 'var(--green-primary)',
          }}
        >
          {variation && variation.toFixed(2)}%
        </strong>{' '}
        {variation < 0 ? (
          <RiArrowDownSLine size={16} color="var(--red-primary)" />
        ) : (
          <RiArrowUpSLine size={16} color="var(--green-primary)" />
        )}
      </p>
    </Container>
  );
};

export default Card;
