import styled from 'styled-components';

export const Container = styled.div`
  .check {
    display: flex;
    align-items: center;
    margin-top: 0.3rem;

    form.input {
      button {
        color: var(--white);
      }

      div + button {
        background-color: ${({ theme }) => theme.backgroundPrimary} !important;

        p {
          color: ${({ theme }) => theme.text};
        }
      }
    }

    #post {
      width: 7.2rem;
      height: 1.8rem;
      font: 600 0.3rem Work Sans;
    }

    p {
      font-size: 1rem;
      color: ${({ theme }) => theme.text};
      margin-left: 0.5rem;
      cursor: pointer;
      transition: 0.3s;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */

      :hover {
        color: ${({ theme }) => theme.text};
        opacity: 0.4;
      }
    }

    svg {
      cursor: pointer;
      transition: 0.3s;

      :hover {
        opacity: 0.7;
      }
    }
  }

  .check:first-child {
    margin-top: 0;
  }

  .input {
    display: flex;
    align-items: center;

    input {
      margin: 0;
      margin-top: 0 !important;
    }

    button {
      padding: 0;
      margin: 0;
      margin-left: 0.2rem;
    }
  }

  .delete-btn {
    margin-left: 0.5rem;
  }
`;

export const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  min-height: 1rem;
  min-width: 1rem;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.backgroundTerciary};
  border-radius: 4px;
  border: solid 1px var(--gray);
  cursor: pointer;

  #checkline {
    color: ${({ theme }) => theme.text};
  }
`;
