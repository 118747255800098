import { cloneDeep } from 'lodash';
import selectGroupIndexById from './SelectGroupIndexById';
import selectProjectIndexById from './SelectProjectIndexById';

export const editGroupDetails = (
  users: User[],
  dueDate: string,
  progress: number,
  projectId: number,
  groupId: number,
  projects: Project[]
): Project[] => {
  const newGlobalProjects = cloneDeep(projects);

  const projectIndex = selectProjectIndexById(projectId, projects);

  if (projectIndex === -1) return [];

  const selectedProject = newGlobalProjects[projectIndex];

  if (!selectedProject.groups) return [];

  const groupIndex = selectGroupIndexById(projectIndex, groupId, projects);

  selectedProject.groups[groupIndex].due_date = dueDate;

  selectedProject.groups[groupIndex] = {
    ...selectedProject.groups[groupIndex],
    users,
    due_date: dueDate,
    progress,
  };

  return newGlobalProjects;
};
