import React from 'react';
import { BigCard, BigCardContainer } from './styles';

export default function BigDataCard({ title, value, color, pin1, pin2, pin3 }) {
  return (
    <BigCardContainer style={{ position: 'relative' }}>
      {pin1 !== undefined ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            background: '#1a1c1e',
            borderRadius: '3px',
            padding: '4px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'default',
          }}
          whileHover={{ scale: 1.3 }}
        >
          <p style={{ fontWeight: 'bold', color: '#f6f6f6', fontSize: '12px' }}>
            {pin1}
          </p>
        </div>
      ) : (
        <> </>
      )}

      {pin2 !== undefined ? (
        <div
          style={{
            position: 'absolute',
            top: 20,
            right: 0,
            background: '#1a1c1e',
            borderRadius: '3px',
            padding: '4px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'default',
          }}
          whileHover={{ scale: 1.3 }}
        >
          <p style={{ fontWeight: 'bold', color: '#f6f6f6', fontSize: '12px' }}>
            {pin2}
          </p>
        </div>
      ) : (
        <> </>
      )}

      {pin3 !== undefined ? (
        <div
          style={{
            position: 'absolute',
            top: 40,
            right: 0,
            background: '#1a1c1e',
            borderRadius: '3px',
            padding: '4px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'default',
          }}
          whileHover={{ scale: 1.3 }}
        >
          <p style={{ fontWeight: 'bold', color: '#f6f6f6', fontSize: '12px' }}>
            {pin3}
          </p>
        </div>
      ) : (
        <> </>
      )}

      <BigCard>
        <p style={{ color }}>{title}</p>
        <p style={{ color }}>{value}</p>
      </BigCard>
    </BigCardContainer>
  );
}
