import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: ${({ theme }) => theme.text};
  padding: 2rem;
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background: ${({ theme }) => theme.backgroundPrimary};

  header {
    margin-bottom: 2rem;

    p {
      color: ${({ theme }) => theme.text};
    }
  }

  header .text {
    display: flex;
    font-weight: bold;
    font-size: 1.5rem;
  }

  header img {
    width: 6rem;
    margin-bottom: 0.5rem;
  }

  .children {
    padding: 1.2rem;
    z-index: 1;
  }

  .red {
    color: var(--red-primary);
  }
`;

export const Footer = styled.footer`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.backgroundPrimary};

  p {
    color: ${({ theme }) => theme.text};
  }
`;

export const LogoHeader = styled.div`
  width: 5.5rem;
  min-height: 38px;
  background-image: ${({ theme }) => theme.logo};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const LogoFooter = styled.div`
  width: 5.5rem;
  margin-left: 0.5rem;
  min-height: 38px;
  background-image: ${({ theme }) => theme.checkinLogo};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
