import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.text};
  border-radius: 8px;
  letter-spacing: 0.96px;

  margin-bottom: 1rem;
  margin-top: 1rem;

  .pageTitle {
    margin-top: 1.6rem;
    font-size: 0.9rem;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 1.6rem;
  }

  .pageTitle h1:last-child {
    color: var(--red-primary);
  }

  .results div:last-child {
    margin-right: 0rem;
  }

  .results {
    display: flex;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  }

  .continueButton {
    float: right;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .transparent {
    background: transparent;
    height: 4rem;
  }

  input {
    margin-top: 0.5rem;
  }
`;

export const HeaderBar = styled.div`
  margin: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.84px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;

  hr {
    margin-top: 0.8rem;
    border: solid 1px ${({ theme }) => theme.backgroundTerciary};
  }
`;
