import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useWhiteMode } from '../../hooks/useWhiteMode';
import { lightTheme, darkTheme } from '../../styles/themes';

const ThemeContext: React.FC = ({ children }) => {
  const { theme } = useWhiteMode();

  const themeMode = theme === 'dark' ? darkTheme : lightTheme;

  return <ThemeProvider theme={themeMode}>{children}</ThemeProvider>;
};

export default ThemeContext;
