/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import Popup from 'reactjs-popup';

import { MediumCard } from './styles';

export default function Card({
  title,
  title2,
  value,
  value2,
  pin1 = '0%',
  pin2 = '0%',
  color,
  tooltip1,
  tooltip2,
}) {
  return (
    <div style={{ position: 'relative' }}>
      <Popup
        className="tooltip"
        arrow={false}
        position="left"
        contentStyle={{
          width: '120px',
        }}
        on="hover"
        trigger={
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              background: '#1a1c1e',
              borderRadius: '3px',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'default',
              opacity: '70%',
            }}
            whileHover={{ scale: 1.3 }}
          >
            <p
              style={{
                fontWeight: 'bold',
                color: '#f6f6f6',
                fontSize: '12px',
              }}
            >
              {pin1}
            </p>
          </div>
        }
      >
        <p style={{ marginTop: '20px' }}>{tooltip1}</p>
      </Popup>

      <Popup
        className="tooltip"
        on="hover"
        arrow={false}
        position="left"
        contentStyle={{
          width: '120px',
        }}
        trigger={
          <div
            style={{
              position: 'absolute',
              top: 20,
              right: 0,
              background: '#1a1c1e',
              borderRadius: '3px',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'default',
              opacity: '70%',
            }}
            whileHover={{ scale: 1.3 }}
          >
            <p
              style={{ fontWeight: 'bold', color: '#f6f6f6', fontSize: '12px' }}
            >
              {pin2}
            </p>
          </div>
        }
      >
        <p>{tooltip2}</p>
      </Popup>
      <MediumCard>
        <div className="cardData">
          <p>{title}</p>
          <p style={{ color }}>{value}</p>
        </div>
        <div className="cardData">
          <p>{title2}</p>
          <p style={{ color }}>{value2}</p>
        </div>
      </MediumCard>
    </div>
  );
}
