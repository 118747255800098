import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  section:first-of-type {
    width: 31rem;
  }
`;

export const Content = styled.div`
  width: 97%;
`;
