import styled from 'styled-components';

export const Card = styled.article`
  background-color: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 384px;
  margin-right: 2vh;
  min-width: 267px;
  padding: 2vh;
  position: relative;
  width: 23%;

  &::after {
    background-color: ${props => props.color};
    border-radius: 0px 0px 8px 8px;
    bottom: 0vh;
    content: '';
    left: 0;
    min-height: 8px;
    min-width: 200px;
    position: absolute;
    width: 100%;
  }
`;

export const Title = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5vh;
  justify-content: space-between;

  img {
    height: 0.875rem;
    width: 0.875rem;
  }

  svg {
    cursor: pointer;
    font-size: 0.875rem;
  }

  input {
    width: 75%;
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.text};
    flex: 1;
    font-size: 0.8rem;
    letter-spacing: 2.52px;
    line-height: 14px;
    margin: 0 1vh;
  }
  button {
    margin: 0 0 0 1vh;
  }
`;

export const Label = styled.label`
  color: var(--red-primary);
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-size: 0.625rem;
  letter-spacing: 1.8px;

  & > div {
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  div .select-search {
    font-size: 0.625rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.8px;
  }

  div .select-search .select-search__value input {
    height: auto;
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.backgroundSecondary};
    letter-spacing: 1.8px;
    font-size: 0.625rem;
    font-family: 'Poppins', sans-serif;
    margin-top: 1vh;
    height: 2rem;

    &::placeholder {
      color: var(--red-primary);
      letter-spacing: 1.8px;
      font-size: 0.625rem;
      font-family: 'Poppins', sans-serif;
    }
  }
`;

export const Users = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 50px;
  align-items: center;
`;

export const Subtasks = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 2vh;
  overflow: auto;

  button {
    align-self: flex-start;
  }
`;
export const Subtitle = styled.h4`
  color: ${({ theme }) => theme.text};
  font-size: 0.875rem;
  font-weight: normal;
  letter-spacing: 0.84px;
  line-height: 13px;
  margin-bottom: 0.5vh;
`;
