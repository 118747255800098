import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const Main = styled.main`
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  width: 100vw;
  height: 94.5vh;
  padding: 0px 1.25rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.backgroundPrimary};

  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.text};
  }
`;
