import React from 'react';
import { Container } from './styles';

export default function LittleCard({ color, title, value = 'Carregando...' }) {
  return (
    <Container>
      <p style={{ color: '#f6f6f6' }}>{title}</p>
      <p style={{ color }}>{value}</p>
    </Container>
  );
}
