import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  > div {
    max-height: 80vh;
  }
  button {
    margin: 0 !important;
  }

  main {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem;

    ::-webkit-scrollbar {
      height: 0.4rem;
      border-radius: 0.4rem;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.text};
    }
  }

  #confirm-button {
    background: var(--green-primary);
    width: 7rem;
    height: 2.1rem;
    font: bold 0.9rem Work Sans;
    color: var(--white);
  }
`;
