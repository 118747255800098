import React from 'react';
import { useImportPlaybook } from '../../../../../../../../contexts/ImportPlaybookContext';

import CloseButton from '../../../components/CloseButton';

import { LabelContainer } from '../../../styles/PhaseChecklistItemContainer';

interface ChecklistItemProps {
  checklistItem: IPhaseChecklistItem;
  projectId: number;
  groupId: number;
  phaseId: number;
}

const PhaseChecklistItem: React.FC<ChecklistItemProps> = ({
  checklistItem,
  projectId,
  groupId,
  phaseId,
}) => {
  const {
    updatePhaseChecklistItemName,
    deletePhaseChecklistItem,
  } = useImportPlaybook();

  return (
    <LabelContainer htmlFor={checklistItem.name}>
      <input type="checkbox" disabled />
      <input
        name={checklistItem.name}
        defaultValue={checklistItem.name}
        type="text"
        onBlur={e => {
          updatePhaseChecklistItemName(
            projectId,
            groupId,
            phaseId,
            checklistItem.id,
            e.target.value
          );
        }}
      />
      <CloseButton
        onClick={() => {
          deletePhaseChecklistItem(
            projectId,
            groupId,
            phaseId,
            checklistItem.id
          );
        }}
        disabled={false}
      />
    </LabelContainer>
  );
};

export default PhaseChecklistItem;
