import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background: var(--red-primary);
  bottom: 9rem;
  right: 0.8rem;
  border-radius: 0.8rem;
  margin-bottom: 0.3rem;
  color: var(--white);

  :before {
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid var(--red-primary);
    bottom: -0.8rem;

    content: '';
    left: 42%;

    position: absolute;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  .item-menu {
    width: 100%;
    align-items: center;
    margin-bottom: 0.6rem;
    display: flex;
    border: none;
    background: none;
    color: var(--white);
    padding-bottom: 0.5rem;

    p {
      text-align: left;
      margin-left: 0.5rem;
    }

    border-bottom: solid 1px var(--red-primary);
  }

  .item-menu:last-child {
    margin-bottom: 0rem;
  }
`;
