import React, { useState, useCallback } from 'react';
import {
  RiSeedlingLine,
  RiEditBoxLine,
  RiStarLine,
  RiCheckLine,
} from 'react-icons/ri';

// import  PreviewTaskPhaseCard from '../../../../../components/PreviewTaskPhaseCard';
import Responsible from '../../../../../components/Responsible';
import Checklist from '../../../../../components/Checklist';

interface PhaseCardTypes {
  type?: 'initial' | 'intermediary' | 'final';
  phase: Phase;
}

const PhaseCard: React.FC<PhaseCardTypes> = ({ type = 'intermediary' }) => {
  const [users, setUsers] = useState([{}]);
  const [checks, setChecks] = useState([{ name: '', marked: false }]);

  const handleColor = useCallback(cardType => {
    if (cardType === 'initial') {
      return 'var(--green-primary)';
    }
    if (cardType === 'intermediary') {
      return 'var(--yellow-primary)';
    }
    return 'var(--cyan-primary)';
  }, []);

  return (
    <div color={handleColor(type)}>
      <header>
        {type === 'initial' && (
          <RiSeedlingLine color="var(--green-primary)" size={19} />
        )}
        {type === 'intermediary' && (
          <RiStarLine color="var(--yellow-primary)" size={19} />
        )}
        {type === 'final' && (
          <RiCheckLine color="var(--cyan-primary)" size={19} />
        )}
        <p style={{ fontSize: '0.8rem' }}>phase.name</p>
        <RiEditBoxLine color="var(--gray)" size={15} />
      </header>
      <main>
        <div className="input">
          <p>Responsável</p>

          <Responsible users={users} setUsers={setUsers} />
        </div>

        <div className="input">
          <p style={{ marginBottom: '0.6rem' }}>Checklist</p>
          <Checklist checks={checks} setChecks={setChecks} />
        </div>
      </main>
    </div>
  );
};

export default PhaseCard;
