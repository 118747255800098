import styled from 'styled-components';

export const ScrollContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  padding: 0 1rem;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 0;

  .reAprove {
    background: var(--red-primary);
  }

  ::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.backgroundSecondary};
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 10px;
  }

  .rdrCalendarWrapper {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;
  }

  .rdrDayNumber span {
    color: var(--white) !important;
  }

  .rdrNextPrevButton {
    background: var(--gray);

    i {
      border-color: transparent var(--white) transparent transparent;
    }
  }
  span + button.rdrNextPrevButton {
    i {
      border-color: transparent transparent transparent var(--white);
    }
  }

  .rdrDateDisplayWrapper {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;
  }

  .rdrDateDisplayItem {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;

    input {
      color: ${({ theme }) => theme.text};
    }
  }

  .rdrMonth {
    background-color: ${({ theme }) => theme.backgroundSecondary} !important;
    color: var(--white) !important;
  }

  .table {
    margin-top: 20px;
    max-height: 200px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.backgroundSecondary};
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 10px;
    }
  }

  .popup-content .tooltip-content {
    background: var(--gray) !important;
    border: 0 !important;
    box-shadow: 0 !important;
    padding: 0px !important;
  }

  .popUp-tooltip-content {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.backgroundPrimary};
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .yes {
      color: var(--green-primary);
    }

    .no {
      color: var(--red-primary);
    }
  }

  .multi-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    --rmsc-main: ${({ theme }) => theme.backgroundSecondary};
    --rmsc-hover: var(--red-primary);
    --rmsc-bg: ${({ theme }) => theme.backgroundSecondary} !important;
    --rmsc-selected: ${({ theme }) => theme.backgroundSecondary};
    --rmsc-border: ${({ theme }) => theme.backgroundSecondary};
    --rmsc-gray: ${({ theme }) => theme.text};
    --rmsc-p: 8px; /* Spacing */
    --rmsc-radius: 3px; /* Radius */
    --rmsc-h: 33px; /* Height */

    color: ${({ theme }) => theme.text} !important;
    transition: 0.3s !important;
    flex: 1;
    min-width: 20vh;
    font-size: 0.7rem !important;
    cursor: pointer;

    :hover {
      --rmsc-border: var(--red-primary);

      background: var(--red-primary);
    }

    svg {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }

    .dropdown-heading {
      cursor: pointer;
    }
  }

  .panel-content {
    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.backgroundSecondary};
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 10px;
    }
  }

  .go1084040746 {
    cursor: pointer !important;
  }

  .go606532123 {
    input {
      background: ${({ theme }) => theme.backgroundSecondary};
    }
  }

  header {
    p {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      color: ${({ theme }) => theme.text};
    }
  }

  .header-text {
    margin: 8px 0 0;
    color: ${({ theme }) => theme.text};
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  input[type='date'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex: 1;
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 2px;
    border: 0;
    padding: 8px 12px;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
    font-family: Arial, Helvetica, sans-serif;
  }

  .hideButton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex: 1;
    background: transparent;
    border-radius: 2px;
    border: 0;
    padding: 0.4rem;
    border-radius: 10%;
    margin-left: 0.3rem;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex: 1;
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 2px;
    border: 0;
    padding: 12.2px 12px;
    font-size: 0.7rem;
    color: ${({ theme }) => theme.text};
    cursor: pointer;
    transition: 0.3s;
    width: 100%;

    option {
      background: var(--gray);
    }

    :hover {
      background: var(--red-primary);
      color: var(--white);
    }
  }

  .button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex: 1;
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 2px;
    border: 0;
    padding: 12.2px 12px;
    font-size: 0.7rem;
    color: ${({ theme }) => theme.text};
    cursor: pointer;
    transition: 0.3s;

    :hover {
      background: var(--red-primary);
      color: var(--white);
    }
  }

  .off-button {
    display: none;
  }

  .options {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(5, 19.4%);

    @media (max-width: 958px) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(3, 33.3%);
    }

    @media (max-width: 768px) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(2, 50%);
    }

    @media (max-width: 475px) {
      grid-template-rows: repeat(1, auto);
      grid-template-columns: repeat(1, 100%);
    }
  }

  .option {
    width: 100%;

    .date {
      flex: 1;
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 2px;
      border: 0;
      display: flex;
      justify-content: center;
      padding: 8px 12px;
      color: ${({ theme }) => theme.text};
      font-size: 1rem;
      margin-right: 8px;
      font-family: Work Sans;
      text-align: center;
      font-style: normal;
      cursor: pointer;
      transition: 0.3s;

      :hover {
        background: var(--red-primary);
        color: var(--white);
      }
    }
  }

  .clientsSelect {
    width: 100%;

    .outer-div {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .search-div {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid ${({ theme }) => theme.backgroundSecondary};
      border-radius: 3px 0 0 3px;

      input {
        width: 92%;
        height: 65%;
        padding: 5px;
        border: none;
        border-radius: 4px;
        background: ${({ theme }) => theme.backgroundSecondary};
        color: var(--white);
      }
    }

    .select-div {
      width: 67%;

      select {
        border-radius: 0 3px 3px 0;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .more-button {
    background: ${({ theme }) => theme.backgroundSecondary};
    align-items: center;
    display: flex;
    padding-right: 5px;
    padding-left: 5px;
    cursor: pointer;
    transition: 0.3s;

    :hover {
      background: var(--red-primary);
      color: var(--white);
    }
  }

  .option-selectors {
    display: flex;
    flex-direction: row;
  }

  main {
    width: 100%;

    tr {
      border-bottom: 1px solid var(--gray);
    }

    .checkbox-tableroll {
      border-bottom: 0px !important;
    }

    .FiX {
      color: ${({ theme }) => theme.text};
      transition: 0.3s;
      cursor: pointer;

      :hover {
        color: var(--red-primary);
      }
    }

    .FiCheck {
      color: ${({ theme }) => theme.text};
      transition: 0.3s;
      cursor: pointer;

      :hover {
        color: var(--green-primary);
      }
    }

    .table-content {
      transition: 0.3s;
      cursor: pointer;

      :hover {
        filter: brightness(70%);
      }
    }

    td {
      padding: 8px;
      justify-content: center;
      align-items: center;
    }

    .checkbox-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .table-container {
      background-color: var(--red-primary);
      margin-top: 1rem;
      border-radius: 5px;
      width: 100%;
      position: relative;
    }

    .delete-selected {
      position: absolute;
      top: 14.5px;
      right: 20px;

      background-color: var(--white);
      color: var(--red-primary);
      border: 0;
      border-radius: 5px;
      padding: 5px 12px;

      display: flex;
      align-items: center;
      gap: 5px;

      :hover {
        transform: scale(1.04);
        transition: 0.25s;
      }

      :not(:hover) {
        transition: 0.2s;
      }
    }

    h1 {
      color: var(--white);
      font-size: 25px;
      font-weight: normal;
      padding: 10px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      color: ${({ theme }) => theme.text};
      letter-spacing: 0px;
      background-color: ${({ theme }) => theme.backgroundSecondary};
      padding: 6px;
      font-size: 0.9rem;
      letter-spacing: 0px;
      padding-top: 15px;
      margin-bottom: 20px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;

      .table-header {
        color: ${({ theme }) => theme.text};
        background: ${({ theme }) => theme.backgroundSecondary};

        td {
          color: ${({ theme }) => theme.text};

          button {
            color: ${({ theme }) => theme.text};
            font-weight: bold;
            background: transparent;
          }
        }
      }
    }

    tbody button {
      flex: 1;
      background: ${({ theme }) => theme.backgroundPrimary};
      border-radius: 2px;
      border: 0;
      padding: 5px, 0px, 0px;
      color: ${({ theme }) => theme.text};
      font-size: 16px;
      font-family: Work Sans;
      font-style: normal;
    }

    tbody button.ascending::after {
      content: '❯';
      transform: rotate(90deg);
      display: inline-block;
      margin-left: 1em;
    }

    tbody button.descending::after {
      content: '❮';
      transform: rotate(90deg);
      display: inline-block;
      margin-left: 1em;
    }
  }

  .table-download-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex: 1;
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 2px;
    border: 0;
    padding: 8px 12px;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
    font-family: Arial, Helvetica, sans-serif;
    transition: 0.3s;

    :hover {
      color: var(--white);
      background-color: var(--red-primary);
    }
  }

  .popUp {
    color: ${({ theme }) => theme.text};

    .header-p {
      font-size: 18px;
      color: ${({ theme }) => theme.text};
      margin-bottom: 20px;
    }

    .header-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .inputs {
      display: grid;
      grid-template-columns: repeat(3, 2fr);
      grid-gap: 14px;

      .input {
        display: flex;
        flex-direction: column;

        label {
          color: ${({ theme }) => theme.text};
          font-size: 16px;
          margin-bottom: 2px;
          margin-top: 2px;
        }

        select {
          width: 100%;
        }

        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          flex: 1;
          background: ${({ theme }) => theme.backgroundSecondary};
          border-radius: 2px;
          border: 0;
          padding: 8px 12px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      label {
        color: ${({ theme }) => theme.text};
        font-size: 16px;
        margin-bottom: 2px;
        margin-top: 2px;
      }

      textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        flex: 1;
        background: ${({ theme }) => theme.backgroundSecondary};
        border-radius: 2px;
        border: 0;
        padding: 8px 12px;
        font-size: 16px;
        color: ${({ theme }) => theme.text};
        font-family: Arial, Helvetica, sans-serif;
      }
    }

    .button {
      margin: 5px;
      padding: 8px 12px;
    }

    .selected {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      flex: 1;
      border-radius: 2px;
      border: 0;
      padding: 4px 12px;
      font-size: 16px;
      transition: 0.3s;
      background: var(--red-primary);
      color: var(--white);
      margin: 5px;
    }

    footer {
      button {
        transition: 0.3s;

        :hover {
          filter: brightness(70%);
        }
      }
    }
  }

  .select-search {
    width: 100% !important;
  }

  .select-search__input {
    height: 2.5rem !important;
    font: normal 1rem Work Sans !important;
  }
`;

export const Circle = styled.div`
  display: flex;
  flex: 1;
  height: 10px;
  width: 10px;
  border-radius: 20%;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  transition: 0.3s;

  :hover {
    background-color: var(--red-primary);
    filter: brightness(90%) !important;
  }
`;
