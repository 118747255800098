import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function editProject(
  projectId: number,
  projectName = '',
  projectDesc = '',
  tagsIds: number[]
): Promise<Project> {
  try {
    const response = await apiV2.put(`projects`, {
      include_project_group_progress: true,
      id: projectId,
      name: projectName,
      desc: projectDesc,
      tags_ids: tagsIds,
    });

    const project = response.data;

    return project;
  } catch (err) {
    toast.error(
      'Ocorreu algum problema ao editar o projeto, atualize a página e tente novamente 😕'
    );
    return {} as Project;
  }
}
