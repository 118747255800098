import styled from 'styled-components';

export const Container = styled.div`
  .graphics {
    display: flex;
    max-height: 17vh;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  .graphics .donuts {
    display: flex;
  }

  .list:first-child {
    margin-right: 2rem;
  }

  .doneInLastCheckin {
    display: flex;
    width: 100%;
  }

  .doneInLastCheckin .list {
    height: 50vh;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .tasks {
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: auto;
    width: 100%;
    margin-bottom: 1rem;
  }

  .doneInLastCheckin .list .title {
    margin-bottom: 0.7rem;
    font-size: 1rem;
    font-weight: bold;
  }

  .task {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background: ${({ theme }) => theme.backgroundSecondary};
    color: ${({ theme }) => theme.text};
    padding: 1rem;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .task div p:first-child {
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
    color: var(--gray);
  }

  .task .rightSideOfTask {
    display: flex;
    align-items: center;
  }

  .task .rightSideOfTask svg {
    margin-left: 0.6rem;
    cursor: pointer;
  }

  .task .doneDate {
    color: var(--white) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0.4rem 1rem;
    background: var(--gray);
    margin-left: 0.5rem;
    border-radius: 8px;
  }
  .popup-content {
    background: ${({ theme }) => theme.backgroundSecondary};
  }
`;

export const Modal = styled.div`
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgb(0, 0, 0, 0.3);
  cursor: initial;
`;
