import React, { useState, useCallback } from 'react';
import Popup from 'reactjs-popup';

import { RiEditLine } from 'react-icons/ri';

import * as S from './styles';
import { TextInput, Button } from '../../../../../components/Inputs';

interface EditModal {
  editCard: (
    newCard: { name: string; id: number; value: number },
    update: boolean
  ) => void;
  card: { name: string; id: number; value: number };
}

const Edit: React.FC<EditModal> = ({ editCard, card }) => {
  const [newName, setNewName] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      editCard({ name: newName, id: card.id, value: card.value / 100 }, true);
      setIsOpen(false);
    },
    [card, editCard, newName]
  );

  return (
    <>
      <div>
        <RiEditLine
          onClick={() => setIsOpen(!isOpen)}
          size={16}
          color="var(--gray)"
        />
      </div>
      <Popup
        open={isOpen}
        arrow={false}
        position="bottom center"
        contentStyle={{
          width: '15rem',
          background: 'transparent',
          border: 0,
          borderRadius: '8px',
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <S.EditCard onSubmit={e => handleSubmit(e)}>
          <header>
            <RiEditLine size={16} color="var(--red-primary)" />
            <p>Renomear</p>
          </header>
          <main>
            <TextInput
              height="1.8rem"
              placeholder="Digite Aqui"
              value={newName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewName(e.target.value);
              }}
            />
          </main>
          <footer>
            <Button
              background="var(--green-primary)"
              width="100%"
              height="1.6rem"
              text="Confirmar"
              font="400 0.9rem Work Sans"
              type="submit"
            />
          </footer>
        </S.EditCard>
      </Popup>
    </>
  );
};

export default Edit;
