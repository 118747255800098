import styled from 'styled-components';

import { ReactComponent as HighAtomo } from '../../../../assets/images/top-atomo.svg';
import { ReactComponent as LowAtomo } from '../../../../assets/images/bottom-atomo.svg';

export const Container = styled.div<{ selected: number | undefined }>`
  display: flex;
  place-content: center;
  background: ${({ theme }) => theme.backgroundPrimary};
  height: 100%;
  transition: 0.5s;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
  }

  main {
    z-index: 1;
    height: 100%;
    max-width: 700px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 2rem;

    overflow-y: auto;
    transition: 0.5s;

    > div:first-child {
      margin-bottom: 2.5rem;
      transition: 0.5s;

      @media (max-width: 1000px) {
        margin-bottom: 1rem;
      }
    }

    header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-bottom: 1.4rem;
      transition: 0.5s;
    }

    header p {
      font-size: 1.4rem;
      font-weight: bold;
      color: var(--red-primary);
    }

    header p:first-child {
      color: ${({ theme }) => theme.text};
    }

    @media (max-width: 1000px) {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      padding: 1rem 1rem;
      text-align: center;
      transition: 0.5s;

      div:first-child {
        display: flex;
        flex-direction: column;
        place-content: center;
      }
    }
  }

  .textareaholder {
    width: 100%;
    border-radius: 8px;
    background: ${({ theme }) => theme.backgroundSecondary};
    padding: 1rem;

    textarea {
      width: 100%;
      resize: none;
      min-height: 7rem;
      border-radius: 8px;
      border: 0;
      outline: 0;
      padding: 1rem;
      background: ${({ theme }) => theme.backgroundTerciary};
      color: ${({ theme }) => theme.text};
      font: 400 0.8rem Work Sans;
    }

    textarea::placeholder {
      color: ${({ theme }) => theme.text};
      opacity: 70%;
    }
  }

  .textholder {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
      margin-top: 1rem;
    }
  }

  .cardRating {
    display: flex;

    align-items: center;
    justify-content: center;
    transition: 0.5s;

    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .atoms {
    @media (max-width: 1000px) {
      opacity: 0;
    }
  }
`;

export const LogoHeader = styled.div`
  width: 5.5rem;
  left: 20%;
  top: 5%;

  position: absolute;
  min-height: 38px;
  background-image: ${({ theme }) => theme.logo};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1367px) {
    margin-bottom: 1rem;
  }

  @media (max-width: 1000px) {
    opacity: 0;
  }
`;

export const LogoFooter = styled.div`
  width: 6rem;
  left: 80%;
  top: 88%;
  position: absolute;

  min-height: 38px;
  background-image: ${({ theme }) => theme.checkinLogo};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  :after {
    position: absolute;
    right: 120%;
    top: 15%;
    font-size: 0.8rem;
    color: var(--white);
    content: 'Powered By';
  }

  @media (max-width: 1000px) {
    opacity: 0;
  }
`;

export const TopAtomo = styled(HighAtomo)`
  position: absolute;
  top: -6%;
  right: -4%;
`;

export const BottomAtomo = styled(LowAtomo)`
  position: absolute;
  bottom: -6%;
  left: -4%;
`;
