/* eslint-disable no-restricted-syntax */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import Loading from '../../../../components/Loadings/Loading';

import apiV2 from '../../../../services/apiV2';
import api from '../../../../services/api';

import PopUp from '../../../../components/PopUp';
import { Button } from '../../../../components/Inputs';
import { OptionsSelect } from '../../../../components/Inputs/OptionsSelect';

import CheckinCard from './CheckinCard';

import * as S from './styles';
import { Params, CheckinObject } from '../../types';
import { Okrs } from '../../components/KrsAndOkrs/types';

interface Project {
  name: string;
  value: string;
}

const CheckinSelect: React.FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();

  const [clientId, setClientId] = useState(null);
  const [projectId, setProjectId] = useState('');
  const [checkins, setCheckins] = useState<CheckinObject[]>([]);
  const [checkinDraft, setCheckinDraft] = useState<CheckinObject[]>([]);
  const [clientName, setClientName] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isLastQuarter, setIsLastQuarter] = useState(false);
  const [showCreateDraft, setShowCreateDraft] = useState(false);
  const [projectsMessage, setProjectsMessage] = useState('Carregando...');
  const projects = useMemo((): Project[] => {
    return [];
  }, []);

  const handleGetProjects = useCallback(
    async client => {
      await apiV2.get(`projects/projectsNameId/${client}`).then(res => {
        res.data.forEach((project: { name: string; value: number }) => {
          projects.push({ name: project.name, value: String(project.value) });
        });

        if (res.data.length) setProjectsMessage('Escolha o projeto');
        else setProjectsMessage('Sem Projetos!');
      });
    },
    [projects]
  );

  const handleGetCheckins = useCallback(async () => {
    await apiV2
      .get(`quarter/${id}`)
      .then(async res => {
        setCheckins(res.data.checkins);
        setClientId(res.data.client_id);

        await api.get(`clients/${res.data.client_id}`).then(response => {
          if (response.data) setClientName(response.data.name);
        });

        await apiV2
          .get(`quarter/byClient/${res.data.client_id}`)
          .then(response => {
            const firstItemOfArray = response.data[0];
            if (firstItemOfArray.quarter.id === Number(id)) {
              setIsLastQuarter(true);
            }
          });

        await handleGetProjects(res.data.client_id);

        await apiV2
          .get(`checkinDraft?clientId=${res.data.client_id}&isActive=true`)
          .then(response => {
            setCheckinDraft(response.data);

            setIsLoading(false);
          })
          .catch(() => {
            toast.error('Falha ao buscar drafts.');
          });
      })
      .catch(() => {
        toast.error('Falha ao carregar dados do checkin.');
      });
  }, [id, handleGetProjects]);

  const handleNewDraft = useCallback(async () => {
    if (!projectId) {
      toast.error('Selecione um projeto ou crie um!');
    } else {
      setIsLoading(true);
      let draftId = 0;
      let draftOkrs: Okrs[] = [];
      let clientOkrs: Okrs[] = [];

      await apiV2
        .post('/checkinDraft', {
          name: `${format(new Date(), 'dd.MM.yyyy')}`,
          client_id: clientId,
          project_id: projectId,
        })
        .then(res => {
          draftId = res.data.id;
          draftOkrs = res.data.okrs;
        })
        .catch(err => {
          if (
            err.response.data ===
            "You can't create a new draft for a project if one already exists."
          ) {
            toast.error('Já existe um checkin com este projeto!.');
          } else toast.error('Falha ao tentar criar novo checkin.');

          setIsLoading(false);
        });

      if (checkins.length === 0) {
        await apiV2
          .get(`okr?clientId=${clientId}&isActive=true`)
          .then(res => {
            clientOkrs = res.data;
          })
          .catch(() => {
            toast.error('Erro ao linkar Okrs!');
          });

        const okrsDraftIds = draftOkrs.map(e => e.id);
        const okrsToAdd = clientOkrs.filter(e => !okrsDraftIds.includes(e.id));

        for await (const okr of okrsToAdd) {
          await apiV2
            .put('okr/addToDraft', {
              okrId: okr.id,
              checkinDraftId: draftId,
            })
            .catch(() => {
              toast.error('Falha ao adicionar okrs para o draft');
            });
        }
      }
      history.push(`/checkin/create_new/${draftId}`);
      setIsLoading(false);
    }
  }, [clientId, history, projectId, checkins.length]);

  const handleEditLink = useCallback(
    async (link, checkinId) => {
      await apiV2
        .put('checkin/updatePresentationLink', {
          checkinId,
          presentation_link: link,
        })
        .then(() => {
          const checkinIndex = checkins.findIndex(
            checkin => checkinId === checkin.id
          );

          const newCheckins = checkins;
          newCheckins[checkinIndex].presentation_link = link;
          setCheckins([...newCheckins]);
        })
        .catch(() => {
          toast.error('Erro ao salvar novo link!');
        });
    },
    [checkins]
  );

  useEffect(() => {
    handleGetCheckins();
  }, [handleGetCheckins, handleGetProjects]);

  useEffect(() => {
    checkins.sort((a, b) => {
      if (new Date(a.created_at) > new Date(b.created_at)) {
        return -1;
      }
      if (new Date(a.created_at) < new Date(b.created_at)) {
        return 1;
      }
      return 0;
    });
  }, [checkins]);

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container>
      {showCreateDraft && (
        <PopUp width="40%" header="" closePopUp={setShowCreateDraft}>
          <S.NewDraft>
            <p>Projeto:</p>

            <OptionsSelect
              placeholder={projectsMessage}
              options={projects}
              search
              onChange={setProjectId}
            />

            <Button
              text="CRIAR NOVO CHECKIN"
              background="var(--red-primary)"
              width="100%"
              height="2rem"
              font="700 0.8rem Work Sans"
              className="link"
              onClick={() => handleNewDraft()}
            />
          </S.NewDraft>
        </PopUp>
      )}

      <p>{clientName}</p>

      <header>
        <div className="pageTitle">
          <h1>SELECIONE O</h1>
          <h1>CHECK-IN OU CRIE UM NOVO</h1>
        </div>

        {clientId !== null && (
          <div className="headerButtons">
            <Link to={`/checkin/quarters/${clientId}/0`}>
              <Button
                background="var(--red-primary)"
                width="14rem"
                height="1.8rem"
                font="400 0.8rem Work Sans"
                text="VER TODOS OS QUARTERS"
              />
            </Link>

            <Button
              background="var(--green-primary)"
              width="14rem"
              height="1.8rem"
              font="400 0.8rem Work Sans"
              text="ADICIONAR NOVO CHECKIN"
              onClick={() => setShowCreateDraft(true)}
            />
          </div>
        )}
      </header>

      <main>
        <div>
          <strong>Checkins em aberto</strong>
          {isLastQuarter &&
            checkinDraft.map(draft => {
              return <CheckinCard isDraft showName infos={draft} />;
            })}

          <hr />
        </div>
        {checkins.map(checkin => {
          return (
            <CheckinCard handleEditLink={handleEditLink} infos={checkin} />
          );
        })}
      </main>
    </S.Container>
  );
};

export default CheckinSelect;
