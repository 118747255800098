import styled from 'styled-components';
import BackgroundImage from '../../../../assets/images/backgroundImg.webp';

export const Container = styled.div`
  display: flex;
  height: 84vh;
  max-width: 630px;
  max-height: 484px;
  margin: 13vh auto;
  box-shadow: 0px 0px 58px -2px var(--red-primary);
  border-radius: 8px;
  position: relative;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-clip: padding-box;
  background-position: initial;
  z-index: 0;
`;

export const ImgContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;
  background-color: ${({ theme }) => theme.lowOpacity};
  z-index: 1;
`;

export const Name = styled.h1`
  margin-top: 3vh;
  font-size: 1.7rem;
  font-weight: bold;
  letter-spacing: 0.84px;
  line-height: 26px;
  color: ${({ theme }) => theme.text};
  max-width: 75%;
  text-align: center;
`;

export const JobTitle = styled.h3`
  display: none;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.84px;
  line-height: 20px;
  color: ${({ theme }) => theme.text};
`;

export const Aside = styled.aside`
  width: 50%;
  display: flex;
  justify-content: center;
  border-radius: 0 8px 8px 0;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  z-index: 1;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2vw;
`;

export const FormTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.625rem;
  color: ${({ theme }) => theme.text};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  label {
    width: 48%;
  }

  label + label {
    margin-left: 4%;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
  margin-bottom: 0.625rem;
  font-size: 0.75rem;

  input {
    font-size: 0.625rem;
    letter-spacing: 0.72px;
    line-height: 13px;
    font-weight: normal;
    margin-top: 0.3125rem;
    background: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    border-radius: 8px;
    min-height: 28px;
    padding: 4px 14px;
    border: none;
  }
`;

export const Separator = styled.hr`
  margin: 0.625rem 0 1.25rem;
  border-color: var(--gray);
`;

export const PassCheck = styled.ul`
  color: ${({ theme }) => theme.text};
  display: flex;
  margin-bottom: 0.625rem;
  flex-wrap: wrap;

  li {
    display: flex;
    align-items: center;
    margin: 0 0.5vw 0.5vh 0;
    font-size: 0.5rem;
    justify-content: center;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;
