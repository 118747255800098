import styled from 'styled-components';

interface StyleProps {
  width: string;
}

export const PopUp = styled.div<StyleProps>`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 8;
  overflow-y: auto;
  box-shadow: 0px 0px 12px 3px ${({ theme }) => theme.lowOpacity};

  .background {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.backgroundPrimary};
  }

  .container {
    opacity: 1;
    width: ${props => props.width};
    background-color: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 8px;
    position: absolute;
    z-index: 9;

    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.backgroundSecondary};
      color: ${({ theme }) => theme.text};
      padding: 1rem;
      font-size: 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      p {
        margin-left: 8px;
      }

      .icon_close {
        margin-right: 10px;
        cursor: pointer;
        color: var(--red-primary);
        transition: 0.3s;
        background-color: var(--red-primary);
        border-radius: 80%;
        margin-top: 2px;

        :hover {
          color: ${({ theme }) => theme.backgroundTerciary};
          background-color: ${({ theme }) => theme.backgroundTerciary};
        }
      }
    }
  }
`;
export const Main = styled.div`
  padding: 1rem;
  border-radius: 8px;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 80vh;
`;
