import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export const createTag = async (
  name: string,
  color = 'var(--cyan-primary)',
  clientId: string | number
): Promise<ITag> => {
  try {
    const response = await apiV2.post(`tags/`, {
      name,
      color,
      client_id: clientId,
    });

    const tag = response.data;

    return tag;
  } catch {
    toast.error(
      'Ocorreu algum problema ao criar a tag, atualize a página e tente novamente 😕'
    );

    return {} as ITag;
  }
};

export default createTag;
