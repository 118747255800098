import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  height: 93.8vh;

  #pageName {
    color: var(--red-primary);
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 2vh;
  }
`;
export const Search = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
`;

export const Filters = styled.div`
  display: flex;
  margin-top: 20px;
  input[type='radio'] {
    position: absolute;
    visibility: hidden;
    display: none;
  }

  label {
    color: ${({ theme }) => theme.text};
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 20px;
    transition: all 0.2s linear;
    background: ${({ theme }) => theme.backgroundSecondary};
    margin-right: 20px;
    &:hover {
      filter: brightness(90%);
      border-radius: 3px;
      transition: all 0.2s linear;
    }
  }
  input[type='radio']:checked + label {
    color: var(--white);
    background: var(--red-primary);
    border-radius: 3px;
    transition: all 0.2s linear;

    &:hover {
      filter: brightness(90%);
      border-radius: 3px;
      transition: all 0.2s linear;
    }
  }
  select {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.backgroundSecondary};
    border: none;
    font-weight: bold;
    width: 10%;
    margin-right: 20px;

    option {
      color: ${({ theme }) => theme.text};
      :disabled {
        color: var(--white);
        background-color: var(--gray);
      }
    }
  }
`;
export const Input = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  input {
    background: ${({ theme }) => theme.backgroundSecondary};
    border: 1px solid ${({ theme }) => theme.text};
    border-radius: 50px;
    color: ${({ theme }) => theme.text};
    font-weight: bold;
    height: 40px;
    width: 40%;
    padding: 0 10px;
  }
  button {
    margin-left: 8px;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;

    :hover {
      filter: brightness(90%);
    }
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 8px;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    :hover {
      filter: brightness(90%);
    }
  }
`;
export const PostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;

  height: 72%;
  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    border-radius: 5px;
    cursor: grab;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.text};
  }
`;
export const Controll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray);
  svg {
    cursor: pointer;
  }
`;
