import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 90%;

  header {
    display: flex;

    p {
      margin-left: 0.785rem;
    }

    div:last-child {
      display: flex;
      align-items: center;
      margin-left: auto;

      span {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.6rem;
      }
    }
  }

  main {
    width: 100%;
    height: 24rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem 0;
    gap: 0.8rem;
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      width: 12rem;
      height: 1.8rem;
      border: 0;
      border-radius: 0.3rem;
      background: var(--red-primary);
      color: var(--white);
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;

export const OkrWrapper = styled.div`
  width: 50%;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.25rem 0;

  p {
    margin-left: 0.785rem;
  }
`;
