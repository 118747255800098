import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  min-height: 60vh;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-color: red yellow;
`;

export const Input = styled.div`
  width: 100%;
  display: flex;
  margin-top: 0.8rem;
  flex-direction: column;

  .select-search__value {
    input {
      padding: 0.5rem 0.2rem;
      margin-top: 0.7rem;
      font: 400 0.9rem Work Sans;
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.backgroundSecondary};

      &::placeholder {
        color: ${({ theme }) => theme.text};
        opacity: 1; /* Firefox */
        font: 400 0.9rem Work Sans;
      }
    }
  }

  select {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.backgroundSecondary};
    width: 100%;
    min-width: 14rem;
    height: 2.5rem;
    font: 400 0.9rem Work Sans;
    border: none;
    padding: 0.5rem 0rem;
    margin: 0.5rem 0;
  }
  p {
    margin-top: 0.8rem;
    font: 400 0.9rem Work Sans;
    word-break: break-word;
  }

  .filter__control {
    background-color: ${({ theme }) => theme.backgroundSecondary};
    color: ${({ theme }) => theme.text};
    border: none;
    box-shadow: none;
    margin-top: 0.7rem;
    cursor: pointer;

    &:hover {
      border-color: var(--red-primary);
      box-shadow: none;
    }

    .filter__value-container {
      padding: 0;
      font: 400 0.9rem Work Sans;
    }

    .filter__placeholder {
      color: ${({ theme }) => theme.text};
    }

    .filter__input {
      color: ${({ theme }) => theme.text};
    }

    .filter__single-value {
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.backgroundSecondary};
      width: 100%;
      font: 400 0.9rem Work Sans;
      border: none;
    }
  }
  .filter__menu {
    margin: 0;
    background-color: ${({ theme }) => theme.backgroundSecondary};
    color: ${({ theme }) => theme.text};
    margin-top: 0.2rem;

    .filter__menu-list {
      padding: 0;

      .filter__group {
        padding: 0;
        position: relative;

        .filter__group-heading {
          background-color: var(--gray);
          color: ${({ theme }) => theme.text};
          margin: 0;
          height: 4vh;
          display: flex;
          justify-content: space-around;
        }

        .filter__option {
          cursor: pointer;
          height: 3rem;
          font-size: 0.9rem;
          display: flex;
          align-items: center;

          &.filter__option--is-focused {
            background: var(--red-primary);
            color: var(--white);
          }
          &.filter__option--is-selected {
            background: var(--red-primary);
          }
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  strong {
    font-size: 0.75rem;
    color: var(--red-primary);
  }
`;
