import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 98%;
  min-height: 5rem;
  display: flex;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.backgroundTerciary};
  margin: 0.5rem 0.5rem 0.5rem 0.25rem;
  color: ${({ theme }) => theme.text};
  &:hover {
    box-shadow: 0 0.25rem 1.5rem 0 rgba(227, 36, 56, 0.5);
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 0.5rem;
  cursor: pointer;

  .name {
    font-size: 0.7rem;
  }
`;

export const TagsArea = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const Header = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  #responsibles {
    padding-top: 1.5rem;
  }
`;

export const Title = styled.div`
  display: flex;

  p {
    font-size: 0.6rem;
    margin-left: 0.2rem;
  }
  button {
    background: none;
    border: none;
    margin-left: 1rem;
  }
`;

export const RestoreGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -1.2rem;
  button {
    display: flex;
    align-items: center;
    color: var(--gray);
    background: none;
    border: none;

    #restore-label {
      margin: 0 0.5rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  p {
    font-size: 0.6rem;
    margin-right: 0.5rem;
  }

  div > div {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 0.3rem;
  }

  #period {
    width: 50%;
    display: flex;
  }

  #period > div {
    margin-bottom: 0.6rem;
  }

  #progress {
    width: 50%;
    padding-left: 0.5rem;
    display: flex;
  }

  #progress > div {
    margin-bottom: 0.6rem;
  }
`;
