import React, { useCallback, useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import apiV2 from '../../../../../../services/apiV2';
import * as S from './styles';

interface ObjectProgress {
  percentage: number;
  count: number;
}

interface ProgressResponse {
  lateDoneTasksProgress: ObjectProgress;
  lateTasksProgress: ObjectProgress;
  onTimeDoneTasksProgress: ObjectProgress;
  onTimeTasksProgress: ObjectProgress;
  rescheduleTasksProgress: ObjectProgress;
}

export const ChartsBoard: React.FC<{ id: number }> = ({ id }) => {
  const [progress, setProgress] = useState<ProgressResponse>({
    lateTasksProgress: {
      percentage: 0,
      count: 0,
    },
    lateDoneTasksProgress: {
      percentage: 0,
      count: 0,
    },
    onTimeTasksProgress: {
      percentage: 0,
      count: 0,
    },
    onTimeDoneTasksProgress: {
      percentage: 0,
      count: 0,
    },
    rescheduleTasksProgress: {
      percentage: 0,
      count: 0,
    },
  });

  const getProgress = useCallback(async () => {
    await apiV2.get(`users/${id}/tasksprogress`).then(res => {
      setProgress(res.data);
    });
  }, [id]);

  useEffect(() => {
    getProgress();
  }, [getProgress]);
  return (
    <S.Wrapper>
      <S.ChartBox>
        <p>Tarefas atrasadas</p>
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Tarefas atrasadas',
              value: progress.lateTasksProgress.percentage,
              color: `var(--red-primary)`,
            },
            {
              value: 100 - progress.lateTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={20}
          label={() => `${progress.lateTasksProgress.percentage || 0}%`}
          labelStyle={{
            fontSize: '1.25rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
      </S.ChartBox>
      <S.ChartBox>
        <p>Entregues atrasadas</p>
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Entregues atrasadas',
              value: progress.lateDoneTasksProgress.percentage,
              color: `var(--yellow-primary)`,
            },
            {
              value: 100 - progress.lateDoneTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={20}
          label={() => `${progress.lateDoneTasksProgress.percentage || 0}%`}
          labelStyle={{
            fontSize: '1.25rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
      </S.ChartBox>
      <S.ChartBox>
        <p>Tarefas em dia</p>
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Tarefas em dia',
              value: progress.onTimeTasksProgress.percentage,
              color: `var(--cyan-primary)`,
            },
            {
              value: 100 - progress.onTimeTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={20}
          label={() => `${progress.onTimeTasksProgress.percentage || 0}%`}
          labelStyle={{
            fontSize: '1.25rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
      </S.ChartBox>
      <S.ChartBox>
        <p>Tarefas entregues</p>
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Tarefas entregues',
              value: progress.onTimeDoneTasksProgress.percentage,
              color: `var(--green-primary)`,
            },
            {
              value: 100 - progress.onTimeDoneTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={20}
          label={() => `${progress.onTimeDoneTasksProgress.percentage || 0}%`}
          labelStyle={{
            fontSize: '1.25rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
      </S.ChartBox>
      <S.ChartBox>
        <p>Tarefas remanejadas</p>
        <PieChart
          className="doughnut-chart"
          data={[
            {
              title: 'Tarefas remanejadas',
              value: progress.rescheduleTasksProgress.percentage,
              color: `var(--magenta-primary)`,
            },
            {
              value: 100 - progress.rescheduleTasksProgress.percentage,
              color: 'var(--gray)',
            },
          ]}
          lineWidth={20}
          label={() => `${progress.rescheduleTasksProgress.percentage || 0}%`}
          labelStyle={{
            fontSize: '1.25rem',
            fontFamily: 'sans-serif',
          }}
          labelPosition={0}
        />
      </S.ChartBox>
    </S.Wrapper>
  );
};
