import React, { createContext, useCallback, useEffect, useState } from 'react';

type ContextType = {
  view: string;
  changeView: (value: string) => void;
};

const ViewContext = createContext<ContextType>({
  view: 'list',
  changeView: () => undefined,
});

const ViewProvider: React.FC = ({ children }) => {
  const [view, setView] = useState('list');

  const changeView = useCallback((value: string) => {
    window.localStorage.setItem('view', value);
    setView(value);
  }, []);

  useEffect(() => {
    const localTheme = window.localStorage.getItem('view');
    localTheme && setView(localTheme);
  }, []);

  return (
    <ViewContext.Provider
      value={{
        view,
        changeView,
      }}
    >
      {children}
    </ViewContext.Provider>
  );
};

export { ViewContext, ViewProvider };
