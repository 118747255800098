import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  .disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  svg {
    font-size: 1rem;
    color: var(--red-primary);
    margin-right: 0.5rem;
    margin-bottom: 0.1rem;
  }
  h4 {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.text};
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.96px;
    line-height: 13px;
  }

  h4 + svg {
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
  }
`;
