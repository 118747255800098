import styled from 'styled-components';

export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: 0.675rem;
    text-transform: uppercase;
  }
`;

export const GroupBadge = styled.span`
  color: ${({ theme }) => theme.text};
  display: inline-block;
  font-size: 0.725rem;
  text-align: center;
`;
