import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProps } from './themes';

import LogoImageWhite from '../assets/images/logo.svg';
import LogoImageBlack from '../assets/images/logo2.svg';
import CheckinLogoLight from '../assets/images/logoV4Light.svg';
import CheckinLogoDark from '../assets/images/logov4.png';

type GlobalThemeProps = {
  theme: ThemeProps;
};

export default createGlobalStyle`
  html {
    //dark-mode
    --dark-background-primary: #131c21;
    --dark-background-secondary: #2a2f32;
    --dark-background-terciary: #161b22;
    --dark-text: #ffffff;
    --dark-secondary-text: #7F8790;
    --dark-logo: url(${LogoImageWhite});
    --dark-low-opacity: #0C0E10de;
    --dark-checkin-logo: url(${CheckinLogoDark});
    --dark-box-shadow: #00000029;
    --dark-menu-client-background: #272B30;

    //white-mode
    --white-background-primary: #f1f1f1;
    --white-background-secondary: #fefefe;
    --white-background-terciary: #c5c5c5;
    --white-text: #000000;
    --white-secondary-text: #797593;
    --white-logo: url(${LogoImageBlack});
    --white-low-opacity: #f1f3f4de;
    --white-checkin-logo: url(${CheckinLogoLight});
    --white-box-shadow: #7F87904D;
    --white-menu-client-background: #9EA5AD;


    //global
    --white: #f1f3f4;
    --black: #0C0E10;
    --gray: #707070;
    --gray-text: #7F8790;
    --red-primary: #e32438;
    --yellow-primary: #FBAA49;
    --magenta-primary: #FF33FF;
    --cyan-primary: #20AAEA;
    --green-primary: #1FC875;
    --cyan-secondary: #5C9AD5;
    --orange-secondary: #EF7E32;
    --purple-secondary: #F1D302;

    //csat-colors
    --very-unsatisfied: #FF565F;
    --unsatisfied: #FC5604;
    --indifferent: #FFD119;
    --satisfied: #9FD973;
    --very-satisfied: #65CB65;

    //new colors
    --gray-700: #272B30;

  }

  html, body, #root {
      height: 100%;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
  }

  pre {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    white-space: pre-wrap;
  }

  body {
      background: ${({ theme }: GlobalThemeProps) => theme.backgroundPrimary};
      -webkit-font-smoothing: antialiased !important;
      overflow: hidden;
  }

  button {
    cursor: pointer;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    ::-webkit-scrollbar {
      height: 4px;
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }: GlobalThemeProps) => theme.backgroundSecondary}
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 8px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }: GlobalThemeProps) => theme.text};
    }
  }
`;
