import React, { useState } from 'react';
import { RiInformationLine } from 'react-icons/ri';

import { Button } from '../../../../../components/Inputs';

import * as S from './styles';
import { DataHolder } from '../Card/styles';
import CheckinsList from './CheckinsList';

interface OldCheckinList {
  id: number;
  created_at: Date;
}

const LegacyCheckins: React.FC<{ checkinList: OldCheckinList[] }> = ({
  checkinList,
}) => {
  const [viewCheckins, setViewCheckins] = useState(false);

  return (
    <S.Container>
      <div>
        <DataHolder>
          <div className="labelHolder">
            <p style={{ color: ' var(--red-primary)' }} className="info">
              Quarter
            </p>
          </div>
          <p>Checkins Antigos</p>
        </DataHolder>

        <DataHolder>
          <div className="labelHolder">
            <RiInformationLine size={14} color="var(--red-primary)" />
            <p className="info">O que são?</p>
          </div>

          <p>Aqui você encontra checkins que foram criados no modelo antigo.</p>
        </DataHolder>

        {viewCheckins && (
          <CheckinsList
            closePopUp={setViewCheckins}
            checkinList={checkinList}
          />
        )}
      </div>

      <Button
        background="var(--red-primary)"
        width="100%"
        height="2rem"
        text="Visualizar"
        font="700 0.9rem Work Sans"
        onClick={() => setViewCheckins(!viewCheckins)}
      />
    </S.Container>
  );
};

export default LegacyCheckins;
