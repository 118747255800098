import React, { useCallback, useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { RiSearchLine } from 'react-icons/ri';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { WaitingSignatureTable } from './components/Tables/WaitingSignatureTable';
import { ActiveContractsTable } from './components/Tables/ActiveContractsTable';
import { InactiveContractsTable } from './components/Tables/InactiveContractsTable';
import { WaitingVerificationTable } from './components/Tables/WaitingVerificationTable';
import * as S from './styles';
import Loading from '../../components/Loadings/CustomLoading';
import { getCheckedContractsIds } from './utils/GetCheckedContractsIds';
import { approveContract } from './services/ApproveContract';
import { useContracts } from '../../contexts/ContractsContext';
import { disapproveContract } from './services/DisapproveContract';

export const Compliance: React.FC = () => {
  const [route, setRoute] = useState('approve');
  const [activeActionsMenu, setActiveActionsMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { contracts } = useContracts();

  const getPageRoute = useCallback(routeUrl => {
    switch (routeUrl) {
      case '/compliance/sign':
        setRoute('sign');
        break;
      case '/compliance/active':
        setRoute('active');
        break;
      case '/compliance/inactive':
        setRoute('inactive');
        break;
      default:
        setRoute('approve');
    }
  }, []);

  const handleApproveCheckedContracts = async (allContracts: Contract[]) => {
    setLoading(true);
    const checkedIds = getCheckedContractsIds(allContracts);
    const approveAllContracts = async (id: number) => {
      if (await approveContract(id)) {
        toast.success(
          'O contrato foi aprovado e está aguardando as assinaturas.'
        );
      }
    };

    checkedIds.map(async (id: number) => {
      setLoading(true);
      await approveAllContracts(id);

      setLoading(false);
    });
  };

  const handleDisapproveCheckedContracts = async (allContracts: Contract[]) => {
    setLoading(true);
    const checkedIds = getCheckedContractsIds(allContracts);
    const disapproveAllContracts = async (id: number) => {
      if (await disapproveContract(id)) {
        toast.success('O contrato foi reprovado e o card retornou no pipefy');
      }
    };

    checkedIds.map(async (id: number) => {
      setLoading(true);
      await disapproveAllContracts(id);

      setLoading(false);
    });
  };

  useEffect(() => {
    getPageRoute(location.pathname);
  }, [getPageRoute, location]);

  return (
    <S.Compliance>
      <S.Header>
        <h2>V4 Company HQ</h2>
        <S.Buttons>
          <button type="button" className="isActive">
            Contratos Clientes
          </button>
          <button type="button" className="disabled" disabled>
            Contratos Colaboradores
            <span>em desenvolvimento</span>
          </button>
          <button type="button" className="disabled" disabled>
            Contratos Franquias
            <span>em desenvolvimento</span>
          </button>
        </S.Buttons>
      </S.Header>
      <S.Table>
        <S.TableHeader>
          <S.ActionButtons>
            <div>
              <button
                type="button"
                onClick={() => setActiveActionsMenu(!activeActionsMenu)}
              >
                Ações
                <FaAngleDown />
              </button>
              <ul className={activeActionsMenu ? 'isActive' : ''}>
                <button
                  type="button"
                  onClick={() => handleApproveCheckedContracts(contracts)}
                >
                  Aprovar todos
                </button>
                <button
                  type="button"
                  onClick={() => handleDisapproveCheckedContracts(contracts)}
                >
                  Rejeitar todos
                </button>
              </ul>
            </div>
            <button type="button" className="disabled">
              Buscar
              <RiSearchLine />
            </button>
            <button className="period disabled" type="button">
              Período
            </button>
          </S.ActionButtons>
          <S.HeaderTabs>
            <Link to="approve">
              <button
                type="button"
                className={route === 'approve' ? 'isActive' : ''}
              >
                Aguardando verificação
              </button>
            </Link>
            <Link to="sign">
              <button
                type="button"
                className={route === 'sign' ? 'isActive' : ''}
              >
                Aguardando assinatura
              </button>
            </Link>
            <Link to="active">
              <button
                type="button"
                className={route === 'active' ? 'isActive' : ''}
              >
                Contratos ativos
              </button>
            </Link>
            <Link to="inactive">
              <button
                type="button"
                className={route === 'inactive' ? 'isActive' : ''}
              >
                Contratos encerrados
              </button>
            </Link>
          </S.HeaderTabs>
        </S.TableHeader>
        {loading ? (
          <Loading style={{ alignSelf: 'center' }} />
        ) : (
          <Switch>
            <Route
              path="/compliance/approve"
              component={WaitingVerificationTable}
            />
            <Route path="/compliance/sign" component={WaitingSignatureTable} />
            <Route path="/compliance/active" component={ActiveContractsTable} />
            <Route
              path="/compliance/inactive"
              component={InactiveContractsTable}
            />
          </Switch>
        )}
      </S.Table>
    </S.Compliance>
  );
};
