import React, { useState, useRef, useEffect, useMemo } from 'react';

import { FaAngleDown } from 'react-icons/fa';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import AddNewButton from '../../../components/AddNewButton';
import CloseButton from '../../../components/CloseButton';

import Group from '../Group';
import IndividualTask from '../IndividualTask';

import { useImportPlaybook } from '../../../../../../../../contexts/ImportPlaybookContext';
import UserAvatar from '../../../../../../../../components/UserAvatar';

import {
  Container,
  Project,
  Label,
  Users,
  Deadline,
  Separator,
  Buttons,
  AccordionButton,
  Accordion,
  DeliveryGroups,
  IndividualTasks,
} from '../../../styles/ProjectContainer';

interface ProjectProps {
  project: IPlaybookProject;
}

const ProjectItem: React.FC<ProjectProps> = ({ project }) => {
  const [firstTime, setFirstTime] = useState(true);

  const [projectDueDate, setProjectDueDate] = useState(-1);

  const {
    initialUsers,
    updateProjectName,
    updateProjectDescription,
    deleteProject,
    addNewGroup,
    addNewIndividualTask,
    addUsersInProjects,
    createInitialUsers,
  } = useImportPlaybook();

  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0');
  const [setRotate, setRotateState] = useState('');

  const content = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0' : `500vh`);
    setRotateState(setActive === 'active' ? '' : 'rotate');
  }

  const convertDueDateInDate = useMemo(() => {
    if (projectDueDate === -1) return 'Sem prazo';
    const actualDate = new Date();

    const actualDay = actualDate.getDate();

    const deadline = actualDay + projectDueDate;

    const newDate = actualDate.setDate(deadline);

    const formatedNewDate = format(new Date(newDate), 'dd.MMMM.yyyy', {
      locale: ptBR,
    }).toString();

    return formatedNewDate;
  }, [projectDueDate]);

  const responsibles = useMemo(
    () =>
      project.users_ids?.map(user => (
        <UserAvatar key={Math.floor(Math.random() * 65536)} id={Number(user)} />
      )),
    [project.users_ids]
  );

  useEffect(() => {
    if (firstTime) {
      addUsersInProjects(initialUsers);
      setFirstTime(false);
    }
  }, [addUsersInProjects, createInitialUsers, firstTime, initialUsers]);

  return (
    <>
      <Container>
        <Project>
          <Label htmlFor="projectName">
            Projeto
            <textarea
              id="projectName"
              defaultValue={project.name}
              onBlur={e => updateProjectName(project.id, e.target.value)}
            />
          </Label>
          <Separator />
          <Label htmlFor="responsibles">
            Responsáveis
            <Users>{responsibles}</Users>
          </Label>
          <Separator />
          <Deadline>
            Prazo
            <p>{convertDueDateInDate}</p>
          </Deadline>
          <Separator />
          <Label htmlFor="projectDescription">
            Descrição
            <textarea
              id="projectDescription"
              defaultValue={project.desc}
              onBlur={e => updateProjectDescription(project.id, e.target.value)}
            />
          </Label>
          <Buttons>
            <CloseButton
              type="button"
              onClick={() => deleteProject(project.id)}
              disabled={false}
            />
            <AccordionButton
              type="button"
              className={`${setActive}`}
              onClick={toggleAccordion}
            >
              <FaAngleDown className={`${setRotate}`} />
            </AccordionButton>
          </Buttons>
        </Project>
        <Accordion ref={content} style={{ maxHeight: `${setHeight}` }}>
          <DeliveryGroups>
            {project.groups &&
              project.groups?.map(group => (
                <Group
                  key={group.id}
                  group={group}
                  groups={project.groups ? project.groups : []}
                  projectId={project.id}
                  setProjectDueDate={setProjectDueDate}
                />
              ))}
            <AddNewButton onClick={() => addNewGroup(project.id)}>
              Adicionar novo grupo de entrega
            </AddNewButton>
          </DeliveryGroups>
          <IndividualTasks>
            {project.tasks &&
              project.tasks.map(individualTask => (
                <IndividualTask
                  key={individualTask.id}
                  individualTask={individualTask}
                  projectId={project.id}
                  setProjectDueDate={setProjectDueDate}
                />
              ))}
            <AddNewButton onClick={() => addNewIndividualTask(project.id)}>
              Adicionar nova task individual
            </AddNewButton>
          </IndividualTasks>
        </Accordion>
      </Container>
    </>
  );
};

export default ProjectItem;
