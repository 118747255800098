import React, { useCallback, useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import { Container } from './styles';
import api from '../../../../../services/api';

export default function AvatarInput({
  clientName,
  avatar,
  file,
  setFile,
  clientId,
}) {
  const [avatarUrl, setAvatarUrl] = useState(avatar && avatar.url);

  useEffect(() => {
    setAvatarUrl(avatar && avatar.url);
  }, [avatar]);

  const handleChange = useCallback(
    async e => {
      const data = new FormData();

      data.append('file', e.target.files[0]);

      await api
        .post('/files', data)
        .then(async res => {
          const { id, url } = res.data;
          await api
            .put('clients', {
              id: clientId,
              avatar_id: id,
            })
            .then(() => {
              toast.success('Cliente atualizado!');
            })
            .catch(() => {
              toast.error('Algo deu errado, por favor, atualize a página!');
            });
          setFile(id);
          setAvatarUrl(url);
        })
        .catch(() => {
          toast.error('A imagem é muito grande');
        });
    },
    [clientId, setFile]
  );

  return (
    <Container>
      <label htmlFor="avatar">
        <Avatar
          name={clientName}
          src={avatarUrl}
          className="avatar"
          color="var(--gray)"
          style={{ border: '0' }}
        />

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
        />
      </label>
    </Container>
  );
}
