import { Dispatch, SetStateAction } from 'react';
import getRandomId from '../../utils/GetRandomInteger';

export const addNewProject = (
  clientId: number,
  globalProjects: IProject[],
  setGlobalProjects: Dispatch<SetStateAction<IProject[]>>
): void => {
  const newProject = {
    id: getRandomId(),
    name: 'Novo projeto',
    desc: 'Adicionar descrição',
    order: globalProjects.length,
    status: 'aberto',
    client_id: clientId,
    due_date: new Date(),
    groups: [],
    tasks: [],
  };

  setGlobalProjects(state => [...state, newProject]);
};
