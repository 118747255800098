import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin-left: 1vw;
  margin: 1vh 1vw 1vh 0;
`;

export const Label = styled.label`
  color: var(--red-primary);
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Poppins', sans-serif;
  font-size: 0.625rem;
  letter-spacing: 1.8px;

  textarea {
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 8px;
    border: none;
    color: ${({ theme }) => theme.text};
    font-size: 0.75rem;
    font-weight: bold;
    margin-top: 0.5vh;
    min-height: 50px;
    padding: 1vh 1vw;
    resize: none;
  }

  div .select-search {
    font-size: 0.625rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1.8px;
  }

  div .select-search .select-search__value input {
    height: auto;
    color: var(--red-primary);
    letter-spacing: 1.8px;
    font-size: 0.625rem;
    font-family: 'Poppins', sans-serif;

    &::placeholder {
      color: var(--red-primary);
      letter-spacing: 1.8px;
      font-size: 0.625rem;
      font-family: 'Poppins', sans-serif;
    }
  }
`;

export const Deadline = styled.label`
  color: var(--red-primary);
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-size: 0.625rem;
  letter-spacing: 1.8px;
  width: 10vw;

  p {
    border-radius: 8px;
    border: none;
    color: ${({ theme }) => theme.text};
    font-size: 0.75rem;
    font-weight: bold;
    min-height: 50px;
    margin-top: 0.5vh;
    padding: 1vh 0;
  }

  button {
    background-color: transparent;
    border: none;

    p {
      display: flex;
      justify-content: space-between;
    }

    svg {
      margin-left: 1vh;
    }
  }
`;

export const SeparatorVertical = styled.hr`
  border-radius: 4px;
  border: 1px solid var(--gray);
  min-height: 16px;
  margin: 0 2vh;
`;

export const AccordionButton = styled.button`
  align-items: center;
  align-self: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 1vh 1vw;
  transition: background-color 0.6s ease;

  svg {
    color: ${({ theme }) => theme.text};
  }

  .rotate {
    transform: rotate(180deg);
  }
`;

export const Accordion = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 1vh;
  padding-top: 1vh;
`;
