import { RiAddCircleLine } from 'react-icons/ri';
import * as S from './styles';

interface AddNewProps {
  labelText: string;
  onClick: () => void;
}

export const AddNew: React.FC<AddNewProps> = ({ labelText, onClick }) => {
  return (
    <S.Wrapper type="button" onClick={onClick}>
      <RiAddCircleLine className="icon" size={20} color="var(--red-primary)" />
      <p>{labelText}</p>
    </S.Wrapper>
  );
};
