import styled from 'styled-components';

export const TableBody = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 5px 5px 5px;
  list-style: none;
  background-color: ${({ theme }) => theme.backgroundSecondary};

  li {
    width: 100%;
    align-items: center;
  }

  li:last-child {
    margin-bottom: 2px;
  }

  div.status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8%;

    svg {
      transform: rotate(90deg);
      font-size: 1rem;
    }
    p {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  div.vigence {
    width: 10%;
  }
  div.first-payment {
    width: 12%;
  }
  div.client {
    width: 16%;
  }
  div.cnpj {
    width: 12%;
  }
  div.legal-representative {
    width: 14%;
  }
  div.legal-representative-cpf {
    width: 12%;
  }
  div.contract-value {
    width: 12%;
  }
  div.product {
    width: 6%;
  }

  hr {
    color: var(--gray);
    height: 1.5rem;
    opacity: 0.1;
  }
`;

export const TableCategories = styled.li`
  display: flex;
  background-color: ${({ theme }) => theme.backgroundSecondary};
`;

export const ColumnCategory = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;

  strong {
    padding: 0.5rem;
    text-align: left;
    color: ${({ theme }) => theme.text};
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.24px;
    line-height: 1rem;
  }
`;

export const TableLine = styled.li`
  display: flex;
  cursor: pointer;
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  transition-property: all;
  transition-duration: 0.3s;

  &:hover div.aprovation button:last-child svg {
    color: var(--white);
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.backgroundPrimary};
  }

  &:hover {
    box-shadow: 0 0 8px rgb(0 0 0 / 60%);
    background: var(--red-primary);

    p {
      color: var(--white);
    }
  }
`;

export const ColumnLine = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;

  p {
    padding: 0.5rem;
    text-align: left;
    color: ${({ theme }) => theme.text};
    font-size: 0.625rem;
    letter-spacing: 0.22px;
    line-height: 0.875rem;
  }
`;

export const Pagination = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;

  svg {
    font-size: 0.625rem;
    color: ${({ theme }) => theme.text};
    margin: 0 0.25rem;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    background-color: transparent;
    font-size: 0.75rem;
    border: none;

    &.isActive {
      background-color: var(--red-primary);
      color: var(--white);
    }
  }

  button + button {
    margin-left: 0.2rem;
  }
`;

export const Modals = styled.div`
  display: flex;
  position: relative;
`;
