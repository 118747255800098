import styled from 'styled-components';

interface EditProps {
  edit: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 4px;
  padding: 10px;
  color: ${({ theme }) => theme.text};
  justify-content: space-between;
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
    cursor: pointer;
    transition: 0.5s all;
    :hover {
      width: 20px;
      height: 20px;
      transition: 0.5s all;
    }
  }

  #delete {
    color: var(--red-primary);
    margin-left: 20px;
  }
  .avatar {
    margin-right: 10px;
    border-radius: 50%;
    width: 36px !important;
    height: 36px !important;
    max-width: 36px !important;
  }
`;
export const Right = styled.div<EditProps>`
  display: flex;
  align-items: center;
  p {
    margin-right: 10px;
    color: var(--red-primary);
  }
  .approval {
    margin-right: 30px;
    cursor: pointer;
  }
  .accept:hover {
    color: var(--green-primary);
  }
  .denie:hover {
    color: var(--red-primary);
  }
  .select-container {
    width: 100%;
    height: 58px;
    padding: 0;
  }
  .select__control {
    width: 100%;
    height: 100%;
    background: ${props => {
      if (props.edit) {
        return 'var(--white)';
      }
      return 'transparent';
    }};
    border: ${props => {
      if (props.edit) {
        return '';
      }
      return 'none';
    }};
  }
  .select__indicators {
    visibility: ${props => {
      if (props.edit) {
        return 'visible';
      }
      return 'hidden';
    }};
  }

  .select__multi-value__remove {
    visibility: ${props => {
      if (props.edit) {
        return 'visible';
      }
      return 'hidden';
    }};
  }

  .select__multi-value {
    display: flex;
    align-items: center;
    height: 60%;
    background: transparent;
    font-size: 12px;
  }
  .select__multi-value__label {
    color: var(--gray);
  }
  .select__value-container {
    height: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }
  .select__input {
    color: ${({ theme }) => theme.text};
    height: 100%;
    font-size: 12px;
  }
  .css-b8ldur-Input {
    height: 60%;
  }
  .select__menu {
    background: ${({ theme }) => theme.backgroundPrimary};
    line-height: 20px;
    height: auto;
    max-height: 500px;
  }
  .select__menu-list {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    padding: 0;
    max-height: 500px;
  }
  .select__option {
    width: 100% !important;
    max-height: 40px;

    :hover {
      background-color: ${({ theme }) => theme.backgroundPrimary};
    }
  }
  .select__option--is-focused {
    background-color: ${({ theme }) => theme.backgroundPrimary};
  }
`;
