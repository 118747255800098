import styled from 'styled-components';

interface LabelProps {
  width?: string;
}

export const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  font-size: 0.875rem;
  letter-spacing: 0.84px;
  line-height: 0.75rem;
  font-weight: 700;
  color: ${({ theme }) => theme.text};
  margin-bottom: 1rem;

  input {
    font-size: 0.75rem;
    letter-spacing: 0.72px;
    line-height: 0.75rem;
    font-weight: 400;
    margin-top: 0.625rem;
    height: 2rem;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    padding: 0.3rem 0.75rem;
    border: none;

    ::placeholder {
      color: ${({ theme }) => theme.text};
    }
  }

  select {
    font-size: 0.75rem;
    letter-spacing: 0.72px;
    line-height: 0.75rem;
    font-weight: 400;
    margin-top: 0.625rem;
    height: 2rem;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    padding: 0.3rem 0.75rem;
    border: none;

    ::placeholder {
      color: ${({ theme }) => theme.text};
    }
  }

  p {
    margin-top: 0.3125rem;
    width: 100%;
    font-size: 0.625rem;
    letter-spacing: 0.84px;
    line-height: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.text};
  }

  button {
    font-size: 0.75rem;
    letter-spacing: 0.72px;
    line-height: 0.75rem;
    font-weight: 400;
    margin-top: 0.625rem;
    height: 2rem;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    padding: 0.3rem 0.75rem;
    border: none;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
`;

export const AddressLabel = styled.label<LabelProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: ${props => props.width};
  color: ${({ theme }) => theme.text};

  h5 {
    width: 100%;
    color: ${({ theme }) => theme.text};
  }

  input#street {
    width: 48%;
  }

  input {
    font-size: 0.75rem;
    letter-spacing: 0.72px;
    line-height: 0.75rem;
    font-weight: 400;
    margin-top: 0.625rem;
    height: 2rem;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    padding: 0.3rem 0.75rem;
    border: none;
    width: 22%;

    ::placeholder {
      color: ${({ theme }) => theme.text};
    }
  }
`;

export const ContractLabel = styled.label<LabelProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.text};

  a {
    font-size: 0.75rem;
    letter-spacing: 0.72px;
    line-height: 13px;
    margin-left: 0.5rem;
    color: var(--green-primary);
    text-decoration: none;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
