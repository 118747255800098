import React, { useEffect, useState } from 'react';

import { FaRegEye } from 'react-icons/fa';

import { IoMdClose } from 'react-icons/io';

import AddNewButton from '../../../components/AddNewButton';

import { Button } from '../../../../../../../../components/Inputs';

import Project from '../Project';

import { ProjectCardLoading } from '../../../../../../pages/ListView/components/Loadings/index';
import { useImportPlaybook } from '../../../../../../../../contexts/ImportPlaybookContext';

import {
  Background,
  Container,
  Header,
  Projects,
  Footer,
} from '../../../styles/PlaybookContainer';

interface PlaybookProps {
  handleClose: () => void;
  playbook: IPlaybook;
  clientId: string;
  users: IUserId[];
}

const Playbook: React.FC<PlaybookProps> = ({
  handleClose,
  playbook,
  clientId,
  users,
}) => {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const {
    addNewProject,
    importPlaybook,
    createInitialUsers,
    globalPlaybookProjects,
    createGlobalPlaybookProjects,
  } = useImportPlaybook();

  const handleSubmit = () => {
    importPlaybook(Number(clientId), handleClose, setSubmitLoading);
  };

  useEffect(() => {
    if (firstTime) {
      createGlobalPlaybookProjects(playbook.id, setLoading);
      createInitialUsers(users);
      setFirstTime(false);
    }
  }, [
    createGlobalPlaybookProjects,
    createInitialUsers,
    firstTime,
    playbook.id,
    users,
  ]);

  return (
    <>
      <Background onClick={handleClose} />
      <Container>
        {submitLoading ? (
          <ProjectCardLoading />
        ) : (
          <>
            <Header>
              <FaRegEye />
              <h3>Preview: {playbook.name}</h3>
              <IoMdClose onClick={handleClose} />
            </Header>
            <Projects>
              {loading ? (
                <ProjectCardLoading />
              ) : (
                globalPlaybookProjects.map(project => (
                  <Project key={project.id} project={project} />
                ))
              )}
              <AddNewButton onClick={() => addNewProject(playbook.id)}>
                Adicionar novo projeto
              </AddNewButton>
            </Projects>
            <Footer>
              {loading && (
                <span>
                  Estamos carregando o playbook, isto pode demorar um pouco,
                  aguarde...
                </span>
              )}
              <Button
                background="var(--gray)"
                width="10.5rem"
                height="2.1rem"
                className="initialButton"
                font="400 0.9rem Work Sans"
                disabled={loading}
                onClick={handleClose}
              >
                <p>Voltar</p>
              </Button>
              <Button
                background="var(--green-primary)"
                height="2.1rem"
                width="10.5rem"
                font="400 0.9rem Work Sans"
                className="rightButton"
                disabled={loading}
                onClick={() => handleSubmit()}
              >
                <p>Importar Playbook</p>
              </Button>
            </Footer>
          </>
        )}
      </Container>
    </>
  );
};

export default Playbook;
