import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  RiTrophyLine,
  RiPsychotherapyLine,
  RiCloseCircleFill,
} from 'react-icons/ri';

import apiV2 from '../../../../services/apiV2';

import { Button } from '../../../../components/Inputs';

import * as S from './styles';

interface Result {
  id: number;
  description: string;
}

interface Results {
  lessons?: boolean;
  draftId: string;
  oldList: Result[];
}

const ResultsHolder: React.FC<Results> = ({
  lessons = false,
  draftId,
  oldList,
}) => {
  const [newItem, setNewItem] = useState('');
  const [listOfItems, setListOfItems] = useState<Result[]>(oldList);
  const [type, setType] = useState('victory');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setListOfItems(oldList);

    if (lessons) setType('lesson');
  }, [oldList, lessons]);

  const handleAdd = useCallback(
    async e => {
      e.preventDefault();

      if (listOfItems.length > 15) {
        toast.warn('Número maximo atingido');
        return;
      }

      if (newItem.length < 1) {
        toast.warn('Digite antes de adicionar');
        return;
      }

      const victory = {
        victory: {
          description: newItem,
        },
        checkinDraftId: draftId,
      };

      const lesson = {
        lesson: {
          description: newItem,
        },
        checkinDraftId: draftId,
      };

      setNewItem('');
      setIsSaving(true);

      await apiV2
        .post(`/${type}`, type === 'lesson' ? lesson : victory)
        .then(res => {
          setListOfItems([...listOfItems, res.data]);
        })
        .catch(() => {
          toast.warn('Falha ao adicionar');
        });

      setIsSaving(false);
    },
    [listOfItems, newItem, draftId, type]
  );

  const handleRemove = useCallback(
    id => {
      toast.warn('Removendo!');

      apiV2
        .delete(`/${type}/delete?${type}Id=${id}&checkinDraftId=${draftId}`)
        .then(() => {
          const filtered = listOfItems.filter(itemName => itemName.id !== id);
          setListOfItems(filtered);
          toast.success('Deletado!');
        })
        .catch(() => {
          toast.warn('Falha ao deletar');
        });
    },
    [listOfItems, draftId, type]
  );

  return (
    <S.Container>
      <form onSubmit={e => handleAdd(e)}>
        <S.Icon>
          {lessons ? (
            <RiPsychotherapyLine color="var(--green-primary)" size={80} />
          ) : (
            <RiTrophyLine color="var(--green-primary)" size={80} />
          )}
          {lessons ? <p>Aprendizados</p> : <p>Vitórias</p>}
        </S.Icon>

        <S.InteractiveArea>
          <textarea
            maxLength={64}
            placeholder={
              lessons
                ? 'Digite aqui os aprendizados'
                : 'Digite aqui as vitórias'
            }
            value={newItem}
            onChange={e => setNewItem(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleAdd(e)}
          />

          <div className="button">
            <Button
              height="1.8rem"
              width="6rem"
              font="800 0.7rem Work Sans"
              background={isSaving ? 'var(--gray)' : 'var(--red-primary)'}
              disabled={isSaving}
              text={isSaving ? 'Adicionando...' : 'Adicionar'}
              type="submit"
            />
          </div>

          <S.List>
            {listOfItems.map(item => {
              return (
                <div
                  key={item.id}
                  className="item"
                  onClick={() => handleRemove(item.id)}
                  role="button"
                  aria-hidden
                >
                  <S.Ball />
                  <p>{item.description}</p>
                  <RiCloseCircleFill size={15} color="var(--red-primary)" />
                </div>
              );
            })}
          </S.List>
        </S.InteractiveArea>
      </form>
    </S.Container>
  );
};

export default ResultsHolder;
