import { FC } from 'react';
import { FiCircle } from 'react-icons/fi';

import * as S from './styles';

interface PopUpProps {
  header: string | number | boolean;
  closePopUp: React.Dispatch<React.SetStateAction<boolean>>;
  width?: string;
}

const PopUp: FC<PopUpProps> = ({
  children,
  header,
  closePopUp,
  width = '50%',
}) => {
  return (
    <S.PopUp width={width}>
      <div className="container" style={{ width }}>
        <div className="header">
          <p>{header}</p>
          <FiCircle
            onClick={() => closePopUp && closePopUp(false)}
            size={12}
            className="icon_close"
          />
        </div>
        <S.Main>{children}</S.Main>
      </div>
      <div
        aria-hidden="true"
        className="background"
        onClick={() => closePopUp && closePopUp(false)}
      />
    </S.PopUp>
  );
};
export default PopUp;
