import React, { useState } from 'react';
import { RiArrowDownLine } from 'react-icons/ri';
import { format } from 'date-fns';

import Comments from './Comments';

interface CheckinList {
  oldCheckin: {
    id: number;
    created_at?: Date;
  };
}

const OldCheckin: React.FC<CheckinList> = ({ oldCheckin }) => {
  const [showComments, setShowComments] = useState(false);

  return (
    <div className="oldCheckin">
      <header>
        <p>
          {format(
            oldCheckin.created_at
              ? new Date(oldCheckin.created_at)
              : new Date(),
            'dd/MM/yyyy'
          )}
        </p>
        <RiArrowDownLine
          onClick={() => setShowComments(!showComments)}
          size={19}
          color="var(--red-primary)"
        />
      </header>
      {showComments && <Comments id={oldCheckin.id} />}
    </div>
  );
};

export default OldCheckin;
