import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function deletePhase(phaseId: number): Promise<void> {
  try {
    await apiV2.delete(`/phases/${phaseId}`);
  } catch {
    toast.error(
      'Ocorreu algum problema ao deletar a fase, atualize a página e tente novamente :S'
    );
  }
}
