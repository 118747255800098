import React, { FC } from 'react';

import { TextInput as NewTextInput } from './TextInput';

import { Select as NewSelect } from './Select';
import { Button as NewButton } from './Button';

import { ButtonProps, TextInputProps, SelectProps } from './types';

const Button: FC<ButtonProps> = ({ ...rest }) => {
  return <NewButton {...rest} />;
};

const TextInput: FC<TextInputProps> = ({ ...rest }) => {
  return <NewTextInput {...rest} />;
};

const Select: FC<SelectProps> = ({ ...rest }) => {
  return <NewSelect {...rest} />;
};

export { TextInput, Select, Button };
