import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  > div {
    max-height: 80vh;
  }

  header,
  main,
  footer {
    width: 20rem;
  }

  header {
    display: flex;
    align-items: center;
  }

  main {
    select {
      width: 15rem;
      height: 1.5rem;
      border-radius: 0.4rem;
      background-color: ${({ theme }) => theme.backgroundPrimary};
      color: ${({ theme }) => theme.text};
      border: none;
      margin-top: 0.5rem;
    }

    overflow-x: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar {
      height: 0.4rem;
      border-radius: 0.4rem;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.text};
    }
  }
`;

export const Content = styled.div`
  width: 95%;
`;

export const InputWrapper = styled.div`
  width: 8rem;
  margin: 0 0.8rem;
`;

export const RemoveResponsibles = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 0.5rem;
  }
`;
