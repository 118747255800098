import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  background: transparent;

  div {
    display: flex;
    flex-direction: column !important;
  }

  .description {
    font-size: 0.8rem;
    color: var(--gray);
  }

  .email {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;

    font-size: 0.8rem;
    background: ${({ theme }) => theme.backgroundSecondary};
    color: ${({ theme }) => theme.text};
    padding: 0.4rem;
    border-radius: 8px;
    margin-top: 0.5rem;
  }

  .email svg {
    cursor: pointer;
  }
`;
