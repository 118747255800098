/* eslint-disable react/require-default-props */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  RiArrowUpSLine,
  RiCommandLine,
  RiArrowDownSLine,
  RiSeedlingLine,
  RiSpamLine,
  RiSendPlaneLine,
  RiPushpin2Line,
  RiStarHalfFill,
  RiCheckLine,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import { Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import * as S from './styles';
import { AddNew } from '../../../../components/AddNew';
import { TaskCard } from '../TaskCard';
import { Modal } from '../../../../styles';
import PhaseEdit from '../../../../components/Modals/PhaseEdit';
import NewTask from '../../../../components/Modals/NewTask';
import { EditFinalPhase } from '../../../../components/Modals/EditFinalPhase';
import { restorePhase } from '../../../../services/RestorePhase';
import { SetPhases } from '../../../../utils/SetPhases';
import { useTasks } from '../../../../../../contexts/TasksContext';

interface PhaseCardProps {
  index: number;
  phases: Phase[];
  phase: Phase;
  title: string;
  display: boolean;
  isDragging?: boolean;
  setDragDisabled?: Dispatch<SetStateAction<boolean>>;
  projectId: number;
  groupId: number;
}

export const PhaseCard = ({
  index,
  phases,
  phase,
  title,
  display,
  isDragging,
  setDragDisabled,
  projectId,
  groupId,
}: PhaseCardProps): JSX.Element => {
  const { projects, setProjects } = useTasks();
  const [tasksArea, setTasksArea] = useState(true);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalNew, setOpenModalNew] = useState(false);

  const handleOpenModal = (type: string): void => {
    if (setDragDisabled) {
      setDragDisabled(true);
    }
    type === 'new'
      ? setOpenModalNew(!openModalNew)
      : setOpenModalEdit(!openModalEdit);
  };

  const handleCloseModal = (): void => {
    if (setDragDisabled) {
      setDragDisabled(false);
    }
    setOpenModalEdit(false);
    setOpenModalNew(false);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') {
      setOpenModalEdit(false);
      setOpenModalNew(false);
    }
    if (setDragDisabled) {
      setDragDisabled(false);
    }
  };

  const handleOpenTasks = (): void => {
    setTasksArea(!tasksArea);
  };

  const handleRestorePhase = useCallback(
    async (phaseToRestore: Phase) => {
      await restorePhase(phaseToRestore.id);

      const phaseUndeleted = {
        ...phaseToRestore,
        was_removed: false,
      };

      const phasesUpdate = phases.filter(
        phaseItem => phaseItem.id !== phaseToRestore.id
      );

      phasesUpdate.splice(phaseToRestore.order! - 1, 0, phaseUndeleted);
      const newProjects = SetPhases(phasesUpdate, projects, projectId, groupId);
      setProjects(() => newProjects);
      toast.success('Fase restaurada com sucesso');
    },

    [phases, groupId, projectId, projects, setProjects]
  );

  const iconPhase = useMemo(() => {
    switch (phase.icon_name) {
      case 'RiSeedlingLine':
        return (
          <RiSeedlingLine
            className="icon"
            color="var(--green-primary)"
            size={20}
          />
        );
      case 'RiCommandLine':
        return (
          <RiCommandLine
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiStarHalfFill':
        return (
          <RiStarHalfFill
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiPushpin2Line':
        return (
          <RiPushpin2Line
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiSpamLine':
        return (
          <RiSpamLine
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiSendPlaneLine':
        return (
          <RiSendPlaneLine
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiCheckLine':
        return (
          <RiCheckLine className="icon" color="var(--cyan-primary)" size={20} />
        );
      default:
        return false;
    }
  }, [phase.icon_name]);

  const colorToSticker = useMemo(() => {
    if (index === 0) {
      return 'var(--green-primary)';
    }
    if (index === phases.length - 1) {
      return 'var(--cyan-primary)';
    }
    return 'var(--yellow-primary)';
  }, [index, phases.length]);

  return (
    <>
      <S.Wrapper isDragging={isDragging}>
        <S.Main className="drag">
          <div id="name">
            <button onClick={handleOpenTasks} type="button">
              {iconPhase}
              <h4>
                {' '}
                {title && title.length > 27
                  ? `${title.substring(0, 20)}...`
                  : title}
              </h4>
            </button>
            <button type="button" onClick={() => handleOpenModal('edit')}>
              <FaRegEdit size={12} id="edit-button" />
            </button>
          </div>
          <div>
            {phase.was_removed && (
              <S.RestorePhase>
                <button
                  type="button"
                  title={phase.name}
                  onClick={() => handleRestorePhase(phase)}
                >
                  <RiDeleteBin2Line size={14} color="var(--red-primary)" />
                  <p id="restore-label">Restaurar Fase</p>
                </button>
              </S.RestorePhase>
            )}
          </div>
          <div id="add-new" />
          <div id="add-new" />
          {tasksArea ? (
            <div id="arrow-box">
              {index === 0 ? (
                <AddNew
                  labelText="Adicionar uma task"
                  onClick={() => handleOpenModal('new')}
                />
              ) : (
                <div />
              )}

              <button type="button" onClick={handleOpenTasks}>
                <RiArrowUpSLine className="icon arrow" size={20} />
              </button>
            </div>
          ) : (
            <div id="arrow-box">
              {index === 0 ? (
                <AddNew
                  labelText="Adicionar uma task"
                  onClick={() => handleOpenModal('new')}
                />
              ) : (
                <div />
              )}

              <button type="button" onClick={handleOpenTasks}>
                <RiArrowDownSLine className="icon arrow" size={20} />
              </button>
            </div>
          )}
        </S.Main>

        <S.StickerColor style={{ background: `${colorToSticker}` }} />
      </S.Wrapper>
      <Droppable droppableId={`${index}`} type="task" direction="vertical">
        {provided => (
          <S.Container ref={provided.innerRef} {...provided.droppableProps}>
            {tasksArea && (
              <>
                {phase.tasks && phase.tasks.length ? (
                  phase.tasks.map((task, taskIndex) => {
                    return (
                      <div className="taskCard" key={task.id}>
                        <S.TaskWrapper className="content">
                          <S.Circlemark
                            style={{ background: `${colorToSticker}` }}
                            display={display || undefined}
                          />
                          <S.LineMark
                            className="lineDotted"
                            style={{ background: `${colorToSticker}` }}
                            display={display || undefined}
                          />
                          <TaskCard
                            phase={phase}
                            task={task}
                            index={taskIndex}
                            stickerColor={colorToSticker}
                            setDragDisabled={setDragDisabled}
                            opacity={index === phases.length - 1 ? '0.6' : '1'}
                            projectId={projectId}
                            groupId={groupId}
                          />
                        </S.TaskWrapper>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      style={{
                        margin: '3.5rem 0',
                      }}
                    >
                      Não há task
                    </p>
                  </div>
                )}
              </>
            )}
            {provided.placeholder}
          </S.Container>
        )}
      </Droppable>
      {openModalNew && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NewTask
            phases={phases}
            phaseId={phase.id}
            handleClose={handleCloseModal}
          />
        </Modal>
      )}
      {openModalEdit && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          {index === phases.length - 1 ? (
            <EditFinalPhase
              handleClose={handleCloseModal}
              phase={phase}
              phases={phases}
              projectIdByPhase={projectId}
              groupIdByPhase={groupId}
            />
          ) : (
            <PhaseEdit
              phase={phase}
              phases={phases}
              groupId={groupId}
              projectId={projectId}
              handleClose={handleCloseModal}
            />
          )}
        </Modal>
      )}
    </>
  );
};
