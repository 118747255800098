import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  button.showArea {
    width: 80%;
    background: none;
    border: none;
    color: var(--red-primary);
  }
`;

export const TopBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.text};
  margin-bottom: 1rem;

  h4 {
    font-size: 1.2rem;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  > button {
    width: 100px;
    height: 30px;
    font-size: 0.7rem;
    font-weight: bold;
    color: var(--white);
    background-color: var(--red-primary);
    border: none;
    border-radius: 0.4rem;
    margin-left: 3rem;
  }
`;

export const Cards = styled.div`
  width: 98.5%;
  min-height: 100%;
  position: static;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  margin-bottom: 1rem;
  @media (max-width: 1115px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
