import React, { useEffect } from 'react';

import {
  Container,
  Title,
  SalesFunnel,
  FirstLine,
  Leads,
  LastLine,
} from './styles';

interface NewSalesFunnelChart {
  funnels: {
    id: number;
    name: string;
    value: number;
    position: number;
  }[];
}

const SalesFunnelChart: React.FC<NewSalesFunnelChart> = ({ funnels = [] }) => {
  const minValue = Math.min(...funnels.map(funnel => funnel.value));

  useEffect(() => {
    funnels.sort((a, b) => a.position - b.position);
  }, [funnels]);

  const convertValueInPercent = (value: number) => {
    const highestValue = Math.max(...funnels.map(funnel => funnel.value), 0);

    const percentage = ((value * 100) / highestValue).toFixed(3);
    if (Number(percentage) >= 100) return 101;
    return percentage;
  };

  return (
    <Container>
      <Title>Funil de vendas</Title>
      <p style={{ alignSelf: 'center' }}>
        Entradas de vendas em forma de funil
      </p>
      <SalesFunnel>
        <FirstLine>
          <h3> </h3>
          <div>
            <h4>100%</h4>
            <hr />
          </div>
        </FirstLine>

        {funnels.map(funnel => {
          return (
            <Leads key={funnel.id}>
              <h3>{funnel.name}</h3>
              <div>
                <h4
                  style={{
                    width: `${convertValueInPercent(Number(funnel.value))}%`,
                  }}
                >
                  {Number(funnel.value) / 100}
                </h4>
              </div>
            </Leads>
          );
        })}

        <LastLine>
          <h3> </h3>
          <div>
            <hr
              style={{
                width: `${convertValueInPercent(minValue)}%`,
              }}
            />
            <h4>{convertValueInPercent(minValue)}%</h4>
          </div>
        </LastLine>
      </SalesFunnel>
    </Container>
  );
};

export default SalesFunnelChart;
