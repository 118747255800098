import styled from 'styled-components';

interface InputProps {
  width: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
  h1 {
    margin-bottom: 20px;
  }
`;
export const Input = styled.div<InputProps>`
  display: flex;
  align-items: center;
  input {
    background: ${({ theme }) => theme.backgroundSecondary};
    border: 1px solid ${({ theme }) => theme.text};
    border-radius: 50px;
    height: 40px;
    width: ${props => props.width};
    padding: 0 10px;
    color: ${({ theme }) => theme.text};

    ::placeholder {
      color: var(--gray);
    }
  }
`;
