import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function createSubtask(
  taskId: number,
  subtaskName: string
): Promise<Task> {
  try {
    const response = await apiV2.post(`subtasks`, {
      task_id: taskId,
      name: subtaskName,
      include_task_progress: true,
    });

    const task = response.data;

    return task;
  } catch {
    toast.error(
      'Ocorreu algum problema ao criar a subtask, atualize a página e tente novamente :S'
    );

    return {} as Task;
  }
}
