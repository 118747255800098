import React, { useCallback } from 'react';

import { RiDashboardLine } from 'react-icons/ri';
import CheckinSlide from '../../../components/CheckinSlide';

import { DashData, CheckinDraft } from '../../../types';

import Card from './Card';
import InvestmentChart from './InvestmentChart';
import FaturamentoChart from './FaturamentoChart';
import CplCpoChart from './CplCpoChart';
import ConversionChart from './ConversionChart';
import SalesFunnelChart from './SalesFunnelChart';
import TableChart from './TableChart';

import * as S from './styles';

interface NewFunnelSlide {
  dashData: DashData;
  checkinData: CheckinDraft;
}

const FunnelSlide: React.FC<NewFunnelSlide> = ({ dashData, checkinData }) => {
  const searchForSells = useCallback(() => {
    let result = 1; // neutral element in a division.
    const sortedFunnels = checkinData.funnels.sort(
      (a, b) => a.position - b.position
    );
    if (sortedFunnels.length > 0) {
      result = sortedFunnels[sortedFunnels.length - 1].value;
    }

    return result;
  }, [checkinData.funnels]);
  const ticketmedio = checkinData.revenue / searchForSells();

  const handleFixNumber = useCallback(value => {
    return `${value.toFixed(2)}`.replace('.', ',');
  }, []);

  const handleFormatNumber = useCallback(
    (value: number) => {
      return `R$${handleFixNumber(value / 100)}`;
    },
    [handleFixNumber]
  );

  const handleRoi = useCallback(() => {
    const preRoi =
      checkinData.revenue * (checkinData.client_margin / (100 * 100));
    const roi = preRoi / checkinData.investment;

    return handleFixNumber(roi);
  }, [checkinData, handleFixNumber]);

  return (
    <CheckinSlide redWords={['r']}>
      <S.Container>
        {!checkinData.bi_link ? (
          <S.Dash>
            <header>
              <RiDashboardLine size={28} color="var(--green-primary)" />
              <h2>Dash</h2>
            </header>
            <div className="cards">
              <Card
                name="Investimento"
                value={handleFormatNumber(checkinData.investment)}
                variation={dashData.variations.investmentVar}
              />
              <Card
                name="Faturamento"
                value={handleFormatNumber(checkinData.revenue)}
                variation={dashData.variations.revenueVar}
              />
              <Card
                name="Lucro Bruto"
                value={handleFormatNumber(
                  checkinData.revenue - checkinData.investment
                )}
                variation={dashData.variations.grossProfitVar}
              />
              <Card
                name="Ticket médio"
                value={`R$${
                  ticketmedio === Infinity ? 0 : handleFixNumber(ticketmedio)
                }`}
                variation={dashData.variations.avgTicketVar}
              />
              <Card
                name="ROAS"
                value={handleFixNumber(
                  checkinData.revenue / checkinData.investment
                )}
                variation={dashData.variations.roasVar}
              />
              <Card
                name="ROI"
                value={handleRoi()}
                variation={dashData.variations.roiVar}
              />
            </div>

            <div className="charts">
              <InvestmentChart dashData={dashData} />
              <FaturamentoChart dashData={dashData} />
              <CplCpoChart dashData={dashData} />
              <ConversionChart dashData={dashData} />
              <SalesFunnelChart funnels={checkinData.funnels} />
              <TableChart dashData={dashData} />
            </div>
          </S.Dash>
        ) : (
          <iframe
            width="100%"
            height="580"
            title="powerbi"
            src={checkinData.bi_link}
            frameBorder="0"
          />
        )}
      </S.Container>
    </CheckinSlide>
  );
};

export default FunnelSlide;
