import styled from 'styled-components';

export const NewChargePopUp = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .container {
    opacity: 1;
    width: 100%;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 2px;
    overflow-x: auto;
    overflow-y: auto;

    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.backgroundSecondary};
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 10px;
    }

    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: ${({ theme }) => theme.backgroundSecondary};
      color: ${({ theme }) => theme.text};
      padding: 5px;
      font-size: 16px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;

      p {
        margin-left: 8px;
      }

      .FiX {
        margin-right: 10px;
        cursor: pointer;
        color: var(--red-primary);
        transition: 0.3s;
        background-color: var(--red-primary);
        border-radius: 80%;
        margin-top: 2px;

        :hover {
          color: var(--gray);
          background-color: var(--gray);
        }
      }
    }

    main {
      padding: 18px;
    }
  }
`;
