import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function approveContract(
  id: number,
  file?: File
): Promise<boolean> {
  try {
    const formData = new FormData();
    formData.append('prop1', 'value1');
    formData.append('prop2', 'value2');
    if (file) {
      formData.append('file', file);
    }

    await apiV2.post(
      `/contracts/approve/${id}`,
      file && formData,
      file && {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    toast.success('O contrato foi aprovado e está aguardando as assinaturas.');

    return true;
  } catch (err) {
    toast.error(
      `Ocorreu algum problema ao aprovar este contrato, atualize a página e tente novamente 😕 `
    );

    return false;
  }
}
