import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.text};

  > .cards {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2rem;
  }

  @media (max-width: 1367px) {
    > .cards {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media (max-width: 950px) {
    > .cards {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
`;
