import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;

  .simple-input {
    width: 100%;
  }

  h1 {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    padding-top: 3rem;
    padding-right: 0rem;
  }
`;
