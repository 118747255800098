import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import apiV2 from '../../services/apiV2';

import selectProjectIndexById from '../../modules/Tasks/components/Modals/Playbook/utils/SelectProjectIndexById';
import selectProjectById from '../../modules/Tasks/components/Modals/Playbook/utils/SelectProjectById';
import getRandomId from '../../modules/Tasks/components/Modals/Playbook/utils/GetRandomInteger';

type ExportPlaybookContextData = {
  globalProjects: IProject[];
  setGlobalProjects: Dispatch<SetStateAction<IProject[]>>;
  updateProjectName: (projectId: number, newName: string) => void;
  updateProjectDescription: (projectId: number, newDescription: string) => void;
  deleteProject: (projectId: number) => void;

  addNewGroup: (projectId: number) => void;
  updateGroupName: (
    projectId: number,
    groupId: number,
    newName: string
  ) => void;
  updateGroupDescription: (
    projectId: number,
    groupId: number,
    newDescription: string
  ) => void;
  deleteGroup: (projectId: number, groupId: number) => void;

  addNewPhase: (projectId: number, groupId: number) => void;
  updatePhaseName: (
    projectId: number,
    groupId: number,
    phaseId: number,
    newName: string
  ) => void;
  deletePhase: (projectId: number, groupId: number, phaseId: number) => void;

  addNewTask: (projectId: number, groupId: number) => void;
  updateTaskName: (
    projectId: number,
    groupId: number,
    taskId: number,
    newName: string
  ) => void;
  updateTaskDescription: (
    projectId: number,
    groupId: number,
    taskId: number,
    newDescription: string
  ) => void;
  deleteTask: (projectId: number, groupId: number, taskId: number) => void;

  addNewPhaseChecklistItem: (
    projectId: number,
    groupId: number,
    phaseId: number
  ) => void;
  updatePhaseChecklistItemName: (
    projectId: number,
    groupId: number,
    phaseId: number,
    phaseChecklistItemId: number,
    newName: string
  ) => void;
  deletePhaseChecklistItem: (
    projectId: number,
    groupId: number,
    phaseId: number,
    phaseChecklistItemId: number
  ) => void;

  addNewIndividualTask: (projectId: number) => void;
  updateIndividualTaskName: (
    projectId: number,
    individualTaskId: number,
    newName: string
  ) => void;
  updateIndividualTaskDescription: (
    projectId: number,
    individualTaskId: number,
    newDescription: string
  ) => void;
  deleteIndividualTask: (projectId: number, individualTaskId: number) => void;

  updateDueDateInIndividualTask: (
    newDueDate: Date,
    projectId: number,
    individualTaskId: number
  ) => void;
  updateDueDateInTask: (
    newDueDate: Date,
    projectId: number,
    groupId: number,
    taskId: number
  ) => void;
  getMinimunIndividualTasksDueDate: (projectId: number) => number;
  getMinimunTasksDueDate: (projectId: number, groupId: number) => number;
  getProjectDueDate: (projectId: number) => number;

  createGlobalProjects: (
    clientId: string,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => void;

  createPlaybook: (
    unitId: number,
    name: string,
    isOfficialSelected: boolean,
    handleClose: () => void,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => void;
};

const ExportPlaybookContext = createContext({} as ExportPlaybookContextData);

interface ExportPlaybookContextProviderProps {
  children: ReactNode;
}

const ExportPlaybookProvider: React.FC<ExportPlaybookContextProviderProps> = ({
  children,
}: ExportPlaybookContextProviderProps) => {
  const [globalProjects, setGlobalProjects] = useState<IProject[]>([]);

  const createGlobalProjects = async (
    clientId: string,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => {
    setLoading(true);

    const getProjects = await apiV2.get(`/projects/${clientId}`);

    const newProjects: IProject[] = getProjects.data;

    const projectsWithGroupsAndIndividualTasks = await Promise.all(
      newProjects.map(async project => {
        const newProject = project;

        const responseGroups = await apiV2.get(`/groups/${newProject.id}`);

        const newGroups: IGroup[] = responseGroups.data.groups;

        const individualTasks: ITask[] = responseGroups.data.tasks;

        const groupsWithPhases = await Promise.all(
          newGroups.map(async group => {
            const newGroup = group;

            const responsePhases = await apiV2.get(`/phases/${newGroup.id}`);

            const allPhases: IPhase[] = responsePhases.data;

            const allTasks = allPhases.flatMap((phase: IPhase) => phase.tasks);

            const allPhasesWithTasksInFirstPhase = allPhases.map((phase, i) => {
              const newPhase = phase;

              if (i === 0) {
                newPhase.tasks = allTasks;
              }
              if (i !== 0) {
                newPhase.tasks = [];
              }

              return newPhase;
            });

            newGroup.phases = allPhasesWithTasksInFirstPhase;

            return newGroup;
          })
        );

        newProject.groups = groupsWithPhases;

        newProject.tasks = individualTasks;

        return newProject;
      })
    );

    setGlobalProjects(projectsWithGroupsAndIndividualTasks);
    setLoading(false);
  };

  const updateProjectName = (projectId: number, newName: string) => {
    const newGlobalProjects = [...globalProjects];

    const projectIndex = selectProjectIndexById(projectId, newGlobalProjects);

    newGlobalProjects[projectIndex] = {
      ...newGlobalProjects[projectIndex],
      name: newName,
    };

    setGlobalProjects(() => newGlobalProjects);
  };

  const updateProjectDescription = (
    projectId: number,
    newDescription: string
  ) => {
    const newGlobalProjects = [...globalProjects];

    const projectIndex = selectProjectIndexById(projectId, newGlobalProjects);

    newGlobalProjects[projectIndex] = {
      ...newGlobalProjects[projectIndex],
      desc: newDescription,
    };

    setGlobalProjects(() => newGlobalProjects);
  };

  const deleteProject = (projectId: number) => {
    const selectedProject = selectProjectById(projectId, globalProjects);

    if (!selectedProject) return;

    const projectsWithoutDeletedProject = globalProjects.filter(project => {
      return project.id !== projectId;
    });

    toast.success(`O projeto '${selectedProject.name}' foi deletado!`);

    setGlobalProjects(() => projectsWithoutDeletedProject);
  };

  const addNewGroup = (projectId: number) => {
    const groupId = getRandomId();

    const newGlobalProjects = [...globalProjects];

    const projectIndex = selectProjectIndexById(projectId, newGlobalProjects);

    if (projectIndex === -1) return;

    let newGroups = newGlobalProjects[projectIndex].groups;

    if (!newGroups) {
      newGroups = [];
    }

    const newGroup = {
      id: groupId,
      name: 'Novo grupo',
      desc: 'Adicionar descrição',
      order: newGroups.length,
      status: 'aberto',
      project_id: projectId,
      due_date: String(new Date()),
      kanban_phase: '',
      progress: 0,
      phases: [
        {
          id: getRandomId(),
          name: 'To Do',
          desc: 'Fase inicial',
          status: 'aberto',
          group_id: groupId,
          order: 0,
          was_removed: false,
          remove_users: false,
          icon_name: 'RiSeedlingLine',
          phase_checklist_items: [],
          tasks: [],
          progress: 0,
        },
        {
          id: getRandomId(),
          name: 'Done',
          desc: 'Fase final',
          status: 'aberto',
          group_id: groupId,
          order: 1,
          was_removed: false,
          remove_users: false,
          icon_name: 'RiCheckLine',
          phase_checklist_items: [],
          tasks: [],
          progress: 0,
        },
      ],
    };

    newGlobalProjects[projectIndex] = {
      ...newGlobalProjects[projectIndex],
      groups: [...newGroups, newGroup],
    };

    setGlobalProjects(() => newGlobalProjects);
  };

  const updateGroupName = (
    projectId: number,
    groupId: number,
    newName: string
  ) => {
    const newGlobalProjects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, newGlobalProjects);

    if (projectIndex === -1) return;

    const selectedProject = newGlobalProjects[projectIndex];

    if (!selectedProject.groups) return;

    const groupIndex = selectedProject.groups.findIndex(
      (group: IGroup) => group.id === groupId
    );

    selectedProject.groups[groupIndex].name = newName;

    setGlobalProjects(() => newGlobalProjects);
  };

  const updateGroupDescription = (
    projectId: number,
    groupId: number,
    newDescription: string
  ) => {
    const newGlobalProjects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, newGlobalProjects);

    if (projectIndex === -1) return;

    const selectedProject = newGlobalProjects[projectIndex];

    if (!selectedProject.groups) return;

    const groupIndex = selectedProject.groups.findIndex(
      (group: IGroup) => group.id === groupId
    );

    selectedProject.groups[groupIndex].desc = newDescription;

    setGlobalProjects(() => newGlobalProjects);
  };

  const deleteGroup = (projectId: number, groupId: number) => {
    const newGlobalProjects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, newGlobalProjects);

    if (projectIndex === -1) return;

    const selectedProject = newGlobalProjects[projectIndex];

    if (!selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup) return;

    const groupsWithoutDeletedGroup = selectedProject.groups.filter(
      group => group.id !== selectedGroup.id
    );

    newGlobalProjects[projectIndex].groups = groupsWithoutDeletedGroup;

    selectedProject.groups = groupsWithoutDeletedGroup;

    setGlobalProjects(() => newGlobalProjects);

    toast.success(`O grupo '${selectedGroup.name}' foi deletado!`);
  };

  function createNewPhaseObject(groupId: number, newPhaseOrder: number) {
    return {
      id: getRandomId(),
      name: 'Nova fase',
      desc: 'Adicionar descrição',
      status: 'aberto',
      group_id: groupId,
      order: newPhaseOrder,
      next_phase_id: 0,
      was_removed: false,
      remove_users: false,
      icon_name: 'RiStarHalfFill',
      phase_checklist_items: [],
      tasks: [],
      progress: 0,
      users_ids: [],
    };
  }

  const addNewPhase = (projectId: number, groupId: number) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup) return;

    if (!selectedGroup.phases) {
      selectedGroup.phases = [];
    }
    const oldPhases = [...selectedGroup.phases];

    const existFinalPhase = oldPhases.length > 1;

    const newPhaseOrder = oldPhases.length;

    const newPhase = createNewPhaseObject(groupId, newPhaseOrder);

    if (existFinalPhase) {
      oldPhases[oldPhases.length - 1].order = oldPhases.length + 1;
      oldPhases[0].order = 1;
    }

    const groupsWithNewPhase = [...oldPhases, newPhase];

    const groupsWithNewPhaseOrdered = groupsWithNewPhase.sort(
      (a, b) => a.order - b.order
    );

    selectedGroup.phases = groupsWithNewPhaseOrdered;

    setGlobalProjects(() => projects);
  };

  const updatePhaseName = (
    projectId: number,
    groupId: number,
    phaseId: number,
    newName: string
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const phases = [...selectedGroup.phases];

    const selectedPhase = phases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase) return;

    selectedPhase.name = newName;

    setGlobalProjects(() => projects);
  };

  const deletePhase = (projectId: number, groupId: number, phaseId: number) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const phaseToDelete = selectedGroup.phases.find(phase => {
      return phase.id === phaseId;
    });

    if (!phaseToDelete) return;

    const groupsWithoutDeletedPhase = selectedGroup.phases.filter(phase => {
      return phase.id !== phaseId;
    });

    toast.success(`A fase '${phaseToDelete.name}' foi deletada!`);

    selectedGroup.phases = groupsWithoutDeletedPhase;

    setGlobalProjects(() => projects);
  };

  function createNewTask(firstPhaseId: number, order: number) {
    return {
      id: getRandomId(),
      name: 'Nova task',
      desc: 'Adicionar descrição',
      status: 'aberto',
      due_date: new Date(),
      type: '',
      days_repeat: 0,
      checklist: false,
      phase_id: firstPhaseId,
      order,
      required: true,
      progress: 0,
      subtasks: [],
    };
  }

  const addNewTask = (projectId: number, groupId: number) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase) return;

    if (!selectedPhase.tasks) {
      selectedPhase.tasks = [];
    }
    const oldTasks = [...selectedPhase.tasks];

    const newTask = createNewTask(firstPhaseId, oldTasks.length + 1);

    const phasesWithNewTask = [...oldTasks, newTask];

    selectedPhase.tasks = phasesWithNewTask;

    setGlobalProjects(() => projects);
  };

  const updateTaskName = (
    projectId: number,
    groupId: number,
    taskId: number,
    newName: string
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase || !selectedPhase.tasks) return;

    const selectedTask = selectedPhase.tasks.find(task => {
      return task.id === taskId;
    });

    if (!selectedTask) return;

    selectedTask.name = newName;

    setGlobalProjects(() => projects);
  };

  const updateTaskDescription = (
    projectId: number,
    groupId: number,
    taskId: number,
    newDescription: string
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase || !selectedPhase.tasks) return;

    const selectedTask = selectedPhase.tasks.find(task => {
      return task.id === taskId;
    });

    if (!selectedTask) return;

    selectedTask.desc = newDescription;

    setGlobalProjects(() => projects);
  };

  const deleteTask = (projectId: number, groupId: number, taskId: number) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const oldPhases = [...selectedGroup.phases];

    if (!selectedGroup.phases[0]) return;

    const firstPhaseId = selectedGroup.phases[0].id;

    const selectedPhase = oldPhases.find(phase => {
      return phase.id === firstPhaseId;
    });

    if (!selectedPhase || !selectedPhase.tasks) return;

    const selectedTask = selectedPhase.tasks.find(task => task.id === taskId);

    if (!selectedTask) return;

    const taskWithoutDeletedTask = selectedPhase.tasks.filter(task => {
      return task.id !== selectedTask.id;
    });

    selectedPhase.tasks = taskWithoutDeletedTask;

    setGlobalProjects(() => projects);

    toast.success(`A tarefa '${selectedTask.name}' foi deletada!`);
  };

  const addNewPhaseChecklistItem = (
    projectId: number,
    groupId: number,
    phaseId: number
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const selectedPhase = selectedGroup.phases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase) return;

    if (!selectedPhase.phase_checklist_items) {
      selectedPhase.phase_checklist_items = [];
    }

    const oldPhaseChecklistItems = [...selectedPhase.phase_checklist_items];

    const newPhaseChecklistItem = {
      id: getRandomId(),
      name: 'Novo checklist',
      phase_id: phaseId,
      is_required: false,
    };

    const phasesWithNewChecklistItems = [
      ...oldPhaseChecklistItems,
      newPhaseChecklistItem,
    ];

    selectedPhase.phase_checklist_items = phasesWithNewChecklistItems;

    setGlobalProjects(() => projects);
  };

  const updatePhaseChecklistItemName = (
    projectId: number,
    groupId: number,
    phaseId: number,
    phaseChecklistItemId: number,
    newName: string
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const selectedPhase = selectedGroup.phases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase || !selectedPhase.phase_checklist_items) return;

    const oldPhaseChecklistItems = [...selectedPhase.phase_checklist_items];

    const selectedPhaseChecklistItem = oldPhaseChecklistItems.find(
      phaseChecklistItem => {
        return phaseChecklistItem.id === phaseChecklistItemId;
      }
    );

    if (!selectedPhaseChecklistItem) return;

    selectedPhaseChecklistItem.name = newName;

    setGlobalProjects(() => projects);
  };

  const deletePhaseChecklistItem = (
    projectId: number,
    groupId: number,
    phaseId: number,
    phaseChecklistItemId: number
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(group => {
      return group.id === groupId;
    });

    if (!selectedGroup || !selectedGroup.phases) return;

    const selectedPhase = selectedGroup.phases.find(phase => {
      return phase.id === phaseId;
    });

    if (!selectedPhase || !selectedPhase.phase_checklist_items) return;

    const selectedPhaseChecklistItem = selectedPhase.phase_checklist_items.find(
      phaseChecklistItem => {
        return phaseChecklistItem.id === phaseChecklistItemId;
      }
    );

    if (!selectedPhaseChecklistItem) return;

    const phaseChecklistItemsWithoutDeletedPhaseChecklistItem = selectedPhase.phase_checklist_items.filter(
      phaseChecklistItem => {
        return phaseChecklistItem.id !== selectedPhaseChecklistItem.id;
      }
    );

    selectedPhase.phase_checklist_items = phaseChecklistItemsWithoutDeletedPhaseChecklistItem;

    setGlobalProjects(() => projects);
  };

  function createIndividualTask(projectId: number, order: number) {
    return {
      id: getRandomId(),
      name: 'Nova tarefa individual',
      desc: 'Adicione uma descrição',
      status: 'aberto',
      due_date: new Date(),
      type: '',
      days_repeat: 0,
      checklist: true,
      project_id: projectId,
      order,
      required: false,
      progress: 0,
      subtasks: [],
    };
  }

  const addNewIndividualTask = (projectId: number) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject) return;

    if (!selectedProject.tasks) {
      selectedProject.tasks = [];
    }

    const oldIndividualTasks = [...selectedProject.tasks];

    const newIndividualTask = createIndividualTask(
      projectId,
      oldIndividualTasks.length + 1
    );

    const projectWithNewIndividualTask = [
      ...oldIndividualTasks,
      newIndividualTask,
    ];

    selectedProject.tasks = projectWithNewIndividualTask;

    setGlobalProjects(() => projects);
  };

  const updateIndividualTaskName = (
    projectId: number,
    individualTaskId: number,
    newName: string
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.tasks) return;

    const selectedIndividualTask = selectedProject.tasks.find(
      individualTask => {
        return individualTask.id === individualTaskId;
      }
    );

    if (!selectedIndividualTask) return;

    selectedIndividualTask.name = newName;

    setGlobalProjects(() => projects);
  };

  const updateIndividualTaskDescription = (
    projectId: number,
    individualTaskId: number,
    newDescription: string
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.tasks) return;

    const selectedIndividualTask = selectedProject.tasks.find(
      individualTask => {
        return individualTask.id === individualTaskId;
      }
    );

    if (!selectedIndividualTask) return;

    selectedIndividualTask.desc = newDescription;

    setGlobalProjects(() => projects);
  };

  const deleteIndividualTask = (
    projectId: number,
    individualTaskId: number
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.tasks) return;

    const selectedIndividualTask = selectedProject.tasks.find(
      individualTask => {
        return individualTask.id === individualTaskId;
      }
    );

    if (!selectedIndividualTask) return;

    const tasksWithoutDeletedIndividualTask = selectedProject.tasks.filter(
      individualtask => {
        return individualtask.id !== selectedIndividualTask.id;
      }
    );

    selectedProject.tasks = tasksWithoutDeletedIndividualTask;

    setGlobalProjects(() => projects);

    toast.success(
      `A tarefa individual '${selectedIndividualTask.name}' foi deletada!`
    );
  };

  const updateDueDateInIndividualTask = (
    newDueDate: Date,
    projectId: number,
    individualTaskId: number
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.tasks) return;

    const selectedIndividualTask = selectedProject.tasks.find(
      individualTask => individualTask.id === individualTaskId
    );

    if (!selectedIndividualTask) return;

    selectedIndividualTask.due_date = newDueDate;

    setGlobalProjects(() => projects);
  };

  const updateDueDateInTask = (
    newDueDate: Date,
    projectId: number,
    groupId: number,
    taskId: number
  ) => {
    const projects = cloneDeep(globalProjects);

    const projectIndex = selectProjectIndexById(projectId, projects);

    const selectedProject = projects[projectIndex];

    if (!selectedProject || !selectedProject.groups) return;

    const selectedGroup = selectedProject.groups.find(
      group => group.id === groupId
    );

    if (!selectedGroup || !selectedGroup.phases) return;

    const firstPhase = selectedGroup.phases[0];

    if (!firstPhase.tasks) return;

    const selectedTask = firstPhase.tasks.find(task => task.id === taskId);

    if (!selectedTask) return;

    selectedTask.due_date = newDueDate;

    setGlobalProjects(() => projects);
  };

  const getMinimunTasksDueDate = (projectId: number, groupId: number) => {
    const newProjects = [...globalProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });
    let minimunDeadline = -1;

    if (!selectedProject || !selectedProject.groups) return minimunDeadline;

    const selectedGroup = selectedProject.groups.find(
      group => group.id === groupId
    );

    if (!selectedGroup || !selectedGroup.phases) return minimunDeadline;

    const firstPhase = selectedGroup.phases[0];

    if (!firstPhase || !firstPhase.tasks) return minimunDeadline;

    const allTasks = firstPhase.tasks;

    if (!allTasks) return minimunDeadline;

    if (allTasks.length > 1) {
      const taskWithminimunDeadline = allTasks.reduce((prev, curr) => {
        const previous = prev;
        const current = curr;
        if (!(previous.due_date instanceof Object)) {
          if (typeof previous.due_date === 'string') {
            previous.due_date = new Date(previous.due_date);
          } else {
            previous.due_date = new Date();
          }
        }
        if (!(current.due_date instanceof Object)) {
          if (typeof current.due_date === 'string') {
            current.due_date = new Date(current.due_date);
          } else {
            current.due_date = new Date();
          }
        }
        return previous.due_date.getTime() < current.due_date.getTime()
          ? prev
          : curr;
      });

      minimunDeadline = taskWithminimunDeadline.due_date.getTime();
    }

    if (allTasks.length === 1) {
      const taskWithMinimunDeadline = allTasks[0];

      if (!(taskWithMinimunDeadline.due_date instanceof Date)) {
        taskWithMinimunDeadline.due_date = new Date();
      }

      minimunDeadline = taskWithMinimunDeadline.due_date.getTime();
    }

    return minimunDeadline;
  };

  const getMinimunIndividualTasksDueDate = (projectId: number) => {
    const newProjects = [...globalProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });
    let minimunDeadline = -1;

    if (!selectedProject || !selectedProject.tasks) return minimunDeadline;

    const allTasks = selectedProject.tasks;

    if (!allTasks) return minimunDeadline;

    if (allTasks.length > 1) {
      const taskWithminimunDeadline = allTasks.reduce((prev, curr) => {
        const previous = prev;
        const current = curr;

        if (!(previous.due_date instanceof Object)) {
          if (typeof previous.due_date === 'string') {
            previous.due_date = new Date(previous.due_date);
          } else {
            previous.due_date = new Date();
          }
        }
        if (!(current.due_date instanceof Object)) {
          if (typeof current.due_date === 'string') {
            current.due_date = new Date(current.due_date);
          } else {
            current.due_date = new Date();
          }
        }
        return previous.due_date.getTime() < current.due_date.getTime()
          ? prev
          : curr;
      });

      minimunDeadline = taskWithminimunDeadline.due_date.getTime();
    }

    if (allTasks.length === 1) {
      const taskWithMinimunDeadline = allTasks[0];

      if (!(taskWithMinimunDeadline.due_date instanceof Date)) {
        taskWithMinimunDeadline.due_date = new Date();
      }

      minimunDeadline = taskWithMinimunDeadline.due_date.getTime();
    }

    return minimunDeadline;
  };

  const getProjectDueDate = (projectId: number) => {
    const newProjects = [...globalProjects];

    const selectedProject = newProjects.find(project => {
      return project.id === projectId;
    });

    let deadline = -1;
    let individualTaskDeadline = -1;
    let groupDeadline = -1;

    if (!selectedProject) return deadline;

    const allIndividualTasks = selectedProject.tasks;

    if (!allIndividualTasks) {
      individualTaskDeadline = -1;
    }

    if (allIndividualTasks && allIndividualTasks.length > 1) {
      const individualTaskWithMinimunDeadline = allIndividualTasks.reduce(
        (prev, curr) => {
          return prev.due_date < curr.due_date ? prev : curr;
        }
      );

      individualTaskDeadline = new Date(
        individualTaskWithMinimunDeadline.due_date
      ).getTime();
    }

    if (allIndividualTasks && allIndividualTasks.length === 1) {
      const individualTaskWithMinimunDeadline = allIndividualTasks[0].due_date;

      individualTaskDeadline = new Date(
        individualTaskWithMinimunDeadline
      ).getTime();
    }

    const allGroups = selectedProject.groups;

    if (!allGroups) {
      groupDeadline = -1;
    }

    if (allGroups && allGroups.length === 1) {
      const allGroupsDuedate: number[] = [];
      const groupDueDate = getMinimunTasksDueDate(projectId, allGroups[0].id);

      allGroupsDuedate.push(groupDueDate);

      [groupDeadline] = allGroupsDuedate;
    }

    if (allGroups && allGroups.length > 1) {
      const allGroupsDuedate: number[] = [];
      allGroups.map(group => {
        const groupDueDate = getMinimunTasksDueDate(projectId, group.id);

        return allGroupsDuedate.push(groupDueDate);
      });

      groupDeadline = allGroupsDuedate.reduce((prev, curr) => {
        return prev < curr ? prev : curr;
      });
    }

    if (groupDeadline === -1) {
      deadline = individualTaskDeadline;
    }
    if (individualTaskDeadline === -1) {
      deadline = groupDeadline;
    }

    if (groupDeadline !== -1 && individualTaskDeadline !== -1)
      deadline =
        groupDeadline <= individualTaskDeadline
          ? individualTaskDeadline
          : groupDeadline;

    return deadline;
  };

  const createPlaybook = async (
    unitId: number,
    name: string,
    isOfficialSelected: boolean,
    handleClose: () => void,
    setLoading: Dispatch<SetStateAction<boolean>>
  ) => {
    const data = {
      playbook: {
        name,
        desc: 'Descrição',
        isOfficial: isOfficialSelected,
        rating: 0,
        unit_id: Number(unitId),
      },
      projects: globalProjects,
    };

    try {
      setLoading(true);
      await apiV2.post(`/playbooks/export`, data);

      toast.success('Playbook criado com sucesso!');
    } catch (err) {
      toast.error(
        'Problema ao criar Playbook, confira os campos e tente novamente 😕'
      );
    } finally {
      setTimeout(() => {
        setLoading(false);
        handleClose();
      }, 2000);
    }
  };

  return (
    <ExportPlaybookContext.Provider
      value={{
        globalProjects,
        setGlobalProjects,
        updateProjectName,
        updateProjectDescription,
        deleteProject,
        addNewGroup,
        updateGroupName,
        updateGroupDescription,
        deleteGroup,
        addNewPhase,
        updatePhaseName,
        deletePhase,
        addNewTask,
        updateTaskName,
        updateTaskDescription,
        deleteTask,
        addNewPhaseChecklistItem,
        updatePhaseChecklistItemName,
        deletePhaseChecklistItem,
        addNewIndividualTask,
        updateIndividualTaskName,
        updateIndividualTaskDescription,
        deleteIndividualTask,
        updateDueDateInIndividualTask,
        updateDueDateInTask,
        getProjectDueDate,
        getMinimunTasksDueDate,
        getMinimunIndividualTasksDueDate,
        createGlobalProjects,
        createPlaybook,
      }}
    >
      {children}
    </ExportPlaybookContext.Provider>
  );
};

export { ExportPlaybookProvider, ExportPlaybookContext };

export const useExportPlaybook = (): ExportPlaybookContextData => {
  return useContext(ExportPlaybookContext);
};
