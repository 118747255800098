import React from 'react';

import KrsAndOkrs from '../../../components/KrsAndOkrs';
import KrsAndOkrsView from '../../../components/KrsAndOkrsView';
import CheckinSlide from '../../../components/CheckinSlide';
import Chart from './Chart';

import { Okr, OkrProgress } from '../../../types';

import * as S from './styles';

interface NewKrsAndOkrsSlide {
  clientId: number;
  draftId: number;
  isDraft: number;
  okrs: Okr[];
  progress: OkrProgress;
}

const KrsAndOkrsSlide: React.FC<NewKrsAndOkrsSlide> = ({
  clientId,
  draftId,
  isDraft,
  okrs = [],
  progress,
}) => {
  return (
    <CheckinSlide redWords={['o']}>
      <S.Container>
        <div className="lineChart">
          <Chart progress={progress} />
        </div>

        <div className="okrs">
          {isDraft ? (
            <KrsAndOkrs presentation draftId={draftId} clientId={clientId} />
          ) : (
            <KrsAndOkrsView presentation okrs={okrs} />
          )}
        </div>
      </S.Container>
    </CheckinSlide>
  );
};

export default KrsAndOkrsSlide;
