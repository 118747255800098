import { cloneDeep } from 'lodash';
import selectProjectIndexById from './SelectProjectIndexById';

export const editIndividualTasks = (
  tasks: Task[],
  projects: Project[],
  projectId: number
): Project[] => {
  const globalProjects = cloneDeep(projects);

  const projectIndex = selectProjectIndexById(projectId, globalProjects);

  const selectedProject = globalProjects[projectIndex];

  if (!selectedProject.tasks) {
    selectedProject.tasks = [];
  }
  selectedProject.tasks = tasks;

  return globalProjects;
};
