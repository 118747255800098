import React, { useState, useCallback } from 'react';
import Popup from 'reactjs-popup';
import { RiEditLine } from 'react-icons/ri';

import { TextInput, Button } from '../../../../../../components/Inputs';
import { Calendar } from '../../../../../../components/Calendar';

import * as S from './styles';
import { ThemeProps } from '../../../../../../styles/themes';

interface Edit {
  editFunction: (
    id: number,
    name: string,
    date: Date,
    createdNow: boolean
  ) => Promise<void>;
  name: string;
  id: number;
  date: Date;
  recentTask?: boolean;
}

const EditKr: React.FC<Edit> = ({
  editFunction,
  name,
  id,
  date,
  recentTask = false,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newName, setNewName] = useState(name);
  const [newDate, setNewDate] = useState<Date | undefined>(date);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      setIsLoading(true);
      await editFunction(id, newName, newDate!, recentTask);

      setIsLoading(false);
      setOpenModal(false);
    },
    [editFunction, id, newName, newDate, recentTask]
  );

  return (
    <Popup
      on="click"
      lockScroll={false}
      closeOnDocumentClick={false}
      arrow={false}
      open={openModal}
      position="top right"
      modal
      trigger={() => {
        return (
          <div>
            <RiEditLine
              onClick={() => setOpenModal(true)}
              size={16}
              color={String((theme: ThemeProps) => theme.backgroundSecondary)}
            />
          </div>
        );
      }}
      contentStyle={{
        background: 'transparent',
        width: '20rem',
        border: 0,
        borderRadius: '8px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <S.EditCard onSubmit={e => handleSubmit(e)}>
        <header>
          <RiEditLine size={20} color="var(--red-primary)" />
          <p>Edição</p>
        </header>
        <main>
          <S.Input>
            <p>Nome:</p>
            <TextInput
              maxLength={255}
              required
              height="1.8rem"
              placeholder="Digite Aqui"
              value={newName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewName(e.target.value);
              }}
            />
          </S.Input>
          <S.Input>
            <p style={{ marginBottom: '1rem' }}>Data:</p>
            <Calendar date={newDate} onChange={setNewDate} />
          </S.Input>
        </main>
        <footer>
          <Button
            background="var(--dark-background-terciary)"
            className="midButton"
            height="1.6rem"
            text="Cancelar"
            font="400 0.9rem Work Sans"
            type="button"
            onClick={() => {
              setOpenModal(false);
            }}
          />

          <Button
            disabled={isLoading}
            background={isLoading ? 'var(--gray)' : 'var(--green-primary)'}
            width="100%"
            height="1.6rem"
            text={isLoading ? 'Carregando...' : 'Confirmar'}
            font="400 0.9rem Work Sans"
            type="submit"
          />
        </footer>
      </S.EditCard>
    </Popup>
  );
};

export default EditKr;
