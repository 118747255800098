import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  label {
    display: flex;
    color: ${({ theme }) => theme.text};
    flex-direction: column;
    font-weight: bold;
    margin-top: 10px;
    input {
      margin-top: 5px;
      background: ${({ theme }) => theme.backgroundPrimary};
      color: ${({ theme }) => theme.text};
      border: none;
      padding: 0 5px;
      height: 20px;
      width: 40%;
    }
    textarea {
      margin-top: 5px;
      background: ${({ theme }) => theme.backgroundPrimary};
      color: ${({ theme }) => theme.text};
      border: 1px solid ${({ theme }) => theme.backgroundPrimary};
      border-radius: 6px;
      padding: 10px;
      max-width: 100%;
      min-width: 100%;
      min-height: 40px;
      max-height: 400px;
    }
    select {
      color: ${({ theme }) => theme.text};
      background: transparent;
      border: none;
      font-weight: bold;
      width: 100%;
      margin-top: 5px;
      option {
        color: ${({ theme }) => theme.text};
        :disabled {
          color: var(--white);
          background-color: var(--gray);
        }
      }
    }
  }

  button {
    margin: auto;
    margin-top: 20px;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    font-weight: bolder;
    font-size: 1.2vw;
    :hover {
      filter: brightness(90%);
    }
  }
`;
