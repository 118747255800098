import styled from 'styled-components';

export const Container = styled.div`
  background: #1a2024;
  border-radius: 3px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  p {
    font-size: 16px;
    font-weight: bold;
    color: #f6f6f6;
    margin-top: 20px;
  }

  .input {
    margin-right: 20px;
    color: #f6f6f6;

    select,
    input {
      background: #1a2024;
      border-radius: 2px;
      border: 0;
      padding: 7.5px 8px;
      color: #adadad;
      font-size: 16px;
      margin-right: 8px;
      cursor: pointer;
      font-family: Work Sans;
      font-style: normal;
      transition: 0.3s;

      :hover {
        background: #ea4b4c;
      }
    }

    p {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .button {
    width: 100%;
    height: 35px;
    background: #e02041;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    margin-top: 16px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    transition: filter 0.2s;
  }

  .button:hover {
    filter: brightness(90%);
  }
`;

export const ChartContainer = styled.div`
  flex: 0;
  padding: 20px;
  position: relative;

  .inside {
    position: absolute;
    text-align: center;
    left: 43%;
    top: 36%;

    @media (max-width: 1400px) {
      left: 40%;
      top: 35%;
    }

    p {
      margin-top: 5px;
      font-size: 15px;

      :nth-child(2) {
        color: #adadad;
        font-size: 10px;
      }
    }
  }
`;
