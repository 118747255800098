import styled from 'styled-components';

interface IconCardProps {
  selected: number | undefined;
  iconId: number;
  color: string;
}

export const IconCard = styled.div<IconCardProps>`
  display: flex;

  max-width: ${({ selected, iconId }) =>
    selected === undefined || selected === iconId ? '100%' : 0};
  font-size: ${({ selected, iconId }) =>
    selected === undefined || selected === iconId ? '1rem' : 0};
  opacity: ${({ selected, iconId }) =>
    selected === undefined || selected === iconId ? 1 : 0};
  padding: ${({ selected, iconId }) =>
    selected === undefined || selected === iconId ? '1rem' : '0rem'};
  margin: ${({ selected, iconId }) =>
    selected === undefined || selected === iconId ? '0rem 1rem' : '0rem'};
  transition: 0.5s;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  border-radius: 8px;
  box-shadow: ${({ color, selected }) =>
    selected && `0 5px 45px -15px ${color}`};
  background: ${({ theme, selected }) =>
    selected ? theme.backgroundSecondary : 'transparent'};
  font-weight: bold;

  p {
    margin-top: 0.5rem;
    color: var(--white);
  }

  svg {
    color: ${({ color }) => color};
    max-width: ${({ selected, iconId }) =>
      selected === undefined || selected === iconId ? '100%' : 0};
  }

  :hover {
    box-shadow: ${({ color, selected }) =>
      selected ? '' : `0 5px 50px -15px ${color}`};
    background: ${({ theme }) => theme.backgroundSecondary};
    filter: ${({ selected }) => selected && 'grayscale(80%)'};
    opacity: ${({ selected }) => selected && '0.5'};
  }

  @media (max-width: 1000px) {
    max-height: ${({ selected, iconId }) =>
      selected === undefined || selected === iconId ? '100%' : 0};

    svg {
      max-height: ${({ selected, iconId }) =>
        selected === undefined || selected === iconId ? '100%' : 0};
    }
  }
`;
