import { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ChartsArea } from './components/ChartsArea';
import { OkrsArea } from './components/OkrsArea';
import { Profile } from './components/Profile';
import apiV2 from '../../services/apiV2';
import * as S from './styles';
import { Okr } from './types';

import { useAuth } from '../../contexts/AuthContext';

interface ObjectProgress {
  percentage: number;
  count: number;
}

type Progress = {
  lateDoneTasksProgress: ObjectProgress;
  lateTasksProgress: ObjectProgress;
  onTimeDoneTasksProgress: ObjectProgress;
  onTimeTasksProgress: ObjectProgress;
  rescheduleTasksProgress: ObjectProgress;
};

const MyTasks: FC = () => {
  const { user } = useAuth();
  const { id } = user;
  const [progress, setProgress] = useState<Progress>({
    lateTasksProgress: {
      percentage: 0,
      count: 0,
    },
    lateDoneTasksProgress: {
      percentage: 0,
      count: 0,
    },
    onTimeTasksProgress: {
      percentage: 0,
      count: 0,
    },
    onTimeDoneTasksProgress: {
      percentage: 0,
      count: 0,
    },
    rescheduleTasksProgress: {
      percentage: 0,
      count: 0,
    },
  });
  const [okrs, setOkrs] = useState<Okr[]>([]);

  const getProgress = useCallback(async () => {
    await apiV2
      .get(`users/${id}/tasksprogress`)
      .then(res => {
        setProgress(res.data);
      })
      .catch(() => {
        toast.error(
          'Ocorreu algum problema, atualize a página e tente novamente 😕'
        );
      });
  }, [id]);

  const getOkrs = useCallback(async () => {
    await apiV2
      .get(`users/${id}/okrs`)
      .then(res => {
        setOkrs(() => res.data);
      })
      .catch(() => {
        toast.error(
          'Ocorreu algum problema, atualize a página e tente novamente 😕'
        );
      });
  }, [id]);

  useEffect(() => {
    getProgress();
    getOkrs();
  }, [getOkrs, getProgress]);

  const tasks =
    progress.lateDoneTasksProgress.count +
    progress.lateTasksProgress.count +
    progress.onTimeDoneTasksProgress.count +
    progress.onTimeTasksProgress.count;
  return (
    <S.Wrapper>
      <S.Container>
        <Profile />
        <S.Info>
          <h3>
            Você tem <span>{`${tasks}`} Tasks</span>
          </h3>
        </S.Info>
        <ChartsArea progress={progress} />
      </S.Container>
      <S.Container>
        <OkrsArea okrs={okrs} />
      </S.Container>
    </S.Wrapper>
  );
};

export default MyTasks;
