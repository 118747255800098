/* eslint-disable react/require-default-props */
import React, { useState, useCallback, useEffect } from 'react';
import { RiGitMergeLine } from 'react-icons/ri';
import { Container } from './styles';
import { Button } from '../../../../../components/Inputs';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import Responsible from '../../../../../components/Responsible';
import { Calendar } from '../../../../../components/Calendar';
import { massEditTask } from '../../../services/MassEditTask';
import { useTasks } from '../../../../../contexts/TasksContext';
import { editProjectDetails } from '../../../utils/EditProjectDetails';
import { setGroups } from '../../../utils/SetGroups';
import { editGroupDetails } from '../../../utils/EditGroupDetails';
import { SetPhases } from '../../../utils/SetPhases';
import { SetIndividualTasks } from '../../../utils/SetIndividualTasks';

interface UserItem {
  id: number;
  name: string;
  last_name: string;
}
interface MassEditTaskModalProps {
  type: string;
  id: number;
  projectId?: number;
  responsibles?: User[];
  dueDate?: string;
  handleClose: () => void;
}

function MassEditTaskModal({
  type,
  id,
  projectId,
  handleClose,
  responsibles,
  dueDate,
}: MassEditTaskModalProps) {
  const [isDisable, setIsDisable] = useState(false);
  const { projects, setProjects } = useTasks();
  const [users, setUsers] = useState<
    {
      id?: number;
    }[]
  >([{}]);
  const [date, setDate] = useState<Date | undefined>(
    dueDate ? new Date(dueDate) : undefined
  );

  const getUsers = useCallback(() => {
    const usersList: UserItem[] = [];
    responsibles!.forEach((user: User) => {
      usersList.push({
        id: user.id,
        name: user.name,
        last_name: user.last_name,
      });
    });
    setUsers(usersList);
  }, [responsibles]);

  useEffect(() => {
    if (responsibles) {
      getUsers();
    }
  }, [getUsers, responsibles]);

  const massEdit = useCallback(async () => {
    setIsDisable(true);

    const dateToString = date ? date.toString() : null;

    const editedTask = await massEditTask(
      type,
      id,
      dateToString,
      users.filter(user => user.id).map(user => user.id)
    );

    if (type === 'project_id') {
      const newProjects = setGroups(
        editedTask.groups,
        projects,
        editedTask.project.id
      );

      const newProjectsUpdated = SetIndividualTasks(
        editedTask.tasks,
        newProjects,
        editedTask.project.id
      );

      const projectsWithProjectUpdated = editProjectDetails(
        editedTask.project.users,
        editedTask.project.due_date,
        editedTask.project.progress,
        editedTask.project.id,
        newProjectsUpdated
      );

      setProjects(() => projectsWithProjectUpdated);

      handleClose();
      return;
    }
    if (type === 'group_id') {
      const newProjects = setGroups(editedTask.groups, projects, projectId!);
      const selectedGroup = editedTask.groups.filter(
        (group: Group) => group.id === id
      )[0];

      const projectsWithGroupsUpdated = editGroupDetails(
        selectedGroup.users,
        selectedGroup.due_date,
        selectedGroup.progress,
        projectId!,
        selectedGroup.id,
        newProjects
      );

      const projectsWithProjectUpdated = editProjectDetails(
        editedTask.project.users,
        editedTask.project.due_date,
        editedTask.project.progress,
        projectId!,
        projectsWithGroupsUpdated
      );

      setProjects(() => projectsWithProjectUpdated);

      handleClose();

      return;
    }
    if (type === 'phase_id') {
      const newProjects = SetPhases(
        editedTask.phases!,
        projects,
        editedTask.project.id,
        editedTask.group!.id
      );

      const projectsWithGroupsUpdated = editGroupDetails(
        editedTask.group!.users,
        editedTask.group!.due_date,
        editedTask.group!.progress,
        editedTask.project.id,
        editedTask.group!.id,
        newProjects
      );

      const projectsWithProjectUpdated = editProjectDetails(
        editedTask.project.users,
        editedTask.project.due_date,
        editedTask.project.progress,
        editedTask.project.id,
        projectsWithGroupsUpdated
      );

      setProjects(() => projectsWithProjectUpdated);

      handleClose();
    }

    setIsDisable(false);
  }, [date, handleClose, id, projectId, projects, setProjects, type, users]);

  return (
    <Container>
      <TaskCard close={handleClose}>
        <header>
          <RiGitMergeLine size={21} color="var(--red-primary)" />
          <p>Edição em massa</p>
        </header>
        <main>
          <div className="input">
            <p>Responsável</p>

            <Responsible users={users} setUsers={setUsers} />
          </div>

          <div className="input">
            <p>Prazo da task</p>
            <div style={{ marginTop: '0.8rem' }}>
              <Calendar date={date || null} onChange={setDate} activeRemove />
            </div>
          </div>

          <div style={{ fontSize: '0.9rem' }} className="input">
            <p style={{ color: 'var(--yellow-primary)' }}>ATENÇÃO!</p>
            <p style={{ color: 'var(--gray)' }}>
              Tudo que for alterado aqui, modificará em todas as tasks da fase,
              grupo ou projeto*
            </p>
          </div>

          <footer style={{ display: 'flex' }}>
            <Button
              id="return-button"
              onClick={handleClose}
              disabled={isDisable}
            >
              <p>Voltar</p>
            </Button>
            <Button id="confirm-button" onClick={massEdit} disabled={isDisable}>
              <p>Confirmar</p>
            </Button>
          </footer>
        </main>
      </TaskCard>
    </Container>
  );
}

export default MassEditTaskModal;
