import { toast } from 'react-toastify';
import api from '../../../services/api';

interface Props {
  post: {
    id: number;
    name: string;
    published: string;
    createdAt: Date;
    voteId: number;
    problem: string;
    reasons: string;
    solutions: string;
    youtube: string;
  };
  event: number;
}

export async function postNewWikiPostVoteService({ event, post }: Props) {
  try {
    await api.post('wiki_posts/vote', {
      wiki_post_id: post.id,
      vote: event,
      ...(post.voteId ? { id: post.voteId } : {}),
    });
  } catch {
    toast.error('Falha ao salvar voto!');
  }
}
