import React, { useEffect, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as S from './styles';
import ModalHeader from '../ModalHeader';
import { ModalAddressLabel, ModalLabel } from '../ModalLabel';
import ModalFooter from '../ModalFooter';
import ModalButton from '../ModalButton';
import { approveContract } from '../../../services/ApproveContract';
import { getFirstPartner } from '../../../utils/GetFirstPartner';
import { useContracts } from '../../../../../contexts/ContractsContext';
import { updateContract } from '../../../services/UpdateContract';
import { ModalLoading } from '../../../../../components/Loadings/ModalLoading';
import { deleteContract } from '../../../utils/DeleteContract';
import { getFirstAddress } from '../../../utils/GetFirstAddress';
import { disapproveContract } from '../../../services/DisapproveContract';
import { getUnits } from '../../../services/GetUnits';
import { Calendar } from '../../../../../components/Calendar';
import ModalBackground from '../ModalBackground';
import { ModalApprove } from '../ModalApprove';

interface editInformationProps {
  contractToVerify: Contract;
  handleCloseModal: () => void;
  left?: string;
}

export const EditInformation: React.FC<editInformationProps> = ({
  contractToVerify,
  handleCloseModal,
  left,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingButtons, setLoadingButtons] = useState(false);
  const [contract, setContract] = useState({} as Contract);
  const { contracts, setContracts } = useContracts();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);

  const nextPayments = [
    { name: '1 (um)', value: '1 (um)' },
    { name: '2 (dois)', value: '2 (dois)' },
    { name: '3 (três)', value: '3 (três)' },
    { name: '4 (quatro)', value: '4 (quatro)' },
    { name: '5 (cinco)', value: '5 (cinco)' },
    { name: '6 (seis)', value: '6 (seis)' },
    { name: '7 (sete)', value: '7 (sete)' },
    { name: '8 (oito)', value: '8 (oito)' },
    { name: '9 (nove)', value: '9 (nove)' },
    { name: '10 (dez)', value: '10 (dez)' },
    { name: '11 (onze)', value: '11 (onze)' },
    { name: '12 (doze)', value: '12 (doze)' },
    { name: '13 (treze)', value: '13 (treze)' },
    { name: '14 (quatorze)', value: '14 (quatorze)' },
    { name: '15 (quinze)', value: '15 (quinze)' },
    { name: '16 (dezesseis)', value: '16 (dezesseis)' },
    { name: '17 (dezessete)', value: '17 (dezessete)' },
    { name: '18 (dezoito)', value: '18 (dezoito)' },
    { name: '19 (dezenove)', value: '19 (dezenove)' },
    { name: '20 (vinte)', value: '20 (vinte)' },
    { name: '21 (vinte e um)', value: '21 (vinte e um)' },
    { name: '22 (vinte e dois)', value: '22 (vinte e dois)' },
    { name: '23 (vinte e três)', value: '23 (vinte e três)' },
    { name: '24 (vinte e quatro)', value: '24 (vinte e quatro)' },
    { name: '25 (vinte e cinco)', value: '25 (vinte e cinco)' },
    { name: '26 (vinte e seis)', value: '26 (vinte e seis)' },
    { name: '27 (vinte e sete)', value: '27 (vinte e sete)' },
    { name: '28 (vinte e oito)', value: '28 (vinte e oito)' },
    { name: '29 (vinte e nove)', value: '29 (vinte e nove)' },
    { name: '30 (trinta)', value: '30 (trinta)' },
    { name: '31 (trinta e um)', value: '31 (trinta e um)' },
  ];

  const vigenceTime = [
    { name: '6 (seis) meses', value: '6 (seis) meses' },
    { name: '12 (doze) meses', value: '12 (doze) meses' },
    { name: '18 (dezoito) meses', value: '18 (dezoito) meses' },
  ];

  const [unitName, setUnitName] = useState([{} as Unit]);

  const handleApproveContract = async (id: number, file?: File) => {
    setLoading(true);
    setLoadingButtons(true);

    if (await updateContract(contract))
      if (await approveContract(id, file)) {
        const contractsWithContractDeleted = deleteContract(contracts, id);

        setContracts(() => contractsWithContractDeleted);

        handleCloseModal();
      }

    setLoading(false);
    setLoadingButtons(false);
  };

  const handleDisapproveContract = async (id: number) => {
    setLoading(true);
    setLoadingButtons(true);

    if (await disapproveContract(id)) {
      toast.info('O contrato foi reprovado e o card retornou no pipefy');

      const contractsWithContractDeleted = deleteContract(contracts, id);

      setContracts(() => contractsWithContractDeleted);
    }

    handleCloseModal();

    setLoading(false);
    setLoadingButtons(false);
  };

  const handleOpenCalendar = (): void => {
    setOpenCalendar(!openCalendar);
  };

  const handleUpdateContractProp = (
    contractToUpdate: Contract,
    type: string,
    value: string
  ) => {
    setLoadingButtons(true);

    let newContract = contractToUpdate;

    newContract = {
      ...contract,
      [type]: value,
    };

    setContract(() => newContract);

    setLoadingButtons(false);
  };

  const handleUpdateContractDate = (value: Date) => {
    setLoadingButtons(true);

    let newContract = contractToVerify;

    newContract = {
      ...newContract,
      first_payment_date: value,
    };

    setContract(newContract);

    setLoadingButtons(false);
  };

  const handleUpdateContractUnit = (
    contractToUpdate: Contract,
    type: string,
    value: string
  ) => {
    setLoadingButtons(true);

    const unit = value.split(',');

    const newUnit = {
      id: +unit[0],
      name: unit[1],
      stakeholder_email: unit[2],
      stakeholder_name: unit[3],
    };
    let newContract = contractToUpdate;

    newContract = {
      ...newContract,
      [type]: newUnit,
    };

    setContract(newContract);

    setLoadingButtons(false);
  };

  const handleUpdateContractAddresses = (
    contractToUpdate: Contract,
    type: string,
    value: string
  ) => {
    setLoadingButtons(true);

    const newContract = contractToUpdate;

    newContract.addresses[0] = {
      ...newContract.addresses[0],
      [type]: value,
    };

    setContract(() => newContract);

    setLoadingButtons(false);
  };

  const handleUpdateContractPartners = (
    contractToUpdate: Contract,
    type: string,
    value: string
  ) => {
    setLoadingButtons(true);

    const newContract = contractToUpdate;

    newContract.partners[0] = {
      ...newContract.partners[0],
      [type]: value,
    };

    setContract(() => newContract);

    setLoadingButtons(false);
  };

  const getUnitNames = async () => {
    const units = await getUnits();

    setUnitName(units);
  };
  useEffect(() => {
    setContract(contractToVerify);

    getUnitNames();
  }, [contractToVerify]);

  return (
    <>
      <S.EditInformationModal left={left}>
        <ModalHeader
          icon={MdEdit}
          title="Editar informações"
          setModal={handleCloseModal}
          disabled={loadingButtons}
        />
        {loading ? (
          <ModalLoading rows={7} />
        ) : (
          contractToVerify && (
            <S.FormInformations action="">
              <S.Row>
                <ModalLabel
                  title="Razão social do cliente"
                  htmlFor="companyName"
                >
                  <input
                    id="companyName"
                    type="text"
                    placeholder="Escreva aqui a razão social do cliente"
                    defaultValue={contract.social_name}
                    onBlur={e =>
                      handleUpdateContractProp(
                        contract,
                        'social_name',
                        e.target.value
                      )
                    }
                  />
                </ModalLabel>
                <ModalLabel
                  title="Data do primeiro pagamento"
                  htmlFor="calendar"
                >
                  <input
                    id="calendar"
                    type="text"
                    placeholder="Escreva aqui a data do primeiro pagamento"
                    value={new Date(
                      contract.first_payment_date
                    ).toLocaleDateString('pt-BR')}
                    onClick={handleOpenCalendar}
                  />
                </ModalLabel>
                {openCalendar && (
                  <S.CalendarModal>
                    <Calendar
                      date={new Date(contractToVerify.first_payment_date)}
                      onChange={handleUpdateContractDate}
                      activeRemove
                      setModal={handleOpenCalendar}
                    />
                    <ModalBackground setModal={handleOpenCalendar} />
                  </S.CalendarModal>
                )}
                <ModalLabel title="CNPJ/CPF" htmlFor="cnpj">
                  <input
                    id="cnpj"
                    type="text"
                    placeholder="Escreva aqui o CNPJ/CPF"
                    defaultValue={contract.cnpj_cpf}
                    onBlur={e =>
                      handleUpdateContractProp(
                        contract,
                        'cnpj_cpf',
                        e.target.value
                      )
                    }
                  />
                </ModalLabel>
                <ModalLabel
                  title="Pagamentos subsequentes"
                  htmlFor="nextPayments"
                >
                  <select
                    placeholder="Dia útil dos próximos pagamentos"
                    onChange={e =>
                      handleUpdateContractProp(
                        contract,
                        'payment_date',
                        e.target.value
                      )
                    }
                    value={contract.payment_date}
                  >
                    {nextPayments.map(option => (
                      <option key={option.name} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </ModalLabel>
                <ModalLabel title="Tempo de vigência" htmlFor="vigenceTime">
                  <select
                    placeholder="Coloque um valor em meses"
                    onChange={e =>
                      handleUpdateContractProp(
                        contract,
                        'life_time',
                        e.target.value
                      )
                    }
                    value={contract.life_time}
                  >
                    {vigenceTime.map(option => (
                      <option key={option.name} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </ModalLabel>
                <ModalLabel title="Valor Fee" htmlFor="fee">
                  <input
                    id="fee"
                    type="number"
                    placeholder="Escreva aqui um valor"
                    defaultValue={contract.fee}
                    onChange={e =>
                      handleUpdateContractProp(contract, 'fee', e.target.value)
                    }
                  />
                </ModalLabel>
              </S.Row>
              <S.Row>
                <ModalLabel
                  title="Representante legal"
                  htmlFor="legalRepresentative"
                >
                  <input
                    id="legalRepresentative"
                    type="text"
                    placeholder="Digite o nome do representante legal"
                    defaultValue={
                      contractToVerify.partners
                        ? getFirstPartner(contractToVerify.partners).name
                        : 'Sócio legal não preenchido'
                    }
                    onBlur={e =>
                      handleUpdateContractPartners(
                        contract,
                        'name',
                        e.target.value
                      )
                    }
                  />
                </ModalLabel>
                <ModalLabel title="Cpf" htmlFor="cpf">
                  <input
                    id="cpf"
                    type="text"
                    placeholder="Digite o CPF do representante legal"
                    defaultValue={
                      contractToVerify.partners
                        ? getFirstPartner(contractToVerify.partners).cpf
                        : 'CPF do sócio não preenchido'
                    }
                    onChange={e =>
                      handleUpdateContractPartners(
                        contract,
                        'cpf',
                        e.target.value
                      )
                    }
                  />
                </ModalLabel>
              </S.Row>
              <S.Row>
                <ModalAddressLabel htmlFor="street" width="100%">
                  <ModalLabel title="Rua" htmlFor="street">
                    <input
                      style={{ width: '100%' }}
                      id="street"
                      type="text"
                      placeholder="Nome da rua/avenida"
                      defaultValue={
                        contractToVerify.addresses
                          ? getFirstAddress(contractToVerify.addresses).street
                          : 'Rua não preenchida'
                      }
                      onBlur={e =>
                        handleUpdateContractAddresses(
                          contract,
                          'street',
                          e.target.value
                        )
                      }
                    />
                  </ModalLabel>
                  <ModalLabel width="25%" title="Cidade" htmlFor="city">
                    <input
                      style={{ width: '100%' }}
                      id="city"
                      type="text"
                      placeholder="Cidade"
                      defaultValue={
                        contractToVerify.addresses
                          ? getFirstAddress(contractToVerify.addresses).city
                          : 'Cidade não preenchida'
                      }
                      onBlur={e =>
                        handleUpdateContractAddresses(
                          contract,
                          'city',
                          e.target.value
                        )
                      }
                    />
                  </ModalLabel>
                  <ModalLabel width="22%" title="Estado" htmlFor="state">
                    <input
                      style={{ width: '100%' }}
                      id="state"
                      type="text"
                      placeholder="Estado"
                      defaultValue={
                        contractToVerify.addresses
                          ? getFirstAddress(contractToVerify.addresses).state
                          : 'Estado não preenchido'
                      }
                    />
                  </ModalLabel>
                  <ModalLabel width="24%" title="Número" htmlFor="number">
                    <input
                      style={{ width: '100%' }}
                      id="number"
                      type="text"
                      placeholder="Número"
                      defaultValue={
                        contractToVerify.addresses
                          ? getFirstAddress(contractToVerify.addresses).number
                          : 'Número não preenchido'
                      }
                      onBlur={e =>
                        handleUpdateContractAddresses(
                          contract,
                          'number',
                          e.target.value
                        )
                      }
                    />
                  </ModalLabel>
                  <ModalLabel width="24%" title="Bairro" htmlFor="district">
                    <input
                      style={{ width: '100%' }}
                      id="district"
                      type="text"
                      placeholder="Bairro"
                      defaultValue={
                        contractToVerify.addresses
                          ? getFirstAddress(contractToVerify.addresses).district
                          : 'Bairro não preenchido'
                      }
                      onBlur={e =>
                        handleUpdateContractAddresses(
                          contract,
                          'district',
                          e.target.value
                        )
                      }
                    />
                  </ModalLabel>
                  <ModalLabel width="24%" title="País" htmlFor="country">
                    <input
                      style={{ width: '100%' }}
                      id="country"
                      type="text"
                      placeholder="País"
                      defaultValue={
                        contractToVerify.addresses
                          ? getFirstAddress(contractToVerify.addresses).country
                          : 'País não preenchido'
                      }
                      onBlur={e =>
                        handleUpdateContractAddresses(
                          contract,
                          'district',
                          e.target.value
                        )
                      }
                    />
                  </ModalLabel>
                  <ModalLabel width="24%" title="Cep" htmlFor="cep">
                    <input
                      style={{ width: '100%' }}
                      id="cep"
                      type="text"
                      placeholder="CEP"
                      defaultValue={
                        contractToVerify.addresses
                          ? getFirstAddress(contractToVerify.addresses).cep
                          : 'CEP não preenchido'
                      }
                      onBlur={e =>
                        handleUpdateContractAddresses(
                          contract,
                          'cep',
                          e.target.value
                        )
                      }
                    />
                  </ModalLabel>
                </ModalAddressLabel>
              </S.Row>
              <S.Row>
                <ModalLabel title="Tipo do contrato" htmlFor="contract">
                  <input
                    id="contract"
                    type="text"
                    placeholder="Selecione um contrato"
                    defaultValue={contract.product || ''}
                    readOnly
                  />
                </ModalLabel>
                <ModalLabel title="Qual unidade?" htmlFor="unit">
                  <select
                    placeholder="Selecione uma unidade"
                    onChange={e =>
                      handleUpdateContractUnit(contract, 'unit', e.target.value)
                    }
                    value={contract.unit && contract.unit.name}
                  >
                    <option value={contract.unit && contract.unit.name}>
                      {contract.unit && contract.unit.name}
                    </option>
                    {unitName.map(option => (
                      <option
                        key={option.name}
                        value={[
                          String(option.id),
                          option.name,
                          option.stakeholder_email,
                          option.stakeholder_name,
                        ]}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </ModalLabel>
              </S.Row>
            </S.FormInformations>
          )
        )}
        <ModalFooter>
          <ModalButton className="red disabled">
            Distrato <span style={{ width: '10rem' }}>Em desenvolvimento</span>
          </ModalButton>
          <ModalButton className="cyan disabled">
            Aditivo <span style={{ width: '10rem' }}>Em desenvolvimento</span>
          </ModalButton>
          <ModalButton
            className={`red ${loadingButtons && 'disabled'}`}
            width="10rem"
            alignRight
            type="button"
            disabled={loadingButtons}
            onClick={() => {
              handleDisapproveContract(contract.id);
            }}
          >
            Reprovar
          </ModalButton>
          <ModalButton
            className={`green ${loadingButtons && 'disabled'}`}
            width="10rem"
            type="button"
            disabled={loadingButtons}
            onClick={() => {
              setOpenApproveModal(true);
            }}
          >
            Aprovar
          </ModalButton>
        </ModalFooter>
      </S.EditInformationModal>
      {openApproveModal && (
        <ModalApprove
          contractId={contract.id}
          handleCloseModal={() => setOpenApproveModal(false)}
          product={contract.product || ''}
          handleApproveContract={handleApproveContract}
        />
      )}
    </>
  );
};
