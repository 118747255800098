import styled from 'styled-components';

export const Container = styled.div`
  header .configs {
    display: flex;
    align-items: center;
  }

  header .configs p {
    letter-spacing: 0.84px;
    font-weight: bold;
    color: ${({ theme }) => theme.text};
  }

  header .configs .select-search__input {
    width: 8rem;
    height: 2rem;
    padding: 0.5rem;
    font: 400 0.8rem Work Sans;
    margin-left: 1rem;
  }

  header .configs .biLink {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    input {
      margin-top: 0rem;
    }
  }
`;
