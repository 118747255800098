import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 160px;
  background: ${({ theme }) => theme.backgroundSecondary};
  border: ${props => {
    if (props.user && props.vote < 1 && !props.open) {
      return '1px solid var(--red-primary)';
    }
    return '1px solid var(--black)';
  }};

  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px;
  color: ${({ theme }) => theme.text};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  white-space: nowrap;
  cursor: pointer;
  > p {
    margin-top: 5px;
    padding: 10px;
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > h1 {
    color: ${({ theme }) => theme.text};
  }

  #priority {
    p {
      color: var(--white);
    }
  }
  #category {
    p {
      color: var(--white);
    }
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.text};
`;
export const Urgency = styled.div`
  display: flex;
  > div {
    background: ${props => {
      if (!props.open) {
        return 'var(--green-primary)';
      }
      if (props.dueDate < 24) {
        return 'var(--red-primary)';
      }
      if (props.dueDate < 48) {
        return 'var(--yellow-primary)';
      }

      return 'var(--cyan-primary)';
    }};
    margin-right: 5px;
    padding: 5px;
    border-radius: 6px;
    color: var(--white);
  }
`;
export const What = styled.div`
  display: flex;
  > div {
    background: ${props => {
      if (props.category === 'finance') {
        return 'var(--green-primary)';
      }
      if (props.category === 'tech') {
        return 'var(--red-primary)';
      }
      if (props.category === 'sales') {
        return 'var(--purple-secondary)';
      }
      return 'var(--cyan-secondary)';
    }};
    margin-right: 5px;
    padding: 5px;
    border-radius: 6px;
    p {
      color: var(--white);
    }
    color: var(--white);
  }
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Assigns = styled.div`
  display: flex;

  .avatar {
    border-radius: 50%;
    padding: 0px;
    width: 36px !important;
    height: 36px !important;
    font-size: 36px;
  }
  .first {
    border: ${props => {
      if (props.isUser) {
        return '1px solid var(--green-primary)';
      }
      return '1px solid var(--black)';
    }};
  }
  .second {
    border: ${props => {
      if (props.isSecond) {
        return '1px solid var(--green-primary)';
      }
      return '1px solid var(--black)';
    }};
  }
`;
export const Checks = styled.div`
  display: flex;
  #user {
    margin-left: -10px;
  }
`;
