import PropTypes from 'prop-types';
import { ReactNode } from 'react';

export interface DefaultProps extends React.HTMLAttributes<Element> {
  children: ReactNode;
}

export default function Default({ children }: DefaultProps) {
  return <>{children}</>;
}

Default.propTypes = {
  children: PropTypes.element.isRequired,
};
