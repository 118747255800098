/* eslint-disable react/require-default-props */
import * as S from './styles';

interface ProgressDisplayProps {
  width: string;
  height: string;
  borderRadius: string;
  percentage: number;
  title: string;
  colorBar?: string;
}

export const ProgressDisplay = ({
  width,
  height,
  borderRadius,
  percentage,
  title,
  colorBar = 'var(--yellow-primary)',
}: ProgressDisplayProps): JSX.Element => {
  return (
    <S.ProgressBar style={{ width, height, borderRadius }}>
      <div
        className="progress"
        style={{
          width: `${percentage}%`,
          borderRadius,
          background: `${colorBar}`,
        }}
      >
        <h5>{title || `${percentage}%`}</h5>
      </div>
    </S.ProgressBar>
  );
};
