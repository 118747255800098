import styled from 'styled-components';

export const ApproveModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  padding: 1.5rem;
  border-radius: 5px;
  z-index: 100;
  overflow: auto;
  max-height: 90%;
  justify-content: center;
  button {
    margin: 1rem;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  border-top: 1px solid var(--gray);
  padding: 1rem;
`;

export const Uploader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 42vw;
  height: 20vh;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed #e32337;
  color: #fff;
  padding: 1rem;
  font-size: 0.8rem;
  cursor: pointer;
  > div:last-child {
    font-weight: bolder;
  }
`;
