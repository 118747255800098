import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { FiThumbsUp, FiThumbsDown, FiSend } from 'react-icons/fi';
import { toast } from 'react-toastify';
import Avatar from 'react-avatar';
import {
  Container,
  Votes,
  Body,
  FirstLine,
  Status,
  SecondLine,
  ThirdLine,
  Replies,
} from './styles';
import Reply from './reply';

import api from '../../../../services/api';

function SuggestionOption({ suggestion, getSuggestions, isAdmin }) {
  const [reply, setReply] = useState(false);
  const [user, setUser] = useState({ name: '', last_name: '', avatar: null });

  const status = useMemo(() => {
    if (suggestion.status === 'done') {
      return 'Finalizado';
    }
    if (suggestion.status === 'reproved') {
      return 'Reprovado';
    }
    if (suggestion.status === 'backlog') {
      return 'Backlog';
    }
    if (suggestion.status === 'dev') {
      return 'Em desenvolvimento';
    }
    if (suggestion.status === 'review') {
      return 'Em análise';
    }
    return 'Aberto';
  }, [suggestion.status]);

  const points = useMemo(() => {
    return suggestion.likes - suggestion.dislikes;
  }, [suggestion.dislikes, suggestion.likes]);

  const sendVote = useCallback(
    async like => {
      if (suggestion.userVoteId) {
        await api
          .put('/vote_suggestion', {
            id: suggestion.userVoteId,
            suggestion_id: suggestion.id,
            like,
          })
          .then(() => {
            toast.success('Voto computado!');
            getSuggestions();
          })
          .catch(() => {
            toast.error('Algo errado aconteceu, por favor, atualize a página!');
            getSuggestions();
          });
        return true;
      }
      await api
        .post('/vote_suggestion', {
          suggestion_id: suggestion.id,
          like,
        })
        .then(() => {
          toast.success('Voto computado!');
          getSuggestions();
        })
        .catch(() => {
          toast.error('Algo errado aconteceu, por favor, atualize a página!');
          getSuggestions();
        });
      return true;
    },
    [getSuggestions, suggestion.id, suggestion.userVoteId]
  );

  const getUser = useCallback(async () => {
    if (suggestion.replies.length > 0) {
      await api
        .get(`/users/${suggestion.replies[0].user_id}`)
        .then(res => setUser(res.data));
    }
  }, [suggestion.replies]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Container>
      <Votes>
        <h1>{points}</h1>
        <h2>{points > 1 ? 'pontos' : 'ponto'}</h2>
      </Votes>
      <Body>
        <FirstLine>
          <h1>{suggestion.name}</h1>
          <Status status={suggestion.status}>
            <h4>{status}</h4>
          </Status>
        </FirstLine>
        <SecondLine>
          <p>{suggestion.desc}</p>
        </SecondLine>
        <ThirdLine>
          <FiThumbsUp
            color={suggestion.userVote === 'like' && 'var(--red-primary)'}
            onClick={() => sendVote(true)}
          />
          <p>{suggestion.likes}</p>
          <FiThumbsDown
            color={suggestion.userVote === 'dislike' && 'var(--red-primary)'}
            onClick={() => sendVote(false)}
          />
          <p>{suggestion.dislikes}</p>
          {isAdmin && <FiSend onClick={() => setReply(!reply)} />}
        </ThirdLine>
        {suggestion.replies.length > 0 &&
          suggestion.replies.map(rply => {
            return (
              <Replies key={rply.id}>
                <Avatar
                  name={`${user.name} ${user.last_name}`}
                  src={user.avatar && user.avatar.url}
                  className="avatar"
                  color="var(--gray)"
                  key={user.id}
                />
                <p>{rply.desc}</p>
              </Replies>
            );
          })}
        {reply && (
          <Reply
            setActive={setReply}
            suggestion_id={suggestion.id}
            getSuggestions={getSuggestions}
          />
        )}
      </Body>
    </Container>
  );
}

export default SuggestionOption;
