import { useState, useEffect } from 'react';

interface EventProps {
  pageX: number;
}

const useDraggable = (el: React.RefObject<HTMLDivElement>): void => {
  const [{ dx }, setOffset] = useState({ dx: 0 });

  useEffect(() => {
    const newEl = el;
    const handleMouseDown = (event: EventProps) => {
      const startX = event.pageX - dx;

      const handleMouseMove = (newEvent: EventProps) => {
        const newDx = newEvent.pageX - startX;
        setOffset({ dx: newDx });
      };

      document.addEventListener('mousemove', handleMouseMove);
      if (newEl.current !== null) {
        newEl.current.style.cursor = 'grabbing';
      }
      document.addEventListener(
        'mouseup',
        () => {
          document.removeEventListener('mousemove', handleMouseMove);
          if (newEl.current !== null) {
            newEl.current.style.cursor = 'default';
          }
        },
        { once: true }
      );
    };

    if (el.current !== null) {
      el.current.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      if (newEl.current !== null) {
        newEl.current.removeEventListener('mousedown', handleMouseDown);
      }
    };
  }, [dx, el]);

  useEffect(() => {
    const newEl = el;
    if (newEl.current !== null) {
      newEl.current.style.transform = `translate3d(${dx}px, 0, 0)`;
    }
  }, [dx, el]);
};

export default useDraggable;
