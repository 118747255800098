import { useState, useMemo, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import UnitForm from '../../components/UnitForm';
import UnitUsers from '../../components/UnitUsers';
import UnitClients from '../../components/UnitClients';

import api from '../../../../services/api';
import * as S from './styles';

type Role = {
  id: number;
  role: string;
};

export function UnitProfile() {
  const [option, setOption] = useState('unit');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFinance, setIsFinance] = useState(false);

  const history = useHistory();
  const { id }: { id: string } = useParams();

  const selectedOption = useMemo(() => {
    if (option === 'unit') {
      return <UnitForm id={id} isAdmin={isAdmin} isFinance={isFinance} />;
    }
    if (option === 'users') {
      return <UnitUsers id={Number(id)} isAdmin={isAdmin} />;
    }
    return <UnitClients id={Number(id)} history={history} />;
  }, [history, isAdmin, isFinance, id, option]);

  useEffect(() => {
    api.get(`units/${id}`);

    api.get('roles').then(res => {
      setIsAdmin(
        res.data.filter((role: Role) => role.role === 'admin').length > 0
      );
      setIsFinance(
        res.data.filter((role: Role) => role.role === 'finance').length > 0
      );
    });
  }, [id]);

  return (
    <S.Wrapper>
      <S.Options>
        <label htmlFor="unit">
          <input
            name="options"
            type="radio"
            id="unit"
            checked={option === 'unit'}
            onChange={e => setOption(e.target.id)}
          />
          <span>Unidade</span>
        </label>
        <label htmlFor="users">
          <input
            name="options"
            type="radio"
            id="users"
            checked={option === 'users'}
            onChange={e => setOption(e.target.id)}
          />
          <span>Usuários</span>
        </label>
        <label htmlFor="clients">
          <input
            name="options"
            type="radio"
            id="clients"
            checked={option === 'clients'}
            onChange={e => setOption(e.target.id)}
          />
          <span>Clientes</span>
        </label>
      </S.Options>
      {selectedOption}
    </S.Wrapper>
  );
}
