import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getPhases(groupId: number, query = ''): Promise<Phase[]> {
  try {
    const response = await apiV2.get(`phases/${groupId}${query}`);

    const phases = response.data;

    return phases;
  } catch {
    toast.error(
      'Ocorreu algum problema ao carregar as fases, atualize a página e tente novamente 😕'
    );
    return [];
  }
}
