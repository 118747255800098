import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';

import apiV2 from '../../../../services/apiV2';
import Loading from '../../../../components/Loadings/Loading';
import NextPageButton from './NextPageButton';
import BlockProvider from '../../../../contexts/HandleCheckinBlock';

import ResultsHolder from '../../components/ResultsHolder';
import NameChanger from '../../components/NameChanger';
import PremisesAndRisks from '../../components/PremisesAndRisks';
import Funnel from '../../components/Funnel';
import KrsAndOkrs from '../../components/KrsAndOkrs';

import { Params, CheckinDraft } from '../../types';
import * as S from './styles';

const CreateNew: React.FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<CheckinDraft>({
    considerations: '',
    actions_for_next_week_v4: '',
    actions_for_next_week_client: '',
    name: '',
    victories: [],
    premises: [],
    lessons: [],
    client_id: 0,
    funnels: [],
    investment: 0,
    revenue: 0,
    lifetime_in_months: 0,
    bi_link: '',
    okrs: [],
    project_id: 0,
    emails: [],
    client_margin: 0,
  });

  const oldTopCards = {
    investment: formData.investment,
    revenue: formData.revenue,
    lifetime_in_months: formData.lifetime_in_months,
    client_margin: formData.client_margin,
  };

  const handleGetOldInfos = useCallback(async () => {
    await apiV2
      .get(`checkinDraft/getById/${id}`)
      .then(res => {
        setFormData(res.data.checkinDraft);
        formData.funnels = formData.funnels.sort(
          (a, b) => a.position - b.position
        );
        setIsLoading(false);
      })
      .catch(err => {
        toast.error(JSON.stringify(err.message));
      });
  }, [id, formData]);

  const handleNameEdit = useCallback(
    (name: string) => {
      const reFormData = formData;
      reFormData.name = name;
      setFormData(reFormData);
    },
    [formData]
  );

  const handlePresentationRedirect = useCallback(() => {
    history.push(`/checkin/presentation/${id}/1`);
  }, [history, id]);

  useEffect(() => {
    if (formData.funnels.length <= 0) {
      handleGetOldInfos();
    }
  }, [handleGetOldInfos, formData]);

  return isLoading ? (
    <Loading />
  ) : (
    <BlockProvider>
      <S.Container>
        <p>{formData.name}</p>

        <NextPageButton handleClick={handlePresentationRedirect} />

        <div className="pageTitle">
          <h1>SETUP NOVO</h1>
          <h1>CHECK IN</h1>
        </div>

        <NameChanger
          checkinId={Number(id)}
          handleEdit={handleNameEdit}
          name={formData.name}
        />

        <S.HeaderBar>
          <p>Resultados</p>
          <hr />
        </S.HeaderBar>

        <div className="results">
          <ResultsHolder oldList={formData.victories} draftId={id} />
          <ResultsHolder oldList={formData.lessons} lessons draftId={id} />
        </div>

        <S.HeaderBar>
          <hr />
        </S.HeaderBar>

        <Funnel
          oldTopCards={oldTopCards}
          oldFunnel={formData.funnels}
          checkinId={Number(id)}
          biLink={formData.bi_link}
        />

        <S.HeaderBar>
          <hr />
        </S.HeaderBar>

        {formData.client_id !== 0 && (
          <KrsAndOkrs draftId={Number(id)} clientId={formData.client_id} />
        )}

        <S.HeaderBar>
          <p>Premissas e Riscos</p>
          <hr />
        </S.HeaderBar>

        <PremisesAndRisks oldData={formData.premises} draftId={Number(id)} />

        <NextPageButton handleClick={handlePresentationRedirect} />
        <div className="transparent" />
      </S.Container>
    </BlockProvider>
  );
};

export default CreateNew;
