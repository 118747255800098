import styled, { css } from 'styled-components';

interface ContainerProps {
  link: boolean;
  unread: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  color: var(--gray);

  & + div {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid var(--gray);
  }

  p {
    font-size: 13px;
    line-height: 18px;

    color: ${({ theme }) => theme.text};
    ${props =>
      props.link &&
      css`
        :hover {
          cursor: pointer;
          color: var(--gray);
          text-decoration: underline;
        }
      `}
  }

  time {
    display: block;
    font-size: 12px;
    opacity: 0.6;
    margin-bottom: 5px;

    color: ${({ theme }) => theme.text};
  }
  button {
    font-size: 12px;
    border: 0;
    background: none;
    color: ${({ theme }) => theme.text};
    padding: 0 5px;
    margin: 0 5px;
    transition: all 0.2 ease;

    :hover {
      color: var(--gray);
    }
  }

  ${props =>
    props.unread &&
    css`
      &::after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: var(--red-primary);
        border-radius: 50%;
      }
    `}
`;
