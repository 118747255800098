import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;
    width: 100%;
    margin-left: 2rem;
    height: 100%;
  }

  @media (max-width: 1100px) {
    .cards {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;

    .cards {
      margin-left: 0rem;
      margin-top: 2rem;
    }
  }
`;
