import React, { useState } from 'react';

import { Container } from './styles';

import Header from './header';
import TopCards from './TopCards';
import ManualCards from './ManualCards';

interface FunnelParams {
  checkinId: number;
  oldFunnel: {
    id: number;
    name: string;
    value: number;
    position: number;
  }[];
  oldTopCards: {
    investment: number;
    revenue: number;
    lifetime_in_months: number;
    client_margin: number;
  };
  biLink: string;
}

const Funnel: React.FC<FunnelParams> = ({
  checkinId,
  oldFunnel,
  oldTopCards,
  biLink,
}) => {
  const [showConfig, setShowConfig] = useState('');
  const [newBiLink, setNewBiLink] = useState<string | null>(biLink);

  return (
    <Container>
      <Header
        newLink={newBiLink}
        setNewBiLink={setNewBiLink}
        checkinId={checkinId}
        setShowConfig={setShowConfig}
        showConfig={showConfig}
      />
      <TopCards oldTopCards={oldTopCards} checkinId={checkinId} />
      {showConfig === 'Manual' && (
        <ManualCards
          newBiLink={newBiLink}
          setNewBiLink={setNewBiLink}
          oldCards={oldFunnel}
          checkinDraftId={checkinId}
        />
      )}
    </Container>
  );
};

export default Funnel;
