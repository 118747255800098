/* eslint-disable react/require-default-props */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Tag } from '../Tag';
import { createTag } from '../../services/CreateTag';
import { TextInput } from '../../../../components/Inputs';

import { getTags } from '../../services/GetTags';

import * as S from './styles';

interface TagsCreateProps {
  clientId?: number;
  width?: string;
  tagsList: ITag[];
  setTagsList: Dispatch<SetStateAction<ITag[]>>;
}

export const TagsCreate = ({
  clientId,
  width = '100%',
  tagsList,
  setTagsList,
}: TagsCreateProps): JSX.Element => {
  const { id } = useParams<Params>();
  const [tagName, setTagName] = useState('');
  const [tagArea, setTagArea] = useState(false);
  const [tagsAlreadyExistant, setTagsAlreadyExistant] = useState<ITag[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const setTags = useCallback(async () => {
    let tagClientId;
    clientId ? (tagClientId = String(clientId)) : (tagClientId = id);
    const tags = await getTags(tagClientId);
    setTagsAlreadyExistant(() => tags);
  }, [id, clientId]);

  useEffect(() => {
    setTags();
  }, [setTags]);

  const handleCreateTag = useCallback(
    async (name: string) => {
      const alreadyExistInDataBase: ITag = tagsAlreadyExistant.filter(
        (tag: ITag) => tag.name.toLowerCase() === name.toLowerCase()
      )[0];

      if (alreadyExistInDataBase) {
        return;
      }
      if (name === '') {
        toast.warn('Não é possível criar uma tag sem nome.');
        return;
      }
      const createdTag = await createTag(
        name,
        'var(--cyan-primary)',
        clientId || id
      );

      if (createdTag) {
        setTagsAlreadyExistant(state => [...state, createdTag]);
        setTagsList(state => [...state, createdTag]);
      }
    },
    [id, tagsAlreadyExistant, setTagsList, clientId]
  );

  const handleAddOnChange = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      const selectedTag: ITag = tagsAlreadyExistant.filter(
        (tag: ITag) => tag.name.toLowerCase() === tagName.toLowerCase()
      )[0];
      const alreadyExistInTagList: ITag = tagsList.filter(
        (tag: ITag) => tag.name.toLowerCase() === tagName.toLowerCase()
      )[0];

      if (!selectedTag) {
        handleCreateTag(tagName);
        setTagName(() => '');
        return;
      }
      if (!alreadyExistInTagList) {
        setTagsList(state => [...state, selectedTag]);
      } else {
        toast.warn(
          'Já existe uma tag com esse nome. Por favor, tente outro nome.'
        );
      }
      setTagName(() => '');
    },
    [tagName, tagsAlreadyExistant, handleCreateTag, tagsList, setTagsList]
  );

  const handleAddOnClick = useCallback(
    (name: string) => {
      const selectedTag: ITag = tagsAlreadyExistant.filter(
        (tag: ITag) => tag.name.toLowerCase() === name.toLowerCase()
      )[0];
      const alreadyExistInTagList: ITag = tagsList.filter(
        (tag: ITag) => tag.name.toLowerCase() === name.toLowerCase()
      )[0];

      if (!alreadyExistInTagList) {
        setTagsList(state => [...state, selectedTag]);
      } else {
        toast.warn(
          'Já existe uma tag com esse nome. Por favor, tente outro nome.'
        );
      }
      setTagName(() => '');
    },
    [tagsAlreadyExistant, tagsList, setTagsList]
  );

  const tags = !tagName
    ? tagsAlreadyExistant
    : tagsAlreadyExistant.filter(tag =>
        tag.name.toLowerCase().includes(tagName.toLocaleLowerCase())
      );

  useEffect(() => {
    if (tagName !== '') {
      setTagArea(() => true);
    } else {
      setTagArea(() => false);
    }
  }, [tagName]);

  return (
    <S.Wrapper style={{ width }}>
      <form onSubmit={handleAddOnChange}>
        <TextInput
          id="tag-name"
          type="text"
          placeholder="Nome da Tag"
          value={tagName}
          onChange={handleChange}
          autoComplete="off"
        />
      </form>
      {tagArea && tags.length > 0 && (
        <div id="tag-area">
          {tags.map(tagItem => (
            <div key={tagItem.id}>
              <input
                type="radio"
                id={tagItem.name}
                name="icon"
                value={tagItem.name}
                onClick={() => handleAddOnClick(tagItem.name)}
              />
              <label htmlFor={tagItem.name}>{tagItem.name}</label>
            </div>
          ))}
        </div>
      )}
      <S.Content>
        {tagsList &&
          tagsList.map((tag: ITag) => {
            return <Tag key={tag.name} tag={tag} setTagsList={setTagsList} />;
          })}
      </S.Content>
    </S.Wrapper>
  );
};
