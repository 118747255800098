/* eslint-disable react/require-default-props */
import { useCallback, useMemo, useState } from 'react';
import {
  RiSeedlingLine,
  RiStarHalfFill,
  RiCommandLine,
  RiCheckLine,
  RiPushpin2Line,
  RiSpamLine,
  RiSendPlaneLine,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { TaskCard } from '../../TaskCard';
import * as S from './styles';
import { Modal } from '../../../../../styles';
import NewTask from '../../../../../components/Modals/NewTask';
import PhaseEdit from '../../../../../components/Modals/PhaseEdit';
import { EditFinalPhase } from '../../../../../components/Modals/EditFinalPhase';
import { PopupText } from '../../../../../components/PopupText';
import { restorePhase } from '../../../../../services/RestorePhase';
import { useTasks } from '../../../../../../../contexts/TasksContext';
import { SetPhases } from '../../../../../utils/SetPhases';

interface BoardProps {
  phases: Phase[];
  phase: Phase;
  index: number;
}

export function Board({ phases, phase, index }: BoardProps) {
  const { projects, setProjects } = useTasks();
  const { projectId, groupId } = useParams<TasksParams>();
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenModal = (type: string): void => {
    type === 'new'
      ? setOpenModal(!openModal)
      : setOpenModalEdit(!openModalEdit);
  };

  const handleCloseModal = (): void => {
    setOpenModalEdit(false);
    setOpenModal(false);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') {
      setOpenModalEdit(false);
      setOpenModal(false);
    }
  };

  const handleRestorePhase = useCallback(
    async (phaseToRestore: Phase) => {
      await restorePhase(phaseToRestore.id).then(() => {
        const phaseUndeleted = {
          ...phaseToRestore,
          was_removed: false,
        };

        const phasesUpdate = phases.filter(
          phaseItem => phaseItem.id !== phaseToRestore.id
        );

        phasesUpdate.splice(phaseToRestore.order! - 1, 0, phaseUndeleted);
        const newProjects = SetPhases(
          phasesUpdate,
          projects,
          +projectId,
          +groupId
        );
        setProjects(() => newProjects);
        toast.success('Fase restaurada com sucesso');
      });
    },
    [phases, groupId, projectId, projects, setProjects]
  );

  const iconPhase = useMemo(() => {
    switch (phase.icon_name) {
      case 'RiSeedlingLine':
        return (
          <RiSeedlingLine
            className="icon"
            color="var(--green-primary)"
            size={20}
          />
        );
      case 'RiCommandLine':
        return (
          <RiCommandLine
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiStarHalfFill':
        return (
          <RiStarHalfFill
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiPushpin2Line':
        return (
          <RiPushpin2Line
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiSpamLine':
        return (
          <RiSpamLine
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiSendPlaneLine':
        return (
          <RiSendPlaneLine
            className="icon"
            color="var(--yellow-primary)"
            size={20}
          />
        );
      case 'RiCheckLine':
        return (
          <RiCheckLine className="icon" color="var(--cyan-primary)" size={20} />
        );
      default:
        return false;
    }
  }, [phase.icon_name]);
  return (
    <>
      <Draggable draggableId={`${phase.id}`} index={index}>
        {provided => (
          <S.Wrapper {...provided.draggableProps} ref={provided.innerRef}>
            <div
              className="drag"
              {...provided.dragHandleProps}
              style={{ height: '95%' }}
            >
              <S.Title>
                {iconPhase}
                <S.TaskName>
                  <PopupText popUpText={phase.name}>
                    {phase.name.length > 27
                      ? `${phase.name.substring(0, 20)}...`
                      : phase.name}
                  </PopupText>
                </S.TaskName>
                <button
                  id="edit-button"
                  type="button"
                  onClick={() => handleOpenModal('edit')}
                >
                  <FaRegEdit size={14} color="var(--gray)" />
                </button>
              </S.Title>
              {phase.was_removed && (
                <S.RestorePhase>
                  <button
                    type="button"
                    title={phase.name}
                    onClick={() => handleRestorePhase(phase)}
                  >
                    <RiDeleteBin2Line size={14} color="var(--red-primary)" />
                    <p id="restore-label">Restaurar Fase</p>
                  </button>
                </S.RestorePhase>
              )}
              <Droppable droppableId={`${index}`}>
                {providedItem => (
                  <S.Content
                    ref={providedItem.innerRef}
                    {...providedItem.droppableProps}
                  >
                    {phase.tasks &&
                      phase.tasks.map((task, taskIndex) => {
                        return (
                          <div key={task.id}>
                            <TaskCard
                              task={task}
                              index={taskIndex}
                              phase={phase}
                              tasks={phase.tasks}
                              opacity={
                                index === phases.length - 1 ? '0.6' : '1'
                              }
                            />
                          </div>
                        );
                      })}
                    {providedItem.placeholder}
                  </S.Content>
                )}
              </Droppable>
            </div>
            <S.Footer>
              {index === 0 && (
                <button
                  id="new-task"
                  type="button"
                  onClick={() => handleOpenModal('new')}
                >
                  Nova Task
                </button>
              )}
            </S.Footer>
          </S.Wrapper>
        )}
      </Draggable>
      {openModal && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NewTask
            handleClose={handleCloseModal}
            phaseId={phase.id}
            phases={phases}
          />
        </Modal>
      )}
      {openModalEdit && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          {index === phases.length - 1 ? (
            <EditFinalPhase
              handleClose={handleCloseModal}
              phase={phase}
              phases={phases}
            />
          ) : (
            <PhaseEdit
              phase={phase}
              phases={phases}
              groupId={+groupId}
              projectId={+projectId}
              handleClose={handleCloseModal}
            />
          )}
        </Modal>
      )}
    </>
  );
}
