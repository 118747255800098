import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 84vh;
  overflow-y: auto;
  > div {
    margin-top: 20px;
  }
`;
export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  > div {
    margin-top: 10px;
  }
`;
