import { FC, useEffect, useRef, useCallback, useMemo } from 'react';

import * as S from './styles';
import { SelectProps } from '../types';

export const Select: FC<SelectProps> = ({
  options: fatherOptions,
  onChange,
  value,
  name,
}) => {
  const ulRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const options = useMemo(() => {
    return fatherOptions;
  }, [fatherOptions]);

  useEffect(() => {
    const inputReference = inputRef.current;
    const ulReference = ulRef.current;

    if (inputReference && ulReference) {
      inputReference.addEventListener('click', event => {
        event.stopPropagation();
        ulReference.style.display = 'flex';
      });
      document.addEventListener('click', () => {
        ulReference.style.display = 'none';
      });
    }
  }, [onChange]);

  const handleGetValue = useCallback(() => {
    if (options) {
      const filteredOptions = options.filter(option => option.value === value);
      const [filteredOption] = filteredOptions;

      if (filteredOption) return filteredOption.name;
    }

    if (value === 'null') return '';

    return value;
  }, [options, value]);

  return (
    <S.Container>
      <input
        value={handleGetValue()}
        maxLength={0}
        autoComplete="false"
        placeholder="Selecionar"
        ref={inputRef}
      />
      <ul ref={ulRef}>
        {options?.map(option => {
          return (
            <button
              type="button"
              key={option.value}
              onClick={() => {
                const inputRefResult = inputRef.current;

                if (inputRefResult) {
                  if (inputRefResult.value !== option.name) {
                    inputRefResult.value = option.name;
                    onChange({
                      target: {
                        name,
                        value: option.value,
                      },
                    });
                  }
                }
              }}
            >
              {option.name}
            </button>
          );
        })}
      </ul>
    </S.Container>
  );
};
