import { FC } from 'react';

import * as S from './styles';

interface ProgressBarProps {
  percentage: string;
  color: string;
  title: string;
  empty?: boolean;
}

const ProgressBar: FC<ProgressBarProps> = ({
  percentage,
  color,
  title,
  empty = false,
}) => {
  return (
    <S.Container>
      <S.ProgressBar percentage={percentage}>
        <div
          className="progress"
          style={{
            background: color,
          }}
        >
          {!empty && <h5>{title || `${percentage}%`}</h5>}
        </div>
      </S.ProgressBar>
    </S.Container>
  );
};

export default ProgressBar;
