/* eslint-disable no-nested-ternary */
import { FC, useMemo, useState } from 'react';

import { differenceInHours } from 'date-fns';
import Avatar from 'react-avatar';
import { useAuth } from '../../../../contexts/AuthContext';

import PostEdit from './edit';
import PostView from './view';
import * as S from './styles';

type User = {
  id: number;
  name: string;
  last_name: string;
  active_token: string;
  avatar: {
    url: string;
  };
};

type Post = {
  id: number;
  name: string;
  ticket: {
    category: string;
    subcategory: string;
  };
  published: string;
  createdAt: Date;
  user: User;
  voteId: number;
  problem: string;
  reasons: string;
  solutions: string;
  ticket_id: number;
  youtube: string;
};

interface WikiPostProps {
  post: Post;
  getPosts: () => Promise<void>;
  editable: boolean;
}

const WikiPost: FC<WikiPostProps> = ({ post, getPosts, editable }) => {
  const [active, setActive] = useState(false);
  const { id: userId } = useAuth().user;

  const { dueDate, hours } = useMemo(() => {
    const time = differenceInHours(new Date(), new Date(post.createdAt));

    return {
      dueDate: post.published
        ? 'Publicado'
        : 168 - time > 72
        ? `${Math.round((168 - time) / 24)} dias`
        : `${168 - time}h`,
      hours: 168 - time,
    };
  }, [post.createdAt, post.published]);

  return (
    <>
      <S.Container onClick={() => setActive(true)} editable={editable}>
        <S.Header>
          {editable && (
            <S.Urgency dueDate={hours} open={!post.published}>
              <div id="priority">
                <p>WikiPost</p>
              </div>
              <div id="due_date">{dueDate}</div>
            </S.Urgency>
          )}
          <S.What category={post.ticket.category}>
            <div id="category">
              <p>{post.ticket.category.toUpperCase()}</p>
            </div>
            <div id="subcategory">
              <p>{post.ticket.subcategory.toUpperCase()}</p>
            </div>
          </S.What>
        </S.Header>
        <h1>{post.name}</h1>

        {editable && (
          <S.Footer isUser={post.user && post.user.id === userId}>
            {post.user && (
              <Avatar
                name={`${post.user.name} ${post.user.last_name}`}
                src={post.user.avatar && post.user.avatar.url}
                className="avatar"
                color="var(--gray)"
                key={post.user.id}
              />
            )}
          </S.Footer>
        )}
      </S.Container>
      {active &&
        (editable ? (
          <PostEdit
            setActive={setActive}
            post={post}
            userId={userId}
            getPosts={getPosts}
          />
        ) : (
          <PostView setActive={setActive} id={post.id} />
        ))}
    </>
  );
};

export default WikiPost;
