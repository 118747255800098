import { ChangeEvent, FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import { ReactComponent as TopAtomo } from '../../../../assets/images/top-atomo.svg';
import { ReactComponent as BottomAtomo } from '../../../../assets/images/bottom-atomo.svg';

import * as S from './styles';

const ForgotPassword: FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  function onChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    await api
      .post('/forget_password', {
        email,
      })
      .then(() => {
        toast.success('Email enviado, confira sua caixa de email e spam!');
        history.push(`/`);
      })
      .catch(() => {
        setLoading(false);
        toast.error('Email invalido! Por favor, confira o preenchimento');
      });
  }

  return (
    <S.Container>
      <S.BackgroundContainer />
      <TopAtomo
        style={{
          position: 'absolute',
          top: '-6%',
          right: '-4%',
        }}
      />
      <BottomAtomo
        style={{
          position: 'absolute',
          bottom: '-6%',
          left: '-4%',
        }}
      />
      <S.Form onSubmit={onSubmit}>
        <S.Logo />
        <h1>Recupere sua senha</h1>
        <strong>Enviaremos a restauração para seu e-mail</strong>
        <S.InputContainer htmlFor="email">
          Email
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Email"
            value={email}
            onChange={onChangeEmail}
            required
          />
        </S.InputContainer>
        <button type="submit">{loading ? 'Enviando...' : 'Enviar'}</button>
        <p>
          Voltar para o <Link to="/">Login</Link>
        </p>
      </S.Form>
    </S.Container>
  );
};

export default ForgotPassword;
