import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getProjects(
  projectId: number,
  query = ''
): Promise<Project[]> {
  try {
    const response = await apiV2.get(`projects/${projectId}${query}`);

    const projects = response.data;

    return projects;
  } catch {
    toast.error(
      'Ocorreu algum problema ao carregar os projetos, atualize a página e tente novamente :S'
    );
    return [];
  }
}
