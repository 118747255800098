export const formatContractsFee = (contractsToUpdated: Contract[]) => {
  const contractsBeforeFormat = contractsToUpdated;

  const formatedContracts = contractsBeforeFormat.map(contract => {
    let newContract = contract;

    newContract = {
      ...newContract,
      fee: newContract.fee / 100,
    };
    return newContract;
  });
  return formatedContracts;
};
