import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function restoreTask(taskId: number): Promise<void> {
  try {
    await apiV2.post(`tasks/undelete/${taskId}`);
  } catch {
    toast.error(
      'Problema ao restaurar sua task, atualize a página e tente novamente 😕'
    );
  }
}
