import { useState } from 'react';
import { Link } from 'react-router-dom';

import { RiErrorWarningLine, RiArrowDownSLine } from 'react-icons/ri';

import * as S from './styles';

interface ClientProps {
  id: number;
  name: string;
  handleMenuActive: () => void;
}

export function Client({ id, name, handleMenuActive }: ClientProps) {
  const [active, setActive] = useState(false);

  return (
    <S.Container className={active ? 'active' : ''}>
      <S.Submenu className={active ? 'active' : ''}>
        <button type="button" onClick={() => setActive(!active)}>
          <p>{name}</p>
        </button>
        <RiArrowDownSLine onClick={() => setActive(!active)} />
      </S.Submenu>
      {active && (
        <S.OpenSubmenu>
          <hr />
          {/* <li>
            <Link
              to={`/tasks/${id}/list`}
              title={name}
              onClick={handleMenuActive}
            >
              <RiClipboardLine />
              <p>Tasks</p>
            </Link>
          </li>
          <li>
            <Link to={`/checkin/quarters/${id}/1`} onClick={handleMenuActive}>
              <RiCalendarCheckLine />
              <p>Check-in</p>
            </Link>
          </li> */}
          <li>
            <Link to={`/client/profile/${id}`} onClick={handleMenuActive}>
              <RiErrorWarningLine />
              <p>Perfil</p>
            </Link>
          </li>
        </S.OpenSubmenu>
      )}
    </S.Container>
  );
}
