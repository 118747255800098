import { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';

import Profile from './pages/Profile';
import api from '../../services/api';

export default function Clients() {
  const [client, setClient] = useState();

  const history = useHistory();
  const { id } = useParams();
  const { unit_id: userUnitId } = useAuth().user;
  const matrizUnit = 1;

  const goBack = useCallback(async () => {
    history.push(`/`);
  }, [history]);

  const getClient = useCallback(async () => {
    const response = await api.get(`/clients/${id}`);

    let authorized = false;

    response.data.units.forEach(unit => {
      if (unit.id === userUnitId) authorized = true;
      if (userUnitId === matrizUnit) authorized = true;
    });

    setClient(response.data);

    if (!authorized) goBack();
  }, [id, userUnitId, goBack]);

  useEffect(() => {
    getClient();
  }, [getClient, id]);

  return <Profile client={client} />;
}
