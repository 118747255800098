import React, { useState, useCallback } from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';

import { TextInput, Button } from '../../../../components/Inputs';

import * as S from './styles';

interface EmailChange {
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
}

const ChangeEmail: React.FC<EmailChange> = ({ emails, setEmails }) => {
  const [newEmail, setNewEmail] = useState('');

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!emails.includes(newEmail)) {
        setEmails([...emails, newEmail]);
      }
    },
    [setEmails, newEmail, emails]
  );

  const handleEmailRemove = useCallback(
    deleteEmail => {
      const newEmailList = emails.filter(email => email !== deleteEmail);

      setEmails(newEmailList);
    },
    [emails, setEmails]
  );

  return (
    <S.Container onSubmit={handleSubmit}>
      <div>
        <p>Emails:</p>
        <TextInput
          value={newEmail}
          maxLength={255}
          type="email"
          placeholder="Digite o e-mail."
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNewEmail(e.target.value);
          }}
        />
      </div>

      {emails.map(email => {
        return (
          <div key={email} className="email">
            <p>{email}</p>
            <RiCloseCircleLine
              onClick={() => handleEmailRemove(email)}
              size={16}
              color="var(--red-primary)"
            />
          </div>
        );
      })}

      <Button
        background="var(--red-primary)"
        width="100%"
        height="1.6rem"
        text="Adicionar e-mail"
        font="400 0.9rem Work Sans"
        type="submit"
      />
    </S.Container>
  );
};

export default ChangeEmail;
