/* eslint-disable react/require-default-props */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { RiMore2Fill } from 'react-icons/ri';

import { Tooltip } from './Tooltip';
import * as S from './styles';
import { editTag } from '../../services/EditTag';

type TagType = {
  id: number;
  name: string;
  color: string;
};

interface TagProps {
  setTagsList: Dispatch<SetStateAction<ITag[]>>;
  showLabel?: boolean;
  setShowLabel?: (value: boolean) => void;
  tag: TagType;
  type?: string;
  typeId?: number;
}

export const Tag = ({
  setTagsList,
  showLabel = true,
  setShowLabel,
  tag,
  type,
  typeId,
}: TagProps): JSX.Element => {
  const [tooltipArea, setTooltipArea] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(tag.color);

  const handleOpenTooltip = useCallback(() => {
    setTooltipArea(state => !state);
  }, []);

  const handleShowLabel = useCallback(() => {
    if (setShowLabel) {
      setShowLabel(!showLabel);
    }
  }, [setShowLabel, showLabel]);

  const handleEditTag = useCallback(async () => {
    const editedTag = await editTag(tag.id, backgroundColor);

    setTagsList(state => state.filter(tagItem => tagItem.id !== tag.id));
    if (editedTag) setTagsList(state => [...state, editedTag]);
  }, [tag.id, setTagsList, backgroundColor]);

  useEffect(() => {
    if (!tooltipArea && backgroundColor !== tag.color) {
      handleEditTag();
    }
  }, [handleEditTag, tooltipArea, tag.color, backgroundColor]);

  return (
    <S.Wrapper title={tag.name}>
      <S.Content
        style={{ background: `${backgroundColor}` }}
        onClick={!showLabel ? handleShowLabel : () => ''}
      >
        {showLabel && (
          <>
            <button
              id="tag-name"
              type="button"
              onClick={showLabel ? handleShowLabel : () => ''}
            >
              {tag.name}
            </button>
            <button id="tag-options" type="button" onClick={handleOpenTooltip}>
              <RiMore2Fill color="var(--white)" size={15} />
            </button>
          </>
        )}
      </S.Content>
      {tooltipArea && (
        <Tooltip
          id={tag.id}
          type={type}
          typeId={typeId}
          setTooltipArea={setTooltipArea}
          setBackgroundColor={setBackgroundColor}
          setTagsList={setTagsList}
        />
      )}
    </S.Wrapper>
  );
};
