import React, { useCallback, useState, useEffect } from 'react';
import { RiAwardLine } from 'react-icons/ri';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import apiV2 from '../../../../../services/apiV2';

import { Button } from '../../../../../components/Inputs';

import { CheckinObject } from '../../../types';

import AddPresentationLink from '../AddPresentationLink';

import * as S from './styles';

interface CheckinCardProps {
  infos: CheckinObject;
  isDraft?: boolean;
  showName?: boolean;
  handleEditLink?: (link: string, id: number) => void;
}

interface Csat {
  id: string;
  comment: string | null;
  score: number;
}

const CheckinCard: React.FC<CheckinCardProps> = ({
  infos,
  handleEditLink,
  showName = true,
  isDraft = false,
}) => {
  const [csat, setCsat] = useState<Csat>();

  const hasCsat = !!csat?.score;
  const scoreIndex = csat ? csat.score - 1 : 0;
  const csatColors = ['#FF565F', '#FC5604', '#FFD119', '#9FD973', '#65CB65'];
  const csatDescriptions = [
    'Muito Insatisfeito',
    'Insatisfeito',
    'Indiferente',
    'Satisfeito',
    'Muito Satisfeito',
  ];

  const handleGetCsat = useCallback(async () => {
    const response = await apiV2.get(
      `csat/getByCheckinIds?checkinIds=${infos.id}`
    );

    const [currentCsat] = response.data;
    setCsat(currentCsat);
  }, [infos.id]);

  useEffect(() => {
    if (!isDraft) handleGetCsat();
  }, [handleGetCsat, isDraft]);

  return (
    <S.Container key={infos.id}>
      <p className="name">
        {showName && 'Nome: '}
        {showName && <strong>{infos.name} </strong>}
        Projeto: <strong>{infos.project_name}</strong>
      </p>

      <div className="date">
        <p>{format(new Date(infos.created_at), 'dd.MM.yyyy')}</p>
      </div>

      <div>
        {isDraft && (
          <Link to={`/checkin/create_new/${infos.id}`}>
            <Button
              text="EDITAR"
              background="var(--red-primary)"
              width="14rem"
              height="2rem"
              font="700 0.8rem Work Sans"
              className="link"
            />
          </Link>
        )}

        {!isDraft && hasCsat && csat && (
          <S.Csat color={csatColors[scoreIndex]}>
            <Button
              background={csatColors[scoreIndex]}
              width="100%"
              height="2rem"
              font="700 0.8rem Work Sans"
              className="csat"
            >
              <RiAwardLine size={16} color="var(--white)" />
            </Button>

            <span>
              {csatDescriptions[scoreIndex]}. <br />
              {csat.comment && `Avaliação do cliente: ${csat.comment}`}
            </span>
          </S.Csat>
        )}

        {!isDraft && (
          <Link to={`/checkin/presentation/${infos.id}/0`}>
            <Button
              text="APRESENTAR"
              background="var(--red-primary)"
              width="14rem"
              height="2rem"
              font="700 0.8rem Work Sans"
              className="link"
            />
          </Link>
        )}

        {!isDraft && handleEditLink && !infos.presentation_link && (
          <AddPresentationLink id={infos.id} handleEdit={handleEditLink} />
        )}
      </div>
    </S.Container>
  );
};

export default CheckinCard;
