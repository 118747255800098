import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  color: ${({ theme }) => theme.text};

  #name {
    width: 27%;
  }

  #responsible {
    width: 17%;
  }

  #period {
    width: 17%;
  }

  #progress {
    width: 17%;
  }

  @media (max-width: 1360px) {
    #name {
      width: 20%;
    }
    p {
      font-size: 0.5rem;
      margin-right: 0.4rem;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin: 0 0.5rem;
  }

  span {
    color: ${({ theme }) => theme.text};
  }
`;
