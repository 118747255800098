import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0%;

  main {
    width: 100%;
    display: flex;
    padding: 2rem 0;
    justify-content: space-between;
    .doughnut-chart {
      width: 10%;
      fill: ${({ theme }) => theme.text};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      width: 12rem;
      height: 1.8rem;
      border: 0;
      border-radius: 0.3rem;
      background: var(--red-primary);
      color: var(--white);
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;
