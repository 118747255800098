import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';

import Default from '../styles/Layouts/Default';
import Authenticated from '../styles/Layouts/Authenticated';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  ...rest
}: RouteProps) {
  const { user } = useAuth();

  const Layout = user ? Authenticated : Default;

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return isPrivate === !!user ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect to={{ pathname: isPrivate ? '/' : '/home' }} />
        );
      }}
    />
  );
}
