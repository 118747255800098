import { useState } from 'react';
import {
  RiFoldersLine,
  RiSave2Line,
  RiGitPullRequestLine,
} from 'react-icons/ri';

import { Modal } from '../../styles';
import ImportPlaybook from '../Modals/Playbook/ImportPlaybook';
import ExportPlaybook from '../Modals/Playbook/ExportPlaybook';
import NewProject from '../Modals/NewProject';
import * as S from './styles';
import { ExportPlaybookProvider } from '../../../../contexts/ExportPlaybookContext/index';
import { ImportPlaybookProvider } from '../../../../contexts/ImportPlaybookContext';

export function SideButtonMenu() {
  const [openNewProject, setOpenNewProject] = useState(false);
  const [openExportPlaybook, setOpenExportPlaybook] = useState(false);
  const [openImportPlaybook, setOpenImportPlaybook] = useState(false);

  const handleOpenModal = (type: string): void => {
    switch (type) {
      case 'import':
        setOpenImportPlaybook(!openImportPlaybook);
        break;
      case 'export':
        setOpenExportPlaybook(!openExportPlaybook);
        break;
      default:
        setOpenNewProject(!openNewProject);
        break;
    }
  };

  const handleCloseModal = (): void => {
    setOpenNewProject(false);
    setOpenExportPlaybook(false);
    setOpenImportPlaybook(false);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') {
      setOpenNewProject(false);
      setOpenExportPlaybook(false);
      setOpenImportPlaybook(false);
    }
  };

  return (
    <S.Wrapper>
      <S.Container>
        <button
          type="button"
          className="item-menu"
          onClick={() => handleOpenModal('new')}
        >
          <RiFoldersLine size={26} />
          <p>
            Novo <br /> Projeto
          </p>
        </button>

        <button
          type="button"
          className="item-menu"
          onClick={() => handleOpenModal('export')}
        >
          <RiSave2Line size={26} />
          <p>
            Salvar <br /> Playbook
          </p>
        </button>

        <button
          type="button"
          className="item-menu"
          onClick={() => handleOpenModal('import')}
        >
          <RiGitPullRequestLine size={26} />
          <p>
            Importar <br /> Playbook
          </p>
        </button>
      </S.Container>
      {openNewProject && (
        <Modal id="modal" onClick={handleOutsideClick}>
          <NewProject handleClose={handleCloseModal} />
        </Modal>
      )}
      {openExportPlaybook && (
        <ExportPlaybookProvider>
          <Modal id="modal" onClick={handleOutsideClick}>
            <ExportPlaybook handleClose={handleCloseModal} />
          </Modal>
        </ExportPlaybookProvider>
      )}
      {openImportPlaybook && (
        <ImportPlaybookProvider>
          <Modal id="modal" onClick={handleOutsideClick}>
            <ImportPlaybook handleClose={handleCloseModal} />
          </Modal>
        </ImportPlaybookProvider>
      )}
    </S.Wrapper>
  );
}
