import { useState } from 'react';
import { useDrop } from 'react-dnd';
import * as S from './styles';
import { DetailsBar } from '../../components/DetailsBar';
import { ProjectCard } from './components/ProjectCard';
import { ProjectCardLoading } from './components/Loadings';
import { ItenTypes } from '../../types';
import { Modal } from '../../styles';
import NewProject from '../../components/Modals/NewProject';
import { useTasks } from '../../../../contexts/TasksContext';
import { changeProjectOrder } from '../../services/ChangeProjectOrder';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    move?: (from: number, to: number) => void;
  }
}

export function ListView() {
  const { projects, setProjects, projectsLoading } = useTasks();
  const [openModal, setOpenModal] = useState(false);
  const [draggingTimeOut, setDraggingTimeOut] = useState(0);

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') setOpenModal(!openModal);
  };

  const [, drop] = useDrop({
    accept: ItenTypes.PROJECT,
    drop: () => ({ name: 'Project' }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const moveProjectCardHandler = (
    selectedProjectIndex: number,
    destinyProjectIndex: number,
    project: Project
  ): void => {
    const selectedProjectToMove = projects[selectedProjectIndex];
    const destinyProject = projects[destinyProjectIndex];

    if (selectedProjectToMove) {
      const newProjects = [...projects];
      const prevProject = newProjects.splice(
        destinyProjectIndex,
        1,
        selectedProjectToMove
      );

      newProjects.splice(selectedProjectIndex, 1, prevProject[0]);

      setProjects(() => newProjects);
    }

    if (draggingTimeOut) {
      clearTimeout(draggingTimeOut);
    }

    setDraggingTimeOut(
      window.setTimeout(() => {
        changeProjectOrder(project.id, destinyProject.order);
      }, 500)
    );
  };

  return (
    <S.Container>
      <div className="width-fixer">
        <DetailsBar setOpenModal={setOpenModal} data-testid="details-bar" />
        <S.Content>
          {!projectsLoading ? (
            projects.map((project, index) => {
              return (
                <div ref={drop} className="content" key={project.id}>
                  <ProjectCard
                    data-testid="project"
                    project={project}
                    index={index}
                    move={moveProjectCardHandler}
                  />
                </div>
              );
            })
          ) : (
            <ProjectCardLoading />
          )}
        </S.Content>
      </div>
      {openModal && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NewProject handleClose={handleCloseModal} />
        </Modal>
      )}
    </S.Container>
  );
}
