import React from 'react';
import { format } from 'date-fns';

import Edit from '../Edit';
import { CheckinTask } from '../../../../types';

interface TaskProps {
  task: {
    task: Task | CheckinTask;
    projectName: string;
    groupName?: string | undefined;
    phaseName?: string | undefined;
  };
  editFunction?: (
    id: number,
    name: string,
    date: Date,
    createdNow: boolean
  ) => Promise<void>;
  finished?: boolean;
  recentTask?: boolean;
}

const TaskCard: React.FC<TaskProps> = ({
  task,
  editFunction,
  finished = false,
  recentTask = false,
}) => {
  return (
    <div className="task">
      <div>
        <p>
          {task.projectName} {task.groupName && '>'} {task.groupName}{' '}
          {task.phaseName && '>'} {task.phaseName}
        </p>
        <p>{task.task.name}</p>
      </div>

      <div className="rightSideOfTask">
        <p
          style={
            finished
              ? {
                  background: !task.task.finished_at
                    ? 'var(--yellow-primary)'
                    : 'var(--green-primary)',
                }
              : {}
          }
          className="doneDate"
        >
          {`${finished ? 'Concluído em' : 'Prazo até'}
          ${format(new Date(task.task.due_date), 'dd.MM.yyyy')}`}
        </p>
        {editFunction && (
          <Edit
            name={task.task.name}
            id={task.task.id}
            date={new Date(task.task.due_date)}
            editFunction={editFunction}
            recentTask={recentTask}
          />
        )}
      </div>
    </div>
  );
};

export default TaskCard;
