import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundTerciary};
  width: 16.25rem;
  height: 23rem;
  padding: 1rem;
  border-radius: 0.8rem;
  margin: 0.5rem;

  #name {
    width: 100%;
    height: 30%;
    margin-top: 3rem;
    flex-direction: column;
    justify-content: center;
  }

  #period {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;

    & + div {
      width: 14rem;
    }
  }
  #progress {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;

    & + div {
      width: 14rem;
    }
  }
  #responsibles {
    width: 100%;
    height: 30%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }

  .animation {
    animation-name: loading;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    @keyframes loading {
      0% {
        background-color: var(--gray);
      }
      10% {
        background-color: var(--gray-text);
      }
      20% {
        background-color: var(--gray);
      }
      30% {
        background-color: var(--gray-text);
      }
      40% {
        background-color: var(--gray);
      }
      50% {
        background-color: var(--gray-text);
      }
      60% {
        background-color: var(--gray);
      }
      70% {
        background-color: var(--gray-text);
      }
      80% {
        background-color: var(--gray);
      }
      90% {
        background-color: var(--gray-text);
      }
      100% {
        background-color: var(--gray);
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const NameBar = styled.div`
  width: 10rem;
  height: 0.8rem;
  margin: 0.2rem 0;
  border-radius: 0.2rem;
  background-color: var(--gray);
`;

export const AvatarIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--gray);
  margin-left: -0.5rem;
  border: 0.1rem solid ${({ theme }) => theme.backgroundPrimary};
`;

export const ProgressBar = styled.div`
  height: 1.5rem;
  background-color: var(--gray);
  border-radius: 0.2rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 12rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundTerciary};
  margin: 0.5rem 0;
  padding: 1rem;

  .progress {
    margin-top: 0.5rem;
  }

  #name {
    width: 100%;
    height: 30%;
    flex-direction: column;
    justify-content: center;
    & > :first-child {
      width: 20%;
    }
  }

  #period {
    width: 100%;
    height: 30%;
    margin-top: 1rem;
    display: flex;
    div {
      width: 49%;
      margin-right: 1rem;
    }
  }

  #managers {
    display: flex;
    height: 30%;
    margin-top: 1rem;
    width: 100%;
    align-items: center;
    & > :first-child {
      width: 20%;
      margin-right: 1rem;
    }
  }

  #responsibles {
    width: 100%;
    display: flex;
  }

  .animation {
    animation-name: loading;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    @keyframes loading {
      0% {
        background-color: var(--gray);
      }
      10% {
        background-color: var(--gray-text);
      }
      20% {
        background-color: var(--gray);
      }
      30% {
        background-color: var(--gray-text);
      }
      40% {
        background-color: var(--gray);
      }
      50% {
        background-color: var(--gray-text);
      }
      60% {
        background-color: var(--gray);
      }
      70% {
        background-color: var(--gray-text);
      }
      80% {
        background-color: var(--gray);
      }
      90% {
        background-color: var(--gray-text);
      }
      100% {
        background-color: var(--gray);
      }
    }
  }
`;
