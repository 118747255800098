import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  overflow-y: auto;
  padding: 1rem;
  margin: 0px;
  border-radius: 8px;
  justify-content: space-between;

  p {
    font-size: 0.7rem;
    margin-bottom: 0.5vh;
    color: ${({ theme }) => theme.text};
  }
`;

export const Title = styled.h1`
  font-size: 0.8rem;
  margin-bottom: 0.5vh;
  text-align: center;
  color: ${({ theme }) => theme.text};
`;

export const SalesFunnel = styled.ul`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  flex: 1;
  justify-content: flex-end;
  li {
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
    h3 {
      font-size: 0.75rem;
      width: 30%;
      color: ${({ theme }) => theme.text};
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 70%;
      h4 {
        font-size: 0.85rem;
        border-radius: 0.4rem;
        color: ${({ theme }) => theme.text};
        text-align: center;
      }
      hr {
        position: relative;
        color: ${({ theme }) => theme.text};
        &::before {
          content: '';
          position: absolute;
          bottom: -15px;
          left: -2px;
          width: 1px;
          height: 0.1rem;
          background-color: ${({ theme }) => theme.text};
        }
        &::after {
          content: '';
          position: absolute;
          bottom: -15px;
          right: -2px;
          width: 1px;
          height: 0.1rem;
          background-color: ${({ theme }) => theme.text};
        }
      }
    }
  }
`;
export const FirstLine = styled.li`
  div {
    h4 {
      padding: 0.1rem 0;
    }
    hr {
      width: 100%;
      margin-bottom: 0.3rem;
    }
  }
`;

export const Leads = styled.li`
  div {
    h4 {
      width: 100%;
      padding: 0.2rem 0;
      background-color: var(--red-primary);
    }
  }
`;

export const LastLine = styled.li`
  div {
    h4 {
      padding: 0.1rem 0;
    }
    hr {
      margin-top: 0.3rem;
    }
  }
`;
