import React, { useCallback } from 'react';

import { RiCompassLine, RiUserLine } from 'react-icons/ri';

import EditKr from './EditKr';
import UserAvatar from '../../../../../components/UserAvatar';
import ProgressBar from '../../../../../components/Progressbar';

import { CardEditing } from '../types';

import * as S from './styles';

const MetaCard: React.FC<CardEditing> = ({
  cardInfo,
  removeFunction,
  editFunction,
  predefined = false,
}) => {
  const handlePercentage = useCallback(() => {
    let percentage = ((cardInfo.value / cardInfo.goal) * 100).toFixed(0);

    if (cardInfo.is_the_lesser_the_better) {
      percentage = ((cardInfo.goal / cardInfo.value) * 100).toFixed(0);
    }

    if (percentage === 'NaN') {
      percentage = '0';
    }

    return percentage;
  }, [cardInfo.value, cardInfo.goal, cardInfo.is_the_lesser_the_better]);

  const handleProgressColor = useCallback(progress => {
    if (progress >= 100) {
      return 'var(--green-primary)';
    }

    if (progress >= 80) {
      return 'var(--cyan-primary)';
    }

    return 'var(--yellow-primary)';
  }, []);

  return (
    <S.Container>
      <div className="name">
        <p>{cardInfo.name}</p>
        {!predefined && (
          <EditKr
            removeFunction={removeFunction}
            editFunction={editFunction}
            cardInfo={cardInfo}
          />
        )}
      </div>

      <S.MetaProgress>
        <div className="metaName">
          <RiCompassLine size={20} color="var(--red-primary)" />
          <p>Meta</p>
        </div>

        <div className="progress">
          <ProgressBar
            percentage={`${handlePercentage()}`}
            color={handleProgressColor(handlePercentage())}
            title={`${handlePercentage()} %`}
          />
        </div>
      </S.MetaProgress>

      <S.Users>
        <RiUserLine size={19} color="var(--red-primary)" />
        <p>Time</p>
        <div className="responsibles">
          {cardInfo.users_id &&
            cardInfo.users_id.map(user => {
              return <UserAvatar key={user.id} id={user.id} size="1.5rem" />;
            })}
        </div>
      </S.Users>
    </S.Container>
  );
};

export default MetaCard;
