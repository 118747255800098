import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Content = styled.div`
  min-height: 0.5rem;
  max-height: 1.3rem;
  min-width: 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 0 0.2rem 0 1.1rem;
  position: relative;
  cursor: pointer;

  button#tag-name {
    font-size: 0.75rem;
    border: 0;
    background: transparent;
    color: var(--white);
  }
  button#tag-options {
    width: 1rem;
    height: 1rem;
    border: 0;
    background: transparent;
    margin-top: 0.2rem;
    margin-left: 1rem;
  }
`;
