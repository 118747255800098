/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { addDays, addMonths, addMinutes, format } from 'date-fns';
import NewPopUp from '../../../../components/PopUp';
import api from '../../../../services/api';

import { Container, Radio, SelectedRadio } from './styles';

const EditPopup = ({
  setContactPopup,
  itemId,
  userName,
  setOverallUpdate,
  setOnLoading,
  overallUpdate,
}) => {
  const [selectedRadio, setSelectedRadio] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    api.get(`billing/contacts?billingId=${itemId}`).then(res => {
      const contacts = res.data;

      if (contacts[contacts.length - 1]) {
        setDate(
          format(
            addMinutes(
              new Date(contacts[contacts.length - 1].contact_date),
              500
            ),
            'yyyy-MM-dd'
          )
        );
        setDescription(contacts[contacts.length - 1].contact_result);

        if (contacts[contacts.length - 1].contacted) {
          setSelectedRadio('yes');
        }

        if (!contacts[contacts.length - 1].contacted) {
          setSelectedRadio('no');
        }
      }

      return <p>Ainda não recebido</p>;
    });
  }, [itemId]);

  const handleDateFix = useCallback((endDate, addingMonths, months) => {
    const newDate = format(new Date(endDate), 'MM/dd/yyyy');

    if (addingMonths === true) {
      return addMonths(addDays(new Date(newDate), 1), months);
    }
    return addDays(new Date(newDate), 1);
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (selectedRadio === '') {
        toast.error('Selecione Sim ou Não');
      }

      setOnLoading(true);
      await api
        .post('/billing/contacts', {
          contact_date: handleDateFix(date, false, 0),
          billing_id: itemId,
          contact_person: userName,
          contact_result: description,
          contacted: selectedRadio === 'yes',
        })
        .then(async () => {
          await setOverallUpdate(overallUpdate + 1);
          toast.success('Status de contato atualizado!');
          setContactPopup(false);
        })
        .catch(err => {
          if (err.response.status !== 200) {
            toast.error(`${err.response.data.error}`);
          }
        });

      setOnLoading(false);
    },
    [
      date,
      description,
      handleDateFix,
      itemId,
      overallUpdate,
      selectedRadio,
      setContactPopup,
      setOnLoading,
      setOverallUpdate,
      userName,
    ]
  );

  return (
    <NewPopUp
      width="40%"
      header="Obtenção de contato"
      closePopUp={setContactPopup}
    >
      <Container>
        <form onSubmit={handleSubmit}>
          <h1 style={{ fontWeight: 'bold', padding: '0px' }}>
            Obteve contato?
          </h1>

          <div className="formBox">
            <div className="radios">
              {!selectedRadio.includes('yes') ? (
                <Radio
                  onClick={() => setSelectedRadio('yes')}
                  className="radio"
                />
              ) : (
                <SelectedRadio
                  onClick={() => setSelectedRadio('no')}
                  className="radio"
                />
              )}
              <label>Sim</label>

              {!selectedRadio.includes('no') ? (
                <Radio
                  onClick={() => setSelectedRadio('no')}
                  className="radio"
                />
              ) : (
                <SelectedRadio
                  onClick={() => setSelectedRadio('yes')}
                  className="radio"
                />
              )}
              <label>Não</label>
            </div>

            <div className="formInput">
              <label>Data de contato</label>
              <input
                required
                type="date"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
            </div>

            <div className="formInput">
              <label>Observaçōes</label>
              <textarea
                required
                placeholder="Descreva suas observaçōes aqui!"
                id="description"
                name="description"
                cols="40"
                rows="5"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </div>
            <footer>
              <button
                onClick={() => setContactPopup(false)}
                className="button"
                type="button"
              >
                Cancelar
              </button>
              <button
                className="button"
                style={{ backgroundColor: 'var(--red-primary)' }}
                type="submit"
              >
                Salvar
              </button>
            </footer>
          </div>
        </form>
      </Container>
    </NewPopUp>
  );
};

export default EditPopup;
