import React, { Dispatch, SetStateAction } from 'react';
import { IoMdClose } from 'react-icons/io';

import * as S from './styles';

interface ModalHeaderProps {
  icon?: React.ComponentType;
  title: string;
  setModal: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  icon: Icon,
  title,
  setModal,
  disabled,
}) => {
  return (
    <S.Header>
      {Icon && <Icon />}
      <h4>{title}</h4>
      <IoMdClose
        onClick={() => setModal(false)}
        className={disabled ? 'disabled' : ''}
      />
    </S.Header>
  );
};
export default ModalHeader;
