import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 10rem;
  height: 8rem;
  border-radius: 8px;
  margin-top: 2rem;
  padding: 0.7rem 0.2rem 0.7rem 0.4rem;
  position: absolute;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  box-shadow: 0 0.25rem 1.9rem 0 ${({ theme }) => theme.backgroundPrimary};
  z-index: 10;

  p {
    margin-right: 1rem;
  }

  input[type='color' i] {
    visibility: hidden;
  }

  label {
    width: 100%;
    height: 1.5rem;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.text};
    cursor: pointer;
    font-weight: bold;
    p {
      width: 70%;
    }
  }

  button {
    border: none;
    background: transparent;
    display: flex;
    font-family: inherit;
    color: ${({ theme }) => theme.text};
    font-weight: bold;
    font-size: 1.01rem;
    margin-top: 0.8rem;
  }

  button:last-child {
    background: ${({ theme }) => theme.backgroundTerciary};
    width: 100%;
    height: 100%;
    padding: 0.4rem;
    border-radius: 8px;
  }
`;
