import styled from 'styled-components';

export const EditCard = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 100%;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;
  border: 0;

  header {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    border-bottom: solid 1px ${({ theme }) => theme.backgroundTerciary};
    padding-bottom: 0.5rem;
    padding-top: 0.2rem;

    p {
      ${({ theme }) => theme.text}
    }
  }

  header svg {
    margin-left: 0.1rem;
    margin-right: 0.5rem;
  }

  footer {
    margin-top: 0.7rem;

    .midButton {
      margin-top: 0.7rem;
      margin-bottom: 0.8rem !important;
      width: 100% !important;
    }
  }
`;

export const Input = styled.div`
  margin-bottom: 1rem;

  .select-search__input {
    width: 100% !important;
    height: 1.8rem !important;
    font: 400 0.9rem Work Sans !important;
    background: ${({ theme }) => theme.backgroundPrimary};
  }

  .select-search input {
    background: ${({ theme }) => theme.backgroundPrimary};
  }

  .select-search__select {
    height: 7rem;
    overflow-y: auto;
  }

  input {
    margin-top: 0.5rem;
  }
`;

export const Checkbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;

  min-height: 18px;
  min-width: 18px;
  max-height: 18px;
  max-width: 18px;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 4px;
  border: 0;
  cursor: pointer;
`;
