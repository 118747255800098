import styled from 'styled-components';

export const Container = styled.div`
  button {
    margin-bottom: 1rem;
  }

  .okrs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;

    @media (max-width: 800px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .okr {
    padding: 1rem;
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 8px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.backgroundTerciary};
  border-radius: 1px;

  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.text};
  }

  svg {
    display: flex;
    margin-right: 0.5rem;
  }

  .nameAndIcon {
    display: flex;
  }

  .trashBin {
    cursor: pointer;
    transition: 0.3s;

    :hover {
      opacity: 0.4;
    }
  }
`;
