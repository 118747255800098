import { addDays, differenceInDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { RiEditBoxLine } from 'react-icons/ri';

import { Calendar } from '../../../../../../../../components/Calendar';
import ResponsibleIds from '../../../../../../../../components/ResponsibleIds';
import { useImportPlaybook } from '../../../../../../../../contexts/ImportPlaybookContext';
import { Modal } from '../../../../../../styles';

import CloseButton from '../../../components/CloseButton';

import {
  Container,
  Label,
  Deadline,
  SeparatorVertical,
  Buttons,
} from '../../../styles/TaskContainer';

interface TaskProps {
  task: IPlaybookTask;
  tasks: IPlaybookTask[];
  projectId: number;
  groupId: number;
  phaseId: number;
  setGroupDueDate: Dispatch<SetStateAction<number>>;
  setProjectDueDate: Dispatch<SetStateAction<number>>;
}

const Task: React.FC<TaskProps> = ({
  task,
  projectId,
  groupId,
  phaseId,
  setGroupDueDate,
  setProjectDueDate,
}) => {
  const [firstTime, setFirstTime] = useState(true);
  const [users, setUsers] = useState<IUserId[]>([]);
  const [backupUsers, setBackupUsers] = useState<IUserId[]>([]);

  const [taskDueDate, setTaskDueDate] = useState<Date | undefined>(new Date());

  const [openNewCalendar, setOpenNewCalendar] = useState(false);

  const handleOpenCalendar = (): void => {
    setOpenNewCalendar(!openNewCalendar);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'calendar-modal') handleOpenCalendar();
  };

  const {
    initialUsers,
    addUsersInTask,
    addUserToProjectByTask,
    deleteUserToProjectByTask,
    updateTaskName,
    updateTaskDescription,
    deleteTask,
    updateDueDateInTask,
    getMinimunTasksDueDate,
    getProjectDueDate,
  } = useImportPlaybook();

  const deleteTaskAndUpdateProjectDueDate = () => {
    deleteTask(projectId, groupId, task.id);
    const groupDueDate = getMinimunTasksDueDate(projectId, groupId);
    const projectDueDate = getProjectDueDate(projectId);
    setGroupDueDate(groupDueDate);
    setProjectDueDate(projectDueDate);
  };

  useEffect(() => {
    const diffDays = differenceInDays(taskDueDate!, new Date()) + 1;

    if (task.due_date !== diffDays) {
      updateDueDateInTask(diffDays, projectId, groupId, task.id);
      const groupDueDate = getMinimunTasksDueDate(projectId, groupId);
      const projectDueDate = getProjectDueDate(projectId);
      setGroupDueDate(groupDueDate);
      setProjectDueDate(projectDueDate);
    }
  }, [
    getMinimunTasksDueDate,
    getProjectDueDate,
    groupId,
    projectId,
    setGroupDueDate,
    setProjectDueDate,
    task.due_date,
    task.id,
    taskDueDate,
    updateDueDateInTask,
  ]);

  useEffect(() => {
    if (backupUsers.length !== users.length) {
      if (backupUsers.length > users.length) {
        const [deletedUser] = backupUsers.filter(user => !users.includes(user));

        addUsersInTask(users, projectId, groupId, task.id);
        deleteUserToProjectByTask(deletedUser, projectId, groupId, task.id);
      } else {
        const [addedUser] = users.filter(user => !backupUsers.includes(user));

        addUsersInTask(users, projectId, groupId, task.id);
        addUserToProjectByTask(addedUser, projectId, groupId);
      }
      setBackupUsers(users);
    }
  }, [
    addUserToProjectByTask,
    addUsersInTask,
    backupUsers,
    deleteUserToProjectByTask,
    groupId,
    phaseId,
    projectId,
    task.id,
    users,
  ]);

  useEffect(() => {
    if (firstTime) {
      addUsersInTask(initialUsers, projectId, groupId, task.id);
      setUsers(initialUsers);
      setBackupUsers(initialUsers);

      const groupDueDate = getMinimunTasksDueDate(projectId, groupId);
      const projectDueDate = getProjectDueDate(projectId);
      setGroupDueDate(groupDueDate);
      setProjectDueDate(projectDueDate);

      setTaskDueDate(addDays(new Date(), task.due_date));
      setFirstTime(false);
    }
  }, [
    addUsersInTask,
    firstTime,
    getMinimunTasksDueDate,
    getProjectDueDate,
    groupId,
    initialUsers,
    phaseId,
    projectId,
    setGroupDueDate,
    setProjectDueDate,
    task.due_date,
    task.id,
    users,
  ]);

  return (
    <>
      <Container>
        <Label htmlFor="taskName">
          <p>Task</p>
          <textarea
            id="taskName"
            defaultValue={task.name}
            onBlur={e => {
              updateTaskName(projectId, groupId, task.id, e.target.value);
            }}
          />
        </Label>
        <SeparatorVertical />
        <Label>
          <ResponsibleIds
            users={users}
            setUsers={setUsers}
            placeholder="Selecione aqui um responsável"
          />
        </Label>
        <SeparatorVertical />
        <Deadline>
          Prazo
          <button type="button" onClick={handleOpenCalendar}>
            <p>
              {format(taskDueDate!, 'dd.MMMM.yyyy', {
                locale: ptBR,
              }).toString()}
              <RiEditBoxLine color="var(--gray)" size={15} />
            </p>
          </button>
          {openNewCalendar && (
            <Modal id="calendar-modal" onClick={handleOutsideClick}>
              <Calendar
                date={taskDueDate}
                onChange={setTaskDueDate}
                activeRemove
              />
            </Modal>
          )}
        </Deadline>
        <SeparatorVertical />
        <Label htmlFor="taskDescription">
          Descrição
          <textarea
            id="taskDescription"
            defaultValue={task.desc}
            onBlur={e => {
              updateTaskDescription(
                projectId,
                groupId,
                task.id,
                e.target.value
              );
            }}
          />
        </Label>
        <Buttons>
          <CloseButton
            onClick={() => deleteTaskAndUpdateProjectDueDate()}
            disabled={false}
          />
        </Buttons>
      </Container>
    </>
  );
};

export default Task;
