/* eslint-disable react/require-default-props */
import { useState, useCallback, useEffect } from 'react';
import { RiFoldersLine } from 'react-icons/ri';

import { toast } from 'react-toastify';
import { Button, TextInput } from '../../../../../components/Inputs';
import { Container } from './styles';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import RichText from '../../../../../components/RichText';
import { Modal } from '../../../styles';
import MassEditTaskModal from '../MassEditTaskModal';
import useForm from '../../../../../hooks/useForm';
import { LoadingButton } from '../../LoadingButton';
import { TagsCreate } from '../../TagsCreate';
import { useTasks } from '../../../../../contexts/TasksContext';
import { editProject } from '../../../services/EditProject';
import { deleteProject } from '../../../services/DeleteProject';

interface ProjectEditProps {
  project: Project;
  handleClose: () => void;
  tags?: ITag[];
}

interface useFormHookProps {
  form: { projectName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

function ProjectEdit({ project, handleClose, tags }: ProjectEditProps) {
  const { projects, setProjects } = useTasks();
  const { form, onChange }: useFormHookProps = useForm({
    projectName: project.name,
  });
  const [isDisable, setIsDisable] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tagsList, setTagsList] = useState<ITag[]>([]);

  useEffect(() => {
    setTagsList(() => tags || []);
  }, [tags]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const [content, setContent] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const invertModalVisibility = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'outside-modal') invertModalVisibility();
  };

  const handleEditProject = useCallback(async () => {
    setIsDisable(true);
    setSaving(true);

    const editedProject = await editProject(
      project.id,
      form.projectName,
      content,
      tagsList.map((tag: ITag) => tag.id)
    );

    const projectsUpdate = projects.filter(
      projectItem => projectItem.id !== project.id
    );

    projectsUpdate.splice(editedProject.order - 1, 0, editedProject);
    setProjects(() => projectsUpdate);
    handleClose();

    toast.success('Projeto editado com sucesso!');

    setIsDisable(false);
    setSaving(false);
  }, [
    content,
    form.projectName,
    tagsList,
    handleClose,
    project.id,
    projects,
    setProjects,
  ]);

  const handleDeleteProject = useCallback(async () => {
    setIsDisable(true);
    setDeleting(true);

    await deleteProject(project.id);

    setProjects(() =>
      projects.filter(projectItem => projectItem.id !== project.id)
    );
    toast.success('Projeto excluído com sucesso. Aguarde!');

    handleClose();

    setIsDisable(false);
    setDeleting(false);
  }, [handleClose, project.id, projects, setProjects]);

  return (
    <Container>
      <TaskCard close={handleClose}>
        <header>
          <RiFoldersLine size={21} color="var(--red-primary)" />
          <p>Editar Projeto</p>
        </header>
        <main>
          <Button id="mass-edit-button" onClick={invertModalVisibility}>
            <p>Fazer edição em massa</p>
          </Button>
          <div className="input">
            <p>Nome do projeto</p>
            <TextInput
              name="projectName"
              onChange={handleInputChange}
              width="100%"
              height="2.1rem"
              value={form.projectName}
              placeholder="Escreva o nome do projeto"
              font="400 0.9rem Work Sans"
            />
          </div>

          <div className="input">
            <p>Adicionar uma Tag</p>
            <TagsCreate tagsList={tagsList} setTagsList={setTagsList} />
          </div>

          <div className="input">
            <p>Descrição do projeto</p>
            <RichText
              height={200}
              width={282}
              initialValue={project.desc}
              contentToSave={(contentItem: string) => {
                setContent(contentItem);
              }}
            />
          </div>

          <footer>
            {deleting ? (
              <LoadingButton width="70%" height="2.1rem" />
            ) : (
              <Button
                id="delete-button"
                disabled={isDisable}
                onClick={() => handleDeleteProject()}
              >
                <p>Deletar</p>
              </Button>
            )}
            {saving ? (
              <LoadingButton width="100%" height="2.1rem" />
            ) : (
              <Button
                id="confirm-button"
                disabled={isDisable}
                onClick={() => handleEditProject()}
              >
                <p>Confirmar</p>
              </Button>
            )}
          </footer>
        </main>
      </TaskCard>
      {openModal && (
        <Modal id="outside-modal" onClick={handleOutsideClick}>
          <MassEditTaskModal
            type="project_id"
            id={project.id}
            responsibles={project.users}
            dueDate={project.due_date}
            handleClose={invertModalVisibility}
          />
        </Modal>
      )}
    </Container>
  );
}

export default ProjectEdit;
