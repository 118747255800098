import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  ::-webkit-scrollbar {
    background-color: #1a2024;
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 10px;
  }
  .rdrCalendarWrapper {
    background-color: #1a2024 !important;
  }
  .rdrDayNumber span {
    color: #fff !important;
  }
  .rdrNextPrevButton {
    background: #adadad;
  }
  .rdrDateDisplayWrapper {
    background-color: #1a2024 !important;
  }
  .rdrDateDisplayItem {
    background-color: #1a2024 !important;
    input {
      color: #adadad !important;
    }
  }
  .rdrMonth {
    background-color: #1a2024 !important;
    color: #fff !important;
  }
  .table {
    margin-top: 20px;
    max-height: 200px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      background-color: #1a2024;
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #333;
      border-radius: 10px;
    }
  }
  .popup-content .tooltip-content {
    background: #333 !important;
    border: 0 !important;
    box-shadow: 0 !important;
    padding: 0px !important;
  }
  .popUp-tooltip-content {
    color: #fff;
    background: #0c0e10;
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .yes {
      color: #3e4;
    }
    .no {
      color: #e32438;
    }
  }
  .multi-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    --rmsc-main: #e32438;
    --rmsc-hover: #1a2024;
    --rmsc-selected: #1a2024;
    --rmsc-border: #1a2024;
    --rmsc-gray: #adadad;
    --rmsc-bg: #1a2024;
    --rmsc-p: 8px; /* Spacing */
    --rmsc-radius: 3px; /* Radius */
    --rmsc-h: 33px; /* Height */
    color: #adadad !important;
    transition: 0.3s !important;
    flex: 1;
    min-width: 20vh;
    :hover {
      --rmsc-border: #e32438;
    }
  }
  .panel-content {
    ::-webkit-scrollbar {
      background-color: #1a2024;
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #333;
      border-radius: 10px;
    }
  }
  .go1084040746 {
    cursor: pointer !important;
  }
  .go606532123 {
    input {
      background: #1a2024 !important;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Half = styled.div`
  display: flex;
  flex-direction: column;

  .page-title {
    color: #ea4b4c;
    font-size: 18px;
    margin-bottom: 24px;
  }

  .inputs {
    display: flex;
    flex-direction: row;
  }

  .input {
    margin-right: 20px;
    color: #f6f6f6;

    select,
    input {
      background: #1a2024;
      border-radius: 2px;
      border: 0;
      padding: 7.5px 8px;
      color: #adadad;
      font-size: 16px;
      margin-right: 8px;
      cursor: pointer;
      font-family: Work Sans;
      font-style: normal;
      transition: 0.3s;

      :hover {
        background: #ea4b4c;
      }
    }

    p {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
`;
