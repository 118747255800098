import React, { useCallback } from 'react';

import { CardData } from '../../../../components/PremisesAndRisks/types';

import {
  Container,
  Title,
  Values,
  ValueHolder,
  FinalValueHolder,
} from './styles';

interface Card {
  cardInfo: CardData;
}

const PremiseCard: React.FC<Card> = ({ cardInfo }) => {
  const { name, cause, risk, effect, probability, impact } = cardInfo;

  const handleValueColor = useCallback(() => {
    const finalValue = probability * impact;
    if (finalValue >= 50) {
      return 'var(--red-primary)';
    }
    if (finalValue >= 30) {
      return 'var(--yellow-primary)';
    }
    return 'var(--green-primary)';
  }, [impact, probability]);

  return (
    <Container>
      <header>
        <div className="name">
          <p>{name}</p>
        </div>
      </header>
      <main>
        <div className="texts">
          <div className="textHolder">
            <Title>Causa:</Title>

            <p className="answer">{cause}</p>
          </div>

          <div className="textHolder">
            <Title>Risco:</Title>

            <p className="answer">{risk}</p>
          </div>

          <div className="textHolder">
            <Title>Efeito:</Title>
            <p className="answer">{effect}</p>
          </div>
        </div>

        <Values>
          <ValueHolder>
            <Title>Probabilidade:</Title>
            <p>{probability}</p>
          </ValueHolder>

          <ValueHolder>
            <Title>Impacto:</Title>
            <p>{impact}</p>
          </ValueHolder>

          <FinalValueHolder>
            <Title style={{ color: handleValueColor() }}>Valor:</Title>
            <p style={{ color: handleValueColor() }}>{probability * impact}</p>
          </FinalValueHolder>
        </Values>
      </main>
    </Container>
  );
};

export default PremiseCard;
