import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function restoreGroup(groupId: number): Promise<void> {
  try {
    await apiV2.post(`groups/undelete/${groupId}`);
  } catch {
    toast.error(
      'Problema ao restaurar seu grupo de entrega, atualize a página e tente novamente 😕'
    );
  }
}
