import { isSameDay, isBefore } from 'date-fns';

function isSelected(day: Date, value: Date): boolean {
  return isSameDay(value, day);
}

function isToday(day: Date): boolean {
  return isSameDay(day, new Date());
}

export function beforeToday(day: Date): boolean {
  return isBefore(day, new Date());
}

export default function dayStyles(
  day: Date,
  value: Date | null | undefined,
  blockPast: boolean
): string {
  if (blockPast && beforeToday(day)) return 'before';
  if (value) {
    if (isSelected(day, value)) return 'selected';
  }
  if (isToday(day)) return 'today';
  return '';
}
