import styled from 'styled-components';

export const EditCard = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: 100%;
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 8px;

  header {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    border-bottom: solid 1px ${({ theme }) => theme.backgroundTerciary};
    padding-bottom: 0.5rem;
    padding-top: 0.2rem;
    margin-bottom: 1rem;
  }

  svg {
    margin-left: 0rem !important;
    margin-right: 0.5rem;
  }

  footer {
    margin-top: 0.7rem;

    .midButton {
      margin-bottom: 0.8rem !important;
      width: 100% !important;
      margin-right: 0.5rem;
    }
  }
`;

export const Input = styled.div`
  margin-bottom: 1rem;

  .select-search__input {
    width: 100% !important;
    height: 1.8rem !important;
    font: 400 0.9rem Work Sans !important;
    background: ${({ theme }) => theme.backgroundPrimary};
  }

  .select-search input {
    background: ${({ theme }) => theme.backgroundPrimary};
  }

  .select-search__select {
    height: 7rem;
    overflow-y: auto;
  }
`;
