import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 8px;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .pageTitle {
    margin-top: 1.6rem;
    font-size: 0.8rem;
    letter-spacing: 1.5px;
    margin-bottom: 1.6rem;
  }

  .pageTitle h1 {
    color: ${({ theme }) => theme.text};
  }

  .pageTitle h1:last-child {
    color: var(--red-primary);
  }

  .headerButtons {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .headerButtons button:first-child {
    margin-right: 0.5rem;
  }

  hr {
    border: 1px solid ${({ theme }) => theme.backgroundTerciary};
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none !important;
  }
`;

export const NewDraft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  padding-top: 0.5rem;

  .select-search__input {
    background: ${({ theme }) => theme.backgroundPrimary} !important;
    border-radius: 8px;
    height: 2rem !important;
    margin-top: 0.4rem;
    margin-bottom: 1.2rem;
    font: 400 0.8rem Work Sans;
  }
`;
