import React, { useCallback, useEffect, useState, useRef } from 'react';

import {
  RiSeedlingLine,
  RiEditBoxLine,
  RiStarLine,
  RiCheckLine,
} from 'react-icons/ri';

import AddNewButton from '../../../components/AddNewButton';

import PhaseChecklistItem from '../PhaseChecklistItem';

import { useExportPlaybook } from '../../../../../../../../contexts/ExportPlaybookContext';
import CloseButton from '../../../components/CloseButton';

import {
  Card,
  Title,
  Subtitle,
  Subtasks,
} from '../../../styles/PhaseContainer';

interface PhaseProps {
  phase: IPhase;
  projectId: number;
  groupId: number;
  index: number;
  phasesLength: number;
}

const Phase: React.FC<PhaseProps> = ({
  phase,
  projectId,
  groupId,
  index,
  phasesLength,
}) => {
  const [firstTime, setFirstTime] = useState(true);

  const {
    updatePhaseName,
    deletePhase,
    addNewPhaseChecklistItem,
  } = useExportPlaybook();

  const handleColor = useCallback(cardType => {
    if (cardType === 'RiStarHalfFill') {
      return 'var(--yellow-primary)';
    }
    if (cardType === 'RiCheckLine') {
      return 'var(--cyan-primary)';
    }
    return 'var(--green-primary)';
  }, []);

  const phaseNameInput = useRef<HTMLInputElement>(null);

  function handleFocus() {
    return phaseNameInput.current?.focus();
  }

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    }
  }, [firstTime]);

  return (
    <>
      <Card color={handleColor(phase.icon_name)}>
        <Title>
          {phase.icon_name === 'RiSeedlingLine' && (
            <RiSeedlingLine color="var(--green-primary)" size={19} />
          )}
          {phase.icon_name === 'RiStarHalfFill' && (
            <RiStarLine color="var(--yellow-primary)" size={19} />
          )}
          {phase.icon_name === 'RiCheckLine' && (
            <RiCheckLine color="var(--cyan-primary)" size={19} />
          )}
          <input
            ref={phaseNameInput}
            defaultValue={phase.name}
            onBlur={e => {
              updatePhaseName(projectId, groupId, phase.id, e.target.value);
            }}
          />
          <RiEditBoxLine onClick={handleFocus} color="var(--gray)" size={20} />
          {index !== 0 && index !== phasesLength && (
            <CloseButton
              onClick={() => {
                deletePhase(projectId, groupId, phase.id);
              }}
              disabled={false}
            />
          )}
        </Title>
        <Subtasks>
          <Subtitle>Checklist</Subtitle>
          {phase.phase_checklist_items &&
            phase.phase_checklist_items.map(checklistItem => (
              <PhaseChecklistItem
                key={checklistItem.id}
                checklistItem={checklistItem}
                projectId={projectId}
                groupId={groupId}
                phaseId={phase.id}
              />
            ))}
          <AddNewButton
            onClick={() =>
              addNewPhaseChecklistItem(projectId, groupId, phase.id)
            }
          >
            Adicionar novo checklist
          </AddNewButton>
        </Subtasks>
      </Card>
    </>
  );
};

export default Phase;
