import { FC, useEffect, useState, useMemo } from 'react';
import Avatar from 'react-avatar';

import { Link } from 'react-router-dom';
import * as S from './styles';
import api from '../../../../services/api';

type Brodcast = {
  author_id: number;
  id: number;
  name: string;
};

type Author = {
  name: string;
  last_name: string;
  avatar: {
    url: string;
  };
};

interface BroadcastProps {
  broadcast: Brodcast;
}

const BroadcastCard: FC<BroadcastProps> = ({ broadcast }) => {
  const [author, setAuthor] = useState({} as Author);

  useEffect(() => {
    api.get(`users/${broadcast.author_id}`).then(res => setAuthor(res.data));
  }, [broadcast.author_id]);

  const authorName = useMemo(() => {
    return `${author.name} ${author.last_name} `;
  }, [author.last_name, author.name]);

  return (
    <S.Container>
      <Link to={`/broadcasts/${broadcast.id}`}>
        <strong>{broadcast.name}</strong>
      </Link>

      <Avatar
        name={authorName}
        src={author.avatar && author.avatar.url}
        className="avatar"
        color="var(--gray)"
      />
    </S.Container>
  );
};

export default BroadcastCard;
