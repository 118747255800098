import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type ContextType = {
  show: boolean;
  handleShowTags: (value: boolean) => void;
  allTagsOfClient: ITagFilter[];
  setAllTagsOfClient: Dispatch<SetStateAction<ITagFilter[]>>;
};

const TagsContext = createContext({} as ContextType);

const TagsProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState(false);
  const [allTagsOfClient, setAllTagsOfClient] = useState<ITagFilter[]>([]);

  const handleShowTags = useCallback((value: boolean) => {
    window.localStorage.setItem('showTag', String(value));
    setShow(value);
  }, []);

  useEffect(() => {
    const localShowTags = window.localStorage.getItem('showTag');
    localShowTags && setShow(localShowTags !== 'false');
  }, []);

  return (
    <TagsContext.Provider
      value={{
        show,
        handleShowTags,
        allTagsOfClient,
        setAllTagsOfClient,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

export { TagsContext, TagsProvider };

export const useTags = (): ContextType => {
  return useContext(TagsContext);
};
