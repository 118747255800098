import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type ContractsContextData = {
  contracts: Contract[];
  setContracts: Dispatch<SetStateAction<Contract[]>>;
  actualPage: number;
  setActualPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  setTotalPages: Dispatch<SetStateAction<number>>;
};

export const ContractsContext = createContext({} as ContractsContextData);

type ContractsContextProviderProps = {
  children: ReactNode;
};

export const ContractsProvider: React.FC<ContractsContextProviderProps> = ({
  children,
}: ContractsContextProviderProps) => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [actualPage, setActualPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  return (
    <ContractsContext.Provider
      value={{
        contracts,
        setContracts,
        actualPage,
        totalPages,
        setActualPage,
        setTotalPages,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export const useContracts = (): ContractsContextData => {
  return useContext(ContractsContext);
};
