import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 5rem;

  div {
    display: flex;
  }

  span {
    color: ${({ theme }) => theme.text};
  }

  h4 {
    font-size: 0.6rem;
    font-weight: 400;
    color: var(--red-primary);
  }

  p {
    color: ${({ theme }) => theme.text};
    font-size: 0.9rem;
  }

  #name {
    width: 25%;
    flex-direction: column;
  }

  #edit-button {
    margin: 0 0.5rem;
    color: ${({ theme }) => theme.text};
  }

  #responsible {
    width: 16.7%;
    justify-content: center;
  }

  #period {
    width: 16.5%;
    justify-content: center;
  }
  #progress {
    width: 17%;
    justify-content: center;
  }

  #project {
    select {
      width: 15rem;
      height: 1.5rem;
      border-radius: 0.4rem;
      background-color: ${({ theme }) => theme.backgroundPrimary};
      color: ${({ theme }) => theme.text};
      border: 0.1rem solid ${({ theme }) => theme.text};
      margin-left: 0.2rem;
    }
  }

  @media (max-width: 1360px) {
    #name {
      width: 16%;
    }
    #responsible {
      width: 16%;
    }
    #project {
      select {
        width: 10rem;
      }
    }
    h4 {
      font-size: 0.4rem;
      color: ${({ theme }) => theme.text};
    }
    p {
      font-size: 0.6rem;
      color: ${({ theme }) => theme.text};
    }
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.text};
`;
