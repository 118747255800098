import React from 'react';

import * as S from './styles';

interface ModalButtonProps {
  className: string;
  children?: React.ReactNode;
  width?: string;
  alignLeft?: boolean;
  alignRight?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const ModalButton: React.FC<ModalButtonProps> = ({
  children,
  width = '8rem',
  alignLeft = false,
  alignRight = false,
  className,
  disabled = false,
  type = 'button',
  ...rest
}) => {
  return (
    <S.Button
      {...rest}
      className={className}
      alignLeft={alignLeft}
      alignRight={alignRight}
      width={width}
      type={type}
      disabled={disabled}
    >
      {children}
    </S.Button>
  );
};

export default ModalButton;
