export const deleteContract = (
  contracts: Contract[],
  contractId: number
): Contract[] => {
  const newContracts = contracts;

  const contractsWithDeletedContract = newContracts.filter(
    contract => contract.id !== contractId
  );

  return contractsWithDeletedContract;
};
