import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  overflow-y: auto;
  width: 100%;
  overflow-y: auto;
`;

export const Dash = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  header svg {
    margin-right: 0.5rem;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-gap: 1rem;
  }

  .charts {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 0.8rem;
  }
`;
