import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import {
  RiArrowUpSLine,
  RiArrowDownSLine,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import {
  DragObjectWithType,
  DragSourceOptions,
  useDrag,
  useDrop,
} from 'react-dnd';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { cloneDeep } from 'lodash';
import useProgress from '../../../../../../hooks/useProgress';
import * as S from './styles';
import { Responsibles } from '../../../../components/Responsibles';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { AddNew } from '../../../../components/AddNew';
import { GroupCard } from '../GroupCard';
import { IndividualTaskCard } from '../IndividualTaskCard';
import { ItenTypes } from '../../../../types';
import { GroupLoading, TaskLoading } from '../Loadings';
import { Tag } from '../../../../components/Tag';
import { useTags } from '../../../../../../contexts/TagsContext';
import { Modal } from '../../../../styles';
import NewGroup from '../../../../components/Modals/NewGroup';
import ProjectEdit from '../../../../components/Modals/ProjectEdit';
import NewIndividualTask from '../../../../components/Modals/NewIndividualTask';
import { useTasks } from '../../../../../../contexts/TasksContext';
import { restoreProject } from '../../../../services/RestoreProject';
import { changeIndividualTaskOrder } from '../../../../services/ChangeIndividualTaskOrder';
import { changeGroupOrder } from '../../../../services/ChangeGroupOrder';
import { getGroupsAndIndividualTasks } from '../../../../services/GetGroupsAndIndividualTasks';
import { setGroups } from '../../../../utils/SetGroups';
import { SetIndividualTasks } from '../../../../utils/SetIndividualTasks';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    isDragging?: boolean;
    ref?: DragSourceOptions;
  }
}

type DragItem = {
  index: number;
  project: Project;
  name: string;
  type: string;
};

interface ProjectCardProps {
  project: Project;
  move: (from: number, to: number, project: Project) => void;
  index: number;
}

export function ProjectCard({ project, move, index }: ProjectCardProps) {
  const {
    projects,
    setProjects,
    setProjectsLoading,
    groupsAndTasksLoading,
    setGroupsAndTasksLoading,
    query,
  } = useTasks();
  const ref = useRef<HTMLDivElement>(null);
  const { progress, colors } = useProgress();
  const [openGroupView, setOpenGroupView] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalNewGroup, setOpenModalNewGroup] = useState(false);
  const [openModalNewTask, setOpenModalNewTask] = useState(false);
  const [draggingTimeOut, setDraggingTimeOut] = useState(0);
  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();

  const [lastSelectedProjectId, setLastSelectProjectId] = useState(0);

  useEffect(() => {
    setTags(() => (project.tags ? project.tags : []));
  }, [project]);

  const setGroupsAndTasksInfos = useCallback(async () => {
    if (lastSelectedProjectId !== project.id) {
      setGroupsAndTasksLoading(true);
    }
    setLastSelectProjectId(project.id);

    const globalProjects = cloneDeep(projects);

    const [
      responseGroups,
      responseIndividualTasks,
    ] = await getGroupsAndIndividualTasks(project.id, query);

    globalProjects[index] = {
      ...globalProjects[index],
      groups: responseGroups,
      tasks: responseIndividualTasks,
    };

    setProjects(() => globalProjects);

    setGroupsAndTasksLoading(false);
  }, [
    project.id,
    query,
    index,
    projects,
    setProjects,
    setGroupsAndTasksLoading,
    lastSelectedProjectId,
    setLastSelectProjectId,
  ]);

  const periodProgressPercent = useMemo(
    () => progress(project.created_at, project.due_date),
    [progress, project.created_at, project.due_date]
  );

  const periodProgressTitle = useMemo(
    () =>
      project.due_date
        ? format(new Date(project.due_date), 'dd.MMMM.yyyy', {
            locale: ptBR,
          }).toString()
        : 'Sem prazo',
    [project.due_date]
  );

  const progressBarsColors = useMemo(
    () => colors(project.due_date, project.finished_at),
    [colors, project.due_date, project.finished_at]
  );

  const progressTitle = useMemo(
    () => `${project.progress ? Math.round(project.progress * 100) / 100 : 0}%`,
    [project.progress]
  );

  const handleOpenModal = (type: string): void => {
    if (type === 'new group') {
      setOpenModalNewGroup(!openModalNewGroup);
    } else if (type === 'new task') {
      setOpenModalNewTask(!openModalNewTask);
    } else {
      setOpenModalEdit(!openModalEdit);
    }
  };

  const handleCloseModal = (): void => {
    setOpenModalEdit(false);
    setOpenModalNewGroup(false);
    setOpenModalNewTask(false);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') {
      setOpenModalEdit(false);
      setOpenModalNewGroup(false);
      setOpenModalNewTask(false);
    }
  };

  const handleOpenGroups = (): void => {
    if (!openGroupView && lastSelectedProjectId !== project.id) {
      setGroupsAndTasksInfos();
    }
    setOpenGroupView(!openGroupView);
  };

  const [{ isDragging }, drag] = useDrag({
    item: {
      index,
      project,
      name: 'Project Card',
      type: ItenTypes.PROJECT,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItenTypes.PROJECT,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = (item as DragItem)?.index;
      const hoverIndex = index;
      const projectSelected = (item as DragItem)?.project;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref?.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor?.getClientOffset();

      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      move(dragIndex, hoverIndex, projectSelected);

      const itemIndex = (
        receveidItem: DragObjectWithType,
        hoverIdx: number
      ): number => {
        const itemAny = receveidItem as DragItem;
        itemAny.index = hoverIdx;
        return itemAny.index;
      };

      itemIndex(item, hoverIndex);
    },
  });

  const moveGroupOrTask = (
    selectedItemIndex: number,
    destinyItemIndex: number,
    type: string,
    taskOrGroup: Task | Group
  ): void => {
    if (draggingTimeOut) {
      clearTimeout(draggingTimeOut);
    }

    if (type === 'group') {
      const selectedItemToMove = project.groups[selectedItemIndex];
      const destinyItem = project.groups[destinyItemIndex];

      if (selectedItemToMove) {
        const newGroups = [...project.groups];
        const prevGroups = newGroups.splice(
          destinyItemIndex,
          1,
          selectedItemToMove
        );
        newGroups.splice(selectedItemIndex, 1, prevGroups[0]);
        const newProjects = setGroups(newGroups, projects, project.id);
        setProjects(() => newProjects);
      }

      setDraggingTimeOut(
        window.setTimeout(async () => {
          changeGroupOrder(taskOrGroup.id, destinyItem.order);
        }, 500)
      );
    } else {
      const selectedItemToMove = project.tasks[selectedItemIndex];
      const destinyItem = project.tasks[destinyItemIndex];

      if (selectedItemToMove) {
        const newTasks = [...project.tasks];
        const prevTasks = newTasks.splice(
          destinyItemIndex,
          1,
          selectedItemToMove
        );
        newTasks.splice(selectedItemIndex, 1, prevTasks[0]);
        const projectsWithIndividualTaskUpdated = SetIndividualTasks(
          newTasks,
          projects,
          project.id
        );

        setProjects(() => projectsWithIndividualTaskUpdated);
      }
      setDraggingTimeOut(
        window.setTimeout(async () => {
          changeIndividualTaskOrder(taskOrGroup.id, destinyItem.order!);
        }, 500)
      );
    }
  };

  const handleRestoreProject = useCallback(
    async (projectToRestore: Project) => {
      setProjectsLoading(true);

      await restoreProject(projectToRestore.id);

      const projectIndex = projects.findIndex(
        selectedProject => selectedProject.id === projectToRestore.id
      );

      const newProjects = cloneDeep(projects);

      newProjects[projectIndex] = {
        ...newProjects[projectIndex],
        was_removed: false,
        status: 'aberto',
      };

      setProjects(() => newProjects);
      setProjectsLoading(false);
    },
    [projects, setProjects, setProjectsLoading]
  );

  useProgress();

  drag(drop(ref));
  return (
    <>
      <S.Wrapper ref={ref} isDragging={isDragging}>
        <S.Container className="drag">
          {tags && (
            <S.TagsArea>
              {tags.map((tag: ITag, indexOfTag) => {
                if (indexOfTag >= 10) {
                  return false;
                }
                return (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    type="project_id"
                    typeId={project.id}
                    setTagsList={setTags}
                    showLabel={show}
                    setShowLabel={handleShowTags}
                  />
                );
              })}
            </S.TagsArea>
          )}
          <S.Main>
            <div className="nameAndEdit">
              <button id="name" onClick={handleOpenGroups} type="button">
                <h4>Projeto/Canal/Estratégia/Loop</h4>
                <p>{project.name}</p>
              </button>

              <button
                type="button"
                id="edit-button"
                onClick={() => handleOpenModal('edit')}
              >
                <FaRegEdit size={14} />
              </button>
            </div>

            <div className="grid-item">
              <p> | </p>
              {project.was_removed ? (
                <S.RestoreProject>
                  <button
                    type="button"
                    title={project.name}
                    onClick={() => handleRestoreProject(project)}
                  >
                    <RiDeleteBin2Line size={14} color="var(--red-primary)" />
                    <p id="restore-label">Restaurar Projeto</p>
                  </button>
                </S.RestoreProject>
              ) : (
                <div id="managers">
                  <Responsibles users={project.users} />
                </div>
              )}
              <p> | </p>
            </div>

            <div className="grid-item">
              <div id="period">
                <ProgressDisplay
                  width="95%"
                  height="2rem"
                  borderRadius="0.5rem"
                  percentage={periodProgressPercent}
                  title={periodProgressTitle}
                  colorBar={progressBarsColors.dueDateColor}
                />
              </div>
              <p> | </p>
            </div>

            <div className="grid-item">
              <div id="progress">
                <ProgressDisplay
                  width="95%"
                  height="2rem"
                  borderRadius="0.5rem"
                  title={progressTitle}
                  percentage={project.progress}
                  colorBar={progressBarsColors.progressColor}
                />
              </div>
              <p> | </p>
            </div>

            <div id="add-new">
              <AddNew
                labelText="Adicionar novo grupo"
                onClick={() => handleOpenModal('new group')}
              />
              {openGroupView ? (
                <button type="button" onClick={handleOpenGroups}>
                  <RiArrowUpSLine className="icon" size={20} />
                </button>
              ) : (
                <button type="button" onClick={handleOpenGroups}>
                  <RiArrowDownSLine className="icon" size={20} />
                </button>
              )}
            </div>
          </S.Main>
        </S.Container>
        <S.StickerColor className="drag" />
      </S.Wrapper>
      {openGroupView && (
        <>
          {!groupsAndTasksLoading && (
            <>
              <S.EmptyIndividualTask>
                <AddNew
                  labelText="Adicionar nova Task Individual"
                  onClick={() => handleOpenModal('new task')}
                />
              </S.EmptyIndividualTask>
              {project.tasks && project.tasks.length ? (
                project.tasks!.map((task, TaskIndex) => {
                  return (
                    <div className="content" key={task.id}>
                      <IndividualTaskCard
                        projectId={project.id}
                        task={task}
                        move={moveGroupOrTask}
                        index={TaskIndex}
                        tasks={project.tasks}
                      />
                    </div>
                  );
                })
              ) : (
                <p
                  style={{
                    alignSelf: 'center',
                    margin: '1rem 0',
                    color: 'var(--gray)',
                  }}
                >
                  Não há task individual
                </p>
              )}
            </>
          )}

          {groupsAndTasksLoading && <TaskLoading />}

          {!groupsAndTasksLoading && (
            <>
              {project.groups && project.groups.length ? (
                project.groups.map((group, groupIndex) => {
                  return (
                    <div className="content" key={group.id}>
                      <GroupCard
                        group={group}
                        projectId={project.id}
                        move={moveGroupOrTask}
                        index={groupIndex}
                        groups={project.groups}
                      />
                    </div>
                  );
                })
              ) : (
                <p
                  style={{
                    alignSelf: 'center',
                    margin: '1rem 0',
                    color: 'var(--gray)',
                  }}
                >
                  Não há grupo
                </p>
              )}
            </>
          )}
          {groupsAndTasksLoading && <GroupLoading />}
        </>
      )}
      {openModalNewGroup && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NewGroup projectId={project.id} handleClose={handleCloseModal} />
        </Modal>
      )}
      {openModalNewTask && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NewIndividualTask
            handleClose={handleCloseModal}
            projectId={project.id}
            tasks={project.tasks}
          />
        </Modal>
      )}
      {openModalEdit && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <ProjectEdit
            project={project}
            handleClose={handleCloseModal}
            tags={tags}
          />
        </Modal>
      )}
    </>
  );
}
