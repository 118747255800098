import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: flex-end;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 95%;
  position: absolute;
  z-index: 5;

  .popups {
    display: flex;
    flex-direction: row;
    flex: auto;
    width: 100%;
    padding: 50px;

    form {
      h1 {
        color: ${({ theme }) => theme.text};
      }
    }
  }

  .inputs {
    display: grid;
    grid-template-columns: repeat(4, 2fr) !important;
    grid-gap: 5px;

    .input {
      label {
        padding: 3px;
      }

      p {
        margin-top: 69px !important;
      }
    }
  }

  .description {
    label {
      padding: 5px;
    }
  }

  .cancelarRow {
    float: left !important;
    margin-left: 20px;
    margin-top: 10px;
  }

  .cancelar {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex: 1;
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 2px;
    border: 0;
    padding: 8px 12px;
    font-size: 0.7rem;
    color: var(--gray);
    font-family: Arial, Helvetica, sans-serif;
    transition: 0.3s;

    :hover {
      filter: brightness(70%);
    }
  }

  .history {
    height: 428px;
    overflow-y: scroll;
    padding-right: 10px;

    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.backgroundSecondary};
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 10px;
    }
  }

  footer {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button.button {
      background-color: var(--red-primary);
      color: var(--white);
    }
  }
`;

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  opacity: 0.5;
  z-index: 5;

  left: 0;
  top: 0;

  background-color: ${({ theme }) => theme.backgroundSecondary};
`;
