import React, { useState, useCallback } from 'react';

import { toast } from 'react-toastify';
import PopUp from '../../../../components/PopUp';

import { Form } from './styles';
import api from '../../../../services/api';

function NewSuggestion({ active, title, setSearch, getSuggestions }) {
  const [name, setName] = useState(title);
  const [desc, setDesc] = useState('');

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (desc.length < 120) {
        toast.error('Sua descrição deve ter no minimo 120 caracteres');
        return false;
      }
      await api
        .post('/suggestions', {
          name,
          desc,
        })
        .then(() => {
          active(false);
          setSearch('');
          getSuggestions();
          toast.success('Sua sugestão foi enviada com sucesso');
        })
        .catch(() => {
          toast.error('Algo deu errado, por favor atualize sua página.');
        });
      return true;
    },
    [active, desc, getSuggestions, name, setSearch]
  );

  return (
    <PopUp closePopUp={active} header="Nova Sugestão" width="40%">
      <Form onSubmit={handleSubmit}>
        <label htmlFor="name">
          Qual sua ideia?
          <input
            id="name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
        </label>
        <label htmlFor="desc">
          Descrição
          <textarea
            id="desc"
            type="text"
            value={desc}
            onChange={e => setDesc(e.target.value)}
            placeholder="Descreva sua ideia em no minimo 120 caracteres."
            required
          />
        </label>
        <button type="submit">Confirmar</button>
      </Form>
    </PopUp>
  );
}

export default NewSuggestion;
