import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;

  footer {
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;
  }
  #return-button {
    background: var(--gray);
    width: 70%;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }

  #confirm-button {
    background: var(--green-primary);
    width: 100%;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }
`;
