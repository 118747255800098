import React from 'react';

import { Group, GroupBadge } from './styles';

const formatGroupLabel = data => (
  <Group>
    <span>{data.label}</span>
    <GroupBadge>{data.options.length}</GroupBadge>
  </Group>
);

export default formatGroupLabel;
