import React, { useState } from 'react';

import Responsible from '../../../../../../../../../components/Responsible';

import { Container } from './styles';

interface TaskProps {
  task: Task;
}

const TaskItem: React.FC<TaskProps> = ({ task }) => {
  const [name, setName] = useState(task.name);
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState(`${task.due_date}`);
  const [users, setUsers] = useState([{}]);

  return (
    <>
      <Container>
        <div className="previewItem">
          <p>Task</p>
          <textarea
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="previewResponsible">
          <p>Responsaveis</p>
          <Responsible users={users} setUsers={setUsers} />
        </div>

        <div className="previewItem">
          <p>Prazo</p>
          <input
            value={deadline}
            onChange={e => {
              setDeadline(e.target.value);
            }}
          />
        </div>

        <div className="previewItem">
          <p>Descrição</p>
          <textarea
            value={description}
            onChange={e => {
              setDescription(e.target.value);
            }}
          />
        </div>
      </Container>
    </>
  );
};

export default TaskItem;
