import {
  FC,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import Avatar from 'react-avatar';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import * as S from './styles';
import NewPopup from '../../../../../components/PopUp';
import api from '../../../../../services/api';

type Post = {
  id: number;
  name: string;
  published: string;
  createdAt: Date;
  voteId: number;
  problem: string;
  reasons: string;
  solutions: string;
  youtube: string;
  ticket_id: number;
};

type User = {
  id: number;
  name: string;
  last_name: string;
  active_token: string;
  avatar: {
    url: string;
  };
};

type Reply = {
  id: number;
  user: User;
  createdAt: string;
  desc: string;
};

type Ticket = {
  name: string;
  user: User;
  createdAt: Date;
  desc: string;
  replies: Reply[];
};

interface PostEditProps {
  setActive: Dispatch<SetStateAction<boolean>>;
  userId: number;
  post: Post;
  getPosts: () => Promise<void>;
}

const PostEdit: FC<PostEditProps> = ({ setActive, userId, post, getPosts }) => {
  const [ticket, setTicket] = useState({} as Ticket);
  const [name, setName] = useState(post.name);
  const [problem, setProblem] = useState(post.problem);
  const [reasons, setReasons] = useState(post.reasons);
  const [solutions, setSolutions] = useState(post.solutions);
  const [youtube, setYoutube] = useState(post.youtube);

  const getTicket = useCallback(async () => {
    await api
      .get(`/tickets/${post.ticket_id}`)
      .then(res => setTicket(res.data));
  }, [post.ticket_id]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      await api
        .put('/wiki_posts', {
          id: post.id,
          name,
          problem,
          reasons,
          solutions,
          youtube,
          published: true,
        })
        .then(() => {
          getPosts();
          setActive(false);
          toast.success('Wiki post salvo e publicado com sucesso!');
        });
    },
    [setActive, getPosts, name, post.id, problem, reasons, solutions, youtube]
  );

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  return (
    <NewPopup
      closePopUp={setActive}
      header="Criar ou Editar Post na Wiki"
      width="80%"
    >
      <S.Container>
        {ticket && (
          <S.History>
            <h2>Historico do Chamado</h2>
            <S.Topic>
              <S.Header isUser={ticket.user && ticket.user.id === userId}>
                <Avatar
                  name={`${ticket.user?.name} ${ticket.user?.last_name}`}
                  src={ticket.user?.avatar && ticket.user?.avatar.url}
                  className="avatar"
                  color="var(--gray)"
                  key={ticket.user?.id}
                />
                <h1>{ticket.name}</h1>
              </S.Header>

              <pre>
                {ticket.createdAt &&
                  format(new Date(ticket?.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                <br />
                {ticket.desc}
              </pre>
            </S.Topic>
            <S.Replies>
              {ticket.replies?.map(
                reply =>
                  reply.user && (
                    <S.Reply key={reply.id}>
                      <S.Header isUser={reply.user && reply.user.id === userId}>
                        <Avatar
                          name={`${reply.user.name} ${reply.user.last_name}`}
                          src={reply.user.avatar && reply.user.avatar.url}
                          className="avatar"
                          color="var(--gray)"
                          key={reply.user.id}
                        />
                        {format(
                          new Date(reply.createdAt),
                          'dd/MM/yyyy HH:mm:ss'
                        )}
                      </S.Header>
                      <pre>{reply.desc}</pre>
                    </S.Reply>
                  )
              )}
            </S.Replies>
          </S.History>
        )}
        <S.Vr />
        <S.Form onSubmit={handleSubmit}>
          <h2>Post na Wiki</h2>
          <label htmlFor="title">
            Titulo
            <input
              type="text"
              id="title"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </label>
          <label htmlFor="problem">
            Problema
            <textarea
              id="problem"
              value={problem}
              onChange={e => setProblem(e.target.value)}
              required
            />
          </label>
          <label htmlFor="reasons">
            Causas
            <textarea
              id="reasons"
              value={reasons}
              onChange={e => setReasons(e.target.value)}
              required
            />
          </label>
          <label htmlFor="solutions">
            Soluções
            <textarea
              id="solutions"
              value={solutions}
              onChange={e => setSolutions(e.target.value)}
              required
            />
          </label>
          <label htmlFor="youtube">
            ID de um video no Youtube
            <input
              type="text"
              id="youtube"
              value={youtube}
              onChange={e => setYoutube(e.target.value)}
            />
          </label>
          <button type="submit">Salvar e Publicar</button>
        </S.Form>
      </S.Container>
    </NewPopup>
  );
};

export default PostEdit;
