import { RiArrowGoBackLine } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './styles';
import { TopBar } from './TopBar';
import { Infos } from './Infos';
import { ProjectBoard } from '../components/ProjectBoard';
import { useTasks } from '../../../../../contexts/TasksContext';
import ComponentLoading from '../../../../../components/Loadings/ComponentLoading';

export function ProjectMain() {
  const history = useHistory();
  const { projects } = useTasks();
  const { id } = useParams<TasksParams>();

  return (
    <S.Wrapper id="scroller">
      <S.ReturnButton
        id="return-button"
        type="button"
        onClick={() => history.push(`/tasks/${id}/kanban`)}
      >
        <RiArrowGoBackLine size={20} color="var(--red-primary)" />
        <p>Voltar</p>
      </S.ReturnButton>
      <TopBar />
      {projects.length ? (
        <>
          <Infos />
          <ProjectBoard />
        </>
      ) : (
        <ComponentLoading quantity={6} />
      )}
    </S.Wrapper>
  );
}
