import styled from 'styled-components';

export const Form = styled.form`
  margin: 2rem auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
  height: 87vh;
  overflow-y: auto;

  @media (min-width: 768px) {
    width: 75%;
  }

  .avatar {
    border: 2px solid var(--red-primary);
    border-radius: 6px;
    max-width: 140px;
    max-height: 140px;
    width: auto;
    height: auto;
    margin-right: 20px;
  }
  > label {
    font-weight: 1000;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    div {
      width: 50%;
    }

    #units {
      div {
        input {
          font: 400 0.8rem 'Work Sans';
        }
      }
    }

    div {
      select {
        background: ${({ theme }) => theme.backgroundSecondary};
        font: 400 0.8rem 'Work Sans';
      }
    }
  }

  > button {
    width: 20%;
    margin-top: 20px;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    font-weight: bolder;
    font-size: 1em;
    margin-left: 75%;

    :hover {
      filter: brightness(70%);
    }
  }
`;
