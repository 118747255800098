import React, { Fragment, useState } from 'react';
import { format } from 'date-fns';

import Month from './month';
import * as S from './styles';

import { DashData } from '../../../../types';

interface NewTableChart {
  dashData: DashData;
}

const TableChart: React.FC<NewTableChart> = ({ dashData }) => {
  const [showMonth, setShowMonth] = useState(false);

  return (
    <>
      <S.Container>
        <S.Title>ROI, ROAS</S.Title>
        <table>
          <thead>
            <tr>
              <th> </th>
              <th>Quarter/Mês/Semana</th>
              <th>ROAS</th>
              <th>ROAS LTV</th>
              <th>ROI</th>
              <th>ROI LTV</th>
            </tr>
          </thead>
          <tbody>
            {dashData.finalRoiPresentation.map(quarter => {
              return (
                <Fragment key={quarter.quarterId}>
                  <tr>
                    <td>
                      <button
                        onClick={() => setShowMonth(!showMonth)}
                        type="button"
                      >
                        {showMonth ? '-' : '+'}
                      </button>
                    </td>
                    <td>
                      Quarter{' '}
                      {format(new Date(quarter.quarterStart), 'dd.MM.yyyy')}
                    </td>
                    <td>{quarter.roas && quarter.roas.toFixed(2)}</td>
                    <td>{quarter.roasLtv && quarter.roasLtv.toFixed(2)}</td>
                    <td>{quarter.roi && quarter.roi.toFixed(2)}</td>
                    <td>{quarter.roiLtv && quarter.roiLtv.toFixed(2)}</td>
                  </tr>
                  {showMonth &&
                    quarter.monthly.map(month => {
                      return month.dates.map((date, i) => {
                        return <Month date={date} monthI={i} month={month} />;
                      });
                    })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </S.Container>
    </>
  );
};

export default TableChart;
