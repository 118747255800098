import React from 'react';
import { FaCrosshairs } from 'react-icons/fa';
import { ProgressBar } from '../../../../components/ProgressBar';
import * as S from './styles';

interface OkrKrCardProps {
  name: string;
  percentage: number;
}

export const OkrKrCard: React.FC<OkrKrCardProps> = ({ name, percentage }) => {
  return (
    <S.Wrapper>
      <div id="title">
        <p title={name}>{name}</p>
      </div>
      <S.Label>
        <FaCrosshairs size={14} color="var(--red-primary)" />
        <p>Meta</p>
      </S.Label>
      <ProgressBar percentage={percentage} />
    </S.Wrapper>
  );
};
