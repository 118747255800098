/* eslint-disable jsx-a11y/label-has-associated-control */
import { Wrapper } from './styles';

interface CheckboxProps {
  name: string;
  finished: boolean;
  setFinished: (status: boolean) => void;
  checkFunction?: () => void;
  disabled?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  finished,
  setFinished,
  checkFunction,
  disabled,
}) => {
  const handleCheckbox = (): void => {
    finished ? setFinished(false) : setFinished(true);
  };
  return (
    <Wrapper>
      <label htmlFor={name}>
        <input
          type="checkbox"
          className="checkbox"
          name={name}
          onChange={handleCheckbox}
          checked={finished}
          id={name}
          onClick={checkFunction || handleCheckbox}
          disabled={disabled}
        />
        <label htmlFor={name} className="css-label" />
      </label>
    </Wrapper>
  );
};
