import styled from 'styled-components';

export const BigCard = styled.div`
  background: #1a2024;
  border-radius: 3px;
  padding: 15px;
  margin: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    font-weight: bold;

    :nth-child(2) {
      margin-top: 5px;
      font-size: 20px;
    }
  }
`;

export const BigCardContainer = styled.div`
  display: flex;
  flex: 1;
`;
