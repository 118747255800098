import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  textarea {
    margin-top: 5px;
    background: ${({ theme }) => theme.backgroundSecondary};
    color: ${({ theme }) => theme.text};
    border: 1px solid var(--black);
    border-radius: 6px;
    padding: 10px;
    max-width: 100%;
    min-width: 100%;
    min-height: 40px;
    max-height: 400px;
  }
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  select {
    color: ${({ theme }) => theme.text};
    background: transparent;
    border: none;
    font-weight: bold;
    option {
      color: ${({ theme }) => theme.text};
      :disabled {
        background: var(--gray);
      }
    }
  }
  button {
    margin-left: 8px;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    :hover {
      filter: brightness(90%);
    }
  }
`;
