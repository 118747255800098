import React from 'react';
import { CSSProperties } from 'styled-components';

import { Container } from './styles';

interface LoadingProps {
  style?: CSSProperties;
}

const Loading: React.FC<LoadingProps> = ({ style }) => (
  <Container className="ellipsis" data-testid="loading-container" style={style}>
    <div />
    <div />
    <div />
    <div />
  </Container>
);
export default Loading;
