import { createContext, useState, useCallback, useContext } from 'react';

import api from '../../services/api';
import apiV2 from '../../services/apiV2';

import { AuthProps, AuthContextData, AuthState, User } from './types';

const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProps): JSX.Element {
  const [data, setData] = useState(() => {
    const token = window.localStorage.getItem('@v4company:token');
    const user = window.localStorage.getItem('@v4company:user');

    if (token && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      apiV2.defaults.headers.Authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const updateUser = useCallback(async (user: User) => {
    const token = window.localStorage.getItem('@v4company:token');
    localStorage.setItem('@v4company:user', JSON.stringify(user));

    if (token) setData({ token, user });
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@v4company:token', token);
    localStorage.setItem('@v4company:user', JSON.stringify(user));

    api.defaults.headers.Authorization = `Bearer ${token}`;
    apiV2.defaults.headers.Authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@v4company:token');
    localStorage.removeItem('@v4company:user');

    setData({} as AuthState);
  }, []);

  const checkMasterAdmin = useCallback(userEmail => {
    const masterAdmins = [
      'vinicius.fazenda@v4company.com',
      'victor.ludwig@v4company.com',
      'jefte@v4company.com',
      'amanda.rodrigues@v4company.com',
      'andressa.dias@v4company.com',
      'bruno.ferreira@v4company.com',
      'bianca.melo@v4company.com',
      'eduarda.verruck@v4company.com',
      'lauren.comonel@v4company.com',
      'mateus.guerra@v4company.com',
      'moises.nascimento@v4company.com',
      'quezia.carvalho@v4company.com',
      'luana.santos@v4company.com',
      'caio.scorsoni@v4company.com',
      'eduarda.silva@v4company.com',
      'fernando.hamann@v4company.com',
      'mario.amigo@v4company.com',
      'gerson.xavier@v4company.com',
      'pedro.texeira@v4company.com',
      'deborah.lethycia@v4company.com',
      'mariana.bossle@v4company.com',
      'nayara.souza@v4company.com',
      'daniele.fortunato@v4company.com',
      'fernando.hamann@v4company.com',
      'stefany@v4company.com ',
    ];

    return masterAdmins.includes(userEmail);
  }, []);

  const contextValues = {
    user: data.user,
    signIn,
    signOut,
    checkMasterAdmin,
    updateUser,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth, AuthContext };
