import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  overflow-x: auto;

  button {
    border: none;
    background: none;
    color: ${({ theme }) => theme.text};
  }
`;

export const ReturnButton = styled.button`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  p {
    margin-left: 0.3rem;
    font-size: 1.2rem;
  }
`;
