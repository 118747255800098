import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  color: ${({ theme }) => theme.text};
  header {
    display: flex;
    align-items: center;
  }

  main {
    select {
      width: 15rem;
      height: 1.5rem;
      border-radius: 0.4rem;
      background-color: ${({ theme }) => theme.backgroundTerciary};
      color: ${({ theme }) => theme.text};
      border: none;
      margin-top: 0.5rem;
    }
  }

  #confirm-button {
    background: var(--green-primary);
    width: 100%;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }
`;

export const InputWrapper = styled.div`
  width: 8rem;
  margin: 0 0.8rem;
`;
