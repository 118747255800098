import React, { useMemo } from 'react';

import UserAvatar from '../../../../../components/UserAvatar';
import NewPopUp from '../../../../../components/PopUp';
import { Container, UsersContainer } from './styles';

function WhoSign({ signs, recivers, setActive }) {
  const { seen, unseen } = useMemo(() => {
    const s = signs.map(sign => sign.user_id);
    const us = recivers
      .filter(reciver => !s.includes(reciver.id))
      .map(reciver => reciver.id);
    return { seen: s, unseen: us };
  }, [recivers, signs]);
  return (
    <NewPopUp
      closePopUp={setActive}
      header="Confirmação de leitura"
      width="60%"
    >
      <Container>
        <strong>Leu: </strong>
        <UsersContainer>
          {seen.map(s => (
            <UserAvatar key={s} id={s} />
          ))}
        </UsersContainer>
        <strong>Ainda não Leu: </strong>
        <UsersContainer>
          {unseen.map(us => (
            <UserAvatar key={us} id={us} />
          ))}
        </UsersContainer>
      </Container>
    </NewPopUp>
  );
}

export default WhoSign;
