import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function editPhase(
  phaseId: number,
  phaseName: string,
  phaseDescription: string,
  selectedIcon: string,
  isUserRemove: boolean,
  usersIds: {
    id?: number | undefined;
  }[]
): Promise<Phase> {
  try {
    const response = await apiV2.put(`phases`, {
      id: phaseId,
      name: phaseName,
      desc: phaseDescription,
      icon_name: selectedIcon,
      remove_users: isUserRemove,
      users_ids: usersIds,
    });

    const { phase } = response.data;

    return phase;
  } catch {
    toast.error(
      'Ocorreu algum problema ao editar a fase, atualize a página e tente novamente :S'
    );
    return {} as Phase;
  }
}
