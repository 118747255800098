import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 17rem;
  max-width: 17rem;
  height: 98%;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  padding: 1rem;
  margin-right: 1rem;
`;

export const Title = styled.div`
  width: 100%;
  height: 8%;
  display: flex;
  border-bottom: 0.1rem solid var(--gray);
  color: ${({ theme }) => theme.text};
  align-items: center;
  position: relative;

  p {
    font-size: 0.7rem;
    margin: 0 0.5rem;
    color: ${({ theme }) => theme.text};
  }

  #edit-button {
    margin: 0;
    margin-bottom: 0.8rem;
    background: none;
    border: none;
    position: absolute;
    right: 0;
  }
  @media (max-width: 1300px) {
    height: 12%;
  }
`;

export const TaskName = styled.div`
  width: 75%;
`;

export const RestorePhase = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;

    #restore-label {
      color: var(--gray);
      margin: 0 0.5rem;
    }
  }
`;
export const Content = styled.div`
  width: 100%;
  max-height: 92%;
  min-height: 92%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    height: 0.4rem;
    border-radius: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.text};
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  button#new-task {
    width: 75%;
    height: 3vh;
    border: none;
    border-radius: 0.4rem;
    text-transform: uppercase;
    display: flex;
    background-color: var(--red-primary);
    align-items: center;
    justify-content: center;
    color: var(--white);
  }
`;
