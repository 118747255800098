import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { RiFilterLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { OptionsSelect } from '../../../../../components/Inputs/OptionsSelect';
import { useTags } from '../../../../../contexts/TagsContext';
import { useTasks } from '../../../../../contexts/TasksContext';
import { Checkbox } from '../../Checkbox';
import * as S from './styles';
import { getProjects } from '../../../services/GetProjects';

interface FiltersProps {
  checkDeleted: boolean;
  setCheckDeleted: Dispatch<SetStateAction<boolean>>;
}

export const Filters: React.FC<FiltersProps> = ({
  checkDeleted,
  setCheckDeleted,
}) => {
  const [isActive, setIsActive] = useState('standard');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const {
    allUsersOfProjects,
    projectsLoading,
    setProjectsLoading,
    setProjects,
    query,
    setQuery,
  } = useTasks();
  const { id } = useParams<Params>();

  const [filters, setFilters] = useState<IFilters>({
    deleted: false,
    users: [],
    tags: [],
    sortAsc: '',
  });

  const { show, handleShowTags, allTagsOfClient } = useTags();

  const handleSelectUsers = useCallback(e => {
    setSelectedUsers(e);
  }, []);

  const handleSelectTags = useCallback(e => {
    setSelectedTags(e);
  }, []);

  const setProjectsInfo = useCallback(
    async newQuery => {
      if (typeof setProjectsLoading === 'function') {
        setProjectsLoading(true);
        const responseProjects = await getProjects(Number(id), newQuery);

        setProjects(() => responseProjects);
        setProjectsLoading(false);
      }
    },
    [setProjects, id, setProjectsLoading]
  );

  useEffect(() => {
    let newQuery = '';
    if (filters.deleted) {
      newQuery += '?deleted=true';
    }
    if (filters.users && filters.users.length > 0) {
      newQuery.length > 1
        ? (newQuery += `&users=[${filters.users.toString()}]`)
        : (newQuery += `?users=[${filters.users.toString()}]`);
    }
    if (filters.tags && filters.tags.length > 0) {
      newQuery.length > 1
        ? (newQuery += `&tags=[${filters.tags.toString()}]`)
        : (newQuery += `?tags=[${filters.tags.toString()}]`);
    }
    if (filters.sortAsc) {
      newQuery.length > 1
        ? (newQuery += `&sortAsc=${filters.sortAsc}`)
        : (newQuery += `?sortAsc=${filters.sortAsc}`);
    }

    if (query !== newQuery) {
      setProjectsInfo(newQuery);
    }

    if (typeof setQuery === 'function') {
      setQuery(newQuery);
    }
  }, [
    filters.deleted,
    filters.sortAsc,
    filters.users,
    filters.tags,
    id,
    query,
    setQuery,
    setProjectsInfo,
  ]);

  useEffect(() => {
    if (filters.users !== selectedUsers) {
      setFilters({ ...filters, users: selectedUsers });
    }
    if (filters.tags !== selectedTags) {
      setFilters({ ...filters, tags: selectedTags });
    }
  }, [filters, selectedUsers, selectedTags, setFilters]);

  return (
    <S.Filters>
      <div className="header">
        <RiFilterLine className="icon" size={20} color="var(--red-primary)" />
        <p>Filtrar </p>
      </div>
      <div className="filterOptions">
        <div className="checkboxDelete">
          <Checkbox
            name="deleted"
            finished={checkDeleted}
            setFinished={setCheckDeleted}
            checkFunction={() =>
              setFilters({ ...filters, deleted: !filters.deleted })
            }
            disabled={projectsLoading}
          />
          <p>Mostrar Deletadas?</p>
        </div>

        <div className="checkboxDelete">
          <Checkbox
            name="tags"
            finished={show}
            setFinished={() => handleShowTags(!show)}
            checkFunction={() => handleShowTags(!show)}
          />
          <p>Mostrar Tags?</p>
        </div>

        <OptionsSelect
          id="tags-select"
          onChange={handleSelectTags}
          name="tags"
          value={selectedTags}
          options={allTagsOfClient}
          placeholder="Filtrar por Tags"
          search
          multiple
          printOptions="on-focus"
        />

        <OptionsSelect
          id="users-select"
          onChange={handleSelectUsers}
          name="users"
          value={selectedUsers}
          options={allUsersOfProjects}
          placeholder="Responsável"
          search
          multiple
          printOptions="on-focus"
        />

        <div className="filterButtons">
          <button
            style={{
              backgroundColor:
                isActive === 'standard' ? 'var(--red-primary)' : 'initial',
              border:
                isActive === 'standard' ? 'none' : '0.1rem solid var(--gray)',
              color: isActive === 'standard' ? 'var(--white)' : 'var(--gray)',
            }}
            onClick={() => {
              setIsActive('standard');
              setFilters({ ...filters, sortAsc: '' });
            }}
            type="button"
          >
            Padrão
          </button>
          <button
            style={{
              backgroundColor:
                isActive === 'asc' ? 'var(--red-primary)' : 'initial',
              border: isActive === 'asc' ? 'none' : '0.1rem solid var(--gray)',
              color: isActive === 'asc' ? 'var(--white)' : 'var(--gray)',
            }}
            onClick={() => {
              setIsActive('asc');
              setFilters({ ...filters, sortAsc: '1' });
            }}
            type="button"
          >
            Menor Prazo
          </button>
          <button
            style={{
              backgroundColor:
                isActive === 'desc' ? 'var(--red-primary)' : 'initial',
              border: isActive === 'desc' ? 'none' : '0.1rem solid var(--gray)',
              color: isActive === 'desc' ? 'var(--white)' : 'var(--gray)',
            }}
            onClick={() => {
              setIsActive('desc');
              setFilters({ ...filters, sortAsc: '0' });
            }}
            type="button"
          >
            Maior Prazo
          </button>
        </div>
      </div>
    </S.Filters>
  );
};
