import { FC, Dispatch, SetStateAction } from 'react';

import * as S from './styles';

interface SearchProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  title?: string;
  placeholder: string;
  width?: string;
}

const Search: FC<SearchProps> = ({
  search,
  setSearch,
  title,
  placeholder,
  width = '40%',
}) => {
  return (
    <S.Container>
      {title && <h1>{title}</h1>}
      <S.Input width={width}>
        <input
          type="text"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={placeholder}
        />
      </S.Input>
    </S.Container>
  );
};

export { Search };
