import React, { useCallback, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { FileUploader } from '../../../../../components/FileUploader';
import ModalHeader from '../ModalHeader';
import ModalButton from '../ModalButton';

import * as S from './styles';

interface ModalApproveProps {
  contractId: number;
  handleCloseModal: () => void;
  product: string;
  handleApproveContract: (id: number, file?: File) => void;
}

export const ModalApprove: React.FC<ModalApproveProps> = ({
  contractId,
  handleCloseModal,
  product,
  handleApproveContract,
}) => {
  const [file, setFile] = useState<File>();
  const [custom, setCustom] = useState(
    !!(product !== 'Tração' && product !== 'Avançado' && product !== 'V4 Order')
  );

  const handleUpload = useCallback((upload: File) => {
    setFile(upload);
  }, []);

  return (
    <>
      <S.ApproveModal>
        <ModalHeader
          icon={MdEdit}
          title="Contrato padrão ou customizado?"
          setModal={handleCloseModal}
          disabled={false}
        />
        <S.Row>
          <ModalButton
            className={`red `}
            width="8rem"
            type="button"
            disabled={false}
            onClick={() => {
              setCustom(!custom);
            }}
          >
            Upar modelo customizado
          </ModalButton>
          <ModalButton
            className={`green ${product !== 'Tração' &&
              product !== 'Avançado' &&
              product !== 'Grupo Salus' &&
              product !== 'V4 Order' &&
              'disabled'}`}
            width="8rem"
            type="button"
            disabled={false}
            onClick={() => {
              handleApproveContract(contractId);
            }}
          >
            Aprovar com modelo padão
          </ModalButton>
          {custom && (
            <>
              <FileUploader types={['docx']} handleFile={handleUpload} />
              {file && (
                <ModalButton
                  className={`green ${product !== 'Tração' &&
                    product !== 'Avançado' &&
                    product !== 'Grupo Salus' &&
                    product !== 'V4 Order' &&
                    'disabled'}`}
                  width="80%"
                  type="button"
                  disabled={false}
                  onClick={() => {
                    handleApproveContract(contractId, file);
                  }}
                >
                  Aprovar Customizado
                </ModalButton>
              )}
            </>
          )}
        </S.Row>
      </S.ApproveModal>
    </>
  );
};
