/* eslint-disable react/require-default-props */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FaRegEdit } from 'react-icons/fa';
import {
  RiTimeLine,
  RiLineChartFill,
  RiUser3Line,
  RiDeleteBin2Line,
} from 'react-icons/ri';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import * as S from './styles';

import { Tag } from '../../../../components/Tag';
import { useTags } from '../../../../../../contexts/TagsContext';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { Responsibles } from '../../../../components/Responsibles';
import NormalTask from '../../../../components/Modals/NormalTask';
import { Modal } from '../../../../styles';
import useProgress from '../../../../../../hooks/useProgress';
import { PopupText } from '../../../../components/PopupText';
import { restoreTask } from '../../../../services/RestoreTask';

interface TaskCardProps {
  phase: Phase;
  task: Task;
  index: number;
  tasks: Task[];
  setTasks?: Dispatch<SetStateAction<Task[]>>;
  opacity?: string;
}

export function TaskCard({
  phase,
  task,
  index,
  tasks,
  setTasks,
  opacity,
}: TaskCardProps) {
  const { projectId, groupId } = useParams<TasksParams>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { progress, colors } = useProgress();
  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();

  useEffect(() => {
    setTags(() => (task.tags ? task.tags : []));
  }, [task]);

  const periodProgressPercent = useMemo(
    () => progress(task.created_at, task.due_date),
    [progress, task.created_at, task.due_date]
  );

  const progressBarsColors = useMemo(
    () => colors(task.due_date, task.finished_at),
    [colors, task.due_date, task.finished_at]
  );

  const progressTitle = useMemo(
    () => `${task.progress ? Math.round(task.progress * 100) / 100 : 0}%`,
    [task.progress]
  );

  const handleSetModal = (): void => {
    setIsOpenModal(!isOpenModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') handleSetModal();
  };

  const handleRestoreTask = useCallback(
    async (taskToRestore: Task) => {
      await restoreTask(taskToRestore.id).then(() => {
        const taskUndeleted = {
          ...taskToRestore,
          was_removed: false,
        };

        const tasksUpdate = tasks!.filter(
          taskItem => taskItem.id !== taskToRestore.id
        );

        tasksUpdate.splice(taskToRestore.order! - 1, 0, taskUndeleted);
        setTasks!([...tasksUpdate]);
        toast.success('Task restaurada com sucesso');
      });
    },
    [setTasks, tasks]
  );

  return (
    <>
      <Draggable draggableId={`${task.id}`} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <S.Wrapper isDragging={snapshot.isDragging} style={{ opacity }}>
              <S.Container tabIndex={0}>
                <S.TagsArea>
                  {tags &&
                    tags.map((tag: ITag, i) => {
                      if (i >= 10) {
                        return false;
                      }
                      return (
                        <Tag
                          key={tag.id}
                          tag={tag}
                          type="task_id"
                          typeId={task.id}
                          setTagsList={setTags}
                          showLabel={show}
                          setShowLabel={handleShowTags}
                        />
                      );
                    })}
                </S.TagsArea>
                <S.Title role="button" onClick={handleSetModal}>
                  <PopupText popUpText={task.name}>
                    {task.name.length > 27
                      ? `${task.name.substring(0, 20)}...`
                      : task.name}
                  </PopupText>
                  <button type="button" onClick={handleSetModal}>
                    <FaRegEdit size={14} id="edit-button" color="var(--gray)" />
                  </button>
                </S.Title>
                <S.Content role="button" onClick={handleSetModal}>
                  <div id="period">
                    <RiTimeLine
                      className="icon"
                      size={14}
                      color="var(--red-primary)"
                    />

                    <ProgressDisplay
                      width="85%"
                      height="1rem"
                      borderRadius="0.3rem"
                      percentage={periodProgressPercent}
                      title={
                        task.due_date
                          ? format(new Date(task.due_date), 'dd.MMMM.yyyy', {
                              locale: ptBR,
                            }).toString()
                          : 'Sem prazo'
                      }
                      colorBar={progressBarsColors.dueDateColor}
                    />
                  </div>
                  <div id="progress">
                    <RiLineChartFill
                      className="icon"
                      size={14}
                      color="var(--red-primary)"
                    />

                    <ProgressDisplay
                      width="85%"
                      height="1rem"
                      borderRadius="0.3rem"
                      title={progressTitle}
                      percentage={task.progress}
                      colorBar={progressBarsColors.progressColor}
                    />
                  </div>
                </S.Content>
                <div id="responsible">
                  {task.was_removed ? (
                    <S.RestoreTask>
                      <button
                        type="button"
                        title={task.name}
                        onClick={() => handleRestoreTask(task)}
                      >
                        <RiDeleteBin2Line
                          size={14}
                          color="var(--red-primary)"
                        />
                        <p id="restore-label">Restaurar Task</p>
                      </button>
                    </S.RestoreTask>
                  ) : (
                    <>
                      <RiUser3Line
                        className="icon"
                        size={14}
                        color="var(--red-primary)"
                      />
                      <Responsibles users={task.users} size="1.4rem" />
                    </>
                  )}
                </div>
              </S.Container>
            </S.Wrapper>
          </div>
        )}
      </Draggable>
      {isOpenModal && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NormalTask
            handleClose={handleSetModal}
            task={task}
            phase={phase}
            tasks={tasks}
            tags={tags}
            projectId={+projectId}
            groupId={+groupId}
          />
        </Modal>
      )}
    </>
  );
}
