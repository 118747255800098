import { QuartersByClient } from '../pages/QuarterSelect/types';
import { history } from '../../../services/history';

interface Props {
  quarters: QuartersByClient;
  redirect?: boolean;
  id: string;
}

export function verifyIsLastQuarter({ quarters, redirect = false, id }: Props) {
  if (quarters.length) {
    if (new Date(quarters[0].quarter.next_quarter) < new Date()) {
      history.push(`/checkin/vselect/${id}`);
      return;
    }

    if (redirect) history.push(`/checkin/quarter/${quarters[0].quarter.id}`);
  }

  if (!quarters.length) history.push(`/checkin/vselect/${id}`);
}
