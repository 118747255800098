import styled from 'styled-components';

interface Props {
  showEmail: boolean;
}

export const Container = styled.div<Props>`
  height: 100%;

  .children {
    align-self: center;
  }

  .loadingDiv {
    position: relative;
    width: 20rem;
    height: 20rem;
    border-radius: 8px;
    align-self: center;
  }

  .loadingBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      color: ${({ theme }) => theme.text};
    }
  }

  header {
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 0.1rem;
  }

  header h1:last-child {
    color: var(--red-primary);
  }

  .finalSlide {
    display: flex;
    flex-direction: column;
    max-width: 40rem;
  }

  .finalSlide div {
    display: flex;
    flex-direction: row;
  }

  .finalSlide div > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: ${({ showEmail }) => (showEmail ? '1rem' : '0rem')};
  }

  .finalSlide main .input div {
    margin-right: 0rem !important;
  }

  .finalSlide button {
    margin-top: 1rem;
  }

  .showEmails {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--red-primary);
    padding: 0 0.4rem;
    border-radius: 8px;
    margin-left: 1rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    cursor: pointer;

    &::before {
      content: '';
      border-style: solid;
      border-color: transparent var(--red-primary) transparent transparent;
      border-width: 8px 8px 8px 0;
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .showEmails p {
    font-size: 0.8rem;
    transform: rotate(180deg);
  }

  form {
    border-left: solid 1px var(--gray);
    padding-left: 1rem;
  }
`;
