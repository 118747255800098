import React, { useState } from 'react';
import { parse } from 'date-fns';
import * as S from './styles';

export function DistractCalculator() {
  const [valorMensalidade, setValorMensalidade] = useState<number>(0);
  const [totalMensalidades, setTotalMensalidades] = useState<number>(0);
  const [mensalidadesPagas, setMensalidadesPagas] = useState<number>(0);
  const [dataInicio, setDataInicio] = useState<string>('');
  const [dataTermino, setDataTermino] = useState<string>('');
  const [result, setResult] = useState('');

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const dataInicioObj = parse(dataInicio, 'dd/MM/yyyy', new Date());
    const dataTerminoObj = parse(dataTermino, 'dd/MM/yyyy', new Date());
    let diasTrabalhados = Math.floor(
      (dataTerminoObj.getTime() - dataInicioObj.getTime()) /
        (1000 * 60 * 60 * 24)
    );
    diasTrabalhados -= mensalidadesPagas * 30;

    if (diasTrabalhados <= 0) {
      setResult('Não há valor a ser pago como rescisão.');
    } else {
      const valorDiario = valorMensalidade / 30;
      let valorRescisao = diasTrabalhados * valorDiario;
      let multa = 0;
      const mensalidadesRest = totalMensalidades - mensalidadesPagas - 1;
      if (mensalidadesRest > 0) {
        multa = mensalidadesRest * valorMensalidade * 0.1;
      }
      if (multa > 0) {
        valorRescisao += multa;
      }
      setResult(
        `O valor da rescisão contratual é de R$ ${valorRescisao.toFixed(2)}.`
      );
      setResult(`Foram trabalhados ${diasTrabalhados} dias.`);
    }
  }

  return (
    <S.Container>
      <form onSubmit={handleSubmit}>
        Qual o valor da mensalidade?
        <input
          type="number"
          value={valorMensalidade}
          onChange={event =>
            setValorMensalidade(parseFloat(event.target.value))
          }
        />
        Qual a quantidade total de mensalidades do contrato?
        <input
          type="number"
          value={totalMensalidades}
          onChange={event => setTotalMensalidades(Number(event.target.value))}
        />
        Quantas mensalidades já foram pagas?
        <input
          type="number"
          value={mensalidadesPagas}
          onChange={event => setMensalidadesPagas(Number(event.target.value))}
        />
        Qual a data de início do projeto (formato: dd/mm/aaaa)?
        <input
          type="text"
          value={dataInicio}
          onChange={event => setDataInicio(event.target.value)}
        />
        Qual a data do término do serviço prestado (formato: dd/mm/aaaa)?
        <input
          type="text"
          value={dataTermino}
          onChange={event => setDataTermino(event.target.value)}
        />
        <button type="submit">Calcular</button>
      </form>

      <h1>{result}</h1>
    </S.Container>
  );
}
