import { toast } from 'react-toastify';
import api from '../../../services/api';

interface PayloadProps {
  name?: string;
  last_name?: string;
  email?: string;
  avatar_id?: string;
  oldPassword?: string;
}

interface userResponse {
  avatar_id: number;
  email: string;
  id: number;
  last_name: string;
  name: string;
  unit: { name: string; stakeholder_name: string };
  unit_id: number;
  avatar: {
    name: string;
    path: string;
    url: string;
  };
}

export async function updateProfileRequest(payload: PayloadProps) {
  try {
    const { name, last_name, email, avatar_id, ...rest } = payload;

    const profile = {
      name,
      last_name,
      email,
      avatar_id,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = await api.put<userResponse>('users', profile);

    toast.success('Perfil atualizado com sucesso!', {
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    return response;
  } catch (err) {
    toast.error('Erro ao autalizar perfil confira seus dados', {
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  return undefined;
}
