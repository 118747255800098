import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0.5rem 0;

  .content {
    display: flex;
    flex-direction: column;
  }
  .width-fixer {
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 880px) {
    max-width: 1200px;
    overflow-x: scroll;

    .width-fixer {
      width: 1200px;
    }
  }
`;

export const Content = styled.div`
  max-height: 100vh;
  padding-right: 1rem;
  padding-bottom: 30rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 0.4rem;
    border-radius: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.text};
  }
`;
