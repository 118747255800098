import React, { useMemo } from 'react';
import { RiFileListLine, RiDeleteBinLine, RiStarLine } from 'react-icons/ri';

import MetaOkr from '../MetaOkr';
import KrsCard from '../KrsCard';

import * as S from '../styles';
import { UserId, Okrs, CardInfo } from '../types';

interface Props {
  okr: Okrs;
  presentation: boolean;
  i: number;
  predefined?: boolean;
  handleOkrUpdate: (okrId: number, newKrs: CardInfo[]) => void;
  handleOkrDelete: (id: number) => void;
  handleMainOkrEdit: (id: number, name: string) => void;
}

const OkrCard: React.FC<Props> = ({
  okr,
  presentation,
  i,
  handleOkrUpdate,
  handleOkrDelete,
  handleMainOkrEdit,
  predefined = false,
}) => {
  const okrInfos = useMemo(() => {
    const okrUsers: UserId[] = [];
    let progress = 0;

    if (okr.krs) {
      okr.krs.forEach(kr => {
        if (kr.is_the_lesser_the_better) {
          progress += (kr.goal / kr.value) * 100;
        } else {
          progress += (kr.value / kr.goal) * 100;
        }

        kr.users_id.forEach(user => {
          const contains = okrUsers.some(elem => {
            return JSON.stringify(user) === JSON.stringify(elem);
          });
          if (!contains) {
            okrUsers.push(user);
          }
        });
      });
    }

    return { progress: progress / okr.krs.length, okrUsers };
  }, [okr.krs]);

  return (
    <div key={okr.id} className="okr">
      {predefined && (
        <p className="predefinedOkr">
          <RiStarLine size={12} color="var(--green-primary)" /> Okr Predefinida
        </p>
      )}

      <S.Header>
        <div className="nameAndIcon">
          {predefined && (
            <RiFileListLine size={23} color="var(--green-primary)" />
          )}

          <p>{okr.name}</p>
        </div>

        {!predefined && (
          <RiDeleteBinLine
            className="trashBin"
            size={16}
            color="var(--red-primary)"
            onClick={() => handleOkrDelete(okr.id)}
          />
        )}
      </S.Header>

      <MetaOkr
        data-testid="meta"
        editFunction={handleMainOkrEdit}
        color={presentation ? i + 1 : 0}
        predefined={predefined}
        cardInfo={{
          name: okr.name,
          users_id: okrInfos.okrUsers,
          progress: okrInfos.progress,
          is_the_lesser_the_better: false,
          id: okr.id,
          value: 0,
          goal: 0,
        }}
      />

      <KrsCard
        predefined={predefined}
        handleOkrUpdate={handleOkrUpdate}
        presentation={presentation}
        okrId={okr.id}
        krs={okr.krs}
      />
    </div>
  );
};

export default OkrCard;
