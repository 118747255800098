import React, { useCallback, useEffect, useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';

import Responsible from '../../../../../../components/Responsible';

import { Container } from './styles';

import GroupItem from './Group';
import apiV2 from '../../../../../../services/apiV2';

const ProjectItem: React.FC<{ project: Project }> = ({ project }) => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [name, setName] = useState(project.name);
  const [description, setDescription] = useState(project.desc);
  const [deadline, setDeadline] = useState('29.Setembro.2020');
  const [users, setUsers] = useState([{}]);
  const [showGroups, setShowGroups] = useState(false);

  const getGroups = useCallback(async () => {
    await apiV2.get(`groups/${project.id}`).then(res => {
      setGroups(res.data.groups);
    });
  }, [project.id]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  return (
    <>
      <Container>
        <div className="previewItem">
          <p>Projeto</p>
          <textarea
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </div>

        <div className="previewResponsible">
          <p>Responsaveis</p>
          <Responsible users={users} setUsers={setUsers} />
        </div>

        <div className="previewItem">
          <p>Prazo</p>
          <input
            value={deadline}
            onChange={e => {
              setDeadline(e.target.value);
            }}
          />
        </div>

        <div className="previewItem">
          <p>Descrição</p>
          <textarea
            value={description}
            onChange={e => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <RiArrowDropDownLine
          style={{ transform: showGroups ? 'rotate(180deg)' : 'rotate(0)' }}
          onClick={() => setShowGroups(!showGroups)}
          size={21}
        />
      </Container>
      {showGroups &&
        groups.map(group => {
          return <GroupItem group={group} />;
        })}
    </>
  );
};

export default ProjectItem;
