import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .victoriesList {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 0.4rem;
    margin-top: 4rem;
  }

  .victorie {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RedBall = styled.div`
  height: 0.4rem;
  width: 0.4rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background: var(--red-primary);
`;
