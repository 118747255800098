import React, { useCallback } from 'react';

import { RiCompassLine, RiUserLine } from 'react-icons/ri';

import EditKr from './EditMetaOkr';
import UserAvatar from '../../../../../components/UserAvatar';
import ProgressBar from '../../../../../components/Progressbar';

import { CardInfo } from '../types';

import * as S from './styles';

interface CardEditing {
  cardInfo: CardInfo;
  editFunction: (id: number, name: string) => void;
  color?: number;
  predefined?: boolean;
}

const MetaOkr: React.FC<CardEditing> = ({
  cardInfo,
  editFunction,
  color = 0,
  predefined = false,
}) => {
  const handleProgress = useCallback(() => {
    const percentage = cardInfo.progress ? cardInfo.progress.toFixed(0) : 0;

    if (Number(percentage) < 0) return 0;
    if (Number(percentage) > 100) return 100;
    if (Number.isNaN(Number(percentage))) return 0;
    return percentage;
  }, [cardInfo.progress]);

  const colors = [
    'var(--white)',
    'var(--red-primary)',
    'var(--yellow-primary)',
    'var(--cyan-primary)',
    'var(--green-primary)',
    'var(--magenta-primary)',
    'var(--orange-secondary)',
  ];

  const handleProgressColor = useCallback(progress => {
    if (progress >= 100) {
      return 'var(--green-primary)';
    }

    if (progress >= 80) {
      return 'var(--cyan-primary)';
    }

    return 'var(--yellow-primary)';
  }, []);

  return (
    <S.Container>
      <div className="name">
        <p>{cardInfo.name}</p>

        <div className="editAndColor">
          {!predefined && (
            <EditKr
              actualTitle={cardInfo.name}
              editCard={editFunction}
              cardId={cardInfo.id}
            />
          )}

          {color !== 0 && <S.ChartSquare color={colors[color]} />}
        </div>
      </div>

      <S.MetaProgress>
        <div className="metaName">
          <RiCompassLine size={20} color="var(--red-primary)" />
          <p>Meta</p>
        </div>

        <ProgressBar
          percentage={`${handleProgress()}`}
          color={handleProgressColor(handleProgress())}
          title={`${handleProgress()}%`}
        />
      </S.MetaProgress>

      <S.Users>
        <RiUserLine size={19} color="var(--red-primary)" />
        <p>Time</p>
        <div className="responsibles">
          {cardInfo.users_id.map(user => {
            return <UserAvatar key={user.id} id={user.id} size="1.5rem" />;
          })}
        </div>
      </S.Users>
    </S.Container>
  );
};

export default MetaOkr;
