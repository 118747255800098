import SelectSearch from 'react-select-search';
import * as S from './styles';

function OptionsSelect({ options, ...rest }) {
  return (
    <S.Container>
      <SelectSearch {...rest} printOptions="on-focus" options={options || []} />
    </S.Container>
  );
}

export { OptionsSelect };
