import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;

  .formBox {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;

    .radios {
      color: var(--gray);
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;

      label {
        font-size: 16px;
        padding-right: 30px;
        padding-left: 5px;
      }
    }

    .formInput {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 5px;
      }
      input {
        margin-bottom: 10px;
        color: var(--gray);
      }

      textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        flex: 1;
        background: ${({ theme }) => theme.backgroundSecondary};
        border-radius: 2px;
        border: 0;
        padding: 8px 12px;
        font-size: 16px;
        color: var(--gray);
        font-family: Arial, Helvetica, sans-serif;
      }
    }

    footer {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 0px;

      display: flex;
      justify-content: space-between;
      width: 100%;

      button {
        color: var(--white);
        background-color: var(--gray);
        width: 4rem;
        flex: unset;
      }
    }
  }
`;

export const Radio = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  transition: 0.3s;
  cursor: pointer;

  :hover {
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: var(--red-primary);
    border: 3px solid ${({ theme }) => theme.backgroundSecondary};
  }
`;

export const SelectedRadio = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--red-primary);
  border: 3px solid ${({ theme }) => theme.backgroundSecondary};
`;
