import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function finishTask(
  taskId: number,
  finishedAt: Date | null,
  project_phase_name: 'DONE' | 'DOING'
): Promise<Task> {
  try {
    const response = await apiV2.put(`tasks`, {
      id: taskId,
      finished_at: finishedAt,
      project_phase_name,
    });

    const taskFinished = response.data;

    return taskFinished;
  } catch {
    toast.error(
      'Ocorreu algum problema, atualize a página e tente novamente 😕'
    );
    return {} as Task;
  }
}
