import React from 'react';

import { Container } from './styles';

export default function CohortChart() {
  // const [apiRes, setApiRes] = useState([]);
  // const backgroundColors = useMemo(() => {
  //   return [
  //     'rgb(56, 88, 152)',
  //     '#E1306C',
  //     '#FCAF45',
  //     '#e5e5e5',
  //     '#F12424',
  //     '#f6f6f6',
  //   ];
  // }, []);

  // const dataSets = useMemo(() => {
  //   const arrayOfValues = [];
  //   apiRes.map(item => {
  //     arrayOfValues.push({
  //       label: item.name,
  //       backgroundColor:
  //         backgroundColors[Math.floor(Math.random() * backgroundColors.length)],
  //       data: item.value,
  //       opacity: 0.5,
  //     });

  //     return null;
  //   });

  //   return arrayOfValues;
  // }, [apiRes, backgroundColors]);

  // const dataLabels = useMemo(() => {
  //   const arrayOfValues = [];
  //   apiRes.map(item => {
  //     arrayOfValues.push(item.name);
  //     return null;
  //   });

  //   return arrayOfValues;
  // }, [apiRes]);

  // useEffect(() => {
  //   const initialDate = format(
  //     subMonths(new Date(dates[0].startDate), updates > 0 ? 0 : 3),
  //     'yyyy-MM-dd'
  //   );
  //   const finalDate = format(new Date(dates[0].endDate), 'yyyy-MM-dd');

  //   api
  //     .get('billing/dashboard/cohort', {
  //       params: {
  //         month: initialDate,
  //         finalMonth: finalDate,
  //       },
  //     })
  //     .then(res => {
  //       setApiRes(res.data);
  //     });
  // }, [dates, updates]);

  // const data = useMemo(() => {
  //   return {
  //     backgroundColor: backgroundColors,
  //     labels: dataLabels,
  //     datasets: dataSets,
  //   };
  // }, [backgroundColors, dataLabels, dataSets]);

  return (
    <Container style={{ padding: '20px' }}>
      <h1 style={{ fontSize: '20px', color: '#f6f6f6' }}>Cohort</h1>

      {/* <Line
        data={data}
        options={{
          legend: {
            display: false,
          },
          bezierCurve: true,
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              fill: true,
              tension: 0,
            },
          },
          cutoutPercentage: 73,
          scales: {
            xAxes: [
              {
                stacked: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            ],
          },
          plugins: {
            labels: {
              render: 'label',
              fontSize: 0,
            },
          },
        }}
      /> */}
    </Container>
  );
}
