import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  flex: 0.5;
  left: 5%;
  bottom: 0;
  z-index: 3;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.backgroundSecondary};
  padding: 0.8rem;
  box-shadow: 0 4px 8px 0 rgba(1, 1, 1, 0.4), 0 6px 20px 0 rgba(1, 1, 1, 0.6);

  user-select: none;
  -moz-user-select: none;
  -webkit--webkit-touch-callout: none;

  header {
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
  }

  header h1:last-child {
    color: var(--red-primary);
  }

  svg {
    cursor: pointer;
    ${({ theme }) => theme.text}
  }

  .up-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .up-button p {
    margin-right: 0.8rem;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

export const Card = styled.form`
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;
  margin-top: 1rem;

  .input {
    margin-bottom: 1rem;
  }

  .input textarea {
    padding: 1rem;
    width: 100%;
    height: 5rem;
    border-radius: 8px;
    margin-top: 0.5rem;
    background: ${({ theme }) => theme.backgroundPrimary};
    font: 400 0.8rem Work Sans;
    border: 0;
    color: ${({ theme }) => theme.text};
  }
`;
