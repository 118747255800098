import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;

  header {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
  }

  header p {
    font-size: 0.9rem;
    margin-left: 0.3rem;
  }

  .krs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 980px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 0.5rem;
    }
  }

  button {
    margin-bottom: 0rem !important;
    margin-right: 0rem !important;
    float: right;
  }
  .popup-content {
    background: ${({ theme }) => theme.backgroundSecondary};
  }
`;
