import React, { useState, useEffect, useCallback } from 'react';

import { RiPencilLine, RiDeleteBinLine } from 'react-icons/ri';

import Editing from './editing';
import {
  Container,
  Title,
  Values,
  ValueHolder,
  FinalValueHolder,
} from './styles';

import { CardData } from '../types';

interface Card {
  cardData: CardData;
  handleCardDelete: (id: number) => void;
  handleCardEdit: (id: string, newCard: CardData) => void;
}

const PremiseCard: React.FC<Card> = ({
  cardData,
  handleCardDelete,
  handleCardEdit,
}) => {
  const { name, impact, cause, probability, risk, effect } = cardData;
  const [editing, setEditing] = useState(false);

  const handleValueColor = useCallback(() => {
    const finalValue = probability * impact;
    if (finalValue >= 50) {
      return 'var(--red-primary)';
    }
    if (finalValue >= 30) {
      return 'var(--yellow-primary)';
    }
    return 'var(--green-primary)';
  }, [impact, probability]);

  useEffect(() => {
    setEditing(false);
  }, [cardData]);

  return (
    <Container>
      {!editing ? (
        <>
          <header>
            <div className="name">
              <p>{name}</p>
              <RiPencilLine
                onClick={() => setEditing(true)}
                size={16}
                color="var(--gray)"
              />
            </div>
            <RiDeleteBinLine
              size={16}
              color="var(--red-primary)"
              onClick={() => {
                handleCardDelete(cardData.id);
              }}
            />
          </header>
          <main>
            <div className="texts">
              <div className="textHolder">
                <Title>Causa:</Title>
                <p className="answer">{cause}</p>
              </div>

              <div className="textHolder">
                <Title>Risco:</Title>
                <p className="answer">{risk}</p>
              </div>

              <div className="textHolder">
                <Title>Efeito:</Title>
                <p className="answer">{effect}</p>
              </div>
            </div>

            <Values>
              <ValueHolder>
                <Title>Probabilidade:</Title>
                <p>{probability}</p>
              </ValueHolder>

              <ValueHolder>
                <Title>Impacto:</Title>
                <p>{impact}</p>
              </ValueHolder>

              <FinalValueHolder>
                <Title style={{ color: handleValueColor() }}>Valor:</Title>
                <p style={{ color: handleValueColor() }}>
                  {probability * impact}
                </p>
              </FinalValueHolder>
            </Values>
          </main>
        </>
      ) : (
        <Editing cardData={cardData} handleCardEdit={handleCardEdit} />
      )}
    </Container>
  );
};

export default PremiseCard;
