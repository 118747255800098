import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;

  #confirm-button {
    background: var(--green-primary);
    width: 50%;
    height: 2.1rem;
    font: bold 0.9rem Work Sans;
    color: var(--white);
  }
`;
