import React from 'react';
import * as S from './styles';

interface ProgressBarProps {
  percentage: number;
  quarterPercent?: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  quarterPercent,
}) => {
  return (
    <S.ProgressBar>
      <div
        className="progress"
        style={{
          width: `${percentage}%`,
          background: `var(--green-primary)`,
        }}
      >
        <h5>{`${percentage}%`}</h5>
      </div>
      {quarterPercent && (
        <div
          className="line-mark"
          title="Tempo quarter"
          style={{
            width: `${quarterPercent}%`,
          }}
        />
      )}
    </S.ProgressBar>
  );
};
