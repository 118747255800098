import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { RiEditBoxLine } from 'react-icons/ri';

import { Calendar } from '../../../../../../../../components/Calendar';
import { useExportPlaybook } from '../../../../../../../../contexts/ExportPlaybookContext';
import { Modal } from '../../../../../../styles';

import CloseButton from '../../../components/CloseButton';

import {
  Container,
  Label,
  Deadline,
  SeparatorVertical,
  Buttons,
} from '../../../styles/TaskContainer';

interface TaskProps {
  task: ITask;
  projectId: number;
  groupId: number;
  phaseId: number;
  setGroupDueDate: Dispatch<SetStateAction<Date>>;
  setProjectDueDate: Dispatch<SetStateAction<Date>>;
}

const Task: React.FC<TaskProps> = ({
  task,
  projectId,
  groupId,
  setGroupDueDate,
  setProjectDueDate,
}) => {
  const [firstTime, setFirstTime] = useState(true);

  const [taskDueDate, setTaskDueDate] = useState<Date | undefined>(
    new Date(task.due_date)
  );
  const [backupDueDate, setBackupDueDate] = useState<Date>(new Date());

  const [openNewCalendar, setOpenNewCalendar] = useState(false);

  const handleOpenCalendar = (): void => {
    setOpenNewCalendar(!openNewCalendar);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'calendar-modal') handleOpenCalendar();
  };

  const {
    updateTaskName,
    updateTaskDescription,
    deleteTask,
    updateDueDateInTask,
    getProjectDueDate,
    getMinimunTasksDueDate,
  } = useExportPlaybook();

  const deleteTaskAndUpdateProjectDueDate = () => {
    deleteTask(projectId, groupId, task.id);
    const projectTimestamp = getProjectDueDate(projectId);
    const minimunTasksTimestamp = getMinimunTasksDueDate(projectId, groupId);
    const minimunProjectDueDate = new Date(projectTimestamp);
    const minimunTasksDueDate = new Date(minimunTasksTimestamp);

    setGroupDueDate(minimunTasksDueDate);
    setProjectDueDate(minimunProjectDueDate);
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);

      const projectTimestamp = getProjectDueDate(projectId);
      const minimunTasksTimestamp = getMinimunTasksDueDate(projectId, groupId);
      const minimunProjectDueDate = new Date(projectTimestamp);
      const minimunTasksDueDate = new Date(minimunTasksTimestamp);

      setGroupDueDate(minimunTasksDueDate);
      setProjectDueDate(minimunProjectDueDate);
      setBackupDueDate(taskDueDate!);
    }
  }, [
    firstTime,
    getMinimunTasksDueDate,
    getProjectDueDate,
    groupId,
    projectId,
    setGroupDueDate,
    setProjectDueDate,
    taskDueDate,
  ]);

  useEffect(() => {
    if (backupDueDate.getTime() !== taskDueDate!.getTime()) {
      updateDueDateInTask(taskDueDate!, projectId, groupId, task.id);

      const projectTimestamp = getProjectDueDate(projectId);

      const minimunTasksTimestamp = getMinimunTasksDueDate(projectId, groupId);

      const minimunProjectDueDate = new Date(projectTimestamp);

      const minimunTasksDueDate = new Date(minimunTasksTimestamp);

      setGroupDueDate(minimunTasksDueDate);
      setProjectDueDate(minimunProjectDueDate);
      setBackupDueDate(taskDueDate!);
    }
  }, [
    backupDueDate,
    getMinimunTasksDueDate,
    getProjectDueDate,
    groupId,
    projectId,
    setGroupDueDate,
    setProjectDueDate,
    task.id,
    taskDueDate,
    updateDueDateInTask,
  ]);

  return (
    <>
      <Container>
        <Label htmlFor="taskName">
          <p>Task</p>
          <textarea
            id="taskName"
            defaultValue={task.name}
            onBlur={e => {
              updateTaskName(projectId, groupId, task.id, e.target.value);
            }}
          />
        </Label>
        <SeparatorVertical />
        <Deadline>
          Prazo
          <button type="button" onClick={handleOpenCalendar}>
            <p>
              {format(taskDueDate!, 'dd.MMMM.yyyy', {
                locale: ptBR,
              }).toString()}
              <RiEditBoxLine color="var(--gray)" size={15} />
            </p>
          </button>
          {openNewCalendar && (
            <Modal id="calendar-modal" onClick={handleOutsideClick}>
              <Calendar
                date={taskDueDate}
                onChange={setTaskDueDate}
                activeRemove
              />
            </Modal>
          )}
        </Deadline>
        <SeparatorVertical />
        <Label htmlFor="taskDescription">
          Descrição
          <textarea
            id="taskDescription"
            defaultValue={task.desc}
            onBlur={e => {
              updateTaskDescription(
                projectId,
                groupId,
                task.id,
                e.target.value
              );
            }}
          />
        </Label>
        <Buttons>
          <CloseButton
            onClick={() => deleteTaskAndUpdateProjectDueDate()}
            disabled={false}
          />
        </Buttons>
      </Container>
    </>
  );
};

export default Task;
