import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getGroupsAndIndividualTasks(
  projectId: number,
  query = ''
): Promise<[Group[], Task[]]> {
  try {
    const response = await apiV2.get(`groups/${projectId}${query}`);

    const responseGroups: Group[] = response.data.groups;

    const responseIndividualTasks: Task[] = response.data.tasks;

    return [responseGroups, responseIndividualTasks];
  } catch {
    toast.error(
      'Ocorreu algum problema, atualize a página e tente novamente 😕'
    );
    return [[], []];
  }
}
