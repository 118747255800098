import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

type MassEditTaskResponse = {
  groups: Group[];
  message: string;
  project: Project;
  tasks: Task[];
  phases?: Phase[];
  group?: Group;
};

export const massEditTask = async (
  type: string,
  id: number,
  date: string | null,
  usersIds: (number | undefined)[]
): Promise<MassEditTaskResponse> => {
  try {
    const response = await apiV2.put(`tasks/mass`, {
      include_project_group_progress: true,
      [type]: id,
      due_date: date,
      users_ids: usersIds,
    });

    const task = response.data;

    return task;
  } catch {
    toast.error(
      'Ocorreu algum problema ao editar a task, atualize a página e tente novamente 😕'
    );

    return {} as MassEditTaskResponse;
  }
};
