import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { AiOutlineCheck } from 'react-icons/ai';
import { FaAngleLeft, FaAngleRight, FaTimes } from 'react-icons/fa';
import Loading from '../../../../../components/Loadings/CustomLoading';
import { useContracts } from '../../../../../contexts/ContractsContext';
import { EditInformation } from '../../Modals/EditInformation';
import { approveContract } from '../../../services/ApproveContract';
import { disapproveContract } from '../../../services/DisapproveContract';
import { convertNumberToMoney } from '../../../utils/ConvertNumberToMoney';
import { formatContractsFee } from '../../../utils/FormatContractsFee';
import { deleteContract } from '../../../utils/DeleteContract';
import * as S from './styles';
import { getContracts } from '../../../services/GetContracts';
import ModalBackground from '../../Modals/ModalBackground';

export const WaitingVerificationTable: React.FC = () => {
  const [editInformationModal, setEditInformationModal] = useState(false);
  const [contractToVerify, setContractToVerify] = useState({} as Contract);

  const [loading, setLoading] = useState(true);

  const {
    contracts,
    setContracts,
    setActualPage,
    setTotalPages,
    actualPage,
    totalPages,
  } = useContracts();

  const arrayOfTotalPages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handleCloseModal = (): void => {
    setEditInformationModal(!editInformationModal);
  };

  const handleApproveContract = async (id: number) => {
    setLoading(true);

    if (await approveContract(id)) {
      const contractsWithContractDeleted = deleteContract(contracts, id);

      setContracts(() => contractsWithContractDeleted);
    }

    setLoading(false);
  };

  const handleDisapproveContract = async (id: number) => {
    setLoading(true);

    if (await disapproveContract(id)) {
      const contractsWithContractDeleted = deleteContract(contracts, id);
      setContracts(() => contractsWithContractDeleted);
    }

    setLoading(false);
  };

  const checkContract = (
    contractsToUpdated: Contract[],
    contractId: number,
    isChecked: boolean
  ) => {
    const newContracts = contractsToUpdated;

    const contractIndex = newContracts.findIndex(
      contract => contract.id === contractId
    );

    newContracts[contractIndex] = {
      ...newContracts[contractIndex],
      checked: isChecked,
    };

    setContracts(() => newContracts);
  };

  const checkAllContracts = (
    contractsToUpdated: Contract[],
    isChecked: boolean
  ) => {
    const updatedContracts = contractsToUpdated;

    const contractsWithCheckedUpdated = updatedContracts.map(contract => {
      let updatedContract = contract;

      updatedContract = {
        ...updatedContract,
        checked: isChecked,
      };

      return updatedContract;
    });

    setContracts(() => contractsWithCheckedUpdated);
  };

  const setContractsInfo = useCallback(
    async (status = 'approve', page = 1, limit = 10) => {
      setLoading(true);
      const newContracts = await getContracts(status, page, limit);

      if (newContracts) {
        const contractsWithFormatedFee = formatContractsFee(
          newContracts.contracts
        );
        setContracts(contractsWithFormatedFee);
        setActualPage(newContracts.page);
        setTotalPages(newContracts.total_pages);
      }

      setLoading(false);
    },
    [setContracts, setActualPage, setTotalPages]
  );

  useEffect(() => {
    setContractsInfo();
  }, [setContractsInfo]);

  return (
    <>
      <S.TableBody>
        <S.TableCategories>
          <S.ColumnCategory className="aprovation">
            <input
              id="allcheckbox"
              type="checkbox"
              onChange={e => {
                checkAllContracts(contracts, e.target.checked);
              }}
            />
            <strong>Aprovação</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="contract-start">
            <strong>Início contrato</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="vigence">
            <strong>Vigência</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="first-payment">
            <strong>Primeiro Pagamento</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="client">
            <strong>Unidade</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="cnpj">
            <strong>CNPJ/CPF</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="contract-value">
            <strong>Valor do Contrato</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="product">
            <strong>Produto</strong>
          </S.ColumnCategory>
        </S.TableCategories>
        {loading ? (
          <Loading style={{ alignSelf: 'center' }} />
        ) : (
          contracts &&
          contracts.map(contract => (
            <S.TableLine
              key={contract.id}
              onClick={() => {
                setContractToVerify(contract);
              }}
            >
              <S.ColumnLine className="aprovation">
                <input
                  id="checkbox"
                  type="checkbox"
                  onChange={e => {
                    checkContract(contracts, contract.id, e.target.checked);
                  }}
                  checked={contract.checked}
                />
                <button
                  type="button"
                  onClick={() => handleDisapproveContract(contract.id)}
                >
                  <FaTimes />
                </button>
                <button
                  type="button"
                  onClick={() => handleApproveContract(contract.id)}
                >
                  <AiOutlineCheck />
                </button>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="contract-start"
                onClick={() => {
                  setEditInformationModal(!editInformationModal);
                }}
              >
                <p>
                  {format(new Date(contract.contract_start), 'dd/MM/yyyy', {
                    locale: ptBR,
                  }).toString()}
                </p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="vigence"
                onClick={() => {
                  setEditInformationModal(!editInformationModal);
                }}
              >
                <p>{contract.life_time}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="first-payment"
                onClick={() => {
                  setEditInformationModal(!editInformationModal);
                }}
              >
                <p>{contract.payment_date}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="client"
                onClick={() => {
                  setEditInformationModal(!editInformationModal);
                }}
              >
                <p>{contract.unit.name}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="cnpj"
                onClick={() => {
                  setEditInformationModal(!editInformationModal);
                }}
              >
                <p>{contract.cnpj_cpf}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="contract-value"
                onClick={() => {
                  setEditInformationModal(!editInformationModal);
                }}
              >
                <p>{convertNumberToMoney(contract.fee)}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="product"
                onClick={() => {
                  setEditInformationModal(!editInformationModal);
                }}
              >
                <p>{contract.product ? contract.product : 'Tração'}</p>
              </S.ColumnLine>
            </S.TableLine>
          ))
        )}
        {editInformationModal && (
          <S.Modals>
            <ModalBackground setModal={handleCloseModal} />
            <EditInformation
              contractToVerify={contractToVerify}
              handleCloseModal={handleCloseModal}
              left="50%"
            />
          </S.Modals>
        )}
      </S.TableBody>
      {arrayOfTotalPages.length > 0 && (
        <S.Pagination>
          <FaAngleLeft onClick={() => setContractsInfo('approve', 1, 10)} />
          {arrayOfTotalPages.map(page => (
            <button
              key={page}
              onClick={() => setContractsInfo('approve', page, 10)}
              className={page === actualPage ? 'isActive' : ''}
              type="button"
            >
              {page}
            </button>
          ))}
          <FaAngleRight
            onClick={() =>
              setContractsInfo('approve', arrayOfTotalPages.length, 10)
            }
          />
        </S.Pagination>
      )}
    </>
  );
};
