import React, { useCallback, useEffect, useState } from 'react';
import { BiLayer } from 'react-icons/bi';
import { RiAddCircleLine, RiArrowGoBackLine } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import NewPhase from '../../../components/Modals/NewPhase';
import { Modal } from '../../../styles';
import { GroupBoard } from '../components/GroupBoard';
import * as S from './styles';
import { useTasks } from '../../../../../contexts/TasksContext';
import selectProjectIndexById from '../../../utils/SelectProjectIndexById';
import selectGroupIndexById from '../../../utils/SelectGroupIndexById';
import { getGroupsAndIndividualTasks } from '../../../services/GetGroupsAndIndividualTasks';

export function GroupMain() {
  const { projects, setProjects, query } = useTasks();
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { id, projectId, groupId } = useParams<TasksParams>();

  const projectIndex = selectProjectIndexById(+projectId, projects);

  const setGroupsAndTasksInfos = useCallback(async () => {
    const globalProjects = cloneDeep(projects);
    const [
      responseGroups,
      responseIndividualTasks,
    ] = await getGroupsAndIndividualTasks(+projectId, query);

    globalProjects[projectIndex] = {
      ...globalProjects[projectIndex],
      groups: responseGroups,
      tasks: responseIndividualTasks,
    };
    setProjects([...globalProjects]);
  }, [query, setProjects, projectId, projectIndex, projects]);

  useEffect(() => {
    if (!projects[projectIndex].groups && !projects[projectIndex].tasks) {
      setGroupsAndTasksInfos();
    }
  }, [setGroupsAndTasksInfos, projectIndex, projects]);

  const groupIndex =
    projects[projectIndex].groups &&
    selectGroupIndexById(projectIndex, +groupId, projects);

  const handleChangeModalVisibility = (): void => {
    setOpenModal(state => !state);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') handleChangeModalVisibility();
  };
  return (
    <>
      <S.Wrapper>
        <S.ReturnButton
          id="return-button"
          type="button"
          onClick={() =>
            history.push(`/tasks/${id}/kanban/projects/${projectId}`)
          }
        >
          <RiArrowGoBackLine size={20} color="var(--red-primary)" />
          <p>Voltar</p>
        </S.ReturnButton>
        <S.Header>
          <div id="group">
            <BiLayer size={22} color="var(--red-primary)" />
            <p>Grupo de Entrega</p>
          </div>
          <span>|</span>
          <div id="name">
            <p>
              {projects[projectIndex].groups &&
                `${projects[projectIndex].name} -> ${projects[projectIndex].groups[groupIndex].name}`}
            </p>
          </div>
          <span>|</span>
          <div id="add-new">
            <button type="button" onClick={handleChangeModalVisibility}>
              <RiAddCircleLine size={16} color="var(--red-primary)" />
              <p>Adicionar nova fase</p>
            </button>
          </div>
        </S.Header>
        {projects[projectIndex].groups && <GroupBoard />}
      </S.Wrapper>
      {openModal && (
        <Modal id="modal" onClick={handleOutsideClick}>
          <NewPhase
            groupId={+groupId}
            projectId={+projectId}
            handleClose={handleChangeModalVisibility}
            phases={projects[projectIndex].groups[groupIndex].phases}
          />
        </Modal>
      )}
    </>
  );
}
