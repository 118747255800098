import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function disapproveContract(id: number): Promise<boolean> {
  try {
    await apiV2.delete(`/contracts/disapprove/${id}`);

    toast.info('O contrato foi reprovado e o card retornou no pipefy');

    return true;
  } catch (err) {
    toast.error(
      `Ocorreu algum problema no momento de não aprovar este contrato, atualize a página e tente novamente 😕`
    );

    return false;
  }
}
