import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { RiAddCircleLine } from 'react-icons/ri';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import AddNewButton from '../../../components/AddNewButton';
import CloseButton from '../../../components/CloseButton';

import Phase from '../Phase';
import { useImportPlaybook } from '../../../../../../../../contexts/ImportPlaybookContext';
import Task from '../Task';
import UserAvatar from '../../../../../../../../components/UserAvatar';

import {
  Container,
  Label,
  Deadline,
  Users,
  SeparatorVertical,
  SeparatorHorizontal,
  Buttons,
  AccordionButton,
  Accordion,
  Phases,
  AddNewPhase,
  Tasks,
} from '../../../styles/GroupContainer';

interface GroupProps {
  group: IPlaybookGroup;
  groups: IPlaybookGroup[];
  projectId: number;
  setProjectDueDate: Dispatch<SetStateAction<number>>;
}

const Group: React.FC<GroupProps> = ({
  group,
  projectId,
  setProjectDueDate,
}) => {
  const [groupDueDate, setGroupDueDate] = useState(-1);

  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('');

  const [firstTime, setFirstTime] = useState(true);

  const {
    initialUsers,
    updateGroupName,
    updateGroupDescription,
    deleteGroup,
    addNewPhase,
    addNewTask,
    addUsersInGroups,
  } = useImportPlaybook();

  const groupContent = useRef<HTMLDivElement>(null);

  function toggleGroupAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `500vh`);
    setRotateState(setActive === 'active' ? '' : 'rotate');
  }

  const responsibles = useMemo(
    () =>
      group.users_ids &&
      group.users_ids.map(user => (
        <UserAvatar key={Math.floor(Math.random() * 65536)} id={Number(user)} />
      )),
    [group.users_ids]
  );

  const convertDueDateInDate = useMemo(() => {
    if (groupDueDate === -1) return 'Sem prazo';

    const actualDate = new Date();

    const actualDay = actualDate.getDate();

    const deadline = actualDay + groupDueDate;

    const newDate = actualDate.setDate(deadline);

    const formatedNewDate = format(new Date(newDate), 'dd.MMMM.yyyy', {
      locale: ptBR,
    }).toString();

    return formatedNewDate;
  }, [groupDueDate]);

  useEffect(() => {
    if (firstTime) {
      addUsersInGroups(initialUsers);
      setFirstTime(false);
    }
  }, [
    addUsersInGroups,
    firstTime,
    group.id,
    group.phases,
    initialUsers,
    projectId,
  ]);

  return (
    <>
      <Container>
        <Label htmlFor="groupName">
          Grupo de entrega
          <textarea
            defaultValue={group.name}
            id="groupName"
            onBlur={e => {
              updateGroupName(projectId, group.id, e.target.value);
            }}
          />
        </Label>
        <SeparatorVertical />
        <Label htmlFor="responsibles">
          Responsáveis
          <Users>{responsibles}</Users>
        </Label>
        <SeparatorVertical />
        <Deadline>
          prazo
          <p>{convertDueDateInDate}</p>
        </Deadline>
        <SeparatorVertical />
        <Label htmlFor="groupDescription">
          Descrição
          <textarea
            defaultValue={group.desc}
            id="groupDescription"
            onBlur={e => {
              updateGroupDescription(projectId, group.id, e.target.value);
            }}
          />
        </Label>
        <Buttons>
          <CloseButton
            onClick={() => deleteGroup(projectId, group.id)}
            disabled={false}
          />
          <AccordionButton
            type="button"
            className={`${setActive}`}
            onClick={toggleGroupAccordion}
          >
            <FaAngleDown className={`${setRotate}`} />
          </AccordionButton>
        </Buttons>
      </Container>
      <Accordion ref={groupContent} style={{ maxHeight: `${setHeight}` }}>
        <Phases>
          {group.phases &&
            group.phases.map(phase => (
              <Phase
                key={phase.id}
                phase={phase}
                phases={group.phases ? group.phases : []}
                projectId={projectId}
                groupId={group.id}
              />
            ))}
          <AddNewPhase>
            <button
              type="button"
              onClick={() => addNewPhase(projectId, group.id)}
            >
              <RiAddCircleLine />
              <p>Adicionar nova fase</p>
            </button>
          </AddNewPhase>
        </Phases>
        <SeparatorHorizontal />
        <Tasks>
          {group.phases &&
            group.phases[0] &&
            group.phases[0].tasks &&
            group.phases[0].tasks.map(task => (
              <Task
                key={task.id}
                task={task}
                tasks={
                  group.phases && group.phases[0]?.tasks
                    ? group.phases[0].tasks
                    : []
                }
                projectId={projectId}
                groupId={group.id}
                phaseId={
                  group.phases && group.phases[0].id && group.phases[0].id
                }
                setGroupDueDate={setGroupDueDate}
                setProjectDueDate={setProjectDueDate}
              />
            ))}
          {group.phases && (
            <AddNewButton onClick={() => addNewTask(projectId, group.id)}>
              Adicionar nova task
            </AddNewButton>
          )}
        </Tasks>
      </Accordion>
    </>
  );
};

export default Group;
