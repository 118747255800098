import { useState, useCallback, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  faHouse,
  faSackDollar,
  faBullhorn,
  faClipboardListCheck,
  faFileContract,
  faBuilding,
  faQuestionCircle,
  faLightbulbExclamation,
  faSearch,
  faClipboardList,
  faCalendarCheck,
  faHandshake,
  faInfoCircle,
  faMale,
  faSignOutAlt,
} from '@fortawesome/pro-regular-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

import { toast } from 'react-toastify';
import { RiSearchLine } from 'react-icons/ri';
import * as S from './styles';
import { Client } from '../Client';
import Loading from '../../../../../components/Loadings/CustomLoading';

import api from '../../../../../services/api';

interface MenuProps {
  menuActive: boolean;
  handleMenuActive: () => void;
}

interface Role {
  role?: string;
}
interface ClientData {
  id: number;
  name: string;
  avatar: {
    url: string;
  };
}

library.add(
  faHouse,
  faSackDollar,
  faBullhorn,
  faClipboardListCheck,
  faFileContract,
  faBuilding,
  faQuestionCircle,
  faHandshake,
  faLightbulbExclamation,
  faSearch,
  faClipboardList,
  faCalendarCheck,
  faInfoCircle,
  faMale,
  faSignOutAlt
);

export function Menu({ menuActive, handleMenuActive }: MenuProps) {
  const [clients, setClients] = useState<ClientData[]>([]);
  const [searchName, setSearchName] = useState('');
  const [loading, setLoading] = useState(true);
  const [isFinance, setIsFinance] = useState(false);
  const [isCompliance, setIsCompliance] = useState(false);

  const orderClients = (unorderedClients: ClientData[]) => {
    const sortClients = unorderedClients.sort((firstClient, secondClient) => {
      const nameFirstClient = firstClient.name.toUpperCase();
      const nameSecondClient = secondClient.name.toUpperCase();

      if (nameFirstClient < nameSecondClient) {
        return -1;
      }
      if (nameFirstClient > nameSecondClient) {
        return 1;
      }

      return 0;
    });
    return sortClients;
  };
  const getClients = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get('/clients');
      const orderedClients = orderClients(response.data);
      setClients(orderedClients);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSearch = useCallback(e => {
    setSearchName(e.target.value.toLowerCase());
  }, []);

  const getRoles = useCallback(async () => {
    try {
      const response = await api.get('roles');
      const allRoles: string[] = response.data.flatMap(
        (role: Role) => role.role
      );
      if (allRoles.length !== -1) {
        const existFinance = allRoles.find(role => role === 'finance');
        existFinance && setIsFinance(true);
        const existCompliance = allRoles.find(role => role === 'compliance');
        existCompliance && setIsCompliance(true);
      }
    } catch (err) {
      toast.error(
        'Ocorreu algum problema, atualize a página e tente novamente 😕'
      );
    }
  }, []);

  useEffect(() => {
    getClients();
    getRoles();
  }, [getRoles, getClients]);

  return (
    <S.Container className={menuActive ? 'active' : ''}>
      <S.Body>
        <S.Menu>
          <h2>Menu</h2>
          {isFinance && (
            <li>
              <Link to="/finance" onClick={handleMenuActive}>
                <div>
                  <FontAwesomeIcon icon={['far', 'sack-dollar']} />
                </div>
                Financeiro
              </Link>
            </li>
          )}
          {isFinance && (
            <li>
              <Link to="/rejected_bills" onClick={handleMenuActive}>
                <div>
                  <FontAwesomeIcon icon={['far', 'sack-dollar']} />
                </div>
                Rejeitados
              </Link>
            </li>
          )}
          {isCompliance && (
            <li>
              <Link
                to="/compliance/approve"
                id="compliance"
                onClick={handleMenuActive}
              >
                <div>
                  <FontAwesomeIcon icon={['far', 'file-contract']} />
                </div>
                Compliance
              </Link>
            </li>
          )}
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://portal.pipefy.com/padroes"
              id="pipefy"
              onClick={handleMenuActive}
            >
              <div>
                <FontAwesomeIcon icon={['far', 'clipboard-list-check']} />
              </div>
              Padrões V4
            </a>
          </li>
          <li>
            <Link to="/units" id="units" onClick={handleMenuActive}>
              <div>
                <FontAwesomeIcon icon={['far', 'building']} />
              </div>
              Unidades
            </Link>
          </li>
        </S.Menu>
        <S.ClientsContainer>
          <h2>Clientes</h2>
          <S.Search>
            <input type="text" placeholder="Buscar" onChange={handleSearch} />
            <button type="button">
              <RiSearchLine size={15} />
            </button>
          </S.Search>
          <S.Clients>
            {loading ? (
              <Loading style={{ alignSelf: 'center' }} />
            ) : (
              clients
                .filter(client =>
                  client.name.toLowerCase().includes(searchName)
                )
                .map(client => (
                  <Client
                    key={client.id}
                    id={client.id}
                    name={client.name}
                    handleMenuActive={handleMenuActive}
                  />
                ))
            )}
          </S.Clients>
        </S.ClientsContainer>
      </S.Body>
    </S.Container>
  );
}
