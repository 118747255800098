import styled from 'styled-components';
import checkbox from '../../../../assets/images/checkbox.png';

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.backgroundPrimary};
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.text};
  padding: 10px 5px;
`;
export const Content = styled.div`
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.text};
  max-height: 66vh;
  overflow: auto;

  span {
    p {
      span {
        color: ${({ theme }) => theme.text} !important;
      }
    }
  }
`;
export const Author = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  div {
    margin: 0 5px;
  }
  .avatar {
    border: 1px solid var(--black);
    border-radius: 50%;
    padding: 0px;
    width: 36px !important;
    height: 36px !important;
    font-size: 36px;

    img {
      max-width: none !important;
    }
  }
`;

export const Required = styled.div`
  .required {
    display: flex;
    flex-direction: row;
    align-items: center;
    strong {
      margin-left: 5px;
    }
    input[type='checkbox'].checkbox {
      position: absolute;
      z-index: -1000;
      left: -1000px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }

    input[type='checkbox'].checkbox + label.css-label {
      padding-left: 22px;
      height: 17px;
      display: inline-block;
      line-height: 17px;
      background-repeat: no-repeat;
      background-position: 0 0;
      font-size: 17px;
      vertical-align: middle;
      cursor: pointer;
    }

    input[type='checkbox'].checkbox:checked + label.css-label {
      background-position: 0 -17px;
    }
    label.css-label {
      background-image: url(${checkbox});
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
`;
export const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
