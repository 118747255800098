import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { OptionsSelect } from '../../../../../components/Inputs/OptionsSelect';

import { ProjectsProps } from '../types';

interface ProjectsFileProps {
  projects: ProjectsProps[];
  setProjectId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const Projects: React.FC<ProjectsFileProps> = ({ projects, setProjectId }) => {
  const [projectsMessage, setProjectsMessage] = useState('Carregando...');

  const newProjects = useMemo((): { name: string; value: string }[] => {
    return [];
  }, []);

  const handleGetProjects = useCallback(async () => {
    projects.forEach((project: { name: string; value: string }) => {
      newProjects.push({ name: project.name, value: project.value });
    });

    if (projects) setProjectsMessage('Escolha o projeto');
    else setProjectsMessage('Sem Projetos!');
  }, [projects, newProjects]);

  useEffect(() => {
    handleGetProjects();
  }, [handleGetProjects]);

  return (
    <OptionsSelect
      placeholder={projectsMessage}
      options={projects}
      search
      onChange={setProjectId}
    />
  );
};

export default Projects;
