import React, { useState, useCallback } from 'react';
import { RiClipboardLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { Button, TextInput } from '../../../../../components/Inputs';
import { Container } from './styles';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import RichText from '../../../../../components/RichText';
import { Calendar } from '../../../../../components/Calendar';
import Responsible from '../../../../../components/Responsible';
import useForm from '../../../../../hooks/useForm';
import { LoadingButton } from '../../LoadingButton';
import { TagsCreate } from '../../TagsCreate';
import { createTask } from '../../../services/CreateTask';
import { setTasks } from '../../../utils/SetTasks';
import { useTasks } from '../../../../../contexts/TasksContext';
import { editGroupDetails } from '../../../utils/EditGroupDetails';
import { editProjectDetails } from '../../../utils/EditProjectDetails';

interface useFormHookProps {
  form: { taskName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

const NewTask: React.FC<{
  handleClose: () => void;
  phases: Phase[];
  phaseId: number;
}> = ({ handleClose, phases, phaseId }) => {
  const { projects, setProjects } = useTasks();
  const { form, onChange }: useFormHookProps = useForm({ taskName: '' });
  const [users, setUsers] = useState<{ id?: number }[]>([{}]);
  const [date, setDate] = useState<Date | undefined>();
  const [content, setContent] = useState('');
  const [isDisable, setIsDisable] = useState(false);
  const [tagsList, setTagsList] = useState<ITag[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const handleCreateTask = useCallback(async () => {
    setIsDisable(true);
    if (form.taskName === '') {
      toast.warn('Você precisa adicionar um nome a task');
      setIsDisable(false);

      return;
    }
    const dateToSave = date || null;

    const newTask = await createTask(
      form.taskName,
      content,
      phaseId,
      dateToSave,
      users.filter(user => user?.id).map(user => user?.id)
    );

    const responseTask = {
      checklist: newTask.checklist,
      created_at: newTask.created_at,
      days_repeat: newTask.days_repeat,
      desc: newTask.desc,
      due_date: newTask.due_date,
      finished_at: newTask.finished_at,
      first_due_date: newTask.first_due_date,
      id: newTask.id,
      name: newTask.name,
      order: newTask.order,
      phase_id: newTask.phase_id,
      progress: newTask.progress,
      project_id: newTask.project_id,
      project_phase_name: newTask.project_phase_name,
      recurrence_type: newTask.recurrence_type,
      recurrence_value: newTask.recurrence_value,
      recurrence_id: newTask.recurrence_id,
      required: newTask.required,
      status: newTask.status,
      subtask_phase_checklist_items:
        newTask.subtask_phase_checklist_items ?? [],
      subtasks: newTask.subtasks ?? [],
      tags: newTask.tags,
      type: newTask.type,
      updated_at: newTask.updated_at,
      users: newTask.users,
      was_removed: newTask.was_removed,
    };

    const selectedPhase = phases.filter(phase => phase.id === phaseId)[0];
    selectedPhase.tasks = [...selectedPhase.tasks, responseTask];

    const newProjects = setTasks(
      selectedPhase.tasks,
      projects,
      newTask.project.id,
      newTask.group.id,
      phaseId
    );

    const projectsWithGroupsUpdated = editGroupDetails(
      newTask.group.users,
      newTask.group.due_date,
      newTask.group.progress,
      newTask.project.id,
      newTask.group.id,
      newProjects
    );

    const projectsWithProjectUpdated = editProjectDetails(
      newTask.project.users,
      newTask.project.due_date,
      newTask.project.progress,
      newTask.project.id,
      projectsWithGroupsUpdated
    );

    setProjects(() => projectsWithProjectUpdated);

    toast.success('Task criada com sucesso. Aguarde!');

    setIsDisable(false);
    handleClose();
  }, [
    content,
    date,
    form.taskName,
    handleClose,
    phaseId,
    phases,
    projects,
    setProjects,
    users,
  ]);

  return (
    <Container>
      <TaskCard width="26rem" close={handleClose}>
        <header>
          <RiClipboardLine size={21} color="var(--red-primary)" />
          <p>Nova Task</p>
        </header>
        <main>
          <div className="input">
            <p>Nome da task</p>
            <TextInput
              name="taskName"
              onChange={handleInputChange}
              value={form.taskName}
              width="100%"
              height="2.1rem"
              placeholder="Escreva o nome da task"
              font="400 0.9rem Work Sans"
            />
          </div>

          <div className="input">
            <p>Responsável</p>

            <Responsible users={users} setUsers={setUsers} />
          </div>

          <div className="input">
            <p>Adicionar uma Tag</p>
            <TagsCreate
              width="96%"
              tagsList={tagsList}
              setTagsList={setTagsList}
            />
          </div>

          <div className="input">
            <p>Descrição da task</p>
            <RichText
              height={120}
              width={282}
              initialValue="Escreva aqui a descrição da task."
              contentToSave={(contentItem: string) => {
                setContent(contentItem);
              }}
            />
          </div>

          <div className="input">
            <p>Prazo da task</p>
            <div style={{ marginTop: '0.8rem' }}>
              <Calendar onChange={setDate} date={null} activeRemove />
            </div>
          </div>
        </main>
        <footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isDisable ? (
            <LoadingButton width="7rem" height="2.1rem" />
          ) : (
            <Button
              id="confirm-button"
              disabled={isDisable}
              onClick={() => handleCreateTask()}
            >
              <p>Salvar</p>
            </Button>
          )}
        </footer>
      </TaskCard>
    </Container>
  );
};

export default NewTask;
