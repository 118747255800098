import { Switch } from 'react-router-dom';
import Route from './Route';
import { Compliance } from '../modules/Compliance';
import Broadcast from '../modules/Broadcasts/pages/Broadcast';
import Broadcasts from '../modules/Broadcasts';
import ChargesCharts from '../modules/Financial/pages/FinancialDashboard';
import Clients from '../modules/Clients';
import Financial from '../modules/Financial';
import RejectedBills from '../modules/RejectedBills';
import ForgotPassword from '../modules/Login/pages/ForgotPassword';
import Helpdesk from '../modules/Help';
import Login from '../modules/Login';
import Notifications from '../modules/Broadcasts/pages/Notifications';
import NewCheckin from '../modules/Checkin/pages/QuarterSelect';
import CheckinSelect from '../modules/Checkin/pages/CheckinSelect';
import NewSetup from '../modules/Checkin/pages/CreateNew';
import Slides from '../modules/Checkin/pages/Slides';
import VSelect from '../modules/Checkin/pages/VSelect';
import LinkOkrs from '../modules/Checkin/pages/LinkOkrs';
import CSAT from '../modules/Checkin/pages/CSAT';
import { DistractCalculator } from '../modules/Financial/pages/DistractCalculator';
import { Profile } from '../modules/Login/pages/Profile';
import { Tasks } from '../modules/Tasks';
import { Register } from '../modules/Login/pages/Register';
import { ResetPassword } from '../modules/Login/pages/ResetPassword';
import Suggestion from '../modules/Suggestions';
import Tickets from '../modules/Help/pages/Tickets';
import { UnitProfile } from '../modules/Units/pages/UnitProfile';
import Units from '../modules/Units';
import { UserApproval } from '../modules/UserApproval';
import MyTasks from '../modules/MyTasks';
import TasksBoard from '../modules/MyTasks/pages/TasksBoard';
import OkrsKrs from '../modules/MyTasks/pages/OkrsKrs';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/reset_password/:id" component={ResetPassword} />
      <Route path="/user_approval/:id" component={UserApproval} isPrivate />
      <Route path="/calculator" component={DistractCalculator} isPrivate />
      <Route path="/finance" component={Financial} isPrivate />
      <Route path="/rejected_bills" component={RejectedBills} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/client/profile/:id" component={Clients} isPrivate />
      <Route path="/tasks/:id" component={Tasks} isPrivate />
      <Route path="/home/tasks_board/okrs" component={OkrsKrs} isPrivate />
      <Route path="/home/tasks_board" component={TasksBoard} isPrivate />
      <Route path="/home" component={MyTasks} isPrivate />
      <Route path="/suggestions" component={Suggestion} isPrivate />
      <Route exact path="/help" component={Helpdesk} isPrivate />
      <Route path="/help/tickets" component={Tickets} isPrivate />
      <Route exact path="/units" component={Units} isPrivate />
      <Route path="/units/:id" component={UnitProfile} isPrivate />
      <Route path="/dashboard/finance" component={ChargesCharts} isPrivate />
      <Route path="/notifications" component={Notifications} isPrivate />
      <Route exact path="/broadcasts" component={Broadcasts} isPrivate />
      <Route path="/broadcasts/:id" component={Broadcast} isPrivate />
      <Route path="/checkin/create_new/:id" component={NewSetup} isPrivate />
      <Route
        path="/checkin/quarters/:id/:redirect"
        component={NewCheckin}
        isPrivate
      />
      <Route path="/compliance" component={Compliance} isPrivate />

      <Route
        path="/checkin/quarter/:id"
        component={CheckinSelect}
        exact
        isPrivate
      />
      <Route
        path="/checkin/presentation/:id/:draft"
        component={Slides}
        exact
        isPrivate
      />
      <Route
        path="/checkin/linkOkrs/:id"
        component={LinkOkrs}
        exact
        isPrivate
      />
      <Route path="/checkin/csat/:id" component={CSAT} exact />
      <Route path="/checkin/vselect/:id" component={VSelect} exact isPrivate />
    </Switch>
  );
}
