import styled, { css } from 'styled-components';

interface StyledDivProps {
  display: boolean | undefined;
}

export const Wrapper = styled.div`
  width: 98%;
  align-self: flex-end;
  display: flex;
  height: 2.5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.backgroundTerciary};
  margin-bottom: 0.5rem;
  color: var(--gray);
  align-items: center;
  transition: 0.3s;
  margin-bottom: 1rem;
  margin-top: 1rem;

  button {
    border: none;
    background: none;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .arrow {
    margin-right: 1rem;
  }

  #arrow-box {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    white-space: nowrap;
  }

  :hover {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(227, 36, 56, 0.3);
  }

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(255, 255, 255, 0.2);
      border-radius: 1rem;
      background: transparent;
      box-shadow: none !important;
      cursor: grabbing;
      .drag {
        opacity: 0.8;
      }
    `}
`;

export const Main = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  .icon {
    margin: 0 0.5rem;
  }

  #arrow-box {
    button {
      svg {
        color: ${({ theme }) => theme.text};
      }
    }
  }

  #name {
    align-items: center;
    display: flex;
    font-size: 0.9rem;
    cursor: pointer;

    button {
      color: ${({ theme }) => theme.text};
    }

    #edit-button {
      margin-left: 1.2rem;
      color: ${({ theme }) => theme.text};
    }
  }
  #add-new {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    button {
      border: none;
      background: none;
      margin-left: 1.8rem;
      color: ${({ theme }) => theme.text};

      svg {
        color: ${({ theme }) => theme.text};
      }
    }

    .arrow {
      margin: 0;
      margin-right: 3.9rem;
    }
  }
`;

export const RestorePhase = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  button {
    display: flex;
    align-items: center;
    color: var(--gray);
    background: none;
    border: none;

    #restore-label {
      margin: 0 0.5rem;
    }
  }
`;

export const Container = styled.div`
  & > :last-child {
    .lineDotted {
      display: none;
    }
  }

  p {
    color: ${({ theme }) => theme.text};
  }
`;

export const TaskWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

export const Circlemark = styled.div<StyledDivProps>`
  position: relative;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  left: 5rem;
  top: 2.6rem;

  ${props =>
    props.display &&
    css`
      display: none;
    `}
`;

export const LineMark = styled.div<StyledDivProps>`
  border: 0;
  border-right: 0.19rem;
  height: 5.2rem;
  border-style: dotted;
  position: absolute;
  left: 5.3rem;
  top: 3.5rem;
  color: ${({ theme }) => theme.backgroundPrimary}
    ${props =>
      props.display &&
      css`
        display: none;
      `};
`;

export const StickerColor = styled.div`
  justify-content: flex-end;
  width: 0.5%;
  height: 100%;
  border-radius: 0 1rem 1rem 0;
  outline: none;
`;
