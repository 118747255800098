import styled from 'styled-components';

interface ChartColor {
  color: string;
}

export const Container = styled.div`
  padding: 1rem;
  border-radius: 8px;
  background: ${({ theme }) => theme.backgroundPrimary};
  letter-spacing: 0.28px;

  .name {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
  }

  .name svg {
    cursor: pointer;
    min-width: 16px;
    min-height: 16px;
  }

  .editAndColor {
    display: flex;
  }

  .popup-content {
    background: ${({ theme }) => theme.backgroundSecondary};
  }
`;

export const MetaProgress = styled.div`
  margin-top: 1.5rem;

  .metaName {
    display: flex;
    align-items: center;
    margin-bottom: 0.4rem;
  }

  .metaName p {
    font-size: 0.9rem;
  }

  .metaName svg {
    margin-right: 0.5rem;
  }

  .progress div {
    border-radius: 4px !important;
  }
`;

export const Users = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;

  p {
    font-size: 0.9rem;
    margin-right: 0.4rem;
  }

  svg {
    margin-right: 0.4rem;
  }

  .avatar {
    margin-bottom: 0.2rem;
  }

  .responsibles {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const ChartSquare = styled.div`
  min-height: 1rem;
  min-width: 1rem;
  max-height: 1rem;
  -moz-box-align-width: 1rem;
  background: ${(p: ChartColor) => p.color};
  border-radius: 0.2rem;
  margin-left: 0.5rem;
`;
