import styled from 'styled-components';
import checkbox from '../../../../assets/images/checkbox.png';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  color: var(--gray);
  label {
    font-weight: 1000;
    display: flex;
    flex-direction: column;
    margin-top: 0.625rem;

    strong {
      color: ${({ theme }) => theme.text};
    }
  }

  .selectBox {
    font-weight: 1000;
    display: flex;
    flex-direction: column;
    margin-top: 0.625rem;

    strong {
      color: ${({ theme }) => theme.text};
    }
  }

  #content {
    margin-top: 5px;
    background: ${({ theme }) => theme.backgroundPrimary} 0% 0% no-repeat
      padding-box;
    font-weight: normal;
    border: 1px solid ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.text};
    border-radius: 6px;
    padding: 0.625rem;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    max-height: 400px;
    resize: both;
    overflow-y: auto;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }

  .required {
    display: flex;
    flex-direction: row;
    align-items: center;

    strong {
      margin-left: 10px;
      color: ${({ theme }) => theme.text};
    }
    input[type='checkbox'].checkbox {
      position: absolute;
      z-index: -1000;
      left: -1000px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }

    input[type='checkbox'].checkbox + label.css-label {
      padding-left: 1.375rem;
      height: 1.0625rem;
      display: inline-block;
      line-height: 1.0625rem;
      background-repeat: no-repeat;
      background-position: 0 0;
      font-size: 1.0625rem;
      vertical-align: middle;
      cursor: pointer;
    }

    input[type='checkbox'].checkbox:checked + label.css-label {
      background-position: 0 -1.0625rem;
    }
    label.css-label {
      background-image: url(${checkbox});
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
`;
export const Button = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1.25rem;
`;
