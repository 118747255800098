import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.backgroundSecondary};

  button {
    width: 20%;
    margin-top: 20px;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    font-weight: bolder;
    font-size: 1em;
    margin-left: 75%;
    margin-bottom: 20px;
    :hover {
      filter: brightness(70%);
    }
  }
  label {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.text};
    margin-top: 10px;
    input {
      margin-top: 5px;
      background: ${({ theme }) => theme.backgroundPrimary} 0% 0% no-repeat
        padding-box;
      color: ${({ theme }) => theme.text};
      border: none;
      padding: 0 5px;
      height: 30px;
      width: 40%;
      border-radius: 4px;
    }
    select {
      color: ${({ theme }) => theme.text};
      background-color: ${({ theme }) => theme.backgroundPrimary};
      border: none;
      font-weight: bold;
      width: 40%;
      margin-top: 5px;

      option {
        color: ${({ theme }) => theme.text};
        background-color: ${({ theme }) => theme.backgroundPrimary};
      }
    }
  }
`;
