/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';
import { format, addMinutes, addBusinessDays } from 'date-fns';
import { motion } from 'framer-motion';

// Ordena a table pra cima ou baixo.
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = React.useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

// Retorna a table.
const RejectedTable = props => {
  const { items, requestSort, sortConfig } = useSortableData(props.contents);

  // Corrige a cor do status dependendo do valor.
  const handleStatusColor = useCallback(status => {
    if (status === 'canceled' || status === 'Cancelado') {
      return <td style={{ color: 'var(--yellow-primary)' }}>Rejeitado</td>;
    }
  }, []);

  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const formatDate = dateString => {
    return format(addMinutes(new Date(dateString), 500), 'dd/MM/yyyy');
  };

  return (
    <motion.table
      animate={{ opacity: 1, scaleY: 1, height: 0 }}
      transition={{
        type: 'spring',
        stiffness: 200,
        damping: 50,
      }}
    >
      <tbody>
        <tr
          initial={{ opacity: 0, y: -25 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, scaleY: 0 }}
          className="table-header"
        >
          <td>
            <p />
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('due_date')}
              className={getClassNamesFor('due_date')}
            >
              Vencimento
            </button>
          </td>
          <td>
            <p />
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('client_id')}
              className={getClassNamesFor('client_id')}
            >
              Cliente
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('unit_id')}
              className={getClassNamesFor('unit_id')}
            >
              Unidade
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('fee_value')}
              className={getClassNamesFor('fee_value')}
            >
              Valor do fee
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('royalties_value')}
              className={getClassNamesFor('royalties_value')}
            >
              Royalties
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('matriz_value')}
              className={getClassNamesFor('matriz_value')}
            >
              Valor matriz
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('status')}
              className={getClassNamesFor('status')}
            >
              Status
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('received_date')}
              className={getClassNamesFor('received_date')}
            >
              Recebido Iugu
            </button>
          </td>
        </tr>
        {items.map(item => {
          return (
            <tr
              key={item.id}
              initial={{ opacity: 0, y: -25 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                type: 'spring',
                stiffness: 200,
                damping: 50,
              }}
            >
              <td>
                <div>
                  <></>
                </div>
              </td>

              <td>{formatDate(item.due_date)}</td>

              <td>
                <div>
                  <></>
                </div>
              </td>
              <td>
                <p>{item.client.name}</p>
              </td>
              <td>{item.unit.name}</td>
              <td>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(item.fee_value)}
              </td>
              <td>{item.royalties_value}%</td>
              <td>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(item.fee_value * (item.royalties_value / 100))}
              </td>
              {handleStatusColor(item.status, item.approved_admin)}
              <td>
                {formatDate(new Date(item.received_date)) !==
                formatDate(new Date(item.due_date))
                  ? formatDate(new Date(item.received_date))
                  : formatDate(
                      addBusinessDays(new Date(item.received_date), 2)
                    )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </motion.table>
  );
};

export default RejectedTable;
