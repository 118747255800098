import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function deleteProject(projectId: number): Promise<void> {
  try {
    await apiV2.delete(`projects/${projectId}`);
  } catch {
    toast.error(
      'Ocorreu algum problema ao deletar o projeto, atualize a página e tente novamente :S'
    );
  }
}
