/* eslint-disable react/require-default-props */
import Avatar from 'react-avatar';
import { WrapperAvatar } from './styles';

interface ResponsiblesProps {
  users: User[];
  size?: string;
}

export const Responsibles = ({
  users,
  size = '2.3rem',
}: ResponsiblesProps): JSX.Element => {
  const usersList = users
    ? [...new Map(users.map((user: User) => [user.id, user])).values()]
    : [];

  return (
    <>
      <WrapperAvatar>
        {users &&
          usersList.map((user: User) => {
            if (user) {
              if (user.file) {
                return (
                  <div key={user.id}>
                    <Avatar
                      name={`${user.name} ${user.last_name}`}
                      src={`https://api.laboratoriodomarketing.com/files/${user.file.path}`}
                      className="avatar"
                      color="var(--gray)"
                      size={size}
                    />
                  </div>
                );
              }
              return (
                <div key={user.id}>
                  <Avatar
                    name={`${user.name} ${user.last_name}`}
                    src="https://api.laboratoriodomarketing.com/files"
                    className="avatar"
                    color="var(--gray)"
                    size={size}
                  />
                </div>
              );
            }
            return false;
          })}
      </WrapperAvatar>
    </>
  );
};
