import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function changeGroupOrder(
  groupId: number,
  newOrder: number
): Promise<void> {
  try {
    await apiV2.put(`groups`, {
      id: groupId,
      order: newOrder,
    });
  } catch {
    toast.error(
      'Ocorreu algum problema, atualize a página e tente novamente 😕'
    );
  }
}
