import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { RiEditLine, RiCheckLine } from 'react-icons/ri';

import Responsible from '../../../../../components/Responsible';
import { TextInput, Button } from '../../../../../components/Inputs';

import * as S from './styles';

interface NewKr {
  addFunction: (newKr: {
    name: string;
    goal: number;
    value: number;
    is_the_lesser_the_better: boolean;
    users_id: number[];
  }) => void;
}

const AddKr: React.FC<NewKr> = ({ addFunction }) => {
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState('');
  const [goal, setGoal] = useState(0);
  const [actual, setActual] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [users, setUsers] = useState<{ id?: number }[]>([]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      if (users.length < 1) toast.error('Adicione pelo menos um responsável');
      else {
        const responsibles: number[] = [];
        users.forEach(user => {
          if (user.id) {
            responsibles.push(user.id);
          }
        });

        addFunction({
          name,
          goal: Math.round(goal * 100),
          value: Math.round(actual * 100),
          is_the_lesser_the_better: reverse,
          users_id: responsibles,
        });
        setOpenModal(false);
      }
    },
    [actual, addFunction, goal, name, reverse, users]
  );

  return (
    <Popup
      lockScroll={false}
      arrow={false}
      closeOnDocumentClick={false}
      open={openModal}
      position="top right"
      modal
      trigger={() => {
        return (
          <div>
            <Button
              background="var(--green-primary)"
              height="1.8rem"
              width="16rem"
              font="400 1rem Work Sans"
              text="Adicionar nova KR"
              onClick={() => setOpenModal(true)}
            />
          </div>
        );
      }}
      contentStyle={{
        width: '20rem',
        border: 0,
        borderRadius: '8px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <S.EditCard onSubmit={e => handleSubmit(e)}>
        <header>
          <RiEditLine size={20} color="var(--red-primary)" />
          <p>Edição</p>
        </header>
        <main>
          <S.Input>
            <p>Nome:</p>
            <TextInput
              required
              height="1.8rem"
              placeholder="Digite Aqui"
              maxLength={60}
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
              }}
            />
          </S.Input>

          <S.Input>
            <p>Meta:</p>
            <TextInput
              required
              height="1.8rem"
              placeholder="Digite Aqui"
              type="number"
              step="0.01"
              min="0"
              max="10000000"
              value={goal}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setGoal(parseFloat(e.target.value));
              }}
            />
          </S.Input>

          <S.Input>
            <p>Valor atingido:</p>
            <TextInput
              required
              height="1.8rem"
              placeholder="Digite Aqui"
              type="number"
              step="0.01"
              max="10000000"
              value={actual}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setActual(parseFloat(e.target.value));
              }}
            />
          </S.Input>

          <S.Input>
            <p>Meta menor é melhor?:</p>
            {!reverse ? (
              <S.Checkbox onClick={() => setReverse(!reverse)} />
            ) : (
              <S.Checkbox onClick={() => setReverse(!reverse)}>
                <RiCheckLine size={16} color="var(--gray)" />
              </S.Checkbox>
            )}
          </S.Input>

          <S.Input>
            <p>Responsável:</p>
            <Responsible users={users} setUsers={setUsers} />
          </S.Input>
        </main>
        <footer>
          <Button
            background="var(--gray)"
            className="midButton"
            height="1.6rem"
            text="Cancelar"
            font="400 0.9rem Work Sans"
            type="button"
            onClick={() => {
              setOpenModal(false);
            }}
          />

          <Button
            background="var(--green-primary)"
            width="100%"
            height="1.6rem"
            text="Confirmar"
            font="400 0.9rem Work Sans"
            type="submit"
          />
        </footer>
      </S.EditCard>
    </Popup>
  );
};

export default AddKr;
