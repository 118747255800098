import { ReactNode } from 'react';
import { ViewProvider } from './ViewContext';
import { TagsProvider } from './TagsContext';
import { TasksProvider } from './TasksContext';
import { AuthProvider } from './AuthContext';
import { BroadcastProvider } from './BroadcastContext';
import { ContractsProvider } from './ContractsContext';
import ThemeContext from './ThemeContext';

interface ContextsProps extends React.HTMLAttributes<Element> {
  children: ReactNode;
}

export function Contexts({ children }: ContextsProps) {
  return (
    <AuthProvider>
      <BroadcastProvider>
        <ViewProvider>
          <ThemeContext>
            <TasksProvider>
              <ContractsProvider>
                <TagsProvider>{children}</TagsProvider>
              </ContractsProvider>
            </TasksProvider>
          </ThemeContext>
        </ViewProvider>
      </BroadcastProvider>
    </AuthProvider>
  );
}
