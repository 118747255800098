import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 10rem;
  background-color: ${({ theme }) => theme.backgroundTerciary};
  position: absolute;
  margin-left: -5.5rem;
  width: 10rem;
  min-height: 15rem;
  border-radius: 0.6rem;
  color: ${({ theme }) => theme.text};
  z-index: 2;
  box-shadow: 1px 1px 10px 5px #424242;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  :before {
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
    border-bottom: 0.9rem solid ${({ theme }) => theme.backgroundSecondary};
    top: -0.9rem;
    content: '';
    left: 50%;
    margin-left: 1.1rem;
    position: absolute;
  }
`;

export const Content = styled.div`
  display: flex;
  p {
    margin-left: 0.5rem;
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    p {
      font-size: 0.7rem;
      margin: 0 1.1rem 0 0.5rem;
    }
    span {
      color: var(--red-primary);
    }
  }
  @media (max-width: 1360px) {
    div {
      p {
        font-size: 0.6rem;
        margin: 0 0.7rem 0 0.5rem;
      }
    }
  }
`;
