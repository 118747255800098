import React, { useState, useMemo, useCallback } from 'react';

import { toast } from 'react-toastify';
import { Form } from './styles';
import NewPopup from '../../../../components/PopUp';
import api from '../../../../services/api';

function NewTicket({ active, title, setSearch }) {
  const [name, setName] = useState(title);
  const [desc, setDesc] = useState('');
  const [category, setCategory] = useState('all');
  const [subcategory, setSubcategory] = useState('all');

  const subOptions = useMemo(() => {
    if (category === 'cs') {
      return (
        <>
          <option value="onboarding">Onboarding</option>
          <option value="performance">Performance</option>
          <option value="conflicts">Resolução de Conflitos</option>
        </>
      );
    }
    if (category === 'nc') {
      return (
        <>
          <option value="growth">Crescimento da Unidade</option>
          <option value="hiring">Contratações</option>
          <option value="renegociation">Renogiciação</option>
        </>
      );
    }
    if (category === 'finance') {
      return (
        <>
          <option value="billings">Contas a Receber</option>
          <option value="pnp">Padrões e Processos</option>
        </>
      );
    }
    if (category === 'tech') {
      return (
        <>
          <option value="lab">Mkt Lab</option>
        </>
      );
    }
    if (category === 'sales') {
      return (
        <>
          <option value="hunter">Hunter</option>
          <option value="pnp">Padrões e Processos</option>
        </>
      );
    }
    return false;
  }, [category]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (desc.length < 120) {
        toast.error('Sua descrição deve ter no minimo 120 caracteres');
        return false;
      }
      await api
        .post('/tickets', {
          name,
          desc,
          category,
          subcategory,
        })
        .then(() => {
          active(false);
          setSearch('');
          toast.success('Seu ticket foi enviado com sucesso');
        })
        .catch(() => {
          toast.error('Algo deu errado, por favor atualize sua página.');
        });
      return true;
    },
    [active, category, desc, name, setSearch, subcategory]
  );

  return (
    <NewPopup closePopUp={active} header="Nova Sugestão" width="60%">
      <Form onSubmit={handleSubmit}>
        <label htmlFor="name">
          No que podemos te ajudar?
          <input
            id="name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
        </label>
        <label htmlFor="category">
          Categoria
          <select
            name="category"
            onChange={e => setCategory(e.target.value)}
            required
          >
            <option value="">Selecione a Categoria</option>
            <option value="cs">Custumer Success</option>
            <option value="nc">Network Coordination</option>
            <option value="finance">Financeiro</option>
            <option value="tech">Tecnologia</option>
            <option value="sales">Vendas</option>
          </select>
        </label>
        <label htmlFor="subcategory">
          Subcategoria
          <select
            name="subcategory"
            onChange={e => setSubcategory(e.target.value)}
            defaultValue="0"
            required
          >
            <option value="">Selecione a Subcategoria</option>
            {subOptions}
          </select>
        </label>

        <label htmlFor="desc">
          Descrição
          <textarea
            id="desc"
            type="text"
            value={desc}
            onChange={e => setDesc(e.target.value)}
            placeholder="Descreva no que podemos te ajudar, em no minimo, 120 caracteres."
            required
          />
        </label>
        <button type="submit">Confirmar</button>
      </Form>
    </NewPopup>
  );
}

export default NewTicket;
