import React, { useCallback, useState, useEffect } from 'react';

import CustomLoading from '../../../../../../components/Loadings/CustomLoading';

import api from '../../../../../../services/api';

interface Comments {
  comment1: string;
  comment2: string;
  comment3: string;
}

const Comments: React.FC<{ id: number }> = ({ id }) => {
  const [comments, setComments] = useState<Comments>();

  const handleGetComments = useCallback(async () => {
    await api.get(`comments/${id}`).then(res => {
      setComments(res.data);
    });
  }, [id]);

  useEffect(() => {
    handleGetComments();
  }, [handleGetComments]);

  return (
    <main>
      <div className="comment">
        <p>Observações do checkin:</p>
        {!comments?.comment1 && <CustomLoading />}
        <p>{comments?.comment1}</p>
      </div>

      <div className="comment">
        <p>Ações da V4 na proxima semana:</p>
        {!comments?.comment1 && <CustomLoading />}
        <p>{comments?.comment2}</p>
      </div>

      <div className="comment">
        <p>Ações do Cliente para a próxima semana:</p>
        {!comments?.comment1 && <CustomLoading />}
        <p>{comments?.comment3}</p>
      </div>
    </main>
  );
};

export default Comments;
