import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 8px;
  padding: 1rem;
  overflow-y: auto;
  height: 100%;

  .pageTitle {
    margin-top: 1.6rem;
    font-size: 0.9rem;
    letter-spacing: 1.5px;
    margin-bottom: 1.6rem;
  }

  .pageTitle h1:last-child {
    color: var(--red-primary);
  }

  .cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 1rem;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media (max-width: 920px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: 500px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
`;
