import React, { useEffect, useState, useRef, useMemo } from 'react';

import {
  RiSeedlingLine,
  RiEditBoxLine,
  RiStarLine,
  RiCheckLine,
} from 'react-icons/ri';

import PhaseChecklistItem from '../PhaseChecklistItem';
import AddNewButton from '../../../components/AddNewButton';
import ResponsibleIds from '../../../../../../../../components/ResponsibleIds';

import { useImportPlaybook } from '../../../../../../../../contexts/ImportPlaybookContext';

import {
  Card,
  Title,
  Label,
  Users,
  Subtitle,
  Subtasks,
} from '../../../styles/PhaseContainer';
import CloseButton from '../../../components/CloseButton';

interface PhaseProps {
  phase: IPlaybookPhase;
  phases: IPlaybookPhase[];
  projectId: number;
  groupId: number;
}

const Phase: React.FC<PhaseProps> = ({ phase, phases, projectId, groupId }) => {
  const [firstTime, setFirstTime] = useState(true);

  const [users, setUsers] = useState<IUserId[]>([]);
  const [backupUsers, setBackupUsers] = useState<IUserId[]>([]);

  const {
    addNewPhaseChecklistItem,
    updatePhaseName,
    deletePhase,
    addUserInPhase,
    deleteUserInPhase,
  } = useImportPlaybook();

  const updateUsers = useRef(() => {
    if (backupUsers.length !== users.length) {
      if (backupUsers.length > users.length) {
        const [deletedUser] = backupUsers.filter(user => !users.includes(user));

        deleteUserInPhase(deletedUser, projectId, groupId, phase.id);
      } else {
        const [addedUser] = users.filter(user => !backupUsers.includes(user));

        addUserInPhase(addedUser, projectId, groupId, phase.id);
      }
      setBackupUsers(users);
    }
  });

  const phaseOrder = useMemo(() => {
    if (phases.length > 0 && phases[0].id === phase.id) {
      return 'var(--green-primary)';
    }
    if (
      phases[0].id !== phase.id &&
      phases[phases.length - 1].id !== phase.id
    ) {
      return 'var(--yellow-primary)';
    }
    return 'var(--cyan-primary)';
  }, [phase.id, phases]);

  const phaseNameInput = useRef<HTMLInputElement>(null);

  function handleFocus() {
    return phaseNameInput.current?.focus();
  }

  useEffect(() => {
    updateUsers.current();
  }, [users]);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    }
  }, [firstTime]);

  return (
    <>
      {phases && (
        <Card color={phaseOrder}>
          <Title>
            {phaseOrder === 'var(--green-primary)' && (
              <RiSeedlingLine color={phaseOrder} size={20} />
            )}
            {phaseOrder === 'var(--yellow-primary)' && (
              <RiStarLine color={phaseOrder} size={20} />
            )}
            {phaseOrder === 'var(--cyan-primary)' && (
              <RiCheckLine color={phaseOrder} size={20} />
            )}
            <input
              ref={phaseNameInput}
              defaultValue={phase.name}
              onBlur={e => {
                updatePhaseName(projectId, groupId, phase.id, e.target.value);
              }}
            />
            <RiEditBoxLine
              onClick={handleFocus}
              color="var(--gray)"
              size={20}
            />
            <CloseButton
              onClick={() => {
                deletePhase(projectId, groupId, phase.id);
              }}
              disabled={false}
            />
          </Title>
          <Label htmlFor="responsibles">
            Responsáveis
            <Users>
              <ResponsibleIds users={users} setUsers={setUsers} />
            </Users>
          </Label>
          <Subtasks>
            <Subtitle>Checklist</Subtitle>
            {phase.phase_checklist_items &&
              phase.phase_checklist_items.map(checklistItem => (
                <PhaseChecklistItem
                  key={checklistItem.id}
                  checklistItem={checklistItem}
                  projectId={projectId}
                  groupId={groupId}
                  phaseId={phase.id}
                />
              ))}
            <AddNewButton
              onClick={() =>
                addNewPhaseChecklistItem(projectId, groupId, phase.id)
              }
            >
              Adicionar novo checklist
            </AddNewButton>
          </Subtasks>
        </Card>
      )}
    </>
  );
};

export default Phase;
