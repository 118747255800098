/* eslint-disable react/require-default-props */
import {
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  MouseEvent,
} from 'react';
import { RiStackLine } from 'react-icons/ri';

import { toast } from 'react-toastify';
import { Button, TextInput } from '../../../../../components/Inputs';
import { Container } from './styles';
import RichText from '../../../../../components/RichText';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import { Modal } from '../../../styles';
import MassEditTaskModal from '../MassEditTaskModal';
import useForm from '../../../../../hooks/useForm';
import { LoadingButton } from '../../LoadingButton';
import { TagsCreate } from '../../TagsCreate';
import { deleteGroup } from '../../../services/DeleteGroup';
import { editGroup } from '../../../services/EditGroup';
import selectProjectIndexById from '../../../utils/SelectProjectIndexById';
import { useTasks } from '../../../../../contexts/TasksContext';
import { setGroups } from '../../../utils/SetGroups';

interface useFormHookProps {
  form: { groupName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

interface GroupEditProps {
  group: Group;
  projectId: number;
  handleClose: () => void;
  tags?: ITag[];
}

function GroupEdit({ group, projectId, handleClose, tags }: GroupEditProps) {
  const { projects, setProjects } = useTasks();
  const { form, onChange }: useFormHookProps = useForm({
    groupName: group.name,
  });

  const [groupDescription, setGroupDescription] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [tagsList, setTagsList] = useState<ITag[]>([]);

  useEffect(() => {
    setTagsList(() => tags || []);
  }, [tags]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const handleOpenModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'outside-modal') handleOpenModal();
  };

  const projectIndex = selectProjectIndexById(projectId, projects);

  const handleEditGroup = useCallback(async () => {
    setIsDisable(true);
    setSaving(true);

    const editedGroup = await editGroup(
      group.id,
      form.groupName,
      groupDescription,
      tagsList.map((tag: ITag) => tag.id)
    );

    const groupUpdate = projects[projectIndex].groups.filter(
      groupItem => groupItem.id !== group.id
    );

    groupUpdate.splice(editedGroup.order - 1, 0, editedGroup);
    const newProjects = setGroups(groupUpdate, projects, projectId);
    setProjects(() => newProjects);

    toast.success('Grupo editado com sucesso!');

    handleClose();
    setIsDisable(false);
    setSaving(false);
  }, [
    form.groupName,
    group.id,
    tagsList,
    groupDescription,
    handleClose,
    projectIndex,
    projectId,
    projects,
    setProjects,
  ]);

  const editGroupOnBlur = useCallback(async () => {
    setIsDisable(true);
    setSaving(true);

    const editedGroup = await editGroup(
      group.id,
      form.groupName,
      groupDescription,
      tagsList.map((tag: ITag) => tag.id)
    );

    const groupUpdate = projects[projectIndex].groups.filter(
      groupItem => groupItem.id !== group.id
    );

    groupUpdate.splice(editedGroup.order - 1, 0, editedGroup);
    const newProjects = setGroups(groupUpdate, projects, projectId);
    setProjects(() => newProjects);

    toast.success('Grupo editado com sucesso!');

    setIsDisable(false);
    setSaving(false);

    setIsDisable(false);
    setSaving(false);
  }, [
    form.groupName,
    tagsList,
    group.id,
    groupDescription,
    projectId,
    projectIndex,
    projects,
    setProjects,
  ]);

  const handleDeleteGroup = useCallback(async () => {
    setIsDisable(true);
    setDeleting(true);

    await deleteGroup(group.id);

    const newProjects = setGroups(
      projects[projectIndex].groups.filter(
        groupItem => groupItem.id !== group.id
      ),
      projects,
      projectId
    );
    setProjects(() => newProjects);
    handleClose();

    toast.success('Grupo excluído com sucesso!');

    setIsDisable(false);
    setDeleting(false);
  }, [group.id, handleClose, projectIndex, projects, projectId, setProjects]);

  return (
    <Container>
      <TaskCard close={handleClose}>
        <header>
          <RiStackLine size={21} color="var(--red-primary)" />
          <p>Editar grupo</p>
        </header>
        <main>
          <Button id="mass-edit-button" onClick={handleOpenModal}>
            <p>Fazer edição em massa</p>
          </Button>

          <div className="input">
            <p>Nome do grupo</p>
            <TextInput
              name="groupName"
              onChange={handleInputChange}
              width="100%"
              height="2.1rem"
              value={form.groupName}
              placeholder="Escreva o nome do grupo"
              font="400 0.9rem Work Sans"
            />
          </div>

          <div className="input">
            <p>Adicionar uma Tag</p>
            <TagsCreate tagsList={tagsList} setTagsList={setTagsList} />
          </div>

          <div className="input" onBlur={() => editGroupOnBlur()}>
            <p>Descrição do grupo</p>
            <RichText
              height={200}
              width={282}
              initialValue="Escreva aqui a descrição do grupo."
              contentToSave={(content: string) => {
                setGroupDescription(content);
              }}
            />
          </div>

          <footer>
            {deleting ? (
              <LoadingButton width="70%" height="2.1rem" />
            ) : (
              <Button
                id="delete-button"
                text="Deletar"
                disabled={isDisable}
                onClick={() => handleDeleteGroup()}
              />
            )}
            {saving ? (
              <LoadingButton width="100%" height="2.1rem" />
            ) : (
              <Button
                id="confirm-button"
                disabled={isDisable}
                text="Confirmar"
                onClick={handleEditGroup}
              />
            )}
          </footer>
        </main>
      </TaskCard>
      {openModal && (
        <Modal id="outside-modal" onClick={handleOutsideClick}>
          <MassEditTaskModal
            type="group_id"
            id={group.id}
            projectId={projectId}
            responsibles={group.users}
            dueDate={group.due_date}
            handleClose={handleCloseModal}
          />
        </Modal>
      )}
    </Container>
  );
}

export default GroupEdit;
