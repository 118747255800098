import React, { useCallback, useEffect, useState } from 'react';
import { FaRegListAlt } from 'react-icons/fa';

import _ from 'lodash';
import { useAuth } from '../../../../../../contexts/AuthContext';

import apiV2 from '../../../../../../services/apiV2';
import { Kr, Okr } from '../../../../types';
import { OkrKrCard } from '../OkrKrCard';
import * as S from './styles';

export const OkrKrBoard: React.FC = () => {
  const { id } = useAuth().user;
  const [okrs, setOkrs] = useState<Okr[]>([]);
  const [krs, setKrs] = useState<Kr[]>([]);

  const getOkrs = useCallback(async () => {
    await apiV2.get(`users/${id}/okrs`).then(res => {
      res.data.map((okr: Okr) =>
        okr.krs.map((kr: Kr) => setKrs(state => [...state, kr]))
      );
      setOkrs(() => res.data);
    });
  }, [id]);

  useEffect(() => {
    getOkrs();
  }, [getOkrs]);
  return (
    <S.Wrapper>
      <S.Title>
        <FaRegListAlt size={16} color="var(--green-primary)" />
        <p>OKR&apos;s Padrão</p>
      </S.Title>
      <S.Content>
        {okrs &&
          okrs.map((okr: Okr) => {
            let krProgress = 0;
            const totalKrs = okr.krs.length > 0 ? okr.krs.length : 1;
            _.forEach(okr.krs, kr => {
              if (kr.is_the_lesser_the_better) {
                krProgress += 1 + (1 - kr.value / kr.goal);
              } else {
                krProgress += kr.value / kr.goal;
              }
            });

            let okrProgress = Math.round((krProgress / totalKrs) * 100);
            okrProgress = okrProgress > 100 ? 100 : okrProgress;
            return (
              <OkrKrCard
                key={okr.id}
                name={okr.name}
                percentage={okrProgress < 0 ? 0 : okrProgress}
              />
            );
          })}
      </S.Content>
      <S.Title>
        <FaRegListAlt size={16} color="var(--green-primary)" />
        <p>KR&apos;s Padrão</p>
      </S.Title>
      <S.Content>
        {krs &&
          krs.map((kr: Kr) => {
            let krProgress = 0;
            if (kr.is_the_lesser_the_better) {
              krProgress += Math.round(1 + (1 - (kr.value / kr.goal) * 100));
            } else {
              krProgress += Math.round((kr.value / kr.goal) * 100);
            }
            return (
              <S.KrWrapper key={kr.id}>
                <OkrKrCard
                  name={kr.name}
                  percentage={krProgress < 0 ? 0 : krProgress}
                />
              </S.KrWrapper>
            );
          })}
      </S.Content>
    </S.Wrapper>
  );
};
