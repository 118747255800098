import { ThemeProvider } from 'styled-components';
import { ReactNode } from 'react';
import { Header } from '../Header';
import * as S from './styles';

import { useWhiteMode } from '../../../hooks/useWhiteMode';
import { lightTheme, darkTheme } from '../../themes';

export interface AuthenticatedProps extends React.HTMLAttributes<Element> {
  children: ReactNode;
}

export default function Authenticated({ children }: AuthenticatedProps) {
  const { theme, themeToggler } = useWhiteMode();

  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <S.Wrapper>
        <S.Main>
          <Header themeToggler={themeToggler} />
          <S.Body>{children}</S.Body>
        </S.Main>
      </S.Wrapper>
    </ThemeProvider>
  );
}
