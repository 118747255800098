import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;
  padding-right: 0;
`;

export const Options = styled.div`
  display: flex;
  width: 100%;
  label {
    cursor: pointer;
    color: ${({ theme }) => theme.text};
    display: flex;
    width: 50%;
    border-right: 1px solid var(--gray);
    :last-child {
      border-right: none;
    }
    span {
      margin: auto;
      color: ${({ theme }) => theme.text};
    }
    input[type='radio'] {
      position: absolute;
      visibility: hidden;
      display: none;
    }
    input[type='radio']:checked + span {
      color: var(--red-primary);
      border-bottom: 2px solid var(--red-primary);
    }
  }
`;
