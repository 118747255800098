export interface ThemeProps {
  backgroundPrimary: string;
  backgroundSecondary: string;
  backgroundTerciary: string;
  text: string;
  secondaryText: string;
  logo: string;
  checkinLogo: string;
  lowOpacity: string;
  boxShadow: string;
  menuClientBackground: string;
}

export const darkTheme: ThemeProps = {
  backgroundPrimary: 'var(--dark-background-primary)',
  backgroundSecondary: 'var(--dark-background-secondary)',
  backgroundTerciary: 'var(--dark-background-terciary)',
  text: 'var(--dark-text)',
  secondaryText: 'var(--dark-secondary-text)',
  logo: 'var(--dark-logo)',
  checkinLogo: 'var(--dark-checkin-logo)',
  lowOpacity: 'var(--dark-low-opacity)',
  boxShadow: 'var(--dark-box-shadow)',
  menuClientBackground: 'var(--dark-menu-client-background)',
};

export const lightTheme: ThemeProps = {
  backgroundPrimary: 'var(--white-background-primary)',
  backgroundSecondary: 'var(--white-background-secondary)',
  backgroundTerciary: 'var(--white-background-terciary)',
  text: 'var(--white-text)',
  secondaryText: 'var(--white-secondary-text)',
  logo: 'var(--white-logo)',
  checkinLogo: 'var(--white-checkin-logo)',
  lowOpacity: 'var(--white-low-opacity)',
  boxShadow: 'var(--white-box-shadow)',
  menuClientBackground: 'var(--white-menu-client-background)',
};
