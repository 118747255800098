/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { format, addMinutes, addBusinessDays } from 'date-fns';
import { motion } from 'framer-motion';
import { useAuth } from '../../../../contexts/AuthContext';

import api from '../../../../services/api';

// Ordena a table pra cima ou baixo.
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = React.useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

// Retorna a table.
const AproveTable = props => {
  const [disable, setDisable] = useState(false);
  const { user, checkMasterAdmin } = useAuth();

  const { items, requestSort, sortConfig } = useSortableData(props.contents);

  // Corrige a cor do status dependendo do valor.
  const handleStatusColor = useCallback((status, admin) => {
    if (status === 'Waiting for Unit approval') {
      return <td style={{ color: 'var(--yellow-primary)' }}>Check Unidade</td>;
    }

    if (admin === null) {
      return <td style={{ color: 'var(--red-primary)' }}>Check Admin</td>;
    }

    return <td>{status}</td>;
  }, []);

  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const formatDate = dateString => {
    return format(addMinutes(new Date(dateString), 500), 'dd/MM/yyyy');
  };

  const handleApproval = useCallback(
    async (state, id) => {
      if (disable) {
        return;
      }
      setDisable(true);
      const isMasterAdmin = checkMasterAdmin(user.email);
      if (state === 'Aprove') {
        props.setOnLoading(true);
        await api
          .put(`billing/${isMasterAdmin ? 'master_' : ''}approval`, {
            id,
          })
          .then(res => {
            const message = () => {
              if (res.data === `Billing ${id} approved by unit`) {
                return `Cobrança ${id} aprovada pela unidade`;
              }

              if (res.data === `Billing Approved`) {
                return `Cobrança aprovada!`;
              }

              if (res.data === `Permission denied to approve billing ${id}`) {
                return `Permissão negada para aprovar a cobrança ${id}`;
              }

              if (res.data === `Billing ${id} approved by admin`) {
                return `Cobrança ${id} aprovada pelo admin`;
              }

              if (res.data === `No data altered in the billing ${id}`) {
                return `Nenhum dado alterado na billing ${id}`;
              }

              return 'Aprovada';
            };
            toast.success(message);
            props.setOverallUpdate(props.overallUpdate + 1);
          })
          .catch(err => {
            if (err?.response?.status !== 200) {
              const message = () => {
                if (err.response.data.error === `Validation fails.`) {
                  return `Falha na validação`;
                }

                if (err.response.data.error === `Invalid User`) {
                  return `Usúario inválido`;
                }

                if (
                  err.response.data.error === `Billing ${id} approved by admin`
                ) {
                  return `Cobrança ${id} aprovada pelo admin`;
                }

                if (err.response.data.error === 'Invalid billing selected') {
                  return 'Cobrança selecionada inválida';
                }

                return err.response.data.error;
              };
              toast.error(message);
            }
          });
        props.setOnLoading(false);
      }
      if (state === 'Remove') {
        await api
          .put('billing/delete', {
            id,
          })
          .then(() => {
            props.setJsonData(props.jsonData.filter(item => item.id !== id));
            toast.success('Cobrança Removida!');
          })
          .catch(err => {
            if (err.response.status !== 200) {
              const message = () => {
                if (err.response.data.error === `Validation fails.`) {
                  return `Falha na validação`;
                }

                if (err.response.data.error === `Invalid User`) {
                  return `Usúario inválido`;
                }

                if (err.response.data.error === 'Invalid billing selected') {
                  return 'Cobrança selecionada inválida';
                }

                return err.response.data.error;
              };
              toast.error(message);
            }
          });
      }
      setDisable(false);
    },
    [disable, checkMasterAdmin, user.email, props]
  );

  return (
    <motion.table
      animate={{ opacity: 1, scaleY: 1, height: 0 }}
      transition={{
        type: 'spring',
        stiffness: 200,
        damping: 50,
      }}
    >
      <tbody>
        <tr
          initial={{ opacity: 0, y: -25 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, scaleY: 0 }}
          className="table-header"
        >
          <td>
            <p />
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('due_date')}
              className={getClassNamesFor('due_date')}
            >
              Vencimento
            </button>
          </td>
          <td>
            <p />
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('client_id')}
              className={getClassNamesFor('client_id')}
            >
              Cliente
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('unit_id')}
              className={getClassNamesFor('unit_id')}
            >
              Unidade
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('fee_value')}
              className={getClassNamesFor('fee_value')}
            >
              Valor do fee
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('royalties_value')}
              className={getClassNamesFor('royalties_value')}
            >
              Royalties
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('matriz_value')}
              className={getClassNamesFor('matriz_value')}
            >
              Valor matriz
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('status')}
              className={getClassNamesFor('status')}
            >
              Status
            </button>
          </td>
          <td>
            <button
              type="button"
              onClick={() => requestSort('received_date')}
              className={getClassNamesFor('received_date')}
            >
              Recebido Iugu
            </button>
          </td>
        </tr>
        {items.map(item => {
          if (!item.cancelled) {
            if (item.unit_id === props.unitId || props.unitId === 1) {
              if (
                props.roles.includes('admin') ||
                props.roles.includes('finance') ||
                item.unit_id === props.unitId
              ) {
                props.setAproveTableLenght(1);

                return (
                  <tr
                    key={item.id}
                    initial={{ opacity: 0, y: -25 }}
                    exit={{ opacity: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      type: 'spring',
                      stiffness: 200,
                      damping: 50,
                    }}
                  >
                    <td>
                      <div>
                        <FiCheck
                          className="FiCheck"
                          size={16}
                          style={{ marginRight: '10px' }}
                          color={disable ? '#858585' : ''}
                          onClick={() => {
                            if (!disable) {
                              handleApproval('Aprove', item.id, item.unit_id);
                            }
                          }}
                        />
                      </div>
                    </td>

                    <td>{formatDate(item.due_date)}</td>

                    <td>
                      <div>
                        <FiX
                          className="FiX"
                          size={16}
                          color={disable ? '#858585' : ''}
                          onClick={() => {
                            if (!disable) {
                              handleApproval('Remove', item.id);
                            }
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <p>{item.client.name}</p>
                    </td>
                    <td>{item.unit.name}</td>
                    <td>
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(item.fee_value)}
                    </td>
                    <td>{item.royalties_value}%</td>
                    <td>
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(item.fee_value * (item.royalties_value / 100))}
                    </td>
                    {handleStatusColor(item.status, item.approved_admin)}
                    <td>
                      {formatDate(new Date(item.received_date)) !==
                      formatDate(new Date(item.due_date))
                        ? formatDate(new Date(item.received_date))
                        : formatDate(
                            addBusinessDays(new Date(item.received_date), 2)
                          )}
                    </td>
                  </tr>
                );
              }
            }
          }
          return null;
        })}
      </tbody>
    </motion.table>
  );
};

export default AproveTable;
