import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 85%;
  height: 10rem;
  margin-top: 1rem;
  display: flex;
  div:first-child {
    margin-right: 0.875rem;
    .avatar {
      border-radius: 50%;
    }
  }

  div:last-child {
    width: 100%;
    h2 {
      margin-top: 1rem;
      font-size: 1.7rem;
    }
  }
  @media (max-width: 1301px) {
    h2 {
      font-size: 1.4rem;
    }
    p {
      font-size: 0.7rem;
    }
  }
`;

export const ButtonsArea = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;

  button {
    width: 9rem;
    height: 1.8rem;
    border: 0;
    border-radius: 0.4rem;
    color: var(--white);
  }

  button#back {
    background: #193135;
    margin-right: 1rem;
  }

  button#go-to {
    background: var(--red-primary);
  }
`;
