import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getQuartersByClient(id: string) {
  try {
    const response = await apiV2.get(`quarter/byClient/${id}`);

    return response.data;
  } catch {
    toast.error('Falha ao buscar quarters por cliente');

    return [];
  }
}
