import styled from 'styled-components';
import PhotoImg from '../../../../../assets/images/photoCameraLogo.png';

export const IconContainer = styled.div`
  transition: opacity 0.2s ease-in-out;
  background-color: var(--gray);
  bottom: 0;
  height: 33%;
  left: 0;
  position: absolute;
  right: 0;
  opacity: 0;

  div {
    background-image: url(${PhotoImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    height: 100%;
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  align-self: center;
  display: flex;

  label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      display: flex;
      border-radius: 50%;
      max-width: 140px;
      max-height: 140px;
      width: auto;
      height: auto;
    }

    input {
      display: none;
    }

    &:hover ${IconContainer} {
      opacity: 0.7;
    }
  }
`;
