import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { RiClipboardLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { Button, TextInput, Select } from '../../../../../components/Inputs';
import * as S from './styles';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import RichText from '../../../../../components/RichText';
import { Calendar } from '../../../../../components/Calendar';
import Responsible from '../../../../../components/Responsible';
import useForm from '../../../../../hooks/useForm';
import apiV2 from '../../../../../services/apiV2';
import Checklist from '../../../../../components/Checklist';
import { LoadingButton } from '../../LoadingButton';
import { TagsCreate } from '../../TagsCreate';
import { useTasks } from '../../../../../contexts/TasksContext';
import { editIndividualTasks } from '../../../utils/EditIndividualTasks';
import { editProjectDetails } from '../../../utils/EditProjectDetails';

interface useFormHookProps {
  form: { taskName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

interface NewIndividualTaskProps {
  handleClose: () => void;
  projectId: number;
  tasks: Task[];
  setTasks?: Dispatch<SetStateAction<Task[]>>;
}

function NewIndividualTask({
  handleClose,
  projectId,
  tasks,
  setTasks,
}: NewIndividualTaskProps) {
  const { form, onChange }: useFormHookProps = useForm({ taskName: '' });

  const [users, setUsers] = useState<
    {
      id?: number;
    }[]
  >([{}]);
  const RecurrenceDefaultValues = [
    {
      name: 'Nenhuma',
      value: 'null',
    },
    {
      name: 'Dias após',
      value: 'DAYS_AFTER',
    },
    {
      name: 'Dias da Semana',
      value: 'WEEK_DAYS',
    },
  ];
  const WeekDays: CheckItem[] = [
    {
      name: 'Domingo',
      marked: false,
      infos: 'Selecionar domingo',
      canBeRemoved: false,
    },
    {
      name: 'Segunda-feira',
      marked: false,
      infos: 'Selecionar Segunda-feira',
      canBeRemoved: false,
    },
    {
      name: 'Terça-feira',
      marked: false,
      infos: 'Selecionar Terça-feira',
      canBeRemoved: false,
    },
    {
      name: 'Quarta-feira',
      marked: false,
      infos: 'Selecionar Quarta-feira',
      canBeRemoved: false,
    },
    {
      name: 'Quinta-feira',
      marked: false,
      infos: 'Selecionar Quinta-feira',
      canBeRemoved: false,
    },
    {
      name: 'Sexta-feira',
      marked: false,
      infos: 'Selecionar Sexta-feira',
      canBeRemoved: false,
    },
    {
      name: 'Sábado',
      marked: false,
      infos: 'Selecionar Sábado',
      canBeRemoved: false,
    },
  ];
  const [date, setDate] = useState<Date | undefined>();
  const { projects, setProjects } = useTasks();
  const [content, setContent] = useState('');
  const [checks, setChecks] = useState<CheckItem[]>([]);
  const [isDisable, setIsDisable] = useState(false);
  const [tagsList, setTagsList] = useState<ITag[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const [recurrence, setRecurrence] = useState({ type: 'null', value: 1 });
  const [weekDays, setWeekDays] = useState(WeekDays);

  const createIndividualTask = useCallback(async () => {
    setIsDisable(true);

    if (form.taskName!.length <= 0 || form.taskName! === ' ') {
      toast.warn('Não é possível criar uma task individual sem nome!');
      setIsDisable(false);
      return;
    }
    const recurrenceValueFormated: number[] = [];

    if (recurrence.type === 'WEEK_DAYS') {
      weekDays.forEach((weekDay, index) => {
        if (weekDay.marked === true) {
          recurrenceValueFormated.push(index);
        }
      });
      if (recurrenceValueFormated.length < 1) {
        toast.warn('Por favor, preencha o tempo de recorrencia');
        setIsDisable(false);
        return;
      }
    }
    if (recurrence.type === 'DAYS_AFTER') {
      if (!recurrence.value) {
        toast.warn('Por favor, preencha o tempo de recorrencia');
        setIsDisable(false);
        return;
      }
      if (typeof recurrence.value === 'string') {
        recurrenceValueFormated.push(JSON.parse(recurrence.value)[0]);
      } else {
        recurrenceValueFormated.push(recurrence.value);
      }
    }

    const dateToSave = date || null;

    await apiV2
      .post(`tasks`, {
        include_project_group_progress: true,
        name: form.taskName,
        desc: content,
        project_id: projectId,
        due_date: dateToSave,
        users_ids: users.filter(user => user.id).map(user => user.id),
        tags_ids: tagsList.map((tag: ITag) => tag.id),
        subtasks: checks,
        ...(recurrence.type !== 'null'
          ? {
              recurrence_type: recurrence.type,
              recurrence_value: JSON.stringify(recurrenceValueFormated),
            }
          : {}),
      })
      .then(res => {
        toast.success('Task Individual criada com sucesso!');

        if (setTasks && tasks) {
          const responseTask = {
            checklist: res.data.checklist,
            created_at: res.data.created_at,
            days_repeat: res.data.days_repeat,
            desc: res.data.desc,
            due_date: res.data.due_date,
            finished_at: res.data.finished_at,
            first_due_date: res.data.first_due_date,
            id: res.data.id,
            name: res.data.name,
            order: res.data.order,
            phase_id: res.data.phase_id,
            progress: res.data.progress,
            project_id: res.data.project_id,
            project_phase_name: res.data.project_phase_name,
            required: res.data.required,
            status: res.data.status,
            subtask_phase_checklist_items: [],
            subtasks: [],
            type: res.data.type,
            recurrence_type: res.data.recurrence_type,
            recurrence_value: res.data.recurrence_value,
            updated_at: res.data.updated_at,
            users: res.data.users,
            was_removed: res.data.was_removed,
          };

          setTasks([...tasks, responseTask]);
        }
        if (editIndividualTasks && tasks) {
          const responseTask = {
            checklist: res.data.checklist,
            created_at: res.data.created_at,
            days_repeat: res.data.days_repeat,
            desc: res.data.desc,
            due_date: res.data.due_date,
            finished_at: res.data.finished_at,
            first_due_date: res.data.first_due_date,
            id: res.data.id,
            name: res.data.name,
            order: res.data.order,
            phase_id: res.data.phase_id,
            progress: res.data.progress,
            project_id: res.data.project_id,
            project_phase_name: res.data.project_phase_name,
            required: res.data.required,
            status: res.data.status,
            subtask_phase_checklist_items: [],
            subtasks: [],
            tags: res.data.tags,
            type: res.data.type,
            recurrence_type: res.data.recurrence_type,
            recurrence_value: res.data.recurrence_value,
            updated_at: res.data.updated_at,
            users: res.data.users,
            was_removed: res.data.was_removed,
          };

          const projectsWithIndividualTaskUpdated = editIndividualTasks(
            [...tasks, responseTask],
            projects,
            projectId
          );

          const projectsWithProjectUpdated = editProjectDetails(
            res.data.project.users,
            res.data.project.due_date,
            res.data.project.progress,
            projectId,
            projectsWithIndividualTaskUpdated
          );

          setProjects(() => projectsWithProjectUpdated);
        }
        handleClose();
      });

    setIsDisable(false);
  }, [
    checks,
    content,
    date,
    form.taskName,
    handleClose,
    projectId,
    recurrence.type,
    tagsList,
    recurrence.value,
    tasks,
    setTasks,
    users,
    weekDays,
    projects,
    setProjects,
  ]);

  return (
    <S.Container>
      <TaskCard width="55rem" close={handleClose}>
        <header>
          <RiClipboardLine size={21} color="var(--red-primary)" />
          <p>Nova task Individual</p>
        </header>
        <main>
          <S.Column>
            <div className="input">
              <p>Nome da task</p>
              <TextInput
                name="taskName"
                onChange={handleInputChange}
                value={form.taskName}
                width="100%"
                height="2.1rem"
                placeholder="Escreva o nome da task"
                font="400 0.9rem Work Sans"
              />
            </div>

            <div className="input">
              <p>Responsável</p>

              <Responsible users={users} setUsers={setUsers} />
            </div>

            <div className="input">
              <p>Adicionar uma Tag</p>
              <TagsCreate tagsList={tagsList} setTagsList={setTagsList} />
            </div>

            <div className="input">
              <p>Tipo Recorrência</p>

              <Select
                options={RecurrenceDefaultValues}
                value={recurrence.type}
                onChange={(e): void => {
                  setRecurrence({
                    ...recurrence,
                    type: String(e.target.value),
                  });
                }}
              />
            </div>
            {recurrence.type === 'DAYS_AFTER' ? (
              <div className="input">
                <p>Quantos dias após?</p>
                <TextInput
                  name="recurrenceValue"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setRecurrence({
                      ...recurrence,
                      value: parseInt(e.target.value, 10),
                    });
                  }}
                  value={recurrence.value}
                  width="100%"
                  height="2.1rem"
                  type="number"
                  min="1"
                  placeholder="Quantidade de dias após"
                  font="400 0.9rem Work Sans"
                />
              </div>
            ) : null}
            {recurrence.type === 'WEEK_DAYS' ? (
              <>
                <div className="input">
                  <p style={{ marginBottom: '0.6rem' }}>Dias da semana</p>
                  <Checklist
                    checks={weekDays}
                    setChecks={setWeekDays}
                    canNotAdd
                  />
                </div>
              </>
            ) : null}

            <div className="input">
              <p style={{ marginBottom: '0.6rem' }}>Checklist</p>
              <Checklist checks={checks} setChecks={setChecks} />
            </div>

            <div className="input">
              <p>Descrição da task</p>
              <RichText
                height={200}
                width="22.5rem"
                initialValue="Escreva aqui a descrição da task."
                contentToSave={(contentItem: string) => {
                  setContent(contentItem);
                }}
              />
            </div>
          </S.Column>

          <S.Column>
            <div className="input">
              <p>Prazo da task</p>
              <div style={{ marginTop: '0.8rem' }}>
                <Calendar onChange={setDate} date={null} activeRemove />
              </div>
            </div>

            <footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {isDisable ? (
                <LoadingButton width="50%" height="2.1rem" />
              ) : (
                <Button
                  id="confirm-button"
                  onClick={() => createIndividualTask()}
                  disabled={isDisable}
                >
                  <p>Criar</p>
                </Button>
              )}
            </footer>
          </S.Column>
        </main>
      </TaskCard>
    </S.Container>
  );
}

export default NewIndividualTask;
