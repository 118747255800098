import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function getSubtasks(taskId: number): Promise<Subtask[]> {
  try {
    const response = await apiV2.get(`subtasks/${taskId}`);

    const subtasks = response.data;

    return subtasks;
  } catch {
    toast.error(
      'Ocorreu algum problema ao carregar as subtasks, atualize a página e tente novamente :S'
    );
    return [];
  }
}
