import React from 'react';
import { RiFileListLine } from 'react-icons/ri';

import MetaOkr from './MetaOkr';
import KrsCard from './KrsCard';

import { Header } from './styles';
import { UserId, Okrs } from './types';

interface Props {
  okr: Okrs;
  presentation: boolean;
  i: number;
}

const KrsAndOkrsView: React.FC<Props> = ({ okr, presentation, i }) => {
  let goal = 0;
  let value = 0;
  const okrUsers: UserId[] = [];

  if (okr.krs) {
    okr.krs.forEach(kr => {
      if (kr.is_the_lesser_the_better) {
        goal += kr.value;
        value += kr.goal;
      } else {
        goal += kr.goal;
        value += kr.value;
      }

      kr.users_id &&
        kr.users_id.forEach(user => {
          const contains = okrUsers.some(elem => {
            return JSON.stringify(user) === JSON.stringify(elem);
          });
          if (!contains) {
            okrUsers.push(user);
          }
        });
    });
  }

  return (
    <div key={okr.id} className="okr">
      <Header>
        <div className="nameAndIcon">
          <RiFileListLine size={23} color="var(--green-primary)" />
          <p>{okr.name}</p>
        </div>
      </Header>

      <MetaOkr
        data-testid="meta"
        color={presentation ? i + 1 : 0}
        cardInfo={{
          name: okr.name,
          users_id: okrUsers,
          goal,
          value,
          is_the_lesser_the_better: false,
          id: okr.id,
        }}
      />

      <KrsCard krs={okr.krs} />
    </div>
  );
};

export default KrsAndOkrsView;
