import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';
import api from '../../../../../../services/api';

type Notification = {
  _id: number;
  read: boolean;
  link: string;
  content: string;
  timeDistance: string;
};

interface NotificationCardProps {
  notification: Notification;
  update: (id: number, isRead: boolean) => Promise<void>;
}

export const NotificationCard: FC<NotificationCardProps> = ({
  notification,
  update,
}) => {
  const { _id: notificationId } = notification;

  const history = useHistory();

  const handleAction = useCallback(
    link => {
      history.push(link);
    },
    [history]
  );

  const handleRead = useCallback(async () => {
    await api
      .put(`notifications/`, {
        id: notificationId,
        read: !notification.read,
      })
      .then(() => {
        update(notificationId, !notification.read);
      });
  }, [notificationId, notification.read, update]);

  return (
    <Container unread={!notification.read} link={!!notification.link}>
      <div aria-hidden onClick={() => handleAction(notification.link)}>
        <p>{notification.content}</p>
      </div>
      <time>{notification.timeDistance}</time>

      <button type="button" onClick={() => handleRead()}>
        Marcar como {!notification.read ? 'lida' : 'não lida'}
      </button>
    </Container>
  );
};
