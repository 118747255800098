import React, { createContext, useState, useContext } from 'react';

interface BlockStates {
  isBlocked: boolean;
  setIsBlocked: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckinBlockContext = createContext({} as BlockStates);

const HandleCheckinBlock: React.FC = ({ children }) => {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  return (
    <CheckinBlockContext.Provider value={{ isBlocked, setIsBlocked }}>
      {children}
    </CheckinBlockContext.Provider>
  );
};

export default HandleCheckinBlock;

export function useBlock(): BlockStates {
  const context = useContext(CheckinBlockContext);
  if (!context)
    throw new Error('useBlock must be used within a CheckinBlock Provider.');

  const { isBlocked, setIsBlocked }: BlockStates = context;
  return { isBlocked, setIsBlocked };
}
