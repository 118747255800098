/* eslint-disable react/require-default-props */
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react';
import {
  RiClipboardLine,
  RiSeedlingLine,
  RiCheckLine,
  RiStarHalfFill,
} from 'react-icons/ri';

import { toast } from 'react-toastify';

import Coments from '../../../../../components/Coments';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import RichText from '../../../../../components/RichText';
import Checklist from '../../../../../components/Checklist';
import { Calendar } from '../../../../../components/Calendar';
import Responsible from '../../../../../components/Responsible';

import { Button, TextInput } from '../../../../../components/Inputs';

import * as S from './styles';

import useProgress from '../../../../../hooks/useProgress';
import useForm from '../../../../../hooks/useForm';
import apiV2 from '../../../../../services/apiV2';
import { LoadingButton } from '../../LoadingButton';
import { ProgressDisplay } from '../../ProgressDisplay';
import { TagsCreate } from '../../TagsCreate';
import { getSubtasks } from '../../../services/GetSubtasks';
import { getComments } from '../../../services/GetComments';
import { getPhases } from '../../../services/GetPhases';
import { editTask } from '../../../services/EditTask';
import { deleteTask } from '../../../services/DeleteTask';
import { editTaskNextPhase } from '../../../services/EditTaskNextPhase';
import { createSubtask } from '../../../services/CreateSubtask';
import { useTasks } from '../../../../../contexts/TasksContext';
import { editProjectDetails } from '../../../utils/EditProjectDetails';
import { editGroupDetails } from '../../../utils/EditGroupDetails';
import { setTasks } from '../../../utils/SetTasks';
import selectGroupIndexById from '../../../utils/SelectGroupIndexById';
import selectProjectIndexById from '../../../utils/SelectProjectIndexById';

interface useFormHookProps {
  form: { taskName?: string };
  onChange: (name: string, value: string | number | boolean | null) => void;
}

interface TaskUser {
  id: number;
  name: string;
  last_name: string;
}

interface NormalTaskProps {
  handleClose: () => void;
  task: Task;
  phase: Phase;
  tasks: Task[];
  setMyTasks?: Dispatch<SetStateAction<Task[]>>;
  tags?: ITag[];
  myTasksView?: boolean;
  projectId?: number;
  groupId?: number;
}

function NormalTask({
  handleClose,
  task,
  phase,
  tasks,
  tags,
  myTasksView,
  projectId,
  setMyTasks,
  groupId,
}: NormalTaskProps) {
  const { projects, setProjects } = useTasks();
  const [phases, setPhases] = useState<Phase[]>([]);
  const { colors } = useProgress();
  const { form, onChange }: useFormHookProps = useForm({ taskName: task.name });
  const [date, setDate] = useState<Date | undefined>(
    task.due_date ? new Date(task.due_date) : undefined
  );
  const [users, setUsers] = useState([{}]);
  const [content, setContent] = useState('');
  const [comments, setComments] = useState<Comments[]>([]);
  const [loading, setLoading] = useState(true);
  const [checks, setChecks] = useState<CheckItem[]>([]);
  const [tooltipArea, setTooltipArea] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [nextPhase, setNextPhase] = useState(false);
  const [tagsList, setTagsList] = useState<ITag[]>([]);

  useEffect(() => {
    setTagsList(() => tags || []);
  }, [tags]);

  const handleTooltip = () => {
    setTooltipArea(!tooltipArea);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    onChange(name, value);
  };

  const progressBarsColors = useMemo(
    () => colors(task.due_date, task.finished_at, task.progress),
    [colors, task.due_date, task.finished_at, task.progress]
  );

  const progressTitle = useMemo(
    () => `${task.progress ? Math.round(task.progress * 100) / 100 : 0}%`,
    [task.progress]
  );

  const setSubstasksInfo = useCallback(async () => {
    const subtasks = await getSubtasks(task.id);

    const checksData = [
      ...subtasks.map((subtask: Subtask) => {
        const marked = !!subtask.finished_at;
        return {
          id: subtask.id,
          name: subtask.name,
          createdAt: subtask.created_at!,
          canBeRemoved: true,
          marked,
        };
      }),
      ...task.subtask_phase_checklist_items!.map(
        (phaseCheckListItem: PhaseChecklistItem) => {
          const marked = !!phaseCheckListItem.finished_at;
          return {
            id: phaseCheckListItem.id,
            name: phaseCheckListItem.name,
            createdAt: phaseCheckListItem.created_at!,
            canBeRemoved: false,
            infos: 'Esse item não pode ser removido pois pertence a uma fase.',
            marked,
          };
        }
      ),
    ];

    setChecks(
      checksData.sort((firstItem, secondItem) =>
        firstItem.createdAt < secondItem.createdAt ? -1 : 1
      )
    );
  }, [task]);

  const setCommentsInfo = useCallback(async () => {
    const newComments = await getComments(task.id);

    setComments(newComments);

    setLoading(false);
  }, [task.id]);

  const getTaskUsers = useCallback(() => {
    const taskUsers: TaskUser[] = [];
    task.users.forEach((user: User) => {
      taskUsers.push({
        id: user.id,
        name: user.name,
        last_name: user.last_name,
      });
    });
    setUsers(taskUsers);
  }, [setUsers, task.users]);

  const setPhasesInfo = useCallback(async () => {
    const newPhases = await getPhases(phase.group_id);

    setPhases(newPhases);
  }, [phase.group_id]);

  useEffect(() => {
    setPhasesInfo();
    setSubstasksInfo();
    setCommentsInfo();
    getTaskUsers();
  }, [setSubstasksInfo, setCommentsInfo, setPhasesInfo, getTaskUsers]);

  const handleEditTask = useCallback(async () => {
    setIsDisable(true);
    setSaving(true);
    const dateToString = date ? date.toString() : null;

    const editedTask = await editTask(
      task.id,
      form.taskName,
      content,
      phase.id,
      dateToString,
      users,
      tagsList.map((tag: ITag) => tag.id)
    );

    if (myTasksView) {
      if (tasks && setMyTasks) {
        const responseTask = {
          checklist: editedTask.checklist,
          created_at: editedTask.created_at,
          days_repeat: editedTask.days_repeat,
          desc: editedTask.desc,
          due_date: editedTask.due_date,
          finished_at: editedTask.finished_at,
          first_due_date: editedTask.first_due_date,
          id: editedTask.id,
          name: editedTask.name,
          order: editedTask.order,
          phase,
          phase_id: editedTask.phase_id,
          progress: editedTask.progress,
          project_id: editedTask.project_id,
          project_phase_name: editedTask.project_phase_name,
          recurrence_type: editedTask.recurrence_type,
          recurrence_value: editedTask.recurrence_value,
          recurrence_id: editedTask.recurrence_id,
          required: editedTask.required,
          status: editedTask.status,
          subtask_phase_checklist_items:
            editedTask.subtask_phase_checklist_items ?? [],
          subtasks: editedTask.subtasks ?? [],
          tags: editedTask.tags,
          top_hierarchy: task.top_hierarchy,
          type: editedTask.type,
          updated_at: editedTask.updated_at,
          users: editedTask.users,
          was_removed: editedTask.was_removed,
        };
        const tasksUpdate = tasks.filter(tasksItem => tasksItem.id !== task.id);
        tasksUpdate.splice(editedTask.order! - 1, 0, responseTask);
        setMyTasks(() => tasksUpdate);
      }
    } else {
      const responseTask = {
        checklist: editedTask.checklist,
        created_at: editedTask.created_at,
        days_repeat: editedTask.days_repeat,
        desc: editedTask.desc,
        due_date: editedTask.due_date,
        finished_at: editedTask.finished_at,
        first_due_date: editedTask.first_due_date,
        id: editedTask.id,
        name: editedTask.name,
        order: editedTask.order,
        phase_id: editedTask.phase_id,
        progress: editedTask.progress,
        project_id: editedTask.project_id,
        project_phase_name: editedTask.project_phase_name,
        recurrence_type: editedTask.recurrence_type,
        recurrence_value: editedTask.recurrence_value,
        recurrence_id: editedTask.recurrence_id,
        required: editedTask.required,
        status: editedTask.status,
        subtask_phase_checklist_items:
          editedTask.subtask_phase_checklist_items ?? [],
        subtasks: editedTask.subtasks ?? [],
        tags: editedTask.tags,
        type: editedTask.type,
        updated_at: editedTask.updated_at,
        users: editedTask.users,
        was_removed: editedTask.was_removed,
      };

      const tasksUpdate = phase.tasks.filter(
        tasksItem => tasksItem.id !== task.id
      );
      tasksUpdate.splice(editedTask.order - 1, 0, responseTask);

      if (projectId && groupId) {
        const newProjectsWithTasks = setTasks(
          tasksUpdate,
          projects,
          projectId,
          groupId,
          phase.id
        );

        const projectsWithGroupsUpdated = editGroupDetails(
          editedTask.group.users,
          editedTask.group.due_date,
          editedTask.group.progress,
          projectId,
          groupId,
          newProjectsWithTasks
        );

        const projectsWithProjectUpdated = editProjectDetails(
          editedTask.project.users,
          editedTask.project.due_date,
          editedTask.project.progress,
          projectId,
          projectsWithGroupsUpdated
        );
        setProjects(() => projectsWithProjectUpdated);
      }
    }

    toast.success('Task editada com sucesso!');
    handleClose();

    setIsDisable(false);
    setSaving(false);
  }, [
    content,
    date,
    form.taskName,
    handleClose,
    phase,
    tagsList,
    task.top_hierarchy,
    myTasksView,
    task.id,
    tasks,
    users,
    projectId,
    groupId,
    projects,
    setProjects,
    setMyTasks,
  ]);

  const editTaskOnBlur = useCallback(async () => {
    setIsDisable(true);
    setSaving(true);

    const dateToString = date ? date.toString() : null;
    const editedTask = await editTask(
      task.id,
      form.taskName,
      content,
      phase.id,
      dateToString,
      users,
      tagsList.map((tag: ITag) => tag.id)
    );

    if (myTasksView) {
      if (tasks && setMyTasks) {
        const responseTask = {
          checklist: editedTask.checklist,
          created_at: editedTask.created_at,
          days_repeat: editedTask.days_repeat,
          desc: editedTask.desc,
          due_date: editedTask.due_date,
          finished_at: editedTask.finished_at,
          first_due_date: editedTask.first_due_date,
          id: editedTask.id,
          name: editedTask.name,
          order: editedTask.order,
          phase,
          phase_id: editedTask.phase_id,
          progress: editedTask.progress,
          project_id: editedTask.project_id,
          project_phase_name: editedTask.project_phase_name,
          recurrence_type: editedTask.recurrence_type,
          recurrence_value: editedTask.recurrence_value,
          recurrence_id: editedTask.recurrence_id,
          required: editedTask.required,
          status: editedTask.status,
          subtask_phase_checklist_items:
            editedTask.subtask_phase_checklist_items ?? [],
          subtasks: editedTask.subtasks ?? [],
          tags: editedTask.tags,
          top_hierarchy: task.top_hierarchy,
          type: editedTask.type,
          updated_at: editedTask.updated_at,
          users: editedTask.users,
          was_removed: editedTask.was_removed,
        };
        const tasksUpdate = tasks.filter(tasksItem => tasksItem.id !== task.id);
        tasksUpdate.splice(editedTask.order! - 1, 0, responseTask);
        setMyTasks(() => tasksUpdate);
      }
    } else {
      const responseTask = {
        checklist: editedTask.checklist,
        created_at: editedTask.created_at,
        days_repeat: editedTask.days_repeat,
        desc: editedTask.desc,
        due_date: editedTask.due_date,
        finished_at: editedTask.finished_at,
        first_due_date: editedTask.first_due_date,
        id: editedTask.id,
        name: editedTask.name,
        order: editedTask.order,
        phase_id: editedTask.phase_id,
        progress: editedTask.progress,
        project_id: editedTask.project_id,
        project_phase_name: editedTask.project_phase_name,
        recurrence_type: editedTask.recurrence_type,
        recurrence_value: editedTask.recurrence_value,
        recurrence_id: editedTask.recurrence_id,
        required: editedTask.required,
        status: editedTask.status,
        subtask_phase_checklist_items:
          editedTask.subtask_phase_checklist_items ?? [],
        subtasks: editedTask.subtasks ?? [],
        tags: editedTask.tags,
        type: editedTask.type,
        updated_at: editedTask.updated_at,
        users: editedTask.users,
        was_removed: editedTask.was_removed,
      };

      const tasksUpdate = phase.tasks.filter(
        tasksItem => tasksItem.id !== task.id
      );
      tasksUpdate.splice(editedTask.order - 1, 0, responseTask);
      if (projectId && groupId) {
        const newProjectsWithTasks = setTasks(
          tasksUpdate,
          projects,
          projectId,
          groupId,
          phase.id
        );

        const projectsWithGroupsUpdated = editGroupDetails(
          editedTask.group.users,
          editedTask.group.due_date,
          editedTask.group.progress,
          projectId,
          groupId,
          newProjectsWithTasks
        );

        const projectsWithProjectUpdated = editProjectDetails(
          editedTask.project.users,
          editedTask.project.due_date,
          editedTask.project.progress,
          projectId,
          projectsWithGroupsUpdated
        );

        setProjects(() => projectsWithProjectUpdated);
      }
    }

    toast.success('Task editada com sucesso!');

    setIsDisable(false);
    setSaving(false);
  }, [
    content,
    date,
    form.taskName,
    phase,
    tagsList,
    task.top_hierarchy,
    myTasksView,
    task.id,
    tasks,
    users,
    projectId,
    groupId,
    projects,
    setProjects,
    setMyTasks,
  ]);

  const handleEditTaskNextPhase = useCallback(
    async (phaseId: number) => {
      setIsDisable(true);
      setNextPhase(true);

      const editedTask = await editTaskNextPhase(task.id, phaseId);

      const responseTask = {
        checklist: editedTask.checklist,
        created_at: editedTask.created_at,
        days_repeat: editedTask.days_repeat,
        desc: editedTask.desc,
        due_date: editedTask.due_date,
        finished_at: editedTask.finished_at,
        first_due_date: editedTask.first_due_date,
        id: editedTask.id,
        name: editedTask.name,
        order: editedTask.order,
        phase_id: editedTask.phase_id,
        progress: editedTask.progress,
        project_id: editedTask.project_id,
        project_phase_name: editedTask.project_phase_name,
        recurrence_type: editedTask.recurrence_type,
        recurrence_value: editedTask.recurrence_value,
        recurrence_id: editedTask.recurrence_id,
        required: editedTask.required,
        status: editedTask.status,
        subtask_phase_checklist_items:
          editedTask.subtask_phase_checklist_items ?? [],
        subtasks: editedTask.subtasks ?? [],
        tags: editedTask.tags,
        type: editedTask.type,
        updated_at: editedTask.updated_at,
        users: editedTask.users,
        was_removed: editedTask.was_removed,
      };

      const filteredTasks = phase.tasks.filter(
        taskItem => taskItem.id !== task.id
      );
      if (projectId && groupId) {
        const newProjects = setTasks(
          filteredTasks,
          projects,
          projectId,
          groupId,
          phase.id
        );

        const projectIndex = selectProjectIndexById(projectId, projects);
        const groupIndex = selectGroupIndexById(
          projectIndex,
          groupId,
          projects
        );

        const selectedPhase = newProjects[projectIndex].groups[
          groupIndex
        ].phases.find(
          phaseItemToUpdate => phaseItemToUpdate.id === editedTask.phase_id
        );
        const tasksUpdate = selectedPhase!.tasks;
        tasksUpdate.splice(responseTask.order! - 1, 0, responseTask);

        setProjects(() => newProjects);
      }
      toast.success('Task editada com sucesso. Aguarde.');
      setIsDisable(false);
      setNextPhase(false);
      handleClose();
    },
    [
      handleClose,
      setProjects,
      phase.tasks,
      task.id,
      groupId,
      phase.id,
      projects,
      projectId,
    ]
  );

  const handleDeleteTask = useCallback(async () => {
    setIsDisable(true);
    setDeleting(true);

    await deleteTask(task.id);

    const filteredTasks = tasks.filter(taskItem => taskItem.id !== task.id);
    if (projectId && groupId) {
      const newProjects = setTasks(
        filteredTasks,
        projects,
        projectId,
        groupId,
        phase.id
      );
      setProjects([...newProjects]);
    }
    toast.success('Task excluída com sucesso. Aguarde!');
    handleClose();

    setIsDisable(false);
    setDeleting(false);
  }, [
    handleClose,
    task.id,
    tasks,
    groupId,
    phase.id,
    projects,
    setProjects,
    projectId,
  ]);

  const postCheckItem = useCallback(
    async (checkContent: string) => {
      const taskWithSubtask = await createSubtask(task.id, checkContent);

      const newTask = task;
      newTask.progress = taskWithSubtask.progress;
      const tasksUpdate = tasks.filter(
        tasksItem => tasksItem.id !== newTask.id
      );

      tasksUpdate.splice(taskWithSubtask.order! - 1, 0, newTask);
      if (projectId && groupId) {
        const newProjects = setTasks(
          tasksUpdate,
          projects,
          projectId,
          groupId,
          phase.id
        );
        setProjects([...newProjects]);
      }
      const newSubtask: CheckItem = {
        id: taskWithSubtask.id,
        createdAt: taskWithSubtask.created_at,
        name: taskWithSubtask.name,
        marked: false,
        canBeRemoved: true,
      };
      setChecks(checks.concat([newSubtask]));

      toast.success('Check item salvo com sucesso!');
    },
    [task, tasks, checks, groupId, phase.id, projects, setProjects, projectId]
  );

  const markCheckBox = useCallback(
    async (id: number | undefined, marked: boolean, canBeRemoved: boolean) => {
      const finished_at = marked ? new Date() : null;
      const endpoint = canBeRemoved
        ? `subtasks`
        : `subtask-phase-checklist-items`;
      const args: {
        id?: number;
        finished_at?: Date | null;
        include_task_progress?: boolean;
      } = {
        id,
        finished_at,
      };

      if (endpoint === `subtasks`) {
        args.include_task_progress = true;
      }

      await apiV2
        .put(endpoint, args)
        .then(res => {
          toast.success('Check item atualizado com sucesso!');

          const newTask = task;
          newTask.progress = res.data.taskProgress;
          const tasksUpdate = tasks.filter(
            tasksItem => tasksItem.id !== newTask.id
          );
          if (projectId && groupId) {
            tasksUpdate.splice(res.data.order - 1, 0, newTask);
            const newProjects = setTasks(
              tasksUpdate,
              projects,
              projectId,
              groupId,
              phase.id
            );
            setProjects([...newProjects]);
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            toast.error(
              'Ocorreu algum problema, atualize a página e tente novamente 😕'
            );
          }
        });
    },
    [task, tasks, groupId, phase.id, projects, setProjects, projectId]
  );

  const deleteCheckItem = useCallback(
    (id: number | undefined) => {
      apiV2
        .delete(`subtasks/${id}`)
        .then(res => {
          toast.success('Check item excluído com sucesso. Aguarde!');

          const newTask = task;
          newTask.progress = res.data.task.progress;
          const tasksUpdate = tasks.filter(
            tasksItem => tasksItem.id !== newTask.id
          );

          tasksUpdate.splice(res.data.order - 1, 0, newTask);

          if (projectId && groupId) {
            const newProjects = setTasks(
              tasksUpdate,
              projects,
              projectId,
              groupId,
              phase.id
            );
            setProjects([...newProjects]);
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            toast.error(
              'Ocorreu algum problema, atualize a página e tente novamente 😕'
            );
          }
        });
    },
    [task, tasks, groupId, phase.id, projects, setProjects, projectId]
  );

  const iconPhase = useMemo(() => {
    if (phase.order === 1) {
      return (
        <RiSeedlingLine
          className="icon"
          color="var(--green-primary)"
          size={20}
        />
      );
    }
    if (phase.order === phases.length) {
      return (
        <RiCheckLine className="icon" color="var(--cyan-primary)" size={20} />
      );
    }
    return (
      <RiStarHalfFill
        className="icon"
        color="var(--yellow-primary)"
        size={20}
      />
    );
  }, [phase.order, phases.length]);
  return (
    <S.Container>
      <TaskCard close={handleClose} width="100%">
        <header>
          <div>
            <RiClipboardLine size={21} color="var(--red-primary)" />
            <p>Task Normal</p>
          </div>

          <hr />

          <div>
            {iconPhase}
            <p>{phase.name}</p>
          </div>

          <hr />

          <div>
            <p style={{ wordBreak: 'normal', marginRight: '1rem' }}>
              Progresso
            </p>
            <div className="progressWrapper">
              <ProgressDisplay
                width="95%"
                height="1.5rem"
                borderRadius="0.2rem"
                percentage={task.progress ? task.progress : 0}
                colorBar={progressBarsColors.progressColor}
                title={progressTitle}
              />
            </div>
          </div>
        </header>

        <main style={{ display: 'flex' }}>
          <div className="contentContainer">
            <div className="input">
              <p>Nome da task</p>
              <TextInput
                name="taskName"
                onChange={handleInputChange}
                // onBlur={() => editTaskOnBlur()}
                width="100%"
                height="2.1rem"
                value={form.taskName}
                placeholder="Escreva o nome da task"
                font="400 0.9rem Work Sans"
              />
            </div>

            <div className="input">
              <p>Responsável</p>

              <Responsible users={users} setUsers={setUsers} />
            </div>

            <div className="input" onBlur={() => editTaskOnBlur()}>
              <p>Descrição da task</p>
              <RichText
                height={120}
                width="22.5rem"
                initialValue={task.desc}
                contentToSave={(contentItem: string) => {
                  setContent(contentItem);
                }}
              />
            </div>

            <div className="input" role="button" tabIndex={0}>
              <p style={{ marginBottom: '1rem' }}>Prazo da task</p>

              <Calendar date={date || null} onChange={setDate} activeRemove />
            </div>
          </div>
          <div className="contentContainer">
            <div className="input">
              <p style={{ marginBottom: '0.6rem' }}>Checklist</p>
              <Checklist
                checks={checks}
                setChecks={setChecks}
                postCheckItem={postCheckItem}
                deleteCheckItem={deleteCheckItem}
                markCheckBox={markCheckBox}
              />
            </div>

            <div className="input">
              <p>Adicionar uma Tag</p>
              <TagsCreate
                clientId={task.top_hierarchy?.client_id}
                tagsList={tagsList}
                setTagsList={setTagsList}
              />
            </div>

            <div className="input">
              <p style={{ marginBottom: '0.6rem' }}>Coments</p>
              <Coments
                comments={comments}
                setComments={setComments}
                loading={loading}
                taskId={task.id}
              />
            </div>
          </div>
        </main>

        <footer>
          <div>
            {deleting ? (
              <LoadingButton width="7rem" height="2.1rem" />
            ) : (
              <Button
                id="delete-button"
                disabled={isDisable}
                onClick={() => handleDeleteTask()}
              >
                <p>Deletar</p>
              </Button>
            )}
            {saving ? (
              <LoadingButton width="7rem" height="2.1rem" />
            ) : (
              <Button
                id="confirm-button"
                disabled={isDisable}
                onClick={() => handleEditTask()}
              >
                <p>Salvar</p>
              </Button>
            )}
            {tooltipArea && (
              <S.ButtonTooltip>
                {phases &&
                  phases.map(phaseItem => {
                    if (phaseItem.id === phase.id) {
                      return false;
                    }
                    return (
                      <div key={phaseItem.id}>
                        <button
                          type="button"
                          onClick={() => handleEditTaskNextPhase(phaseItem.id)}
                          style={{
                            opacity: `${
                              phaseItem.order! < phase.order! ? '0.5' : '1'
                            }`,
                          }}
                        >
                          {phaseItem.name}
                        </button>
                      </div>
                    );
                  })}
              </S.ButtonTooltip>
            )}
            {nextPhase ? (
              <LoadingButton width="10rem" height="2.1rem" />
            ) : (
              <Button
                id="next-phase-button"
                disabled={isDisable}
                onMouseEnter={() => handleTooltip()}
              >
                <p>Próxima fase</p>
              </Button>
            )}
          </div>
        </footer>
      </TaskCard>
    </S.Container>
  );
}

export default NormalTask;
