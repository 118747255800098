import React, { useState, useCallback } from 'react';
import Popup from 'reactjs-popup';
import { RiEditLine } from 'react-icons/ri';

import { TextInput, Button } from '../../../../../components/Inputs';

import * as S from './styles';

interface Edit {
  handleEdit: (link: string, id: number) => void;
  id: number;
}

const EditKr: React.FC<Edit> = ({ handleEdit, id }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState('');

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      setIsLoading(true);

      await handleEdit(link, id);

      setIsLoading(false);
      setOpenModal(false);
    },
    [link, handleEdit, id]
  );

  return (
    <Popup
      on="click"
      lockScroll={false}
      arrow={false}
      open={openModal}
      position="top right"
      modal
      trigger={() => {
        return (
          <div style={{ marginTop: '0.5rem' }}>
            <Button
              text="ADICIONAR LINK DA GRAVAÇÃO"
              background="var(--green-primary)"
              width="14rem"
              height="2rem"
              font="700 0.8rem Work Sans"
              className="link"
            />
          </div>
        );
      }}
      contentStyle={{
        background: 'transparent',
        width: '20rem',
        border: 0,
        borderRadius: '8px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <S.EditCard onSubmit={e => handleSubmit(e)}>
        <header>
          <RiEditLine size={20} color="var(--red-primary)" />
          <p>Adicionar Link</p>
        </header>
        <main>
          <S.Input>
            <p>Link:</p>
            <TextInput
              maxLength={255}
              required
              height="1.8rem"
              placeholder="Digite Aqui"
              value={link}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLink(e.target.value);
              }}
            />
          </S.Input>
        </main>
        <footer>
          <Button
            background="var(--gray)"
            className="midButton"
            height="1.6rem"
            text="Cancelar"
            font="400 0.9rem Work Sans"
            type="button"
            onClick={() => {
              setOpenModal(false);
            }}
          />

          <Button
            disabled={isLoading}
            background={isLoading ? 'var(--gray)' : 'var(--green-primary)'}
            width="100%"
            height="1.6rem"
            text={isLoading ? 'Carregando...' : 'Confirmar'}
            font="400 0.9rem Work Sans"
            type="submit"
          />
        </footer>
      </S.EditCard>
    </Popup>
  );
};

export default EditKr;
