import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 97%;
  height: 4rem;
  align-items: center;
  display: flex;
  margin-top: 1rem;
  border-top: 1px solid var(--gray-text);

  div {
    display: flex;
    width: 18%;
    .icon {
      margin: 0 0.5rem;
    }
  }

  #deliveries {
    width: 25%;
  }
`;
