import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.backgroundSecondary};

  strong {
    color: ${({ theme }) => theme.text};
  }
`;
export const UsersContainer = styled.div`
  display: flex;
  padding: 10px;
  > div {
    margin: 0 5px;
  }
`;
