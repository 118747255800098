import React, { useCallback, useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import apiV2 from '../../../../services/apiV2';

import { Okrs } from './types';

import NewOkr from './NewOkr';
import OkrCard from './OkrCard';

import * as S from './styles';

interface ReKrsAndOkrs {
  draftId?: number;
  clientId: number;
  presentation?: boolean;
  receiveOkrs?: React.Dispatch<React.SetStateAction<Okrs[]>>;
  predefinedOkr?: Okrs;
  savePredefinedOkr?: React.Dispatch<React.SetStateAction<Okrs>>;
}

const KrsAndOkrs: React.FC<ReKrsAndOkrs> = ({
  draftId,
  clientId,
  presentation = false,
  receiveOkrs,
  predefinedOkr,
}) => {
  const [okrs, setOkrs] = useState<Okrs[]>([]);

  const handleGetOkrs = useCallback(() => {
    apiV2.get(`okr?clientId=${clientId}&isActive=true`).then(res => {
      if (presentation) {
        res.data.sort((a: { name: string }, b: { name: string }) => {
          return a.name.localeCompare(b.name);
        });
      }

      setOkrs(res.data);
    });
  }, [clientId, presentation]);

  const handleMainOkrEdit = useCallback(
    (id: number, name: string) => {
      toast.warn('Alterando...');
      apiV2
        .put('okr', {
          id,
          name,
        })
        .then(() => {
          const itemIndex = okrs.findIndex(okr => okr.id === id);
          const reOkrs = okrs;
          reOkrs[itemIndex].name = name;
          setOkrs([...reOkrs]);
          toast.success('Alterado!');
        })
        .catch(() => {
          toast.error('Falha ao alterar Okr');
        });
    },
    [okrs]
  );

  const handleAddOkr = useCallback(
    okr => {
      const newOkr = okr;
      newOkr.krs = [];
      setOkrs([...okrs, newOkr]);
    },
    [okrs]
  );

  const handleOkrDelete = useCallback(
    id => {
      apiV2
        .delete(`okr/deleteOkr?okrId=${id}`)
        .then(() => {
          const filteredItems = okrs.filter(okr => okr.id !== id);
          setOkrs(filteredItems);
          toast.success('Okr deletada com sucesso.');
        })
        .catch(() => {
          toast.error('Erro ao deletar Okr');
        });
    },
    [okrs]
  );

  const handleOkrUpdate = useCallback(
    (okrId, newKrs, predefined = false) => {
      let okrIndex = okrs.findIndex(okr => okr.id === okrId);
      let newOkrs = okrs;
      if (predefined && predefinedOkr) {
        newOkrs = [predefinedOkr];
        okrIndex = newOkrs.findIndex(okr => okr.id === okrId);
      }

      if (newKrs !== newOkrs[okrIndex].krs) {
        newOkrs[okrIndex].krs = newKrs;
        setOkrs([...newOkrs]);
      }
    },
    [okrs, predefinedOkr]
  );

  useEffect(() => {
    handleGetOkrs();
  }, [handleGetOkrs]);

  useEffect(() => {
    if (receiveOkrs) receiveOkrs(okrs);
  }, [okrs, receiveOkrs]);

  return (
    <S.Container>
      {!presentation && (
        <NewOkr
          okrLength={okrs.length}
          clientId={clientId}
          draftId={draftId}
          addFunction={handleAddOkr}
        />
      )}

      <div className="okrs">
        {predefinedOkr && (
          <OkrCard
            i={0}
            okr={predefinedOkr}
            predefined
            handleMainOkrEdit={handleMainOkrEdit}
            handleOkrDelete={handleOkrDelete}
            handleOkrUpdate={handleOkrUpdate}
            presentation={presentation}
          />
        )}

        {okrs.map((okr, i) => {
          return (
            <OkrCard
              key={okr.id}
              i={i}
              okr={okr}
              handleMainOkrEdit={handleMainOkrEdit}
              handleOkrDelete={handleOkrDelete}
              handleOkrUpdate={handleOkrUpdate}
              presentation={presentation}
            />
          );
        })}
      </div>
    </S.Container>
  );
};

export default KrsAndOkrs;
