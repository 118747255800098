import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Loading from '../../../../../components/Loadings/CustomLoading';
import { useContracts } from '../../../../../contexts/ContractsContext';
import { getContracts } from '../../../services/GetContracts';
import { convertNumberToMoney } from '../../../utils/ConvertNumberToMoney';
import { getFirstPartner } from '../../../utils/GetFirstPartner';
import { formatContractsFee } from '../../../utils/FormatContractsFee';
import { ContractInformation } from '../../Modals/ContractInformation';
import ModalBackground from '../../Modals/ModalBackground';

import * as S from './styles';

export const ActiveContractsTable: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [
    openContractInformationModal,
    setOpenContractInformationModal,
  ] = useState(false);
  const [contractToVerify, setContractToVerify] = useState({} as Contract);

  const {
    contracts,
    setContracts,
    setActualPage,
    setTotalPages,
    actualPage,
    totalPages,
  } = useContracts();

  const handleCloseModal = (): void => {
    setOpenContractInformationModal(!openContractInformationModal);
  };

  const arrayOfTotalPages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const setContractsInfo = useCallback(
    async (status = 'active', page = 1, limit = 10) => {
      setLoading(true);
      const newContracts = await getContracts(status, page, limit);

      if (newContracts) {
        const contractsWithFormatedFee = formatContractsFee(
          newContracts.contracts
        );

        setContracts(contractsWithFormatedFee);
        setActualPage(newContracts.page);
        setTotalPages(newContracts.total_pages);
      }

      setLoading(false);
    },
    [setContracts, setActualPage, setTotalPages]
  );

  useEffect(() => {
    setContractsInfo();
  }, [setContractsInfo]);

  return (
    <>
      <S.TableBody>
        <S.TableCategories>
          <S.ColumnCategory className="status">
            <strong>Status</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="vigence">
            <strong>Vigência</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="first-payment">
            <strong>Primeiro Pagamento</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="client">
            <strong>Unidade</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="cnpj">
            <strong>CNPJ</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="legal-representative">
            <strong>Representante Legal</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="legal-representative-cpf">
            <strong>CPF Representante Legal</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="contract-value">
            <strong>Valor do Contrato</strong>
          </S.ColumnCategory>
          <hr />
          <S.ColumnCategory className="product">
            <strong>Produto</strong>
          </S.ColumnCategory>
        </S.TableCategories>
        {loading ? (
          <Loading style={{ alignSelf: 'center' }} />
        ) : (
          contracts &&
          contracts.map(contract => (
            <S.TableLine
              key={contract.id}
              onClick={() => {
                setContractToVerify(contract);
              }}
            >
              <S.ColumnLine
                className="status"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>0A0D</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="vigence"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>{contract.life_time}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="first-payment"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>
                  {format(new Date(contract.first_payment_date), 'dd/MM/yyyy', {
                    locale: ptBR,
                  }).toString()}
                </p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="client"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>{contract.unit.name}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="cnpj"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>{contract.cnpj_cpf}</p>
              </S.ColumnLine>
              <S.ColumnLine
                className="legal-representative"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>
                  {contract.partners
                    ? getFirstPartner(contract.partners).name
                    : 'Sócio legal não preenchido'}
                </p>
              </S.ColumnLine>
              <S.ColumnLine
                className="legal-representative-cpf"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>
                  {contract.partners
                    ? getFirstPartner(contract.partners).cpf
                    : 'CPF do sócio não preenchido'}
                </p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="contract-value"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>{contract.fee && convertNumberToMoney(contract.fee)}</p>
              </S.ColumnLine>
              <hr />
              <S.ColumnLine
                className="product"
                onClick={() => {
                  setOpenContractInformationModal(
                    !openContractInformationModal
                  );
                }}
              >
                <p>{contract.product ? contract.product : 'Tração'}</p>
              </S.ColumnLine>
            </S.TableLine>
          ))
        )}
        {openContractInformationModal && (
          <>
            <ModalBackground setModal={handleCloseModal} />

            <ContractInformation
              contractToVerify={contractToVerify}
              handleCloseModal={handleCloseModal}
              inactiveContract
            />
          </>
        )}
      </S.TableBody>
      {arrayOfTotalPages.length > 0 && (
        <S.Pagination>
          <FaAngleLeft onClick={() => setContractsInfo('active', 1, 10)} />
          {arrayOfTotalPages.map(page => (
            <button
              key={page}
              onClick={() => setContractsInfo('active', page, 10)}
              className={page === actualPage ? 'isActive' : ''}
              type="button"
            >
              {page}
            </button>
          ))}
          <FaAngleRight
            onClick={() =>
              setContractsInfo('active', arrayOfTotalPages.length, 10)
            }
          />
        </S.Pagination>
      )}
    </>
  );
};
