/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react';
import { OptionsSelect } from '../../../../../components/Inputs/OptionsSelect';

import * as S from '../MultaPage/styles';

export default function IuguAndManualPage({ functions }) {
  const handleClientOptions = useCallback(() => {
    const clientOptions = [];
    functions.clientList.map(theClient =>
      clientOptions.push({ name: theClient.name, value: theClient.id })
    );

    const unitOptions = [];
    functions.unitList.map(theUnit =>
      unitOptions.push({ name: theUnit.name, value: theUnit.id })
    );

    return { clientOptions, unitOptions };
  }, [functions.clientList, functions.unitList]);

  return (
    <S.Container>
      <div className="inputs">
        <div className="input">
          <label htmlFor="vencimento">Vencimento</label>
          <input
            onChange={e => {
              functions.setEndDate(e.target.value);
            }}
            value={functions.endDate}
            required
            id="vencimento"
            name="vencimento"
            type="date"
            placeholder="Data"
          />
        </div>

        <div className="input">
          <label htmlFor="sendToContaAzul">Enviar para o conta azul</label>
          <OptionsSelect
            id="sendToContaAzul"
            placeholder="Selecione"
            options={[
              { name: 'Não', value: 'no' },
              { name: 'Sim', value: 'yes' },
              { name: 'Enviado', value: 'sent' },
            ]}
            onChange={functions.setSendToContaAzul}
            defaultValue="no"
          />
        </div>

        <div className="input">
          <label htmlFor="client">Cliente</label>
          <OptionsSelect
            id="client"
            placeholder="Selecione o cliente"
            options={handleClientOptions().clientOptions}
            onChange={functions.setClient}
            search
          />
        </div>

        <div className="input">
          <label htmlFor="unit">Unidade</label>
          <OptionsSelect
            id="unit"
            placeholder="Selecione o Unidade"
            options={handleClientOptions().unitOptions}
            onChange={functions.setUnit}
            search
          />
        </div>

        <div className="input">
          <label htmlFor="value">Valor</label>
          <input
            required
            id="value"
            name="value"
            type="number"
            data-type="currency"
            placeholder="Insira o valor em n°"
            onChange={e => {
              functions.setFeeValue(e.target.value);
            }}
            value={functions.feeValue}
          />
          <p
            style={{
              position: 'absolute',
              marginTop: '60px',
              color: 'var(--gray)',
              fontSize: '12px',
              opacity: '0.7',
            }}
          >
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(!functions.feeValue ? 0 : functions.feeValue)}
          </p>
        </div>

        <div className="input">
          <label htmlFor="royaltys">Royalties</label>
          {functions.selectedPopUpButton.includes('iugu') ? (
            <input
              min="0"
              max="70"
              required
              readOnly={functions.royaltysValue === '' && !functions.unit}
              id="royaltys"
              pattern="[0-9]"
              name="royaltys"
              type="number"
              placeholder={
                functions.royaltysValue === ''
                  ? 'Selecione a unidade'
                  : 'Insira o valor em %'
              }
              onChange={e => {
                functions.setRoyaltysValue(e.target.value);
              }}
              value={functions.royaltysValue}
            />
          ) : (
            <input
              min="0"
              max="100"
              required
              id="royaltys"
              readOnly={functions.royaltysValue === '' && !functions.unit}
              pattern="[0-9]"
              name="royaltys"
              type="number"
              placeholder={
                functions.royaltysValue === ''
                  ? 'Selecione a unidade'
                  : 'Insira o valor em %'
              }
              onChange={e => {
                functions.setRoyaltysValue(e.target.value);
              }}
              value={functions.royaltysValue}
            />
          )}

          <p
            style={{
              position: 'absolute',
              marginTop: '60px',
              color: 'var(--gray)',
              fontSize: '12px',
              opacity: '0.7',
            }}
          >
            {functions.royaltysValue}%
          </p>
        </div>

        <div className="input">
          <label htmlFor="email">Emails (separar por vírugla)</label>
          <input
            id="email"
            placeholder="email@exemplo.com, email"
            value={functions.emails}
            onChange={e => {
              functions.setEmails(e.target.value);
            }}
          />
        </div>

        <div className="input">
          <label htmlFor="chargesNumber">Número de Boletos</label>
          <input
            required
            id="chargesNumber"
            name="chargesNumber"
            type="number"
            placeholder="1"
            value={functions.chargesNumber}
            onChange={e => {
              functions.setChargesNumber(e.target.value);
            }}
            pattern="[0-9]"
            max="80"
          />
        </div>

        {functions.selectedPopUpButton.includes('manual') ? (
          <>
            <div className="input">
              <label htmlFor="status">Status</label>
              <select
                value={functions.status}
                onChange={e => {
                  functions.setStatus(e.target.value);
                }}
                required
                id="status"
                name="status"
              >
                <option value="" disabled>
                  Selecione o status
                </option>
                <option value="true">Pago</option>
                <option value="false">Não Pago</option>
              </select>
            </div>
            <div className="input">
              <label htmlFor="recebimento">Recebimento</label>
              <input
                required
                id="recebimento"
                name="recebimento"
                type="date"
                placeholder="Data"
                value={functions.receivedDate}
                onChange={e => {
                  functions.setReceivedDate(e.target.value);
                }}
              />
            </div>
          </>
        ) : (
          <div />
        )}

        {functions.selectedPopUpButton.includes('matriz') ? (
          <>
            <div className="input">
              <label htmlFor="status">Status</label>
              <select
                value={functions.status}
                onChange={e => {
                  functions.setStatus(e.target.value);
                }}
                required
                id="status"
                name="status"
              >
                <option value="" disabled>
                  Selecione o status
                </option>
                <option value="true">Pago</option>
                <option value="false">Não Pago</option>
              </select>
            </div>
            <div className="input">
              <label htmlFor="recebimento">Recebimento</label>
              <input
                required
                id="recebimento"
                name="recebimento"
                type="date"
                placeholder="Data"
                value={functions.receivedDate}
                onChange={e => {
                  functions.setReceivedDate(e.target.value);
                }}
              />
            </div>
          </>
        ) : (
          <div />
        )}
      </div>
      <div className="description">
        <label htmlFor="description">Descrição</label>
        <textarea
          placeholder="Descreva a nova cobrança aqui!"
          id="description"
          name="description"
          cols="40"
          rows="5"
          value={functions.description}
          onChange={e => functions.setDescription(e.target.value)}
        />
      </div>
    </S.Container>
  );
}
