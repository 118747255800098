import React from 'react';
import { Profile } from '../../components/Profile';
import { OkrKrBoard } from './components/OkrKrBoard';
import * as S from './styles';

const OkrsKrs: React.FC = () => {
  return (
    <S.Wrapper>
      <header>
        <Profile
          buttons
          buttonLabel="Minhas Tasks"
          buttonHref="/home/tasks_board"
        />
      </header>
      <main>
        <OkrKrBoard />
      </main>
    </S.Wrapper>
  );
};

export default OkrsKrs;
