import { FC, useState, useCallback, useRef, useEffect } from 'react';

import { toast } from 'react-toastify';
import UserAvatar from '../UserAvatar';
import { Button } from '../Inputs';
import LoadingComents from './LoadingComents';

import * as S from './styles';
import apiV2 from '../../services/apiV2';
import { urlify } from '../../services/utils';
import { useAuth } from '../../contexts/AuthContext';

interface Coments {
  comments: {
    text: string;
    user_id: number;
    task_id: number;
  }[];
  setComments: React.Dispatch<
    React.SetStateAction<
      {
        text: string;
        user_id: number;
        task_id: number;
      }[]
    >
  >;
  loading: boolean;
  taskId: number;
}

const Coments: FC<Coments> = ({
  comments,
  setComments,
  loading = true,
  taskId,
}) => {
  const { id: userId } = useAuth().user;
  const [textInput, setTextInput] = useState('');

  const postComment = useCallback(async () => {
    if (textInput.length <= 0 || textInput === ' ') {
      toast.warn('Não é possível criar um comentário vazio!');
      return;
    }
    await apiV2
      .post(`task/comments`, {
        text: textInput,
        user_id: userId,
        task_id: taskId,
      })
      .then(() => {
        toast.success('Comentário enviado!');
        setComments([
          ...comments,
          {
            text: textInput,
            user_id: userId,
            task_id: taskId,
          },
        ]);
        setTextInput('');
      });
  }, [comments, setComments, taskId, textInput, userId]);

  const handleAdd = useCallback(() => {
    postComment();
  }, [postComment]);

  const messagesEndRef = (useRef(null) as unknown) as React.MutableRefObject<
    HTMLDivElement
  >;
  const scrollToBottom = useCallback(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messagesEndRef]);

  useEffect(scrollToBottom, [comments, scrollToBottom]);

  return (
    <S.Container data-testid="coments">
      <div className="commentsBox">
        {loading && <LoadingComents />}
        {!loading && (
          <>
            {comments.length !== 0 ? (
              comments.map((comment, i) => {
                return (
                  <div key={`${comment.text}`} className="comment">
                    <div className="commentData">
                      <UserAvatar id={comment.user_id} />
                      {urlify(comment.text)}
                    </div>
                    <div className="timing">
                      <S.Ball />
                      {i !== comments.length - 1 && <S.Line />}
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Sem comentários</p>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>

      <textarea
        placeholder="Faça um comentário aqui."
        value={textInput}
        onChange={e => {
          setTextInput(e.target.value);
        }}
      />
      <Button
        height="2rem"
        font="700 0.9rem Work Sans"
        id="send-button"
        width="5.57rem"
        onClick={() => handleAdd()}
      >
        <p style={{ fontWeight: 'bold' }}>Enviar</p>
      </Button>
    </S.Container>
  );
};

export default Coments;
