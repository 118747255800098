import styled from 'styled-components';

export const Container = styled.div<{ remove: boolean }>`
  background: ${({ theme }) => theme.backgroundTerciary};
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  .body {
    box-sizing: border-box;
    font-size: 0.8rem;
    text-rendering: optimizeLegibility;
    padding: 1rem;
    padding-top: 0rem;
    width: 100%;
  }
  .header {
    width: ${({ remove }) => (remove ? 'auto' : '100%')};
    background-color: ${({ theme }) => theme.backgroundTerciary};
    text-align: center;
    min-height: 2rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.text};
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${({ remove }) => (remove ? '1rem' : '0')};
  }
  .day-names {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
    width: 100%;
    background-color: ${({ theme }) => theme.backgroundTerciary};
    color: ${({ theme }) => theme.text};
    padding-top: 0.4rem;
    border-top: solid 1px rgb(205, 205, 205, 0.2);
  }
  .header .previous {
    flex: ${({ remove }) => (remove ? 0 : 1)};
    margin-right: ${({ remove }) => (remove ? '1.2rem' : '0rem')};
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header .next {
    flex: ${({ remove }) => (remove ? 0 : 1)};
    margin-left: ${({ remove }) => (remove ? '1.2rem' : '0rem')};
    cursor: pointer;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: ${({ remove }) => (remove ? 'space-between' : 'center')};
    margin: 1rem;
    padding-bottom: 0.2rem;
    margin-bottom: 0rem;
    width: 100%;
    min-height: 2rem;
    line-height: 2rem;
  }
  .removeDate {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    cursor: pointer;
  }
  .removeDate p {
    margin-left: 0.5rem;
    font-size: 0.7rem;
  }
  .icon {
    color: ${({ theme }) => theme.text};
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;

    :hover {
      opacity: 1;
    }
  }
  .week {
    background-color: white;
    width: calc(100% / 7);
    height: 44px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.backgroundTerciary};
    font-weight: 400;
    p {
      width: 35px;
      height: 35px;
      font-weight: bold;
    }
  }
  .week div {
    width: 100%;
  }
  .day {
    position: relative;
    width: calc(100% / 7);
    height: 44px;
    display: inline-block;
    background-color: transparent;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    z-index: 1;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
  }
  .day div {
    width: 35px;
    height: 35px;
    position: relative;
    color: ${({ theme }) => theme.text};
    z-index: 100;
    text-align: center;
    line-height: 35px;

    transition: 0.3s;
    cursor: pointer;

    :hover {
      opacity: 0.7;
      border-radius: 50%;
      background: var(--red-primary);
    }
  }

  .day div.blocked {
    color: var(--grey);
    opacity: 0.5;
    border-radius: 50%;
  }
  .day div.selected {
    background-color: var(--red-primary);
    color: var(--white);
    font-weight: bold;
    border-radius: 50%;
  }
  .day div.today {
    background-color: var(--gray);
    border-radius: 50%;
    color: var(--white);
  }
  .day div.before {
    opacity: 0.5;
    color: var(--white);
  }
`;
