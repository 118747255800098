import React from 'react';
import Chart from 'react-apexcharts';
import { format } from 'date-fns';

import { Props } from 'react-apexcharts';
import getDefaultOptions from '../../../../../../styles/defaultChartStyles';
import * as S from './styles';
import { DashData } from '../../../../types';

interface InvestChart {
  dashData: DashData;
}

const InvestmentChart: React.FC<InvestChart> = ({ dashData }) => {
  const labels: string[] = [];
  const values: { name: string; data: number[] }[] = [
    { name: 'Investimentos', data: [] },
  ];

  dashData.investment.investments.forEach((investmentItem, i) => {
    values[0].data.push(Number(investmentItem.toFixed(2)));
    labels.push(format(new Date(dashData.investment.dates[i]), 'dd/MM'));
  });

  const defaultOptions = getDefaultOptions({});

  const options: Props['ApexOptions'] = {
    ...defaultOptions,
    xaxis: {
      categories: labels,
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
  };

  return (
    <S.Container>
      <h1>Investimento</h1>

      <Chart options={options} series={values} type="area" width="100%" />
    </S.Container>
  );
};

export default InvestmentChart;
