import React, { useState } from 'react';
import { RiBubbleChartLine } from 'react-icons/ri';

import { Button, TextInput } from '../../../../../components/Inputs';
import { Container } from './styles';
import TaskCard from '../../../../../components/PopUp/TaskCard';
import RichText from '../../../../../components/RichText';
import Responsible from '../../../../../components/Responsible';
import { Modal } from '../../../styles';
import Preview from '../Preview';

interface ImportPlaybookProps {
  handleClose: () => void;
}

function ImportPlaybook({ handleClose }: ImportPlaybookProps) {
  const [users, setUsers] = useState([{}]);
  const [content, setContent] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'third-modal') handleOpenModal();
  };

  return (
    <Container>
      <TaskCard close={handleClose}>
        <header>
          <RiBubbleChartLine size={21} color="var(--red-primary)" />
          <p>Importar Playbook</p>
        </header>
        <main>
          <div className="input">
            <p>Playbook </p>
            <TextInput
              width="100%"
              height="2.1rem"
              placeholder="Selecione o playbook"
              font="400 0.9rem Work Sans"
            />
          </div>

          <div className="input">
            <p>Responsável</p>

            <Responsible users={users} setUsers={setUsers} />
          </div>

          <div className="input">
            <p>Descrição do playbook</p>
            <RichText
              height={200}
              width={282}
              initialValue={content}
              contentToSave={(value: string) => {
                setContent(value);
              }}
            />
          </div>

          <footer>
            <div style={{ display: 'flex' }}>
              <Button
                background="var(--gray)"
                width="70%"
                height="2.1rem"
                className="initialButton"
                font="400 0.9rem Work Sans"
              >
                <p>Voltar</p>
              </Button>
              <Button
                background="var(--red-primary)"
                width="100%"
                height="2.1rem"
                font="400 0.9rem Work Sans"
                onClick={handleOpenModal}
              >
                <p>Abrir Preview</p>
              </Button>
            </div>
            <div style={{ marginTop: '0.8rem' }}>
              <Button
                background="var(--green-primary)"
                width="100%"
                height="2.1rem"
                font="400 0.9rem Work Sans"
              >
                <p>Importar</p>
              </Button>
            </div>
          </footer>
        </main>
      </TaskCard>
      {openModal && (
        <Modal id="third-modal" onClick={handleOutsideClick}>
          <Preview handleClose={handleCloseModal} />
        </Modal>
      )}
    </Container>
  );
}

export default ImportPlaybook;
