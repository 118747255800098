import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FaRegEdit } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { useTasks } from '../../../../../../contexts/TasksContext';
import useProgress from '../../../../../../hooks/useProgress';
import ProjectEdit from '../../../../components/Modals/ProjectEdit';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { Responsibles } from '../../../../components/Responsibles';
import { Modal } from '../../../../styles';
import * as S from './styles';
import selectProjectIndexById from '../../../../utils/SelectProjectIndexById';

export function Infos() {
  const { progress, colors } = useProgress();
  const { projects } = useTasks();
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { id, projectId } = useParams<TasksParams>();

  const projectIndex = selectProjectIndexById(+projectId, projects);

  const periodProgressPercent = useMemo(
    () =>
      progress(
        projects[projectIndex].created_at,
        projects[projectIndex].due_date
      ),
    [progress, projects, projectIndex]
  );

  const periodProgressTitle = useMemo(
    () =>
      projects[projectIndex].due_date
        ? format(new Date(projects[projectIndex].due_date), 'dd.MMMM.yyyy', {
            locale: ptBR,
          }).toString()
        : 'Sem prazo.',
    [projects, projectIndex]
  );

  const progressBarsColors = useMemo(
    () =>
      colors(
        projects[projectIndex].due_date,
        projects[projectIndex].finished_at
      ),
    [colors, projects, projectIndex]
  );

  const progressTitle = useMemo(
    () =>
      `${
        projects[projectIndex].progress
          ? Math.round(projects[projectIndex].progress * 100) / 100
          : 0
      }%`,
    [projects, projectIndex]
  );

  const handleOpenModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') handleOpenModal();
  };

  const handleSelectProject = (idOfProject: string) => {
    history.push(`/tasks/${id}/kanban/projects/${idOfProject}`);
  };

  return (
    <>
      <S.Wrapper>
        <S.Container id="name">
          <h4>Projeto/Canal/Estratégia/Loop</h4>
          <p>{projects[projectIndex].name}</p>
        </S.Container>
        <button type="button" onClick={handleOpenModal}>
          <FaRegEdit size={16} id="edit-button" />
        </button>
        <span>|</span>
        <S.Container id="responsible">
          <Responsibles users={projects[projectIndex].users} />
        </S.Container>
        <span>|</span>
        <S.Container id="period">
          <ProgressDisplay
            width="99%"
            height="1.45rem"
            borderRadius="0.3rem"
            percentage={periodProgressPercent}
            title={periodProgressTitle}
            colorBar={progressBarsColors.dueDateColor}
          />
        </S.Container>
        <span>|</span>
        <S.Container id="progress">
          <ProgressDisplay
            width="99%"
            height="1.45rem"
            borderRadius="0.3rem"
            title={progressTitle}
            percentage={projects[projectIndex].progress}
            colorBar={progressBarsColors.progressColor}
          />
        </S.Container>
        <span>|</span>
        <S.Container id="project">
          <select onChange={e => handleSelectProject(e.target.value)}>
            {projects &&
              projects.map(projectOption => {
                return (
                  <option key={projectOption.id} value={projectOption.id}>
                    {projectOption.name}
                  </option>
                );
              })}
          </select>
        </S.Container>
      </S.Wrapper>
      {openModal && (
        <Modal id="modal" onClick={handleOutsideClick}>
          <ProjectEdit
            project={projects[projectIndex]}
            handleClose={handleCloseModal}
          />
        </Modal>
      )}
    </>
  );
}
