import styled from 'styled-components';

interface ProjectProps {
  cardsArea: boolean;
}

export const Wrapper = styled.div`
  display: flex;

  width: 100%;
  height: 93vh;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  overflow-y: hidden;

  @media (max-width: 880px) {
    overflow: auto;
    height: auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundPrimary};

  hr {
    margin-bottom: 0.13rem;
    border: ${({ theme }) => theme.backgroundSecondary} 1px solid;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
`;

export const TopBar = styled.div`
  width: 100%;
  padding: 0.5rem 0 0 0;
  height: auto;
`;

export const Content = styled.div<ProjectProps>`
  width: 100%;
  display: flex;
  min-height: 0;
  height: ${props => (props.cardsArea ? '73%' : '85%')};

  @media (max-width: 1367px) {
    height: ${props => (props.cardsArea ? '65%' : '78%')};
  }
`;

export const Button = styled.button`
  position: fixed;
  bottom: 5rem;
  right: 2.8rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: var(--red-primary);
  border: none;
  color: var(--white);
`;

export const Modal = styled.div`
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  background-color: rgb(0, 0, 0, 0.3);
  cursor: initial;
`;
