import styled from 'styled-components';

export const Container = styled.div`
  background: #1a2024;
  border-radius: 3px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  p {
    font-size: 16px;
    font-weight: bold;
    color: #f6f6f6;
    margin-top: 20px;
  }
`;

export const ChartContainer = styled.div`
  flex: 0;
  padding: 20px;
  position: relative;

  .inside {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: calc(0.2 * 22vh);
    margin-left: calc(0.2 * 0.5vh);
    width: calc(0.2 * 90vw);
    text-align: center;
    cursor: default;

    p {
      margin-top: 5px;
      font-size: 15px;

      :nth-child(2) {
        color: #adadad;
        font-size: 10px;
      }
    }

    @media (max-width: 1400px) {
      margin-top: calc(0.2 * 20vh);

      p {
        font-size: 9px;

        :nth-child(2) {
          color: #adadad;
          font-size: 7px;
        }
      }
    }
  }
`;
