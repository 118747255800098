import React, { useMemo, useState } from 'react';
import { FiCheck } from 'react-icons/fi';

import { differenceInHours } from 'date-fns';
import Avatar from 'react-avatar';
import { useAuth } from '../../../../../../contexts/AuthContext';
import {
  Container,
  Header,
  Urgency,
  What,
  Footer,
  Assigns,
  Checks,
} from './styles';
import ReplyTicket from '../replyTicket';

function Item({ ticket, getTickets, getPosts, user = false }) {
  const [reply, setReply] = useState(false);
  const { id: userId } = useAuth().user;

  const { priority, dueDate } = useMemo(() => {
    if (ticket.priority === 1) {
      return {
        priority: 'Prioridade 1',
        dueDate: ticket.open
          ? differenceInHours(
              new Date(ticket.due_date),
              new Date(ticket.createdAt)
            )
          : differenceInHours(
              new Date(ticket.finished_at),
              new Date(ticket.createdAt)
            ),
      };
    }
    if (ticket.priority === 2) {
      return {
        priority: 'Prioridade 2',
        dueDate: ticket.open
          ? differenceInHours(
              new Date(ticket.due_date),
              new Date(ticket.createdAt)
            )
          : differenceInHours(
              new Date(ticket.finished_at),
              new Date(ticket.createdAt)
            ),
      };
    }
    return {
      priority: 'Prioridade 3',
      dueDate: ticket.open
        ? differenceInHours(
            new Date(ticket.due_date),
            new Date(ticket.createdAt)
          )
        : differenceInHours(
            new Date(ticket.finished_at),
            new Date(ticket.createdAt)
          ),
    };
  }, [
    ticket.createdAt,
    ticket.due_date,
    ticket.finished_at,
    ticket.open,
    ticket.priority,
  ]);

  return (
    <>
      <Container
        onClick={() => setReply(true)}
        user={user}
        vote={ticket.vote}
        open={ticket.open}
      >
        <Header>
          {!user && (
            <Urgency dueDate={dueDate} open={ticket.open}>
              <div id="priority">
                <p>{priority}</p>
              </div>
              <div id="due_date">{`${dueDate}h`}</div>
            </Urgency>
          )}
          <What category={ticket.category}>
            <div id="category">
              <p>{ticket.category.toUpperCase()}</p>
            </div>
            <div id="subcategory">
              <p>{ticket.subcategory.toUpperCase()}</p>
            </div>
          </What>
        </Header>
        <h1>{ticket.name}</h1>
        <p>{ticket.desc}</p>
        <Footer>
          <Assigns
            isUser={ticket.assign && ticket.assign.id === userId}
            isSecond={ticket.secondAssign && ticket.secondAssign.id === userId}
          >
            {ticket.assign && (
              <Avatar
                name={`${ticket.assign.name} ${ticket.assign.last_name}`}
                src={ticket.assign.avatar && ticket.assign.avatar.url}
                className="avatar first"
                color="var(--gray)"
                key={ticket.assign.id}
              />
            )}
            {ticket.secondAssign && (
              <Avatar
                name={`${ticket.secondAssign.name} ${ticket.secondAssign.last_name}`}
                src={
                  ticket.secondAssign.avatar && ticket.secondAssign.avatar.url
                }
                className="avatar second"
                color="var(--gray)"
                key={ticket.secondAssign.id}
              />
            )}
          </Assigns>
          <Checks>
            <FiCheck
              id="support"
              color={!ticket.support_open && 'var(--green-primary)'}
            />
            <FiCheck id="user" color={!ticket.open && 'var(--green-primary)'} />
          </Checks>
        </Footer>
      </Container>
      {reply && (
        <ReplyTicket
          active={setReply}
          ticket={ticket}
          getTickets={getTickets}
          getPosts={getPosts}
          user={user}
        />
      )}
    </>
  );
}

export default Item;
