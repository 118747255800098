/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useCallback } from 'react';

import { toast } from 'react-toastify';
import { removeEmpty } from '../../../../services/utils';
import { Form } from './styles';
import { TextInput, Select } from '../../../../components/Inputs';
import { OptionsSelect } from '../../../../components/Inputs/OptionsSelect';

import AvatarInput from './AvatarInput';
import api from '../../../../services/api';

export default function Client({ client }) {
  const [object, setObject] = useState(client);
  const [file, setFile] = useState(client?.avatar && client?.avatar.id);
  const [units, setUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);

  useEffect(() => {
    api.get('units?active=true').then(res => {
      const clientUnits = [];
      const responseUnits = res.data.map(unit => {
        return { name: unit.name, value: unit.id };
      });

      client?.units.forEach(unit => {
        responseUnits.forEach(item => {
          if (item.value === unit.id) {
            clientUnits.push(item.value);
          }
        });
      });

      setUnits(responseUnits);
      if (clientUnits?.length > 0) setSelectedUnits(clientUnits);
    });
  }, [client?.units]);

  useEffect(() => {
    setObject(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      await api
        .put(`/clients`, removeEmpty(object))
        .then(() => {
          toast.success('Cliente atualizado!');
        })
        .catch(() => {
          toast.error('Algo deu errado, por favor, atualize a página!');
        });

      return true;
    },
    [object]
  );

  const handleChangeUnit = useCallback(
    e => {
      setSelectedUnits(e);
      setObject({ ...object, units_id: e });
    },
    [object]
  );

  const cnpjMask = useCallback(value => {
    let string = value || '';

    string = string.replace(/\D/g, '');
    string = string.replace(/(\d{2})(\d)/, '$1.$2');
    string = string.replace(/(\d{3})(\d)/, '$1.$2');
    string = string.replace(/(\d{3})(\d)/, '$1/$2');
    string = string.replace(/(\d{4})(\d)/, '$1-$2');

    return string;
  }, []);

  const zipCodeMask = useCallback(value => {
    let string = value || '';

    string = string.replace(/[^0-9\.]+/g, '');

    return string;
  }, []);

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;
      setObject({ ...object, [name]: value });
    },
    [object]
  );
  return (
    <Form onSubmit={handleSubmit}>
      <AvatarInput
        name="avatar_id"
        clientName={client?.name}
        avatar={client?.avatar}
        file={file}
        clientId={client?.id}
        setFile={setFile}
        className="avatar"
      />
      <label htmlFor="name">
        <strong>Nome:</strong>
        <TextInput
          id="name"
          value={object?.name}
          name="name"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="units">
        Unidades com acesso:
        <OptionsSelect
          id="units"
          onChange={handleChangeUnit}
          name="units"
          value={selectedUnits}
          options={units}
          search
          multiple
          printOptions="on-focus"
        />
      </label>{' '}
      <label htmlFor="hyperflow_emails">
        Enviar E-mails?
        <Select
          id="hyperflow_emails"
          onChange={e => handleChange(e)}
          name="hyperflow_emails"
          value={object?.hyperflow_emails}
          options={[
            { key: 1, value: true, name: 'Sim' },
            { key: 2, value: false, name: 'Não' },
          ]}
        />
      </label>
      <label htmlFor="active">
        O Cliente está ativo?
        <Select
          id="active"
          onChange={e => handleChange(e)}
          name="active"
          value={object?.active}
          options={[
            { key: 1, value: true, name: 'Sim' },
            { key: 2, value: false, name: 'Não' },
          ]}
        />
      </label>
      <label htmlFor="social_name">
        <strong>Razão Social:</strong>
        <TextInput
          id="social_name"
          value={object?.social_name}
          name="social_name"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="entity">
        <strong>Regime jurídico:</strong>
        <Select
          id="entity"
          onChange={e => handleChange(e)}
          name="entity"
          value={object?.entity}
          options={[
            { key: 1, value: true, name: 'Pessoa Jurídica' },
            { key: 2, value: false, name: 'Pessoa Física' },
          ]}
        />
      </label>
      <label htmlFor="cnpj">
        <strong>CNPJ:</strong>
        <TextInput
          id="cnpj"
          value={object?.cnpj}
          name="cnpj"
          onChange={e =>
            setObject({ ...object, cnpj: cnpjMask(e.target.value) })
          }
          maxLength={18}
          minLength={18}
        />
      </label>
      <label htmlFor="stakeholder_name">
        <strong>Nome do Stakeholder:</strong>
        <TextInput
          id="stakeholder_name"
          value={object?.stakeholder_name}
          name="stakeholder_name"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="stakeholder_email">
        <strong>E-mail do Stakeholder:</strong>
        <TextInput
          id="stakeholder_email"
          value={object?.stakeholder_email}
          name="stakeholder_email"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="stakeholder_phone">
        <strong>Telefone do Stakeholder:</strong>
        <TextInput
          id="stakeholder_phone"
          value={object?.stakeholder_phone}
          name="stakeholder_phone"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="partner_name">
        <strong>Nome do Sócio que assinou o contrato:</strong>
        <TextInput
          id="partner_name"
          value={object?.partner_name}
          name="partner_name"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="partner_cpf">
        <strong>CPF do Sócio que assinou o contrato:</strong>
        <TextInput
          id="partner_cpf"
          value={object?.partner_cpf}
          name="partner_cpf"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="financial_name">
        <strong>Nome do Financeiro:</strong>
        <TextInput
          id="financial_name"
          value={object?.financial_name}
          name="financial_name"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="financial_email">
        <strong>E-mail do Financeiro:</strong>
        <TextInput
          id="financial_email"
          value={object?.financial_email}
          name="financial_email"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="financial_phone">
        <strong>Telefone do Financeiro:</strong>
        <TextInput
          id="financial_phone"
          value={object?.financial_phone}
          name="financial_phone"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="zip_code">
        <strong>CEP:</strong>
        <TextInput
          id="zip_code"
          value={object?.zip_code}
          name="zip_code"
          onChange={e =>
            setObject({ ...object, zip_code: zipCodeMask(e.target.value) })
          }
          pattern="[0-9]+"
          maxLength={8}
          minLength={8}
        />
      </label>
      <label htmlFor="state">
        <strong>Estado:</strong>
        <Select
          id="state"
          onChange={e => handleChange(e)}
          name="state"
          value={object?.state}
          options={[
            { key: 1, value: 'Acre (AC)', name: 'Acre (AC)' },
            { key: 2, value: 'Alagoas (AL)', name: 'Alagoas (AL)' },
            { key: 3, value: 'Amapá (AP)', name: 'Amapá (AP)' },
            { key: 4, value: 'Amazonas (AM)', name: 'Amazonas (AM)' },
            { key: 5, value: 'Bahia (BA)', name: 'Bahia (BA)' },
            { key: 6, value: 'Ceará (CE)', name: 'Ceará (CE)' },
            {
              key: 7,
              value: 'Distrito Federal (DF)',
              name: 'Distrito Federal (DF)',
            },
            {
              key: 8,
              value: 'Espírito Santo (ES)',
              name: 'Espírito Santo (ES)',
            },
            { key: 9, value: 'Goiás (GO)', name: 'Goiás (GO)' },
            { key: 10, value: 'Maranhão (MA)', name: 'Maranhão (MA)' },
            { key: 11, value: 'Mato Grosso (MT)', name: 'Mato Grosso (MT)' },
            {
              key: 12,
              value: 'Mato Grosso do Sul (MS)',
              name: 'Mato Grosso do Sul (MS)',
            },
            {
              key: 13,
              value: 'Minas Gerais (MG)',
              name: 'Minas Gerais (MG)',
            },
            { key: 14, value: 'Pará (PA)', name: 'Pará (PA)' },
            { key: 15, value: 'Paraíba (PB)', name: 'Paraíba (PB)' },
            { key: 16, value: 'Paraná (PR)', name: 'Paraná (PR)' },
            { key: 17, value: 'Pernambuco (PE)', name: 'Pernambuco (PE)' },
            { key: 18, value: 'Piauí (PI)', name: 'Piauí (PI)' },
            {
              key: 19,
              value: 'Rio de Janeiro (RJ)',
              name: 'Rio de Janeiro (RJ)',
            },
            {
              key: 20,
              value: 'Rio Grande do Norte (RN)',
              name: 'Rio Grande do Norte (RN)',
            },
            {
              key: 21,
              value: 'Rio Grande do Sul (RS)',
              name: 'Rio Grande do Sul (RS)',
            },
            { key: 22, value: 'Rondônia (RO)', name: 'Rondônia (RO)' },
            { key: 23, value: 'Roraima (RR)', name: 'Roraima (RR)' },
            {
              key: 24,
              value: 'Santa Catarina (SC)',
              name: 'Santa Catarina (SC)',
            },
            { key: 25, value: 'São Paulo (SP)', name: 'São Paulo (SP)' },
            { key: 26, value: 'Sergipe (SE)', name: 'Sergipe (SE)' },
            { key: 27, value: 'Tocantins (TO)', name: 'Tocantins (TO)' },
          ]}
        />
      </label>
      <label htmlFor="city">
        <strong>Cidade:</strong>
        <TextInput
          id="city"
          value={object?.city}
          name="city"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="address">
        <strong>Endereço:</strong>
        <TextInput
          id="address"
          value={object?.address}
          name="address"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="address_number">
        <strong>Numero:</strong>
        <TextInput
          id="address_number"
          value={object?.address_number}
          name="address_number"
          onChange={handleChange}
          placeholder="digite aqui"
        />
      </label>
      <label htmlFor="fee_value">
        <strong>Valor do fee:</strong>
        <TextInput
          id="fee_value"
          value={object?.fee_value}
          name="fee_value"
          onChange={handleChange}
          placeholder="digite aqui"
        />
      </label>
      <label htmlFor="pipefy_project_id">
        <strong>Id do card central de projetos:</strong>
        <TextInput
          placeholder="digite aqui"
          id="pipefy_project_id"
          value={object?.pipefy_project_id}
          name="pipefy_project_id"
          onChange={handleChange}
        />
      </label>
      <label htmlFor="observation">
        <strong>Observação 📝:</strong>
        <textarea
          placeholder="digite aqui"
          style={{
            background: '#161b22',
            color: 'white',
            padding: '0.5rem',
            borderRadius: '0.5rem',
            fontFamily: 'Open Sans',
            border: 'none',
            height: '10rem',
          }}
          id="observation"
          value={object?.observation}
          name="observation"
          onChange={handleChange}
        />
      </label>
      <button type="submit">Atualizar Cliente</button>
    </Form>
  );
}
