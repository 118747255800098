import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.text};

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: var(--red-primary);
  }

  .commentsBox {
    margin-top: 1rem;
    max-height: 20rem;
    width: 100%;
    overflow-y: auto;
    padding: 0.3rem;

    ::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.text};
    }
  }

  .commentsBox .avatar {
    margin-right: 1rem;
  }

  .comment {
    display: flex;
    justify-content: space-between;
  }

  .comment p {
    margin-bottom: 1rem;
  }

  .commentData {
    display: flex;
    margin: 0.5rem 0;
  }

  .timing {
    margin-left: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: normal;
  }

  .timing .time {
    margin-bottom: 0.2rem;
    color: var(--gray);
  }

  button {
    margin-top: 0.8rem;
    float: right;
  }

  #send-button {
    background-color: ${({ theme }) => theme.backgroundPrimary} !important;
    color: ${({ theme }) => theme.text} !important;
  }
`;

export const Ball = styled.div`
  min-height: 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--gray);
`;

export const Line = styled.div`
  border: 0;
  border-right: 0.2rem;
  min-height: 0.4rem;
  height: 200%;
  border-style: dotted;
  border-color: var(--gray);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const LoadingAvatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: var(--gray);
`;
