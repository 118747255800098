import { FC, useState, useCallback, useEffect, useMemo } from 'react';

import { format } from 'date-fns';
import { History } from 'history';
import { Search } from '../../../../components/Search';
import LabTable from '../../../../components/LabTable';
import api from '../../../../services/api';
import * as S from './styles';

interface UnitClientsProps {
  id: number;
  history: History<unknown>;
}

type Client = {
  id: string;
  name: string;
  stakeholder_name: string;
  stakeholder_email: string;
  stakeholder_phone: string;
  active: boolean;
  social_name: string;
  cnpj: string;
  partner_name: string;
  partner_cpf: string;
  financial_name: string;
  financial_email: string;
  financial_phone: string;
  state: string;
  city: string;
  address: string;
  createdAt: string;
  canceled_at: string;
};

const UnitClients: FC<UnitClientsProps> = ({ id, history }) => {
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const getClients = useCallback(async () => {
    await api
      .get(`clients?unit=${id}&active=all`)
      .then(res => setClients(res.data));
  }, [id]);

  const handleRowClick = useCallback(
    e => {
      history.push(`/client/profile/${e.id}`);
    },
    [history]
  );

  const table = useMemo(() => {
    const response = clients
      .filter(
        (client: Client) =>
          (client.name &&
            client.name.toLowerCase().includes(search.toLowerCase())) ||
          (client.stakeholder_name &&
            client.stakeholder_name
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (client.stakeholder_email &&
            client.stakeholder_email
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (client.stakeholder_phone &&
            client.stakeholder_phone
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (client.partner_name &&
            client.partner_name.toLowerCase().includes(search.toLowerCase())) ||
          (client.financial_name &&
            client.financial_name
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (client.financial_email &&
            client.financial_email
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (client.social_name &&
            client.social_name.toLowerCase().includes(search.toLowerCase())) ||
          (client.state &&
            client.state.toLowerCase().includes(search.toLowerCase())) ||
          (client.city &&
            client.city.toLowerCase().includes(search.toLowerCase()))
      )
      .map((client: Client) => {
        return {
          id: client.id,
          name: client.name,
          stakeholder_name: client.stakeholder_name,
          stakeholder_email: client.stakeholder_email,
          stakeholder_phone: client.stakeholder_phone,
          active: client.active ? 'Sim' : 'Não',
          social_name: client.social_name,
          cnpj: client.cnpj,
          partner_name: client.partner_name,
          partner_cpf: client.partner_cpf,
          financial_name: client.financial_name,
          financial_email: client.financial_email,
          financial_phone: client.financial_phone,
          state: client.state,
          city: client.city,
          address: client.address,
          createdAt: format(new Date(client.createdAt), 'dd/MM/yyyy'),
          canceledAt: client.canceled_at
            ? format(new Date(client.canceled_at), 'dd/MM/yyyy')
            : null,
        };
      });
    return response;
  }, [clients, search]);

  useEffect(() => {
    getClients();
  }, [getClients]);

  return (
    <S.Container>
      <Search
        placeholder="Pesquisar unidades..."
        setSearch={setSearch}
        search={search}
      />
      <S.TableWrapper>
        {table.length > 0 && (
          <LabTable contents={table} rowClick={handleRowClick} />
        )}
      </S.TableWrapper>
    </S.Container>
  );
};

export default UnitClients;
