import { FC, useCallback, useState, useEffect } from 'react';
import api from '../../../../services/api';
import { Search } from '../../../../components/Search';
import UserCard from '../UserCard';

import * as S from './styles';

type Role = {
  id: number;
  role: string;
};

type User = {
  id: number;
  name: string;
  last_name: string;
  roles: Role[];
  active_token: string;
  avatar: {
    url: string;
  };
};

interface UnitUsersProps {
  id: number;
  isAdmin: boolean;
}

const UnitUsers: FC<UnitUsersProps> = ({ id, isAdmin }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [search, setSearch] = useState('');

  const getUsers = useCallback(async () => {
    await api.get(`units/${id}`).then(res => setUsers(res.data.users));
  }, [id]);

  const removeUser = useCallback(
    userId => {
      const filteredUsers = users.filter(user => user.id !== userId);
      setUsers(filteredUsers);
    },
    [users]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <S.Container>
      <Search
        setSearch={setSearch}
        search={search}
        title="Buscar Usuário"
        placeholder="Digite o nome do Usuário.."
      />
      <S.Cards>
        {users
          .filter(user => {
            const name = `${user.name} ${user.last_name}`;

            return name.toLowerCase().includes(search.toLowerCase());
          })
          .map(user => (
            <UserCard
              user={user}
              key={user.id}
              isAdmin={isAdmin}
              removeUser={removeUser}
            />
          ))}
      </S.Cards>
    </S.Container>
  );
};

export default UnitUsers;
