/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { MdSkipPrevious, MdSkipNext } from 'react-icons/md';
import {
  Container,
  Search,
  Filters,
  SuggestionsWrapper,
  Input,
  Controll,
} from './styles';
import api from '../../services/api';
import SuggestionOption from './components/Option';
import NewSuggestion from './components/NewSuggestion';

function Suggestions() {
  const [search, setSearch] = useState('');
  const [best, setBest] = useState(true);
  const [status, setStatus] = useState('all');
  const [suggestions, setSuggestions] = useState([]);
  const [newSuggestion, setNewSuggestion] = useState(false);
  const [show, setShow] = useState(5);
  const [isAdmin, setIsAdmin] = useState(false);

  const getSuggestions = useCallback(async () => {
    await api.get('/suggestions').then(res => setSuggestions(res.data));
  }, []);

  const userRoles = useCallback(async () => {
    await api.get('/roles').then(res => {
      let isAdm = 0;
      res.data.forEach(role => {
        if (role.role === 'admin' || role.role === 'tech') {
          isAdm += 1;
        }
      });
      if (isAdm === 2) {
        setIsAdmin(true);
      }
    });
  }, []);

  useEffect(() => {
    getSuggestions();
    userRoles();
  }, [getSuggestions, userRoles]);

  const suggestionsLength = useMemo(
    () =>
      suggestions
        .filter(suggestion =>
          suggestion.name.toLowerCase().includes(search.toLowerCase())
        )
        .filter(suggestion => {
          if (status === 'all') {
            return status !== 'done' && status !== 'reproved';
          }
          return suggestion.status === status;
        }).length,
    [suggestions, status, search]
  );

  return (
    <Container>
      <Search>
        <h1>Como podemos melhorar o mkt.lab?</h1>
        <Input>
          <input
            type="text"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Pesquisar sugestão ou adicionar nova..."
          />
          {search.length > 2 && (
            <button type="button" onClick={() => setNewSuggestion(true)}>
              Não encontrou? Criar nova!
            </button>
          )}
        </Input>
      </Search>
      <Filters>
        <input
          type="radio"
          id="best"
          name="order"
          value="best"
          checked={best}
          onClick={() => setBest(true)}
        />
        <label htmlFor="best">Melhores</label>
        <input
          type="radio"
          id="newer"
          name="order"
          value="newer"
          checked={!best}
          onClick={() => setBest(false)}
        />
        <label htmlFor="newer">Mais novas</label>
        <select
          name="status"
          onChange={e => setStatus(e.target.value)}
          defaultValue="0"
        >
          <option value="0" disabled>
            Status
          </option>
          <option value="all">Todos</option>
          <option value="open">Abertos</option>
          <option value="backlog">Backlog</option>
          <option value="dev">Desenvolvendo</option>
          <option value="review">Em análise</option>
          <option value="done">Finalizado</option>
          <option value="reproved">Reprovado</option>
        </select>
      </Filters>
      <SuggestionsWrapper>
        {suggestions
          .filter(suggestion =>
            suggestion.name.toLowerCase().includes(search.toLowerCase())
          )
          .filter(suggestion => {
            if (status === 'all') {
              return status !== 'done' && status !== 'reproved';
            }
            return suggestion.status === status;
          })
          .sort((a, b) => {
            if (best) {
              return a.likes - a.dislikes > b.likes - b.dislikes ? -1 : 1;
            }
            return a.createdAt > b.createdAt ? -1 : 1;
          })
          .map((suggestion, i) => {
            if (i < show && i >= show - 5) {
              return (
                <SuggestionOption
                  suggestion={suggestion}
                  getSuggestions={getSuggestions}
                  isAdmin={isAdmin}
                  key={suggestion.id}
                />
              );
            }
            return false;
          })}
        <Controll>
          <div>
            {show - 5 > 0 ? (
              <MdSkipPrevious size={26} onClick={() => setShow(show - 5)} />
            ) : (
              <MdSkipPrevious size={26} style={{ color: 'var(--gray)' }} />
            )}
            {show > suggestionsLength
              ? `${show - 4} - ${show -
                  (show - suggestionsLength)} de ${suggestionsLength}`
              : `${show - 4} - ${show} de ${suggestionsLength}`}
            {show < suggestionsLength ? (
              <MdSkipNext size={26} onClick={() => setShow(show + 5)} />
            ) : (
              <MdSkipNext size={26} style={{ color: 'var(--gray)' }} />
            )}
          </div>
        </Controll>
      </SuggestionsWrapper>
      {newSuggestion && (
        <NewSuggestion
          active={setNewSuggestion}
          title={search}
          setSearch={setSearch}
          getSuggestions={getSuggestions}
        />
      )}
    </Container>
  );
}

export default Suggestions;
