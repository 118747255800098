import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function editGroup(
  groupId: number,
  groupName = '',
  groupDescription = '',
  tagsIds: number[]
): Promise<Group> {
  try {
    const response = await apiV2.put(`groups`, {
      include_project_group_progress: true,
      id: groupId,
      name: groupName,
      desc: groupDescription,
      tags_ids: tagsIds,
    });

    const { group } = response.data;

    return group;
  } catch (err) {
    toast.error(
      'Ocorreu algum problema ao editar o grupo, atualize a página e tente novamente 😕'
    );
    return {} as Group;
  }
}
