import styled from 'styled-components';

export const Wrapper = styled.button`
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray) !important;
  border: 1px solid var(--red-primary) !important;
  font-size: 0;
`;
