import { FC, Dispatch, SetStateAction } from 'react';
import { MdSkipPrevious, MdSkipNext } from 'react-icons/md';

import * as S from './styles';

interface PageControllerProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  start: number;
  finish: number;
  count: number;
}

const PageController: FC<PageControllerProps> = ({
  page,
  setPage,
  start,
  finish,
  count,
}) => {
  return (
    <S.Container>
      {page - 1 > 0 ? (
        <MdSkipPrevious size={26} onClick={() => setPage(page - 1)} />
      ) : (
        <MdSkipPrevious size={26} />
      )}
      {`${start} - ${finish} de ${count}`}
      {finish < count ? (
        <MdSkipNext size={26} onClick={() => setPage(page + 1)} />
      ) : (
        <MdSkipNext size={26} />
      )}
    </S.Container>
  );
};

export default PageController;
