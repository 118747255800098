import styled from 'styled-components';

export const Container = styled.div`
  width: 61.94rem;
  max-height: 90vh;
  word-break: break-all;
  position: fixed;
  top: 3rem;
  color: ${({ theme }) => theme.text};

  header div {
    display: flex;
    width: 28%;
    :last-child {
      width: 21rem;
    }
  }

  header p {
    font-size: 1rem;
  }

  header hr {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 1rem;
    border: solid 1px var(--gray);
  }

  header {
    .progressWrapper {
      width: 8rem;
      div {
        min-width: 0%;
        max-width: 100%;
      }
    }
  }

  .progress {
    width: 100%;
    color: ${({ theme }) => theme.text};
  }

  main {
    display: flex;
  }

  .contentContainer {
    width: 50rem;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    overflow: auto;

    ::-webkit-scrollbar {
      height: 0.4rem;
      border-radius: 0.4rem;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.backgroundSecondary};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--gray);
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.text};
    }
  }
  .contentContainer:first-child {
    width: 35rem;
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px solid var(--gray);

    div:first-child {
      margin-bottom: 0rem;
    }
  }

  footer div {
    display: flex;
    justify-content: space-between;
    width: 45%;
    float: right;

    div {
      div {
        button {
          color: ${({ theme }) => theme.text};
        }
      }
    }
  }

  footer div button {
    p {
      color: var(--white);
    }
  }

  #delete-button {
    background: var(--red-primary);
    width: 7rem;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }

  #confirm-button {
    background: var(--green-primary);
    width: 7rem;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }

  #next-phase-button {
    background: var(--red-primary);
    width: 10rem;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }
`;

export const ButtonTooltip = styled.div`
  width: 10rem !important;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  position: absolute;
  bottom: 4rem;
  right: 1.53rem;
  min-height: 3rem;
  border-radius: 0.6rem;
  color: ${({ theme }) => theme.text};
  z-index: 2;
  box-shadow: inset 0 0 0.7rem red;
  display: flex;
  float: right;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  button {
    color: var(--white);
    border: none;
    background: none;
    margin: 0.3rem 0;
    :hover {
      color: var(--gray);
    }
  }
`;
