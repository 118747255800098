/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import NewPopUp from '../../../../components/PopUp';
import api from '../../../../services/api';
import { Form } from './styles';

const NewUnit = ({ setActive, getUnits }) => {
  const [object, setObject] = useState({
    name: '',
    stakeholder_name: '',
    stakeholder_email: '',
    phone: '',
    cnpj: '',
    cpf: '',
    zip_code: '',
    rg: '',
    state: '',
    address: '',
    personal_email: '',
    city: '',
    bank: '',
    agency: '',
    account: '',
    account_type: '',
    pipefyId: '',
    address_number: '',
    address_complement: '',
    district: '',
    social_name: '',
    training_fee: 0,
    franchise_fee: 0,
    start_date: '',
  });
  const accountMask = useCallback(
    value => {
      let string = value || '';
      string = string.replace(/-/g, '');

      let match = '';

      if (
        object.bank === '001' ||
        object.bank === '033' ||
        object.bank === '422'
      ) {
        const regex = /^([^\s]{8})([^\s]{1})$/g;
        match = regex.exec(string);
      } else if (object.bank === '104') {
        const regex = /^([^\s]{11})([^\s]{1})$/g;
        match = regex.exec(string);
      } else if (object.bank === '237' || object.bank === '655') {
        const regex = /^([^\s]{7})([^\s]{1})$/g;
        match = regex.exec(string);
      } else if (object.bank === '341') {
        const regex = /^([^\s]{5})([^\s]{1})$/g;
        match = regex.exec(string);
      } else if (object.bank === '041' || object.bank === '756') {
        const regex = /^([^\s]{9})([^\s]{1})$/g;
        match = regex.exec(string);
      } else if (object.bank === '748') {
        const regex = /^([^\s]{7})$/g;
        match = regex.exec(string);
      }

      if (match) {
        match.shift();
        string = match.join('-');
      }

      return string;
    },
    [object]
  );
  const agencyMask = useCallback(
    value => {
      let string = value || '';
      string = string.replace(/-/g, '');
      let match = '';
      if (object.bank === '001' || object.bank === '237') {
        const regex = /^([^\s]{4})([^\s]{1})$/g;
        match = regex.exec(string);
      } else {
        const regex = /^([^\s]{4})$/g;
        match = regex.exec(string);
      }

      if (match) {
        match.shift();
        string = match.join('-');
      }

      return string;
    },
    [object]
  );

  useEffect(() => {
    setObject({
      ...object,
      agency: agencyMask(object.agency),
      account: accountMask(object.account),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object.bank]);

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;
      setObject({ ...object, [name]: value });
    },
    [object]
  );

  const {
    maxAgency,
    maxAccount,
    placeholderAgency,
    placeholderAccount,
  } = useMemo(() => {
    if (object.bank === '001') {
      return {
        maxAgency: 5,
        maxAccount: 9,
        placeholderAgency: '9999-D',
        placeholderAccount: '99999999-D',
      };
    }
    if (object.bank === '033' || object.bank === '422') {
      return {
        maxAgency: 4,
        maxAccount: 9,
        placeholderAgency: '9999',
        placeholderAccount: '99999999-D',
      };
    }
    if (object.bank === '104') {
      return {
        maxAgency: 4,
        maxAccount: 12,
        placeholderAgency: '9999',
        placeholderAccount: 'XXX99999999-D (X: Operação)',
      };
    }
    if (object.bank === '237') {
      return {
        maxAgency: 5,
        maxAccount: 8,
        placeholderAgency: '9999-D',
        placeholderAccount: '9999999-D',
      };
    }
    if (object.bank === '341') {
      return {
        maxAgency: 4,
        maxAccount: 6,
        placeholderAgency: '9999',
        placeholderAccount: '99999-D',
      };
    }
    if (object.bank === '041' || object.bank === '756') {
      return {
        maxAgency: 4,
        maxAccount: 10,
        placeholderAgency: '9999',
        placeholderAccount: '999999999-D',
      };
    }
    if (object.bank === '748') {
      return {
        maxAgency: 4,
        maxAccount: 7,
        placeholderAgency: '9999',
        placeholderAccount: '999999D',
      };
    }
    if (object.bank === '655') {
      return {
        maxAgency: 4,
        maxAccount: 8,
        placeholderAgency: '9999',
        placeholderAccount: '9999999-D',
      };
    }

    return {
      maxAgency: 0,
      maxAccount: 0,
      placeholderAgency: 'Selecione um banco antes',
      placeholderAccount: 'Selecione um banco antes',
    };
  }, [object.bank]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (
        object.account.length < maxAccount ||
        object.agency.length < maxAgency
      ) {
        toast.error('Confira a formatação dos dados bancarios!');
        return false;
      }
      await api
        .post(`units`, object)
        .then(() => {
          toast.success('Unidade criada com sucesso!');
          getUnits();
          setActive(false);
        })
        .catch(err => {
          if (err.response.data.msg === 'Stakeholder email already exist.') {
            toast.error('Email do Stakeholder já cadastrado em outra unidade.');
            return false;
          }
          if (err.response.data.msg) {
            toast.error(`${err.response.data.msg}`);
            return false;
          }
          toast.error(
            'Algo deu errado, por favor, tente novamente mais tarde.'
          );
          return false;
        });

      return true;
    },
    [getUnits, maxAccount, maxAgency, object, setActive]
  );

  return (
    <NewPopUp closePopUp={setActive} header="Nova unidade" width="60%">
      <Form onSubmit={handleSubmit}>
        <label htmlFor="name">
          <strong>Nome da unidade</strong>
          <input
            name="name"
            id="name"
            type="text"
            value={object.name}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="social_name">
          <strong>Razão Social</strong>
          <input
            name="social_name"
            id="social_name"
            type="text"
            value={object.social_name}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="stakeholder_name">
          <strong>Nome do Principal Stakeholder</strong>
          <input
            name="stakeholder_name"
            id="stakeholder_name"
            type="text"
            value={object.stakeholder_name}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="stakeholder_email">
          <strong>E-mail do Principal Stakeholder</strong>
          <input
            name="stakeholder_email"
            id="stakeholder_email"
            type="email"
            value={object.stakeholder_email}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="phone">
          <strong>Telefone do Principal Stakeholder</strong>
          <input
            name="phone"
            id="phone"
            type="text"
            pattern="\([0-9]{2}\) [0-9]{4,6}-[0-9]{3,4}$+"
            value={object.phone}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="cnpj">
          <strong>CNPJ</strong>
          <input
            name="cnpj"
            id="cnpj"
            type="text"
            pattern="/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/"
            value={object.cnpj}
            onChange={handleChange}
            required
          />
        </label>

        <label htmlFor="cpf">
          <strong>CPF</strong>
          <input
            name="cpf"
            id="cpf"
            type="text"
            pattern="/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/"
            value={object.cpf}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="rg">
          <strong>RG</strong>
          <input
            name="rg"
            id="rg"
            type="text"
            value={object.rg}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="personal_email">
          <strong>E-mail Pessoal do Principal Stakeholder</strong>
          <input
            name="personal_email"
            id="personal_email"
            type="email"
            value={object.personal_email}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="zip_code">
          <strong>CEP</strong>
          <input
            name="zip_code"
            id="zip_code"
            pattern="[0-9]+"
            type="text"
            value={object.zip_code}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="state">
          <strong>Estado</strong>
          <select
            name="state"
            id="state"
            value={object.state}
            onChange={handleChange}
            required
          >
            <option value="" />
            <option value="Acre (AC)">Acre (AC) </option>
            <option value="Alagoas (AL)">Alagoas (AL) </option>
            <option value="Amapá (AP)">Amapá (AP) </option>
            <option value="Amazonas (AM)">Amazonas (AM) </option>
            <option value="Bahia (BA)">Bahia (BA) </option>
            <option value="Ceará (CE)">Ceará (CE) </option>
            <option value="Distrito Federal (DF)">Distrito Federal (DF)</option>
            <option value="Espírito Santo (ES)">Espírito Santo (ES) </option>
            <option value="Goiás (GO)">Goiás (GO) </option>
            <option value="Maranhão (MA)">Maranhão (MA) </option>
            <option value="Mato Grosso (MT)">Mato Grosso (MT) </option>
            <option value="Mato Grosso do Sul (MS)">
              Mato Grosso do Sul (MS)
            </option>
            <option value="Minas Gerais (MG)">Minas Gerais (MG) </option>
            <option value="Pará (PA)">Pará (PA) </option>
            <option value="Paraíba (PB)">Paraíba (PB) </option>
            <option value="Paraná (PR)">Paraná (PR) </option>
            <option value="Pernambuco (PE)">Pernambuco (PE) </option>
            <option value="Piauí (PI)">Piauí (PI) </option>
            <option value="Rio de Janeiro (RJ)">Rio de Janeiro (RJ) </option>
            <option value="Rio Grande do Norte (RN)">
              Rio Grande do Norte (RN)
            </option>
            <option value="Rio Grande do Sul (RS)">
              Rio Grande do Sul (RS)
            </option>
            <option value="Rondônia (RO)">Rondônia (RO) </option>
            <option value="Roraima (RR)">Roraima (RR) </option>
            <option value="Santa Catarina (SC)">Santa Catarina (SC) </option>
            <option value="São Paulo (SP)">São Paulo (SP) </option>
            <option value="Sergipe (SE)">Sergipe (SE) </option>
            <option value="Tocantins (TO)">Tocantins (TO)</option>
          </select>
        </label>
        <label htmlFor="city">
          <strong>Cidade</strong>
          <input
            name="city"
            id="city"
            type="text"
            value={object.city}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="district">
          <strong>Bairro</strong>
          <input
            name="district"
            id="district"
            type="text"
            value={object.district}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="address">
          <strong>Endereço</strong>
          <input
            name="address"
            id="address"
            type="text"
            value={object.address}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="address_number">
          <strong>Numero</strong>
          <input
            name="address_number"
            id="address_number"
            type="text"
            value={object.address_number}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="address_complement">
          <strong>Complemento</strong>
          <input
            name="address_complement"
            id="address_complement"
            type="text"
            value={object.address_complement}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="active">
          <strong>Ativa</strong>
          <select
            name="active"
            id="active"
            value={object.active}
            onChange={e =>
              setObject({
                ...object,
                active: e.target.value === 'true',
              })
            }
            required
          >
            <option value="" />
            <option value={!!true}>Ativo</option>
            <option value={false}>Inativo</option>
          </select>
        </label>

        <label htmlFor="bank">
          <strong>Banco</strong>
          <select
            name="bank"
            id="bank"
            value={object.bank}
            onChange={handleChange}
            required
          >
            <option value="" />
            <option value="001">Banco do Brasil</option>
            <option value="033">Santander</option>
            <option value="104">Caixa Econômica</option>
            <option value="237">Bradesco</option>
            <option value="341">Itaú</option>
            <option value="041">Banrisul</option>
            <option value="748">Sicredi</option>
            <option value="756">Sicoob</option>
            <option value="422">Safra</option>
            <option value="136">Unicred</option>
            <option value="655">Votorantim</option>
          </select>
        </label>
        <label htmlFor="account_type">
          <strong>Tipo de Conta</strong>
          <select
            name="account_type"
            id="account_type"
            value={object.account_type}
            onChange={handleChange}
            required
          >
            <option value="" />
            <option value="cc">Conta Corrente</option>
            <option value="cp">Conta Poupança</option>
          </select>
        </label>
        <label htmlFor="agency">
          <strong>Agencia</strong>
          <input
            type="text"
            value={object.agency}
            id="agency"
            name="agency"
            maxLength={maxAgency}
            minLength={maxAgency}
            placeholder={placeholderAgency}
            onChange={e =>
              setObject({ ...object, agency: agencyMask(e.target.value) })
            }
            required
          />
        </label>
        <label htmlFor="account">
          <strong>Conta</strong>
          <input
            type="text"
            value={object.account}
            id="account"
            name="account"
            maxLength={maxAccount}
            minLength={maxAccount}
            placeholder={placeholderAccount}
            onChange={e =>
              setObject({ ...object, account: accountMask(e.target.value) })
            }
            required
          />
        </label>
        <label htmlFor="pipefyId">
          <strong>ID do Pipefy</strong>
          <input
            name="pipefyId"
            id="pipefyId"
            type="text"
            value={object.pipefyId}
            onChange={handleChange}
            required
          />
        </label>
        <label htmlFor="training_fee">
          <strong>Taxa De Treinamento De Franquia</strong>
          <input
            type="text"
            value={object.training_fee}
            id="training_fee"
            name="training_fee"
            placeholder="5000"
            onChange={e =>
              setObject({ ...object, training_fee: e.target.value })
            }
            required
          />
        </label>
        <label htmlFor="franchise_fee">
          <strong>Taxa De Inicio De Franquia</strong>
          <input
            type="text"
            value={object.franchise_fee}
            id="franchise_fee"
            name="franchise_fee"
            placeholder="5000"
            onChange={e =>
              setObject({ ...object, franchise_fee: e.target.value })
            }
            required
          />
        </label>
        <label htmlFor="start_date">
          <strong>Data de inicio da franquia</strong>
          <p>
            Data cadastrada: {new Date(object.start_date).toLocaleDateString()}
          </p>
          <input
            id="start_date"
            name="start_date"
            type="date"
            placeholder="Data de inicio da franquia"
            value={object.start_date}
            onChange={e => setObject({ ...object, start_date: e.target.value })}
          />
        </label>
        <button type="submit">Salvar</button>
      </Form>
    </NewPopUp>
  );
};

export default NewUnit;
