import React from 'react';

import { Container } from './styles';

import { TextInputProps } from '../types';

export const TextInput: React.FC<TextInputProps> = ({
  width,
  height,
  font,
  background,
  ...rest
}) => {
  return (
    <Container props={{ width, height, font, background }}>
      <input type="text" {...rest} />
    </Container>
  );
};
