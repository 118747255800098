import React from 'react';

import { RiFileListLine } from 'react-icons/ri';

import NewMeta from '../Meta';

import { Container } from './styles';
import { CardInfo } from '../types';

interface KrsCards {
  krs: CardInfo[];
}

const KrsCard: React.FC<KrsCards> = ({ krs }) => {
  return (
    <Container>
      <header>
        <RiFileListLine size={20} color="var(--green-primary)" />
        <p>KR&apos;s Time</p>
      </header>

      <div className="krs">
        {krs &&
          krs.map(card => {
            return <NewMeta key={card.id} cardInfo={card} />;
          })}
      </div>
    </Container>
  );
};

export default KrsCard;
