import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import ChartCard from '../../../../components/ChartCard';
import { useAuth } from '../../../../contexts/AuthContext';

import * as S from './styles';

import { getTasksProgress } from '../../services/GetTasksProgress';
import { Filters } from './Filters';
import { getClient } from '../../services/GetClient';

type ITaskProgress = {
  percentage: number;
  count: number;
  users: User[];
};

interface HeaderProps {
  cardsArea: boolean;
  setCardsArea: Dispatch<SetStateAction<boolean>>;
}

export function Header({ cardsArea, setCardsArea }: HeaderProps) {
  const { id: userId } = useAuth().user;
  const [clientName, setClientName] = useState('');
  const [checkDeleted, setCheckDeleted] = useState(false);
  const { id } = useParams<Params>();

  const [lateTasksProgress, setLateTasksProgress] = useState(
    {} as ITaskProgress
  );
  const [lateDoneTasksProgress, setLateDoneTasksProgress] = useState(
    {} as ITaskProgress
  );
  const [onTimeTasksProgress, setOnTimeTasksProgress] = useState(
    {} as ITaskProgress
  );
  const [onTimeDoneTasksProgress, setOnTimeDoneTasksProgress] = useState(
    {} as ITaskProgress
  );
  const [rescheduleTasksProgress, setRescheduleTasksProgress] = useState(
    {} as ITaskProgress
  );

  const setTasksInfo = useCallback(async () => {
    if (id) {
      const tasksProgress = await getTasksProgress(id);

      if (tasksProgress) {
        setLateTasksProgress(tasksProgress.lateTasksProgress);
        setLateDoneTasksProgress(tasksProgress.lateDoneTasksProgress);
        setOnTimeTasksProgress(tasksProgress.onTimeTasksProgress);
        setOnTimeDoneTasksProgress(tasksProgress.onTimeDoneTasksProgress);
        setRescheduleTasksProgress(tasksProgress.rescheduleTasksProgress);
      }

      const client = await getClient(id);

      if (client) setClientName(client.name);
    }
  }, [id]);

  useEffect(() => {
    setTasksInfo();
  }, [setTasksInfo]);

  return (
    <S.Wrapper>
      <S.TopBar>
        <S.Title>
          <h4>{clientName}</h4>
          {userId % 2 === 0 && (
            <button
              onClick={() => {
                setCardsArea!(!cardsArea);
              }}
              type="button"
            >
              Ver Gráficos
            </button>
          )}
        </S.Title>
        <Filters
          checkDeleted={checkDeleted}
          setCheckDeleted={setCheckDeleted}
        />
      </S.TopBar>
      {cardsArea && (
        <S.Cards>
          <ChartCard
            data-testid="chart-card"
            title="Tarefas atrasadas"
            percent={lateTasksProgress?.percentage}
            color="var(--red-primary)"
            users={lateTasksProgress?.users}
          />
          <ChartCard
            title="Entregues atrasadas"
            percent={lateDoneTasksProgress?.percentage}
            color="var(--yellow-primary)"
            users={lateDoneTasksProgress?.users}
          />
          <ChartCard
            title="Tarefas em dia"
            percent={onTimeTasksProgress?.percentage}
            color="var(--cyan-primary)"
            users={onTimeTasksProgress?.users}
          />
          <ChartCard
            title="Tarefas entregues"
            percent={onTimeDoneTasksProgress?.percentage}
            color="var(--green-primary)"
            users={onTimeDoneTasksProgress?.users}
          />
          <ChartCard
            title="Tarefas remanejadas"
            percent={rescheduleTasksProgress?.percentage}
            color="var(--magenta-primary)"
            users={rescheduleTasksProgress?.users}
          />
        </S.Cards>
      )}
      {userId % 2 !== 0 && (
        <>
          {cardsArea ? (
            <button
              className="showArea"
              type="button"
              onClick={() => setCardsArea(!cardsArea)}
            >
              <RiArrowUpSLine className="icon" size={28} />
            </button>
          ) : (
            <button
              className="showArea"
              type="button"
              onClick={() => setCardsArea(!cardsArea)}
            >
              <RiArrowDownSLine className="icon" size={28} />
            </button>
          )}
        </>
      )}
    </S.Wrapper>
  );
}
