import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { RiEditBoxLine } from 'react-icons/ri';
import CloseButton from '../../../components/CloseButton';

import { useExportPlaybook } from '../../../../../../../../contexts/ExportPlaybookContext';
import { Modal } from '../../../../../../styles';
import { Calendar } from '../../../../../../../../components/Calendar';

import {
  Container,
  Label,
  Deadline,
  SeparatorVertical,
  Buttons,
} from '../../../styles/IndividualTaskContainer';

interface TaskProps {
  individualTask: ITask;
  projectId: number;
  setProjectDueDate: Dispatch<SetStateAction<Date>>;
}

const IndividualTask: React.FC<TaskProps> = ({
  individualTask,
  projectId,
  setProjectDueDate,
}) => {
  const [openNewCalendar, setOpenNewCalendar] = useState(false);

  const [firstTime, setFirstTime] = useState(true);

  const [individualTaskDueDate, setIndividualTaskDueDate] = useState<
    Date | undefined
  >(new Date(individualTask.due_date));
  const [backupDueDate, setBackupDueDate] = useState<Date>(new Date());

  const {
    updateIndividualTaskName,
    updateIndividualTaskDescription,
    deleteIndividualTask,
    updateDueDateInIndividualTask,
    getProjectDueDate,
  } = useExportPlaybook();

  const handleOpenCalendar = (): void => {
    setOpenNewCalendar(!openNewCalendar);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'calendar-modal') handleOpenCalendar();
  };

  const deleteIndividualTaskAndUpdateProjectDueDate = () => {
    deleteIndividualTask(projectId, individualTask.id);

    const projectTimestamp = getProjectDueDate(projectId);
    const minimunProjectDueDate = new Date(projectTimestamp);

    setProjectDueDate(minimunProjectDueDate);
  };

  useEffect(() => {
    if (firstTime) {
      // apiRequestSubtask();
      setFirstTime(false);

      const projectTimestamp = getProjectDueDate(projectId);
      const minimunProjectDueDate = new Date(projectTimestamp);

      setProjectDueDate(minimunProjectDueDate);
      setBackupDueDate(individualTaskDueDate!);
    }
  }, [
    firstTime,
    getProjectDueDate,
    individualTaskDueDate,
    projectId,
    setProjectDueDate,
  ]);

  useEffect(() => {
    if (backupDueDate.getTime() !== individualTaskDueDate!.getTime()) {
      updateDueDateInIndividualTask(
        individualTaskDueDate!,
        projectId,
        individualTask.id
      );
      const projectTimestamp = getProjectDueDate(projectId);
      const minimunProjectDueDate = new Date(projectTimestamp);

      setProjectDueDate(minimunProjectDueDate);
      setBackupDueDate(individualTaskDueDate!);
    }
  }, [
    backupDueDate,
    getProjectDueDate,
    individualTask.id,
    individualTaskDueDate,
    projectId,
    setProjectDueDate,
    updateDueDateInIndividualTask,
  ]);

  return (
    <>
      <Container>
        <Label>
          Task Individual
          <textarea
            id="individualTaskName"
            defaultValue={individualTask.name}
            onBlur={e => {
              updateIndividualTaskName(
                projectId,
                individualTask.id,
                e.target.value
              );
            }}
          />
        </Label>
        <SeparatorVertical />
        <Deadline>
          Prazo
          <button type="button" onClick={handleOpenCalendar}>
            <p>
              {format(individualTaskDueDate!, 'dd.MMMM.yyyy', {
                locale: ptBR,
              }).toString()}
              <RiEditBoxLine color="var(--gray)" size={15} />
            </p>
          </button>
          {openNewCalendar && (
            <Modal id="calendar-modal" onClick={handleOutsideClick}>
              <Calendar
                onChange={setIndividualTaskDueDate}
                date={individualTaskDueDate}
                activeRemove
              />
            </Modal>
          )}
        </Deadline>
        <SeparatorVertical />
        <Label>
          Descrição
          <textarea
            id="individualTaskDescription"
            defaultValue={individualTask.desc}
            onBlur={e => {
              updateIndividualTaskDescription(
                projectId,
                individualTask.id,
                e.target.value
              );
            }}
          />
        </Label>
        <Buttons>
          <CloseButton
            onClick={() => deleteIndividualTaskAndUpdateProjectDueDate()}
            disabled={false}
          />
        </Buttons>
      </Container>
    </>
  );
};

export default IndividualTask;
