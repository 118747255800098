import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    width: 100%;
    height: 17rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.backgroundSecondary};
  }
  main {
    width: 100%;
    padding: 2rem 0;
  }
`;
