import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-left: 0.4rem;
  margin-right: 2rem;

  form {
    width: 100%;
    display: flex;
  }

  .button {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .button button {
    border-radius: 4px;
  }

  @media (max-width: 500px) {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
`;

export const InteractiveArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem;

  textarea {
    width: 100%;
    min-height: 7rem;
    border-radius: 8px;
    border: 0;
    outline: 0;
    padding: 1rem;
    background: ${({ theme }) => theme.backgroundSecondary};
    color: ${({ theme }) => theme.text};
    font: 400 0.8rem Work Sans;
  }

  textarea::placeholder {
    color: ${({ theme }) => theme.text};
    opacity: 70%;
  }
`;

export const List = styled.div`
  margin-top: 1.6rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 2rem;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1350px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .item {
    display: flex;
    align-items: center;
    width: 10rem;
    word-break: break-all;
    cursor: pointer;
    transition: 0.3s;
  }

  .item svg {
    display: none;
    min-width: 15px;
    min-height: 15px;
  }

  .item:hover {
    color: ${({ theme }) => theme.backgroundTerciary};
  }

  .item:hover svg {
    display: initial;
  }

  .item p {
    margin-left: 0.5rem;
    margin-right: 0.3rem;
    color: ${({ theme }) => theme.text};
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 0.9rem;

  p {
    margin-top: 0.6rem;
    color: ${({ theme }) => theme.text};
  }
`;

export const Ball = styled.div`
  width: 0.4rem;
  height: 0.4rem;
  min-height: 0.4rem;
  min-width: 0.4rem;
  border-radius: 50%;
  background: var(--red-primary);
`;
