import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export const editTag = async (id: number, color: string): Promise<ITag> => {
  try {
    const response = await apiV2.put(`tags/`, {
      id,
      color,
    });

    const tag = response.data;

    return tag;
  } catch {
    toast.error(
      'Ocorreu algum problema editando as tags, atualize a página e tente novamente 😕'
    );

    return {} as ITag;
  }
};
