import styled from 'styled-components';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 0.5rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  position: relative;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.backgroundTerciary};
`;

export const IconsHeader = styled.div`
  width: 100%;
  padding: 0.5rem;
  button {
    width: 100%;
    border: none;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: ${({ theme }) => theme.text};
    }
  }
`;

export const IconsSelect = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem;
  flex-wrap: wrap;
  position: absolute;
  margin-top: 2rem;
  border-radius: 0 0 5px 5px;
  z-index: 2;
  background-color: ${({ theme }) => theme.backgroundTerciary};

  input[type='radio'] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  label {
    color: ${({ theme }) => theme.text};
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 5px;
    transition: all 0.2s linear;
    background: ${({ theme }) => theme.backgroundTerciary};
    margin-right: 20px;
    &:hover {
      filter: brightness(90%);
      border-radius: 3px;
      transition: all 0.2s linear;
    }
  }
  input[type='radio']:checked + label {
    color: var(--white);
    background: var(--gray);
    border-radius: 1rem;
    transition: all 0.2s linear;

    &:hover {
      filter: brightness(90%);
      border-radius: 3px;
      transition: all 0.2s linear;
    }
  }
`;
