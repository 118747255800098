import { Dispatch, SetStateAction, useState } from 'react';
import {
  RiCommandLine,
  RiSeedlingLine,
  RiSpamLine,
  RiSendPlaneLine,
  RiPushpin2Line,
  RiStarHalfFill,
  RiCheckLine,
  RiArrowUpSLine,
  RiArrowDownSLine,
} from 'react-icons/ri';
import { IconBaseProps } from 'react-icons';
import * as S from './styles';

interface Icon {
  value: string;
  label: string;
  content: React.ComponentType<IconBaseProps>;
}

interface SelectIconProps {
  selectedIcon: string;
  setSelectedIcon: Dispatch<SetStateAction<string>>;
}

export const SelectIcon = ({
  selectedIcon,
  setSelectedIcon,
}: SelectIconProps): JSX.Element => {
  const [iconsArea, setIconsArea] = useState(false);
  const icons: { [key: string]: Icon } = {
    RiSeedlingLine: {
      value: 'RiSeedlingLine',
      label: 'Iniciando',
      content: RiSeedlingLine,
    },
    RiCommandLine: {
      value: 'RiCommandLine',
      label: 'Brainstorm',
      content: RiCommandLine,
    },
    RiStarHalfFill: {
      value: 'RiStarHalfFill',
      label: 'Intermediário',
      content: RiStarHalfFill,
    },
    RiPushpin2Line: {
      value: 'RiPushpin2Line',
      label: 'Em Processo',
      content: RiPushpin2Line,
    },
    RiSpamLine: {
      value: 'RiSpamLine',
      label: 'Urgente',
      content: RiSpamLine,
    },
    RiSendPlaneLine: {
      value: 'RiSendPlaneLine',
      label: 'Enviar',
      content: RiSendPlaneLine,
    },
    RiCheckLine: {
      value: 'RiCheckLine',
      label: 'Finalizado',
      content: RiCheckLine,
    },
  };

  const SelectedIcon = selectedIcon
    ? icons[`${selectedIcon}`].content
    : RiSeedlingLine;

  const handleSelect = (iconName: string) => {
    setSelectedIcon(iconName);
    setIconsArea(!iconsArea);
  };

  return (
    <S.Wrapper>
      <S.IconsHeader>
        <button type="button" onClick={() => setIconsArea(!iconsArea)}>
          {selectedIcon ? (
            <SelectedIcon size={20} color="var(--red-primary)" />
          ) : (
            <p>Selecione o ícone</p>
          )}
          {iconsArea ? (
            <div id="arrow-box">
              <RiArrowUpSLine
                className="icon arrow"
                color="var(--gray)"
                size={20}
              />
            </div>
          ) : (
            <div id="arrow-box">
              <RiArrowDownSLine
                className="icon arrow"
                color="var(--gray)"
                size={20}
              />
            </div>
          )}
        </button>
      </S.IconsHeader>
      {iconsArea && (
        <S.IconsSelect>
          {Object.values(icons).map(({ content: Content, label, value }) => {
            return (
              <div key={label}>
                <input
                  type="radio"
                  id={label}
                  name="icon"
                  checked={selectedIcon === value}
                  value={value}
                  onChange={() => handleSelect(value)}
                />
                <label htmlFor={label}>
                  {' '}
                  <S.Icon>
                    <Content size={20} color="var(--red-primary)" />
                  </S.Icon>
                </label>
              </div>
            );
          })}
        </S.IconsSelect>
      )}
    </S.Wrapper>
  );
};
