import React, { useEffect, useState } from 'react';
import { FaRegEye } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Project from './containers/Project';
import AddNewButton from '../components/AddNewButton';
import { useAuth } from '../../../../../../contexts/AuthContext';

import { Background, Container, Header, Projects, Footer } from './styles';
import { Button } from '../../../../../../components/Inputs';
import { ProjectCardLoading } from '../../../../pages/ListView/components/Loadings';
import { useExportPlaybook } from '../../../../../../contexts/ExportPlaybookContext';
import { addNewProject } from './utils/ProjectFunctions';

const ExportPlaybook: React.FC<{ handleClose: () => void }> = ({
  handleClose,
}) => {
  const [playbookName, setPlaybookName] = useState('');
  const [loading, setLoading] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [isOfficialSelected, setisOfficialSelected] = useState(false);

  const { unit_id: unitId } = useAuth().user;

  const clientId = useParams<Params>().id;

  const {
    globalProjects,
    setGlobalProjects,
    createGlobalProjects,
    createPlaybook,
  } = useExportPlaybook();

  const isMatrizUser = (matrizId: number) => {
    if (matrizId === 1) {
      setShowCheckbox(true);
    }
  };

  const handleSubmit = () => {
    if (!playbookName) {
      toast.error('Digite um nome para o Playbook!');
      return;
    }
    createPlaybook(
      unitId,
      playbookName,
      isOfficialSelected,
      handleClose,
      setLoading
    );
  };

  useEffect(() => {
    if (firstTime) {
      createGlobalProjects(clientId, setLoading);
      isMatrizUser(unitId);
      setFirstTime(false);
    }
  }, [clientId, createGlobalProjects, firstTime, unitId]);

  return (
    <>
      <Background onClick={handleClose} />
      <Container>
        <Header>
          <FaRegEye />
          <label htmlFor="playbook">
            <input
              id="playbook"
              placeholder="Digite aqui um nome para este Playbook"
              defaultValue={playbookName}
              onBlur={e => {
                setPlaybookName(e.target.value);
              }}
              required
            />
          </label>
          {showCheckbox && (
            <label htmlFor="official" data-testid="playbookOfficial">
              Playbook oficial?
              <input
                id="official"
                type="checkbox"
                checked={isOfficialSelected}
                onChange={() => setisOfficialSelected(!isOfficialSelected)}
              />
            </label>
          )}
          <IoMdClose onClick={handleClose} />
        </Header>
        <Projects>
          {loading ? (
            <ProjectCardLoading />
          ) : (
            globalProjects.map(project => (
              <Project key={project.id} project={project} />
            ))
          )}
          <AddNewButton
            onClick={() =>
              addNewProject(Number(clientId), globalProjects, setGlobalProjects)
            }
          >
            Adicionar novo projeto
          </AddNewButton>
        </Projects>
        <Footer>
          {loading && (
            <span>
              Estamos carregando seus projetos, isto pode demorar um pouco,
              aguarde...
            </span>
          )}
          <Button
            background="var(--gray)"
            width="10.5rem"
            height="2.1rem"
            className="initialButton"
            font="400 0.9rem Work Sans"
            disabled={loading}
            onClick={handleClose}
          >
            <p>Voltar</p>
          </Button>
          <Button
            background="var(--green-primary)"
            height="2.1rem"
            width="10.5rem"
            font="400 0.9rem Work Sans"
            className="rightButton"
            disabled={loading}
            onClick={() => handleSubmit()}
          >
            <p>Salvar Playbook</p>
          </Button>
        </Footer>
      </Container>
    </>
  );
};

export default ExportPlaybook;
