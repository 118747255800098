import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  border-radius: 8px;
`;

export const UserList = styled.ul`
  background: ${({ theme }) => theme.backgroundPrimary};
  border: 2px solid ${({ theme }) => theme.backgroundPrimary};
  box-shadow: ${({ theme }) => theme.backgroundPrimary} 0px 54px 55px,
    ${({ theme }) => theme.backgroundPrimary} 0px -12px 30px,
    ${({ theme }) => theme.backgroundPrimary} 0px 4px 6px,
    ${({ theme }) => theme.backgroundPrimary} 0px 12px 13px,
    ${({ theme }) => theme.backgroundPrimary} 0px -3px 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 30vh;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
  margin-top: 0.6rem;
  border-radius: 8px;

  li {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    button {
      display: flex;
      width: 100%;
      align-items: center;
      height: 100%;
      background: transparent;
      border: none;
      color: ${({ theme }) => theme.text};

      .avatar {
        height: 2rem !important;
        width: 2rem !important;
        border-radius: 50%;
        margin-right: 0.5rem;
      }

      :hover {
        color: var(--white);
        background: var(--red-primary);
        cursor: pointer;
      }
    }
  }
`;
