import React, { useState, useCallback } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { RiEditLine } from 'react-icons/ri';

import { postOkrService } from '../../../services/postOkrService';

import { TextInput, Button } from '../../../../../components/Inputs';

import * as S from './styles';

interface NewOkr {
  addFunction: (name: string) => void;
  clientId: number;
  draftId?: number;
  okrLength: number;
}

const AddOkr: React.FC<NewOkr> = ({
  addFunction,
  clientId,
  draftId,
  okrLength,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [name, setName] = useState('');

  const maxOkrsNumber = 6;

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (okrLength >= maxOkrsNumber) {
        return toast.error('Número máximo de okrs alcançado!');
      }

      setIsAdding(true);

      const createdOkr = await postOkrService({ clientId, draftId, name });

      if (createdOkr) {
        addFunction(createdOkr);
        setOpenModal(false);

        toast.success('Adicionado!');
      }

      setIsAdding(false);

      return createdOkr;
    },
    [addFunction, clientId, draftId, name, okrLength]
  );

  return (
    <Popup
      on="click"
      lockScroll={false}
      arrow={false}
      open={openModal}
      closeOnDocumentClick={false}
      modal
      position="top right"
      trigger={() => {
        return (
          <div>
            <Button
              background="var(--green-primary)"
              height="1.8rem"
              width="16rem"
              font="400 1rem Work Sans"
              text="Adicionar nova OKR"
              onClick={() => setOpenModal(true)}
            />
          </div>
        );
      }}
      contentStyle={{
        width: '20rem',
        border: 0,
        borderRadius: '8px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <S.EditCard onSubmit={e => handleSubmit(e)}>
        <header>
          <RiEditLine size={20} color="var(--red-primary)" />
          <p>Criação Okr</p>
        </header>
        <main>
          <S.Input>
            <p>Nome:</p>
            <TextInput
              required
              height="1.8rem"
              placeholder="Digite Aqui"
              value={name}
              maxLength={200}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setName(e.target.value);
              }}
            />
          </S.Input>
        </main>
        <footer>
          <Button
            background="var(--gray)"
            className="midButton"
            height="1.6rem"
            text="Cancelar"
            font="400 0.9rem Work Sans"
            type="button"
            onClick={() => {
              setOpenModal(false);
            }}
          />

          <Button
            background={isAdding ? 'var(--gray)' : 'var(--green-primary)'}
            disabled={isAdding}
            text={isAdding ? 'Adicionando...' : 'Adicionar'}
            width="100%"
            height="1.6rem"
            font="400 0.9rem Work Sans"
            type="submit"
          />
        </footer>
      </S.EditCard>
    </Popup>
  );
};

export default AddOkr;
