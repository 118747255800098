/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React, { useCallback, useState, useMemo } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Container, Button } from './styles';

// Ordena a table pra cima ou baixo.
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

// Retorna a table.
const LabTable = props => {
  const { items, requestSort, sortConfig } = useSortableData(props.contents);

  // Coloca o icone de cima ou baixo.
  const getClassNamesFor = useCallback(
    name => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    },
    [sortConfig]
  );

  return (
    <>
      <Container style={{ transform: `scale(${props.xAxes}, ${props.yAxes})` }}>
        <table id="main-table">
          <tbody>
            <tr className="table-header">
              {Object.keys(items[0]).map(tableElement => {
                return (
                  <td key={tableElement}>
                    <button
                      type="button"
                      onClick={() => requestSort(tableElement)}
                      className={getClassNamesFor(tableElement)}
                    >
                      {tableElement}
                    </button>
                  </td>
                );
              })}
            </tr>
            {items.map(item => {
              return (
                <tr
                  className="table-content"
                  key={item.id}
                  onClick={() => props.rowClick(item)}
                >
                  {Object.values(item).map((tableElement, i) => {
                    return <td key={i}>{tableElement}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Container>
      <Button>
        <ReactHTMLTableToExcel
          className="table-download-button"
          table="main-table"
          filename="TabelaFinanceiro"
          sheet="tablexls"
          buttonText="Baixar tabela Excel"
        />
      </Button>
    </>
  );
};

export default LabTable;
