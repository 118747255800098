import { FC, useEffect, useState, useMemo, useCallback } from 'react';

import Avatar from 'react-avatar';

import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import * as S from './styles';
import api from '../../services/api';

interface AvatarProps {
  id: number;
  size?: string;
}

const UserAvatar: FC<AvatarProps> = ({ id, size = '36px' }) => {
  const [loading, setLoading] = useState(true);
  const [firstTime] = useState(true);

  const [user, setUser] = useState<IUser>({} as IUser);

  const { id: userId } = useAuth().user;

  const userName = useMemo(() => {
    return `${user?.name} ${user?.last_name}`;
  }, [user]);

  const getUser = useCallback(async () => {
    setLoading(true);

    try {
      await api.get(`users/${id}`).then(res => setUser(res.data));
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const randomKey = Math.floor(Math.random() * 65536);

  useEffect(() => {
    if (firstTime) {
      getUser();
    }
  }, [firstTime, getUser]);

  return (
    <S.Container key={randomKey} size={size}>
      {loading ? (
        <></>
      ) : (
        <Avatar
          name={userName}
          src={user.avatar && user.avatar.url}
          className="avatar"
          color="var(--gray)"
          style={{
            boxShadow: id === userId && 'var(--green-primary) 0px 0px 0.2rem',
          }}
        />
      )}
    </S.Container>
  );
};

export default UserAvatar;
