import styled from 'styled-components';

interface ButtonProps {
  width: string;
  alignLeft: boolean;
  alignRight: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  border-radius: 8px;
  background-color: var(--red-primary);
  color: var(--white);
  font-size: 0.75rem;
  letter-spacing: 0.72px;
  font-weight: 600;
  border: none;
  width: ${props => props.width};
  margin: 0 0.5rem;

  &:first-child {
    margin-left: 0;
    margin-right: ${props => props.alignLeft && 'auto'};
  }

  &:last-child {
    margin-right: 0;
  }

  & {
    margin-right: 0;
    margin-left: ${props => props.alignRight && 'auto'};
  }

  &.green {
    background-color: var(--green-primary);
  }

  &.red {
    background-color: var(--red-primary);
  }

  &.cyan {
    background-color: var(--cyan-primary);
  }
`;
