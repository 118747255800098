import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.6rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 2rem;
  max-width: 1500px;

  .cardAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      margin-right: 0.8rem;
    }
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;
  padding: 1rem;

  header {
    display: flex;
    justify-content: space-between;

    p {
      color: ${({ theme }) => theme.text};
    }
  }

  header svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .nameEdit {
    display: flex;
  }
`;

export const EditCard = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background: ${({ theme }) => theme.backgroundPrimary};
  width: 100%;

  header {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    border-bottom: solid 1px ${({ theme }) => theme.backgroundTerciary};
    padding-bottom: 0.5rem;
    padding-top: 0.2rem;
  }

  header svg {
    margin-left: 0.1rem;
    margin-right: 0.5rem;
  }

  footer {
    margin-top: 0.7rem;
  }
`;
