import {
  ReactNode,
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../AuthContext';

import api from '../../services/api';

interface BroadcastContextData {
  broadcast: number;
  setBroadcast: Dispatch<SetStateAction<number>>;
}

interface BroadcastProps {
  children: ReactNode;
}

const BroadcastContext = createContext({} as BroadcastContextData);

function BroadcastProvider({ children }: BroadcastProps) {
  const [broadcast, setBroadcast] = useState(0);

  const history = useHistory();
  const { user } = useAuth();

  const getBroadcasts = useCallback(async () => {
    const response = await api.get('broadcasts/check');

    if (response.data.length) {
      const newBroadcast = response.data[0].id;

      setBroadcast(newBroadcast);

      history.push(`/broadcasts/${newBroadcast}`);
    }
  }, [history]);

  useEffect(() => {
    user && getBroadcasts();
  }, [getBroadcasts, user]);

  return (
    <BroadcastContext.Provider value={{ broadcast, setBroadcast }}>
      {children}
    </BroadcastContext.Provider>
  );
}

function useBroadcast(): BroadcastContextData {
  const context = useContext(BroadcastContext);

  if (!context) {
    throw new Error('useBroadcast must be used within a BroadcastProvider');
  }

  return context;
}

export { BroadcastProvider, useBroadcast };
