import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  tr {
    border: 1px solid ${({ theme }) => theme.text};
  }

  .table-content {
    transition: 0.3s;
    cursor: pointer;
    :hover {
      filter: brightness(70%);
    }
  }
  td {
    padding: 8px;
    justify-content: center;
    align-items: center;
  }
  .table-container {
    background-color: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 5px;
    width: 98%;
  }
  h1 {
    color: var(--red-primary);
    font-size: 25px;
    font-weight: normal;
    padding: 10px;
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
    border: 2px solid ${({ theme }) => theme.backgroundSecondary};
    border-radius: 3px;
    width: 100%;
    color: ${({ theme }) => theme.text};
    letter-spacing: 0px;
    background-color: ${({ theme }) => theme.backgroundSecondary};
    padding: 6px;
    font-size: 17px;
    letter-spacing: 0px;
    padding-top: 15px;
    margin-bottom: 20px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    .table-header {
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.backgroundPrimary};
    }
  }
  tbody button {
    flex: 1;
    background: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 2px;
    border: 0;
    padding: 5px, 0px, 0px;
    color: ${({ theme }) => theme.text};
    font-size: 16px;
    font-family: Work Sans;
    font-style: normal;
  }
  tbody button.ascending::after {
    content: '❯';
    transform: rotate(90deg);
    display: inline-block;
    margin-left: 1em;
  }
  tbody button.descending::after {
    content: '❮';
    transform: rotate(90deg);
    display: inline-block;
    margin-left: 1em;
  }
`;
export const Button = styled.div`
  .table-download-button {
    margin-top: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    flex: 1;
    background: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 2px;
    border: 0;
    padding: 8px 12px;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
    font-family: Arial, Helvetica, sans-serif;
    transition: 0.3s;
    :hover {
      background-color: var(--red-primary);
    }
  }
`;
