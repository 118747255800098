import styled from 'styled-components';
import checkbox from '../../../../assets/images/checkbox.png';

export const Wrapper = styled.label`
  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input[type='checkbox'].checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  input[type='checkbox'].checkbox + label.css-label {
    padding-left: 22px;
    height: 17px;
    display: inline-block;
    line-height: 17px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 17px;
    vertical-align: middle;
    cursor: pointer;
  }

  input[type='checkbox'].checkbox:checked + label.css-label {
    background-position: 0 -17px;
  }

  label.css-label {
    background-image: url(${checkbox});
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;
