import React from 'react';
import * as S from './styles';

interface ModalLabelProps {
  title?: string;
  htmlFor: string;
  width?: string;
}

const ModalLabel: React.FC<ModalLabelProps> = ({
  title,
  htmlFor,
  children,
  width = '48%',
}) => {
  return (
    <S.Label htmlFor={htmlFor} width={width}>
      {title}
      {children}
    </S.Label>
  );
};

const ModalAddressLabel: React.FC<ModalLabelProps> = ({
  title,
  htmlFor,
  children,
  width = '100%',
}) => {
  return (
    <S.AddressLabel htmlFor={htmlFor} width={width}>
      {title}
      {children}
    </S.AddressLabel>
  );
};

const ModalContractLabel: React.FC<ModalLabelProps> = ({
  title,
  htmlFor,
  children,
}) => {
  return (
    <S.ContractLabel htmlFor={htmlFor}>
      {title}
      {children}
    </S.ContractLabel>
  );
};

export { ModalLabel, ModalAddressLabel, ModalContractLabel };
