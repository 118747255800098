import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function changeKanbanTaskOrder(body: {
  id: number;
  include_project_group_progress: boolean;
  project_phase_name: string;
  order: number | null;
}): Promise<{ project: Project } | undefined> {
  try {
    const response = await apiV2.put<{ project: Project }>(`tasks`, body);

    const project = response.data;

    return project;
  } catch {
    toast.error(
      'Ocorreu algum problema, atualize a página e tente novamente 😕'
    );
  }

  return undefined;
}
