/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import Popup from 'reactjs-popup';
import { DateRange } from 'react-date-range';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { format, addBusinessDays, addMinutes } from 'date-fns';
import {
  FiCornerRightUp,
  FiMoreVertical,
  FiCornerRightDown,
} from 'react-icons/fi';
import MultiSelect from 'react-multi-select-component';
import { toast } from 'react-toastify';
import { MdWarning } from 'react-icons/md';
import api from '../../services/api';
import { Container, ScrollContainer } from './styles';
import Table from './pages/Table';
import AproveTable from './pages/AproveTable';
import NewCharge from './pages/NewCharge';
import EditPopup from './pages/EditPopup';
import ContactPopup from './pages/ContactPopup';
import PageController from '../../components/PageController';
import Loading from '../../components/Loadings/Loading';

import { useAuth } from '../../contexts/AuthContext';

export default function Charges() {
  const { id: userId, name: userName, unit_id: unitId } = useAuth().user;
  const [jsonData, setJsonData] = useState([]);
  const [aproveTableData, setAproveTableData] = useState('');
  const [editPopup, setEditPopup] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [itemId, setItemId] = useState();
  const [itemRoyalties, setItemRoyalties] = useState();
  const [itemType, setItemType] = useState('yugu');
  const [newChargePopUp, setNewChargePopUp] = useState(false);
  const [dateUpdate, setDateUpdate] = useState(0);
  const [onLoading, setOnLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [overallUpdate, setOverallUpdate] = useState(0);

  // Specify the set that we'll perform the filter (Visão Geral or Aguardando Aprovação)
  const [filterNotApproved, setFilterNotApproved] = useState(false);

  // Paginação "Aguardando Aprovação"
  const [notApprovedPage, setNotApprovedPage] = useState(1);
  const [startNotApproved, setStartNotApproved] = useState(0);
  const [finishNotApproved, setFinishNotApproved] = useState(0);
  const [countNotApproved, setCountNotApproved] = useState(0);
  const [notApprovedFilters, setNotApprovedFilters] = useState([]);

  // Paginação "Visão Geral"
  const [billingPage, setBillingPage] = useState(1);
  const [startBilling, setStartBilling] = useState(0);
  const [finishBilling, setFinishBilling] = useState(0);
  const [countBilling, setCountBilling] = useState(0);
  const [billingFilters, setBillingFilters] = useState([]);

  // Filtros de ordenação
  const [isAscending, setIsAscending] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [roles, setRoles] = useState([]);
  const [unit, setUnit] = useState([]);
  const [client, setClient] = useState([]);
  const [user, setUser] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [clientSearchResult, setClientSearchResult] = useState([]);
  const [iuguId, setIuguId] = useState('');
  const [cardUnitId, setCardUnitId] = useState(0);
  const [aproveTableLenght, setAproveTableLenght] = useState(0);
  const [dataFilter, setDataFilter] = useState(false);
  const [showAllState, setShowAllState] = useState(false);
  const [showAproveTable, setShowAproveTable] = useState(true);
  const [usersFilter, setUsersFilter] = useState([{}]);
  const [selected, setSelected] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const tableDatesFixer = useCallback(dateString => {
    if (dateString) {
      return `${format(
        addMinutes(new Date(dateString), 500),
        "yyyy-MM-dd'T'HH:mm:ss.SSS"
      )}Z`;
    }

    return null;
  }, []);

  const [dataRanges, setDataRanges] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection',
      color: 'var(--red-primary)',
    },
  ]);

  // Adiciona cor e nome no status
  const handleStatusColor = useCallback(
    (resStatus, ended, date, receivedDate, cancelled) => {
      // By setting the time to be the same here and below, we get to compare
      // only the date without the time.
      const today = new Date().setHours(0, 0, 0, 0);

      if (resStatus === 'Pago / Esperando franqueado') {
        return {
          resStatus: 'Pago / Esperando franqueado',
          resColor: 'var(--cyan-primary)',
        };
      }

      if (cancelled) {
        return {
          resStatus: 'Cancelado',
          resColor: 'var(--red-primary)',
        };
      }

      if (resStatus === 'paid') {
        return {
          resStatus: 'Pago',
          resColor: 'var(--cyan-primary)',
        };
      }

      if (ended) {
        const receivedAt = addBusinessDays(new Date(receivedDate), 1);
        receivedAt.setHours(0, 0, 0, 0);

        if (today >= receivedAt) {
          return {
            resStatus: 'Recebido',
            resColor: 'var(--green-primary)',
          };
        }

        return {
          resStatus: 'Pago',
          resColor: 'var(--cyan-primary)',
        };
      }

      if (!ended) {
        const dueDate = new Date(date).setHours(0, 0, 0, 0);

        if (dueDate < today) {
          return {
            resStatus: 'Atrasada',
            resColor: 'var(--red-primary)',
          };
        }

        return {
          resStatus: 'A receber',
          resColor: 'var(--yellow-primary)',
        };
      }

      return {
        resStatus,
        resColor: 'var(--yellow-primary)',
      };
    },
    []
  );

  // Data que veio da request adicionando matrizValue
  const tableItems = useMemo(() => {
    if (jsonData) {
      const filteredData = [];

      jsonData.forEach(item => {
        const data = item;
        data.matriz_value = item.fee_value * (item.royalties_value / 100);

        // Filtra as datas iugu e recebido na conta
        if (
          tableDatesFixer(new Date(item.received_date)) !==
          tableDatesFixer(new Date(item.due_date))
        ) {
          data.iugu_received_date = tableDatesFixer(
            new Date(item.received_date)
          );
        }
        data.iugu_received_date = tableDatesFixer(
          addBusinessDays(new Date(item.received_date), 2)
        );

        data.final_received_date = tableDatesFixer(
          addBusinessDays(new Date(item.received_date), 1)
        );

        const newStatus = handleStatusColor(
          item.status,
          item.ended,
          item.due_date,
          item.received_date,
          item.cancelled
        );

        data.table_status = newStatus.resStatus;
        data.table_status_color = newStatus.resColor;

        filteredData.push(data);

        return null;
      });

      const finalData = {
        data: filteredData,
      };

      return finalData;
    }

    return null;
  }, [handleStatusColor, jsonData, tableDatesFixer]);

  const formatDate = useCallback(date => {
    const formated = format(new Date(date), 'yyyy-MM-dd');
    return formated;
  }, []);

  const formatFilters = useCallback(
    async ({ dateUpdate, client, unit }) => {
      const filters = {};

      if (dateUpdate) {
        const initialDate = formatDate(dataRanges[0].startDate);
        const finalDate = formatDate(dataRanges[0].endDate);
        filters.initialDate = initialDate;
        filters.finalDate = finalDate;
      }

      const clientArray = [];
      if (client !== '') {
        client.map(newClient => {
          clientArray.push(newClient.value);
          return '';
        });
      }
      filters.clientArray = clientArray;

      const unitArray = [];
      if (unit !== '') {
        unit.map(newUnit => {
          unitArray.push(newUnit.value);
          return '';
        });
      }
      filters.unitArray = unitArray;

      const userArray = [];
      if (user !== '') {
        user.map(newUser => {
          userArray.push(newUser.value);
          return '';
        });
      }
      filters.userArray = userArray;

      return filters;
    },
    [dataRanges, formatDate, user]
  );

  const changePageFilteredNotApproved = useCallback(async () => {
    if (client !== '' || unit !== '') {
      const filters = await formatFilters({ client, unit });

      await api
        .get('billing/not_approved', {
          params: {
            userId:
              roles.includes('finance') && !roles.includes('admin')
                ? userId
                : '',
            clientId: filters.clientArray.join(', '),
            unitId: filters.unitArray.join(', '),
            page: notApprovedPage,
          },
        })
        .then(res => {
          setAproveTableData(res.data.billingsNotApproved);
          if (res.data.startNotApproved !== startNotApproved) {
            setStartNotApproved(res.data.start);
          }

          if (res.data.finishNotApproved !== finishNotApproved) {
            setFinishNotApproved(res.data.finish);
          }

          if (res.data.totalNotApproved !== countNotApproved) {
            setCountNotApproved(res.data.totalNotApproved);
          }
        });
    }
    setTimeout(() => {
      setOnLoading(false);
    }, 3000);
  }, [
    client,
    countNotApproved,
    finishNotApproved,
    formatFilters,
    notApprovedPage,
    roles,
    startNotApproved,
    unit,
    userId,
  ]);

  const changePageFilteredBilling = useCallback(async () => {
    if (
      dateUpdate !== 0 ||
      client !== '' ||
      unit !== '' ||
      status !== '' ||
      isAscending !== '' ||
      orderBy !== ''
    ) {
      const filters = await formatFilters({ dateUpdate, client, unit });
      const isAscendingBool = isAscending === 'ASC';
      const orderByFilled = orderBy !== '' ? orderBy : 'DUE_DATE';

      api
        .get(`billing`, {
          params: {
            clientId: filters.clientArray.join(', '),
            unitId: filters.unitArray.join(', '),
            userId: filters.userArray.join(', '),
            status,
            isAscendent: isAscendingBool,
            sortBy: orderByFilled,
            initialDate: filters.initialDate,
            finalDate: filters.finalDate,
            page: billingPage,
            isReceived: dataFilter,
          },
        })
        .then(response => {
          setJsonData(response.data.billings);
          setOnLoading(false);

          if (response.data.start !== startBilling) {
            setStartBilling(response.data.start);
          }

          if (response.data.finish !== finishBilling) {
            setFinishBilling(response.data.finish);
          }

          if (response.data.totalBillings !== countBilling) {
            setCountBilling(response.data.totalBillings);
          }
        });
    }
    setTimeout(() => {
      setOnLoading(false);
    }, 3000);
  }, [
    countBilling,
    finishBilling,
    startBilling,
    billingPage,
    dateUpdate,
    unit,
    client,
    status,
    orderBy,
    isAscending,
    dataFilter,
    formatFilters,
  ]);

  const changePageBilling = useCallback(async () => {
    if (billingFilters.length > 0) {
      changePageFilteredBilling();
    } else {
      await api
        .get('billing', {
          params: {
            unitId: unitId === 1 ? '' : unitId,
            page: billingPage,
          },
        })
        .then(res => {
          setJsonData(res.data.billings);
          setOnLoading(false);
          if (res.data.start !== startBilling) {
            setStartBilling(res.data.start);
          }

          if (res.data.finish !== finishBilling) {
            setFinishBilling(res.data.finish);
          }

          if (res.data.totalBillings !== countBilling) {
            setCountBilling(res.data.totalBillings);
          }
        });
    }
  }, [
    billingPage,
    unitId,
    billingFilters,
    changePageFilteredBilling,
    countBilling,
    finishBilling,
    startBilling,
  ]);

  const changePageNotApproved = useCallback(async () => {
    if (notApprovedFilters.length > 0) {
      changePageFilteredNotApproved();
    } else {
      await api
        .get('billing/not_approved', {
          params: {
            userId:
              roles.includes('finance') && !roles.includes('admin')
                ? userId
                : '',
            page: notApprovedPage,
          },
        })
        .then(res => {
          setAproveTableData(res.data.billingsNotApproved);
          if (res.data.startNotApproved !== startNotApproved) {
            setStartNotApproved(res.data.start);
          }

          if (res.data.finishNotApproved !== finishNotApproved) {
            setFinishNotApproved(res.data.finish);
          }

          if (res.data.totalNotApproved !== countNotApproved) {
            setCountNotApproved(res.data.totalNotApproved);
          }
        });
    }
  }, [
    notApprovedFilters.length,
    changePageFilteredNotApproved,
    roles,
    userId,
    notApprovedPage,
    startNotApproved,
    finishNotApproved,
    countNotApproved,
  ]);

  const basicRequests = useCallback(async () => {
    setOnLoading(true);
    await Promise.all([
      api.get('roles').then(response => {
        const userRoles = [];
        response.data.forEach(item => {
          userRoles.push(item.role);
        });
        setRoles(userRoles);
      }),

      api
        .get('billing', {
          params: {
            unitId: unitId === 1 ? '' : unitId,
            page: billingPage,
          },
        })
        .then(res => {
          setJsonData(res.data.billings);
          setOnLoading(false);
          if (res.data.start !== startBilling) {
            setStartBilling(res.data.start);
          }

          if (res.data.finish !== finishBilling) {
            setFinishBilling(res.data.finish);
          }

          if (res.data.totalBillings !== countBilling) {
            setCountBilling(res.data.totalBillings);
          }
        }),

      api.get('units').then(response => {
        setUnitList(
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
        );
      }),

      api.get('users').then(res => {
        const users = [];
        res.data.forEach(newUser => {
          users.push({
            label: `${newUser.name} ${newUser.last_name}`,
            value: newUser.id,
          });
        });

        setUsersFilter(users);
      }),

      api.get('clients?finance=true&noUnit=true').then(response => {
        setClientList(
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
        );
      }),
    ]).then(() => {
      setOnLoading(false);
    });
    // O eslint foi desabilitado aqui por que se colocarmos todas as
    // dependências sugeridas no warning, toda vez que o usuário trocar
    // de página ao invés de apenas a tabela ser atualizada, a página
    // será recarregada.

    // eslint-disable-next-line
  }, [unitId]);

  // Chamadas api;
  useEffect(() => {
    basicRequests();
  }, [basicRequests, overallUpdate, status]);

  // Muda página de billings ainda não aprovadas.
  useEffect(() => {
    changePageNotApproved();
  }, [notApprovedPage, changePageNotApproved]);

  // Muda página de billings na visão geral.
  useEffect(() => {
    changePageBilling();
  }, [billingPage, changePageBilling]);

  useEffect(() => {
    if (!filterNotApproved) {
      if (dateUpdate !== 0 && !billingFilters.includes('dateUpdate')) {
        setBillingPage(1);
        setBillingFilters(billingFilters.concat(['dateUpdate']));
      }

      if (unit !== '' && !billingFilters.includes('unit')) {
        setBillingPage(1);
        setBillingFilters(billingFilters.concat(['unit']));
      }

      if (client !== '' && !billingFilters.includes('client')) {
        setBillingPage(1);
        setBillingFilters(billingFilters.concat(['client']));
      }

      if (user !== '' && !billingFilters.includes('user')) {
        setBillingPage(1);
        setBillingFilters(billingFilters.concat(['user']));
      }

      if (status !== '' && !billingFilters.includes('status')) {
        setBillingPage(1);
        setBillingFilters(billingFilters.concat(['status']));
      }

      if (orderBy !== '' && !billingFilters.includes('orderBy')) {
        setBillingPage(1);
        setBillingFilters(billingFilters.concat(['orderBy']));
      }

      if (isAscending !== '' && !billingFilters.includes('isAscending')) {
        setBillingPage(1);
        setBillingFilters(billingFilters.concat(['isAscending']));
      }
    } else {
      if (dateUpdate !== 0 && !notApprovedFilters.includes('dateUpdate')) {
        setNotApprovedPage(1);
        setNotApprovedFilters(notApprovedFilters.concat(['dateUpdate']));
      }

      if (unit !== '' && !notApprovedFilters.includes('unit')) {
        setNotApprovedPage(1);
        setNotApprovedFilters(notApprovedFilters.concat(['unit']));
      }

      if (client !== '' && !notApprovedFilters.includes('client')) {
        setNotApprovedPage(1);
        setNotApprovedFilters(notApprovedFilters.concat(['client']));
      }

      if (user !== '' && !notApprovedFilters.includes('user')) {
        setNotApprovedPage(1);
        setNotApprovedFilters(notApprovedFilters.concat(['user']));
      }
    }
  }, [
    dateUpdate,
    unit,
    client,
    user,
    status,
    orderBy,
    isAscending,
    billingFilters,
    filterNotApproved,
    notApprovedFilters,
  ]);

  // Bilings para aprovação
  useEffect(() => {
    changePageNotApproved();
  }, [roles, userId, changePageNotApproved]);

  // Espera o usuario selecionar duas datas e atualiza uma variavel update;
  const handleDateUpdate = useCallback(
    item => {
      setDataRanges([item.selection]);

      if (item.selection.startDate !== item.selection.endDate) {
        setDateUpdate(dateUpdate + 1);
      }
    },
    [dateUpdate]
  );

  const handleClientsSelect = useCallback(
    search_str => {
      if (search_str === '') {
        setClient([]);
        setClientSearchResult([]);
      }

      const selectElement = document.getElementsByClassName('select-client')[0];
      selectElement.selectedIndex = 0;

      const filteredClients = clientList.filter(client => {
        return client.name.toLowerCase().includes(search_str.toLowerCase());
      });

      const cuttedNames = filteredClients.map(client => {
        if (client.name.length > 15) {
          const cutted = `${client.name.substring(0, 15)}...`;
          return {
            id: client.id,
            name: client.name,
            cuttedName: cutted,
          };
        }
        return {
          id: client.id,
          name: client.name,
          cuttedName: null,
        };
      });

      setClientSearchResult([...cuttedNames]);
    },
    [clientList, setClientSearchResult]
  );

  const multipleDelete = useCallback(async () => {
    setDeleteLoading(true);
    const deletedBillings = [];

    await Promise.all(
      selected.map(async billing => {
        try {
          await api.put('billing/delete', {
            id: billing.id,
          });
          deletedBillings.push(billing.id);
        } catch (err) {
          if (err.response && err.response.status !== 200) {
            let message = '';
            if (err.response.data.error.errors === 'Not Found') {
              message = 'Iugu: Erro, não encontrado.';
            } else {
              message = err.response.data.error;
            }
            toast.error(`${message}`);
          }
        }
      })
    );

    setJsonData(jsonData.filter(item => !deletedBillings.includes(item.id)));
    setSelected([]);
    setDeleteLoading(false);
    toast.success(`Boletos cancelados com sucesso!`);
  }, [selected, jsonData, setJsonData]);

  return (
    <ScrollContainer>
      <Container>
        {onLoading ? <Loading /> : <> </>}
        {roles.includes('finance') ? (
          <>
            <div className="popUp">
              {newChargePopUp ? (
                <NewCharge
                  clientList={clientList}
                  unitList={unitList}
                  setNewChargePopUp={setNewChargePopUp}
                  aproveTableData={aproveTableData}
                  setAproveTableData={setAproveTableData}
                  userId={userId}
                  data={tableItems.data}
                  setJsonData={setJsonData}
                  jsonData={jsonData}
                  setOverallUpdate={setOverallUpdate}
                  overallUpdate={overallUpdate}
                />
              ) : (
                <></>
              )}

              {editPopup ? (
                <EditPopup
                  unitList={unitList}
                  itemId={itemId}
                  userId={userId}
                  itemType={itemType}
                  setEditPopup={setEditPopup}
                  setJsonData={setJsonData}
                  jsonData={jsonData}
                  itemRoyalties={itemRoyalties}
                  setOnLoading={setOnLoading}
                  setOverallUpdate={setOverallUpdate}
                  overallUpdate={overallUpdate}
                  iuguId={iuguId}
                  cardUnitId={cardUnitId}
                />
              ) : (
                <> </>
              )}

              {contactPopup ? (
                <ContactPopup
                  setContactPopup={setContactPopup}
                  itemId={itemId}
                  setOnLoading={setOnLoading}
                  setOverallUpdate={setOverallUpdate}
                  overallUpdate={overallUpdate}
                  userName={userName}
                />
              ) : (
                <> </>
              )}
            </div>
            {/* Filtros  */}
            <div className="options">
              <div className="option">
                <header>
                  <p>Filtrar</p>
                </header>
                <select
                  onChange={() => {
                    setFilterNotApproved(!filterNotApproved);
                  }}
                >
                  <option value="billing" defaultValue>
                    Visão Geral
                  </option>
                  <option value="notApproved">Aguardando Aprovação</option>
                </select>
              </div>

              <div className="option">
                <header>
                  <p>Ordenar</p>
                </header>

                <select
                  value={isAscending}
                  onChange={e => {
                    setIsAscending(e.target.value);
                  }}
                >
                  <option disabled defaultValue value="">
                    Ordenar
                  </option>
                  <option value="DESC">Descendente</option>
                  <option value="ASC">Ascendente</option>
                </select>
              </div>

              <div className="option">
                <header>
                  <p>Ordenar Por</p>
                </header>

                <select
                  value={orderBy}
                  onChange={e => {
                    setOrderBy(e.target.value);
                  }}
                >
                  <option disabled defaultValue value="">
                    Ordenar por
                  </option>
                  <option value="DUE_DATE">Vencimento</option>
                  <option value="RECEIVED_DATE">Pagamento</option>
                  <option value="FEE_VALUE">Valor Fee</option>
                  <option value="ROYALTIES_VALUE">Royalties</option>
                </select>
              </div>

              <div className="option">
                <header>
                  <p>Status</p>
                </header>

                <select
                  value={status}
                  onChange={e => {
                    setStatus(e.target.value);
                  }}
                >
                  <option disabled defaultValue value="">
                    Selecione o status
                  </option>
                  <option value="">Todos</option>
                  <option value="received">Recebido</option>
                  <option value="paid">Pago</option>
                  <option value="to-receive">A receber</option>
                  <option value="late">Atrasado</option>
                </select>
              </div>

              <div className="option">
                <header>
                  <p>Usúario</p>
                </header>

                <MultiSelect
                  className="multi-select"
                  options={usersFilter}
                  value={user}
                  onChange={setUser}
                  labelledBy="Selecione o usúario"
                  hasSelectAll={false}
                  overrideStrings={{
                    selectSomeItems: 'Selecione o usúario',
                    allItemsAreSelected: 'Todos itens selecionados.',
                    selectAll: 'Selecionar todos',
                    search: 'Pesquisar',
                  }}
                />
              </div>

              <div className="clientsSelect">
                <header>
                  <p>Cliente</p>
                </header>
                <div className="outer-div">
                  <div className="search-div">
                    <input
                      placeholder="Buscar..."
                      onChange={e => {
                        handleClientsSelect(e.target.value);
                      }}
                    />
                  </div>
                  <div className="select-div">
                    <select
                      name="clientes"
                      className="select-client"
                      placeholder="Selecione o cliente"
                      multiple={false}
                      onChange={e => {
                        const clientName = e.target.value.split('-')[1];
                        const clientId = e.target.value.split('-')[0];
                        setClient([
                          {
                            label: clientName,
                            value: parseInt(clientId, 10),
                          },
                        ]);
                      }}
                    >
                      <option value="" selected disabled>
                        Não selecionado
                      </option>
                      {clientSearchResult &&
                        clientSearchResult.map(client => {
                          return (
                            <option
                              value={`${client.id}-${client.name}`}
                              title={client.name}
                            >
                              {client.cuttedName
                                ? client.cuttedName
                                : client.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>

              {unitId === 1 ? (
                <div className="option">
                  <header>
                    <p>Unidade</p>
                  </header>

                  <MultiSelect
                    className="multi-select"
                    options={unitList.map(theUnit => {
                      return { label: theUnit.name, value: theUnit.id };
                    })}
                    value={unit}
                    onChange={setUnit}
                    labelledBy="Selecione a unidade"
                    hasSelectAll={false}
                    overrideStrings={{
                      selectSomeItems: 'Selecione a unidade',
                      allItemsAreSelected: 'Todos itens selecionados.',
                      selectAll: 'Selecionar todos',
                      search: 'Pesquisar',
                    }}
                  />
                </div>
              ) : (
                <> </>
              )}

              <div className="option">
                <header>
                  <p>Período</p>
                </header>

                <div className="row">
                  <Popup
                    position="bottom center"
                    trigger={
                      <div className="date">
                        <p>
                          {`${dataRanges[0].startDate.getDate()}/${dataRanges[0].startDate.getMonth() +
                            1}/${dataRanges[0].startDate.getFullYear()}`}
                          :
                          {`${dataRanges[0].endDate.getDate()}/${dataRanges[0].endDate.getMonth() +
                            1}/${dataRanges[0].endDate.getFullYear()}`}
                        </p>
                      </div>
                    }
                    on="click"
                    className="tooltip"
                    arrow={false}
                    contentStyle={{
                      border: 0,
                      boxShadow: 0,
                      padding: '0px',
                      marginTop: '20px',
                    }}
                  >
                    <DateRange
                      editableDateInputs
                      onChange={item => handleDateUpdate(item)}
                      moveRangeOnFirstSelection={false}
                      ranges={dataRanges}
                      showPreview
                      style={{ backgroundColor: 'var(--gray)' }}
                    />
                  </Popup>
                  <Popup
                    position="bottom center"
                    trigger={
                      <div className="more-button">
                        <FiMoreVertical size={16} color="var(--gray)" />
                      </div>
                    }
                    on="click"
                    className="tooltip"
                    arrow={false}
                    contentStyle={{
                      border: 0,
                      boxShadow: 0,
                      padding: '0px',
                      marginTop: '5px',
                      marginLeft: '10px',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      {!dataFilter ? (
                        <button
                          style={{
                            background: 'var(--red-primary)',
                            color: 'var(--white)',
                            width: '100%',
                          }}
                          type="button"
                          className="button"
                        >
                          Vencimento
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="button"
                          onClick={() => {
                            setDataFilter(false);
                            if (dateUpdate !== 0) {
                              setDateUpdate(dateUpdate + 1);
                            }
                          }}
                          style={{ flex: 1, width: '100%' }}
                        >
                          Vencimento
                        </button>
                      )}

                      {dataFilter ? (
                        <button
                          style={{
                            background: 'var(--red-primary)',
                            color: 'var(--white)',
                            width: '100%',
                          }}
                          type="button"
                          className="button"
                        >
                          Recebimento
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setDataFilter(true);
                            if (dateUpdate !== 0) {
                              setDateUpdate(dateUpdate + 1);
                            }
                          }}
                          type="button"
                          className="button"
                          style={{ flex: 1, width: '100%' }}
                        >
                          Recebimento
                        </button>
                      )}
                    </div>
                  </Popup>
                </div>
              </div>
              {unitId === 1 && roles.includes('finance') ? (
                <div className="option">
                  <header>
                    <p>Cobrança</p>
                  </header>
                  <div className="option-selectors">
                    <button
                      onClick={() => setNewChargePopUp(true)}
                      className="button"
                      type="button"
                    >
                      Nova Cobrança / Renovação
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {dateUpdate !== 0 ||
              unit !== '' ||
              client !== '' ||
              status !== '' ||
              isAscending !== '' ||
              orderBy !== '' ||
              showAllState === true ? (
                <div className="option">
                  <header>
                    <p>Reset</p>
                  </header>
                  <div className="option-selectors">
                    <button
                      onClick={() => {
                        setOverallUpdate(overallUpdate + 1);
                        setDateUpdate(0);
                        setShowAllState(false);
                        setDataRanges([
                          {
                            startDate: new Date(
                              new Date().getFullYear(),
                              new Date().getMonth(),
                              1
                            ),
                            endDate: new Date(
                              new Date().getFullYear(),
                              new Date().getMonth() + 1,
                              0
                            ),
                            key: 'selection',
                            color: 'var(--red-primary)',
                          },
                        ]);
                        setClient('');
                        setUnit('');
                        setStatus('');
                        setOrderBy('');
                        setIsAscending('');
                        setBillingFilters([]);
                        setNotApprovedFilters([]);
                      }}
                      className="button"
                      type="button"
                    >
                      Reiniciar filtros
                    </button>
                  </div>
                </div>
              ) : (
                <> </>
              )}
            </div>
            <main>
              {aproveTableLenght !== 0 ? (
                <div className="table-container">
                  <h1>
                    Aguardando Aprovação{' '}
                    <button
                      onClick={() => setShowAproveTable(!showAproveTable)}
                      className="hideButton"
                      type="button"
                    >
                      {showAproveTable ? (
                        <FiCornerRightUp size={14} color="var(--white)" />
                      ) : (
                        <FiCornerRightDown size={14} color="var(--white)" />
                      )}
                    </button>
                  </h1>

                  {showAproveTable && (
                    <AproveTable
                      setJsonData={setAproveTableData}
                      jsonData={aproveTableData}
                      contents={aproveTableData}
                      mainTable={jsonData}
                      setMainTable={setJsonData}
                      setOnLoading={setOnLoading}
                      setOverallUpdate={setOverallUpdate}
                      overallUpdate={overallUpdate}
                      roles={roles}
                      unitId={unitId}
                      setAproveTableLenght={setAproveTableLenght}
                      aproveTableLenght={aproveTableLenght}
                    />
                  )}
                </div>
              ) : (
                // Retira a table caso vazia.
                <div className="table-container" style={{ display: 'none' }}>
                  <h1>Aguardando Aprovação</h1>
                  <AproveTable
                    setJsonData={setAproveTableData}
                    jsonData={aproveTableData}
                    contents={aproveTableData}
                    setOnLoading={setOnLoading}
                    setOverallUpdate={setOverallUpdate}
                    overallUpdate={overallUpdate}
                    roles={roles}
                    unitId={unitId}
                    setAproveTableLenght={setAproveTableLenght}
                  />
                </div>
              )}

              <PageController
                start={startNotApproved}
                finish={finishNotApproved}
                count={countNotApproved}
                page={notApprovedPage}
                setPage={setNotApprovedPage}
              />

              <div className="table-container">
                <h1>Visão Geral</h1>

                {selected.length > 0 && (
                  <button
                    className="delete-selected"
                    type="button"
                    onClick={() => multipleDelete()}
                    disabled={deleteLoading}
                    style={{
                      cursor: deleteLoading ? 'not-allowed' : 'pointer',
                      opacity: deleteLoading ? 0.6 : 1,
                    }}
                  >
                    <MdWarning size={18} /> Deletar selecionados
                  </button>
                )}

                {jsonData && (
                  <Table
                    setItemId={setItemId}
                    setEditPopup={setEditPopup}
                    contents={tableItems.data}
                    setItemType={setItemType}
                    setContactPopup={setContactPopup}
                    setItemRoyalties={setItemRoyalties}
                    setIuguId={setIuguId}
                    userUnitId={unitId}
                    setCardUnitId={setCardUnitId}
                    dateUpdate={dateUpdate}
                    unitId={unitId}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
              </div>

              <PageController
                start={startBilling}
                finish={finishBilling}
                count={countBilling}
                page={billingPage}
                setPage={setBillingPage}
              />

              <ReactHTMLTableToExcel
                className="table-download-button"
                table="main-table"
                filename="TabelaFinanceiro"
                sheet="tablexls"
                buttonText="Baixar tabela Excel"
              />
            </main>
          </>
        ) : (
          <h1 style={{ color: 'var(--red-primary)' }}>
            Sem permissão para isso!
          </h1>
        )}
      </Container>
    </ScrollContainer>
  );
}
