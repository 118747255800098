import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

interface ITaskProgress {
  percentage: number;
  count: number;
  users: User[];
}

interface ITaskProgress {
  lateTasksProgress: ITaskProgress;
  lateDoneTasksProgress: ITaskProgress;
  onTimeTasksProgress: ITaskProgress;
  onTimeDoneTasksProgress: ITaskProgress;
  rescheduleTasksProgress: ITaskProgress;
}

export const getTasksProgress = async (id: string): Promise<ITaskProgress> => {
  try {
    const response = await apiV2.get(`projects/tasksprogress/${id}`);

    const taskProgress = response.data;

    return taskProgress;
  } catch {
    toast.error(
      'Ocorreu algum problema com o progresso das tasks, atualize a página e tente novamente 😕'
    );

    return {} as ITaskProgress;
  }
};
