import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export const createTask = async (
  taskName: string | undefined,
  taskDescription: string,
  phaseId: number | undefined,
  dueDate: Date | null,
  usersIds: (number | undefined)[]
): Promise<editTask> => {
  try {
    const response = await apiV2.post(`tasks`, {
      include_project_group_progress: true,
      name: taskName,
      desc: taskDescription,
      phase_id: phaseId,
      due_date: dueDate,
      users_ids: usersIds,
    });

    const task = response.data;

    return task;
  } catch {
    toast.error(
      'Ocorreu algum problema ao criar a task, atualize a página e tente novamente 😕'
    );

    return {} as editTask;
  }
};
