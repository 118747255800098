import React from 'react';
import {
  RiFoldersLine,
  RiUser3Line,
  RiTimeLine,
  RiLineChartFill,
} from 'react-icons/ri';
import * as S from './styles';

export const DetailsBar: React.FC = () => {
  return (
    <S.Wrapper>
      <div id="deliveries">
        <RiFoldersLine className="icon" size={20} color="var(--red-primary)" />
        <p className="titleText">Entregas</p>
      </div>

      <p> | </p>
      <div id="responsible">
        <RiUser3Line className="icon" size={20} color="var(--red-primary)" />
        <p className="titleText">Responsável</p>
      </div>
      <p> | </p>

      <div id="period">
        <RiTimeLine className="icon" size={20} color="var(--red-primary)" />
        <p className="titleText">Prazo</p>
      </div>
      <p> | </p>

      <div id="progress">
        <RiLineChartFill
          className="icon"
          size={20}
          color="var(--red-primary)"
        />
        <p className="titleText">Progresso</p>
      </div>
    </S.Wrapper>
  );
};
