import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin: 1vh 0 1vh 1.5vw;
`;

export const Label = styled.label`
  color: var(--red-primary);
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Poppins', sans-serif;
  font-size: 0.625rem;
  letter-spacing: 1.8px;

  textarea {
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 8px;
    border: none;
    color: ${({ theme }) => theme.text};
    font-size: 0.75rem;
    font-weight: bold;
    margin-top: 0.5vh;
    min-height: 50px;
    padding: 1vh 1vw;
    resize: none;
  }
`;

export const Users = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 50px;
  align-items: center;
`;

export const Deadline = styled.label`
  color: var(--red-primary);
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-size: 0.625rem;
  letter-spacing: 1.8px;
  width: 14vw;

  p {
    border-radius: 8px;
    border: none;
    color: ${({ theme }) => theme.text};
    font-size: 0.75rem;
    font-weight: bold;
    margin-top: 0.5vh;
    min-height: 50px;
    padding: 1vh 0;
  }
`;

export const SeparatorVertical = styled.hr`
  border-radius: 4px;
  border: 1px solid var(--gray);
  margin: 0 2vh;
  min-height: 16px;
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 1vh;
  padding-top: 1vh;
`;

export const AccordionButton = styled.button`
  align-items: center;
  align-self: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  transition: background-color 0.6s ease;

  svg {
    color: ${({ theme }) => theme.text};
  }

  .rotate {
    transform: rotate(180deg);
  }
`;

export const Accordion = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

export const Phases = styled.div`
  display: flex;
  margin-bottom: 1vh;
  margin-left: 2vw;
  margin-top: 1vh;
  overflow-x: auto;
  padding-bottom: 2vh;

  ::-webkit-scrollbar {
    cursor: pointer;
    height: 1vh;
  }
`;

export const AddNewPhase = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10vh;

  button {
    background-color: transparent;
    border: none;
    transition: 0.3s all;

    &:hover {
      filter: brightness(80%);
    }

    svg {
      color: var(--red-primary);
      font-size: 3rem;
      font-weight: bold;
    }

    p {
      color: ${({ theme }) => theme.text};
      font-size: 0.625rem;
      letter-spacing: 1.8px;
      line-height: 10.28px;
      margin-top: 1vh;
      max-width: 65px;
      text-align: center;
    }
  }
`;

export const SeparatorHorizontal = styled.hr`
  background: var(--gray);
  border-radius: 25%;
  border: 1px solid var(--gray);
  min-height: 4px;
  margin: 1vh 0 1vh 2vw;
`;

export const Tasks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
  margin-left: 2vw;
  margin-top: 1vh;
`;
