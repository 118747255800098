import { FC, useCallback, useState } from 'react';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import api from '../../../../../services/api';
import * as S from './styles';

type Profile = {
  name: string;
  last_name: string;
  avatar: {
    url: string;
  };
};

interface AvatarInputProps {
  profile: Profile;
  file: string;
  updateAvatar: (newAvatar: string) => Promise<void>;
}

const AvatarInput: FC<AvatarInputProps> = ({ profile, file, updateAvatar }) => {
  const [avatarUrl, setAvatarUrl] = useState(
    profile.avatar && profile.avatar.url
  );
  const handleChange = useCallback(
    async e => {
      const data = new FormData();

      data.append('file', e.target.files[0]);

      await api
        .post('files', data)
        .then(res => {
          const { id, url } = res.data;

          updateAvatar(id);
          setAvatarUrl(url);
        })
        .catch(() => {
          toast.error('A imagem é muito grande');
        });
    },
    [updateAvatar]
  );

  return (
    <S.Container>
      <label htmlFor="avatar">
        <Avatar
          name={`${profile.name} ${profile.last_name}`}
          src={avatarUrl}
          className="avatar"
          style={{ border: '0' }}
          size="125"
        />

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
        />
        <S.IconContainer>
          <div />
        </S.IconContainer>
      </label>
    </S.Container>
  );
};

export { AvatarInput };
