import React, { useState, useCallback } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useHistory } from 'react-router-dom';
import { FullScreenHandle } from 'react-full-screen';

import { Button, TextInput } from '../../../../../components/Inputs';
import CheckinSlide from '../../../components/CheckinSlide';
import ChangeEmail from '../../../components/ChangeEmail';

import apiV2 from '../../../../../services/apiV2';
import Animation from '../../../../../assets/animations/AnimationLoading.json';

import * as S from './styles';

interface NewFinalSlide {
  draftId: number;
  quarterId: number;
  handle: FullScreenHandle;
  oldEmails: string[];
}

const FinalSlide: React.FC<NewFinalSlide> = ({
  draftId,
  quarterId,
  handle,
  oldEmails,
}) => {
  const [presentation, setPresentation] = useState('');
  const [endingCheckin, setEndingCheckin] = useState(false);
  const [showEmails, setShowEmails] = useState(false);
  const [emails, setEmails] = useState<string[]>(oldEmails || []);
  const history = useHistory();

  const handleEndCheckin = useCallback(async () => {
    setEndingCheckin(true);
    await apiV2
      .put('/checkinDraft', {
        id: draftId,
        presentation_link: presentation,
      })
      .then(() => {
        apiV2
          .post('checkin', {
            checkinDraftId: draftId,
            quarterId,
            emails,
          })
          .then(() => {
            history.push(`/checkin/quarter/${quarterId}`);
          });
      });
  }, [presentation, draftId, quarterId, history, emails]);

  return (
    <S.Container showEmail={showEmails}>
      <CheckinSlide redWords={['e']}>
        {endingCheckin ? (
          <div className="loadingBox">
            <div className="loadingDiv">
              <Player
                style={{ height: '100%', width: '100%' }}
                autoplay
                loop
                src={Animation}
              />
            </div>
            <h3 style={{ marginTop: '3rem', color: 'var(--red-primary)' }}>
              Finalizando checkin, isso pode demorar um pouco...
            </h3>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="finalSlide">
              <div>
                <div>
                  <header>
                    <h1>INSERIR LINK DA</h1>
                    <h1>APRESENTAÇÃO</h1>
                  </header>
                  <main>
                    <div className="input">
                      <TextInput
                        value={presentation}
                        placeholder="Digite Aqui"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setPresentation(e.target.value);
                        }}
                      />
                    </div>
                  </main>
                </div>
                {showEmails && (
                  <ChangeEmail setEmails={setEmails} emails={emails} />
                )}
              </div>
              <Button
                background="var(--gray)"
                width="100%"
                height="1.6rem"
                text="Sair sem finalizar"
                font="400 0.9rem Work Sans"
                onClick={() => handle.exit()}
              />
              <Button
                background="var(--red-primary)"
                width="100%"
                height="1.6rem"
                text="Finalizar"
                font="400 0.9rem Work Sans"
                onClick={() => handleEndCheckin()}
              />
            </div>

            {!showEmails && (
              <div
                aria-hidden="true"
                onClick={() => setShowEmails(true)}
                className="showEmails"
              >
                <p>Adicionar e-mails</p>
              </div>
            )}
          </div>
        )}
      </CheckinSlide>
    </S.Container>
  );
};

export default FinalSlide;
