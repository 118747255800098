import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;
  transition: 0.3s;

  :hover {
    // Not using color variables because its a shadow.
    box-shadow: 0 4px 8px 0 rgba(227, 36, 56, 0.4),
      0 6px 20px 0 rgba(227, 36, 56, 0.6);
  }

  p {
    font-size: 1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.text};
  }

  a {
    text-decoration: none !important;
  }
`;

export const DataHolder = styled.div`
  margin-bottom: 1rem;

  .info {
    color: ${({ theme }) => theme.text};
    font-weight: 400;
    font-size: 0.7rem;
  }

  .labelHolder {
    display: flex;
    margin-bottom: 0.6rem;
  }

  svg {
    margin-right: 0.3rem;
  }

  .progressBar div {
    border-radius: 8px !important;
  }

  .users {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;
