import styled from 'styled-components';

export const Container = styled.div`
  .lineChart {
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  .lineChart p {
    margin-left: 1rem;
  }

  .okrs {
    height: 50vh;
    overflow-y: auto;
  }

  @media (min-width: 1600px) {
    .lineChart {
      height: 20vh;
    }
  }

  @media (max-width: 1366px) {
    .okrs {
      height: 45vh;
    }
  }
`;
