import React, { useState, useCallback, useEffect } from 'react';
import { Search } from '../../components/Search';
import { Button } from '../../components/Inputs';
import NewBroadcast from './components/NewBroadcast';
import api from '../../services/api';
import PageController from '../../components/PageController';
import BroadcastCard from './components/BroadcastCard';
import { Wrapper, FirstLine, BroadcastsWrapper } from './styles';

function Broadcasts() {
  const [newBroadcast, setNewBroadcast] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [start, setStart] = useState(0);
  const [finish, setFinish] = useState(0);
  const [search, setSearch] = useState('');
  const [broadcasts, setBroadcasts] = useState([]);

  const getBroadcasts = useCallback(async () => {
    await api
      .get(`broadcasts?page=${page}${search && `&search=${search}`}`)
      .then(res => {
        setBroadcasts(res.data.broadcasts);
        if (count !== res.data.count) {
          setCount(res.data.count);
        }
        if (start !== res.data.start) {
          setStart(res.data.start);
        }
        if (finish !== res.data.finish) {
          setFinish(res.data.finish);
        }
      });
  }, [count, finish, page, search, start]);

  useEffect(() => {
    getBroadcasts();
  }, [getBroadcasts]);

  return (
    <Wrapper>
      <FirstLine>
        <Search
          placeholder="Pesquisar comunicado..."
          width="40rem"
          search={search}
          setSearch={setSearch}
        />
        <Button
          background="var(--red-primary)"
          width="8rem"
          height="2rem"
          font="0.8rem"
          onClick={() => setNewBroadcast(true)}
        >
          Novo Comunicado
        </Button>
      </FirstLine>
      <BroadcastsWrapper>
        {broadcasts.map(broadcast => (
          <BroadcastCard key={broadcast.id} broadcast={broadcast} />
        ))}
        <PageController
          start={start}
          finish={finish}
          count={count}
          page={page}
          setPage={setPage}
        />
      </BroadcastsWrapper>
      {newBroadcast && <NewBroadcast setActive={setNewBroadcast} />}
    </Wrapper>
  );
}

export default Broadcasts;
