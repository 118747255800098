import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  color: ${({ theme }) => theme.text} !important;
  main {
    display: flex;
  }

  #confirm-button {
    background: var(--green-primary);
    width: 50%;
    height: 2.1rem;
    font: 400 0.9rem Work Sans;
    color: var(--white);
  }
`;

export const Column = styled.div`
  width: 60%;
  max-height: 70vh;
  :first-of-type {
    padding-right: 1rem;
  }
  :last-of-type {
    margin-left: 2rem;
  }

  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 0.4rem;
    border-radius: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.text};
  }
`;
