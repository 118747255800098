import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function deleteChecklistItem(
  checklistId: number | undefined
): Promise<void> {
  try {
    await apiV2.delete(`phase-checklist-items/${checklistId}`);
  } catch {
    toast.error(
      'Ocorreu algum problema ao deletar o checklist, atualize a página e tente novamente :S'
    );
  }
}
