import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { RiAddCircleLine } from 'react-icons/ri';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import AddNewButton from '../../../components/AddNewButton';
import CloseButton from '../../../components/CloseButton';

import {
  Container,
  Label,
  Deadline,
  SeparatorVertical,
  SeparatorHorizontal,
  Buttons,
  AccordionButton,
  Accordion,
  Phases,
  AddNewPhase,
  Tasks,
} from '../../../styles/GroupContainer';

import Phase from '../Phase';
import Task from '../Task';

import { useExportPlaybook } from '../../../../../../../../contexts/ExportPlaybookContext';

interface GroupProps {
  group: IGroup;
  projectId: number;
  setProjectDueDate: Dispatch<SetStateAction<Date>>;
}

const Group: React.FC<GroupProps> = ({
  group,
  projectId,
  setProjectDueDate,
}) => {
  const [groupDueDate, setGroupDueDate] = useState<Date>(new Date());

  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('');

  const [firstTime, setFirstTime] = useState(true);

  const groupContent = useRef<HTMLDivElement>(null);

  const {
    updateGroupName,
    updateGroupDescription,
    deleteGroup,
    addNewPhase,
    addNewTask,
  } = useExportPlaybook();

  function toggleGroupAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0' : `1500vh`);
    setRotateState(setActive === 'active' ? '' : 'rotate');
  }

  const convertDueDateInDate = useMemo(() => {
    if (groupDueDate.getTime() === -1) return 'Sem prazo';

    const formatedNewDate = format(new Date(groupDueDate), 'dd.MMMM.yyyy', {
      locale: ptBR,
    }).toString();

    return formatedNewDate;
  }, [groupDueDate]);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    }
  }, [firstTime]);

  return (
    <>
      <Container>
        <Label htmlFor="groupName">
          Grupo de entrega
          <textarea
            defaultValue={group.name}
            id="groupName"
            onBlur={e => {
              updateGroupName(projectId, group.id, e.target.value);
            }}
          />
        </Label>
        <SeparatorVertical />
        <Deadline>
          Prazo
          <p>{convertDueDateInDate}</p>
        </Deadline>
        <SeparatorVertical />
        <Label htmlFor="groupDescription">
          Descrição
          <textarea
            defaultValue={group.desc}
            id="groupDescription"
            onBlur={e => {
              updateGroupDescription(projectId, group.id, e.target.value);
            }}
          />
        </Label>
        <Buttons>
          <CloseButton
            onClick={() => deleteGroup(projectId, group.id)}
            disabled={false}
          />
          <AccordionButton
            type="button"
            className={`${setActive}`}
            onClick={toggleGroupAccordion}
          >
            <FaAngleDown className={`${setRotate}`} />
          </AccordionButton>
        </Buttons>
      </Container>
      <Accordion ref={groupContent} style={{ maxHeight: `${setHeight}` }}>
        <Phases>
          {group.phases &&
            group.phases.map((phase, i) => (
              <Phase
                phasesLength={group.phases.length - 1}
                index={i}
                key={phase.id}
                phase={phase}
                projectId={projectId}
                groupId={group.id}
              />
            ))}
          <AddNewPhase>
            <button
              type="button"
              onClick={() => addNewPhase(projectId, group.id)}
            >
              <RiAddCircleLine />
              <p>Adicionar nova fase</p>
            </button>
          </AddNewPhase>
        </Phases>
        <SeparatorHorizontal />
        <Tasks>
          {group.phases &&
            group.phases[0] &&
            group.phases[0].tasks &&
            group.phases[0].tasks.map(task => (
              <Task
                key={task.id}
                task={task}
                projectId={projectId}
                groupId={group.id}
                phaseId={
                  group.phases && group.phases[0].id && group.phases[0].id
                }
                setGroupDueDate={setGroupDueDate}
                setProjectDueDate={setProjectDueDate}
              />
            ))}
          <AddNewButton onClick={() => addNewTask(projectId, group.id)}>
            Adicionar nova task
          </AddNewButton>
        </Tasks>
      </Accordion>
    </>
  );
};

export default Group;
