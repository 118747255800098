import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import apiV2 from '../../../../services/apiV2';

import { TextInput } from '../../../../components/Inputs';

import * as S from './styles';

interface NameChange {
  name: string;
  handleEdit: (name: string) => void;
  checkinId: number;
}

const NameChanger: React.FC<NameChange> = ({ name, handleEdit, checkinId }) => {
  const [newName, setNewName] = useState(name);

  useEffect(() => {
    setNewName(name);
  }, [name]);

  const handleapiV2Update = useCallback(async () => {
    if (name !== newName) {
      await apiV2
        .put('checkinDraft', {
          id: checkinId,
          name: newName,
        })
        .catch(() => {
          toast.error('Erro ao salvar nome.');
        });
    }
  }, [newName, checkinId, name]);

  useEffect(() => {
    const timeoutId = setTimeout(() => handleapiV2Update(), 2500);
    return () => clearTimeout(timeoutId);
  }, [newName, handleapiV2Update]);

  return (
    <S.Container>
      <p>Nome do Checkin</p>
      <TextInput
        value={newName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNewName(e.target.value);
          handleEdit(e.target.value);
        }}
        placeholder="Se desejar mudar o nome, digite aqui"
      />
    </S.Container>
  );
};

export default NameChanger;
