import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FcDocument } from 'react-icons/fc';

import * as S from './styles';

interface props {
  types: string[];
  handleFile: (file: File) => void;
}

export const FileUploader: React.FC<props> = ({ types, handleFile }) => {
  const [content, setContent] = useState(
    <>
      <div>
        <u>Clique aqui</u> ou arreste seus aquivos aqui
      </div>
      <div>
        Formatos aceitos:{' '}
        {types.map((type, i) => {
          if (i > 0) {
            return `, ${type}`;
          }
          return type;
        })}
      </div>
    </>
  );
  const [drag, setDrag] = useState(false);
  const onDrop = useCallback(
    acceptedFiles => {
      setDrag(false);
      const path = acceptedFiles[0].path.split('.');
      const fileFormat = path[path.length - 1];
      if (!types.includes(fileFormat)) {
        setContent(
          <>
            <div>
              Formato{' '}
              <u>
                <b>inválido</b>
              </u>
            </div>
            <div>
              Tente novamente nos formatos{' '}
              {types.map((type, i) => {
                if (i > 0) {
                  return `, ${type}`;
                }
                return type;
              })}
            </div>
          </>
        );
        return;
      }
      const { name } = acceptedFiles[0];

      setContent(
        <>
          <div>
            <FcDocument size={36} />
          </div>
          <div>
            <u>
              <b>{name}</b>
            </u>
          </div>
        </>
      );
      handleFile(acceptedFiles[0]);
    },
    [handleFile, types]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <S.Uploader {...getRootProps()}>
      <S.UploaderHover
        onDragEnter={() => setDrag(true)}
        onDragLeave={() => setDrag(false)}
        drag={drag}
      />

      <input {...getInputProps()} />
      {content}
    </S.Uploader>
  );
};
