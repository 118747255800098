/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/require-default-props */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin2Line } from 'react-icons/ri';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import * as S from './styles';
import { Tag } from '../../../../components/Tag';
import { useTags } from '../../../../../../contexts/TagsContext';
import { Responsibles } from '../../../../components/Responsibles';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { Modal } from '../../../../styles';
import NormalTask from '../../../../components/Modals/NormalTask';
import useProgress from '../../../../../../hooks/useProgress';
import { PopupText } from '../../../../components/PopupText';
import { restoreTask } from '../../../../services/RestoreTask';
import { setTasks } from '../../../../utils/SetTasks';
import { useTasks } from '../../../../../../contexts/TasksContext';

interface TaskCardProps {
  phase: Phase;
  task: Task;
  index: number;
  stickerColor: string;
  setDragDisabled?: Dispatch<SetStateAction<boolean>>;
  opacity: string;
  projectId: number;
  groupId: number;
}

export const TaskCard = ({
  phase,
  task,
  index,
  stickerColor = 'var(--green-primary)',
  setDragDisabled,
  opacity,
  projectId,
  groupId,
}: TaskCardProps): JSX.Element => {
  const { progress, colors } = useProgress();
  const [openModal, setOpenModal] = useState(false);
  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();
  const { projects, setProjects } = useTasks();

  useEffect(() => {
    setTags(() => (task.tags ? task.tags : []));
  }, [task]);

  const periodProgressPercent = useMemo(
    () => progress(task.created_at, task.due_date),
    [progress, task.created_at, task.due_date]
  );

  const progressBarsColors = useMemo(
    () => colors(task.due_date, task.finished_at),
    [colors, task.due_date, task.finished_at]
  );

  const progressTitle = useMemo(
    () => `${task.progress ? Math.round(task.progress * 100) / 100 : 0}%`,
    [task.progress]
  );

  const handleOpenModal = (): void => {
    if (setDragDisabled) {
      setDragDisabled(true);
    }
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    if (setDragDisabled) {
      setDragDisabled(false);
    }
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') handleOpenModal();
    if (setDragDisabled) {
      setDragDisabled(false);
    }
  };

  const handleRestoreTask = useCallback(
    async (taskToRestore: Task) => {
      await restoreTask(taskToRestore.id);

      const taskUndeleted = {
        ...taskToRestore,
        was_removed: false,
      };

      const tasksUpdate = phase.tasks.filter(
        taskItem => taskItem.id !== taskToRestore.id
      );

      tasksUpdate.splice(taskToRestore.order! - 1, 0, taskUndeleted);

      const newProjects = setTasks(
        tasksUpdate,
        projects,
        projectId,
        groupId,
        phase.id
      );

      setProjects(newProjects);
      toast.success('Task restaurada com sucesso');
    },
    [phase, groupId, projectId, projects, setProjects]
  );

  return (
    <>
      <Draggable draggableId={`${task.id}`} index={index}>
        {(provided, snapshot) => (
          <S.Wrapper
            tabIndex={0}
            isDragging={snapshot.isDragging}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <S.Container className="drag">
              {tags && (
                <S.TagsArea>
                  {tags.map((tag: ITag, indexOfTag) => {
                    if (indexOfTag >= 10) {
                      return false;
                    }
                    return (
                      <Tag
                        key={tag.id}
                        tag={tag}
                        type="task_id"
                        typeId={task.id}
                        setTagsList={setTags}
                        showLabel={show}
                        setShowLabel={handleShowTags}
                      />
                    );
                  })}
                </S.TagsArea>
              )}
              <S.Main style={{ opacity }}>
                <div id="name" role="button" onClick={handleOpenModal}>
                  <div>
                    <h4>Task</h4>
                    <PopupText popUpText={task.name}>
                      {task.name.length > 27
                        ? `${task.name.substring(0, 20)}...`
                        : task.name}
                    </PopupText>
                  </div>

                  <button
                    id="edit-button"
                    type="button"
                    onClick={handleOpenModal}
                  >
                    <FaRegEdit size={12} />
                  </button>
                </div>

                <div className="grid-item">
                  <p>|</p>
                  {task.was_removed ? (
                    <S.RestoreTask>
                      <button
                        type="button"
                        title={task.name}
                        onClick={() => handleRestoreTask(task)}
                      >
                        <RiDeleteBin2Line
                          size={14}
                          color="var(--red-primary)"
                        />
                        <p id="restore-label">Restaurar Task</p>
                      </button>
                    </S.RestoreTask>
                  ) : (
                    <div id="managers">
                      <Responsibles users={task.users} />
                    </div>
                  )}
                  <p style={{ marginLeft: '0.5rem' }}>|</p>
                </div>

                <div id="period" className="grid-item">
                  <ProgressDisplay
                    width="95%"
                    height="2rem"
                    borderRadius="0.5rem"
                    percentage={periodProgressPercent}
                    title={
                      task.due_date
                        ? format(new Date(task.due_date), 'dd.MMMM.yyyy', {
                            locale: ptBR,
                          }).toString()
                        : 'Sem prazo'
                    }
                    colorBar={progressBarsColors.dueDateColor}
                  />
                  <p style={{ marginLeft: '0.5rem' }}>|</p>
                </div>

                <div className="grid-item" id="progress">
                  <ProgressDisplay
                    width="95%"
                    height="2rem"
                    borderRadius="0.5rem"
                    title={progressTitle}
                    percentage={task.progress}
                    colorBar={progressBarsColors.progressColor}
                  />
                </div>
              </S.Main>
            </S.Container>
            <S.StickerColor style={{ background: `${stickerColor}` }} />
          </S.Wrapper>
        )}
      </Draggable>
      {openModal && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <NormalTask
            handleClose={handleCloseModal}
            task={task}
            phase={phase}
            tasks={phase.tasks}
            tags={tags}
            projectId={projectId}
            groupId={groupId}
          />
        </Modal>
      )}
    </>
  );
};
