import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 1rem 0;
`;

export const Header = styled.div`
  width: 100%;
  height: 1.5rem;
  align-items: center;
  display: flex;

  .icon {
    margin: 0 0.5rem;
  }

  p {
    color: ${({ theme }) => theme.text};
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const Container = styled.div`
  height: 100%;
  padding-bottom: 10rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;

  ::-webkit-scrollbar {
    height: 0.4rem;
    border-radius: 0.4rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.text};
  }
`;
