import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  RiEmotionLaughFill,
  RiEmotionHappyFill,
  RiEmotionNormalFill,
  RiEmotionUnhappyFill,
  RiEmotionSadFill,
} from 'react-icons/ri';

import { SubmitButton } from './SubmitButton';

import EmoteCard from './EmoteCard';

import * as S from './styles';

const CSAT: React.FC = () => {
  const [selected, setSelected] = useState<number | undefined>();
  const [experience, setExperience] = useState<string>();
  const { id }: { id: string } = useParams();

  return (
    <>
      <S.LogoFooter />
      <S.LogoHeader />
      <S.Container selected={selected}>
        <div className="wrapper">
          <div className="atoms">
            <S.TopAtomo />
            <S.BottomAtomo />
          </div>

          <main>
            <div className="card">
              <header>
                <p>O quanto você ficou satisfeito</p>{' '}
                <p>com esta reunião de checkin?</p>
              </header>

              <div className="cardRating">
                <EmoteCard
                  color="#FF565F"
                  setIcon={setSelected}
                  selected={selected}
                  iconId={1}
                  text="Muito Insatisfeito"
                  icon={RiEmotionSadFill}
                />

                <EmoteCard
                  color="#FC5604"
                  setIcon={setSelected}
                  selected={selected}
                  iconId={2}
                  text="Insatisfeito"
                  icon={RiEmotionUnhappyFill}
                />

                <EmoteCard
                  color="#FFD119"
                  setIcon={setSelected}
                  selected={selected}
                  iconId={3}
                  text="Indiferente"
                  icon={RiEmotionNormalFill}
                />

                <EmoteCard
                  color="#9FD973"
                  setIcon={setSelected}
                  selected={selected}
                  iconId={4}
                  text="Satisfeito"
                  icon={RiEmotionHappyFill}
                />

                <EmoteCard
                  color="#65CB65"
                  setIcon={setSelected}
                  selected={selected}
                  iconId={5}
                  text="Muito Satisfeito"
                  icon={RiEmotionLaughFill}
                />
              </div>
            </div>

            <div className="card">
              <header>
                <p>Fale mais sobre como podemos</p>{' '}
                <p>melhorar a sua experiência com a V4</p>
              </header>

              <div className="textholder">
                <div className="textareaholder">
                  <textarea
                    onChange={e => setExperience(e.target.value)}
                    maxLength={500}
                    placeholder="Digite aqui"
                  />
                </div>

                <SubmitButton
                  id={id}
                  experience={experience}
                  score={selected}
                />
              </div>
            </div>
          </main>
        </div>
      </S.Container>
    </>
  );
};

export default CSAT;
