import React from 'react';

import CheckinSlide from '../../../components/CheckinSlide';

import Card from './Card';

import { CardData } from '../../../components/PremisesAndRisks/types';

import * as S from './styles';

interface PremisesAndRisksSlide {
  cards: CardData[];
}

const PremisesAndRisks: React.FC<PremisesAndRisksSlide> = ({ cards }) => {
  return (
    <CheckinSlide redWords={['p', 'r2']}>
      <S.Container>
        <p className="title">Premissas & Riscos</p>
        <main>
          <div className="cards">
            {cards.map(card => {
              return <Card key={card.id} cardInfo={card} />;
            })}
          </div>
          <S.RightBar>
            <div className="numberColumn">
              <div>
                <p className="warn">Prevenir</p>
              </div>

              <div className="numbers">
                <S.NumberHolder background="var(--red-primary)">
                  100
                </S.NumberHolder>
                <S.NumberHolder background="var(--red-primary)">
                  90
                </S.NumberHolder>
                <S.NumberHolder background="var(--red-primary)">
                  80
                </S.NumberHolder>
                <S.NumberHolder background="var(--red-primary)">
                  70
                </S.NumberHolder>
                <S.NumberHolder background="var(--red-primary)">
                  60
                </S.NumberHolder>
                <S.NumberHolder background="var(--red-primary)">
                  50
                </S.NumberHolder>
              </div>
            </div>
            <div className="numberColumn">
              <p className="warn">Mitigar</p>
              <div className="numbers">
                <S.NumberHolder background="var(--yellow-primary)">
                  40
                </S.NumberHolder>
                <S.NumberHolder background="var(--yellow-primary)">
                  30
                </S.NumberHolder>
              </div>
            </div>
            <div className="numberColumn">
              <p className="warn">Aceitar</p>
              <div className="numbers">
                <S.NumberHolder background="var(--green-primary)">
                  20
                </S.NumberHolder>
                <S.NumberHolder background="var(--green-primary)">
                  10
                </S.NumberHolder>
                <S.NumberHolder background="var(--green-primary)">
                  0
                </S.NumberHolder>
              </div>
            </div>
          </S.RightBar>
        </main>
      </S.Container>
    </CheckinSlide>
  );
};

export default PremisesAndRisks;
