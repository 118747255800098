import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 5.5vh;
  z-index: 90;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  box-shadow: 0px 8px 6px ${({ theme }) => theme.boxShadow};
`;

export const Separator = styled.hr`
  margin: 0 1rem 0 1.25rem;
  height: 1rem;
  border: 1px solid var(--gray);
  opacity: 0.6;
`;

export const SideContainer = styled.div`
  position: absolute;
  width: 8%;
  height: 5.5vh;
  background: var(--red-primary);

  @media (min-width: 1366px) {
    width: 16%;
  }

  @media (min-width: 1900px) {
    width: 25%;
  }
`;

export const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const SideBarHeader = styled.div`
  max-width: 18rem;
  height: 100%;
  width: 16rem;
  min-width: 16rem;
  display: flex;
  align-items: center;
  border-radius: 0 2rem 2rem 0;
  background: var(--red-primary);
  position: relative;
  padding: 0 0.5rem;

  h3 {
    color: var(--white);
  }
`;

export const HamburguerMenu = styled.div`
  display: flex;
  width: 20%;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    padding-top: 3px;

    input {
      display: block;
      width: 3rem;
      height: 5.5vh;
      position: absolute;
      top: -0.5rem;
      left: -0.625rem;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      -webkit-touch-callout: none;
    }

    &.active {
      span {
        opacity: 1;
        transform: rotate(45deg) translate(-0.3125rem, -0.875rem);
      }

      span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 0.625rem);
      }

      ul {
        transform: none;
      }
    }

    span {
      display: block;
      width: 1.5rem;
      height: 3px;
      margin: 2px 0;
      position: relative;
      background: var(--white);
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:hover {
        opacity: 0.7;
      }

      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
  }
`;

export const PageName = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: row;
  align-items: center;

  h4 {
    font-size: 1rem;
    color: ${({ theme }) => theme.text};
    margin-left: 1.5rem;
    font-weight: 600;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const AccountDetails = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 590px) {
    display: none;
  }
`;

export const ContainerOptions = styled.div`
  display: flex;
  align-items: center;
  transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  position: relative;

  &.active {
    transform: translate(-9rem, 0);
  }
`;

export const ThemeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${({ theme }) => theme.text};
  }
`;

export const UserOptions = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  transition: all 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  margin-left: -2rem;

  button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    letter-spacing: 0.84px;
    font-size: 0.75rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.text};
    margin-right: 0.75rem;
  }

  a {
    display: flex;
    align-items: center;
    letter-spacing: 0.84px;
    font-size: 0.75rem;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
  }

  svg {
    margin-right: 0.35rem;
    width: 0.875rem;
    height: 0.875rem;
    font-size: 0.75rem;
    color: var(--red-primary);
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
`;

export const User = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;

  button {
    border: none;
    background: transparent;
  }

  .avatar {
    border-radius: 50%;
    max-width: 30px;
    max-height: 30px;
    width: auto;
    height: auto;
  }
`;
