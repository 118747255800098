import { FC, useCallback } from 'react';
import {
  RiArrowRightLine,
  RiArrowLeftLine,
  RiEraserLine,
} from 'react-icons/ri';
import { format, subMonths, addMonths, isSameMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface HeaderProps {
  value: Date | null | undefined;
  setValue: React.Dispatch<React.SetStateAction<Date | null | undefined>>;
  blockPast: boolean;
  remove: boolean;
}

const Header: FC<HeaderProps> = ({ value, setValue, blockPast, remove }) => {
  const currMonthName = useCallback((): string => {
    let currMonth = '';
    if (!value) {
      currMonth = format(new Date(), 'MMMM', { locale: ptBR });
      return currMonth.charAt(0).toUpperCase() + currMonth.slice(1);
    }

    currMonth = format(value, 'MMMM', { locale: ptBR });
    return currMonth.charAt(0).toUpperCase() + currMonth.slice(1);
  }, [value]);

  const currYear = useCallback((): string => {
    if (!value) {
      return format(new Date(), 'yyyy');
    }

    return format(value, 'yyyy');
  }, [value]);

  const prevMonth = useCallback((): Date => {
    if (!value) {
      return subMonths(new Date(), 1);
    }

    return subMonths(value, 1);
  }, [value]);

  const nextMonth = useCallback((): Date => {
    if (!value) {
      return addMonths(new Date(), 1);
    }

    return addMonths(value, 1);
  }, [value]);

  const thisMonth = useCallback((): boolean => {
    if (!value) {
      return isSameMonth(new Date(), new Date());
    }

    return isSameMonth(value, new Date());
  }, [value]);

  return (
    <div className="wrapper">
      <div className="header">
        <div
          className="previous"
          role="button"
          aria-hidden
          onClick={() =>
            blockPast
              ? !thisMonth() && setValue(prevMonth())
              : setValue(prevMonth())
          }
        >
          {blockPast ? (
            !thisMonth() && <RiArrowLeftLine className="icon" size={16} />
          ) : (
            <RiArrowLeftLine className="icon" size={16} />
          )}
        </div>
        <div className="current">
          {currMonthName()} {currYear()}
        </div>
        <div
          role="button"
          aria-hidden
          className="next"
          onClick={() => setValue(nextMonth())}
        >
          <RiArrowRightLine className="icon" size={16} />
        </div>
      </div>

      {remove && (
        <button
          type="button"
          className="removeDate"
          onClick={() => setValue(null)}
          style={{
            background: 'none',
            border: 'none',
            color: 'var(--white)',
            font: '600 0.9rem Work Sans',
          }}
        >
          <RiEraserLine className="icon" size="16" />
          <p>Limpar</p>
        </button>
      )}
    </div>
  );
};

export default Header;
