import axios from 'axios';

const apiV2 = axios.create({
  baseURL: 'https://api.laboratoriodomarketing.com/v2',
});

apiV2.interceptors.response.use(
  response => response,
  err => {
    if (err.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    return Promise.reject(err);
  }
);

export default apiV2;
