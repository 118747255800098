import * as S from './styles';

export const Loading = (): JSX.Element => {
  return (
    <S.Wrapper>
      <S.Container id="name">
        <S.NameBar className="animation" />
        <S.ProgressBar className="animation" />
      </S.Container>
      <S.Container id="period">
        <S.NameBar className="animation" />
        <S.ProgressBar className="animation" />
      </S.Container>

      <S.Container id="progress">
        <S.NameBar className="animation" />
        <S.ProgressBar className="animation" />
      </S.Container>
      <S.Container id="responsibles">
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
        <S.AvatarIcon className="animation" />
      </S.Container>
    </S.Wrapper>
  );
};

export const ProjectCardLoading = (): JSX.Element => {
  return (
    <>
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
    </>
  );
};

export const GroupOrTaskLoading = (): JSX.Element => {
  return (
    <S.Content>
      <div id="name">
        <S.ProgressBar className="animation progress" />
        <S.ProgressBar className="animation progress" />
      </div>
      <div id="period">
        <S.ProgressBar className="animation progress" />
        <S.ProgressBar className="animation progress" />
      </div>
      <div id="managers">
        <S.ProgressBar className="animation" />
        <div id="responsibles">
          <S.AvatarIcon className="animation" />
          <S.AvatarIcon className="animation" />
          <S.AvatarIcon className="animation" />
          <S.AvatarIcon className="animation" />
          <S.AvatarIcon className="animation" />
          <S.AvatarIcon className="animation" />
        </div>
      </div>
    </S.Content>
  );
};
