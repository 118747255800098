/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useState } from 'react';
import { FiCornerRightUp, FiCornerRightDown } from 'react-icons/fi';
import api from '../../services/api';
import { Container } from './styles';
import Loading from '../../components/Loadings/Loading';

import { useAuth } from '../../contexts/AuthContext';
import RejectedTable from './pages/RejectedTable';

export default function RejectedBills() {
  const { unit_id: unitId } = useAuth().user;
  const [jsonData, setJsonData] = useState([]);
  const [rejectedTableData, setRejectedTableData] = useState('');
  const [onLoading, setOnLoading] = useState(true);
  const [overallUpdate, setOverallUpdate] = useState(0);

  const [roles, setRoles] = useState([]);
  const [showRejectedTable, setShowRejectedTable] = useState(true);

  const basicRequests = useCallback(async () => {
    setOnLoading(true);
    await Promise.all([
      api.get('roles').then(response => {
        const userRoles = [];
        response.data.forEach(item => {
          userRoles.push(item.role);
        });
        setRoles(userRoles);
      }),

      api.get('billing/rejected').then(res => {
        setRejectedTableData([...res.data.billingsRejected]);
      }),
    ]).then(() => {
      setOnLoading(false);
    });
  }, []);

  // Chamadas api;
  useEffect(() => {
    basicRequests();
  }, [basicRequests, overallUpdate]);

  return (
    <Container>
      {onLoading ? <Loading /> : <> </>}
      {roles.includes('finance') ? (
        <>
          <main>
            <div className="table-container">
              <h1>
                Rejeitado nos últimos 30 dias{' '}
                <button
                  onClick={() => setShowRejectedTable(!showRejectedTable)}
                  className="hideButton"
                  type="button"
                >
                  {showRejectedTable ? (
                    <FiCornerRightUp size={14} color="var(--white)" />
                  ) : (
                    <FiCornerRightDown size={14} color="var(--white)" />
                  )}
                </button>
              </h1>

              {showRejectedTable && (
                <RejectedTable
                  setJsonData={setRejectedTableData}
                  jsonData={rejectedTableData}
                  contents={rejectedTableData}
                  mainTable={jsonData}
                  setMainTable={setJsonData}
                  setOnLoading={setOnLoading}
                  setOverallUpdate={setOverallUpdate}
                  overallUpdate={overallUpdate}
                  roles={roles}
                  unitId={unitId}
                  aproveTableLenght={1}
                />
              )}
            </div>
          </main>
        </>
      ) : (
        <h1 style={{ color: 'var(--red-primary)' }}>
          Sem permissão para isso!
        </h1>
      )}
    </Container>
  );
}
