import React, { useState, useEffect, useCallback } from 'react';

import { RiDeleteBinLine } from 'react-icons/ri';
import * as S from '../styles';

import Edit from '../Edit';

import { TextInput } from '../../../../../../components/Inputs';

interface Card {
  card: {
    name: string;
    id: number;
    value: number;
  };
  editCard: (
    newCard: { name: string; id: number; value: number },
    update: boolean
  ) => void;
  deleteCard: (id: number) => void;
  i: number;
  cardsLenght: number;
}

const FunnelCard: React.FC<Card> = ({
  card,
  editCard,
  i,
  cardsLenght,
  deleteCard,
}) => {
  const [input, setInput] = useState<number | null>(null);

  useEffect(() => {
    setInput(card.value / 100);
  }, [card.value]);

  const callUpdateFunction = useCallback(() => {
    if (input !== card.value / 100) {
      editCard(
        {
          name: card.name,
          value: input === null ? 0 : input,
          id: card.id,
        },
        true
      );
    }
  }, [input, editCard, card]);

  useEffect(() => {
    const timeoutId = setTimeout(() => callUpdateFunction(), 2000);
    return () => clearTimeout(timeoutId);
  }, [input, callUpdateFunction]);

  return (
    <S.Card>
      <header>
        <div className="nameEdit">
          <p>{card.name}</p>
          <Edit editCard={editCard} card={card} />
        </div>
        {i !== 0 && i !== cardsLenght - 1 && (
          <RiDeleteBinLine
            size={16}
            color="var(--red-primary)"
            onClick={() => deleteCard(card.id)}
          />
        )}
      </header>

      <TextInput
        type="number"
        placeholder="Escreva aqui o valor do funil"
        value={input === null ? undefined : input}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInput(Number(e.target.value));
        }}
      />
      {input !== null && input > 9999999 && (
        <p style={{ color: 'var(--red-primary)' }}>
          Números maiores que 9999999 não serão salvos!
        </p>
      )}
    </S.Card>
  );
};

export default FunnelCard;
