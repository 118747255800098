import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export async function deleteTask(taskId: number): Promise<void> {
  try {
    await apiV2.delete(`/tasks/${taskId}`);
  } catch {
    toast.error(
      'Ocorreu algum problema ao deletar as tasks, atualize a página e tente novamente :S'
    );
  }
}
