import styled from 'styled-components';

interface HeaderProps {
  isUser: boolean;
}

export const Container = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text};
  h2 {
    color: ${({ theme }) => theme.text};
  }
`;
export const History = styled.div`
  width: 40%;
`;
export const Topic = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  pre {
    padding: 10px;
  }

  div {
    align-items: flex-start;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
`;
export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: initial;
  align-items: center;

  color: ${({ theme }) => theme.text};
  > * {
    margin-right: 5px;
  }

  .avatar {
    border: ${props => {
      if (props.isUser) {
        return '1px solid var(--green-primary)';
      }
      return '1px solid var(--black)';
    }};
    border-radius: 50%;
    margin-right: 1rem;
    padding: 0px;
    width: 36px !important;
    height: 36px !important;
    min-width: 36px !important;
    min-height: 36px !important;
    font-size: 36px;
  }
`;

export const Replies = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Reply = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  pre {
    margin-top: 10px;
  }
`;
export const Vr = styled.div`
  width: 2px;
  max-height: 2000px;
  background: var(--gray);
  margin: 0 10px;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
  label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-top: 10px;

    textarea {
      margin-top: 5px;
      background: ${({ theme }) => theme.backgroundPrimary};
      color: ${({ theme }) => theme.text};
      border: 1px solid ${({ theme }) => theme.backgroundPrimary};
      border-radius: 6px;
      padding: 10px;
      max-width: 100%;
      min-width: 100%;
      min-height: 40px;
      max-height: 400px;
    }
    input {
      margin-top: 5px;
      background: ${({ theme }) => theme.backgroundPrimary};
      color: ${({ theme }) => theme.text};
      border: 1px solid ${({ theme }) => theme.backgroundPrimary};
      border-radius: 6px;
      padding: 10px;
      width: 100%;
    }
  }
  button {
    margin-top: 20px;
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);

    :hover {
      filter: brightness(90%);
    }
  }
`;
