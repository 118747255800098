import styled from 'styled-components';

export const Container = styled.div`
  .select-search {
    width: 14rem;
    position: relative;
    font: 700 1.6rem Work Sans;
    input {
      border: 0 !important;
    }
  }
  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }
  .select-search__value {
    position: relative;
    z-index: 1;
  }
  .select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }
  .select-search__input {
    width: 100%;
    height: 3rem;
    border-radius: 8px;
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.backgroundSecondary};
    outline: 0;
    padding: 0 1rem;
    font: 700 1rem Work Sans;
    ::placeholder {
      color: ${({ theme }) => theme.text};
      opacity: 70%;
    }
  }
  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }
  .select-search__select {
    background: ${({ theme }) => theme.backgroundSecondary};
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }
  .select-search__options {
    list-style: none;
    color: ${({ theme }) => theme.text};
    height: 9rem;
  }
  .select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: ${({ theme }) => theme.backgroundSecondary};
    border: 0;
    outline: none;
    font: 700 1.6rem Work Sans;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    color: ${({ theme }) => theme.text};
  }
  .select-search--multiple .select-search__option {
    height: 48px;
  }
  .select-search__option.is-selected {
    background: var(--red-primary);
    color: ${({ theme }) => theme.text};
  }
  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: var(--red-primary);
    color: var(--white);
  }
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: var(--red-primary);
    color: ${({ theme }) => theme.text};
  }
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: ${({ theme }) => theme.backgroundSecondary};
    padding: 8px 16px;
  }
  .select-search.is-disabled {
    opacity: 0.5;
  }
  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }
  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    display: none;
  }
  .select-search--multiple .select-search__input {
    cursor: initial;
  }
  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }
  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
  }
  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    border-radius: 0 0 3px 3px;
  }
  .select-search {
    width: 100%;
  }
`;
