import styled from 'styled-components';

export const Container = styled.div`
  min-height: 4.8rem;
  max-height: 4.8rem;
  position: static;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;

  .doughnut-chart {
    width: 3rem;
    fill: ${({ theme }) => theme.text};
  }
`;

export const TooltipArea = styled.div`
  width: 20%;
  position: relative;

  @media (max-width: 1667px) {
    width: 25%;
  }
`;
