import styled from 'styled-components';

export const LabelContainer = styled.label`
  display: flex;
  margin-top: 0.3rem;

  input[type='checkbox'] {
    background-color: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 3px;
    border: 2px solid var(--gray);
    cursor: pointer;
    min-height: 20px;
    min-width: 19px;
    position: relative;
  }
  input[type='checkbox']:before {
    background-color: ${({ theme }) => theme.backgroundSecondary};
    border-radius: 3px;
    border: 2px solid var(--gray);
    content: '';
    display: block;
    left: 0;
    min-height: 16px;
    min-width: 16px;
    position: absolute;
    top: 0;
  }
  input[type='checkbox']:checked:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-width: 0 2px 2px 0;
    border: solid ${({ theme }) => theme.backgroundPrimary};
    content: '';
    display: block;
    left: 6px;
    min-height: 10px;
    min-width: 5px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
  }

  input[type='text'] {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.text};
    flex: 1;
    font-size: 0.7rem;
    letter-spacing: 0.72px;
    line-height: 13px;
    margin-left: 1vw;
    width: 75%;
  }

  span {
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      opacity: 0.4;
    }
  }
  input + button {
    align-self: center;

    svg {
      font-size: 0.75rem;
    }
  }
`;
