import React from 'react';

import Okr from './Okr';

import { Okrs } from './types';

import { Container } from './styles';

interface ReKrsAndOkrs {
  okrs: Okrs[];
  presentation?: boolean;
}

const KrsAndOkrsView: React.FC<ReKrsAndOkrs> = ({
  okrs,
  presentation = false,
}) => {
  const newOkrs = okrs;

  newOkrs.sort((a: { name: string }, b: { name: string }) => {
    return a.name.localeCompare(b.name);
  });

  return (
    <Container>
      <div className="okrs">
        {newOkrs.map((okr, i) => {
          return <Okr presentation={presentation} okr={okr} i={i} />;
        })}
      </div>
    </Container>
  );
};

export default KrsAndOkrsView;
