import styled from 'styled-components';

interface ProgressBarProps {
  percentage: string;
}

export const Container = styled.div`
  width: 100%;
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  width: 100%;
  height: 20px;
  background: ${({ theme }) => theme.backgroundTerciary};
  border-radius: 3px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8vw;
  align-items: center;
  text-align: center;
  display: flex;
  align-content: initial !important;
  justify-content: initial !important;
  padding: 0 !important;
  position: relative;
  * {
    margin-top: 0;
  }
  .progress {
    height: 100%;
    max-width: 100%;
    width: ${props => props.percentage}% !important;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }
  h5 {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
    color: var(--white);
  }
`;
