import styled, { css } from 'styled-components';

interface StyledDivProps {
  isDragging: boolean;
}

export const Wrapper = styled.div<StyledDivProps>`
  display: flex;
  height: 5.5rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  margin-bottom: 0.5rem;
  align-items: center;
  transition: 0.3s;

  button {
    border: none;
    background: none;
    text-align: left;
  }

  :hover {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(227, 36, 56, 0.3);
  }

  ${props =>
    props.isDragging &&
    css`
      border: 2px dashed var(--gray);
      padding-top: 31px;
      border-radius: 1rem;
      background: transparent;
      box-shadow: none !important;
      cursor: grabbing;
      .drag {
        opacity: 0;
      }
    `}

  @media (max-width: 1290px) {
    height: 7rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TagsArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.4rem;
  margin-left: 1rem;
`;

export const Main = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);

  grid-gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  .grid-item {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;

    p:last-child {
      color: var(--gray);
      margin-left: 0.1rem;
    }

    p:first-child {
      color: var(--gray);
      margin-right: 0.1rem;
    }
  }

  .nameAndEdit {
    border-left: solid 0px black;
    border-right: solid 0px black;
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      color: ${({ theme }) => theme.text} !important;
    }
  }

  #name {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    cursor: pointer;

    p {
      margin-top: 0.3rem;
      color: ${({ theme }) => theme.text};
    }

    h4 {
      color: var(--red-primary);
      font-weight: 400;
      font-size: 0.67rem;
    }
  }

  #managers {
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
  }
  #period {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #progress {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #add-new {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      svg {
        color: ${({ theme }) => theme.text};
      }
    }
  }

  #edit-button {
    svg {
      color: ${({ theme }) => theme.text};
    }
  }
`;

export const EmptyIndividualTask = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 0.4rem;
  color: ${({ theme }) => theme.text};
  font-size: 0.7rem;
`;

export const StickerColor = styled.div`
  justify-content: flex-end;
  width: 0.5%;
  height: 100%;
  background-color: var(--red-primary);
  border-radius: 0 1rem 1rem 0;
  outline: none;
`;

export const RestoreProject = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  button {
    display: flex;
    align-items: center;
    color: var(--gray);
    background: none;
    border: none;
    #restore-label {
      margin: 0 0.5rem;
    }
  }
`;
