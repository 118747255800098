import { toast } from 'react-toastify';
import api from '../../../services/api';

interface Props {
  id: number;
}

interface Post {
  id: number;
  name: string;
  published: string;
  createdAt: Date;
  voteId: number;
  problem: string;
  reasons: string;
  solutions: string;
  youtube: string;
  userVote: number;
  votes: number;
}

export async function getWikiPostService({
  id,
}: Props): Promise<Post | undefined> {
  try {
    const response = await api.get(`wiki_posts/${id}`);

    return response.data;
  } catch {
    toast.error('Falha ao buscar post!');
    return undefined;
  }
}
