import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export const deleteTag = async (
  id: number,
  type: string,
  typeId: number
): Promise<void> => {
  try {
    await apiV2.delete(`tags/${id}?${type}=${typeId}`);
  } catch {
    toast.error(
      'Ocorreu algum problema, atualize a página e tente novamente 😕'
    );
  }
};
