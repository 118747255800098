/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { RiFocusLine, RiDeleteBin2Line } from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import * as S from './styles';
import { Tag } from '../../../../components/Tag';
import { useTags } from '../../../../../../contexts/TagsContext';
import { Modal } from '../../../../styles';
import { Responsibles } from '../../../../components/Responsibles';
import { ProgressDisplay } from '../../../../components/ProgressDisplay';
import { ItenTypes } from '../../../../types';
import { EditIndividualTask } from '../../../../components/Modals/EditIndividualTask';
import useProgress from '../../../../../../hooks/useProgress';
import { PopupText } from '../../../../components/PopupText';
import { finishTask } from '../../../../services/FinishTask';
import { restoreTask } from '../../../../services/RestoreTask';
import { useTasks } from '../../../../../../contexts/TasksContext';
import { editIndividualTasks } from '../../../../utils/EditIndividualTasks';

type DragItem = {
  index: number;
  task: Task;
  name: string;
  type: string;
};

interface IndividualTaskCardProps {
  projectId: number;
  task: Task;
  move: (from: number, to: number, type: string, task: Task | Group) => void;
  index: number;
  tasks?: Task[];
}

export const IndividualTaskCard = ({
  projectId,
  task,
  move,
  index,
  tasks,
}: IndividualTaskCardProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const { projects, setProjects } = useTasks();
  const { progress, colors } = useProgress();
  const [finished, setFinished] = useState(!!task.finished_at);
  const [openModal, setOpenModal] = useState(false);
  const [tags, setTags] = useState<ITag[]>([]);
  const { show, handleShowTags } = useTags();

  useEffect(() => {
    setTags(() => (task.tags ? task.tags : []));
  }, [task]);

  const periodProgressPercent = useMemo(() => {
    if (!task.due_date) {
      return 0;
    }
    return progress(task.created_at, task.due_date);
  }, [progress, task.created_at, task.due_date]);

  const progressBarsColors = useMemo(
    () => colors(task.due_date, task.finished_at),
    [colors, task.due_date, task.finished_at]
  );

  const progressTitle = useMemo(
    () => `${task.progress ? Math.round(task.progress * 100) / 100 : 0}%`,
    [task.progress]
  );

  const invertModalVisibility = (): void => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'modal') invertModalVisibility();
  };

  const handleCheckbox = useCallback(async () => {
    if (!task.users.length) {
      toast.warn(
        'Para ser concluída uma task individual precisa de um responsável.'
      );
      return;
    }
    const finishedAt = task.finished_at ? null : new Date();
    setFinished(!finished);
    const project_phase_name = finishedAt ? 'DONE' : 'DOING';
    const taskFinished = await finishTask(
      task.id,
      finishedAt,
      project_phase_name
    );

    if (taskFinished && taskFinished !== undefined) {
      if (tasks) {
        const taskUpdate = tasks.filter(taskItem => taskItem.id !== task.id);

        taskUpdate.splice(taskFinished.order! - 1, 0, taskFinished);
        const projectsWithIndividualTaskUpdated = editIndividualTasks(
          taskUpdate,
          projects,
          projectId
        );

        setProjects(() => projectsWithIndividualTaskUpdated);
        toast.success('Task individual atualizada com sucesso.');
      }
    }
  }, [finished, task, tasks, projectId, projects, setProjects]);

  const [{ isDragging }, drag] = useDrag({
    item: {
      index,
      task,
      name: 'Individual Task Card',
      type: ItenTypes.INDIVIDUAL_TASK,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItenTypes.INDIVIDUAL_TASK,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = (item as DragItem)?.index;
      const hoverIndex = index;
      const taskSelected = (item as DragItem)?.task;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref?.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor?.getClientOffset();

      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      move(dragIndex, hoverIndex, 'task', taskSelected);

      const itemIndex = (
        receveidItem: DragObjectWithType,
        hoverIdx: number
      ): number => {
        const itemAny = receveidItem as DragItem;
        itemAny.index = hoverIdx;
        return itemAny.index;
      };

      itemIndex(item, hoverIndex);
    },
  });

  const handleRestoreTask = useCallback(
    async (taskToRestore: Task) => {
      await restoreTask(taskToRestore.id);

      const taskUndeleted = {
        ...taskToRestore,
        was_removed: false,
      };

      const tasksUpdate = tasks!.filter(
        taskItem => taskItem.id !== taskToRestore.id
      );

      tasksUpdate.splice(taskToRestore.order! - 1, 0, taskUndeleted);

      const projectsWithIndividualTaskUpdated = editIndividualTasks(
        tasksUpdate,
        projects,
        projectId
      );

      setProjects(() => projectsWithIndividualTaskUpdated);
      toast.success('Task Individual restaurada com sucesso');
    },
    [tasks, projectId, projects, setProjects]
  );

  drag(drop(ref));

  return (
    <>
      <S.Wrapper ref={ref} isDragging={isDragging}>
        <S.Container className="drag">
          {tags && (
            <S.TagsArea>
              {tags.map((tag: ITag, indexOfTag) => {
                if (indexOfTag >= 10) {
                  return false;
                }
                return (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    type="task_id"
                    typeId={task.id}
                    setTagsList={setTags}
                    showLabel={show}
                    setShowLabel={handleShowTags}
                  />
                );
              })}
            </S.TagsArea>
          )}
          <S.Main>
            <S.Content
              id="name"
              tabIndex={0}
              role="button"
              onClick={invertModalVisibility}
              className="grid-item"
            >
              <div id="container-label">
                <RiFocusLine
                  className="icon"
                  size={20}
                  color="var(--red-primary)"
                />
                <h5>
                  Task <br />
                  Individual
                </h5>
              </div>

              <PopupText popUpText={task.name}>
                {task.name && task.name.length > 27
                  ? `${task.name.substring(0, 20)}...`
                  : task.name}
              </PopupText>

              <button type="button" onClick={invertModalVisibility}>
                <FaRegEdit size={14} />
              </button>
            </S.Content>

            <div className="grid-item">
              <p> | </p>
              <div id="managers">
                {task.was_removed ? (
                  <S.RestoreTask>
                    <button
                      type="button"
                      title={task.name}
                      onClick={() => handleRestoreTask(task)}
                    >
                      <RiDeleteBin2Line size={14} color="var(--red-primary)" />
                      <p id="restore-label">Restaurar Task Individual</p>
                    </button>
                  </S.RestoreTask>
                ) : (
                  <Responsibles users={task.users} />
                )}
              </div>
              <p> | </p>
            </div>

            <div className="grid-item">
              <ProgressDisplay
                width="95%"
                height="2rem"
                borderRadius="0.5rem"
                percentage={periodProgressPercent}
                title={
                  task.due_date
                    ? format(new Date(task.due_date), 'dd.MMMM.yyyy', {
                        locale: ptBR,
                      }).toString()
                    : 'Sem prazo'
                }
                colorBar={progressBarsColors.dueDateColor}
              />
              <p> | </p>
            </div>

            <div className="grid-item">
              <ProgressDisplay
                width="95%"
                height="2rem"
                borderRadius="0.5rem"
                title={progressTitle}
                percentage={task.progress}
                colorBar={progressBarsColors.progressColor}
              />
              <p> | </p>
            </div>

            <div className="grid-item">
              <div id="add-new">
                <S.Checkbox>
                  <label htmlFor={`${task.id}`}>
                    <input
                      type="checkbox"
                      data-testid="checkbox"
                      className="checkbox"
                      name={task.name}
                      onChange={handleCheckbox}
                      checked={finished}
                      id={`${task.id}`}
                    />
                    <label htmlFor={`${task.id}`} className="css-label" />
                  </label>
                </S.Checkbox>
                <strong>Concluir</strong>
              </div>
            </div>
          </S.Main>
        </S.Container>
        <S.StickerColor />
      </S.Wrapper>
      {openModal && (
        <Modal id="modal" onMouseDown={handleOutsideClick}>
          <EditIndividualTask
            projectId={projectId}
            task={task}
            handleClose={invertModalVisibility}
            tasks={tasks}
            tags={tags}
          />
        </Modal>
      )}
    </>
  );
};
