import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 16.25rem;
  flex: 1;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.backgroundSecondary};
  color: ${({ theme }) => theme.text};
  margin: 1.5rem;
  margin-bottom: 2.5rem;

  h5 {
    font-size: 0.68rem;
    font-weight: 300;
    color: ${({ theme }) => theme.text};
  }

  #header {
    width: 100%;
    height: 1.5rem;
    padding: 0.6rem;
    display: flex;
    justify-content: flex-end;
    button {
      background: none;
      border: none;
    }
  }

  #tag-area {
    width: 100%;
    padding: 1rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  #name {
    width: 100%;
    padding: 0.5rem;

    h4 {
      font-size: 0.5rem;
      color: var(--red-primary);
    }
    p {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.text};
    }
  }

  #period {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  #progress {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  #responsibles {
    width: 100%;
    height: 6rem;
    display: flex;
    flex-direction: column;
  }

  #button {
    width: 100%;
    height: 1rem;
    display: flex;
    justify-content: center;
    visibility: hidden;

    button {
      opacity: 0;
      transition: 0.3s;
      border: none;
      width: 80%;
      height: 2rem;
      margin-bottom: 1rem;
      color: var(--white);
      background-color: var(--red-primary);
      border-radius: 0.5rem;
    }
  }

  &:hover {
    width: 16.5rem;
    box-shadow: 0 0.25rem 1.9rem 0 rgba(227, 36, 56, 0.5);
    margin-bottom: 0;

    #button {
      height: 3rem;

      button {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export const Container = styled.div`
  .icon {
    margin: 0 0.3rem;
  }
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 3rem;
  padding: 0.5rem;
  display: flex;
  .progress-bar {
    padding: 1rem 0.6rem;
  }
`;

export const RestoreProject = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    color: var(--gray);
    background: none;
    border: none;
    #restore-label {
      margin: 0 0.5rem;
    }
  }
`;
