import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.625rem;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  background: transparent;
  box-shadow: none;
  border-radius: 8px;
  margin-bottom: 0;

  &.active {
    padding: 0.9375rem 0.625rem;
    background: ${({ theme }) => theme.menuClientBackground};
    box-shadow: -2px 3px 2px ${({ theme }) => theme.boxShadow};
    margin-bottom: 1.25rem;

    p {
      color: ${({ theme }) => theme.secondaryText};
    }
  }
`;

export const Submenu = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  margin-bottom: 0.625rem;

  &.active {
    margin-bottom: 0;
  }

  button {
    display: flex;
    align-items: center;
    flex: 1;
    color: ${({ theme }) => theme.text};
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-overflow: initial;
    border: 0;
    background: transparent 0% 0%;
    height: 100%;

    p {
      font-size: 0.875rem;
      letter-spacing: 0.72px;
      line-height: 13px;
      font-family: 'Open Sans';
      color: var(--gray-text);
    }
  }

  svg {
    margin: 0 0.5rem;
    color: var(--gray);
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.text};
    }

    svg {
      color: ${({ theme }) => theme.text};
    }
  }
`;

export const OpenSubmenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;

  hr {
    height: 1px;
    color: ${({ theme }) => theme.text};
    margin: 0.625rem 0;
  }

  li {
    &:hover p {
      color: ${({ theme }) => theme.text};
    }

    a {
      display: flex;
      color: ${({ theme }) => theme.text};
      font-size: 0.875rem;
      cursor: pointer;
      text-decoration: none;
      margin-bottom: 0.3125rem;
      align-items: center;
      font-weight: 400;

      &:hover p {
        color: ${({ theme }) => theme.text};
      }
    }
    svg {
      color: var(--red-primary);
      margin-left: 0;
      margin-right: 0.625rem;
    }
  }
`;
