import React, { FC } from 'react';
import { HiMoon } from 'react-icons/hi';
import { FaSun } from 'react-icons/fa';
import * as S from './styles';

interface ThemeTogglerProps {
  toggleTheme: () => void;
}

const ThemeToggler: FC<ThemeTogglerProps> = ({ toggleTheme }) => {
  return (
    <S.Container>
      <label htmlFor="checkbox" className="switch">
        <input
          id="checkbox"
          type="checkbox"
          onChange={toggleTheme}
          checked={window.localStorage.getItem('theme') === 'light'}
        />
        <S.Icons className="slider round">
          {window.localStorage.getItem('theme') !== 'light' ? (
            <>
              <HiMoon style={{ marginLeft: '8px' }} />
            </>
          ) : (
            <>
              <FaSun style={{ marginLeft: '38px' }} />
            </>
          )}
        </S.Icons>
      </label>
    </S.Container>
  );
};

export default ThemeToggler;
