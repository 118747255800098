import { toast } from 'react-toastify';
import apiV2 from '../../../services/apiV2';

export const editTask = async (
  taskId: number,
  taskName: string | undefined,
  taskDescription: string,
  phaseId: number,
  date: string | null,
  usersIds: {
    id?: number | undefined;
  }[],
  tagsIds: number[]
): Promise<editTask> => {
  try {
    const response = await apiV2.put(`tasks`, {
      include_project_group_progress: true,
      id: taskId,
      name: taskName,
      desc: taskDescription,
      phase_id: phaseId,
      due_date: date,
      users_ids: usersIds,
      tags_ids: tagsIds,
    });

    const task = response.data;

    return task;
  } catch {
    toast.error(
      'Ocorreu algum problema editando a task, atualize a página e tente novamente 😕'
    );

    return {} as editTask;
  }
};
