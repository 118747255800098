import { Container } from './styles';

interface PopupTextProps {
  children: string;
  popUpText: string;
}

export const PopupText = ({
  children,
  popUpText,
}: PopupTextProps): JSX.Element => {
  return (
    <Container>
      <p>{children}</p>
      <span className="tooltiptext">{popUpText}</span>
    </Container>
  );
};
