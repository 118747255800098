import { useCallback, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import api from '../../services/api';
import Loading from '../../components/Loadings/Loading';

export function UserApproval() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();

  const sendApproval = useCallback(async () => {
    await api
      .post(`/user_approval`, {
        activeToken: id,
        confirm: new URLSearchParams(search).get('confirm') === 'true',
      })
      .then(res => {
        if (res.data.msg === 'User Deleted.') {
          toast.info('Negada a entrada do novo usuário!');
        } else if (res.data.msg === 'User approved.') {
          toast.success('Novo usuário adicionado com sucesso!');
        }
        history.push('/home');
      })
      .catch(err => {
        if (err.response.status === 401) {
          toast.error('Link de aprovação incorreto!');
          history.push('/home');
        }
      });
  }, [id, history, search]);

  useEffect(() => {
    sendApproval();
  }, [sendApproval]);

  return <Loading />;
}
