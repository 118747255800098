/* eslint-disable no-restricted-syntax */
import React, { useState, useCallback, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { Calendar } from '../../../../../components/Calendar';
import { Button } from '../../../../../components/Inputs';

import apiV2 from '../../../../../services/apiV2';

import * as S from './styles';
import { Okrs } from '../../../components/KrsAndOkrsView/types';

import { useAuth } from '../../../../../contexts/AuthContext';

interface NewCalendarPopup {
  vselected: string;
  clientId: number;
  newDate: Date;
  hasLast: boolean;
  okrs: Okrs[];
}

const CalendarPopup: React.FC<NewCalendarPopup> = ({
  vselected,
  clientId,
  hasLast,
  newDate,
  okrs,
}) => {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const { id: userId } = useAuth().user;
  const okr = okrs[0];
  const [isCreating, setIsCreating] = useState(false);
  const [lastscreen, setLastscreen] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (hasLast) {
      setLastscreen(true);
    }
  }, [hasLast]);

  const handleSubmit = useCallback(async () => {
    let quarterId = 0;
    if (date!.setUTCHours(0, 0, 0, 0) > new Date().setUTCHours(0, 0, 0, 0)) {
      toast.error('Escolha uma data antes ou igual ao dia de hoje!');
      setLastscreen(false);
    } else {
      toast.warn('Criando novo quarter');
      setIsCreating(true);

      await apiV2
        .post('quarter', {
          start_date: hasLast ? newDate : date,
          client_id: clientId,
          client_v: vselected,
        })
        .then(res => {
          quarterId = res.data.id;
        });

      if (okr) {
        await apiV2
          .post('okr', {
            okr: {
              name: okr.name,
              is_active: true,
              client_id: clientId,
              due_date: new Date(),
            },
          })
          .then(res => {
            okr.id = res.data.id;
          });

        for await (const kr of okr.krs) {
          await apiV2.post('kr', {
            kr: {
              name: kr.name,
              goal: kr.goal,
              value: kr.value,
              isActive: true,
              is_the_lesser_the_better: kr.is_the_lesser_the_better,
              users_id: [userId],
            },
            okrId: okr.id,
          });
        }
      }
      toast.success('Quarter criado');
      history.push(`/checkin/quarter/${quarterId}`);
    }
  }, [hasLast, newDate, date, clientId, vselected, okr, history, userId]);

  return (
    <Popup
      modal
      on="click"
      open={show}
      arrow={false}
      position="bottom center"
      trigger={() => {
        return (
          <div>
            <Button
              background="var(--red-primary)"
              height="1.5rem"
              width="6rem"
              font="400 0.8rem Work Sans"
              text="Continuar"
              onClick={() => setShow(true)}
            />
          </div>
        );
      }}
      contentStyle={{
        width: '24rem',
        border: 0,
        borderRadius: '8px',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <S.Container>
        {!lastscreen ? (
          <>
            <div className="pageTitle">
              <h1>SELECIONE A DATA</h1>
              <h1>QUE INICIA O QUARTER</h1>
            </div>

            <Calendar date={date} onChange={setDate} />

            <footer>
              <Button
                background="var(--gray)"
                height="1.5rem"
                width="6rem"
                font="400 0.8rem Work Sans"
                text="Voltar"
                onClick={() => setShow(false)}
              />

              <Button
                background="var(--red-primary)"
                height="1.5rem"
                width="6rem"
                font="400 0.8rem Work Sans"
                text="Continuar"
                onClick={() => setLastscreen(true)}
              />
            </footer>
          </>
        ) : (
          <div className="startNewCheckin">
            <div className="pageTitle">
              <h1>COMEÇAR</h1>
              <h1>NOVO CHECKIN</h1>
            </div>

            <Button
              disabled={isCreating}
              background="var(--red-primary)"
              height="3rem"
              width="9rem"
              font="700 1rem Work Sans"
              text={isCreating ? 'CRIANDO QUARTER...' : 'INICIAR'}
              onClick={() => handleSubmit()}
            />
          </div>
        )}
      </S.Container>
    </Popup>
  );
};

export default CalendarPopup;
