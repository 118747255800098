import parse from 'html-react-parser';

export const removeEmpty = obj => {
  const newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop] || typeof obj[prop] === 'boolean') {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const urlify = text => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const filter = text.replace('<script>', '');
  const response = parse(
    filter.replace(urlRegex, url => {
      return `&nbsp<a target="_blank" href="${url}">${url}</a>`;
    })
  );
  return response;
};
