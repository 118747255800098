import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { RiBubbleChartLine } from 'react-icons/ri';

import Select from 'react-select';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../../contexts/AuthContext';

import formatGroupLabel from '../components/FormatSelectInGroup';

import { Button } from '../../../../../../components/Inputs';
import ResponsibleIds from '../../../../../../components/ResponsibleIds';

import CustomLoading from '../../../../../../components/Loadings/CustomLoading';

import TaskCard from '../../../../../../components/PopUp/TaskCard';
import { Modal } from '../../../../styles';
import Playbook from './containers/Playbook';
import apiV2 from '../../../../../../services/apiV2';
import { Container, Main, Input, Footer } from './styles';
import { useImportPlaybook } from '../../../../../../contexts/ImportPlaybookContext';

interface Params {
  id: string;
}

interface ImportPlaybookProps {
  handleClose: () => void;
}

interface PlaybookOptions {
  label: string;
  options: IPlaybook[];
  value?: number;
}

const ImportPlaybook: React.FC<ImportPlaybookProps> = ({ handleClose }) => {
  const { id } = useParams<Params>();

  const { setPlaybookUpdatedAt } = useImportPlaybook();

  const [openModal, setOpenModal] = useState(false);
  const [isPlaybookSelected, setPlaybookIsSelected] = useState(false);

  const [playbookSelected, setPlaybookSelected] = useState<IPlaybook>();

  const [playbookOptions, setPlaybookOptions] = useState<PlaybookOptions[]>();

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState<IUserId[]>([]);

  const handleOpenModal = (): void => {
    setOpenModal(!openModal);
  };

  const handleCloseModal = (): void => {
    setOpenModal(!openModal);
  };

  const { unit_id: unitId } = useAuth().user;

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetClick = event.target as HTMLDivElement;
    if (targetClick.id === 'third-modal') handleOpenModal();
  };

  const addFilterToOptions = useCallback(
    (options: IPlaybook[]) => {
      const newData = [...options];

      const PlaybooksWithValue = newData.map(playbook => {
        let newPlaybook = playbook;
        newPlaybook = {
          ...newPlaybook,
          value: playbook.id,
          label: playbook.name,
        };
        return newPlaybook;
      });

      const officialPlaybooks = PlaybooksWithValue.filter(playbook => {
        return playbook.isOfficial === true;
      });

      const unitPlaybooks = PlaybooksWithValue.filter(playbook => {
        return playbook.unit_id === unitId;
      });

      const unitsPlaybooks = PlaybooksWithValue.filter(playbook => {
        return playbook.unit_id !== unitId;
      });

      const groupedOptions = [
        {
          label: 'Playbooks Oficiais',
          options: officialPlaybooks,
        },
        {
          label: 'Playbooks da sua Unidade',
          options: unitPlaybooks,
        },
        {
          label: 'Playbooks de outras Unidades',
          options: unitsPlaybooks,
        },
      ];

      setPlaybookOptions(groupedOptions);
    },
    [unitId]
  );

  const apiPlaybooksRequest = useCallback(async () => {
    try {
      setLoading(true);
      const response = await apiV2.get(`playbooks/playbooks`);
      addFilterToOptions(response.data);
    } catch (err) {
      toast.error(
        'Ocorreu algum problema, atualize a página e tente novamente 😕'
      );
    } finally {
      setLoading(false);
    }
  }, [addFilterToOptions]);

  const handlePlaybookSelect = async (playbookId: number) => {
    try {
      setLoading(true);
      const response = await apiV2.get(`playbooks?playbookId=${playbookId}`);
      if (!response) return;
      setPlaybookSelected(response.data);
      setPlaybookUpdatedAt(response.data.updated_at);
    } catch (err) {
      toast.error(
        'Ocorreu algum problema, atualize a página e tente novamente 😕'
      );
    } finally {
      setPlaybookIsSelected(true);
      setLoading(false);
    }
  };
  const handleChange = (value: PlaybookOptions | null) => {
    if (!value?.value) return;

    handlePlaybookSelect(value.value);
  };

  const isUserSelected = useMemo(() => {
    if (isPlaybookSelected && users.length > 0) {
      return true;
    }
    return false;
  }, [isPlaybookSelected, users.length]);

  useEffect(() => {
    apiPlaybooksRequest();
  }, [apiPlaybooksRequest]);

  return (
    <Container>
      <TaskCard close={handleClose}>
        <header>
          <RiBubbleChartLine size={21} color="var(--red-primary)" />
          <p>Importar Playbook</p>
        </header>
        <Main>
          <Input>
            <h3>Responsável</h3>
            <ResponsibleIds users={users} setUsers={setUsers} />
          </Input>
          <Input>
            <h3>Playbook </h3>
            {playbookOptions && (
              <Select
                defaultValue={playbookOptions[0]}
                options={playbookOptions}
                formatGroupLabel={formatGroupLabel}
                onChange={handleChange}
                classNamePrefix="filter"
                placeholder="Selecione um playbook"
              />
            )}
          </Input>
          <>
            {loading ? (
              <CustomLoading />
            ) : (
              playbookSelected && (
                <Input>
                  <h3>Descrição do playbook:</h3>
                  <p>{playbookSelected.desc}</p>
                </Input>
              )
            )}
            <Footer>
              <div
                style={{
                  display: 'flex',
                  marginTop: '0.8rem',
                  marginBottom: '0.8rem',
                }}
              >
                <Button
                  background="var(--gray)"
                  width="70%"
                  height="2.1rem"
                  className="initialButton"
                  disabled={loading}
                  font="400 0.9rem Work Sans"
                  onClick={handleClose}
                >
                  <p>Voltar</p>
                </Button>
                <Button
                  background="var(--green-primary)"
                  width="100%"
                  height="2.1rem"
                  font="400 0.9rem Work Sans"
                  disabled={!isUserSelected}
                  onClick={handleOpenModal}
                >
                  <p>Abrir Preview</p>
                </Button>
              </div>
              {!isUserSelected && (
                <strong>
                  *selecione pelo menos um usuário e um playbook para ir para o
                  preview.
                </strong>
              )}
            </Footer>
          </>
        </Main>
      </TaskCard>
      {openModal && (
        <Modal id="third-modal" onClick={handleOutsideClick}>
          {playbookSelected && (
            <Playbook
              handleClose={handleCloseModal}
              playbook={playbookSelected}
              clientId={id}
              users={users}
            />
          )}
        </Modal>
      )}
    </Container>
  );
};

export default ImportPlaybook;
