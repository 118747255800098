import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.text};
  }
`;
