import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 5.5rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.backgroundSecondary};
  margin-bottom: 0.5rem;
  align-items: center;
  transition: 0.3s;

  button {
    border: none;
    background: none;
    text-align: left;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TagsArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.4rem;
  margin-left: 1rem;
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.6rem;
  div {
    width: 18%;
  }

  #name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    padding: 0 0.2rem;

    button#label {
      min-width: 90%;
      display: flex;
      align-items: center;

      #individual-task-label {
        width: 20%;
        h4 {
          color: ${({ theme }) => theme.text};
          font-weight: 100;
          font-size: 0.67rem;
          margin-left: 0.5rem;
        }
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.text};
      }

      span {
        margin: 0 1rem;
        color: ${({ theme }) => theme.text};
      }
      div:last-child {
        width: 90%;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        h4 {
          color: var(--red-primary);
          font-size: 0.67rem;
          font-weight: 100;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    #edit-button {
      svg {
        color: ${({ theme }) => theme.text};
      }
    }
  }

  #managers {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div {
      min-width: 35%;
    }
  }
  #period {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #progress {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #open {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 98%;
      height: 2.1rem;
      border-radius: 0.5rem;
      margin-left: 1rem;
      border: 0;
      background: var(--red-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      font-weight: bold;
    }
  }
`;

export const StickerColor = styled.div`
  justify-content: flex-end;
  width: 0.5%;
  height: 100%;
  background-color: var(--red-primary);
  border-radius: 0 1rem 1rem 0;
  outline: none;
`;

export const RestoreProject = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  #restore-label {
    margin: 0 0.5rem;
    font-size: 0.7rem;
  }
`;
