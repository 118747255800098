import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  #pageName {
    color: var(--red-primary);
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 2vh;
  }
`;
export const Search = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text};
`;

export const Filters = styled.div`
  display: flex;
  margin-top: 20px;
  input[type='radio'] {
    position: absolute;
    visibility: hidden;
    display: none;
  }

  label {
    color: ${({ theme }) => theme.text};
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 20px;
    transition: all 0.2s linear;
    background: ${({ theme }) => theme.backgroundPrimary};
    margin-right: 20px;

    :hover {
      filter: brightness(90%);
    }
  }
  input[type='radio']:checked + label {
    color: var(--white);
    background: var(--red-primary);
    border-radius: 3px;
    transition: all 0.2s linear;
  }
  select {
    color: ${({ theme }) => theme.text};
    background: transparent;
    border: none;
    font-weight: bold;
    cursor: pointer;

    option {
      color: ${({ theme }) => theme.text};

      :disabled {
        background: var(--gray);
      }
    }
  }
`;
export const SuggestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-y: auto;
  height: 72vh;
`;
export const Input = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  input {
    background: ${({ theme }) => theme.backgroundSecondary};
    border: 1px solid ${({ theme }) => theme.text};
    border-radius: 50px;
    color: ${({ theme }) => theme.text};
    height: 40px;
    width: 40%;
    padding: 0 10px;

    ::placeholder {
      color: var(--gray);
    }
  }
  button {
    margin-left: 8px;
    background: var(--red-primary);
    border: 1px solid var(--red-primary);
    color: var(--white);
    border-radius: 3px;
    transition: all 0.2s linear;
    padding: 0 10px;
    height: 30px;
    :hover {
      filter: brightness(90%);
    }
  }
`;
export const Controll = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
  align-items: center;
  color: var(--gray);
  width: 100%;
  > div {
    display: flex;
    align-items: center;
  }

  svg {
    cursor: pointer;
    color: var(--gray);
  }
`;
