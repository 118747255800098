import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.backgroundSecondary};
  display: flex;
  flex-direction: column;

  @media (max-width: 1366px) {
    min-width: 14rem;
  }
`;
