import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import apiV2 from '../../../../../services/apiV2';

import { Button } from '../../../../../components/Inputs';

interface SubmitButtonProps {
  id: string;
  experience: string | undefined;
  score: number | undefined;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  id,
  experience,
  score,
}) => {
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (score) {
      await apiV2
        .put('csat/update', {
          id,
          comment: experience,
          score,
        })
        .then(() => {
          setIsSent(true);
        })
        .catch(err => {
          if (err.response.data === 'This CSAT was already filled.')
            toast.error('Pesquisa já respondida!');
          else toast.error(err.message);
        });
    } else toast.error('Clique em um dos emojis!');
  }, [experience, score, id]);

  return (
    <Button
      height={isSent ? '2.5rem' : '1.6rem'}
      width={isSent ? '8rem' : '6rem'}
      font="800 0.7rem Work Sans"
      background="var(--red-primary)"
      disabled={isSent}
      text={isSent ? 'Obrigado pelo seu feedback!' : 'Enviar'}
      onClick={() => handleSubmit()}
    />
  );
};
